/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface GetInitialConsequenceOfChangeRequest
 */
export interface GetInitialConsequenceOfChangeRequest {
  /**
   *
   * @type {string}
   * @memberof GetInitialConsequenceOfChangeRequest
   */
  sessionId: string
}

/**
 * Check if a given object implements the GetInitialConsequenceOfChangeRequest interface.
 */
export function instanceOfGetInitialConsequenceOfChangeRequest(
  value: object,
): boolean {
  let isInstance = true
  isInstance = isInstance && 'sessionId' in value

  return isInstance
}

export function GetInitialConsequenceOfChangeRequestFromJSON(
  json: any,
): GetInitialConsequenceOfChangeRequest {
  return GetInitialConsequenceOfChangeRequestFromJSONTyped(json, false)
}

export function GetInitialConsequenceOfChangeRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GetInitialConsequenceOfChangeRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    sessionId: json['session_id'],
  }
}

export function GetInitialConsequenceOfChangeRequestToJSON(
  value?: GetInitialConsequenceOfChangeRequest | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    session_id: value.sessionId,
  }
}
