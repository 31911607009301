import { CSSProperties } from 'react'
import styled from 'styled-components'

const ColorIconRoot = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;
  min-width: 46px;
  min-height: 46px;
`

const LoadingIndicatorWrapper = styled.div`
  width: 46px;
  height: 46px;
  min-width: 46px;
  min-height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export interface ColorIconProps {
  $isSelected: boolean
  $isLoading: boolean
  $color: string
}

// Always the same, build it once here and inject into the instance template.
const ColorLoadingIndicator = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="46px"
    height="46px"
    viewBox="0 0 50 50"
    xmlSpace="preserve"
  >
    <path
      fill="var(--tds-blue-400)"
      d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
    >
      <animateTransform
        attributeType="xml"
        attributeName="transform"
        type="rotate"
        from="0 25 25"
        to="360 25 25"
        dur="0.6s"
        repeatCount="indefinite"
      />
    </path>
  </svg>
)

// Always the same, build it once here and inject into the instance template.
const colorSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    width="46px"
    height="46px"
    viewBox="0 0 46 46"
  >
    <circle
      cx="23"
      cy="23"
      r="16"
      stroke="#56657a"
      strokeWidth="2"
      fill="none"
    />
    <circle cx="23" cy="23" r="16" stroke="currentColor" fill="currentColor" />
    <circle
      className="hover-indicator"
      cx="23"
      cy="23"
      r="20"
      stroke="#0f3263"
      strokeWidth="2"
      fill="none"
    />
    <circle
      className="active-indicator"
      cx="23"
      cy="23"
      r="20"
      stroke="#4a89f3"
      strokeWidth="3"
      fill="none"
    />
    <circle
      cx="23"
      cy="23"
      r="20"
      stroke="#0f3263"
      strokeWidth="3"
      fill="none"
      display="var(--selection-indicator-display)"
    />
  </svg>
)

export const ColorIcon = (props: ColorIconProps): JSX.Element => {
  // A hack to get around styled components generating one class for each props
  // combination.
  const cssProps = {
    '--selection-indicator-display': props.$isSelected ? 'block' : 'none',
    color: props.$color,
    width: '100%',
    height: '100%',
  } as CSSProperties

  return (
    <ColorIconRoot>
      {props.$isLoading ? (
        <LoadingIndicatorWrapper>
          {ColorLoadingIndicator}
        </LoadingIndicatorWrapper>
      ) : (
        <div style={cssProps}>{colorSvg}</div>
      )}
    </ColorIconRoot>
  )
}
