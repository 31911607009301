import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { allGoStepIds } from '../../api/constants'
import useTexts from '../../utils/useTexts'
import { useAppSelector } from '../../store/hooks'
import { GoStepperEntry, buildStepperEntries } from './GoStepperEntry'
import { BreakpointWidthPx } from '../../css/BreakpointWidthPx'
import { GO_LOW_HEIGHT_LIMIT_ADAPTION_STEPPER } from '../../constants'
import { getGuidedOfferingClientState } from '../../store/sessionDataSlice'
import { TdsStep, TdsStepper } from '@scania/tegel-react'

const TimelineAndHeaderRoot = styled.div`
  width: 100%;

  @media screen and (max-width: ${BreakpointWidthPx.Tablet}px) {
    justify-content: center;
    align-items: center;
    display: flex;
    border-bottom: 1px solid var(--tds-grey-400);
    margin-bottom: 16px;
    margin-left: 16px;
    margin-right: 16px;
  }

  @media screen and (min-width: ${BreakpointWidthPx.Tablet}px) {
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
`

const StepperDesktop = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 64px;
  margin-bottom: 64px;
  width: 100%;
  min-height: 88px;

  @media screen and (max-width: ${BreakpointWidthPx.Tablet}px) {
    display: none;
  }

  // Reduce margin at low screen heights
  @media screen and (max-height: ${GO_LOW_HEIGHT_LIMIT_ADAPTION_STEPPER}) {
    margin-top: 24px;
    margin-bottom: 20px;
  }
`

const StepperMobileTablet = styled.div`
  margin-right: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 50px;
  width: 50px;

  @media screen and (min-width: ${BreakpointWidthPx.Tablet}px) {
    display: none;
  }
`

const StepperMobileWrapper = styled.div`
  height: 50px;
  width: 50px;
  position: relative;
`

const StepperMobileNumberIndicator = styled.div`
  height: 50px;
  width: 50px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 12px;
`

const PageHeader = styled.div`
  text-transform: uppercase;

  @media screen and (min-width: ${BreakpointWidthPx.Tablet}px) {
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  @media screen and (max-width: ${BreakpointWidthPx.Tablet}px) {
    font-size: 20px;
    max-width: calc(100vw - 66px - 44px); // - stepper width - margin
    line-height: unset;
  }

  // Reduce fontsize at low desktop screen heights
  @media screen and (min-width: ${BreakpointWidthPx.Tablet}px) and (max-height: ${GO_LOW_HEIGHT_LIMIT_ADAPTION_STEPPER}) {
    font-size: 32px;
  }
`

const Stepper = styled(TdsStepper)`
  @media screen and (max-width: ${BreakpointWidthPx.Desktop}px) {
    padding-right: 24px;
    padding-left: 24px;
  }

  @media screen and (min-width: ${BreakpointWidthPx.Desktop}px) {
    max-width: ${BreakpointWidthPx.Desktop}px;
  }
`

const StepLabel = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (max-width: ${BreakpointWidthPx.Desktop}px) {
    max-width: 108px;
  }

  @media screen and (min-width: ${BreakpointWidthPx.Desktop}px) {
    max-width: 140px;
  }
`

export interface TimelineProps {
  header: string
  isReady: boolean | null | undefined
}

export const Timeline = ({ header, isReady }: TimelineProps): JSX.Element => {
  const t = useTexts()
  const goClientState = useAppSelector(getGuidedOfferingClientState)
  const refHeader = useRef<HTMLHeadingElement>(null)

  const [numberIndicator, setNumberIndicator] = useState<string>('')
  const [stepperStrokeDashInput, setstepperStrokeDashInput] =
    useState<string>('')
  const [timelineEntries, setTimelineEntries] = useState<GoStepperEntry[]>([])

  // Build data for current step
  useEffect(() => {
    const entries = buildStepperEntries(goClientState)
    setTimelineEntries(entries)
  }, [goClientState])

  // Get index and fill degree for stepper in portrait mode for current step
  useEffect(() => {
    const currentStepId = goClientState?.lastResponse.question?.etelFamily
    if (!currentStepId) {
      return
    }
    const index = allGoStepIds.indexOf(currentStepId)
    let currentNumberIndicator =
      (index === -1 ? allGoStepIds.length : index) + 1
    setNumberIndicator(
      currentNumberIndicator + ' / ' + (allGoStepIds.length + 1),
    )
    const stepperCircumference = 2 * Math.PI * 20 // 2 * pi * radius
    const stepperFillGrade = stepperCircumference * (currentNumberIndicator / 7) // circumference * filling grade
    setstepperStrokeDashInput(
      stepperFillGrade.toString() + ',' + stepperCircumference.toString(),
    )
  }, [goClientState])

  // Adjust font size and text overflow for mobile header, based on content width
  const adjustFontSize = () => {
    const header = refHeader.current

    if (window.innerWidth < BreakpointWidthPx.Tablet && header) {
      header.style.fontSize = '20px'
      header.style.width = 'fit-content'
      const headerWidth = header.offsetWidth
      const containerWidth = header.parentElement?.offsetWidth || 0

      if (headerWidth > containerWidth) {
        const newFontSize = Math.floor(20 * (containerWidth / headerWidth))
        if (newFontSize >= 14) {
          header.style.fontSize = `${newFontSize}px`
          header.style.overflow = ''
          header.style.textOverflow = ''
        } else {
          header.style.fontSize = `14px`
          header.style.width = `${containerWidth}px`
          header.style.overflow = `hidden`
          header.style.textOverflow = `ellipsis`
        }
      }
    }
  }

  useEffect(() => {
    adjustFontSize()
    window.addEventListener('resize', adjustFontSize)
    return () => {
      window.removeEventListener('resize', adjustFontSize)
    }
  }, [header])

  return (
    <TimelineAndHeaderRoot data-name="TimelineRoot">
      <StepperDesktop>
        {timelineEntries.length > 0 && (
          <Stepper>
            {timelineEntries.map((entry, i) => (
              <TdsStep
                state={
                  entry.isActiveQuestion
                    ? 'current'
                    : entry.isFinishedQuestion
                    ? 'success'
                    : 'upcoming'
                }
                key={entry.id || i}
                index={(i + 1).toString()}
              >
                <StepLabel slot="label">
                  {entry.id && t(`SQ_HEADER_${entry.id}`)}
                </StepLabel>
              </TdsStep>
            ))}
          </Stepper>
        )}
      </StepperDesktop>
      <StepperMobileTablet>
        <StepperMobileWrapper>
          <StepperMobileNumberIndicator>
            {numberIndicator}
          </StepperMobileNumberIndicator>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            width="50px"
            height="50px"
            viewBox="0 0 50 50"
          >
            <circle
              cx="25"
              cy="25"
              r="20"
              stroke="#CDD1DB"
              strokeWidth="6"
              fill="none"
            />
            <circle
              cx="25"
              cy="25"
              r="20"
              stroke="var(--tds-positive)"
              strokeWidth="6"
              strokeDasharray={stepperStrokeDashInput}
              fill="none"
              transform="rotate(-90) translate(-50)"
            />
          </svg>
        </StepperMobileWrapper>
      </StepperMobileTablet>
      <PageHeader style={{ opacity: isReady ? 1 : 0.3 }}>
        <h1 ref={refHeader}>{header}</h1>
      </PageHeader>
    </TimelineAndHeaderRoot>
  )
}
