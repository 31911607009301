/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ScaniaGlobalAuxiliary } from './ScaniaGlobalAuxiliary'
import {
  ScaniaGlobalAuxiliaryFromJSON,
  ScaniaGlobalAuxiliaryFromJSONTyped,
  ScaniaGlobalAuxiliaryToJSON,
} from './ScaniaGlobalAuxiliary'

/**
 * TODO: Refactor this to a simple, non-generic data type, fully
 * customized for the needs of Scania Configurator.
 * @export
 * @interface ConfigChangeResultAuxCarrier
 */
export interface ConfigChangeResultAuxCarrier {
  /**
   *
   * @type {{ [key: string]: ScaniaGlobalAuxiliary; }}
   * @memberof ConfigChangeResultAuxCarrier
   */
  auxiliaries: { [key: string]: ScaniaGlobalAuxiliary }
}

/**
 * Check if a given object implements the ConfigChangeResultAuxCarrier interface.
 */
export function instanceOfConfigChangeResultAuxCarrier(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'auxiliaries' in value

  return isInstance
}

export function ConfigChangeResultAuxCarrierFromJSON(
  json: any,
): ConfigChangeResultAuxCarrier {
  return ConfigChangeResultAuxCarrierFromJSONTyped(json, false)
}

export function ConfigChangeResultAuxCarrierFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ConfigChangeResultAuxCarrier {
  if (json === undefined || json === null) {
    return json
  }
  return {
    auxiliaries: mapValues(json['auxiliaries'], ScaniaGlobalAuxiliaryFromJSON),
  }
}

export function ConfigChangeResultAuxCarrierToJSON(
  value?: ConfigChangeResultAuxCarrier | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    auxiliaries: mapValues(value.auxiliaries, ScaniaGlobalAuxiliaryToJSON),
  }
}
