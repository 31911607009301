/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { UserConfigInfo } from './UserConfigInfo'
import {
  UserConfigInfoFromJSON,
  UserConfigInfoFromJSONTyped,
  UserConfigInfoToJSON,
} from './UserConfigInfo'

/**
 *
 * @export
 * @interface UserConfigV2ListResponseWrapper
 */
export interface UserConfigV2ListResponseWrapper {
  /**
   *
   * @type {Array<UserConfigInfo>}
   * @memberof UserConfigV2ListResponseWrapper
   */
  userConfigV2List?: Array<UserConfigInfo> | null
  /**
   *
   * @type {string}
   * @memberof UserConfigV2ListResponseWrapper
   */
  error?: string | null
}

/**
 * Check if a given object implements the UserConfigV2ListResponseWrapper interface.
 */
export function instanceOfUserConfigV2ListResponseWrapper(
  value: object,
): boolean {
  let isInstance = true

  return isInstance
}

export function UserConfigV2ListResponseWrapperFromJSON(
  json: any,
): UserConfigV2ListResponseWrapper {
  return UserConfigV2ListResponseWrapperFromJSONTyped(json, false)
}

export function UserConfigV2ListResponseWrapperFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserConfigV2ListResponseWrapper {
  if (json === undefined || json === null) {
    return json
  }
  return {
    userConfigV2List: !exists(json, 'user_config_v2_list')
      ? undefined
      : json['user_config_v2_list'] === null
      ? null
      : (json['user_config_v2_list'] as Array<any>).map(UserConfigInfoFromJSON),
    error: !exists(json, 'error') ? undefined : json['error'],
  }
}

export function UserConfigV2ListResponseWrapperToJSON(
  value?: UserConfigV2ListResponseWrapper | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    user_config_v2_list:
      value.userConfigV2List === undefined
        ? undefined
        : value.userConfigV2List === null
        ? null
        : (value.userConfigV2List as Array<any>).map(UserConfigInfoToJSON),
    error: value.error,
  }
}
