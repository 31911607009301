/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GoWizardCloseResponseWrapperGowizardClose } from './GoWizardCloseResponseWrapperGowizardClose'
import {
  GoWizardCloseResponseWrapperGowizardCloseFromJSON,
  GoWizardCloseResponseWrapperGowizardCloseFromJSONTyped,
  GoWizardCloseResponseWrapperGowizardCloseToJSON,
} from './GoWizardCloseResponseWrapperGowizardClose'

/**
 * Legacy response wrapper structure to allow matching the original
 * configurator web API.
 *
 * TODO: This should be removed after the .Net Core and ScdsClient
 * migration has been completed.
 * @export
 * @interface GoWizardCloseResponseWrapper
 */
export interface GoWizardCloseResponseWrapper {
  /**
   *
   * @type {GoWizardCloseResponseWrapperGowizardClose}
   * @memberof GoWizardCloseResponseWrapper
   */
  gowizardClose: GoWizardCloseResponseWrapperGowizardClose
}

/**
 * Check if a given object implements the GoWizardCloseResponseWrapper interface.
 */
export function instanceOfGoWizardCloseResponseWrapper(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'gowizardClose' in value

  return isInstance
}

export function GoWizardCloseResponseWrapperFromJSON(
  json: any,
): GoWizardCloseResponseWrapper {
  return GoWizardCloseResponseWrapperFromJSONTyped(json, false)
}

export function GoWizardCloseResponseWrapperFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GoWizardCloseResponseWrapper {
  if (json === undefined || json === null) {
    return json
  }
  return {
    gowizardClose: GoWizardCloseResponseWrapperGowizardCloseFromJSON(
      json['gowizard_close'],
    ),
  }
}

export function GoWizardCloseResponseWrapperToJSON(
  value?: GoWizardCloseResponseWrapper | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    gowizard_close: GoWizardCloseResponseWrapperGowizardCloseToJSON(
      value.gowizardClose,
    ),
  }
}
