import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { TestElementTypeId } from '../../types/TestAttributeId'
import {
  ScaniaAdobeTrackingDownloadXmlEvent,
  pushAdobeEvent,
  ScaniaAdobeEventId,
  ScaniaAdobeDownloadType,
} from '../../utils/adobeAnalytics'
import useTexts from '../../utils/useTexts'
import { SvgLoading } from '../SvgImports'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import {
  getSessionInitDataState,
  getStartupData,
} from '../../store/sessionDataSlice'
import { closeAllModals, getModalState } from '../../store/modalSlice'
import { TdsButton, TdsModal } from '@scania/tegel-react'

const TextBox = styled.div`
  height: 40px;
`

const SpinnerWrapper = styled.div`
  margin-left: 32px;
  height: 40px;

  svg {
    color: var(--tds-blue-600);
  }
`

export function ModalSaveXml(): JSX.Element {
  const t = useTexts()
  const dispatch = useAppDispatch()
  const sessionInitData = useAppSelector(getSessionInitDataState)
  const sessionId = sessionInitData?.sessionId || ''
  const [xmlDownloadUrl, setXmlDownloadUrl] = useState<string | null>(null)
  const startupData = useAppSelector(getStartupData)
  const modalSaveXmlState = useAppSelector(getModalState).modalSaveXmlState

  // Build url for xml download
  useEffect(() => {
    async function buildXmlUrl() {
      if (!sessionId) {
        return
      }
      if (!startupData) {
        return
      }
      const xmlUrl =
        startupData.backendUrl +
        '/api/scania_export_xml?session_id=' +
        sessionId
      setXmlDownloadUrl(xmlUrl)
    }
    buildXmlUrl()
  }, [sessionId, startupData])

  const handleXmlTracking = useCallback(() => {
    if (!modalSaveXmlState) {
      throw new Error('Expected xml source to be defined.')
    }
    const trackingEvent: ScaniaAdobeTrackingDownloadXmlEvent = {
      event: ScaniaAdobeEventId.Download,
      eventInfo: {
        downloadType: ScaniaAdobeDownloadType.Xml,
        filePath: xmlDownloadUrl || '',
        placement: modalSaveXmlState.source,
      },
    }
    pushAdobeEvent(trackingEvent)
    dispatch(closeAllModals())
  }, [xmlDownloadUrl, dispatch, modalSaveXmlState])

  const handleCloseClick = useCallback(() => {
    dispatch(closeAllModals())
  }, [dispatch])

  // Eventlistener to close modal on tdsClose (click outside modal and click on close cross)
  useEffect(() => {
    document.addEventListener('tdsClose', handleCloseClick)
    return () => {
      document.removeEventListener('tdsClose', handleCloseClick)
    }
  }, [handleCloseClick])

  return (
    <TdsModal size="md" header={t('HEADER_DOWNLOAD_XML')} show>
      <span slot="body">
        {xmlDownloadUrl ? (
          <TextBox>{t('LABEL_DOWNLOAD_XML')}</TextBox>
        ) : (
          <SpinnerWrapper>
            <SvgLoading />
          </SpinnerWrapper>
        )}
      </span>

      <span slot="actions" className="tds-u-flex tds-u-gap2 tds-u-flex-wrap">
        <a
          href={xmlDownloadUrl || ''}
          target={'_blank'}
          rel="noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <TdsButton
            variant="primary"
            size="md"
            text={t('ACTION_LABEL_DOWNLOAD')}
            onClick={handleXmlTracking}
            disabled={xmlDownloadUrl ? false : true}
            data-test-element-type={
              TestElementTypeId.ModalSaveXmlDownloadButton
            }
          />
        </a>
        <TdsButton
          variant="secondary"
          size="md"
          text={t('LABEL_ACTION_CLOSE')}
          onClick={handleCloseClick}
          data-test-element-type={TestElementTypeId.ModalCloseButton}
        />
      </span>
    </TdsModal>
  )
}
