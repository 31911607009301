// TODO: Embrace the natural behavior of Android and allow a more adaptive
// layout, why not allow landscape? Stop fighting the different platforms and
// allow their natural default behavior.

// See "Android orientation" in the README file.

import { useEffect, useRef } from 'react'

const usePreventAndroidKeyboardBug = () => {
  const hasFocusOnNewElement = useRef(false)
  /**
   * This sets a class to HTML that prevents the orientation layer
   * to kick in as soon as an Android device falsly triggers the
   * orientation change when the keyboard goes up.
   */

  useEffect(() => {
    if (!window.bowser.android) return
    const root = window.document.documentElement
    const cssSelectors = ['input[type=text]', 'input[type=password]']
    const timeForKeyboardToDisapear = 400
    for (const sel in cssSelectors) {
      const inputElCollection = window.document.querySelectorAll(
        cssSelectors[sel],
      )
      ;[...inputElCollection].forEach((inputEl) => {
        inputEl.addEventListener('focus', () => {
          hasFocusOnNewElement.current = true
          root.classList.add('keyboardup')
          setTimeout(
            () => (hasFocusOnNewElement.current = false),
            timeForKeyboardToDisapear * 2,
          )
        })
        inputEl.addEventListener('blur', () =>
          setTimeout(() => {
            if (!hasFocusOnNewElement.current) {
              root.classList.remove('keyboardup')
            }
          }, timeForKeyboardToDisapear),
        )
      })
    }

    return () => {
      for (const sel in cssSelectors) {
        const inputElCollection = window.document.querySelectorAll(
          cssSelectors[sel],
        )
        ;[...inputElCollection].forEach((inputEl) => {
          inputEl.removeEventListener('focus', () => {
            hasFocusOnNewElement.current = true
            root.classList.add('keyboardup')
            setTimeout(
              () => (hasFocusOnNewElement.current = false),
              timeForKeyboardToDisapear * 2,
            )
          })
          inputEl.removeEventListener('blur', () =>
            setTimeout(() => {
              if (!hasFocusOnNewElement.current) {
                root.classList.remove('keyboardup')
              }
            }, timeForKeyboardToDisapear),
          )
        })
      }
    }
  }, [])
}

export default usePreventAndroidKeyboardBug
