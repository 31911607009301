/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { DealerAdditionalEmail } from './DealerAdditionalEmail'
import {
  DealerAdditionalEmailFromJSON,
  DealerAdditionalEmailFromJSONTyped,
  DealerAdditionalEmailToJSON,
} from './DealerAdditionalEmail'
import type { DealerLocation } from './DealerLocation'
import {
  DealerLocationFromJSON,
  DealerLocationFromJSONTyped,
  DealerLocationToJSON,
} from './DealerLocation'
import type { DealerOpeningHoursEntry } from './DealerOpeningHoursEntry'
import {
  DealerOpeningHoursEntryFromJSON,
  DealerOpeningHoursEntryFromJSONTyped,
  DealerOpeningHoursEntryToJSON,
} from './DealerOpeningHoursEntry'
import type { DealerPhoneEntry } from './DealerPhoneEntry'
import {
  DealerPhoneEntryFromJSON,
  DealerPhoneEntryFromJSONTyped,
  DealerPhoneEntryToJSON,
} from './DealerPhoneEntry'
import type { DealerToplevelService } from './DealerToplevelService'
import {
  DealerToplevelServiceFromJSON,
  DealerToplevelServiceFromJSONTyped,
  DealerToplevelServiceToJSON,
} from './DealerToplevelService'
import type { DealerVisitingAddress } from './DealerVisitingAddress'
import {
  DealerVisitingAddressFromJSON,
  DealerVisitingAddressFromJSONTyped,
  DealerVisitingAddressToJSON,
} from './DealerVisitingAddress'

/**
 *
 * @export
 * @interface Dealer
 */
export interface Dealer {
  /**
   *
   * @type {string}
   * @memberof Dealer
   */
  iD?: string | null
  /**
   *
   * @type {string}
   * @memberof Dealer
   */
  dealerId?: string | null
  /**
   *
   * @type {string}
   * @memberof Dealer
   */
  dealerWorkshopType?: string | null
  /**
   *
   * @type {string}
   * @memberof Dealer
   */
  countryCode?: string | null
  /**
   *
   * @type {string}
   * @memberof Dealer
   */
  name?: string | null
  /**
   *
   * @type {string}
   * @memberof Dealer
   */
  nameLocal?: string | null
  /**
   *
   * @type {string}
   * @memberof Dealer
   */
  city?: string | null
  /**
   *
   * @type {string}
   * @memberof Dealer
   */
  cityLocal?: string | null
  /**
   *
   * @type {string}
   * @memberof Dealer
   */
  region?: string | null
  /**
   *
   * @type {string}
   * @memberof Dealer
   */
  regionLocal?: string | null
  /**
   *
   * @type {string}
   * @memberof Dealer
   */
  eMail?: string | null
  /**
   *
   * @type {Array<DealerAdditionalEmail>}
   * @memberof Dealer
   */
  additionalEMails?: Array<DealerAdditionalEmail> | null
  /**
   *
   * @type {string}
   * @memberof Dealer
   */
  wWW?: string | null
  /**
   *
   * @type {DealerLocation}
   * @memberof Dealer
   */
  location?: DealerLocation | null
  /**
   *
   * @type {Array<DealerPhoneEntry>}
   * @memberof Dealer
   */
  phones?: Array<DealerPhoneEntry> | null
  /**
   *
   * @type {DealerVisitingAddress}
   * @memberof Dealer
   */
  visitingAddress?: DealerVisitingAddress | null
  /**
   *
   * @type {Array<DealerOpeningHoursEntry>}
   * @memberof Dealer
   */
  openingHours?: Array<DealerOpeningHoursEntry> | null
  /**
   *
   * @type {string}
   * @memberof Dealer
   */
  openingHoursComments?: string | null
  /**
   *
   * @type {Array<string>}
   * @memberof Dealer
   */
  acceptedCards?: Array<string> | null
  /**
   *
   * @type {string}
   * @memberof Dealer
   */
  cardComments?: string | null
  /**
   *
   * @type {Array<DealerToplevelService>}
   * @memberof Dealer
   */
  toplevelServices?: Array<DealerToplevelService> | null
}

/**
 * Check if a given object implements the Dealer interface.
 */
export function instanceOfDealer(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function DealerFromJSON(json: any): Dealer {
  return DealerFromJSONTyped(json, false)
}

export function DealerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Dealer {
  if (json === undefined || json === null) {
    return json
  }
  return {
    iD: !exists(json, 'ID') ? undefined : json['ID'],
    dealerId: !exists(json, 'DealerId') ? undefined : json['DealerId'],
    dealerWorkshopType: !exists(json, 'DealerWorkshopType')
      ? undefined
      : json['DealerWorkshopType'],
    countryCode: !exists(json, 'CountryCode') ? undefined : json['CountryCode'],
    name: !exists(json, 'Name') ? undefined : json['Name'],
    nameLocal: !exists(json, 'NameLocal') ? undefined : json['NameLocal'],
    city: !exists(json, 'City') ? undefined : json['City'],
    cityLocal: !exists(json, 'CityLocal') ? undefined : json['CityLocal'],
    region: !exists(json, 'Region') ? undefined : json['Region'],
    regionLocal: !exists(json, 'RegionLocal') ? undefined : json['RegionLocal'],
    eMail: !exists(json, 'EMail') ? undefined : json['EMail'],
    additionalEMails: !exists(json, 'AdditionalEMails')
      ? undefined
      : json['AdditionalEMails'] === null
      ? null
      : (json['AdditionalEMails'] as Array<any>).map(
          DealerAdditionalEmailFromJSON,
        ),
    wWW: !exists(json, 'WWW') ? undefined : json['WWW'],
    location: !exists(json, 'Location')
      ? undefined
      : DealerLocationFromJSON(json['Location']),
    phones: !exists(json, 'Phones')
      ? undefined
      : json['Phones'] === null
      ? null
      : (json['Phones'] as Array<any>).map(DealerPhoneEntryFromJSON),
    visitingAddress: !exists(json, 'VisitingAddress')
      ? undefined
      : DealerVisitingAddressFromJSON(json['VisitingAddress']),
    openingHours: !exists(json, 'OpeningHours')
      ? undefined
      : json['OpeningHours'] === null
      ? null
      : (json['OpeningHours'] as Array<any>).map(
          DealerOpeningHoursEntryFromJSON,
        ),
    openingHoursComments: !exists(json, 'OpeningHoursComments')
      ? undefined
      : json['OpeningHoursComments'],
    acceptedCards: !exists(json, 'AcceptedCards')
      ? undefined
      : json['AcceptedCards'],
    cardComments: !exists(json, 'CardComments')
      ? undefined
      : json['CardComments'],
    toplevelServices: !exists(json, 'ToplevelServices')
      ? undefined
      : json['ToplevelServices'] === null
      ? null
      : (json['ToplevelServices'] as Array<any>).map(
          DealerToplevelServiceFromJSON,
        ),
  }
}

export function DealerToJSON(value?: Dealer | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    ID: value.iD,
    DealerId: value.dealerId,
    DealerWorkshopType: value.dealerWorkshopType,
    CountryCode: value.countryCode,
    Name: value.name,
    NameLocal: value.nameLocal,
    City: value.city,
    CityLocal: value.cityLocal,
    Region: value.region,
    RegionLocal: value.regionLocal,
    EMail: value.eMail,
    AdditionalEMails:
      value.additionalEMails === undefined
        ? undefined
        : value.additionalEMails === null
        ? null
        : (value.additionalEMails as Array<any>).map(
            DealerAdditionalEmailToJSON,
          ),
    WWW: value.wWW,
    Location: DealerLocationToJSON(value.location),
    Phones:
      value.phones === undefined
        ? undefined
        : value.phones === null
        ? null
        : (value.phones as Array<any>).map(DealerPhoneEntryToJSON),
    VisitingAddress: DealerVisitingAddressToJSON(value.visitingAddress),
    OpeningHours:
      value.openingHours === undefined
        ? undefined
        : value.openingHours === null
        ? null
        : (value.openingHours as Array<any>).map(DealerOpeningHoursEntryToJSON),
    OpeningHoursComments: value.openingHoursComments,
    AcceptedCards: value.acceptedCards,
    CardComments: value.cardComments,
    ToplevelServices:
      value.toplevelServices === undefined
        ? undefined
        : value.toplevelServices === null
        ? null
        : (value.toplevelServices as Array<any>).map(
            DealerToplevelServiceToJSON,
          ),
  }
}
