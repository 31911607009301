/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PublicConfigV2InfoResponseWrapperPublicConfigV2Info } from './PublicConfigV2InfoResponseWrapperPublicConfigV2Info'
import {
  PublicConfigV2InfoResponseWrapperPublicConfigV2InfoFromJSON,
  PublicConfigV2InfoResponseWrapperPublicConfigV2InfoFromJSONTyped,
  PublicConfigV2InfoResponseWrapperPublicConfigV2InfoToJSON,
} from './PublicConfigV2InfoResponseWrapperPublicConfigV2Info'

/**
 * Legacy response wrapper structure to allow matching the original
 * configurator web API.
 *
 * TODO: This should be removed after the .Net Core and ScdsClient
 * migration has been completed.
 * @export
 * @interface PublicConfigV2InfoResponseWrapper
 */
export interface PublicConfigV2InfoResponseWrapper {
  /**
   *
   * @type {PublicConfigV2InfoResponseWrapperPublicConfigV2Info}
   * @memberof PublicConfigV2InfoResponseWrapper
   */
  publicConfigV2Info: PublicConfigV2InfoResponseWrapperPublicConfigV2Info
}

/**
 * Check if a given object implements the PublicConfigV2InfoResponseWrapper interface.
 */
export function instanceOfPublicConfigV2InfoResponseWrapper(
  value: object,
): boolean {
  let isInstance = true
  isInstance = isInstance && 'publicConfigV2Info' in value

  return isInstance
}

export function PublicConfigV2InfoResponseWrapperFromJSON(
  json: any,
): PublicConfigV2InfoResponseWrapper {
  return PublicConfigV2InfoResponseWrapperFromJSONTyped(json, false)
}

export function PublicConfigV2InfoResponseWrapperFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PublicConfigV2InfoResponseWrapper {
  if (json === undefined || json === null) {
    return json
  }
  return {
    publicConfigV2Info:
      PublicConfigV2InfoResponseWrapperPublicConfigV2InfoFromJSON(
        json['public_config_v2_info'],
      ),
  }
}

export function PublicConfigV2InfoResponseWrapperToJSON(
  value?: PublicConfigV2InfoResponseWrapper | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    public_config_v2_info:
      PublicConfigV2InfoResponseWrapperPublicConfigV2InfoToJSON(
        value.publicConfigV2Info,
      ),
  }
}
