import { useCallback, useEffect } from 'react'
import { setSessionIdInSessionStorage } from '../../utils/sessionStorageUtil'
import { buildRelativeLandingPageUrl } from '../../utils/UrlBuilders'
import useTexts from '../../utils/useTexts'
import { GoFmMode } from '../../api/goFmMode'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { getUrlParametersToString } from '../../utils/getUrlParametersToString'
import {
  getFactoryModelsState,
  getMarketLanguageState,
  getMarketSettingsState,
} from '../../store/appSlice'
import {
  getIsLoggedInState,
  getSaveEnabled,
  getSessionInitDataState,
} from '../../store/sessionDataSlice'
import {
  closeAllModals,
  closeModalChangesLost,
  openModalSaveGarage,
} from '../../store/modalSlice'
import { openPanelLoginGarage } from '../../store/sidePanelSlice'
import { TdsButton, TdsModal } from '@scania/tegel-react'

interface ModalChangesLostProps {
  handleSaveAsClick: (e: React.MouseEvent) => void
}

export function ModalChangesLost(props: ModalChangesLostProps): JSX.Element {
  const marketLanguage = useAppSelector(getMarketLanguageState)
  const t = useTexts()
  const dispatch = useAppDispatch()
  const sessionInitData = useAppSelector(getSessionInitDataState)
  const sessionId = sessionInitData?.sessionId
  const marketSettings = useAppSelector(getMarketSettingsState)
  const { mode: factoryModelsMode } = useAppSelector(getFactoryModelsState)
  const isLoggedIn = useAppSelector(getIsLoggedInState)
  const saveEnabled = useAppSelector(getSaveEnabled)

  const handleDiscardClick = useCallback(() => {
    if (!marketLanguage) {
      return
    }
    if (sessionId && isLoggedIn) {
      setSessionIdInSessionStorage(sessionId)
    }
    // Reload the application from the landing page.
    const relativeUrl = buildRelativeLandingPageUrl(marketLanguage)
    const params = relativeUrl.params
    document.location.href = relativeUrl.path + getUrlParametersToString(params)
  }, [marketLanguage, sessionId, isLoggedIn])

  const handleSaveClick = useCallback(() => {
    dispatch(closeModalChangesLost())
    if (isLoggedIn) {
      dispatch(openModalSaveGarage())
    }
    // TODO - redirect to Save after login
    if (!isLoggedIn) {
      dispatch(openPanelLoginGarage())
    }
  }, [dispatch, isLoggedIn])

  const handleCloseClick = useCallback(() => {
    dispatch(closeAllModals())
  }, [dispatch])

  // Eventlistener to close modal on tdsClose (click outside modal and click on close cross)
  useEffect(() => {
    document.addEventListener('tdsClose', handleCloseClick)
    return () => {
      document.removeEventListener('tdsClose', handleCloseClick)
    }
  }, [handleCloseClick])

  return (
    <TdsModal size="md" header={t('HEADER_LOSING_CHANGES')} show>
      <span slot="body">
        <div>{t('DESCRIPTION_LOSING_CHANGES')}</div>
      </span>
      <span slot="actions" className="tds-u-flex tds-u-gap2 tds-u-flex-wrap">
        {marketSettings?.garageEnabled &&
          factoryModelsMode !== GoFmMode.OnlyFactoryModels && (
            <TdsButton
              variant="primary"
              size="md"
              text={t('LABEL_ACTION_SAVE_AS')}
              onClick={(e) => props.handleSaveAsClick(e)}
            />
          )}
        {saveEnabled &&
          marketSettings?.garageEnabled &&
          factoryModelsMode !== GoFmMode.OnlyFactoryModels && (
            <TdsButton
              variant="primary"
              size="md"
              text={t('LABEL_ACTION_SAVE')}
              onClick={handleSaveClick}
            />
          )}
        <TdsButton
          variant="primary"
          size="md"
          text={t('LABEL_ACTION_DISCARD_CHANGES')}
          onClick={handleDiscardClick}
        />
        <TdsButton
          variant="secondary"
          size="md"
          text={t('LABEL_ACTION_CLOSE')}
          onClick={handleCloseClick}
        />
      </span>
    </TdsModal>
  )
}
