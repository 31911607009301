/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { CreateSessionResponseWrapperCreateSession } from './CreateSessionResponseWrapperCreateSession'
import {
  CreateSessionResponseWrapperCreateSessionFromJSON,
  CreateSessionResponseWrapperCreateSessionFromJSONTyped,
  CreateSessionResponseWrapperCreateSessionToJSON,
} from './CreateSessionResponseWrapperCreateSession'

/**
 * Legacy response wrapper structure to allow matching the original
 * configurator web API.
 *
 * TODO: This should be removed after the .Net Core and ScdsClient
 * migration has been completed.
 * @export
 * @interface CreateSessionResponseWrapper
 */
export interface CreateSessionResponseWrapper {
  /**
   *
   * @type {CreateSessionResponseWrapperCreateSession}
   * @memberof CreateSessionResponseWrapper
   */
  createSession: CreateSessionResponseWrapperCreateSession
}

/**
 * Check if a given object implements the CreateSessionResponseWrapper interface.
 */
export function instanceOfCreateSessionResponseWrapper(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'createSession' in value

  return isInstance
}

export function CreateSessionResponseWrapperFromJSON(
  json: any,
): CreateSessionResponseWrapper {
  return CreateSessionResponseWrapperFromJSONTyped(json, false)
}

export function CreateSessionResponseWrapperFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CreateSessionResponseWrapper {
  if (json === undefined || json === null) {
    return json
  }
  return {
    createSession: CreateSessionResponseWrapperCreateSessionFromJSON(
      json['create_session'],
    ),
  }
}

export function CreateSessionResponseWrapperToJSON(
  value?: CreateSessionResponseWrapper | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    create_session: CreateSessionResponseWrapperCreateSessionToJSON(
      value.createSession,
    ),
  }
}
