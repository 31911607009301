/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface UserConfigV2SaveRequest
 */
export interface UserConfigV2SaveRequest {
  /**
   *
   * @type {string}
   * @memberof UserConfigV2SaveRequest
   */
  sessionId: string
  /**
   *
   * @type {number}
   * @memberof UserConfigV2SaveRequest
   */
  userConfigId: number
  /**
   *
   * @type {string}
   * @memberof UserConfigV2SaveRequest
   */
  description: string
}

/**
 * Check if a given object implements the UserConfigV2SaveRequest interface.
 */
export function instanceOfUserConfigV2SaveRequest(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'sessionId' in value
  isInstance = isInstance && 'userConfigId' in value
  isInstance = isInstance && 'description' in value

  return isInstance
}

export function UserConfigV2SaveRequestFromJSON(
  json: any,
): UserConfigV2SaveRequest {
  return UserConfigV2SaveRequestFromJSONTyped(json, false)
}

export function UserConfigV2SaveRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserConfigV2SaveRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    sessionId: json['session_id'],
    userConfigId: json['user_config_id'],
    description: json['description'],
  }
}

export function UserConfigV2SaveRequestToJSON(
  value?: UserConfigV2SaveRequest | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    session_id: value.sessionId,
    user_config_id: value.userConfigId,
    description: value.description,
  }
}
