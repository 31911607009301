/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { FactoryModelV2 } from './FactoryModelV2'
import {
  FactoryModelV2FromJSON,
  FactoryModelV2FromJSONTyped,
  FactoryModelV2ToJSON,
} from './FactoryModelV2'
import type { ItemReadMore } from './ItemReadMore'
import {
  ItemReadMoreFromJSON,
  ItemReadMoreFromJSONTyped,
  ItemReadMoreToJSON,
} from './ItemReadMore'

/**
 *
 * @export
 * @interface FactoryModelsV2Response
 */
export interface FactoryModelsV2Response {
  /**
   *
   * @type {Date}
   * @memberof FactoryModelsV2Response
   */
  cacheTime: Date
  /**
   *
   * @type {Array<FactoryModelV2>}
   * @memberof FactoryModelsV2Response
   */
  models: Array<FactoryModelV2>
  /**
   * This is needed to allow the client to know which Etel Applications
   * that has readmore before displaying the initial Firm Models view.
   *
   * Key: Variant Id.
   *
   * TODO: Consider sending only a hasReadmore boolean flag for each
   * Application on this response object and let the client load the
   * read more resources on demand from: /api/get_readmore
   * or from a simplified version of that request.
   * @type {Array<ItemReadMore>}
   * @memberof FactoryModelsV2Response
   */
  readMore: Array<ItemReadMore>
  /**
   * Short text for all Etel codes
   * @type {{ [key: string]: string; }}
   * @memberof FactoryModelsV2Response
   */
  shortTexts: { [key: string]: string }
  /**
   *
   * @type {string}
   * @memberof FactoryModelsV2Response
   */
  secmLastUpdated?: string | null
}

/**
 * Check if a given object implements the FactoryModelsV2Response interface.
 */
export function instanceOfFactoryModelsV2Response(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'cacheTime' in value
  isInstance = isInstance && 'models' in value
  isInstance = isInstance && 'readMore' in value
  isInstance = isInstance && 'shortTexts' in value

  return isInstance
}

export function FactoryModelsV2ResponseFromJSON(
  json: any,
): FactoryModelsV2Response {
  return FactoryModelsV2ResponseFromJSONTyped(json, false)
}

export function FactoryModelsV2ResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): FactoryModelsV2Response {
  if (json === undefined || json === null) {
    return json
  }
  return {
    cacheTime: new Date(json['CacheTime']),
    models: (json['Models'] as Array<any>).map(FactoryModelV2FromJSON),
    readMore: (json['ReadMore'] as Array<any>).map(ItemReadMoreFromJSON),
    shortTexts: json['ShortTexts'],
    secmLastUpdated: !exists(json, 'SecmLastUpdated')
      ? undefined
      : json['SecmLastUpdated'],
  }
}

export function FactoryModelsV2ResponseToJSON(
  value?: FactoryModelsV2Response | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    CacheTime: value.cacheTime.toISOString(),
    Models: (value.models as Array<any>).map(FactoryModelV2ToJSON),
    ReadMore: (value.readMore as Array<any>).map(ItemReadMoreToJSON),
    ShortTexts: value.shortTexts,
    SecmLastUpdated: value.secmLastUpdated,
  }
}
