import styled, { css } from 'styled-components'
import { SvgScaniaWordmark } from '../../components/SvgImports'
import useTexts from '../../utils/useTexts'
import { SuperCampaignData } from './SuperCampaignData'
import { SuperCampaignFooter } from './SuperCampaignFooter'

const paddingLeftRightPx = 40
const footerHeightPx = 96

interface DesktopBlueFooterAreaRootProps {
  $superCampaignEnabled: boolean
}

const DesktopBlueFooterAreaRoot = styled.div<DesktopBlueFooterAreaRootProps>`
  display: grid;

  ${(props) =>
    props.$superCampaignEnabled
      ? css`
          grid-template-columns: 1fr auto 1fr;
        `
      : css`
          grid-template-columns: 1fr 1fr;
        `}

  grid-column-gap: 8px;
  align-items: center;
  padding-left: ${paddingLeftRightPx}px;
  padding-right: ${paddingLeftRightPx}px;
  background-color: var(--tds-blue-800);
  width: 100%;
  height: 100%;
`

const LogotypeWrapper = styled.div`
  display: flex;
  justify-self: end;

  svg {
    height: 22px;
    width: auto;
    path {
      fill: var(--tds-white);
    }
  }
`

const CopyTextline = styled.p`
  margin: 0;
  white-space: nowrap;
`

interface DesktopFooterRootProps {
  $hideAtScreenMaxWidth: number
}

const DesktopFooterRoot = styled.div<DesktopFooterRootProps>`
  align-items: center;
  background-color: white;
  display: flex;
  height: ${footerHeightPx}px;
  min-height: ${footerHeightPx}px;
  position: relative;
  width: 100%;

  // TODO: Wednesday, consider using the breakpoints in Build Mode instead of
  // the orientation: portrait solution. If that seems to take too much work,
  // allow this component to use two modes for layout, breakpoint by pixel width
  // and the old way.

  @media screen and (max-width: ${(props) => props.$hideAtScreenMaxWidth}px) {
    display: none;
  }
`

interface DesktopFooterProps {
  hideAtScreenMaxWidth: number
  superCampaignData?: SuperCampaignData
}

export function DesktopFooter({
  hideAtScreenMaxWidth: displayAtScreenMaxWidth,
  superCampaignData,
}: DesktopFooterProps): JSX.Element {
  const t = useTexts()
  const copyTextLines = t('SCANIA_COPYRIGHT').trim().split('\n')

  return (
    <DesktopFooterRoot
      data-name="Footer"
      $hideAtScreenMaxWidth={displayAtScreenMaxWidth}
    >
      <DesktopBlueFooterAreaRoot $superCampaignEnabled={!!superCampaignData}>
        <span
          style={{ color: 'var(--tds-grey-600)' }}
          className="tds-detail-05"
        >
          {copyTextLines.map((line, i) => (
            <CopyTextline key={'copy-text-line-' + i + line}>
              {line}
            </CopyTextline>
          ))}
        </span>
        {superCampaignData && <SuperCampaignFooter {...superCampaignData} />}
        <LogotypeWrapper>
          <SvgScaniaWordmark />
        </LogotypeWrapper>
      </DesktopBlueFooterAreaRoot>
    </DesktopFooterRoot>
  )
}
