import { SessionInitDataReduxCompatible } from '../api/startup'

export function buildMenuTexts(
  sessionInitData: SessionInitDataReduxCompatible,
) {
  const submenuTexts: Record<string, string> = {}
  sessionInitData.menuInfo.items.forEach((item) => {
    if (item.shortText) {
      submenuTexts[item.id] = item.shortText
    }
  })
  return submenuTexts
}
