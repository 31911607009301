/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { BuildInfoResponseWrapperBuildInfo } from './BuildInfoResponseWrapperBuildInfo'
import {
  BuildInfoResponseWrapperBuildInfoFromJSON,
  BuildInfoResponseWrapperBuildInfoFromJSONTyped,
  BuildInfoResponseWrapperBuildInfoToJSON,
} from './BuildInfoResponseWrapperBuildInfo'

/**
 * Legacy response wrapper structure to allow matching the original
 * configurator web API.
 *
 * TODO: This should be removed after the .Net Core and ScdsClient
 * migration has been completed.
 * @export
 * @interface BuildInfoResponseWrapper
 */
export interface BuildInfoResponseWrapper {
  /**
   *
   * @type {BuildInfoResponseWrapperBuildInfo}
   * @memberof BuildInfoResponseWrapper
   */
  buildInfo: BuildInfoResponseWrapperBuildInfo
}

/**
 * Check if a given object implements the BuildInfoResponseWrapper interface.
 */
export function instanceOfBuildInfoResponseWrapper(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'buildInfo' in value

  return isInstance
}

export function BuildInfoResponseWrapperFromJSON(
  json: any,
): BuildInfoResponseWrapper {
  return BuildInfoResponseWrapperFromJSONTyped(json, false)
}

export function BuildInfoResponseWrapperFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BuildInfoResponseWrapper {
  if (json === undefined || json === null) {
    return json
  }
  return {
    buildInfo: BuildInfoResponseWrapperBuildInfoFromJSON(json['build_info']),
  }
}

export function BuildInfoResponseWrapperToJSON(
  value?: BuildInfoResponseWrapper | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    build_info: BuildInfoResponseWrapperBuildInfoToJSON(value.buildInfo),
  }
}
