/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface LoginError
 */
export interface LoginError {
  /**
   *
   * @type {string}
   * @memberof LoginError
   */
  status: string
  /**
   *
   * @type {string}
   * @memberof LoginError
   */
  subStatus: string
}

/**
 * Check if a given object implements the LoginError interface.
 */
export function instanceOfLoginError(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'status' in value
  isInstance = isInstance && 'subStatus' in value

  return isInstance
}

export function LoginErrorFromJSON(json: any): LoginError {
  return LoginErrorFromJSONTyped(json, false)
}

export function LoginErrorFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): LoginError {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: json['status'],
    subStatus: json['sub_status'],
  }
}

export function LoginErrorToJSON(value?: LoginError | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    sub_status: value.subStatus,
  }
}
