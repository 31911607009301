/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { BuildInfoApplicationVersion } from './BuildInfoApplicationVersion'
import {
  BuildInfoApplicationVersionFromJSON,
  BuildInfoApplicationVersionFromJSONTyped,
  BuildInfoApplicationVersionToJSON,
} from './BuildInfoApplicationVersion'
import type { SpecificVersionInfo } from './SpecificVersionInfo'
import {
  SpecificVersionInfoFromJSON,
  SpecificVersionInfoFromJSONTyped,
  SpecificVersionInfoToJSON,
} from './SpecificVersionInfo'

/**
 *
 * @export
 * @interface BuildInfoResponseWrapperBuildInfo
 */
export interface BuildInfoResponseWrapperBuildInfo {
  /**
   * Build number, set by Jenkins
   * @type {string}
   * @memberof BuildInfoResponseWrapperBuildInfo
   */
  buildNumber: string
  /**
   * Array of name and version of content (resources and images)
   *
   * TODO: Move this, content and application should be further
   * decoupled.
   * @type {Array<SpecificVersionInfo>}
   * @memberof BuildInfoResponseWrapperBuildInfo
   */
  contentVersions: Array<SpecificVersionInfo>
  /**
   *
   * @type {BuildInfoApplicationVersion}
   * @memberof BuildInfoResponseWrapperBuildInfo
   */
  applicationVersion?: BuildInfoApplicationVersion | null
}

/**
 * Check if a given object implements the BuildInfoResponseWrapperBuildInfo interface.
 */
export function instanceOfBuildInfoResponseWrapperBuildInfo(
  value: object,
): boolean {
  let isInstance = true
  isInstance = isInstance && 'buildNumber' in value
  isInstance = isInstance && 'contentVersions' in value

  return isInstance
}

export function BuildInfoResponseWrapperBuildInfoFromJSON(
  json: any,
): BuildInfoResponseWrapperBuildInfo {
  return BuildInfoResponseWrapperBuildInfoFromJSONTyped(json, false)
}

export function BuildInfoResponseWrapperBuildInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BuildInfoResponseWrapperBuildInfo {
  if (json === undefined || json === null) {
    return json
  }
  return {
    buildNumber: json['BuildNumber'],
    contentVersions: (json['ContentVersions'] as Array<any>).map(
      SpecificVersionInfoFromJSON,
    ),
    applicationVersion: !exists(json, 'ApplicationVersion')
      ? undefined
      : BuildInfoApplicationVersionFromJSON(json['ApplicationVersion']),
  }
}

export function BuildInfoResponseWrapperBuildInfoToJSON(
  value?: BuildInfoResponseWrapperBuildInfo | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    BuildNumber: value.buildNumber,
    ContentVersions: (value.contentVersions as Array<any>).map(
      SpecificVersionInfoToJSON,
    ),
    ApplicationVersion: BuildInfoApplicationVersionToJSON(
      value.applicationVersion,
    ),
  }
}
