/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ScaniaSearchResult } from './ScaniaSearchResult'
import {
  ScaniaSearchResultFromJSON,
  ScaniaSearchResultFromJSONTyped,
  ScaniaSearchResultToJSON,
} from './ScaniaSearchResult'

/**
 *
 * @export
 * @interface ScaniaSearchResponseWrapper
 */
export interface ScaniaSearchResponseWrapper {
  /**
   *
   * @type {Array<ScaniaSearchResult>}
   * @memberof ScaniaSearchResponseWrapper
   */
  scaniaSearch?: Array<ScaniaSearchResult> | null
  /**
   *
   * @type {string}
   * @memberof ScaniaSearchResponseWrapper
   */
  error?: string | null
}

/**
 * Check if a given object implements the ScaniaSearchResponseWrapper interface.
 */
export function instanceOfScaniaSearchResponseWrapper(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function ScaniaSearchResponseWrapperFromJSON(
  json: any,
): ScaniaSearchResponseWrapper {
  return ScaniaSearchResponseWrapperFromJSONTyped(json, false)
}

export function ScaniaSearchResponseWrapperFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ScaniaSearchResponseWrapper {
  if (json === undefined || json === null) {
    return json
  }
  return {
    scaniaSearch: !exists(json, 'scania_search')
      ? undefined
      : json['scania_search'] === null
      ? null
      : (json['scania_search'] as Array<any>).map(ScaniaSearchResultFromJSON),
    error: !exists(json, 'error') ? undefined : json['error'],
  }
}

export function ScaniaSearchResponseWrapperToJSON(
  value?: ScaniaSearchResponseWrapper | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    scania_search:
      value.scaniaSearch === undefined
        ? undefined
        : value.scaniaSearch === null
        ? null
        : (value.scaniaSearch as Array<any>).map(ScaniaSearchResultToJSON),
    error: value.error,
  }
}
