/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { Recipient } from './Recipient'
import {
  RecipientFromJSON,
  RecipientFromJSONTyped,
  RecipientToJSON,
} from './Recipient'

/**
 *
 * @export
 * @interface SendShareEmailParamsShareEmailInfo
 */
export interface SendShareEmailParamsShareEmailInfo {
  /**
   *
   * @type {Array<Recipient>}
   * @memberof SendShareEmailParamsShareEmailInfo
   */
  recipients: Array<Recipient>
  /**
   *
   * @type {string}
   * @memberof SendShareEmailParamsShareEmailInfo
   */
  firstName: string
  /**
   *
   * @type {string}
   * @memberof SendShareEmailParamsShareEmailInfo
   */
  familyName: string
  /**
   *
   * @type {string}
   * @memberof SendShareEmailParamsShareEmailInfo
   */
  userEmail: string
  /**
   *
   * @type {string}
   * @memberof SendShareEmailParamsShareEmailInfo
   */
  personalMessage?: string | null
}

/**
 * Check if a given object implements the SendShareEmailParamsShareEmailInfo interface.
 */
export function instanceOfSendShareEmailParamsShareEmailInfo(
  value: object,
): boolean {
  let isInstance = true
  isInstance = isInstance && 'recipients' in value
  isInstance = isInstance && 'firstName' in value
  isInstance = isInstance && 'familyName' in value
  isInstance = isInstance && 'userEmail' in value

  return isInstance
}

export function SendShareEmailParamsShareEmailInfoFromJSON(
  json: any,
): SendShareEmailParamsShareEmailInfo {
  return SendShareEmailParamsShareEmailInfoFromJSONTyped(json, false)
}

export function SendShareEmailParamsShareEmailInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SendShareEmailParamsShareEmailInfo {
  if (json === undefined || json === null) {
    return json
  }
  return {
    recipients: (json['Recipients'] as Array<any>).map(RecipientFromJSON),
    firstName: json['FirstName'],
    familyName: json['FamilyName'],
    userEmail: json['UserEmail'],
    personalMessage: !exists(json, 'PersonalMessage')
      ? undefined
      : json['PersonalMessage'],
  }
}

export function SendShareEmailParamsShareEmailInfoToJSON(
  value?: SendShareEmailParamsShareEmailInfo | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    Recipients: (value.recipients as Array<any>).map(RecipientToJSON),
    FirstName: value.firstName,
    FamilyName: value.familyName,
    UserEmail: value.userEmail,
    PersonalMessage: value.personalMessage,
  }
}
