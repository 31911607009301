/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface GoStateMarket
 */
export interface GoStateMarket {
  /**
   *
   * @type {number}
   * @memberof GoStateMarket
   */
  value: number
}

/**
 * Check if a given object implements the GoStateMarket interface.
 */
export function instanceOfGoStateMarket(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'value' in value

  return isInstance
}

export function GoStateMarketFromJSON(json: any): GoStateMarket {
  return GoStateMarketFromJSONTyped(json, false)
}

export function GoStateMarketFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GoStateMarket {
  if (json === undefined || json === null) {
    return json
  }
  return {
    value: json['Value'],
  }
}

export function GoStateMarketToJSON(value?: GoStateMarket | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    Value: value.value,
  }
}
