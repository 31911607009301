/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface CreateSessionRequest
 */
export interface CreateSessionRequest {
  /**
   *
   * @type {number}
   * @memberof CreateSessionRequest
   */
  etelLanguage: number
  /**
   *
   * @type {string}
   * @memberof CreateSessionRequest
   */
  existingSessionId?: string | null
  /**
   *
   * @type {number}
   * @memberof CreateSessionRequest
   */
  timeoutSeconds?: number | null
}

/**
 * Check if a given object implements the CreateSessionRequest interface.
 */
export function instanceOfCreateSessionRequest(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'etelLanguage' in value

  return isInstance
}

export function CreateSessionRequestFromJSON(json: any): CreateSessionRequest {
  return CreateSessionRequestFromJSONTyped(json, false)
}

export function CreateSessionRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CreateSessionRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    etelLanguage: json['etel_language'],
    existingSessionId: !exists(json, 'existing_session_id')
      ? undefined
      : json['existing_session_id'],
    timeoutSeconds: !exists(json, 'timeout_seconds')
      ? undefined
      : json['timeout_seconds'],
  }
}

export function CreateSessionRequestToJSON(
  value?: CreateSessionRequest | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    etel_language: value.etelLanguage,
    existing_session_id: value.existingSessionId,
    timeout_seconds: value.timeoutSeconds,
  }
}
