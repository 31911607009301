import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import type { RootState } from './index'
import { NewTruckLoadedInfoRedux } from '../types/TruckLoadedTypes'
import { ConfigChangeResult } from '../api/generated'

export interface TruckInfoState {
  truckInfo: NewTruckLoadedInfoRedux | null
  latestConfigChangeResult: ConfigChangeResult | null
}

const initialState: TruckInfoState = {
  truckInfo: null,
  latestConfigChangeResult: null,
}

export const truckInfoSlice = createSlice({
  name: 'truckInfo',
  initialState,
  reducers: {
    setTruckInfo: (
      state,
      action: PayloadAction<NewTruckLoadedInfoRedux | null>,
    ) => {
      state.truckInfo = action.payload
    },
    setLatestConfigChangeResult: (
      state,
      action: PayloadAction<ConfigChangeResult | null>,
    ) => {
      state.latestConfigChangeResult = action.payload
    },
  },
})

export const { setTruckInfo, setLatestConfigChangeResult } =
  truckInfoSlice.actions

export const getTruckInfoState = (state: RootState) => state.truckInfo.truckInfo
/*
export const getTruckInfoState = (state: RootState): NewTruckLoadedInfo | null => {
  if (!state.truckInfo.truckInfo) {
    return null
  }
  const formattedTruckInfo = {
    ...state.truckInfo.truckInfo,
    timeLoaded: new Date(state.truckInfo.truckInfo?.timeLoaded || ""),
  };
  return formattedTruckInfo;
}
*/

export const getLatestConfigChangeResult = (state: RootState) =>
  state.truckInfo.latestConfigChangeResult

export default truckInfoSlice.reducer
