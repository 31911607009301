/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ResetPasswordError } from './ResetPasswordError'
import {
  ResetPasswordErrorFromJSON,
  ResetPasswordErrorFromJSONTyped,
  ResetPasswordErrorToJSON,
} from './ResetPasswordError'

/**
 * This wrapper is needed to match the old API exactly diring the
 * migration, consider using different models and response codes for
 * success and error cases later.
 * @export
 * @interface ResetPasswordResponseWrapper
 */
export interface ResetPasswordResponseWrapper {
  /**
   *
   * @type {any}
   * @memberof ResetPasswordResponseWrapper
   */
  resetPassword?: any | null
  /**
   *
   * @type {ResetPasswordError}
   * @memberof ResetPasswordResponseWrapper
   */
  error?: ResetPasswordError | null
}

/**
 * Check if a given object implements the ResetPasswordResponseWrapper interface.
 */
export function instanceOfResetPasswordResponseWrapper(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function ResetPasswordResponseWrapperFromJSON(
  json: any,
): ResetPasswordResponseWrapper {
  return ResetPasswordResponseWrapperFromJSONTyped(json, false)
}

export function ResetPasswordResponseWrapperFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ResetPasswordResponseWrapper {
  if (json === undefined || json === null) {
    return json
  }
  return {
    resetPassword: !exists(json, 'reset_password')
      ? undefined
      : json['reset_password'],
    error: !exists(json, 'error')
      ? undefined
      : ResetPasswordErrorFromJSON(json['error']),
  }
}

export function ResetPasswordResponseWrapperToJSON(
  value?: ResetPasswordResponseWrapper | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    reset_password: value.resetPassword,
    error: ResetPasswordErrorToJSON(value.error),
  }
}
