import { useRef } from 'react'
import { BreakpointWidthPx } from '../../css/BreakpointWidthPx'
import { StartButton } from './StartButton'
import styled, { css } from 'styled-components'
import { Size2d } from '../../types/Size2d'
import { useResizeObserver } from '../../utils/useResizeObserver'
import {
  StyledTdsBody01,
  StyledTdsParagraph02,
  StyledTdsHeadline06,
  StyledTdsHeadline07,
} from '../../css/StyledTds'
import { TestElementTypeId } from '../../types/TestAttributeId'

interface SubHeaderAndButtonsContainerProps {
  readonly size: Size2d
}

const IntroPageControlAreaRoot = styled.div<SubHeaderAndButtonsContainerProps>`
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  padding-top: 0;
  position: relative;

  // TODO: Replace with container queries when that has been widely available
  // for long enough.
  // https://caniuse.com/css-container-queries
  // https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Container_Queries
  ${({ size }) => {
    if (size.width <= BreakpointWidthPx.Phone) {
      return css`
        padding-bottom: 16px;
        padding-left: 16px;
        padding-right: 16px;
      `
    } else if (size.width <= BreakpointWidthPx.Tablet) {
      return css`
        padding-bottom: 32px;
        padding-left: 32px;
        padding-right: 32px;
      `
    } else {
      return css`
        padding-bottom: 32px;
        padding-left: 128px;
        padding-right: 128px;
      `
    }
  }}
`

const ControlAreaBody = styled.div`
  position: relative;
  max-width: 800px;
`

interface StartButtonContainerProps {
  readonly $size: Size2d
  readonly $buttonCount: number
}

const StartButtonsContainer = styled.div<StartButtonContainerProps>`
  position: relative;
  width: 100%;
  margin-top: 16px;

  ${(props) =>
    props.$buttonCount > 1
      ? css`
          display: grid;
          grid-row-gap: 12px;
          grid-template-columns: auto auto;
        `
      : css`
          display: flex;
          width: fit-content;
        `}

  // TODO: Replace with container queries when that has been widely available
  // for long enough.
  // https://caniuse.com/css-container-queries
  // https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Container_Queries
  ${({ $size }) => {
    if ($size.width <= BreakpointWidthPx.Tablet) {
      return css`
        grid-column-gap: 16px;
      `
    } else {
      return css`
        grid-column-gap: 32px;
      `
    }
  }}
`

interface StartButtonParagraphProps {
  $size: Size2d
}

const StartButtonsParagraph = styled.span<StartButtonParagraphProps>`
  position: relative;
  user-select: none;
  color: white;

  ${({ $size }) => {
    if ($size.width <= BreakpointWidthPx.Phone) {
      return StyledTdsBody01
    } else {
      return StyledTdsParagraph02
    }
  }}
`

const StartButtonsCampaignParagraph = styled.p<StartButtonParagraphProps>`
  margin-top: 24px;
  margin-bottom: 0;
  position: relative;
  user-select: none;
  color: white;

  a {
    color: white;
  }

  ${({ $size }) => {
    if ($size.width <= BreakpointWidthPx.Phone) {
      return StyledTdsHeadline07
    } else {
      return StyledTdsHeadline06
    }
  }}

  text-decoration: underline;
`
interface SubHeaderProps {
  readonly $size: Size2d
}

const SubHeader = styled.h1<SubHeaderProps>`
  color: white;
  position: relative;
  user-select: none;
  margin: 0.5em 0px !important;

  // TODO: Replace with container queries when that has been widely available
  // for long enough.
  // https://caniuse.com/css-container-queries
  // https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Container_Queries
  ${({ $size }) => {
    if ($size.width <= BreakpointWidthPx.Phone) {
      return css`
        font-size: 1.5rem;
        line-height: 1.5rem;
      `
    } else if ($size.width <= BreakpointWidthPx.Tablet) {
      return css`
        font-size: 2rem;
        line-height: 2rem;
      `
    } else {
      return css``
    }
  }}
`

export interface StartButtonParams {
  handleClick: () => void
  testId: TestElementTypeId
  text: string
}

/**
 * The Hidden variant is intended for rendering an empty space with the size
 * equal to the campaign link as if it was visible and enabled. This helps with
 * matching the height of the two button areas in the split-screen layout mode
 * of the intro page.
 */
export enum CampaignLinkMode {
  Disabled,
  Enabled,
  Hidden,
}

export interface IntroPageControlAreaParams {
  buttonOne: StartButtonParams
  buttonTwo: StartButtonParams | null
  campaignLinkMode: CampaignLinkMode
  campaignLinkText: string
  campaignLinkUrl: string
  paragraphText: string
  subHeaderText: string
  smallScreenBreakpoint: number
}

export function IntroPageControlArea({
  buttonOne,
  buttonTwo,
  campaignLinkMode,
  campaignLinkText,
  campaignLinkUrl,
  paragraphText,
  subHeaderText,
  smallScreenBreakpoint,
}: IntroPageControlAreaParams) {
  const rootRef = useRef<HTMLDivElement | null>(null)

  // TODO: Replace with container queries when that has been widely available
  // for long enough.
  // https://caniuse.com/css-container-queries
  // https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Container_Queries
  const size = useResizeObserver<Size2d>(rootRef, { width: 0, height: 0 })
  const isSmallScreen = window.screen.width <= smallScreenBreakpoint

  return (
    <IntroPageControlAreaRoot ref={rootRef} size={size}>
      <ControlAreaBody>
        <SubHeader $size={size}>{subHeaderText}</SubHeader>
        <StartButtonsParagraph $size={size}>
          {paragraphText}
        </StartButtonsParagraph>
        <StartButtonsContainer $size={size} $buttonCount={buttonTwo ? 2 : 1}>
          <StartButton
            handleClick={buttonOne.handleClick}
            primary={true}
            testId={buttonOne.testId}
            text={buttonOne.text}
          />
          {buttonTwo && (
            <StartButton
              handleClick={buttonTwo.handleClick}
              primary={false}
              testId={buttonTwo.testId}
              text={buttonTwo.text}
            />
          )}
        </StartButtonsContainer>
        {campaignLinkMode !== CampaignLinkMode.Disabled && (
          <StartButtonsCampaignParagraph
            $size={size}
            style={{
              visibility:
                campaignLinkMode === CampaignLinkMode.Enabled
                  ? 'visible'
                  : 'hidden',
              display:
                campaignLinkMode === CampaignLinkMode.Hidden && isSmallScreen
                  ? 'none'
                  : 'block',
            }}
          >
            <a href={campaignLinkUrl} target="_blank" rel="noreferrer">
              {campaignLinkText}
            </a>
          </StartButtonsCampaignParagraph>
        )}
      </ControlAreaBody>
    </IntroPageControlAreaRoot>
  )
}
