/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ScaniaGetBuildModeEtelInfoResponseWrapperScaniaGetBuildModeEtelInfo } from './ScaniaGetBuildModeEtelInfoResponseWrapperScaniaGetBuildModeEtelInfo'
import {
  ScaniaGetBuildModeEtelInfoResponseWrapperScaniaGetBuildModeEtelInfoFromJSON,
  ScaniaGetBuildModeEtelInfoResponseWrapperScaniaGetBuildModeEtelInfoFromJSONTyped,
  ScaniaGetBuildModeEtelInfoResponseWrapperScaniaGetBuildModeEtelInfoToJSON,
} from './ScaniaGetBuildModeEtelInfoResponseWrapperScaniaGetBuildModeEtelInfo'

/**
 *
 * @export
 * @interface ScaniaGetBuildModeEtelInfoResponseWrapper
 */
export interface ScaniaGetBuildModeEtelInfoResponseWrapper {
  /**
   *
   * @type {ScaniaGetBuildModeEtelInfoResponseWrapperScaniaGetBuildModeEtelInfo}
   * @memberof ScaniaGetBuildModeEtelInfoResponseWrapper
   */
  scaniaGetBuildModeEtelInfo?: ScaniaGetBuildModeEtelInfoResponseWrapperScaniaGetBuildModeEtelInfo | null
  /**
   *
   * @type {string}
   * @memberof ScaniaGetBuildModeEtelInfoResponseWrapper
   */
  error?: string | null
}

/**
 * Check if a given object implements the ScaniaGetBuildModeEtelInfoResponseWrapper interface.
 */
export function instanceOfScaniaGetBuildModeEtelInfoResponseWrapper(
  value: object,
): boolean {
  let isInstance = true

  return isInstance
}

export function ScaniaGetBuildModeEtelInfoResponseWrapperFromJSON(
  json: any,
): ScaniaGetBuildModeEtelInfoResponseWrapper {
  return ScaniaGetBuildModeEtelInfoResponseWrapperFromJSONTyped(json, false)
}

export function ScaniaGetBuildModeEtelInfoResponseWrapperFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ScaniaGetBuildModeEtelInfoResponseWrapper {
  if (json === undefined || json === null) {
    return json
  }
  return {
    scaniaGetBuildModeEtelInfo: !exists(json, 'scania_get_build_mode_etel_info')
      ? undefined
      : ScaniaGetBuildModeEtelInfoResponseWrapperScaniaGetBuildModeEtelInfoFromJSON(
          json['scania_get_build_mode_etel_info'],
        ),
    error: !exists(json, 'error') ? undefined : json['error'],
  }
}

export function ScaniaGetBuildModeEtelInfoResponseWrapperToJSON(
  value?: ScaniaGetBuildModeEtelInfoResponseWrapper | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    scania_get_build_mode_etel_info:
      ScaniaGetBuildModeEtelInfoResponseWrapperScaniaGetBuildModeEtelInfoToJSON(
        value.scaniaGetBuildModeEtelInfo,
      ),
    error: value.error,
  }
}
