import { Configuration, ScaniaConfiguratorApi } from './generated'

export function buildBackendUrl(): string {
  const serviceUrlFromLocalStorage = localStorage.getItem('scds_backend_url')
  if (serviceUrlFromLocalStorage) {
    console.log(
      `Using "${serviceUrlFromLocalStorage}" from url. Make sure first subdirectory also is included. E.g. "/scds_backend".`,
    )
  }

  // Old default: `${document.location.protocol}//${document.location.hostname}:${document.location.port}${'/scds_backend'}`
  const defaultBackendUrl = document.location.origin + '/scds_backend'

  return serviceUrlFromLocalStorage?.replace(/\/+$/, '') || defaultBackendUrl
}

export function buildClient(basePath: string): ScaniaConfiguratorApi {
  const apiConfig = new Configuration({
    basePath,
  })
  return new ScaniaConfiguratorApi(apiConfig)
}
