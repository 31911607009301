/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type {
  BuildInfoResponseWrapper,
  ChangeConfigRequest,
  ConfigChangeResponseWrapper,
  CreateSessionRequest,
  CreateSessionResponseWrapper,
  CreateUserRequest,
  CreateUserResponseWrapper,
  FactoryModelImageResponse,
  FactoryModelSummary,
  FactoryModelsV2Response,
  FlexImageResult,
  FlexImageV2Request,
  GetCurrentConfigImageRequest,
  GetCurrentConfigImageResponseWrapper,
  GetDealersResponseWrapper,
  GetFactoryModelFlexImageRequest,
  GetInitialConsequenceOfChangeRequest,
  GetInitialConsequenceOfChangeResponseWrapper,
  GetReadmoreRequest,
  GetScaniaCultureInfoResponseWrapper,
  GetStatelessScaniaSystemInfoResponseWrapper,
  GetUserConsentsResponseWrapper,
  GoWizardCloseResponseWrapper,
  GoWizardContinueV2ResponseWrapper,
  GoWizardStartResponseWrapper,
  GowizardChangeSliderRequest,
  GowizardContinueV2Request,
  GowizardStartRequest,
  GowizardUndoV2Request,
  GuidedOfferingInfo,
  HeartbeatResponseWrapper,
  LoadConfigFromStringRequest,
  LoadConfigFromStringResponseWrapper,
  LoginRequest,
  LoginResponseWrapper,
  LogoutResponseWrapper,
  PrepareRfqResponse,
  PublicConfigV2InfoResponseWrapper,
  PublicConfigV2SaveResponseWrapper,
  QrCodeFileType,
  ReadMoreResources,
  ResetPasswordRequest,
  ResetPasswordResponseWrapper,
  ScaniaGetBuildModeEtelInfoResponseWrapper,
  ScaniaImportXmlRequest,
  ScaniaImportXmlResponseWrapper,
  ScaniaSearchResponseWrapper,
  SendChangesToEysRequest,
  SendResetPasswordMailRequest,
  SendResetPasswordMailResponseWrapper,
  SendRfqRequest,
  SendShareMailRequest,
  SetUserGdprConsentRequest,
  SetUserGdprConsentResponse,
  TryChangeConfigRequest,
  TryChangeConfigResponseWrapper,
  UserConfigV2ListResponseWrapper,
  UserConfigV2SaveAsRequest,
  UserConfigV2SaveAsResponseWrapper,
  UserConfigV2SaveRequest,
  UserConfigV2SaveResponseWrapper,
} from '../models'
import {
  BuildInfoResponseWrapperFromJSON,
  BuildInfoResponseWrapperToJSON,
  ChangeConfigRequestFromJSON,
  ChangeConfigRequestToJSON,
  ConfigChangeResponseWrapperFromJSON,
  ConfigChangeResponseWrapperToJSON,
  CreateSessionRequestFromJSON,
  CreateSessionRequestToJSON,
  CreateSessionResponseWrapperFromJSON,
  CreateSessionResponseWrapperToJSON,
  CreateUserRequestFromJSON,
  CreateUserRequestToJSON,
  CreateUserResponseWrapperFromJSON,
  CreateUserResponseWrapperToJSON,
  FactoryModelImageResponseFromJSON,
  FactoryModelImageResponseToJSON,
  FactoryModelSummaryFromJSON,
  FactoryModelSummaryToJSON,
  FactoryModelsV2ResponseFromJSON,
  FactoryModelsV2ResponseToJSON,
  FlexImageResultFromJSON,
  FlexImageResultToJSON,
  FlexImageV2RequestFromJSON,
  FlexImageV2RequestToJSON,
  GetCurrentConfigImageRequestFromJSON,
  GetCurrentConfigImageRequestToJSON,
  GetCurrentConfigImageResponseWrapperFromJSON,
  GetCurrentConfigImageResponseWrapperToJSON,
  GetDealersResponseWrapperFromJSON,
  GetDealersResponseWrapperToJSON,
  GetFactoryModelFlexImageRequestFromJSON,
  GetFactoryModelFlexImageRequestToJSON,
  GetInitialConsequenceOfChangeRequestFromJSON,
  GetInitialConsequenceOfChangeRequestToJSON,
  GetInitialConsequenceOfChangeResponseWrapperFromJSON,
  GetInitialConsequenceOfChangeResponseWrapperToJSON,
  GetReadmoreRequestFromJSON,
  GetReadmoreRequestToJSON,
  GetScaniaCultureInfoResponseWrapperFromJSON,
  GetScaniaCultureInfoResponseWrapperToJSON,
  GetStatelessScaniaSystemInfoResponseWrapperFromJSON,
  GetStatelessScaniaSystemInfoResponseWrapperToJSON,
  GetUserConsentsResponseWrapperFromJSON,
  GetUserConsentsResponseWrapperToJSON,
  GoWizardCloseResponseWrapperFromJSON,
  GoWizardCloseResponseWrapperToJSON,
  GoWizardContinueV2ResponseWrapperFromJSON,
  GoWizardContinueV2ResponseWrapperToJSON,
  GoWizardStartResponseWrapperFromJSON,
  GoWizardStartResponseWrapperToJSON,
  GowizardChangeSliderRequestFromJSON,
  GowizardChangeSliderRequestToJSON,
  GowizardContinueV2RequestFromJSON,
  GowizardContinueV2RequestToJSON,
  GowizardStartRequestFromJSON,
  GowizardStartRequestToJSON,
  GowizardUndoV2RequestFromJSON,
  GowizardUndoV2RequestToJSON,
  GuidedOfferingInfoFromJSON,
  GuidedOfferingInfoToJSON,
  HeartbeatResponseWrapperFromJSON,
  HeartbeatResponseWrapperToJSON,
  LoadConfigFromStringRequestFromJSON,
  LoadConfigFromStringRequestToJSON,
  LoadConfigFromStringResponseWrapperFromJSON,
  LoadConfigFromStringResponseWrapperToJSON,
  LoginRequestFromJSON,
  LoginRequestToJSON,
  LoginResponseWrapperFromJSON,
  LoginResponseWrapperToJSON,
  LogoutResponseWrapperFromJSON,
  LogoutResponseWrapperToJSON,
  PrepareRfqResponseFromJSON,
  PrepareRfqResponseToJSON,
  PublicConfigV2InfoResponseWrapperFromJSON,
  PublicConfigV2InfoResponseWrapperToJSON,
  PublicConfigV2SaveResponseWrapperFromJSON,
  PublicConfigV2SaveResponseWrapperToJSON,
  QrCodeFileTypeFromJSON,
  QrCodeFileTypeToJSON,
  ReadMoreResourcesFromJSON,
  ReadMoreResourcesToJSON,
  ResetPasswordRequestFromJSON,
  ResetPasswordRequestToJSON,
  ResetPasswordResponseWrapperFromJSON,
  ResetPasswordResponseWrapperToJSON,
  ScaniaGetBuildModeEtelInfoResponseWrapperFromJSON,
  ScaniaGetBuildModeEtelInfoResponseWrapperToJSON,
  ScaniaImportXmlRequestFromJSON,
  ScaniaImportXmlRequestToJSON,
  ScaniaImportXmlResponseWrapperFromJSON,
  ScaniaImportXmlResponseWrapperToJSON,
  ScaniaSearchResponseWrapperFromJSON,
  ScaniaSearchResponseWrapperToJSON,
  SendChangesToEysRequestFromJSON,
  SendChangesToEysRequestToJSON,
  SendResetPasswordMailRequestFromJSON,
  SendResetPasswordMailRequestToJSON,
  SendResetPasswordMailResponseWrapperFromJSON,
  SendResetPasswordMailResponseWrapperToJSON,
  SendRfqRequestFromJSON,
  SendRfqRequestToJSON,
  SendShareMailRequestFromJSON,
  SendShareMailRequestToJSON,
  SetUserGdprConsentRequestFromJSON,
  SetUserGdprConsentRequestToJSON,
  SetUserGdprConsentResponseFromJSON,
  SetUserGdprConsentResponseToJSON,
  TryChangeConfigRequestFromJSON,
  TryChangeConfigRequestToJSON,
  TryChangeConfigResponseWrapperFromJSON,
  TryChangeConfigResponseWrapperToJSON,
  UserConfigV2ListResponseWrapperFromJSON,
  UserConfigV2ListResponseWrapperToJSON,
  UserConfigV2SaveAsRequestFromJSON,
  UserConfigV2SaveAsRequestToJSON,
  UserConfigV2SaveAsResponseWrapperFromJSON,
  UserConfigV2SaveAsResponseWrapperToJSON,
  UserConfigV2SaveRequestFromJSON,
  UserConfigV2SaveRequestToJSON,
  UserConfigV2SaveResponseWrapperFromJSON,
  UserConfigV2SaveResponseWrapperToJSON,
} from '../models'

export interface ChangeConfigOperationRequest {
  changeConfigRequest: ChangeConfigRequest
}

export interface ConfigStorageImageRequest {
  imageId: number
}

export interface CreateSessionOperationRequest {
  etelMarket: number
  createSessionRequest: CreateSessionRequest
}

export interface CreateUserOperationRequest {
  createUserRequest: CreateUserRequest
}

export interface FlexImageV2OperationRequest {
  flexImageV2Request: FlexImageV2Request
}

export interface GeneratePdfRequest {
  sessionId: string
  language: number
  summaryFilter: string
  title: string
}

export interface GetCurrentConfigImageOperationRequest {
  getCurrentConfigImageRequest: GetCurrentConfigImageRequest
}

export interface GetDealersRequest {
  countryCode: string
}

export interface GetFactoryModelFlexImageOperationRequest {
  getFactoryModelFlexImageRequest: GetFactoryModelFlexImageRequest
}

export interface GetFactoryModelImageRequest {
  etelMarket: number
  factoryModelId: number
  scaledWidth: number
  scaledHeight: number
}

export interface GetFactoryModelSummaryRequest {
  etelMarket: number
  etelLanguage: number
  factoryModel: number
}

export interface GetFactoryModelsV2Request {
  etelMarket: number
  etelLanguage: number
  usePreviewText: boolean
}

export interface GetInitialConsequenceOfChangeOperationRequest {
  getInitialConsequenceOfChangeRequest: GetInitialConsequenceOfChangeRequest
}

export interface GetLatestConfigChangeResultRequest {
  sessionId: string
}

export interface GetReadmoreOperationRequest {
  getReadmoreRequest: GetReadmoreRequest
}

export interface GetScaniaCultureInfoRequest {
  etelLanguage: number
  etelMarket: number
}

export interface GetUserConsentsRequest {
  sessionId: string
}

export interface GowizardChangeSliderOperationRequest {
  gowizardChangeSliderRequest: GowizardChangeSliderRequest
}

export interface GowizardCloseRequest {
  gowizardUndoV2Request: GowizardUndoV2Request
}

export interface GowizardContinueV2OperationRequest {
  gowizardContinueV2Request: GowizardContinueV2Request
}

export interface GowizardStartOperationRequest {
  gowizardStartRequest: GowizardStartRequest
}

export interface GowizardUndoV2OperationRequest {
  gowizardUndoV2Request: GowizardUndoV2Request
}

export interface HeartbeatRequest {
  sessionId: string
}

export interface LoadConfigFromStringOperationRequest {
  loadConfigFromStringRequest: LoadConfigFromStringRequest
}

export interface LoginOperationRequest {
  loginRequest: LoginRequest
}

export interface LogoutRequest {
  sessionId: string
}

export interface PrepareRfqRequest {
  sessionId: string
}

export interface PublicConfigQrCodeRequest {
  id: string
  type: QrCodeFileType
  etelMarket: number
  etelLanguage: number
  download?: boolean
}

export interface PublicConfigV2InfoRequest {
  publicConfigId: string
}

export interface PublicConfigV2LoadRequest {
  sessionId: string
  publicConfigId: string
}

export interface PublicConfigV2SaveRequest {
  sessionId: string
  description: string
}

export interface ResetPasswordOperationRequest {
  resetPasswordRequest: ResetPasswordRequest
}

export interface ScaniaExportXmlRequest {
  sessionId?: string
}

export interface ScaniaGetBuildModeEtelInfoRequest {
  sessionId: string
}

export interface ScaniaImportXmlOperationRequest {
  scaniaImportXmlRequest: ScaniaImportXmlRequest
}

export interface ScaniaSearchRequest {
  sessionId: string
  searchText: string
  market: number
  language: number
}

export interface SendChangesToEysOperationRequest {
  sendChangesToEysRequest: SendChangesToEysRequest
}

export interface SendDeleteUserMailRequest {
  sessionId: string
}

export interface SendResetPasswordMailOperationRequest {
  sendResetPasswordMailRequest: SendResetPasswordMailRequest
}

export interface SendRfqOperationRequest {
  sendRfqRequest: SendRfqRequest
}

export interface SendShareMailOperationRequest {
  sendShareMailRequest: SendShareMailRequest
}

export interface SetUserGdprConsentOperationRequest {
  setUserGdprConsentRequest: SetUserGdprConsentRequest
}

export interface StartLoginRequest {
  originalUrl: string
}

export interface TryChangeConfigOperationRequest {
  tryChangeConfigRequest: TryChangeConfigRequest
}

export interface UserConfigV2ChangeNameRequest {
  sessionId: string
  userConfigId: number
  newName: string
}

export interface UserConfigV2DeleteRequest {
  sessionId: string
  userConfigId: number
}

export interface UserConfigV2ListRequest {
  sessionId: string
}

export interface UserConfigV2LoadRequest {
  sessionId: string
  userConfigId: number
}

export interface UserConfigV2SaveOperationRequest {
  userConfigV2SaveRequest: UserConfigV2SaveRequest
}

export interface UserConfigV2SaveAsOperationRequest {
  userConfigV2SaveAsRequest: UserConfigV2SaveAsRequest
}

/**
 *
 */
export class ScaniaConfiguratorApi extends runtime.BaseAPI {
  /**
   * Returns info about the build number of NOVA and the content package versions
   */
  async buildInfoRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<BuildInfoResponseWrapper>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password)
    }
    const response = await this.request(
      {
        path: `/api/build_info`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BuildInfoResponseWrapperFromJSON(jsonValue),
    )
  }

  /**
   * Returns info about the build number of NOVA and the content package versions
   */
  async buildInfo(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<BuildInfoResponseWrapper> {
    const response = await this.buildInfoRaw(initOverrides)
    return await response.value()
  }

  /**
   * Will try to change the configuration state for a session.                Should only be called with a suggestion returned from  attempt_bip_v2, since that is to only way to make sure the  requested change is valid.                The normal flow is to make attempt_bip_v2 request for all  configuration changes first and then take different actions  depending on the response.
   */
  async changeConfigRaw(
    requestParameters: ChangeConfigOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ConfigChangeResponseWrapper>> {
    if (
      requestParameters.changeConfigRequest === null ||
      requestParameters.changeConfigRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'changeConfigRequest',
        'Required parameter requestParameters.changeConfigRequest was null or undefined when calling changeConfig.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password)
    }
    const response = await this.request(
      {
        path: `/api/change_config`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ChangeConfigRequestToJSON(requestParameters.changeConfigRequest),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ConfigChangeResponseWrapperFromJSON(jsonValue),
    )
  }

  /**
   * Will try to change the configuration state for a session.                Should only be called with a suggestion returned from  attempt_bip_v2, since that is to only way to make sure the  requested change is valid.                The normal flow is to make attempt_bip_v2 request for all  configuration changes first and then take different actions  depending on the response.
   */
  async changeConfig(
    changeConfigRequest: ChangeConfigRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ConfigChangeResponseWrapper> {
    const response = await this.changeConfigRaw(
      { changeConfigRequest: changeConfigRequest },
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async configStorageImageRaw(
    requestParameters: ConfigStorageImageRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.imageId === null ||
      requestParameters.imageId === undefined
    ) {
      throw new runtime.RequiredError(
        'imageId',
        'Required parameter requestParameters.imageId was null or undefined when calling configStorageImage.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.imageId !== undefined) {
      queryParameters['image_id'] = requestParameters.imageId
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password)
    }
    const response = await this.request(
      {
        path: `/config_storage_image`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.VoidApiResponse(response)
  }

  /**
   */
  async configStorageImage(
    imageId: number,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.configStorageImageRaw({ imageId: imageId }, initOverrides)
  }

  /**
   * Create a new configurator session. A session contains at most one  active configuration.
   */
  async createSessionRaw(
    requestParameters: CreateSessionOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<CreateSessionResponseWrapper>> {
    if (
      requestParameters.etelMarket === null ||
      requestParameters.etelMarket === undefined
    ) {
      throw new runtime.RequiredError(
        'etelMarket',
        'Required parameter requestParameters.etelMarket was null or undefined when calling createSession.',
      )
    }

    if (
      requestParameters.createSessionRequest === null ||
      requestParameters.createSessionRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'createSessionRequest',
        'Required parameter requestParameters.createSessionRequest was null or undefined when calling createSession.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.etelMarket !== undefined) {
      queryParameters['etel_market'] = requestParameters.etelMarket
    }

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password)
    }
    const response = await this.request(
      {
        path: `/api/create_session`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateSessionRequestToJSON(
          requestParameters.createSessionRequest,
        ),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CreateSessionResponseWrapperFromJSON(jsonValue),
    )
  }

  /**
   * Create a new configurator session. A session contains at most one  active configuration.
   */
  async createSession(
    etelMarket: number,
    createSessionRequest: CreateSessionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<CreateSessionResponseWrapper> {
    const response = await this.createSessionRaw(
      { etelMarket: etelMarket, createSessionRequest: createSessionRequest },
      initOverrides,
    )
    return await response.value()
  }

  /**
   * Creates a new user.
   */
  async createUserRaw(
    requestParameters: CreateUserOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<CreateUserResponseWrapper>> {
    if (
      requestParameters.createUserRequest === null ||
      requestParameters.createUserRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'createUserRequest',
        'Required parameter requestParameters.createUserRequest was null or undefined when calling createUser.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password)
    }
    const response = await this.request(
      {
        path: `/api/create_user`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateUserRequestToJSON(requestParameters.createUserRequest),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CreateUserResponseWrapperFromJSON(jsonValue),
    )
  }

  /**
   * Creates a new user.
   */
  async createUser(
    createUserRequest: CreateUserRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<CreateUserResponseWrapper> {
    const response = await this.createUserRaw(
      { createUserRequest: createUserRequest },
      initOverrides,
    )
    return await response.value()
  }

  /**
   * Replacement for the original flex_image. TODO: Write documentation  with examples and remove the _v2 suffix when the original  flex_image is gone.
   */
  async flexImageV2Raw(
    requestParameters: FlexImageV2OperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<FlexImageResult>> {
    if (
      requestParameters.flexImageV2Request === null ||
      requestParameters.flexImageV2Request === undefined
    ) {
      throw new runtime.RequiredError(
        'flexImageV2Request',
        'Required parameter requestParameters.flexImageV2Request was null or undefined when calling flexImageV2.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password)
    }
    const response = await this.request(
      {
        path: `/api/flex_image_v2`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: FlexImageV2RequestToJSON(requestParameters.flexImageV2Request),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      FlexImageResultFromJSON(jsonValue),
    )
  }

  /**
   * Replacement for the original flex_image. TODO: Write documentation  with examples and remove the _v2 suffix when the original  flex_image is gone.
   */
  async flexImageV2(
    flexImageV2Request: FlexImageV2Request,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<FlexImageResult> {
    const response = await this.flexImageV2Raw(
      { flexImageV2Request: flexImageV2Request },
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async generatePdfRaw(
    requestParameters: GeneratePdfRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.sessionId === null ||
      requestParameters.sessionId === undefined
    ) {
      throw new runtime.RequiredError(
        'sessionId',
        'Required parameter requestParameters.sessionId was null or undefined when calling generatePdf.',
      )
    }

    if (
      requestParameters.language === null ||
      requestParameters.language === undefined
    ) {
      throw new runtime.RequiredError(
        'language',
        'Required parameter requestParameters.language was null or undefined when calling generatePdf.',
      )
    }

    if (
      requestParameters.summaryFilter === null ||
      requestParameters.summaryFilter === undefined
    ) {
      throw new runtime.RequiredError(
        'summaryFilter',
        'Required parameter requestParameters.summaryFilter was null or undefined when calling generatePdf.',
      )
    }

    if (
      requestParameters.title === null ||
      requestParameters.title === undefined
    ) {
      throw new runtime.RequiredError(
        'title',
        'Required parameter requestParameters.title was null or undefined when calling generatePdf.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.sessionId !== undefined) {
      queryParameters['session_id'] = requestParameters.sessionId
    }

    if (requestParameters.language !== undefined) {
      queryParameters['language'] = requestParameters.language
    }

    if (requestParameters.summaryFilter !== undefined) {
      queryParameters['summary_filter'] = requestParameters.summaryFilter
    }

    if (requestParameters.title !== undefined) {
      queryParameters['title'] = requestParameters.title
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password)
    }
    const response = await this.request(
      {
        path: `/generate_pdf`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.VoidApiResponse(response)
  }

  /**
   */
  async generatePdf(
    sessionId: string,
    language: number,
    summaryFilter: string,
    title: string,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.generatePdfRaw(
      {
        sessionId: sessionId,
        language: language,
        summaryFilter: summaryFilter,
        title: title,
      },
      initOverrides,
    )
  }

  /**
   */
  async getCurrentConfigImageRaw(
    requestParameters: GetCurrentConfigImageOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<GetCurrentConfigImageResponseWrapper>> {
    if (
      requestParameters.getCurrentConfigImageRequest === null ||
      requestParameters.getCurrentConfigImageRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'getCurrentConfigImageRequest',
        'Required parameter requestParameters.getCurrentConfigImageRequest was null or undefined when calling getCurrentConfigImage.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password)
    }
    const response = await this.request(
      {
        path: `/api/get_current_config_image`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: GetCurrentConfigImageRequestToJSON(
          requestParameters.getCurrentConfigImageRequest,
        ),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetCurrentConfigImageResponseWrapperFromJSON(jsonValue),
    )
  }

  /**
   */
  async getCurrentConfigImage(
    getCurrentConfigImageRequest: GetCurrentConfigImageRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<GetCurrentConfigImageResponseWrapper> {
    const response = await this.getCurrentConfigImageRaw(
      { getCurrentConfigImageRequest: getCurrentConfigImageRequest },
      initOverrides,
    )
    return await response.value()
  }

  /**
   * Provides a cached version of the dealer information from the  upstream dealer service.
   */
  async getDealersRaw(
    requestParameters: GetDealersRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<GetDealersResponseWrapper>> {
    if (
      requestParameters.countryCode === null ||
      requestParameters.countryCode === undefined
    ) {
      throw new runtime.RequiredError(
        'countryCode',
        'Required parameter requestParameters.countryCode was null or undefined when calling getDealers.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.countryCode !== undefined) {
      queryParameters['country_code'] = requestParameters.countryCode
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password)
    }
    const response = await this.request(
      {
        path: `/api/get_dealers`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetDealersResponseWrapperFromJSON(jsonValue),
    )
  }

  /**
   * Provides a cached version of the dealer information from the  upstream dealer service.
   */
  async getDealers(
    countryCode: string,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<GetDealersResponseWrapper> {
    const response = await this.getDealersRaw(
      { countryCode: countryCode },
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async getFactoryModelFlexImageRaw(
    requestParameters: GetFactoryModelFlexImageOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<FactoryModelImageResponse>> {
    if (
      requestParameters.getFactoryModelFlexImageRequest === null ||
      requestParameters.getFactoryModelFlexImageRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'getFactoryModelFlexImageRequest',
        'Required parameter requestParameters.getFactoryModelFlexImageRequest was null or undefined when calling getFactoryModelFlexImage.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password)
    }
    const response = await this.request(
      {
        path: `/api/get_factory_model_flex_image`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: GetFactoryModelFlexImageRequestToJSON(
          requestParameters.getFactoryModelFlexImageRequest,
        ),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      FactoryModelImageResponseFromJSON(jsonValue),
    )
  }

  /**
   */
  async getFactoryModelFlexImage(
    getFactoryModelFlexImageRequest: GetFactoryModelFlexImageRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<FactoryModelImageResponse> {
    const response = await this.getFactoryModelFlexImageRaw(
      { getFactoryModelFlexImageRequest: getFactoryModelFlexImageRequest },
      initOverrides,
    )
    return await response.value()
  }

  /**
   *
   */
  async getFactoryModelImageRaw(
    requestParameters: GetFactoryModelImageRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<FactoryModelImageResponse>> {
    if (
      requestParameters.etelMarket === null ||
      requestParameters.etelMarket === undefined
    ) {
      throw new runtime.RequiredError(
        'etelMarket',
        'Required parameter requestParameters.etelMarket was null or undefined when calling getFactoryModelImage.',
      )
    }

    if (
      requestParameters.factoryModelId === null ||
      requestParameters.factoryModelId === undefined
    ) {
      throw new runtime.RequiredError(
        'factoryModelId',
        'Required parameter requestParameters.factoryModelId was null or undefined when calling getFactoryModelImage.',
      )
    }

    if (
      requestParameters.scaledWidth === null ||
      requestParameters.scaledWidth === undefined
    ) {
      throw new runtime.RequiredError(
        'scaledWidth',
        'Required parameter requestParameters.scaledWidth was null or undefined when calling getFactoryModelImage.',
      )
    }

    if (
      requestParameters.scaledHeight === null ||
      requestParameters.scaledHeight === undefined
    ) {
      throw new runtime.RequiredError(
        'scaledHeight',
        'Required parameter requestParameters.scaledHeight was null or undefined when calling getFactoryModelImage.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.etelMarket !== undefined) {
      queryParameters['etel_market'] = requestParameters.etelMarket
    }

    if (requestParameters.factoryModelId !== undefined) {
      queryParameters['factory_model_id'] = requestParameters.factoryModelId
    }

    if (requestParameters.scaledWidth !== undefined) {
      queryParameters['scaled_width'] = requestParameters.scaledWidth
    }

    if (requestParameters.scaledHeight !== undefined) {
      queryParameters['scaled_height'] = requestParameters.scaledHeight
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password)
    }
    const response = await this.request(
      {
        path: `/api/get_factory_model_image`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      FactoryModelImageResponseFromJSON(jsonValue),
    )
  }

  /**
   *
   */
  async getFactoryModelImage(
    etelMarket: number,
    factoryModelId: number,
    scaledWidth: number,
    scaledHeight: number,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<FactoryModelImageResponse> {
    const response = await this.getFactoryModelImageRaw(
      {
        etelMarket: etelMarket,
        factoryModelId: factoryModelId,
        scaledWidth: scaledWidth,
        scaledHeight: scaledHeight,
      },
      initOverrides,
    )
    return await response.value()
  }

  /**
   * Generate a summary for a factory model.
   */
  async getFactoryModelSummaryRaw(
    requestParameters: GetFactoryModelSummaryRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<FactoryModelSummary>> {
    if (
      requestParameters.etelMarket === null ||
      requestParameters.etelMarket === undefined
    ) {
      throw new runtime.RequiredError(
        'etelMarket',
        'Required parameter requestParameters.etelMarket was null or undefined when calling getFactoryModelSummary.',
      )
    }

    if (
      requestParameters.etelLanguage === null ||
      requestParameters.etelLanguage === undefined
    ) {
      throw new runtime.RequiredError(
        'etelLanguage',
        'Required parameter requestParameters.etelLanguage was null or undefined when calling getFactoryModelSummary.',
      )
    }

    if (
      requestParameters.factoryModel === null ||
      requestParameters.factoryModel === undefined
    ) {
      throw new runtime.RequiredError(
        'factoryModel',
        'Required parameter requestParameters.factoryModel was null or undefined when calling getFactoryModelSummary.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.etelMarket !== undefined) {
      queryParameters['etel_market'] = requestParameters.etelMarket
    }

    if (requestParameters.etelLanguage !== undefined) {
      queryParameters['etel_language'] = requestParameters.etelLanguage
    }

    if (requestParameters.factoryModel !== undefined) {
      queryParameters['factory_model'] = requestParameters.factoryModel
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password)
    }
    const response = await this.request(
      {
        path: `/api/get_factory_model_summary`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      FactoryModelSummaryFromJSON(jsonValue),
    )
  }

  /**
   * Generate a summary for a factory model.
   */
  async getFactoryModelSummary(
    etelMarket: number,
    etelLanguage: number,
    factoryModel: number,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<FactoryModelSummary> {
    const response = await this.getFactoryModelSummaryRaw(
      {
        etelMarket: etelMarket,
        etelLanguage: etelLanguage,
        factoryModel: factoryModel,
      },
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async getFactoryModelsV2Raw(
    requestParameters: GetFactoryModelsV2Request,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<FactoryModelsV2Response>> {
    if (
      requestParameters.etelMarket === null ||
      requestParameters.etelMarket === undefined
    ) {
      throw new runtime.RequiredError(
        'etelMarket',
        'Required parameter requestParameters.etelMarket was null or undefined when calling getFactoryModelsV2.',
      )
    }

    if (
      requestParameters.etelLanguage === null ||
      requestParameters.etelLanguage === undefined
    ) {
      throw new runtime.RequiredError(
        'etelLanguage',
        'Required parameter requestParameters.etelLanguage was null or undefined when calling getFactoryModelsV2.',
      )
    }

    if (
      requestParameters.usePreviewText === null ||
      requestParameters.usePreviewText === undefined
    ) {
      throw new runtime.RequiredError(
        'usePreviewText',
        'Required parameter requestParameters.usePreviewText was null or undefined when calling getFactoryModelsV2.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.etelMarket !== undefined) {
      queryParameters['etel_market'] = requestParameters.etelMarket
    }

    if (requestParameters.etelLanguage !== undefined) {
      queryParameters['etel_language'] = requestParameters.etelLanguage
    }

    if (requestParameters.usePreviewText !== undefined) {
      queryParameters['use_preview_text'] = requestParameters.usePreviewText
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password)
    }
    const response = await this.request(
      {
        path: `/api/get_factory_models_v2`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      FactoryModelsV2ResponseFromJSON(jsonValue),
    )
  }

  /**
   */
  async getFactoryModelsV2(
    etelMarket: number,
    etelLanguage: number,
    usePreviewText: boolean,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<FactoryModelsV2Response> {
    const response = await this.getFactoryModelsV2Raw(
      {
        etelMarket: etelMarket,
        etelLanguage: etelLanguage,
        usePreviewText: usePreviewText,
      },
      initOverrides,
    )
    return await response.value()
  }

  /**
   * Retrieves the consequence of change after loading a new  configuration. This object represents the changes that was made by  SECM compared to the input configuration.
   */
  async getInitialConsequenceOfChangeRaw(
    requestParameters: GetInitialConsequenceOfChangeOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<
    runtime.ApiResponse<GetInitialConsequenceOfChangeResponseWrapper>
  > {
    if (
      requestParameters.getInitialConsequenceOfChangeRequest === null ||
      requestParameters.getInitialConsequenceOfChangeRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'getInitialConsequenceOfChangeRequest',
        'Required parameter requestParameters.getInitialConsequenceOfChangeRequest was null or undefined when calling getInitialConsequenceOfChange.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password)
    }
    const response = await this.request(
      {
        path: `/api/get_initial_consequence_of_change`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: GetInitialConsequenceOfChangeRequestToJSON(
          requestParameters.getInitialConsequenceOfChangeRequest,
        ),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetInitialConsequenceOfChangeResponseWrapperFromJSON(jsonValue),
    )
  }

  /**
   * Retrieves the consequence of change after loading a new  configuration. This object represents the changes that was made by  SECM compared to the input configuration.
   */
  async getInitialConsequenceOfChange(
    getInitialConsequenceOfChangeRequest: GetInitialConsequenceOfChangeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<GetInitialConsequenceOfChangeResponseWrapper> {
    const response = await this.getInitialConsequenceOfChangeRaw(
      {
        getInitialConsequenceOfChangeRequest:
          getInitialConsequenceOfChangeRequest,
      },
      initOverrides,
    )
    return await response.value()
  }

  /**
   * Returns the latest configuration change result.    This is a partial view into the complete truck configuration state  and menu tree since the complete state is too large to be  transferred to the web browser client for every click and as a  result needs to be stored on the server side.
   */
  async getLatestConfigChangeResultRaw(
    requestParameters: GetLatestConfigChangeResultRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ConfigChangeResponseWrapper>> {
    if (
      requestParameters.sessionId === null ||
      requestParameters.sessionId === undefined
    ) {
      throw new runtime.RequiredError(
        'sessionId',
        'Required parameter requestParameters.sessionId was null or undefined when calling getLatestConfigChangeResult.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.sessionId !== undefined) {
      queryParameters['session_id'] = requestParameters.sessionId
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password)
    }
    const response = await this.request(
      {
        path: `/api/get_latest_config_change_result`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ConfigChangeResponseWrapperFromJSON(jsonValue),
    )
  }

  /**
   * Returns the latest configuration change result.    This is a partial view into the complete truck configuration state  and menu tree since the complete state is too large to be  transferred to the web browser client for every click and as a  result needs to be stored on the server side.
   */
  async getLatestConfigChangeResult(
    sessionId: string,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ConfigChangeResponseWrapper> {
    const response = await this.getLatestConfigChangeResultRaw(
      { sessionId: sessionId },
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async getReadmoreRaw(
    requestParameters: GetReadmoreOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ReadMoreResources>> {
    if (
      requestParameters.getReadmoreRequest === null ||
      requestParameters.getReadmoreRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'getReadmoreRequest',
        'Required parameter requestParameters.getReadmoreRequest was null or undefined when calling getReadmore.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password)
    }
    const response = await this.request(
      {
        path: `/api/get_readmore`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: GetReadmoreRequestToJSON(requestParameters.getReadmoreRequest),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ReadMoreResourcesFromJSON(jsonValue),
    )
  }

  /**
   */
  async getReadmore(
    getReadmoreRequest: GetReadmoreRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ReadMoreResources> {
    const response = await this.getReadmoreRaw(
      { getReadmoreRequest: getReadmoreRequest },
      initOverrides,
    )
    return await response.value()
  }

  /**
   * TODO: Merge into create_session.                TODO: Remove this route handler and the related return type and add  what\'s missing on the GuiMarketSettings type instead.
   */
  async getScaniaCultureInfoRaw(
    requestParameters: GetScaniaCultureInfoRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<GetScaniaCultureInfoResponseWrapper>> {
    if (
      requestParameters.etelLanguage === null ||
      requestParameters.etelLanguage === undefined
    ) {
      throw new runtime.RequiredError(
        'etelLanguage',
        'Required parameter requestParameters.etelLanguage was null or undefined when calling getScaniaCultureInfo.',
      )
    }

    if (
      requestParameters.etelMarket === null ||
      requestParameters.etelMarket === undefined
    ) {
      throw new runtime.RequiredError(
        'etelMarket',
        'Required parameter requestParameters.etelMarket was null or undefined when calling getScaniaCultureInfo.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.etelLanguage !== undefined) {
      queryParameters['etel_language'] = requestParameters.etelLanguage
    }

    if (requestParameters.etelMarket !== undefined) {
      queryParameters['etel_market'] = requestParameters.etelMarket
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password)
    }
    const response = await this.request(
      {
        path: `/api/get_scania_culture_info`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetScaniaCultureInfoResponseWrapperFromJSON(jsonValue),
    )
  }

  /**
   * TODO: Merge into create_session.                TODO: Remove this route handler and the related return type and add  what\'s missing on the GuiMarketSettings type instead.
   */
  async getScaniaCultureInfo(
    etelLanguage: number,
    etelMarket: number,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<GetScaniaCultureInfoResponseWrapper> {
    const response = await this.getScaniaCultureInfoRaw(
      { etelLanguage: etelLanguage, etelMarket: etelMarket },
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async getStatelessScaniaSystemInfoRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<GetStatelessScaniaSystemInfoResponseWrapper>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password)
    }
    const response = await this.request(
      {
        path: `/api/get_stateless_scania_system_info`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetStatelessScaniaSystemInfoResponseWrapperFromJSON(jsonValue),
    )
  }

  /**
   */
  async getStatelessScaniaSystemInfo(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<GetStatelessScaniaSystemInfoResponseWrapper> {
    const response = await this.getStatelessScaniaSystemInfoRaw(initOverrides)
    return await response.value()
  }

  /**
   * Returns the user consents for the logged-in user. The returned list  can be empty.
   */
  async getUserConsentsRaw(
    requestParameters: GetUserConsentsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<GetUserConsentsResponseWrapper>> {
    if (
      requestParameters.sessionId === null ||
      requestParameters.sessionId === undefined
    ) {
      throw new runtime.RequiredError(
        'sessionId',
        'Required parameter requestParameters.sessionId was null or undefined when calling getUserConsents.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.sessionId !== undefined) {
      queryParameters['session_id'] = requestParameters.sessionId
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password)
    }
    const response = await this.request(
      {
        path: `/api/get_user_consents`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetUserConsentsResponseWrapperFromJSON(jsonValue),
    )
  }

  /**
   * Returns the user consents for the logged-in user. The returned list  can be empty.
   */
  async getUserConsents(
    sessionId: string,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<GetUserConsentsResponseWrapper> {
    const response = await this.getUserConsentsRaw(
      { sessionId: sessionId },
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async gowizardChangeSliderRaw(
    requestParameters: GowizardChangeSliderOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<GuidedOfferingInfo>> {
    if (
      requestParameters.gowizardChangeSliderRequest === null ||
      requestParameters.gowizardChangeSliderRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'gowizardChangeSliderRequest',
        'Required parameter requestParameters.gowizardChangeSliderRequest was null or undefined when calling gowizardChangeSlider.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password)
    }
    const response = await this.request(
      {
        path: `/api/gowizard_change_slider`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: GowizardChangeSliderRequestToJSON(
          requestParameters.gowizardChangeSliderRequest,
        ),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GuidedOfferingInfoFromJSON(jsonValue),
    )
  }

  /**
   */
  async gowizardChangeSlider(
    gowizardChangeSliderRequest: GowizardChangeSliderRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<GuidedOfferingInfo> {
    const response = await this.gowizardChangeSliderRaw(
      { gowizardChangeSliderRequest: gowizardChangeSliderRequest },
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async gowizardCloseRaw(
    requestParameters: GowizardCloseRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<GoWizardCloseResponseWrapper>> {
    if (
      requestParameters.gowizardUndoV2Request === null ||
      requestParameters.gowizardUndoV2Request === undefined
    ) {
      throw new runtime.RequiredError(
        'gowizardUndoV2Request',
        'Required parameter requestParameters.gowizardUndoV2Request was null or undefined when calling gowizardClose.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password)
    }
    const response = await this.request(
      {
        path: `/api/gowizard_close`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: GowizardUndoV2RequestToJSON(
          requestParameters.gowizardUndoV2Request,
        ),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GoWizardCloseResponseWrapperFromJSON(jsonValue),
    )
  }

  /**
   */
  async gowizardClose(
    gowizardUndoV2Request: GowizardUndoV2Request,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<GoWizardCloseResponseWrapper> {
    const response = await this.gowizardCloseRaw(
      { gowizardUndoV2Request: gowizardUndoV2Request },
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async gowizardContinueV2Raw(
    requestParameters: GowizardContinueV2OperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<GoWizardContinueV2ResponseWrapper>> {
    if (
      requestParameters.gowizardContinueV2Request === null ||
      requestParameters.gowizardContinueV2Request === undefined
    ) {
      throw new runtime.RequiredError(
        'gowizardContinueV2Request',
        'Required parameter requestParameters.gowizardContinueV2Request was null or undefined when calling gowizardContinueV2.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password)
    }
    const response = await this.request(
      {
        path: `/api/gowizard_continue_v2`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: GowizardContinueV2RequestToJSON(
          requestParameters.gowizardContinueV2Request,
        ),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GoWizardContinueV2ResponseWrapperFromJSON(jsonValue),
    )
  }

  /**
   */
  async gowizardContinueV2(
    gowizardContinueV2Request: GowizardContinueV2Request,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<GoWizardContinueV2ResponseWrapper> {
    const response = await this.gowizardContinueV2Raw(
      { gowizardContinueV2Request: gowizardContinueV2Request },
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async gowizardStartRaw(
    requestParameters: GowizardStartOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<GoWizardStartResponseWrapper>> {
    if (
      requestParameters.gowizardStartRequest === null ||
      requestParameters.gowizardStartRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'gowizardStartRequest',
        'Required parameter requestParameters.gowizardStartRequest was null or undefined when calling gowizardStart.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password)
    }
    const response = await this.request(
      {
        path: `/api/gowizard_start`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: GowizardStartRequestToJSON(
          requestParameters.gowizardStartRequest,
        ),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GoWizardStartResponseWrapperFromJSON(jsonValue),
    )
  }

  /**
   */
  async gowizardStart(
    gowizardStartRequest: GowizardStartRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<GoWizardStartResponseWrapper> {
    const response = await this.gowizardStartRaw(
      { gowizardStartRequest: gowizardStartRequest },
      initOverrides,
    )
    return await response.value()
  }

  /**
   * TODO: Remove the _v2 suffix when ScdsClient has been upgraded to  ts-scds-client.
   */
  async gowizardUndoV2Raw(
    requestParameters: GowizardUndoV2OperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<GuidedOfferingInfo>> {
    if (
      requestParameters.gowizardUndoV2Request === null ||
      requestParameters.gowizardUndoV2Request === undefined
    ) {
      throw new runtime.RequiredError(
        'gowizardUndoV2Request',
        'Required parameter requestParameters.gowizardUndoV2Request was null or undefined when calling gowizardUndoV2.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password)
    }
    const response = await this.request(
      {
        path: `/api/gowizard_undo_v2`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: GowizardUndoV2RequestToJSON(
          requestParameters.gowizardUndoV2Request,
        ),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GuidedOfferingInfoFromJSON(jsonValue),
    )
  }

  /**
   * TODO: Remove the _v2 suffix when ScdsClient has been upgraded to  ts-scds-client.
   */
  async gowizardUndoV2(
    gowizardUndoV2Request: GowizardUndoV2Request,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<GuidedOfferingInfo> {
    const response = await this.gowizardUndoV2Raw(
      { gowizardUndoV2Request: gowizardUndoV2Request },
      initOverrides,
    )
    return await response.value()
  }

  /**
   * Issues an heartbeat (keep-alive signal) for the provided session.
   */
  async heartbeatRaw(
    requestParameters: HeartbeatRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<HeartbeatResponseWrapper>> {
    if (
      requestParameters.sessionId === null ||
      requestParameters.sessionId === undefined
    ) {
      throw new runtime.RequiredError(
        'sessionId',
        'Required parameter requestParameters.sessionId was null or undefined when calling heartbeat.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.sessionId !== undefined) {
      queryParameters['session_id'] = requestParameters.sessionId
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password)
    }
    const response = await this.request(
      {
        path: `/api/heartbeat`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      HeartbeatResponseWrapperFromJSON(jsonValue),
    )
  }

  /**
   * Issues an heartbeat (keep-alive signal) for the provided session.
   */
  async heartbeat(
    sessionId: string,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<HeartbeatResponseWrapper> {
    const response = await this.heartbeatRaw(
      { sessionId: sessionId },
      initOverrides,
    )
    return await response.value()
  }

  /**
   * Loads a configuration from the given string.
   */
  async loadConfigFromStringRaw(
    requestParameters: LoadConfigFromStringOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<LoadConfigFromStringResponseWrapper>> {
    if (
      requestParameters.loadConfigFromStringRequest === null ||
      requestParameters.loadConfigFromStringRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'loadConfigFromStringRequest',
        'Required parameter requestParameters.loadConfigFromStringRequest was null or undefined when calling loadConfigFromString.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password)
    }
    const response = await this.request(
      {
        path: `/api/load_config_from_string`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: LoadConfigFromStringRequestToJSON(
          requestParameters.loadConfigFromStringRequest,
        ),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      LoadConfigFromStringResponseWrapperFromJSON(jsonValue),
    )
  }

  /**
   * Loads a configuration from the given string.
   */
  async loadConfigFromString(
    loadConfigFromStringRequest: LoadConfigFromStringRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<LoadConfigFromStringResponseWrapper> {
    const response = await this.loadConfigFromStringRaw(
      { loadConfigFromStringRequest: loadConfigFromStringRequest },
      initOverrides,
    )
    return await response.value()
  }

  /**
   * Log in to a garage account.
   */
  async loginRaw(
    requestParameters: LoginOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<LoginResponseWrapper>> {
    if (
      requestParameters.loginRequest === null ||
      requestParameters.loginRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'loginRequest',
        'Required parameter requestParameters.loginRequest was null or undefined when calling login.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password)
    }
    const response = await this.request(
      {
        path: `/api/login`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: LoginRequestToJSON(requestParameters.loginRequest),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      LoginResponseWrapperFromJSON(jsonValue),
    )
  }

  /**
   * Log in to a garage account.
   */
  async login(
    loginRequest: LoginRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<LoginResponseWrapper> {
    const response = await this.loginRaw(
      { loginRequest: loginRequest },
      initOverrides,
    )
    return await response.value()
  }

  /**
   * Logs out the user for a configurator session.
   */
  async logoutRaw(
    requestParameters: LogoutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<LogoutResponseWrapper>> {
    if (
      requestParameters.sessionId === null ||
      requestParameters.sessionId === undefined
    ) {
      throw new runtime.RequiredError(
        'sessionId',
        'Required parameter requestParameters.sessionId was null or undefined when calling logout.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.sessionId !== undefined) {
      queryParameters['session_id'] = requestParameters.sessionId
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password)
    }
    const response = await this.request(
      {
        path: `/api/logout`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      LogoutResponseWrapperFromJSON(jsonValue),
    )
  }

  /**
   * Logs out the user for a configurator session.
   */
  async logout(
    sessionId: string,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<LogoutResponseWrapper> {
    const response = await this.logoutRaw(
      { sessionId: sessionId },
      initOverrides,
    )
    return await response.value()
  }

  /**
   * Perform \'Extended Check\' and store the result in the server-side  session. This request must be completed before attempting to send  an RFQ.    The reason for seperating this into a new request instead of making  it part of the RFQ request is that it can take a long time to  complete and by starting the \'Extended Check\' while the user fills  in the RFQ form we can avoid some waiting for the user.
   */
  async prepareRfqRaw(
    requestParameters: PrepareRfqRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PrepareRfqResponse>> {
    if (
      requestParameters.sessionId === null ||
      requestParameters.sessionId === undefined
    ) {
      throw new runtime.RequiredError(
        'sessionId',
        'Required parameter requestParameters.sessionId was null or undefined when calling prepareRfq.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.sessionId !== undefined) {
      queryParameters['sessionId'] = requestParameters.sessionId
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password)
    }
    const response = await this.request(
      {
        path: `/api/prepare_rfq`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PrepareRfqResponseFromJSON(jsonValue),
    )
  }

  /**
   * Perform \'Extended Check\' and store the result in the server-side  session. This request must be completed before attempting to send  an RFQ.    The reason for seperating this into a new request instead of making  it part of the RFQ request is that it can take a long time to  complete and by starting the \'Extended Check\' while the user fills  in the RFQ form we can avoid some waiting for the user.
   */
  async prepareRfq(
    sessionId: string,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PrepareRfqResponse> {
    const response = await this.prepareRfqRaw(
      { sessionId: sessionId },
      initOverrides,
    )
    return await response.value()
  }

  /**
   * Generate a QR code image for a public config link.
   */
  async publicConfigQrCodeRaw(
    requestParameters: PublicConfigQrCodeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling publicConfigQrCode.',
      )
    }

    if (
      requestParameters.type === null ||
      requestParameters.type === undefined
    ) {
      throw new runtime.RequiredError(
        'type',
        'Required parameter requestParameters.type was null or undefined when calling publicConfigQrCode.',
      )
    }

    if (
      requestParameters.etelMarket === null ||
      requestParameters.etelMarket === undefined
    ) {
      throw new runtime.RequiredError(
        'etelMarket',
        'Required parameter requestParameters.etelMarket was null or undefined when calling publicConfigQrCode.',
      )
    }

    if (
      requestParameters.etelLanguage === null ||
      requestParameters.etelLanguage === undefined
    ) {
      throw new runtime.RequiredError(
        'etelLanguage',
        'Required parameter requestParameters.etelLanguage was null or undefined when calling publicConfigQrCode.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.etelMarket !== undefined) {
      queryParameters['etel_market'] = requestParameters.etelMarket
    }

    if (requestParameters.etelLanguage !== undefined) {
      queryParameters['etel_language'] = requestParameters.etelLanguage
    }

    if (requestParameters.download !== undefined) {
      queryParameters['download'] = requestParameters.download
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password)
    }
    const response = await this.request(
      {
        path: `/public_config/QR-{id}.{type}`
          .replace(
            `{${'id'}}`,
            encodeURIComponent(String(requestParameters.id)),
          )
          .replace(
            `{${'type'}}`,
            encodeURIComponent(String(requestParameters.type)),
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Generate a QR code image for a public config link.
   */
  async publicConfigQrCode(
    id: string,
    type: QrCodeFileType,
    etelMarket: number,
    etelLanguage: number,
    download?: boolean,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.publicConfigQrCodeRaw(
      {
        id: id,
        type: type,
        etelMarket: etelMarket,
        etelLanguage: etelLanguage,
        download: download,
      },
      initOverrides,
    )
  }

  /**
   * Get public config info
   */
  async publicConfigV2InfoRaw(
    requestParameters: PublicConfigV2InfoRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PublicConfigV2InfoResponseWrapper>> {
    if (
      requestParameters.publicConfigId === null ||
      requestParameters.publicConfigId === undefined
    ) {
      throw new runtime.RequiredError(
        'publicConfigId',
        'Required parameter requestParameters.publicConfigId was null or undefined when calling publicConfigV2Info.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.publicConfigId !== undefined) {
      queryParameters['public_config_id'] = requestParameters.publicConfigId
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password)
    }
    const response = await this.request(
      {
        path: `/api/public_config_v2_info`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PublicConfigV2InfoResponseWrapperFromJSON(jsonValue),
    )
  }

  /**
   * Get public config info
   */
  async publicConfigV2Info(
    publicConfigId: string,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PublicConfigV2InfoResponseWrapper> {
    const response = await this.publicConfigV2InfoRaw(
      { publicConfigId: publicConfigId },
      initOverrides,
    )
    return await response.value()
  }

  /**
   * Loads a public config
   */
  async publicConfigV2LoadRaw(
    requestParameters: PublicConfigV2LoadRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.sessionId === null ||
      requestParameters.sessionId === undefined
    ) {
      throw new runtime.RequiredError(
        'sessionId',
        'Required parameter requestParameters.sessionId was null or undefined when calling publicConfigV2Load.',
      )
    }

    if (
      requestParameters.publicConfigId === null ||
      requestParameters.publicConfigId === undefined
    ) {
      throw new runtime.RequiredError(
        'publicConfigId',
        'Required parameter requestParameters.publicConfigId was null or undefined when calling publicConfigV2Load.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.sessionId !== undefined) {
      queryParameters['session_id'] = requestParameters.sessionId
    }

    if (requestParameters.publicConfigId !== undefined) {
      queryParameters['public_config_id'] = requestParameters.publicConfigId
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password)
    }
    const response = await this.request(
      {
        path: `/api/public_config_v2_load`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Loads a public config
   */
  async publicConfigV2Load(
    sessionId: string,
    publicConfigId: string,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.publicConfigV2LoadRaw(
      { sessionId: sessionId, publicConfigId: publicConfigId },
      initOverrides,
    )
  }

  /**
   * Save the configuration of the current session without any user  related data and return an opaque Id for the configuration that can  be used for public_config_v2_load.
   */
  async publicConfigV2SaveRaw(
    requestParameters: PublicConfigV2SaveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PublicConfigV2SaveResponseWrapper>> {
    if (
      requestParameters.sessionId === null ||
      requestParameters.sessionId === undefined
    ) {
      throw new runtime.RequiredError(
        'sessionId',
        'Required parameter requestParameters.sessionId was null or undefined when calling publicConfigV2Save.',
      )
    }

    if (
      requestParameters.description === null ||
      requestParameters.description === undefined
    ) {
      throw new runtime.RequiredError(
        'description',
        'Required parameter requestParameters.description was null or undefined when calling publicConfigV2Save.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.sessionId !== undefined) {
      queryParameters['session_id'] = requestParameters.sessionId
    }

    if (requestParameters.description !== undefined) {
      queryParameters['description'] = requestParameters.description
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password)
    }
    const response = await this.request(
      {
        path: `/api/public_config_v2_save`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PublicConfigV2SaveResponseWrapperFromJSON(jsonValue),
    )
  }

  /**
   * Save the configuration of the current session without any user  related data and return an opaque Id for the configuration that can  be used for public_config_v2_load.
   */
  async publicConfigV2Save(
    sessionId: string,
    description: string,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PublicConfigV2SaveResponseWrapper> {
    const response = await this.publicConfigV2SaveRaw(
      { sessionId: sessionId, description: description },
      initOverrides,
    )
    return await response.value()
  }

  /**
   * Resets a user password with token
   */
  async resetPasswordRaw(
    requestParameters: ResetPasswordOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ResetPasswordResponseWrapper>> {
    if (
      requestParameters.resetPasswordRequest === null ||
      requestParameters.resetPasswordRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'resetPasswordRequest',
        'Required parameter requestParameters.resetPasswordRequest was null or undefined when calling resetPassword.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password)
    }
    const response = await this.request(
      {
        path: `/api/reset_password`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ResetPasswordRequestToJSON(
          requestParameters.resetPasswordRequest,
        ),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ResetPasswordResponseWrapperFromJSON(jsonValue),
    )
  }

  /**
   * Resets a user password with token
   */
  async resetPassword(
    resetPasswordRequest: ResetPasswordRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ResetPasswordResponseWrapper> {
    const response = await this.resetPasswordRaw(
      { resetPasswordRequest: resetPasswordRequest },
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async scaniaExportXmlRaw(
    requestParameters: ScaniaExportXmlRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {}

    if (requestParameters.sessionId !== undefined) {
      queryParameters['session_id'] = requestParameters.sessionId
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password)
    }
    const response = await this.request(
      {
        path: `/api/scania_export_xml`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.VoidApiResponse(response)
  }

  /**
   */
  async scaniaExportXml(
    sessionId?: string,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.scaniaExportXmlRaw({ sessionId: sessionId }, initOverrides)
  }

  /**
   */
  async scaniaGetBuildModeEtelInfoRaw(
    requestParameters: ScaniaGetBuildModeEtelInfoRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ScaniaGetBuildModeEtelInfoResponseWrapper>> {
    if (
      requestParameters.sessionId === null ||
      requestParameters.sessionId === undefined
    ) {
      throw new runtime.RequiredError(
        'sessionId',
        'Required parameter requestParameters.sessionId was null or undefined when calling scaniaGetBuildModeEtelInfo.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.sessionId !== undefined) {
      queryParameters['session_id'] = requestParameters.sessionId
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password)
    }
    const response = await this.request(
      {
        path: `/api/scania_get_build_mode_etel_info`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ScaniaGetBuildModeEtelInfoResponseWrapperFromJSON(jsonValue),
    )
  }

  /**
   */
  async scaniaGetBuildModeEtelInfo(
    sessionId: string,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ScaniaGetBuildModeEtelInfoResponseWrapper> {
    const response = await this.scaniaGetBuildModeEtelInfoRaw(
      { sessionId: sessionId },
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async scaniaImportXmlRaw(
    requestParameters: ScaniaImportXmlOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ScaniaImportXmlResponseWrapper>> {
    if (
      requestParameters.scaniaImportXmlRequest === null ||
      requestParameters.scaniaImportXmlRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'scaniaImportXmlRequest',
        'Required parameter requestParameters.scaniaImportXmlRequest was null or undefined when calling scaniaImportXml.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password)
    }
    const response = await this.request(
      {
        path: `/api/scania_import_xml`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ScaniaImportXmlRequestToJSON(
          requestParameters.scaniaImportXmlRequest,
        ),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ScaniaImportXmlResponseWrapperFromJSON(jsonValue),
    )
  }

  /**
   */
  async scaniaImportXml(
    scaniaImportXmlRequest: ScaniaImportXmlRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ScaniaImportXmlResponseWrapper> {
    const response = await this.scaniaImportXmlRaw(
      { scaniaImportXmlRequest: scaniaImportXmlRequest },
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async scaniaSearchRaw(
    requestParameters: ScaniaSearchRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ScaniaSearchResponseWrapper>> {
    if (
      requestParameters.sessionId === null ||
      requestParameters.sessionId === undefined
    ) {
      throw new runtime.RequiredError(
        'sessionId',
        'Required parameter requestParameters.sessionId was null or undefined when calling scaniaSearch.',
      )
    }

    if (
      requestParameters.searchText === null ||
      requestParameters.searchText === undefined
    ) {
      throw new runtime.RequiredError(
        'searchText',
        'Required parameter requestParameters.searchText was null or undefined when calling scaniaSearch.',
      )
    }

    if (
      requestParameters.market === null ||
      requestParameters.market === undefined
    ) {
      throw new runtime.RequiredError(
        'market',
        'Required parameter requestParameters.market was null or undefined when calling scaniaSearch.',
      )
    }

    if (
      requestParameters.language === null ||
      requestParameters.language === undefined
    ) {
      throw new runtime.RequiredError(
        'language',
        'Required parameter requestParameters.language was null or undefined when calling scaniaSearch.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.sessionId !== undefined) {
      queryParameters['session_id'] = requestParameters.sessionId
    }

    if (requestParameters.searchText !== undefined) {
      queryParameters['search_text'] = requestParameters.searchText
    }

    if (requestParameters.market !== undefined) {
      queryParameters['market'] = requestParameters.market
    }

    if (requestParameters.language !== undefined) {
      queryParameters['language'] = requestParameters.language
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password)
    }
    const response = await this.request(
      {
        path: `/api/scania_search`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ScaniaSearchResponseWrapperFromJSON(jsonValue),
    )
  }

  /**
   */
  async scaniaSearch(
    sessionId: string,
    searchText: string,
    market: number,
    language: number,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ScaniaSearchResponseWrapper> {
    const response = await this.scaniaSearchRaw(
      {
        sessionId: sessionId,
        searchText: searchText,
        market: market,
        language: language,
      },
      initOverrides,
    )
    return await response.value()
  }

  /**
   * Send changes for an EYS (Explore Your Scania) configuration back to EYS.    A new configuration will be saved and the new id will be sent to  the sales representative.    EYS should not be making this request, only the configurator GUI is  supposed to call this method.
   */
  async sendChangesToEysRaw(
    requestParameters: SendChangesToEysOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.sendChangesToEysRequest === null ||
      requestParameters.sendChangesToEysRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'sendChangesToEysRequest',
        'Required parameter requestParameters.sendChangesToEysRequest was null or undefined when calling sendChangesToEys.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password)
    }
    const response = await this.request(
      {
        path: `/api/send_changes_to_eys`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: SendChangesToEysRequestToJSON(
          requestParameters.sendChangesToEysRequest,
        ),
      },
      initOverrides,
    )

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Send changes for an EYS (Explore Your Scania) configuration back to EYS.    A new configuration will be saved and the new id will be sent to  the sales representative.    EYS should not be making this request, only the configurator GUI is  supposed to call this method.
   */
  async sendChangesToEys(
    sendChangesToEysRequest: SendChangesToEysRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.sendChangesToEysRaw(
      { sendChangesToEysRequest: sendChangesToEysRequest },
      initOverrides,
    )
  }

  /**
   */
  async sendDeleteUserMailRaw(
    requestParameters: SendDeleteUserMailRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.sessionId === null ||
      requestParameters.sessionId === undefined
    ) {
      throw new runtime.RequiredError(
        'sessionId',
        'Required parameter requestParameters.sessionId was null or undefined when calling sendDeleteUserMail.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.sessionId !== undefined) {
      queryParameters['session_id'] = requestParameters.sessionId
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password)
    }
    const response = await this.request(
      {
        path: `/api/send_delete_user_mail`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.VoidApiResponse(response)
  }

  /**
   */
  async sendDeleteUserMail(
    sessionId: string,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.sendDeleteUserMailRaw({ sessionId: sessionId }, initOverrides)
  }

  /**
   * Sends a reset password mail for a user
   */
  async sendResetPasswordMailRaw(
    requestParameters: SendResetPasswordMailOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<SendResetPasswordMailResponseWrapper>> {
    if (
      requestParameters.sendResetPasswordMailRequest === null ||
      requestParameters.sendResetPasswordMailRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'sendResetPasswordMailRequest',
        'Required parameter requestParameters.sendResetPasswordMailRequest was null or undefined when calling sendResetPasswordMail.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password)
    }
    const response = await this.request(
      {
        path: `/api/send_reset_password_mail`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: SendResetPasswordMailRequestToJSON(
          requestParameters.sendResetPasswordMailRequest,
        ),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SendResetPasswordMailResponseWrapperFromJSON(jsonValue),
    )
  }

  /**
   * Sends a reset password mail for a user
   */
  async sendResetPasswordMail(
    sendResetPasswordMailRequest: SendResetPasswordMailRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<SendResetPasswordMailResponseWrapper> {
    const response = await this.sendResetPasswordMailRaw(
      { sendResetPasswordMailRequest: sendResetPasswordMailRequest },
      initOverrides,
    )
    return await response.value()
  }

  /**
   * Send a Request For Quotation.
   */
  async sendRfqRaw(
    requestParameters: SendRfqOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.sendRfqRequest === null ||
      requestParameters.sendRfqRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'sendRfqRequest',
        'Required parameter requestParameters.sendRfqRequest was null or undefined when calling sendRfq.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password)
    }
    const response = await this.request(
      {
        path: `/api/send_rfq`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: SendRfqRequestToJSON(requestParameters.sendRfqRequest),
      },
      initOverrides,
    )

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Send a Request For Quotation.
   */
  async sendRfq(
    sendRfqRequest: SendRfqRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.sendRfqRaw({ sendRfqRequest: sendRfqRequest }, initOverrides)
  }

  /**
   */
  async sendShareMailRaw(
    requestParameters: SendShareMailOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.sendShareMailRequest === null ||
      requestParameters.sendShareMailRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'sendShareMailRequest',
        'Required parameter requestParameters.sendShareMailRequest was null or undefined when calling sendShareMail.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password)
    }
    const response = await this.request(
      {
        path: `/api/send_share_mail`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: SendShareMailRequestToJSON(
          requestParameters.sendShareMailRequest,
        ),
      },
      initOverrides,
    )

    return new runtime.VoidApiResponse(response)
  }

  /**
   */
  async sendShareMail(
    sendShareMailRequest: SendShareMailRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.sendShareMailRaw(
      { sendShareMailRequest: sendShareMailRequest },
      initOverrides,
    )
  }

  /**
   * Accept GDPR for a user account.
   */
  async setUserGdprConsentRaw(
    requestParameters: SetUserGdprConsentOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<SetUserGdprConsentResponse>> {
    if (
      requestParameters.setUserGdprConsentRequest === null ||
      requestParameters.setUserGdprConsentRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'setUserGdprConsentRequest',
        'Required parameter requestParameters.setUserGdprConsentRequest was null or undefined when calling setUserGdprConsent.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password)
    }
    const response = await this.request(
      {
        path: `/api/set_user_gdpr_consent`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: SetUserGdprConsentRequestToJSON(
          requestParameters.setUserGdprConsentRequest,
        ),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SetUserGdprConsentResponseFromJSON(jsonValue),
    )
  }

  /**
   * Accept GDPR for a user account.
   */
  async setUserGdprConsent(
    setUserGdprConsentRequest: SetUserGdprConsentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<SetUserGdprConsentResponse> {
    const response = await this.setUserGdprConsentRaw(
      { setUserGdprConsentRequest: setUserGdprConsentRequest },
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async startLoginRaw(
    requestParameters: StartLoginRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.originalUrl === null ||
      requestParameters.originalUrl === undefined
    ) {
      throw new runtime.RequiredError(
        'originalUrl',
        'Required parameter requestParameters.originalUrl was null or undefined when calling startLogin.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.originalUrl !== undefined) {
      queryParameters['originalUrl'] = requestParameters.originalUrl
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password)
    }
    const response = await this.request(
      {
        path: `/api/start_login`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.VoidApiResponse(response)
  }

  /**
   */
  async startLogin(
    originalUrl: string,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.startLoginRaw({ originalUrl: originalUrl }, initOverrides)
  }

  /**
   * Try to change the configuration.                If the requested change cannot be applied without important  side-effects in the configuration state, then configuration change  suggestions will be returned.
   */
  async tryChangeConfigRaw(
    requestParameters: TryChangeConfigOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<TryChangeConfigResponseWrapper>> {
    if (
      requestParameters.tryChangeConfigRequest === null ||
      requestParameters.tryChangeConfigRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'tryChangeConfigRequest',
        'Required parameter requestParameters.tryChangeConfigRequest was null or undefined when calling tryChangeConfig.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password)
    }
    const response = await this.request(
      {
        path: `/api/try_change_config`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: TryChangeConfigRequestToJSON(
          requestParameters.tryChangeConfigRequest,
        ),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TryChangeConfigResponseWrapperFromJSON(jsonValue),
    )
  }

  /**
   * Try to change the configuration.                If the requested change cannot be applied without important  side-effects in the configuration state, then configuration change  suggestions will be returned.
   */
  async tryChangeConfig(
    tryChangeConfigRequest: TryChangeConfigRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<TryChangeConfigResponseWrapper> {
    const response = await this.tryChangeConfigRaw(
      { tryChangeConfigRequest: tryChangeConfigRequest },
      initOverrides,
    )
    return await response.value()
  }

  /**
   * Updates an existing user config with a new name
   */
  async userConfigV2ChangeNameRaw(
    requestParameters: UserConfigV2ChangeNameRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.sessionId === null ||
      requestParameters.sessionId === undefined
    ) {
      throw new runtime.RequiredError(
        'sessionId',
        'Required parameter requestParameters.sessionId was null or undefined when calling userConfigV2ChangeName.',
      )
    }

    if (
      requestParameters.userConfigId === null ||
      requestParameters.userConfigId === undefined
    ) {
      throw new runtime.RequiredError(
        'userConfigId',
        'Required parameter requestParameters.userConfigId was null or undefined when calling userConfigV2ChangeName.',
      )
    }

    if (
      requestParameters.newName === null ||
      requestParameters.newName === undefined
    ) {
      throw new runtime.RequiredError(
        'newName',
        'Required parameter requestParameters.newName was null or undefined when calling userConfigV2ChangeName.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.sessionId !== undefined) {
      queryParameters['session_id'] = requestParameters.sessionId
    }

    if (requestParameters.userConfigId !== undefined) {
      queryParameters['user_config_id'] = requestParameters.userConfigId
    }

    if (requestParameters.newName !== undefined) {
      queryParameters['new_name'] = requestParameters.newName
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password)
    }
    const response = await this.request(
      {
        path: `/api/user_config_v2_change_name`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Updates an existing user config with a new name
   */
  async userConfigV2ChangeName(
    sessionId: string,
    userConfigId: number,
    newName: string,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.userConfigV2ChangeNameRaw(
      { sessionId: sessionId, userConfigId: userConfigId, newName: newName },
      initOverrides,
    )
  }

  /**
   */
  async userConfigV2DeleteRaw(
    requestParameters: UserConfigV2DeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.sessionId === null ||
      requestParameters.sessionId === undefined
    ) {
      throw new runtime.RequiredError(
        'sessionId',
        'Required parameter requestParameters.sessionId was null or undefined when calling userConfigV2Delete.',
      )
    }

    if (
      requestParameters.userConfigId === null ||
      requestParameters.userConfigId === undefined
    ) {
      throw new runtime.RequiredError(
        'userConfigId',
        'Required parameter requestParameters.userConfigId was null or undefined when calling userConfigV2Delete.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.sessionId !== undefined) {
      queryParameters['session_id'] = requestParameters.sessionId
    }

    if (requestParameters.userConfigId !== undefined) {
      queryParameters['user_config_id'] = requestParameters.userConfigId
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password)
    }
    const response = await this.request(
      {
        path: `/api/user_config_v2_delete`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.VoidApiResponse(response)
  }

  /**
   */
  async userConfigV2Delete(
    sessionId: string,
    userConfigId: number,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.userConfigV2DeleteRaw(
      { sessionId: sessionId, userConfigId: userConfigId },
      initOverrides,
    )
  }

  /**
   * Get the list of saved configurations for a session with a logged in  user.
   */
  async userConfigV2ListRaw(
    requestParameters: UserConfigV2ListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<UserConfigV2ListResponseWrapper>> {
    if (
      requestParameters.sessionId === null ||
      requestParameters.sessionId === undefined
    ) {
      throw new runtime.RequiredError(
        'sessionId',
        'Required parameter requestParameters.sessionId was null or undefined when calling userConfigV2List.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.sessionId !== undefined) {
      queryParameters['session_id'] = requestParameters.sessionId
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password)
    }
    const response = await this.request(
      {
        path: `/api/user_config_v2_list`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserConfigV2ListResponseWrapperFromJSON(jsonValue),
    )
  }

  /**
   * Get the list of saved configurations for a session with a logged in  user.
   */
  async userConfigV2List(
    sessionId: string,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<UserConfigV2ListResponseWrapper> {
    const response = await this.userConfigV2ListRaw(
      { sessionId: sessionId },
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async userConfigV2LoadRaw(
    requestParameters: UserConfigV2LoadRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.sessionId === null ||
      requestParameters.sessionId === undefined
    ) {
      throw new runtime.RequiredError(
        'sessionId',
        'Required parameter requestParameters.sessionId was null or undefined when calling userConfigV2Load.',
      )
    }

    if (
      requestParameters.userConfigId === null ||
      requestParameters.userConfigId === undefined
    ) {
      throw new runtime.RequiredError(
        'userConfigId',
        'Required parameter requestParameters.userConfigId was null or undefined when calling userConfigV2Load.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.sessionId !== undefined) {
      queryParameters['session_id'] = requestParameters.sessionId
    }

    if (requestParameters.userConfigId !== undefined) {
      queryParameters['user_config_id'] = requestParameters.userConfigId
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password)
    }
    const response = await this.request(
      {
        path: `/api/user_config_v2_load`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.VoidApiResponse(response)
  }

  /**
   */
  async userConfigV2Load(
    sessionId: string,
    userConfigId: number,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.userConfigV2LoadRaw(
      { sessionId: sessionId, userConfigId: userConfigId },
      initOverrides,
    )
  }

  /**
   * Updates an existing user config with the current one in session.
   */
  async userConfigV2SaveRaw(
    requestParameters: UserConfigV2SaveOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<UserConfigV2SaveResponseWrapper>> {
    if (
      requestParameters.userConfigV2SaveRequest === null ||
      requestParameters.userConfigV2SaveRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'userConfigV2SaveRequest',
        'Required parameter requestParameters.userConfigV2SaveRequest was null or undefined when calling userConfigV2Save.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password)
    }
    const response = await this.request(
      {
        path: `/api/user_config_v2_save`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UserConfigV2SaveRequestToJSON(
          requestParameters.userConfigV2SaveRequest,
        ),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserConfigV2SaveResponseWrapperFromJSON(jsonValue),
    )
  }

  /**
   * Updates an existing user config with the current one in session.
   */
  async userConfigV2Save(
    userConfigV2SaveRequest: UserConfigV2SaveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<UserConfigV2SaveResponseWrapper> {
    const response = await this.userConfigV2SaveRaw(
      { userConfigV2SaveRequest: userConfigV2SaveRequest },
      initOverrides,
    )
    return await response.value()
  }

  /**
   * Saves the session configuration.
   */
  async userConfigV2SaveAsRaw(
    requestParameters: UserConfigV2SaveAsOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<UserConfigV2SaveAsResponseWrapper>> {
    if (
      requestParameters.userConfigV2SaveAsRequest === null ||
      requestParameters.userConfigV2SaveAsRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'userConfigV2SaveAsRequest',
        'Required parameter requestParameters.userConfigV2SaveAsRequest was null or undefined when calling userConfigV2SaveAs.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password)
    }
    const response = await this.request(
      {
        path: `/api/user_config_v2_save_as`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UserConfigV2SaveAsRequestToJSON(
          requestParameters.userConfigV2SaveAsRequest,
        ),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserConfigV2SaveAsResponseWrapperFromJSON(jsonValue),
    )
  }

  /**
   * Saves the session configuration.
   */
  async userConfigV2SaveAs(
    userConfigV2SaveAsRequest: UserConfigV2SaveAsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<UserConfigV2SaveAsResponseWrapper> {
    const response = await this.userConfigV2SaveAsRaw(
      { userConfigV2SaveAsRequest: userConfigV2SaveAsRequest },
      initOverrides,
    )
    return await response.value()
  }
}
