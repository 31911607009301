import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Size2d } from '../../types/Size2d'

const DynamicImageRoot = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
`

const TruckImage = styled.img`
  position: relative;
  height: 100%;
`

export interface DynamicImageProps {
  readonly buildImage: (size: Size2d) => Promise<string | null>

  /**
   * The time to wait after the last resize event before calling the buildImage
   * callback.
   */
  readonly updateDelayMs: number

  /**
   * The `alt` attribute for the inner `img` element.
   */
  readonly alt: string
}

/**
 * An image component that updates itself on size changes. This component is
 * intended to always resize itself to the size allowed by the parent. To
 * change to size of this component, change the size of the parent.
 */
export const DynamicImage = ({
  buildImage,
  updateDelayMs,
  alt,
}: DynamicImageProps): JSX.Element => {
  const [imageUrl, setImageUrl] = useState<string | null>(null)
  const timerId = useRef<number | undefined>()
  const [size, setSize] = useState<Size2d | null>(null)
  const [lazySize, setLazySize] = useState<Size2d | null>(null)
  const rootRef = useRef<HTMLDivElement>(null)

  const resizeObserver = useRef(
    new ResizeObserver((entries) => {
      const target = entries[0].target
      const size: Size2d = {
        width: target.clientWidth,
        height: target.clientHeight,
      }
      setSize(size)
    }),
  )

  useEffect(() => {
    if (!rootRef.current) {
      return
    }
    const observer = resizeObserver
    observer.current.observe(rootRef.current)
    return () => {
      observer.current.disconnect()
    }
  }, [rootRef])

  // Make image updates wait some time after the latest resize event arrived
  // before making the image update request. To save bandwidth and avoid
  // hammering the server.
  useEffect(() => {
    if (!size) {
      return
    }
    window.clearTimeout(timerId.current)
    timerId.current = window.setTimeout(() => {
      setLazySize({
        width: Math.round(size.width),
        height: Math.round(size.height),
      })
    }, updateDelayMs)
    return () => {
      window.clearTimeout(timerId.current)
    }
  }, [size, setLazySize, updateDelayMs])

  useLayoutEffect(() => {
    if (!lazySize) {
      return
    }
    const asyncWrapper = async () => {
      const url = await buildImage(lazySize)
      setImageUrl(url)
    }
    asyncWrapper()
  }, [lazySize, buildImage])

  return (
    <DynamicImageRoot ref={rootRef}>
      {imageUrl && <TruckImage alt={alt} src={imageUrl} />}
    </DynamicImageRoot>
  )
}
