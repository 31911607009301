/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface GoStateLanguage
 */
export interface GoStateLanguage {
  /**
   *
   * @type {number}
   * @memberof GoStateLanguage
   */
  value: number
}

/**
 * Check if a given object implements the GoStateLanguage interface.
 */
export function instanceOfGoStateLanguage(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'value' in value

  return isInstance
}

export function GoStateLanguageFromJSON(json: any): GoStateLanguage {
  return GoStateLanguageFromJSONTyped(json, false)
}

export function GoStateLanguageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GoStateLanguage {
  if (json === undefined || json === null) {
    return json
  }
  return {
    value: json['Value'],
  }
}

export function GoStateLanguageToJSON(value?: GoStateLanguage | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    Value: value.value,
  }
}
