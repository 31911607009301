/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { Dealer } from './Dealer'
import { DealerFromJSON, DealerFromJSONTyped, DealerToJSON } from './Dealer'

/**
 * Legacy response wrapper structure to allow matching the original
 * configurator web API.
 *
 * TODO: This should be removed after the .Net Core and ScdsClient
 * migration has been completed.
 * @export
 * @interface GetDealersResponseWrapper
 */
export interface GetDealersResponseWrapper {
  /**
   *
   * @type {Array<Dealer>}
   * @memberof GetDealersResponseWrapper
   */
  getDealers: Array<Dealer>
}

/**
 * Check if a given object implements the GetDealersResponseWrapper interface.
 */
export function instanceOfGetDealersResponseWrapper(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'getDealers' in value

  return isInstance
}

export function GetDealersResponseWrapperFromJSON(
  json: any,
): GetDealersResponseWrapper {
  return GetDealersResponseWrapperFromJSONTyped(json, false)
}

export function GetDealersResponseWrapperFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GetDealersResponseWrapper {
  if (json === undefined || json === null) {
    return json
  }
  return {
    getDealers: (json['get_dealers'] as Array<any>).map(DealerFromJSON),
  }
}

export function GetDealersResponseWrapperToJSON(
  value?: GetDealersResponseWrapper | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    get_dealers: (value.getDealers as Array<any>).map(DealerToJSON),
  }
}
