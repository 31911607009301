/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * Legacy response wrapper structure to allow matching the original
 * configurator web API.
 *
 * TODO: This should be removed after the .Net Core and ScdsClient
 * migration has been completed.
 * @export
 * @interface UserConfigV2SaveResponseWrapper
 */
export interface UserConfigV2SaveResponseWrapper {
  /**
   *
   * @type {any}
   * @memberof UserConfigV2SaveResponseWrapper
   */
  userConfigV2Save?: any | null
  /**
   *
   * @type {string}
   * @memberof UserConfigV2SaveResponseWrapper
   */
  error?: string | null
}

/**
 * Check if a given object implements the UserConfigV2SaveResponseWrapper interface.
 */
export function instanceOfUserConfigV2SaveResponseWrapper(
  value: object,
): boolean {
  let isInstance = true

  return isInstance
}

export function UserConfigV2SaveResponseWrapperFromJSON(
  json: any,
): UserConfigV2SaveResponseWrapper {
  return UserConfigV2SaveResponseWrapperFromJSONTyped(json, false)
}

export function UserConfigV2SaveResponseWrapperFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserConfigV2SaveResponseWrapper {
  if (json === undefined || json === null) {
    return json
  }
  return {
    userConfigV2Save: !exists(json, 'user_config_v2_save')
      ? undefined
      : json['user_config_v2_save'],
    error: !exists(json, 'error') ? undefined : json['error'],
  }
}

export function UserConfigV2SaveResponseWrapperToJSON(
  value?: UserConfigV2SaveResponseWrapper | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    user_config_v2_save: value.userConfigV2Save,
    error: value.error,
  }
}
