/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const GoQuestionType = {
  Slider: 'SLIDER',
  List: 'LIST',
  ImageList: 'IMAGE_LIST',
  ScalarInput: 'SCALAR_INPUT',
} as const
export type GoQuestionType =
  (typeof GoQuestionType)[keyof typeof GoQuestionType]

export function GoQuestionTypeFromJSON(json: any): GoQuestionType {
  return GoQuestionTypeFromJSONTyped(json, false)
}

export function GoQuestionTypeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GoQuestionType {
  return json as GoQuestionType
}

export function GoQuestionTypeToJSON(value?: GoQuestionType | null): any {
  return value as any
}
