import { useCallback } from 'react'
import { HandleCardClickParams } from './HandleCardClickParams'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { useNavigate } from 'react-router-dom'
import {
  buildStepSkippedTrackingMessage,
  resolveTrackingStepName,
} from './goUserTracking'
import { BuildLinkToNextQuestionHandler } from './BuildLinkToNextQuestionParams'
import { ScaniaAdobeTrackingFlowName } from '../../utils/adobeAnalytics'
import { getDefaultOrFirstId } from '../../utils/handleGoNext'
import {
  getGuidedOfferingClientState,
  setPendingUserTrackingPageNavigation,
} from '../../store/sessionDataSlice'
import { getMarketLanguageState } from '../../store/appSlice'

export type GoChoiceClickHandler = (params: HandleCardClickParams) => void

export interface GoChoiceClickHandlersHookResult {
  handleCardClick: GoChoiceClickHandler
  handleSkipQuestion: VoidFunction
}

export function useGoChoiceClickHandlers(
  buildLink: BuildLinkToNextQuestionHandler,
): GoChoiceClickHandlersHookResult {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const goClientState = useAppSelector(getGuidedOfferingClientState)
  const marketLanguage = useAppSelector(getMarketLanguageState)

  const handleCardClick = useCallback(
    ({ id, isSkippingToNext }: HandleCardClickParams) => {
      if (!goClientState) {
        throw new Error('Expected goClientState to be defined.')
      }
      if (!marketLanguage) {
        throw new Error('Expected etelLanguage to be defined.')
      }
      const opt = goClientState?.lastResponse.question?.options?.find(
        (o) => o.id === id,
      )
      if (!opt) {
        throw new Error('Expected opt to be defined.')
      }
      const familyId = goClientState?.lastResponse.question?.etelFamily || null
      if (!familyId) {
        throw new Error('Expected familyId to be defined.')
      }
      const stepName = resolveTrackingStepName(familyId)
      if (!stepName) {
        throw new Error('Expected stepName to be defined.')
      }
      let stepSkipped: string | null = null
      if (isSkippingToNext) {
        stepSkipped = buildStepSkippedTrackingMessage(
          goClientState,
          familyId,
          stepSkipped,
        )
      }
      dispatch(
        setPendingUserTrackingPageNavigation({
          factoryModelSelected: null,
          flowName: ScaniaAdobeTrackingFlowName.GuidedOffering,
          optionSelected: id,
          stepName,
          stepSkipped,
        }),
      )
      const urlSelection = encodeURIComponent(
        goClientState.userSelections.concat([id]).join('|'),
      )
      const link = buildLink({ id, marketLanguage, urlSelection })
      navigate(link)
    },
    [buildLink, dispatch, goClientState, navigate, marketLanguage],
  )

  const handleSkipQuestion = useCallback(() => {
    const id = getDefaultOrFirstId(goClientState)
    handleCardClick({ id, isSkippingToNext: true })
  }, [goClientState, handleCardClick])

  return { handleCardClick, handleSkipQuestion }
}
