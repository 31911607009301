/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ScaniaReadMoreTextNode } from './ScaniaReadMoreTextNode'
import {
  ScaniaReadMoreTextNodeFromJSON,
  ScaniaReadMoreTextNodeFromJSONTyped,
  ScaniaReadMoreTextNodeToJSON,
} from './ScaniaReadMoreTextNode'

/**
 * Simplified version of the older ReadMore* related response types.
 * @export
 * @interface ItemReadMore
 */
export interface ItemReadMore {
  /**
   *
   * @type {string}
   * @memberof ItemReadMore
   */
  id?: string | null
  /**
   *
   * @type {Array<string>}
   * @memberof ItemReadMore
   */
  imageUrls?: Array<string> | null
  /**
   *
   * @type {Array<ScaniaReadMoreTextNode>}
   * @memberof ItemReadMore
   */
  text?: Array<ScaniaReadMoreTextNode> | null
}

/**
 * Check if a given object implements the ItemReadMore interface.
 */
export function instanceOfItemReadMore(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function ItemReadMoreFromJSON(json: any): ItemReadMore {
  return ItemReadMoreFromJSONTyped(json, false)
}

export function ItemReadMoreFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ItemReadMore {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'Id') ? undefined : json['Id'],
    imageUrls: !exists(json, 'ImageUrls') ? undefined : json['ImageUrls'],
    text: !exists(json, 'Text')
      ? undefined
      : json['Text'] === null
      ? null
      : (json['Text'] as Array<any>).map(ScaniaReadMoreTextNodeFromJSON),
  }
}

export function ItemReadMoreToJSON(value?: ItemReadMore | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    Id: value.id,
    ImageUrls: value.imageUrls,
    Text:
      value.text === undefined
        ? undefined
        : value.text === null
        ? null
        : (value.text as Array<any>).map(ScaniaReadMoreTextNodeToJSON),
  }
}
