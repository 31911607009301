import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import type { RootState } from './index'
import { FactoryModelsState, MarketLanguageState } from './types'
import { EtelMarketAndLanguage, StartupDataBundle } from '../api/startup'
import {
  BuildInfoResponseWrapper,
  GuiMarketSettings,
  ScaniaSystemInfo,
} from '../api/generated'
import { setError } from './sessionDataSlice'

// TODO: This Typescript porting is only just started. Replace all "any" types
// with the actual types and define new types where nedded.
// TODO: Make this entire object nullable instead of its members.
export interface AppState {
  //dealer_locator_enabled: any
  debug_email: string
  factory_models: FactoryModelsState
  factory_models_mode: number
  hasScrollWheel: boolean | null

  /** Intended for the startup code. */
  initialMarketLanguage: EtelMarketAndLanguage | null

  isMouseEnabled: boolean | null
  isTouchCapable: boolean | null
  marketSettings: GuiMarketSettings | null

  /** TODO: Merge this into startupData? */
  marketLanguage: MarketLanguageState | null

  rfqEnabled: boolean | null
  rfq_requires_dealer_id: boolean | null
  show_preview_texts: boolean | null
  supportWebp: boolean | null
  texts: Record<string, string | null | undefined>
  versionInfo: null | (BuildInfoResponseWrapper & ScaniaSystemInfo)
}

var initialState: AppState = {
  //dealer_locator_enabled: null,
  debug_email: '',
  factory_models: {
    factoryModels: null,
    hasBevModels: false,
    mode: 0, // TODO: Eliminate duplicate?
  },
  factory_models_mode: 0,

  initialMarketLanguage: null,
  isMouseEnabled: null,
  hasScrollWheel: null,
  isTouchCapable: null,
  marketLanguage: null,
  marketSettings: null,
  rfqEnabled: null,
  rfq_requires_dealer_id: null,
  show_preview_texts: false,
  supportWebp: null,
  texts: {},
  versionInfo: null,
}

//#region Mutable globals shenanigans
/**
 * TODO: Review global variables on window later. Do we need them?
 *
 * Nic: The global prepopulation of the redux state is there due to the
 * preloader that is started before react is initiated.
 * There is a part of the state that keeps track of if critical tasks
 * has been loaded and when they are, ends the preloader. (think this
 * happends in app.js)
 * Also I do take the opportunity to set some states directly.
 * After the critical tasks are cleared and preloader ended the deferred
 * tasks are loaded.
 * My opinion is that the preloader being outside react does not improve
 * the UX in an agreeable proportion to the amount of complexity it adds
 * to the code.
 * But it works and the complexity is limited and not unbearable so there
 * is no rush changing anything.
 *
 * TODO: Rewrite the preloader screen as part of the React app.
 */
declare global {
  interface Window {
    tempStartupCriticalState: any
  }
}
if (window.tempStartupCriticalState) {
  initialState = {
    ...initialState,
    ...window.tempStartupCriticalState.appData,
  }
}
//#endregion Mutable globals shenanigans

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setUserHasMouseEnabledDevice: (state, action: PayloadAction<boolean>) => {
      state.isMouseEnabled = action.payload
    },
    setUserHasMouseWheelEnabledDevice: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.hasScrollWheel = action.payload
    },
    setInitData: (state, action: PayloadAction<StartupDataBundle>) => {
      if (
        !action.payload.cultureInfo ||
        !action.payload.cultureInfo.languageIso
      )
        //dispatch(
        //  setError({
        //    errorLevel: 2,
        //    friendlyErrorMessage: 'Lost contact with server. Try again.',
        //    errorMessage: `API result didn't contain session ID.`,
        //  }),
        //)
        setError({
          errorLevel: 2,
          friendlyErrorMessage: 'Lost contact with server. Try again.',
          errorMessage: `API result didn't contain session ID.`,
        })
      else {
        const newMarkLang: MarketLanguageState = {
          ...state.marketLanguage,
          ...action.payload.marketLanguage,
          isoLanguageCode: action.payload.cultureInfo.languageIso,
          isoMarketCode: action.payload.cultureInfo.marketIso,
          isoLocalization: action.payload.cultureInfo.localization,
          marketFullname: action.payload.marketSettings.marketFullName,
          optionalLanguages: action.payload.marketSettings.languages,
        }
        state.marketLanguage = newMarkLang
        state.texts = action.payload.localization
        state.factory_models = {
          mode: action.payload.marketSettings.factoryModelsMode,
          factoryModels: action.payload.factoryModels,
          hasBevModels:
            action.payload.factoryModels?.response.models.find(
              (m) => m.bevPropulsion,
            ) !== undefined,
        }
        state.rfqEnabled = action.payload.marketSettings.rfqEnabled
        state.versionInfo = {
          ...action.payload.buildInfo,
          ...action.payload.statelessSystemInfo,
        }
        const modifiedMarketSettings: GuiMarketSettings = {
          ...action.payload.marketSettings,
        }
        if (
          !modifiedMarketSettings.bevCampaignEnabled &&
          !modifiedMarketSettings.superCampaignEnabled
        ) {
          modifiedMarketSettings.useCampaignVideo = false
        }
        state.marketSettings = modifiedMarketSettings
      }
    },
  },
})

export const {
  setUserHasMouseEnabledDevice,
  setUserHasMouseWheelEnabledDevice,
  setInitData,
} = appSlice.actions

export const getAppState = (state: RootState) => state.appData
export const getFactoryModelsState = (state: RootState) =>
  state.appData.factory_models
export const getMarketLanguageState = (state: RootState) =>
  state.appData.marketLanguage
export const getMarketSettingsState = (state: RootState) =>
  state.appData.marketSettings
export const getRfqEnabledState = (state: RootState) => state.appData.rfqEnabled
export const getSupportsWebpState = (state: RootState) =>
  state.appData.supportWebp
export const getTextsState = (state: RootState) => state.appData.texts
export const getTouchCapableState = (state: RootState) =>
  state.appData.isTouchCapable
export const getVersionInfoState = (state: RootState) =>
  state.appData.versionInfo
export const hasScrollWheelState = (state: RootState) =>
  state.appData.hasScrollWheel
export const getInitialMarketLanguageState = (state: RootState) =>
  state.appData.initialMarketLanguage

export default appSlice.reducer
