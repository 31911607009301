import styled from 'styled-components'
import { SvgScaniaWordmark } from '../../components/SvgImports'
import useTexts from '../../utils/useTexts'
import { SuperCampaignData } from './SuperCampaignData'
import { SuperCampaignFooter } from './SuperCampaignFooter'

const BlueFooterAreaRoot = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: var(--tds-blue-800);
  width: 100%;
  height: 100%;
  padding: 24px;
`

const Divider = styled.div`
  border-top-color: var(--tds-blue-600);
  border-top-style: solid;
  border-top-width: thin;
  margin-bottom: 24px;
  width: 100%;
`

const LogotypeWrapper = styled.div`
  align-items: flex-end;
  align-self: flex-end;
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  justify-self: flex-end;

  svg {
    height: 22px;
    width: auto;
    path {
      fill: var(--tds-white);
    }
  }
`

const CopyTextline = styled.p`
  margin: 0;
`

interface PortraitFooterComponentProps {
  $displayAtScreenMaxWidth: number
}

const PortraitFooterRoot = styled.div<PortraitFooterComponentProps>`
  align-items: center;
  background-color: white;
  display: none;
  height: var(--footer-height-mobile);
  min-height: var(--footer-height-mobile);
  position: relative;
  width: 100%;

  // TODO: Wednesday, consider using the breakpoints in Build Mode instead of
  // the orientation: portrait solution. If that seems to take too much work,
  // allow this component to use two modes for layout, breakpoint by pixel width
  // and the old way.

  @media screen and (max-width: ${(props) =>
      props.$displayAtScreenMaxWidth}px) {
    display: flex;
  }
`

const SuperCampaignFooterWrapper = styled.div`
  width: 100%;
  margin-bottom: 24px;
`

interface PortraitFooterProps {
  displayAtScreenMaxWidth: number
  superCampaignData?: SuperCampaignData
}

export function PortraitFooter({
  displayAtScreenMaxWidth,
  superCampaignData,
}: PortraitFooterProps): JSX.Element {
  const t = useTexts()
  const copyTextLines = t('SCANIA_COPYRIGHT').trim().split('\n')

  return (
    <PortraitFooterRoot
      data-name="Footer"
      $displayAtScreenMaxWidth={displayAtScreenMaxWidth}
    >
      <BlueFooterAreaRoot>
        {superCampaignData && (
          <SuperCampaignFooterWrapper>
            <SuperCampaignFooter {...superCampaignData} />
          </SuperCampaignFooterWrapper>
        )}
        <Divider />
        <span
          style={{ color: 'var(--tds-grey-600)' }}
          className="tds-detail-05"
        >
          {copyTextLines.map((line, i) => (
            <CopyTextline key={'copy-text-line-' + i + line}>
              {line}
            </CopyTextline>
          ))}
        </span>
        <LogotypeWrapper>
          <SvgScaniaWordmark />
        </LogotypeWrapper>
      </BlueFooterAreaRoot>
    </PortraitFooterRoot>
  )
}
