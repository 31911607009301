/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { TryChangeConfigResult } from './TryChangeConfigResult'
import {
  TryChangeConfigResultFromJSON,
  TryChangeConfigResultFromJSONTyped,
  TryChangeConfigResultToJSON,
} from './TryChangeConfigResult'

/**
 *
 * @export
 * @interface TryChangeConfigResponseWrapper
 */
export interface TryChangeConfigResponseWrapper {
  /**
   *
   * @type {TryChangeConfigResult}
   * @memberof TryChangeConfigResponseWrapper
   */
  readonly success?: TryChangeConfigResult | null
  /**
   *
   * @type {string}
   * @memberof TryChangeConfigResponseWrapper
   */
  error?: string | null
}

/**
 * Check if a given object implements the TryChangeConfigResponseWrapper interface.
 */
export function instanceOfTryChangeConfigResponseWrapper(
  value: object,
): boolean {
  let isInstance = true

  return isInstance
}

export function TryChangeConfigResponseWrapperFromJSON(
  json: any,
): TryChangeConfigResponseWrapper {
  return TryChangeConfigResponseWrapperFromJSONTyped(json, false)
}

export function TryChangeConfigResponseWrapperFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): TryChangeConfigResponseWrapper {
  if (json === undefined || json === null) {
    return json
  }
  return {
    success: !exists(json, 'success')
      ? undefined
      : TryChangeConfigResultFromJSON(json['success']),
    error: !exists(json, 'error') ? undefined : json['error'],
  }
}

export function TryChangeConfigResponseWrapperToJSON(
  value?: TryChangeConfigResponseWrapper | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    error: value.error,
  }
}
