/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GowizardUndoV2Request } from './GowizardUndoV2Request'
import {
  GowizardUndoV2RequestFromJSON,
  GowizardUndoV2RequestFromJSONTyped,
  GowizardUndoV2RequestToJSON,
} from './GowizardUndoV2Request'

/**
 * Input structure for gowizard_continue_v2.
 * @export
 * @interface GowizardContinueV2Request
 */
export interface GowizardContinueV2Request {
  /**
   *
   * @type {GowizardUndoV2Request}
   * @memberof GowizardContinueV2Request
   */
  state: GowizardUndoV2Request
  /**
   *
   * @type {Array<string>}
   * @memberof GowizardContinueV2Request
   */
  selection: Array<string>
}

/**
 * Check if a given object implements the GowizardContinueV2Request interface.
 */
export function instanceOfGowizardContinueV2Request(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'state' in value
  isInstance = isInstance && 'selection' in value

  return isInstance
}

export function GowizardContinueV2RequestFromJSON(
  json: any,
): GowizardContinueV2Request {
  return GowizardContinueV2RequestFromJSONTyped(json, false)
}

export function GowizardContinueV2RequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GowizardContinueV2Request {
  if (json === undefined || json === null) {
    return json
  }
  return {
    state: GowizardUndoV2RequestFromJSON(json['state']),
    selection: json['selection'],
  }
}

export function GowizardContinueV2RequestToJSON(
  value?: GowizardContinueV2Request | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    state: GowizardUndoV2RequestToJSON(value.state),
    selection: value.selection,
  }
}
