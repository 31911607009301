/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ReadMoreResources } from './ReadMoreResources'
import {
  ReadMoreResourcesFromJSON,
  ReadMoreResourcesFromJSONTyped,
  ReadMoreResourcesToJSON,
} from './ReadMoreResources'
import type { ScaniaReadMoreTextNode } from './ScaniaReadMoreTextNode'
import {
  ScaniaReadMoreTextNodeFromJSON,
  ScaniaReadMoreTextNodeFromJSONTyped,
  ScaniaReadMoreTextNodeToJSON,
} from './ScaniaReadMoreTextNode'

/**
 *
 * @export
 * @interface GoOption
 */
export interface GoOption {
  /**
   *
   * @type {string}
   * @memberof GoOption
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof GoOption
   */
  label: string
  /**
   * Formatting of information passed on to client
   * @type {Array<string>}
   * @memberof GoOption
   */
  tags: Array<string>
  /**
   *
   * @type {ReadMoreResources}
   * @memberof GoOption
   */
  readmore?: ReadMoreResources | null
  /**
   *
   * @type {string}
   * @memberof GoOption
   */
  description?: string | null
  /**
   *
   * @type {string}
   * @memberof GoOption
   */
  image?: string | null
  /**
   *
   * @type {Array<ScaniaReadMoreTextNode>}
   * @memberof GoOption
   */
  readmoreText?: Array<ScaniaReadMoreTextNode> | null
}

/**
 * Check if a given object implements the GoOption interface.
 */
export function instanceOfGoOption(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'label' in value
  isInstance = isInstance && 'tags' in value

  return isInstance
}

export function GoOptionFromJSON(json: any): GoOption {
  return GoOptionFromJSONTyped(json, false)
}

export function GoOptionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GoOption {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    label: json['label'],
    tags: json['tags'],
    readmore: !exists(json, 'readmore')
      ? undefined
      : ReadMoreResourcesFromJSON(json['readmore']),
    description: !exists(json, 'description') ? undefined : json['description'],
    image: !exists(json, 'image') ? undefined : json['image'],
    readmoreText: !exists(json, 'readmoreText')
      ? undefined
      : json['readmoreText'] === null
      ? null
      : (json['readmoreText'] as Array<any>).map(
          ScaniaReadMoreTextNodeFromJSON,
        ),
  }
}

export function GoOptionToJSON(value?: GoOption | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    label: value.label,
    tags: value.tags,
    readmore: ReadMoreResourcesToJSON(value.readmore),
    description: value.description,
    image: value.image,
    readmoreText:
      value.readmoreText === undefined
        ? undefined
        : value.readmoreText === null
        ? null
        : (value.readmoreText as Array<any>).map(ScaniaReadMoreTextNodeToJSON),
  }
}
