import styled from 'styled-components'
import { SvgCross, SvgLoading } from '../SvgImports'
import { useCallback, useEffect, useRef, useState } from 'react'
import { ReadMoreData } from '../../store/types'
import { useClient } from '../../utils/useClient'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { TestElementTypeId } from '../../types/TestAttributeId'
import { BreakpointWidthPx } from '../../css/BreakpointWidthPx'
import { ReadMoreContent } from './ReadMoreContent'
import {
  closePanelReadMore,
  selectSidePanelState,
  setReadMoreData,
} from '../../store/sidePanelSlice'
import { getMarketLanguageState } from '../../store/appSlice'
import { getSessionInitDataState } from '../../store/sessionDataSlice'

interface ReadMoreRootProps {
  $sidePanelIsOpen: boolean
}

const ReadMoreRoot = styled.div<ReadMoreRootProps>`
  height: calc(100% - var(--header-height));
  width: var(--side-panel-width);
  background-color: var(--tds-white);
  position: absolute;

  // TODO: Revisit and try to eliminate.
  z-index: 10;

  top: var(--header-height);
  border: 1px solid var(--tds-grey-300);
  overflow-y: scroll;

  --sidepanel-transition-duration: 250ms;
  right: ${(props) =>
    props.$sidePanelIsOpen ? '0px' : 'calc(0px - var(--side-panel-width))'};
  visibility: ${(props) => (props.$sidePanelIsOpen ? 'visible' : 'hidden')};
  transition-duration: var(--sidepanel-transition-duration);
  transition-property: right, visibility;

  @media screen and (max-width: ${BreakpointWidthPx.Tablet}px) {
    height: 100%;
    top: 0;
    max-width: 100vw;
  }
`

const CloseIconWrapper = styled.div`
  border-bottom: 1px solid var(--tds-grey-300);
  cursor: pointer;

  svg {
    height: 32px;
    width: 32px;
    margin: 12px;
  }
`

const TextContainer = styled.div<SpinnerProps>`
  margin: 16px;
  display: ${(props) => (props.$showSpinner ? 'none' : '')};
`

const Header = styled.h4`
  margin-bottom: 16px;
`

const ReadmoreImage = styled.img`
  max-width: 100%;
`

const ReadmoreImageSmallContainer = styled.div``

interface ReadMorImageSmallProps {
  $isSelectedImage: boolean
}

const ReadmoreImageSmall = styled.img<ReadMorImageSmallProps>`
  width: 20%;
  padding: 4px;
  overflow: hidden;
  cursor: pointer;
  border: ${(props) =>
    props.$isSelectedImage ? '1px solid var(--tds-blue-300)' : ''};

  img {
    position: relative;
    display: inline-block;
    height: auto;
    width: 100%;
  }
`

const Text = styled.div`
  font-size: 14px;
`

interface SpinnerProps {
  $showSpinner: boolean
}

const SpinnerWrapper = styled.div<SpinnerProps>`
  display: ${(props) => (props.$showSpinner ? '' : 'none')};
  margin: 16px;
  height: 40px;

  svg {
    color: var(--tds-blue-600);
  }
`

// Id and short text, the readmore text and images will be loaded by this
// component, except for some items from the Factory Models page that is
// currently handled differently. TODO: Consider unifying the readmore behavior.
export interface ReadMoreEntry {
  id: string

  /**
   * Usually "short text".
   *
   * TODO: Investigate if this can be removed with the latest version of
   * get_readmore.
   */
  title: string | null
}

export function SidePanelReadMore(): JSX.Element {
  const dispatch = useAppDispatch()
  const refSidePanelReadMore = useRef<HTMLDivElement | null>(null)
  const client = useClient()
  const marketLanguage = useAppSelector(getMarketLanguageState)
  const sessionInitData = useAppSelector(getSessionInitDataState)
  const sessionId = sessionInitData?.sessionId
  const {
    readMoreOpen: sidePanelReadMoreIsOpen,
    readMoreData,
    readMoreNode,
  } = useAppSelector(selectSidePanelState)
  const [currentImageUrl, setCurrentImageUrl] = useState<string | null>(null)
  const [showSpinner, setShowSpinner] = useState<boolean>(false)

  const handleReadMoreBuildMode = useCallback(async () => {
    const id = readMoreNode?.id
    if (!marketLanguage || !client || !id || !sessionId) {
      return
    }
    dispatch(setReadMoreData(null))
    setCurrentImageUrl(null)
    setShowSpinner(true)
    const readMoreResult = await client.getReadmore({
      etelLanguage: marketLanguage.language,
      etelMarket: marketLanguage.market,
      itemId: id,
    })
    setShowSpinner(false)
    const images = readMoreResult.resources.filter(
      (resource) => resource.resourceType === 'Image',
    )
    let imageUrls: string[] = []
    if (images && images?.length > 0) {
      images.forEach((image) => {
        if (image.path) {
          imageUrls.push(encodeURI(image.path))
        }
      })
      setCurrentImageUrl(imageUrls[0])
    }

    // TODO: Provide the submenu name as well since most options are called
    // "with" or "without".
    const title = readMoreNode?.title || readMoreResult.shortText || ''

    const newData: ReadMoreData = {
      label: title,
      images: imageUrls,
      text: readMoreResult.readmoreText,
    }
    dispatch(setReadMoreData(newData))
  }, [client, marketLanguage, sessionId, dispatch, readMoreNode])

  // Figure out which readmore data source to display.
  //
  // TODO: Consider unifying this dataflow in the Redux Actions or Reducer
  // layers instead?
  //
  // TODO: Every use case should be covered by getReadmoreV2 now, use a single
  // item id in Redux or as a component property as input for this component.
  useEffect(() => {
    if (!sidePanelReadMoreIsOpen) {
      return
    }
    if (readMoreNode) {
      handleReadMoreBuildMode()
    }
  }, [handleReadMoreBuildMode, readMoreNode, sidePanelReadMoreIsOpen])

  // set current image url
  useEffect(() => {
    if (!sidePanelReadMoreIsOpen) {
      return
    }
    if (readMoreData) {
      setCurrentImageUrl(readMoreData.images[0])
    }
  }, [handleReadMoreBuildMode, sidePanelReadMoreIsOpen, readMoreData])

  const handleCloseClick = useCallback(() => {
    dispatch(closePanelReadMore())
    dispatch(setReadMoreData(null))
    setCurrentImageUrl(null)
  }, [dispatch])

  const handleUserClickOnScreen = useCallback(
    (e: MouseEvent) => {
      if (!sidePanelReadMoreIsOpen) {
        return
      }
      const currentElement = refSidePanelReadMore.current
      const composedPath = e.composedPath()
      const clickIsOutsideSidePanel =
        -1 === composedPath.findIndex((o) => o === currentElement)
      if (clickIsOutsideSidePanel) {
        handleCloseClick()
      }
    },
    [refSidePanelReadMore, handleCloseClick, sidePanelReadMoreIsOpen],
  )

  // Close sidePanel on click outside sidepanel
  useEffect(() => {
    document.addEventListener('click', handleUserClickOnScreen)
    return () => {
      document.removeEventListener('click', handleUserClickOnScreen)
    }
  }, [handleUserClickOnScreen])

  const handleImageClick = (e: any) => {
    setCurrentImageUrl(e.target.src)
  }

  return (
    <ReadMoreRoot
      ref={refSidePanelReadMore}
      $sidePanelIsOpen={sidePanelReadMoreIsOpen}
    >
      <CloseIconWrapper
        data-test-element-type={
          TestElementTypeId.GuidedOfferingInfoReadMoreCloseIcon
        }
        onClick={handleCloseClick}
      >
        <SvgCross />
      </CloseIconWrapper>
      <SpinnerWrapper $showSpinner={showSpinner}>
        <SvgLoading />
      </SpinnerWrapper>
      <TextContainer $showSpinner={showSpinner}>
        <Header>{readMoreData?.label || ''}</Header>
        {currentImageUrl ? <ReadmoreImage src={currentImageUrl} /> : <></>}
        <ReadmoreImageSmallContainer>
          {readMoreData?.images && readMoreData.images.length > 1 ? (
            readMoreData.images.map((url, i) => {
              return (
                <ReadmoreImageSmall
                  $isSelectedImage={url === currentImageUrl}
                  src={url}
                  key={'' + i + ', ' + url}
                  onClick={(event) => handleImageClick(event)}
                />
              )
            })
          ) : (
            <></>
          )}
        </ReadmoreImageSmallContainer>

        <Text
          data-test-element-type={
            TestElementTypeId.GuidedOfferingInfoReadMoreText
          }
        >
          {readMoreData?.text && (
            <ReadMoreContent readMore={readMoreData.text}></ReadMoreContent>
          )}
        </Text>
      </TextContainer>
    </ReadMoreRoot>
  )
}
