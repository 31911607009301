import styled from 'styled-components'
import { BreakpointWidthPx } from '../../../css/BreakpointWidthPx'
import { GO_LOW_HEIGHT_LIMIT_ADAPTION_OPERATION } from '../../../constants'
import { TdsButton, TdsIcon } from '@scania/tegel-react'
import useTexts from '../../../utils/useTexts'

interface ShowUpgradeYourFleet {
  $showUpgradeYourFleet: boolean
}

const ShowRoomRoot = styled.div<ShowUpgradeYourFleet>`
  margin-top: 80px;
  display: flex;
  justify-content: flex-end;

  padding-right: 8px;

  align-items: ${(props) => (props.$showUpgradeYourFleet ? 'end' : 'center')};

  flex-direction: ${(props) =>
    props.$showUpgradeYourFleet ? 'column' : 'row'};

  @media screen and (max-width: ${BreakpointWidthPx.Tablet}px) {
    flex-direction: column;
    align-items: center;
    margin-top: 24px;
  }

  // Reduce margin at low desktop screen heights
  @media screen and (min-width: ${BreakpointWidthPx.Tablet}px) and (max-height: ${GO_LOW_HEIGHT_LIMIT_ADAPTION_OPERATION}) {
    margin-top: 32px;
  }
`

const OptionText = styled.div<ShowUpgradeYourFleet>`
  font-style: 'normal';
  font-family: 'Scania Sans Bold', Arial, Helvetica, sans-serif;
  font-weight: normal;
  color: var(--tds-blue-800);
  margin-bottom: ${(props) => (props.$showUpgradeYourFleet ? '16px' : '0px')};

  @media screen and (min-width: ${BreakpointWidthPx.Tablet}px) {
    padding-right: ${(props) => (props.$showUpgradeYourFleet ? '0px' : '20px')};
  }

  @media screen and (max-width: ${BreakpointWidthPx.Tablet}px) {
    padding-right: ${(props) => (props.$showUpgradeYourFleet ? '0px' : '16px')};
    padding-left: 16px;
    padding-bottom: 16px;
    text-align: center;
  }
`

export interface ShowroomContainerProps {
  handleFirmModelClick: VoidFunction
  showUpgradeYourFleet: boolean
}

export const ShowRoomContainer = ({
  handleFirmModelClick,
  showUpgradeYourFleet,
}: ShowroomContainerProps) => {
  const t = useTexts()

  return (
    <ShowRoomRoot $showUpgradeYourFleet={showUpgradeYourFleet}>
      <OptionText $showUpgradeYourFleet={showUpgradeYourFleet}>
        {t('FM_PRODUCT_RANGE')}
      </OptionText>
      <TdsButton
        variant="secondary"
        size="sm"
        onClick={handleFirmModelClick}
        text={t('FM_OPEN_SHOWROOM')}
      >
        <TdsIcon slot="icon" name="chevron_right" size="16px"></TdsIcon>
      </TdsButton>
    </ShowRoomRoot>
  )
}
