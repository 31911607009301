import { HTMLProps } from 'react'
import styled, { css } from 'styled-components'
import { TestElementTypeId } from '../../types/TestAttributeId'
import { TdsButton, TdsIcon } from '@scania/tegel-react'

interface StartButtonRootProps {
  $primary: boolean
}

const StartButtonRoot = styled(TdsButton)<StartButtonRootProps>`
  button {
    // tds-headline-06
    font-family: 'Scania Sans', Arial, Helvetica, sans-serif !important;
    font-weight: bold !important;
    font-size: 14px !important;
    line-height: 16px !important;
    letter-spacing: -0.02em !important;

    color: white !important;
    padding-left: min(32px, 4vw) !important;
    padding-right: min(32px, 4vw) !important;

    ${({ $primary }) =>
      $primary
        ? css`
            background-color: var(--tds-blue-400) !important;
            border-color: var(--tds-blue-400) !important;
          `
        : css`
            background-color: none !important;
            border-color: white !important;
          `};
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      ${({ $primary }) =>
        $primary
          ? css`
              filter: brightness(1.1);
            `
          : css`
              background-color: rgba(255, 255, 255, 0.1) !important;
            `}
    }
  }
`

export interface StartButtonProps extends HTMLProps<HTMLButtonElement> {
  handleClick: (() => void) | null
  primary: boolean
  text: string
  testId: TestElementTypeId
}

export const StartButton = ({
  handleClick,
  primary,
  testId,
  text,
}: StartButtonProps): JSX.Element => {
  const arrowSize = '20px'
  return (
    <StartButtonRoot
      $primary={primary}
      variant={primary ? 'primary' : 'secondary'}
      fullbleed
      onClick={handleClick || undefined}
      text={text}
      data-test-element-type={testId}
      className="tds-headline-06"
    >
      <TdsIcon
        slot="icon"
        name="arrow_right"
        size="20px"
        style={{ minHeight: arrowSize, minWidth: arrowSize, color: 'white' }}
      ></TdsIcon>
    </StartButtonRoot>
  )
}
