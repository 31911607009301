import convertNewlineToJSX from '../../utils/convertNewlineToJSX'
import { ScaniaReadMoreTextNode } from '../../api/generated'

export interface ReadMoreContentProps {
  readMore: ScaniaReadMoreTextNode[]
}

export function ReadMoreContent(props: ReadMoreContentProps): JSX.Element {
  const renderReadMoreContent = (data: ScaniaReadMoreTextNode[]) => {
    let result: JSX.Element[] = []
    let listItems: JSX.Element[] | null = null
    let keycounter = 0

    data.forEach((item) => {
      if (item.textParagraph) {
        if (listItems) {
          result.push(<ul key={'ul' + keycounter}>{listItems}</ul>)
          listItems = null
          keycounter++
        }
        let paragraph = convertNewlineToJSX(item.textParagraph)
        if (paragraph) {
          result.push(paragraph)
          keycounter++
        }
      } else if (item.textList) {
        if (!listItems) {
          listItems = []
        }
        listItems.push(<li key={'li' + keycounter}>{item.textList}</li>)
        keycounter++
      }
    })

    if (listItems) {
      result.push(<ul key={'ul' + keycounter}>{listItems}</ul>)
    }

    return result
  }

  return <>{renderReadMoreContent(props.readMore)}</>
}
