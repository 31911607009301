import { useEffect } from 'react'
import styled from 'styled-components'
import { SvgInfo } from '../../../components/SvgImports'
import { CardData } from '../../../types/GuidedOffering/CardData'
import { TestElementTypeId } from '../../../types/TestAttributeId'
import { ScaniaAdobeTrackingPageName } from '../../../utils/adobeAnalytics'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { GoChoiceClickHandler } from '../goChoiceClickHook'
import { GoReadMoreClickHandler } from '../goReadmoreUtil'
import { IconInfoWrapper, TextAndInfoWrapper } from './TextAndInfoWrapper'
import { BreakpointWidthPx } from '../../../css/BreakpointWidthPx'
import { getMarketLanguageState } from '../../../store/appSlice'
import { pushPageViewTrackingEvent } from '../../../store/sessionDataSlice'

// Based on AvailableArea that used to be the root of this component.
const WheelConfPageRoot = styled.div`
  height: 100%;
  width: 100vw;
  margin-top: 48px;
  display: flex;
  justify-content: center;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  padding-bottom: 0.25rem;

  @media screen and (max-width: ${BreakpointWidthPx.Tablet}px) {
    margin-top: 16px;
  }
`

const Layout = styled.div`
  --colums: 1;
  display: grid;
  grid-gap: 0px;
  grid-template-columns: repeat(var(--colums), minmax(150px, 1fr));

  // Possibly related to CustomScrollbar. TODO: Investigate later.
  margin-left: -8px;

  max-width: min(100vw, 1200px);

  @media screen and (min-width: ${BreakpointWidthPx.Tablet}px) {
    --colums: 2;
  }
`
const Card = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 1em;

  @media screen and (min-width: ${BreakpointWidthPx.Tablet}px) {
    margin-bottom: 3em;
  }

  img {
    width: 100%;
  }
`

export interface WheelconfPageProps {
  cards: CardData[]
  etelFamilyId: string
  handleCardClick: GoChoiceClickHandler
  handleReadMoreClick: GoReadMoreClickHandler
}

const WheelconfPage = ({
  cards,
  etelFamilyId,
  handleCardClick,
  handleReadMoreClick,
}: WheelconfPageProps): JSX.Element => {
  const dispatch = useAppDispatch()
  const marketLanguage = useAppSelector(getMarketLanguageState)

  // Adobe user tracking.
  useEffect(() => {
    if (!marketLanguage) {
      return
    }
    dispatch(
      pushPageViewTrackingEvent({
        pageName: ScaniaAdobeTrackingPageName.GoWheelConfig,
        marketLanguage,
      }),
    )
  }, [dispatch, marketLanguage])

  return (
    <WheelConfPageRoot>
      <Layout>
        {cards.map((card, i) => (
          <Card
            data-test-element-type={
              TestElementTypeId.GuidedOfferingChoiceButton
            }
            data-test-element-id={card.id}
            data-test-go-group-id={etelFamilyId}
            key={card.id}
            onClick={(e) =>
              handleCardClick({ id: card.id, isSkippingToNext: false })
            }
          >
            <img src={card.imageUrl} alt={card.header} />
            <TextAndInfoWrapper>
              {card.readmore && (
                <IconInfoWrapper
                  onClick={(e) => handleReadMoreClick(e, card.id)}
                >
                  <SvgInfo />
                </IconInfoWrapper>
              )}
              <h3>{card.header}</h3>
            </TextAndInfoWrapper>
          </Card>
        ))}
      </Layout>
    </WheelConfPageRoot>
  )
}

export default WheelconfPage
