import { EtelMarketAndLanguage } from '../api/startup'
import { EtelVariant } from './EtelVariant'
import {
  UrlParamMode,
  getUrlCidBevSemiliveParameters,
} from './getUrlCidParameter'
import { getUrlParametersToString } from './getUrlParametersToString'

export interface RelativeUrl {
  path: string
  params: URLSearchParams
}

export function buildAbsolutePublicConfigLink(
  marketLanguage: EtelMarketAndLanguage,
  publicConfigId: string,
) {
  const relativeUrl = buildRelativePublicConfigLink(
    marketLanguage,
    publicConfigId,
  )
  const params = relativeUrl.params
  return (
    window.location.origin + relativeUrl.path + getUrlParametersToString(params)
  )
}

export function buildRelativePublicConfigLink(
  marketLanguage: EtelMarketAndLanguage,
  publicConfigId: string,
): RelativeUrl {
  const urlCidParams = getUrlCidBevSemiliveParameters(UrlParamMode.ExcludeBev)
  const url = `/link/${marketLanguage.market}/${marketLanguage.language}/${publicConfigId}`
  const relativeUrl = {
    path: url,
    params: urlCidParams,
  }
  return relativeUrl
}

// Should point to the custom Facebook landing page document that provides the
// Open Graph tags from the serverside.
export function buildAbsoluteFacebookShareLink(
  marketLanguage: EtelMarketAndLanguage,
  publicConfigId: string,
) {
  return (
    window.location.origin +
    `/index.aspx?etel_market=${marketLanguage.market}&etel_language=${marketLanguage.language}&pubc=${publicConfigId}`
  )
}

export function buildRelativeLandingPageUrl(
  marketLanguage: EtelMarketAndLanguage,
): RelativeUrl {
  const urlParams = getUrlCidBevSemiliveParameters(UrlParamMode.ExcludeBev)
  urlParams.append('etel_market', marketLanguage.market.toString())
  urlParams.append('etel_language', marketLanguage.language.toString())
  const url = `/`
  const relativeUrl = {
    path: url,
    params: urlParams,
  }
  return relativeUrl
}

export function buildRelativeBuildPageUrl(
  marketLanguage: EtelMarketAndLanguage,
): RelativeUrl {
  const urlParams = getUrlCidBevSemiliveParameters(UrlParamMode.ExcludeBev)
  const url = `/build/${marketLanguage.market}/${marketLanguage.language}/`
  const relativeUrl: RelativeUrl = {
    path: url,
    params: urlParams,
  }
  return relativeUrl
}

export function buildRelativeFactoryModelsBuildModeLink(
  factoryModelId: number,
  etelVariants: EtelVariant[],
  marketLanguage: EtelMarketAndLanguage,
): RelativeUrl {
  const relativeUrl = buildRelativeBuildPageUrl(marketLanguage)
  relativeUrl.params.append('fm', factoryModelId.toString())
  etelVariants.forEach((variant) => {
    relativeUrl.params.append(`v-${variant.family}`, variant.execution)
  })
  // 'v-00889=CG&v-00758=AA&...'
  return relativeUrl
}

export function buildPdfUrl(
  serviceUrl: string,
  sessionId: string,
  etelLanguageCode: number,
  marketDenomination: string | null,
): string {
  const filterName = 'summary_filter'
  var url =
    serviceUrl + '/generate_pdf?language=' + etelLanguageCode + '&session_id='
  url += sessionId
  if (!marketDenomination) {
    // TODO: Revisit this, make sure that we always get this from the server
    // side?
    marketDenomination = '0'
  }
  url += '&title=' + marketDenomination
  url += '&summary_filter=' + filterName
  return url
}
