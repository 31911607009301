/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { SendShareEmailParamsShareEmailInfo } from './SendShareEmailParamsShareEmailInfo'
import {
  SendShareEmailParamsShareEmailInfoFromJSON,
  SendShareEmailParamsShareEmailInfoFromJSONTyped,
  SendShareEmailParamsShareEmailInfoToJSON,
} from './SendShareEmailParamsShareEmailInfo'

/**
 *
 * @export
 * @interface SendShareMailRequest
 */
export interface SendShareMailRequest {
  /**
   *
   * @type {string}
   * @memberof SendShareMailRequest
   */
  sessionId: string
  /**
   *
   * @type {SendShareEmailParamsShareEmailInfo}
   * @memberof SendShareMailRequest
   */
  shareEmailInfo: SendShareEmailParamsShareEmailInfo
  /**
   *
   * @type {string}
   * @memberof SendShareMailRequest
   */
  language: string
}

/**
 * Check if a given object implements the SendShareMailRequest interface.
 */
export function instanceOfSendShareMailRequest(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'sessionId' in value
  isInstance = isInstance && 'shareEmailInfo' in value
  isInstance = isInstance && 'language' in value

  return isInstance
}

export function SendShareMailRequestFromJSON(json: any): SendShareMailRequest {
  return SendShareMailRequestFromJSONTyped(json, false)
}

export function SendShareMailRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SendShareMailRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    sessionId: json['session_id'],
    shareEmailInfo: SendShareEmailParamsShareEmailInfoFromJSON(
      json['share_email_info'],
    ),
    language: json['language'],
  }
}

export function SendShareMailRequestToJSON(
  value?: SendShareMailRequest | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    session_id: value.sessionId,
    share_email_info: SendShareEmailParamsShareEmailInfoToJSON(
      value.shareEmailInfo,
    ),
    language: value.language,
  }
}
