/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { SummaryItem } from './SummaryItem'
import {
  SummaryItemFromJSON,
  SummaryItemFromJSONTyped,
  SummaryItemToJSON,
} from './SummaryItem'

/**
 *
 * @export
 * @interface ConfigChangeResultMiniSummary
 */
export interface ConfigChangeResultMiniSummary {
  /**
   *
   * @type {Array<SummaryItem>}
   * @memberof ConfigChangeResultMiniSummary
   */
  items?: Array<SummaryItem> | null
  /**
   *
   * @type {SummaryItem}
   * @memberof ConfigChangeResultMiniSummary
   */
  cabColor?: SummaryItem | null
  /**
   *
   * @type {SummaryItem}
   * @memberof ConfigChangeResultMiniSummary
   */
  chassisColor?: SummaryItem | null
  /**
   *
   * @type {SummaryItem}
   * @memberof ConfigChangeResultMiniSummary
   */
  contrastColor?: SummaryItem | null
}

/**
 * Check if a given object implements the ConfigChangeResultMiniSummary interface.
 */
export function instanceOfConfigChangeResultMiniSummary(
  value: object,
): boolean {
  let isInstance = true

  return isInstance
}

export function ConfigChangeResultMiniSummaryFromJSON(
  json: any,
): ConfigChangeResultMiniSummary {
  return ConfigChangeResultMiniSummaryFromJSONTyped(json, false)
}

export function ConfigChangeResultMiniSummaryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ConfigChangeResultMiniSummary {
  if (json === undefined || json === null) {
    return json
  }
  return {
    items: !exists(json, 'Items')
      ? undefined
      : json['Items'] === null
      ? null
      : (json['Items'] as Array<any>).map(SummaryItemFromJSON),
    cabColor: !exists(json, 'cab_color')
      ? undefined
      : SummaryItemFromJSON(json['cab_color']),
    chassisColor: !exists(json, 'chassis_color')
      ? undefined
      : SummaryItemFromJSON(json['chassis_color']),
    contrastColor: !exists(json, 'contrast_color')
      ? undefined
      : SummaryItemFromJSON(json['contrast_color']),
  }
}

export function ConfigChangeResultMiniSummaryToJSON(
  value?: ConfigChangeResultMiniSummary | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    Items:
      value.items === undefined
        ? undefined
        : value.items === null
        ? null
        : (value.items as Array<any>).map(SummaryItemToJSON),
    cab_color: SummaryItemToJSON(value.cabColor),
    chassis_color: SummaryItemToJSON(value.chassisColor),
    contrast_color: SummaryItemToJSON(value.contrastColor),
  }
}
