import { SESSION_FAILURE } from './errors'
import {
  GoQuestion,
  GoWizardContinueV2Params,
  GuidedOfferingInfo,
  ScaniaConfiguratorApi,
} from './generated'

export const GO_ID_EXIT = 'Exit'

/**
 * Helper for the stepThroughGuidedOfferingIntoBuildMode function.
 */
export function pickNextChoiceOrFail(q?: GoQuestion | null): string {
  if (!q) {
    throw new Error('Expected q to be defined.')
  }
  if (!q.options) {
    throw new Error('Expected q.options to be defined.')
  }
  if (q.options.length < 1) {
    throw new Error('Expected q.options to be non-empty.')
  }
  let opt = q.options
  if (-1 !== opt.findIndex((o) => o.id === GO_ID_EXIT)) {
    // This question allows the "Exit" (go to build mode) choice.
    return GO_ID_EXIT
  }
  // This is a normal Guided Offering question, return default selection,
  // or the first one.
  const selected = q.selected
  const defaultSelectedIndex = Math.max(
    q.options?.findIndex((o) => o.id === selected) || 0,
    0,
  )
  const i = defaultSelectedIndex < opt.length ? defaultSelectedIndex : 0
  return opt[i].id
}

export async function stepThroughGuidedOfferingToEnd(
  client: ScaniaConfiguratorApi,
  goInfo: GuidedOfferingInfo,
): Promise<GuidedOfferingInfo> {
  if (goInfo.state === undefined) {
    throw new Error('Expected goInfo.state to be defined.')
  }
  let newGoInfo = goInfo
  let i = 1
  const maxCount = 20 // Infinite loop guard.
  while (true) {
    if (i >= maxCount) {
      throw new Error('Reached maxCount, avoiding possible infinite loop.')
    }
    const nextChoice = pickNextChoiceOrFail(newGoInfo.question)
    if (!newGoInfo.state) {
      throw new Error('Expected newGoInfo.state to be defined.')
    }
    if (nextChoice === GO_ID_EXIT) {
      break
    }
    const continueParams: GoWizardContinueV2Params = {
      state: newGoInfo.state,
      selection: [nextChoice],
    }
    newGoInfo = (await client.gowizardContinueV2(continueParams))
      .gowizardContinueV2
  }
  return newGoInfo
}

export enum GuidedOfferingExitResult {
  Success,
  SessionFailure,
  ChangeStepError,
  UnknownError,
}

/**
 * Exit Guided Offering and load the configuration into Build Mode.
 */
export async function exitGuidedOfferingAndLoadConfigIntoBuildMode(
  client: ScaniaConfiguratorApi,
  sessionId: string,
  goInfo: GuidedOfferingInfo,
): Promise<GuidedOfferingExitResult> {
  if (!goInfo?.state) {
    throw new Error('Expected goInfo.state to be defined.')
  }
  const exitParams: GoWizardContinueV2Params = {
    state: goInfo.state,
    selection: [GO_ID_EXIT],
  }
  goInfo = (await client.gowizardContinueV2(exitParams)).gowizardContinueV2
  const config = goInfo.config
  if (!config) {
    throw new Error('Expected config to be defined.')
  }
  const loadResult = await client.loadConfigFromString({
    sessionId,
    config: config.data,
    encoding: config.encoding,
  })
  if (loadResult.loadConfigFromString && !loadResult.error) {
    return GuidedOfferingExitResult.Success
  }
  switch (loadResult.error) {
    case SESSION_FAILURE:
      return GuidedOfferingExitResult.SessionFailure
    case 'SECM_CHANGE_STEP_ERROR':
      return GuidedOfferingExitResult.ChangeStepError
    default:
      return GuidedOfferingExitResult.UnknownError
  }
}
