import { useLayoutEffect, useState } from 'react'

export interface ViewportSize {
  viewportWidth: number
  viewportHeight: number
}

const getCurrentViewportSize = (): ViewportSize => {
  return {
    // TODO: Consider if we want to use window.viewport or not.
    // Some related reading: https://developer.mozilla.org/en-US/docs/Web/CSS/Viewport_concepts
    viewportWidth: Math.round(document.documentElement.clientWidth),
    viewportHeight: Math.round(document.documentElement.clientHeight),
  }
}

// TODO: Replace the old way of handling resize (handleResize.js)
export function useLayoutViewportSize() {
  const [size, setSize] = useState<ViewportSize>(getCurrentViewportSize())
  useLayoutEffect(() => {
    function updateSize() {
      setSize(getCurrentViewportSize())
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  return size
}
