/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface MenuInfo
 */
export interface MenuInfo {
  /**
   *
   * @type {string}
   * @memberof MenuInfo
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof MenuInfo
   */
  name?: string | null
  /**
   *
   * @type {string}
   * @memberof MenuInfo
   */
  shortText?: string | null
  /**
   *
   * @type {string}
   * @memberof MenuInfo
   */
  information?: string | null
  /**
   *
   * @type {string}
   * @memberof MenuInfo
   */
  campaignText?: string | null
}

/**
 * Check if a given object implements the MenuInfo interface.
 */
export function instanceOfMenuInfo(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value

  return isInstance
}

export function MenuInfoFromJSON(json: any): MenuInfo {
  return MenuInfoFromJSONTyped(json, false)
}

export function MenuInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): MenuInfo {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    shortText: !exists(json, 'short_text') ? undefined : json['short_text'],
    information: !exists(json, 'information') ? undefined : json['information'],
    campaignText: !exists(json, 'campaign_text')
      ? undefined
      : json['campaign_text'],
  }
}

export function MenuInfoToJSON(value?: MenuInfo | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    name: value.name,
    short_text: value.shortText,
    information: value.information,
    campaign_text: value.campaignText,
  }
}
