/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetScaniaCultureInfoResponseWrapperGetScaniaCultureInfo } from './GetScaniaCultureInfoResponseWrapperGetScaniaCultureInfo'
import {
  GetScaniaCultureInfoResponseWrapperGetScaniaCultureInfoFromJSON,
  GetScaniaCultureInfoResponseWrapperGetScaniaCultureInfoFromJSONTyped,
  GetScaniaCultureInfoResponseWrapperGetScaniaCultureInfoToJSON,
} from './GetScaniaCultureInfoResponseWrapperGetScaniaCultureInfo'

/**
 * Legacy response wrapper structure to allow matching the original
 * configurator web API.
 *
 * TODO: This should be removed after the .Net Core and ScdsClient
 * migration has been completed.
 * @export
 * @interface GetScaniaCultureInfoResponseWrapper
 */
export interface GetScaniaCultureInfoResponseWrapper {
  /**
   *
   * @type {GetScaniaCultureInfoResponseWrapperGetScaniaCultureInfo}
   * @memberof GetScaniaCultureInfoResponseWrapper
   */
  getScaniaCultureInfo: GetScaniaCultureInfoResponseWrapperGetScaniaCultureInfo
}

/**
 * Check if a given object implements the GetScaniaCultureInfoResponseWrapper interface.
 */
export function instanceOfGetScaniaCultureInfoResponseWrapper(
  value: object,
): boolean {
  let isInstance = true
  isInstance = isInstance && 'getScaniaCultureInfo' in value

  return isInstance
}

export function GetScaniaCultureInfoResponseWrapperFromJSON(
  json: any,
): GetScaniaCultureInfoResponseWrapper {
  return GetScaniaCultureInfoResponseWrapperFromJSONTyped(json, false)
}

export function GetScaniaCultureInfoResponseWrapperFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GetScaniaCultureInfoResponseWrapper {
  if (json === undefined || json === null) {
    return json
  }
  return {
    getScaniaCultureInfo:
      GetScaniaCultureInfoResponseWrapperGetScaniaCultureInfoFromJSON(
        json['get_scania_culture_info'],
      ),
  }
}

export function GetScaniaCultureInfoResponseWrapperToJSON(
  value?: GetScaniaCultureInfoResponseWrapper | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    get_scania_culture_info:
      GetScaniaCultureInfoResponseWrapperGetScaniaCultureInfoToJSON(
        value.getScaniaCultureInfo,
      ),
  }
}
