import { useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { GuidedOfferingClientState } from '../../types/GuidedOffering'
import { GuidedOfferingClientStateStatus } from '../../types/GuidedOffering/GuidedOfferingClientState'
import {
  GoFilterMode,
  planNextGoAction,
  syncClientAndBackendState,
} from '../../utils/syncClientAndBackendState'
import { useClient } from '../../utils/useClient'
import {
  FAMILY_ID_APPLICATION,
  FAMILY_ID_CAB,
  FAMILY_ID_CAB_SERIES,
  FAMILY_ID_OPERATION,
  FAMILY_ID_TRUCK_TYPE,
  FAMILY_ID_WHEEL_CONFIGURATION,
} from '../../api/constants'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { urlHasBevParam } from './urlHasBevParam'
import {
  UrlParamMode,
  getUrlCidBevSemiliveParameters,
} from '../../utils/getUrlCidParameter'
import { getUrlParametersToString } from '../../utils/getUrlParametersToString'
import {
  getGuidedOfferingClientState,
  getStartupData,
  hideInitialLoadingScreen,
  setGuidedOfferingState,
} from '../../store/sessionDataSlice'
import { getMarketLanguageState } from '../../store/appSlice'

export interface GuidedOfferingRouteMatchProps {
  desiredselection?: string
}

// The enum variant value must match the routing paths in App.tsx.
// TODO: Move all Guided Offering under the same "page" using /go/ as URL.
export enum GoPageId {
  Operations = 'go-start',
  Grid = 'grid',
  Slider = 'slider',
  Optionals = 'options',
}

/**
 * Page id mapping for the required Guided Offering Etel Family choices.
 *
 * The optionals Etel Family ids doen't need this function to be identified as
 * they arrive as a dedicated property currently called sliderQuestions on the
 * response object for the 'Continue' request.
 */
export function getGoPageIdForEtelFamilyId(etelFamilyId: string): GoPageId {
  switch (etelFamilyId) {
    case FAMILY_ID_OPERATION:
      return GoPageId.Operations
    case FAMILY_ID_APPLICATION:
      return GoPageId.Grid
    case FAMILY_ID_TRUCK_TYPE:
    case FAMILY_ID_WHEEL_CONFIGURATION:
    case FAMILY_ID_CAB_SERIES:
    case FAMILY_ID_CAB:
      return GoPageId.Slider
    default:
      throw new Error('GoPageId mapping is missing for id: ' + etelFamilyId)
  }
}

export interface GoStateSyncSettings {
  pageId: GoPageId
}

/**
 * A React hook for moving the Guided Offering state from the current state to
 * the desired state based on the URL parameters for the GO pages.
 *
 * This piece of code is quite fragile, mostly due to different "pages" sharing
 * this logic in combination with the unnecessarily complicated web API for
 * Guided Offering, be careful.
 *
 * TODO: Simplify the GO web API.
 */
export const useSyncClientAndBackendState = (
  syncSettings: GoStateSyncSettings,
  handleFatalError: () => void,
): GuidedOfferingClientState | null => {
  const waitingForBackendSync = useRef<boolean>(false)
  const client = useClient()
  const params = useParams()
  const goClientState = useAppSelector(getGuidedOfferingClientState)
  const marketLanguage = useAppSelector(getMarketLanguageState)
  const startupData = useAppSelector(getStartupData)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    const asyncWrapper = async () => {
      if (!client) {
        return // Not initialized yet.
      }
      if (!marketLanguage) {
        return // Not initialized yet.
      }
      if (!startupData) {
        return // Not initialized yet.
      }
      if (
        goClientState?.status ===
        GuidedOfferingClientStateStatus.WaitingForBuildMode
      ) {
        return
      }
      if (waitingForBackendSync.current) {
        console.log('Avoiding state sync, waiting for backend...')
        return
      }
      waitingForBackendSync.current = true
      const desiredSelection = params.desiredselection?.split('|')
      if (!desiredSelection) {
        throw new Error('Expected desiredSelected to be defined.')
      }
      try {
        if (
          syncSettings.pageId === GoPageId.Optionals &&
          goClientState &&
          !goClientState?.cards &&
          goClientState.sliderQuestions
        ) {
          // Optional questions phase reached, don't sync this state here and
          // let the OptionalsPage handle it.
          return
        }
        const wantBev = urlHasBevParam()
        const goAction = planNextGoAction(
          syncSettings,
          desiredSelection,
          goClientState,
          wantBev ? GoFilterMode.Bev : GoFilterMode.Normal,
        )
        if (!goAction) {
          let expectedPageId: GoPageId
          if (!goClientState) {
            throw new Error('Expected goClientState to be defined.')
          }
          if (goClientState.sliderQuestions) {
            expectedPageId = GoPageId.Optionals
          } else {
            const currentQuestionEtelFamilyId =
              goClientState?.lastResponse.question?.etelFamily
            if (!currentQuestionEtelFamilyId) {
              throw new Error(
                'Expected currentQuestionEtelFamilyId to be defined.',
              )
            }
            expectedPageId = getGoPageIdForEtelFamilyId(
              currentQuestionEtelFamilyId,
            )
          }
          if (syncSettings.pageId !== expectedPageId) {
            const desiredSelectionEncoded = encodeURIComponent(
              desiredSelection.join('|'),
            )
            const urlCidParams = getUrlCidBevSemiliveParameters(
              UrlParamMode.ExcludeBev,
            )
            const url = `/go/${expectedPageId}/${marketLanguage.market}/${marketLanguage.language}/${desiredSelectionEncoded}`
            const link = url + getUrlParametersToString(urlCidParams)
            dispatch(hideInitialLoadingScreen())
            navigate(link, { replace: true })
            return
          }

          // No action needed, we are at the desired state and page.
          dispatch(hideInitialLoadingScreen())
          return
        }
        const newState = await syncClientAndBackendState(
          syncSettings.pageId,
          marketLanguage,
          startupData.sessionTimeoutSeconds,
          goAction,
          goClientState,
          client,
        )
        dispatch(setGuidedOfferingState(newState))
      } catch (err) {
        console.error(err)
        handleFatalError()
      } finally {
        waitingForBackendSync.current = false
      }
    }
    asyncWrapper()
  }, [
    client,
    dispatch,
    goClientState,
    handleFatalError,
    marketLanguage,
    navigate,
    params,
    startupData,
    syncSettings,
  ])

  return goClientState
}
