import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './index'
import { RfqParams } from '../api/generated'
import {
  ConsequeceOfChangeUserChoice,
  ConsequenceOfChangeDialogData,
} from '../types/ConsequenceOfChangeTypes'
import {
  ModalSaveAsGarageState,
  ModalSaveImageState,
  ModalSavePdfState,
  ModalSaveQrState,
  ModalSaveXmlState,
  ModalShareEmailState,
  ModalShareFacebookState,
  ModalShareWhatsAppState,
} from './types'

export interface ModalConsequenceOfChangeProps {
  data: ConsequenceOfChangeDialogData
  handleChoice: (choice: ConsequeceOfChangeUserChoice) => void
}

export interface ModalState {
  modalRequestAQuoteIsOpen: boolean
  modalSendChangesToEysIsOpen: boolean
  modalSaveAsGarageIsOpen: boolean
  modalSaveGarageIsOpen: boolean
  modalShareLinkIsOpen: boolean
  modalChangesLostIsOpen: boolean
  modalUploadXmlIsOpen: boolean
  modalUserAccountGdprConsentIsOpen: boolean

  modalRfqSendingState: RfqParams | null
  modalShareEmailState: ModalShareEmailState | null
  modalShareWhatsAppState: ModalShareWhatsAppState | null
  modalShareFacebookState: ModalShareFacebookState | null
  modalSaveAsState: ModalSaveAsGarageState | null
  modalSavePdfState: ModalSavePdfState | null
  modalSaveQrState: ModalSaveQrState | null
  modalSaveXmlState: ModalSaveXmlState | null
  modalSaveImageState: ModalSaveImageState | null
  errorModalState: string | null
  errorIsFatal: boolean
}

const initialState: ModalState = {
  modalRequestAQuoteIsOpen: false,
  modalSendChangesToEysIsOpen: false,
  modalSaveAsGarageIsOpen: false,
  modalSaveGarageIsOpen: false,
  modalShareLinkIsOpen: false,
  modalChangesLostIsOpen: false,
  modalUploadXmlIsOpen: false,
  modalUserAccountGdprConsentIsOpen: false,

  modalRfqSendingState: null,
  modalShareEmailState: null,
  modalShareWhatsAppState: null,
  modalShareFacebookState: null,
  modalSaveAsState: null,
  modalSavePdfState: null,
  modalSaveQrState: null,
  modalSaveXmlState: null,
  modalSaveImageState: null,
  errorModalState: null,
  errorIsFatal: false,
}
export const modalSlice = createSlice({
  name: 'modalState',
  initialState,
  reducers: {
    openModalRequestAQuote: (state) => {
      state.modalRequestAQuoteIsOpen = true
    },
    closeModalRequestAQuote: (state) => {
      state.modalRequestAQuoteIsOpen = false
    },
    openModalSendChangesToEys: (state) => {
      state.modalSendChangesToEysIsOpen = true
    },
    closeModalSendChangesToEys: (state) => {
      state.modalSendChangesToEysIsOpen = false
    },
    openModalSaveAsGarage: (state) => {
      state.modalSaveAsGarageIsOpen = true
    },
    closeModalSaveAsGarage: (state) => {
      state.modalSaveAsGarageIsOpen = false
    },
    openModalSaveGarage: (state) => {
      state.modalSaveGarageIsOpen = true
    },
    closeModalSaveGarage: (state) => {
      state.modalSaveGarageIsOpen = false
    },
    openModalShareLink: (state) => {
      state.modalShareLinkIsOpen = true
    },
    closeModalShareLink: (state) => {
      state.modalShareLinkIsOpen = false
    },
    openModalChangesLost: (state) => {
      state.modalChangesLostIsOpen = true
    },
    closeModalChangesLost: (state) => {
      state.modalChangesLostIsOpen = false
    },
    openModalUploadXml: (state) => {
      state.modalUploadXmlIsOpen = true
    },
    closeModalUploadXml: (state) => {
      state.modalUploadXmlIsOpen = false
    },
    openModalUserAccountGdprConsent: (state) => {
      state.modalUserAccountGdprConsentIsOpen = true
    },
    closeModalUserAccountGdprConsent: (state) => {
      state.modalUserAccountGdprConsentIsOpen = false
    },

    setModalRfqSendingState: (
      state,
      action: PayloadAction<RfqParams | null>,
    ) => {
      state.modalRfqSendingState = action.payload
    },
    setModalShareEmailState: (
      state,
      action: PayloadAction<ModalShareEmailState | null>,
    ) => {
      state.modalShareEmailState = action.payload
    },
    setModalShareWhatsAppState: (
      state,
      action: PayloadAction<ModalShareWhatsAppState | null>,
    ) => {
      state.modalShareWhatsAppState = action.payload
    },
    setModalShareFacebookState: (
      state,
      action: PayloadAction<ModalShareFacebookState | null>,
    ) => {
      state.modalShareFacebookState = action.payload
    },
    setModalSaveAsGarageState: (
      state,
      action: PayloadAction<ModalSaveAsGarageState | null>,
    ) => {
      state.modalSaveAsState = action.payload
    },
    setModalSavePdfState: (
      state,
      action: PayloadAction<ModalSavePdfState | null>,
    ) => {
      state.modalSavePdfState = action.payload
    },
    setModalSaveQrState: (
      state,
      action: PayloadAction<ModalSaveQrState | null>,
    ) => {
      state.modalSaveQrState = action.payload
    },
    setModalSaveXmlState: (
      state,
      action: PayloadAction<ModalSaveXmlState | null>,
    ) => {
      state.modalSaveXmlState = action.payload
    },
    setModalSaveImageState: (
      state,
      action: PayloadAction<ModalSaveImageState | null>,
    ) => {
      state.modalSaveImageState = action.payload
    },
    setErrorModalState: (state, action: PayloadAction<string | null>) => {
      if (state.errorIsFatal && state.errorModalState) {
        // Preserve the first fatal error.
        return
      }
      state.errorModalState = action.payload
    },
    setErrorIsFatal: (state, action: PayloadAction<boolean>) => {
      if (state.errorIsFatal) {
        // Don't allow a non-fatal error to replace a fatal error.
        return
      }
      state.errorIsFatal = action.payload
    },

    closeAllModals: (state) => {
      state.modalRequestAQuoteIsOpen = false
      state.modalSendChangesToEysIsOpen = false
      state.modalSaveAsGarageIsOpen = false
      state.modalSaveGarageIsOpen = false
      state.modalShareLinkIsOpen = false
      state.modalChangesLostIsOpen = false
      state.modalUploadXmlIsOpen = false
      state.modalUserAccountGdprConsentIsOpen = false
      state.modalRfqSendingState = null
      state.modalShareEmailState = null
      state.modalShareWhatsAppState = null
      state.modalShareFacebookState = null
      state.modalSaveAsState = null
      state.modalSavePdfState = null
      state.modalSaveQrState = null
      state.modalSaveXmlState = null
      state.modalSaveImageState = null
      state.errorModalState = null
    },
  },
})

export const {
  openModalRequestAQuote,
  closeModalRequestAQuote,
  openModalSendChangesToEys,
  closeModalSendChangesToEys,
  openModalSaveAsGarage,
  closeModalSaveAsGarage,
  openModalSaveGarage,
  closeModalSaveGarage,
  openModalShareLink,
  closeModalShareLink,
  openModalChangesLost,
  closeModalChangesLost,
  openModalUploadXml,
  closeModalUploadXml,
  openModalUserAccountGdprConsent,
  closeModalUserAccountGdprConsent,

  setModalRfqSendingState,
  setModalShareEmailState,
  setModalShareWhatsAppState,
  setModalShareFacebookState,
  setModalSaveAsGarageState,
  setModalSavePdfState,
  setModalSaveQrState,
  setModalSaveXmlState,
  setModalSaveImageState,
  setErrorModalState,
  setErrorIsFatal,

  closeAllModals,
} = modalSlice.actions

export const getModalState = (state: RootState) => state.modal

export default modalSlice.reducer
