import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  Recipient,
  SendShareEmailParamsShareEmailInfo,
} from '../../api/generated'
import {
  ScaniaAdobeTrackingShareEmailEvent,
  pushAdobeEvent,
  ScaniaAdobeEventId,
  ScaniaAdobeShareOption,
} from '../../utils/adobeAnalytics'
import { useClient } from '../../utils/useClient'
import useTexts from '../../utils/useTexts'
import { SvgLoading } from '../SvgImports'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { getSessionInitDataState } from '../../store/sessionDataSlice'
import { getMarketLanguageState } from '../../store/appSlice'
import { closeAllModals, getModalState } from '../../store/modalSlice'
import {
  TdsButton,
  TdsMessage,
  TdsModal,
  TdsTextField,
  TdsTextarea,
} from '@scania/tegel-react'

const ShareEmailColumn = styled.fieldset`
  width: 40%;
  display: inline-block;
  margin-right: 16px;
  margin-bottom: 16px;
  vertical-align: top;
  border: 1px solid var(--tds-grey-300);
  padding: 8px;
`

const LabelInputField = styled.div`
  margin-top: 16px;
  margin-bottom: 8px;
  display: inline-block;
`

const LabelCompulsory = styled.div`
  display: inline-block;
  color: red;
  margin-left: 6px;
  font-size: 16px;
  font-weight: bold;
`

const TextBox = styled.div`
  margin-bottom: 16px;
`

interface SpinnerWrapperProps {
  $showSpinner: boolean
}

const SpinnerWrapper = styled.div<SpinnerWrapperProps>`
  display: ${(props) => (props.$showSpinner ? '' : 'none')};
  margin-left: 32px;

  svg {
    color: var(--tds-blue-600);
  }
`

interface ModalShareEmailProps {
  handleSessionFailure: () => void
}

export function ModalShareEmail(props: ModalShareEmailProps): JSX.Element {
  const t = useTexts()
  const client = useClient()
  const dispatch = useAppDispatch()
  const sessionInitData = useAppSelector(getSessionInitDataState)
  const marketLanguage = useAppSelector(getMarketLanguageState)
  const source = useAppSelector(getModalState).modalShareEmailState?.source

  const [enteredFirstNameSender, setEnteredFirstNameSender] =
    useState<string>('')
  const [enteredSurnameSender, setEnteredSurnameSender] = useState<string>('')
  const [enteredEmailSender, setEnteredEmailSender] = useState<string>('')
  const [enteredNameReciever, setEnteredNameReciever] = useState<string>('')
  const [enteredEmailReciever, setEnteredEmailReciever] = useState<string>('')
  const [enteredMessage, setEnteredMessage] = useState<string>('')

  const [enteredFirstNameErrorState, setEnteredFirstNameErrorState] = useState<
    'default' | 'error' | 'success' | undefined
  >(undefined)
  const [enteredSurNameErrorState, setEnteredSurNameErrorState] = useState<
    'default' | 'error' | 'success' | undefined
  >(undefined)
  const [enteredEmailSenderErrorState, setEnteredEmailSenderErrorState] =
    useState<'default' | 'error' | 'success' | undefined>(undefined)
  const [enteredRecieverNameErrorState, setEnteredRecieverNameErrorState] =
    useState<'default' | 'error' | 'success' | undefined>(undefined)
  const [enteredEmailRecieverErrorState, setEnteredEmailRecieverErrorState] =
    useState<'default' | 'error' | 'success' | undefined>(undefined)

  const [errorMessage, setErrorMessage] = useState<string>('')
  const [successMessage, setSuccessMessage] = useState<string>('')
  const [showSpinner, setShowSpinner] = useState<boolean>(false)

  const emailSenderChangeHandler = (event: { target: { value: any } }) => {
    const newEmail = event.target.value.trim().toLowerCase()
    const validFormat =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i
    if (!newEmail) {
      setEnteredEmailSender('')
      setEnteredEmailSenderErrorState('error')
    } else if (!validFormat.test(newEmail)) {
      setEnteredEmailSender(newEmail)
      setEnteredEmailSenderErrorState('error')
    } else {
      setEnteredEmailSender(newEmail)
      setEnteredEmailSenderErrorState('success')
    }
  }

  const firstNameSenderChangeHandler = (event: { target: { value: any } }) => {
    const value = event.target.value
    const valueTrimmed = typeof value === 'string' ? value.trim() : null
    if (!valueTrimmed) {
      setEnteredFirstNameSender('')
      setEnteredFirstNameErrorState('error')
    } else {
      setEnteredFirstNameSender(valueTrimmed)
      setEnteredFirstNameErrorState('success')
    }
  }

  const surnameSenderChangeHandler = (event: { target: { value: any } }) => {
    const value = event.target.value
    const valueTrimmed = typeof value === 'string' ? value.trim() : null
    if (!valueTrimmed) {
      setEnteredSurnameSender('')
      setEnteredSurNameErrorState('error')
    } else {
      setEnteredSurnameSender(valueTrimmed)
      setEnteredSurNameErrorState('success')
    }
  }

  const nameRecieverChangeHandler = (event: { target: { value: any } }) => {
    const value = event.target.value
    const valueTrimmed = typeof value === 'string' ? value.trim() : null
    if (!valueTrimmed) {
      setEnteredNameReciever('')
      setEnteredRecieverNameErrorState('error')
    } else {
      setEnteredNameReciever(valueTrimmed)
      setEnteredRecieverNameErrorState('success')
    }
  }

  const emailRecieverChangeHandler = (event: { target: { value: any } }) => {
    const newEmail = event.target.value.trim().toLowerCase() // TODO - remove toLowerCase?
    const validFormat =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i
    if (!newEmail) {
      setEnteredEmailReciever('')
      setEnteredEmailRecieverErrorState('error')
    } else if (!validFormat.test(newEmail)) {
      setEnteredEmailReciever(newEmail)
      setEnteredEmailRecieverErrorState('error')
    } else {
      setEnteredEmailReciever(newEmail)
      setEnteredEmailRecieverErrorState('success')
    }
  }

  const messageChangeHandler = (event: { target: { value: any } }) => {
    if (!event.target.value) {
      setEnteredMessage('')
    } else {
      setEnteredMessage(event.target.value)
    }
  }

  const checkCompulsoryFields = () => {
    let correctInput = false
    if (
      enteredFirstNameErrorState === 'success' &&
      enteredSurNameErrorState === 'success' &&
      enteredEmailSenderErrorState === 'success' &&
      enteredEmailRecieverErrorState === 'success' &&
      enteredRecieverNameErrorState === 'success'
    ) {
      setErrorMessage('')
      correctInput = true
    } else {
      setErrorMessage(t('LABEL_INVALID_FORM_INPUT'))
      if (enteredFirstNameErrorState !== 'success') {
        setEnteredFirstNameErrorState('error')
      }
      if (enteredSurNameErrorState !== 'success') {
        setEnteredSurNameErrorState('error')
      }
      if (enteredEmailSenderErrorState !== 'success') {
        setEnteredEmailSenderErrorState('error')
      }
      if (enteredEmailRecieverErrorState !== 'success') {
        setEnteredEmailRecieverErrorState('error')
      }
      if (enteredRecieverNameErrorState !== 'success') {
        setEnteredRecieverNameErrorState('error')
      }
    }
    return correctInput
  }

  const sendEmailSubmitHandler = async (event: any) => {
    event.preventDefault()
    if (!sessionInitData || !client || !marketLanguage) {
      return
    }
    if (!checkCompulsoryFields()) {
      return
    }
    if (!source) {
      return
    }

    const trackingEvent: ScaniaAdobeTrackingShareEmailEvent = {
      event: ScaniaAdobeEventId.Share,
      eventInfo: {
        shareOption: ScaniaAdobeShareOption.Email,
        placement: source,
      },
    }
    pushAdobeEvent(trackingEvent)

    setShowSpinner(true)

    const sessionId = sessionInitData.sessionId
    const recipient: Recipient = {
      name: enteredNameReciever,
      email: enteredEmailReciever,
    }
    const info: SendShareEmailParamsShareEmailInfo = {
      recipients: [recipient],
      personalMessage: enteredMessage,
      firstName: enteredFirstNameSender,
      familyName: enteredSurnameSender,
      userEmail: enteredEmailSender,
    }

    try {
      await client.sendShareMail({
        sessionId,
        shareEmailInfo: info,
        language: marketLanguage.language.toString(),
      })
      setSuccessMessage(t('DESCRIPTION_SHARE_EMAIL_SENT'))
      setShowSpinner(false)
    } catch (err) {
      console.error(err)
      console.error(t('ERROR_SERVER_COMMUNICATION_BREAKDOWN'))
      setErrorMessage(t('ERROR_SERVER_COMMUNICATION_BREAKDOWN'))
      setShowSpinner(false)
      return
    }

    setEnteredFirstNameSender('')
    setEnteredSurnameSender('')
    setEnteredEmailSender('')
    setEnteredNameReciever('')
    setEnteredEmailReciever('')
    setEnteredMessage('')
    setEnteredEmailRecieverErrorState(undefined)
    setEnteredEmailSenderErrorState(undefined)
    setEnteredFirstNameErrorState(undefined)
    setEnteredRecieverNameErrorState(undefined)
    setEnteredSurNameErrorState(undefined)
    setShowSpinner(false)
  }

  const handleCloseClick = useCallback(() => {
    dispatch(closeAllModals())
  }, [dispatch])

  // Eventlistener to close modal on tdsClose (click outside modal and click on close cross)
  useEffect(() => {
    document.addEventListener('tdsClose', handleCloseClick)
    return () => {
      document.removeEventListener('tdsClose', handleCloseClick)
    }
  }, [handleCloseClick])

  return (
    <TdsModal
      size="md"
      header={
        successMessage ? t('HEADER_SHARE_EMAIL_SENT') : t('HEADER_SHARE_EMAIL')
      }
      show
    >
      <span slot="body">
        {!successMessage && (
          <div>
            <TextBox>{t('DESCRIPTION_SHARE_EMAIL')}</TextBox>
            <form onSubmit={sendEmailSubmitHandler}>
              <ShareEmailColumn>
                <legend>{t('LABEL_SHARE_SENDER')}</legend>
                <LabelInputField>{t('LABEL_FIRST_NAME')}</LabelInputField>
                <LabelCompulsory>*</LabelCompulsory>
                <TdsTextField
                  type="text"
                  onTdsChange={firstNameSenderChangeHandler}
                  state={enteredFirstNameErrorState}
                  value={enteredFirstNameSender}
                  disabled={showSpinner}
                />
                <LabelInputField>{t('LABEL_LAST_NAME')}</LabelInputField>
                <LabelCompulsory>*</LabelCompulsory>
                <TdsTextField
                  type="text"
                  onTdsChange={surnameSenderChangeHandler}
                  state={enteredSurNameErrorState}
                  value={enteredSurnameSender}
                  disabled={showSpinner}
                />
                <LabelInputField>{t('LABEL_EMAIL')}</LabelInputField>
                <LabelCompulsory>*</LabelCompulsory>
                <TdsTextField
                  type="text"
                  onTdsChange={emailSenderChangeHandler}
                  state={enteredEmailSenderErrorState}
                  value={enteredEmailSender}
                  disabled={showSpinner}
                />
              </ShareEmailColumn>
              <ShareEmailColumn>
                <legend>{t('LABEL_SHARE_RECIPIENT')}</legend>
                <LabelInputField>{t('LABEL_NAME')}</LabelInputField>
                <LabelCompulsory>*</LabelCompulsory>
                <TdsTextField
                  type="text"
                  onTdsChange={nameRecieverChangeHandler}
                  state={enteredRecieverNameErrorState}
                  value={enteredNameReciever}
                  disabled={showSpinner}
                />
                <LabelInputField>{t('LABEL_EMAIL')}</LabelInputField>
                <LabelCompulsory>*</LabelCompulsory>
                <TdsTextField
                  type="text"
                  onTdsChange={emailRecieverChangeHandler}
                  state={enteredEmailRecieverErrorState}
                  value={enteredEmailReciever}
                  disabled={showSpinner}
                />
                <LabelInputField>{t('LABEL_PERSONAL_MESSAGE')}</LabelInputField>
                <TdsTextarea
                  placeholder=""
                  onTdsChange={messageChangeHandler}
                  value={enteredMessage}
                  state={undefined}
                  disabled={showSpinner}
                />
              </ShareEmailColumn>
              <SpinnerWrapper $showSpinner={showSpinner}>
                <SvgLoading />
              </SpinnerWrapper>
              {errorMessage && (
                <TdsMessage variant="error" header={errorMessage}></TdsMessage>
              )}
            </form>
          </div>
        )}
        {successMessage && (
          <TdsMessage variant="success">{successMessage}</TdsMessage>
        )}
      </span>

      <span slot="actions" className="tds-u-flex tds-u-gap2 tds-u-flex-wrap">
        {!successMessage && (
          <TdsButton
            size="md"
            disabled={showSpinner}
            type="submit"
            text={t('LABEL_ACTION_SEND')}
            onClick={sendEmailSubmitHandler}
          />
        )}
        <TdsButton
          variant="secondary"
          size="md"
          disabled={showSpinner}
          onClick={handleCloseClick}
          text={t('LABEL_ACTION_CLOSE')}
        />
      </span>
    </TdsModal>
  )
}
