/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ConfigInfoImage } from './ConfigInfoImage'
import {
  ConfigInfoImageFromJSON,
  ConfigInfoImageFromJSONTyped,
  ConfigInfoImageToJSON,
} from './ConfigInfoImage'

/**
 *
 * @export
 * @interface PublicConfigInfo
 */
export interface PublicConfigInfo {
  /**
   *
   * @type {string}
   * @memberof PublicConfigInfo
   */
  guid: string
  /**
   *
   * @type {string}
   * @memberof PublicConfigInfo
   */
  description: string
  /**
   *
   * @type {Array<ConfigInfoImage>}
   * @memberof PublicConfigInfo
   */
  images: Array<ConfigInfoImage>
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof PublicConfigInfo
   */
  configStorageAux?: { [key: string]: string } | null
}

/**
 * Check if a given object implements the PublicConfigInfo interface.
 */
export function instanceOfPublicConfigInfo(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'guid' in value
  isInstance = isInstance && 'description' in value
  isInstance = isInstance && 'images' in value

  return isInstance
}

export function PublicConfigInfoFromJSON(json: any): PublicConfigInfo {
  return PublicConfigInfoFromJSONTyped(json, false)
}

export function PublicConfigInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PublicConfigInfo {
  if (json === undefined || json === null) {
    return json
  }
  return {
    guid: json['Guid'],
    description: json['Description'],
    images: (json['Images'] as Array<any>).map(ConfigInfoImageFromJSON),
    configStorageAux: !exists(json, 'ConfigStorageAux')
      ? undefined
      : json['ConfigStorageAux'],
  }
}

export function PublicConfigInfoToJSON(value?: PublicConfigInfo | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    Guid: value.guid,
    Description: value.description,
    Images: (value.images as Array<any>).map(ConfigInfoImageToJSON),
    ConfigStorageAux: value.configStorageAux,
  }
}
