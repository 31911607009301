import styled from 'styled-components'
import { PortraitFooter } from '../../components/Footer/PortraitFooter'
import { RfqEysButton } from './RfqEysButton'
import { useRfqEysButtonState } from './RfqEysButtonState'
import { BreakpointWidthPx } from '../../css/BreakpointWidthPx'

const BuildModePortraitFooterRoot = styled.div`
  align-items: center;
  background-color: white;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 100%;

  @media screen and (max-width: ${BreakpointWidthPx.Tablet}px) {
    display: flex;
  }
`

const ButtonWrapper = styled.div`
  width: 100%;
  padding-top: 8px;
  padding-bottom: 24px;
  padding-left: 32px;
  padding-right: 32px;

  button {
    font-weight: bold !important;
  }
`

export interface FooterProps {
  handleFooterButtonClick: (() => void) | null
}

export function BuildModePortraitFooter({
  handleFooterButtonClick,
}: FooterProps): JSX.Element {
  const { hidden, buttonText } = useRfqEysButtonState()

  return (
    <BuildModePortraitFooterRoot data-name="Footer">
      {!hidden && (
        <ButtonWrapper>
          <RfqEysButton
            text={buttonText}
            handleClick={handleFooterButtonClick}
          />
        </ButtonWrapper>
      )}
      <PortraitFooter displayAtScreenMaxWidth={BreakpointWidthPx.Tablet} />
    </BuildModePortraitFooterRoot>
  )
}
