import { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { FAMILY_ID_TRUCK_TYPE } from '../../../api/constants'
import { CardData } from '../../../types/GuidedOffering/CardData'
import { TestElementTypeId } from '../../../types/TestAttributeId'
import { ScaniaAdobeTrackingPageName } from '../../../utils/adobeAnalytics'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { GoChoiceClickHandler } from '../goChoiceClickHook'
import { SvgInfo } from '../../../components/SvgImports'
import { GoReadMoreClickHandler } from '../goReadmoreUtil'
import { TextAndInfoWrapper, IconInfoWrapper } from './TextAndInfoWrapper'
import { getMarketLanguageState } from '../../../store/appSlice'
import { pushPageViewTrackingEvent } from '../../../store/sessionDataSlice'

const Layout = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  height: 100%;
  justify-content: space-evenly;
  width: 100vw;
  position: absolute;
`

const Card = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 8px;

  img {
    max-width: 90vw;
    max-height: max(22vh, 150px);
  }
`

interface TruckTypePageProps {
  cards: CardData[]
  handleCardClick: GoChoiceClickHandler
  handleReadMoreClick: GoReadMoreClickHandler
}

const TrucktypePage = ({
  cards,
  handleCardClick,
  handleReadMoreClick,
}: TruckTypePageProps) => {
  const dispatch = useAppDispatch()
  const cardContainer = useRef<HTMLDivElement | null>(null)
  const marketLanguage = useAppSelector(getMarketLanguageState)

  // Adobe user tracking.
  useEffect(() => {
    if (!marketLanguage) {
      return
    }
    dispatch(
      pushPageViewTrackingEvent({
        pageName: ScaniaAdobeTrackingPageName.GoTruckType,
        marketLanguage,
      }),
    )
  }, [dispatch, marketLanguage])

  return (
    <Layout ref={cardContainer}>
      {cards.map((card) => (
        <Card
          key={card.id}
          onClick={() =>
            handleCardClick({ id: card.id, isSkippingToNext: false })
          }
          data-test-element-type={TestElementTypeId.GuidedOfferingChoiceButton}
          data-test-etel-variant-id={card.id}
          data-test-go-group-id={FAMILY_ID_TRUCK_TYPE}
        >
          <img src={card.imageUrl} alt={card.header} />
          <TextAndInfoWrapper>
            {card.readmore && (
              <IconInfoWrapper onClick={(e) => handleReadMoreClick(e, card.id)}>
                <SvgInfo />
              </IconInfoWrapper>
            )}
            <h3>{card.header}</h3>
          </TextAndInfoWrapper>
        </Card>
      ))}
    </Layout>
  )
}

export default TrucktypePage
