import { useCallback, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { SvgCross } from '../../components/SvgImports'

interface PopUpWrapperRootProps {
  $topPx: number
}

const PopUpWrapperRoot = styled.div<PopUpWrapperRootProps>`
  width: 250px;
  border: 1px solid var(--tds-grey-300);
  border-radius: 4px;
  box-shadow: 3px 3px 3px grey;
  background-color: white;
  position: absolute;

  // TODO: Revisit and try to eliminate.
  z-index: 5;

  top: ${(props) => props.$topPx}px;
  right: 8px;
`

const PopUpCloseWrapper = styled.div`
  cursor: pointer;

  svg {
    width: 20px;
    float: right;
    margin: 8px;
  }
`

interface PopUpWrapperProps {
  children: React.ReactNode
  handleClose: () => void
  topPx: number
}

export const PopUpWrapper = (props: PopUpWrapperProps): JSX.Element => {
  const ref = useRef<HTMLDivElement | null>(null)
  const handleClose = props.handleClose

  const handleUserClickOnScreen = useCallback(
    (e: MouseEvent) => {
      const currentElement = ref.current
      if (!currentElement?.contains(e.target as Node)) {
        handleClose()
      }
    },
    [ref, handleClose],
  )

  useEffect(() => {
    document.addEventListener('click', handleUserClickOnScreen)
    return () => {
      document.removeEventListener('click', handleUserClickOnScreen)
    }
  }, [handleUserClickOnScreen])

  return (
    <PopUpWrapperRoot $topPx={props.topPx} ref={ref}>
      <PopUpCloseWrapper onClick={props.handleClose}>
        <SvgCross />
      </PopUpCloseWrapper>
      {props.children}
    </PopUpWrapperRoot>
  )
}
