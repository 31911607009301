import { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import bgimage_desktop_webp from '../../assets/img/reset-password-bg2.jpg'
import bgimage_desktop_jpg from '../../assets/img/reset-password-bg2.jpg'
import whenBgImageIsLoaded from '../../utils/whenBgImageIsLoaded'
import useTexts from '../../utils/useTexts'
import usePreventAndroidKeyboardBug from '../../utils/usePreventAndroidKeyboardBug'
import { useNavigate } from 'react-router'
import { buildRelativeLandingPageUrl } from '../../utils/UrlBuilders'
import { useClient } from '../../utils/useClient'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { getUrlParametersToString } from '../../utils/getUrlParametersToString'
import { BreakpointWidthPx } from '../../css/BreakpointWidthPx'
import {
  getMarketLanguageState,
  getSupportsWebpState,
} from '../../store/appSlice'
import {
  getSessionInitDataState,
  hideInitialLoadingScreen,
  setError,
} from '../../store/sessionDataSlice'
import {
  TdsBlock,
  TdsButton,
  TdsMessage,
  TdsTextField,
} from '@scania/tegel-react'

interface ContainerProps {
  $bgimage: string
}

const Container = styled.div<ContainerProps>`
  width: 100vw;
  height: calc(var(--app-height) - var(--header-height));
  background-image: url('${(props) => props.$bgimage}');
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;

  @media screen and (max-width: ${BreakpointWidthPx.Tablet}px) {
    align-items: unset;
  }

  tds-block {
    margin: 16px;

    @media screen and (min-width: ${BreakpointWidthPx.Tablet}px) {
      width: 450px;
    }

    @media screen and (max-width: ${BreakpointWidthPx.Tablet}px) {
      width: 80%;
    }
  }
`

const InputWrapper = styled.div`
  margin-bottom: 24px;
`

const ResetPassword = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const client = useClient()
  const webp = useAppSelector(getSupportsWebpState)
  const marketLanguage = useAppSelector(getMarketLanguageState)
  const sessionInitData = useAppSelector(getSessionInitDataState)
  const t = useTexts()

  const bgref = useRef<HTMLDivElement | null>(null)
  const pass1 = useRef<HTMLTdsTextFieldElement | null>(null)
  const [passw1, setPassw1] = useState<string | null>(null)
  const [passw2, setPassw2] = useState<string | null>(null)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const [loading, setLoading] = useState(false)
  const [bgIsLoaded, setBgIsLoaded] = useState(false)

  useEffect(() => {
    if (!sessionInitData?.sessionId) {
      return // Not initialized yet.
    }
    if (!bgIsLoaded) {
      return // Not initialized yet.
    }
    // TODO: Revisit later, use a single call in App.tsx.
    dispatch(hideInitialLoadingScreen())
  }, [bgIsLoaded, dispatch, sessionInitData])

  useEffect(() => {
    whenBgImageIsLoaded(bgref.current)
      .then(() => {
        setBgIsLoaded(true)
        const urlParams = new URLSearchParams(window.location.search)
        if (urlParams.has('token')) {
          pass1.current?.focus()
        } else {
          console.error('Token is missing in url.')
          dispatch(
            setError({
              errorLevel: 2,
              friendlyErrorMessage:
                'The url is malformed. Are you sure you have the correct link from the email that was sent to you?',
              errorMessage: `Token is missing in url.`,
            }),
          )
        }
      })
      .catch((e) => console.log('error', e))
  }, [dispatch])

  const switchToStartPage = useCallback(() => {
    if (!marketLanguage) {
      throw new Error(
        'Expected etelMarketCode and etelLanguageCode to defined.',
      )
    }
    const relativeUrl = buildRelativeLandingPageUrl(marketLanguage)
    const params = relativeUrl.params
    navigate(relativeUrl.path + getUrlParametersToString(params))
  }, [marketLanguage, navigate])

  const handleSubmit = useCallback(async () => {
    if (!client) {
      console.error('Expected client to be defined.')
      return
    }
    if (!passw1 || passw1.length === 0) {
      setErrorMessage('Expected both passwords to be defined.')
      return
    }
    if (!passw2 || passw2.length === 0) {
      setErrorMessage('Expected both passwords to be defined.')
      return
    }
    const password1 = passw1.trim()
    const password2 = passw2.trim()
    // TODO: check that password meets requirements here. Min length, allowed characters, etc
    if (password1 === password2 && !!password1) {
      const urlParams = new URLSearchParams(window.location.search)
      const token = urlParams.get('token')
      console.assert(token, 'Cannot proceed without a token. Will fail.')
      if (!token) {
        throw new Error('Expected token to be defined.')
      }
      setLoading(true)
      const statusCode = await client.resetPassword({
        token,
        newPassword: password1,
      })
      if (!statusCode.error) {
        switchToStartPage()
      } else {
        console.error(statusCode.error)
        setLoading(false)
        setErrorMessage(t('DESCRIPTION_PASSWORD_RESET_FAILURE'))
      }
      return
    } else if (password1 !== password2) {
      setErrorMessage(t('NOTIFICATION_PASSWORDS_DONT_MATCH'))
    }
    pass1.current?.focus()
  }, [client, switchToStartPage, t, passw1, passw2])

  const handleCancel = useCallback(async () => {
    switchToStartPage()
  }, [switchToStartPage])

  const passw1ChangeHandler = (event: any) => {
    const newPassw1 = event.target.value
    setErrorMessage('')
    setPassw1(newPassw1)
  }

  const passw2ChangeHandler = (event: any) => {
    const newPassw2 = event.target.value
    setErrorMessage('')
    setPassw2(newPassw2)
  }

  usePreventAndroidKeyboardBug()

  return (
    <Container
      ref={bgref}
      $bgimage={webp ? bgimage_desktop_webp : bgimage_desktop_jpg}
    >
      <TdsBlock>
        <h3 style={{ marginBottom: '16px' }}>{t('HEADER_NEW_PASSWORD')}</h3>
        <InputWrapper>
          <TdsTextField
            type="password"
            placeholder={t('HEADER_ENTER_NEW_PASSWORD')}
            value={passw1 || undefined}
            onInput={passw1ChangeHandler}
            ref={pass1}
            modeVariant="secondary"
          />
        </InputWrapper>

        <TdsTextField
          type="password"
          placeholder={t('LABEL_PASSWORD_REPEAT')}
          value={passw2 || undefined}
          onInput={passw2ChangeHandler}
          modeVariant="secondary"
        />

        {errorMessage && (
          <div style={{ marginTop: '32px' }}>
            <TdsMessage variant="error" header={errorMessage} />
          </div>
        )}
        <TdsButton
          variant="primary"
          size="md"
          onClick={handleSubmit}
          style={{ marginRight: '16px', marginTop: '32px' }}
          disabled={loading}
          text={t('LABEL_ACTION_SAVE')}
        />
        <TdsButton
          variant="secondary"
          size="md"
          onClick={handleCancel}
          disabled={loading}
          text={t('LABEL_ACTION_CANCEL')}
        />
      </TdsBlock>
    </Container>
  )
}
export default ResetPassword
