import { getReadmoreImageUrls } from '../../api/readmoreUtil'
import { ReadMoreData } from '../../store/types'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { useCallback } from 'react'
import {
  ScaniaAdobeEventId,
  ScaniaAdobeTrackingClickGoReadMoreEvent,
  ScaniaAdobeTrackingPageName,
  pushAdobeEvent,
} from '../../utils/adobeAnalytics'
import {
  openPanelReadMore,
  setReadMoreData,
  setReadMoreNode,
} from '../../store/sidePanelSlice'
import { getGuidedOfferingClientState } from '../../store/sessionDataSlice'

export interface UseHandleGoReadmoreClickProps {
  /** Nullable to allow using the hood before the page is initialized. */
  stepName: ScaniaAdobeTrackingPageName | null
}

export type GoReadMoreClickHandler = (ev: React.MouseEvent, id: string) => void

export function useHandleGoReadMoreClick({
  stepName,
}: UseHandleGoReadmoreClickProps): GoReadMoreClickHandler {
  const dispatch = useAppDispatch()
  const goClientState = useAppSelector(getGuidedOfferingClientState)

  const handleShowReadmore = useCallback(
    (ev: React.MouseEvent, id: string) => {
      ev.stopPropagation()
      ev.preventDefault()
      if (!goClientState) {
        throw new Error('Expected goClientState to be defined.')
      }
      const options = goClientState.lastResponse.question?.options
      if (!options) {
        throw new Error('Expected options to be defined.')
      }
      const opt = options.find((o) => o.id === id)
      if (!opt) {
        throw new Error('Expected opt to be defined.')
      }
      if (!stepName) {
        throw new Error('Expected stepName to be defined.')
      }
      const data: ReadMoreData = {
        label: opt.label,
        images: getReadmoreImageUrls(opt),
        text: opt.readmoreText,
      }
      const trackingEvent: ScaniaAdobeTrackingClickGoReadMoreEvent = {
        event: ScaniaAdobeEventId.GoReadMoreClick,
        eventInfo: {
          infoName: opt.label,
          stepName,
        },
      }
      pushAdobeEvent(trackingEvent)

      dispatch(setReadMoreNode(null))
      dispatch(setReadMoreData(data))
      dispatch(openPanelReadMore())
    },
    [dispatch, goClientState, stepName],
  )

  return handleShowReadmore
}
