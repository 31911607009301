import { CardData } from '../types/GuidedOffering/CardData'
import { GuidedOfferingInfo } from '../api/generated'

export function buildCardData(goInfo: GuidedOfferingInfo): CardData[] | null {
  const options = goInfo.question?.options
  if (!options) {
    return null
  }
  const newCards: CardData[] = options.map((o, i) => {
    const result: CardData = {
      header: o.label,
      imageUrl: o.image ? o.image.replace('/Icons/', '/Icons2/') : '',
      id: o.id,
      key: i,
      index: i,
      readmore: o.readmoreText,
      defaultSelected: o.id === goInfo?.question?.selected,
      option: o,
    }
    return result
  })
  if (
    newCards.length > 0 &&
    newCards.findIndex((c) => c.defaultSelected) === -1
  ) {
    newCards[0].defaultSelected = true
  }
  return newCards
}
