/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface DealerLocation
 */
export interface DealerLocation {
  /**
   *
   * @type {number}
   * @memberof DealerLocation
   */
  longitude?: number
  /**
   *
   * @type {number}
   * @memberof DealerLocation
   */
  latitude?: number
}

/**
 * Check if a given object implements the DealerLocation interface.
 */
export function instanceOfDealerLocation(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function DealerLocationFromJSON(json: any): DealerLocation {
  return DealerLocationFromJSONTyped(json, false)
}

export function DealerLocationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): DealerLocation {
  if (json === undefined || json === null) {
    return json
  }
  return {
    longitude: !exists(json, 'Longitude') ? undefined : json['Longitude'],
    latitude: !exists(json, 'Latitude') ? undefined : json['Latitude'],
  }
}

export function DealerLocationToJSON(value?: DealerLocation | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    Longitude: value.longitude,
    Latitude: value.latitude,
  }
}
