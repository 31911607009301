import { allGoStepIds } from '../../api/constants'
import { GuidedOfferingClientState } from '../../types/GuidedOffering/GuidedOfferingClientState'

export interface GoStepperEntry {
  isActiveQuestion: boolean
  isFinishedQuestion: boolean
  id: string
}

export const buildStepperEntries = (
  goClientState: GuidedOfferingClientState | null,
): GoStepperEntry[] => {
  const currentFamilyId = goClientState?.lastResponse.question?.etelFamily
  const OPTIONALS_PAGE = 'OPTIONALS_PAGE'
  let isFinishedQuestion = true
  if (!goClientState) {
    isFinishedQuestion = false
  }
  const entries: GoStepperEntry[] = allGoStepIds
    .concat([OPTIONALS_PAGE])
    .map((id) => {
      let isActiveQuestion = id === currentFamilyId
      if (id === OPTIONALS_PAGE) {
        isActiveQuestion = goClientState?.sliderQuestions !== null
      }
      if (isActiveQuestion) {
        isFinishedQuestion = false
      }
      return { isActiveQuestion, isFinishedQuestion, id: id }
    })
  return entries
}

export function getCurrentQuestionIndex(
  goClientState: GuidedOfferingClientState,
): number {
  return buildStepperEntries(goClientState).findIndex((s) => s.isActiveQuestion)
}
