import useTexts from '../../utils/useTexts'

export const Disclaimer = (): JSX.Element | null => {
  const t = useTexts()
  const message = t('DISCLAIMER_MESSAGE')
  if (message?.toUpperCase() === 'LINK') {
    return (
      <span>
        <a
          href={t('LINK_DISCLAIMER')}
          target="_blank"
          rel="noreferrer"
          style={{ color: 'var(--tds-grey-600)' }}
        >
          {t('LINK_TEXT_DISCLAIMER')}
        </a>
      </span>
    )
  } else if (message === '-' || !message) {
    return null
  } else {
    return <span>{message}</span>
  }
}
