/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { UserConsent } from './UserConsent'
import {
  UserConsentFromJSON,
  UserConsentFromJSONTyped,
  UserConsentToJSON,
} from './UserConsent'

/**
 *
 * @export
 * @interface GetUserConsentsResponseWrapper
 */
export interface GetUserConsentsResponseWrapper {
  /**
   *
   * @type {Array<UserConsent>}
   * @memberof GetUserConsentsResponseWrapper
   */
  getUserConsents?: Array<UserConsent> | null
  /**
   *
   * @type {string}
   * @memberof GetUserConsentsResponseWrapper
   */
  error?: string | null
}

/**
 * Check if a given object implements the GetUserConsentsResponseWrapper interface.
 */
export function instanceOfGetUserConsentsResponseWrapper(
  value: object,
): boolean {
  let isInstance = true

  return isInstance
}

export function GetUserConsentsResponseWrapperFromJSON(
  json: any,
): GetUserConsentsResponseWrapper {
  return GetUserConsentsResponseWrapperFromJSONTyped(json, false)
}

export function GetUserConsentsResponseWrapperFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GetUserConsentsResponseWrapper {
  if (json === undefined || json === null) {
    return json
  }
  return {
    getUserConsents: !exists(json, 'get_user_consents')
      ? undefined
      : json['get_user_consents'] === null
      ? null
      : (json['get_user_consents'] as Array<any>).map(UserConsentFromJSON),
    error: !exists(json, 'error') ? undefined : json['error'],
  }
}

export function GetUserConsentsResponseWrapperToJSON(
  value?: GetUserConsentsResponseWrapper | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    get_user_consents:
      value.getUserConsents === undefined
        ? undefined
        : value.getUserConsents === null
        ? null
        : (value.getUserConsents as Array<any>).map(UserConsentToJSON),
    error: value.error,
  }
}
