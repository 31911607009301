import { ScaniaConfiguratorApi } from './generated/apis'
import {
  BuildInfoResponseWrapper,
  FactoryModelsV2Response,
  GuiMarketSettings,
  ScaniaCultureInfo,
  ScaniaSystemInfo,
  SessionInitData,
  UserConfigInfo,
} from './generated'
import { fetchSettings, SettingsDotJson } from './settingsDotJson'
import {
  UrlParamMode,
  getUrlCidBevSemiliveParameters,
} from '../utils/getUrlCidParameter'

/**
 * Needed since Redux considers the javscript Date type to be unserializable.
 */
export type FactoryModelsResponseWithoutCacheTime = Omit<
  FactoryModelsV2Response,
  'cacheTime'
>

export interface FactoryModelsResponseReduxCompatible
  extends FactoryModelsResponseWithoutCacheTime {
  /**
   * Should be the result of .getTime() on the original Date object.
   */
  cacheJsTimestamp?: number
}

/**
 * Needed since Redux considers the javscript Date type to be unserializable.
 */
export type SessionInitDataWithoutSecmPublishInfo = Omit<
  SessionInitData,
  'secmPublishInfo'
>

export interface SessionInitDataReduxCompatible
  extends SessionInitDataWithoutSecmPublishInfo {
  /**
   * Should be the result of .getTime() on the original Date object.
   */
  secmLastUpdatedTimeAsNumber?: number
}

// TODO: Separate this object into session, and pre-session startup data?
export interface StartupDataBundle {
  backendUrl: string
  marketLanguage: EtelMarketAndLanguage
  settings: SettingsDotJson
  marketSettings: GuiMarketSettings
  sessionInitData: SessionInitDataReduxCompatible
  cultureInfo: ScaniaCultureInfo
  buildInfo: BuildInfoResponseWrapper
  statelessSystemInfo: ScaniaSystemInfo

  factoryModels: FactoryModelsStartupData | null
  localization: Record<string, string | null | undefined>

  // TODO: Why is this part of the startup data?? Should be fetched separately.
  userConfigs: UserConfigInfo[] | null

  // Special mode for users that arrive via EYS public config links.
  exploreYourScaniaMode: boolean

  /**
   * Should be used when starting the deferred goWizardStart.
   */
  sessionTimeoutSeconds: number | undefined
}

export interface GetLocalizationParams {
  /**
   * Added only for end-to-end tests to have something to match the market
   * specific URL on.
   */
  etelMarket: number

  isoLanguageCode: string
  isoMarketCode: string
  showPreviewTexts: boolean
}

export interface StateUpdateIdWrapper {
  stateUpdateId?: number
}

export interface GetPubConfigStringParams {
  configid: number
  description: string
}

export interface EtelMarketAndLanguage {
  /** A four digit Etel market code. */
  market: number

  /** A four digit Etel language code. */
  language: number
}

// TODO: Move to Redux or App.tsx.
// TODO: Use scds_backend as "gateway" for any data provided by SC Admin.
const SCADMIN_URL = document.location.origin + '/scania_admin_service/api/'

// TODO: This function need some review and massage later.
const getLocalization = (
  p: GetLocalizationParams,
): Promise<Record<string, string | undefined | null>> => {
  return new Promise((resolve, reject) => {
    const language_iso = p.isoLanguageCode
    const market_iso = p.isoMarketCode
    const keywordlist = 'MASTER,SQ2020,MASTER_GO'
    const url =
      SCADMIN_URL +
      'LocalizedText/LocalizedTexts?etel_market=' +
      p.etelMarket +
      '&keywords=' +
      keywordlist +
      '&market_iso=' +
      market_iso +
      '&language_iso=' +
      language_iso
    let requestObj = { method: 'GET' }
    fetch(url, requestObj)
      .then((response) => {
        if (response.status === 200) return response.json()
        else
          console.error(
            'Did not receive expected language data. Response: ',
            response,
          )
      })
      .then((json) => {
        let obj: Record<string, string | null | undefined> = {}
        Object.keys(json.texts).forEach((key) => {
          const { previewValue, liveValue } = json.texts[key]
          obj[key] = !p.showPreviewTexts
            ? liveValue
            : previewValue === null
            ? liveValue
            : previewValue
        })
        // TODO: Remove this
        localStorage.setItem('textmaster', JSON.stringify(obj))
        resolve(obj)
      })
      .catch((err) => {
        console.error('Network error while getting localization. ', err)
        reject(err)
      })
  })
}

export const startApplication = async (
  backendUrl: string,
  client: ScaniaConfiguratorApi,
  markLang: EtelMarketAndLanguage,
  showPreviewTexts: boolean,
  existingSessionId: string | undefined,
  sessionTimeoutSeconds: number | undefined,
): Promise<StartupDataBundle> => {
  const etelMarket = markLang.market
  const etelLanguage = markLang.language
  let startupDataBundle: StartupDataBundle
  try {
    var urlParams = new URLSearchParams(window.location.search)

    // RFQ override for YNT (Your Next Truck), originally also for
    // EYS (Explore Your Scania). Can probably be removed after 9.1 is released,
    // but let's wait another version.
    const deactivateRfq = urlParams.get('rfq') === '0'

    // Explore Your Scania mode, enable special truck configuration
    // change feedback button and disable RFQ.
    const exploreYourScaniaMode = urlParams.get('eys') === '1'

    // Must await this before doing the rest in parallel, since this request
    // will not return until the market is loaded.
    const sessionInitDataOriginal: SessionInitData = (
      await client.createSession(etelMarket, {
        etelLanguage,
        existingSessionId,
        timeoutSeconds: sessionTimeoutSeconds,
      })
    ).createSession

    var marketSettings = sessionInitDataOriginal.marketSettings
    marketSettings.rfqEnabled =
      deactivateRfq || exploreYourScaniaMode ? false : marketSettings.rfqEnabled

    const sessionInitData: SessionInitDataReduxCompatible = {
      sessionId: sessionInitDataOriginal.sessionId,
      menuInfo: sessionInitDataOriginal.menuInfo,
      marketSettings,
      composedUrl: sessionInitDataOriginal.composedUrl,
      resetTimeSec: sessionInitDataOriginal.resetTimeSec,
      secmLastUpdatedTimeAsNumber:
        sessionInitDataOriginal.secmPublishInfo.publishDateTime?.getTime(),
    }

    // Get initial data in parallell.
    const startupData = await Promise.all([
      client.getScaniaCultureInfo(etelLanguage, etelMarket),
      client.buildInfo(),
      client.getStatelessScaniaSystemInfo(),
      fetchSettings(),
    ])
    let factoryModelsData = await getFactoryModelsStartupData(
      client,
      etelMarket,
      etelLanguage,
      marketSettings,
      showPreviewTexts,
    )

    // If we received an existing session id, let's try to fetch the saved user
    // configurations as well.
    let userConfigs: UserConfigInfo[] | null = null
    if (existingSessionId) {
      try {
        //userConfigs = await getGarageList(existingSessionId)
        const listResult = await client.userConfigV2List(existingSessionId)
        if (!listResult.userConfigV2List) {
          // TODO: Investigate how to handle this error at startup.
          // We probably need different error codes for the user not being
          // logged in and for session failure and handle them differently.
          console.error('Expected listResult to be defined.')
        } else {
          userConfigs = listResult.userConfigV2List
        }
      } catch (err) {
        // Not a critical error, just log it to help with debugging.
        console.error('Failed to fetch garage list, error: ', err)
      }
    }

    startupDataBundle = {
      backendUrl,
      marketLanguage: markLang,
      settings: startupData[3],
      marketSettings,
      sessionInitData,
      cultureInfo: startupData[0].getScaniaCultureInfo,
      buildInfo: startupData[1],
      statelessSystemInfo: startupData[2].getStatelessScaniaSystemInfo,
      factoryModels: factoryModelsData,
      localization: {},
      userConfigs,
      exploreYourScaniaMode,
      sessionTimeoutSeconds,
    }
  } catch (err) {
    console.error('Caught a network error.')
    console.error(err)
    throw new Error('Problems with the connection.')
  }
  // TODO: Localization is fetched in serial due to need of iso format that is received from cultureInfo call.
  // Need to add this to parallel with etel codes or through a backend service for performance.
  const localization = await getLocalization({
    etelMarket,
    isoLanguageCode: startupDataBundle.cultureInfo.languageIso,
    isoMarketCode: startupDataBundle.cultureInfo.marketIso,
    showPreviewTexts,
  })
  startupDataBundle.localization = localization
  return startupDataBundle
}

export interface FactoryModelsStartupData {
  response: FactoryModelsResponseReduxCompatible
}

async function getFactoryModelsStartupData(
  client: ScaniaConfiguratorApi,
  etelMarket: number,
  etelLanguage: number,
  marketSettings: GuiMarketSettings,
  showPreviewTexts: boolean,
): Promise<FactoryModelsStartupData | null> {
  const urlCidParams = getUrlCidBevSemiliveParameters(UrlParamMode.ExcludeBev)
  const isSemiLiveFm = marketSettings.prepareFm && urlCidParams.has('semilive')
  if (marketSettings.factoryModelsMode === 0 && !isSemiLiveFm) {
    return null
  }
  const response = await client.getFactoryModelsV2(
    etelMarket,
    etelLanguage,
    showPreviewTexts,
  )
  const reduxCompatibleResponse: FactoryModelsResponseReduxCompatible = {
    cacheJsTimestamp: response.cacheTime?.getTime(),
    models: response.models,
    readMore: response.readMore,
    secmLastUpdated: response.secmLastUpdated,
    shortTexts: response.shortTexts,
  }
  return { response: reduxCompatibleResponse }
}
