/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * Legacy response wrapper structure to allow matching the original
 * configurator web API.
 *
 * TODO: This should be removed after the .Net Core and ScdsClient
 * migration has been completed.
 * @export
 * @interface HeartbeatResponseWrapper
 */
export interface HeartbeatResponseWrapper {
  /**
   *
   * @type {string}
   * @memberof HeartbeatResponseWrapper
   */
  heartbeat: string
}

/**
 * Check if a given object implements the HeartbeatResponseWrapper interface.
 */
export function instanceOfHeartbeatResponseWrapper(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'heartbeat' in value

  return isInstance
}

export function HeartbeatResponseWrapperFromJSON(
  json: any,
): HeartbeatResponseWrapper {
  return HeartbeatResponseWrapperFromJSONTyped(json, false)
}

export function HeartbeatResponseWrapperFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): HeartbeatResponseWrapper {
  if (json === undefined || json === null) {
    return json
  }
  return {
    heartbeat: json['heartbeat'],
  }
}

export function HeartbeatResponseWrapperToJSON(
  value?: HeartbeatResponseWrapper | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    heartbeat: value.heartbeat,
  }
}
