/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface MenuItem
 */
export interface MenuItem {
  /**
   *
   * @type {boolean}
   * @memberof MenuItem
   */
  hasReadmore: boolean
  /**
   *
   * @type {string}
   * @memberof MenuItem
   */
  id: string
  /**
   *
   * @type {boolean}
   * @memberof MenuItem
   */
  selectable: boolean
  /**
   *
   * @type {boolean}
   * @memberof MenuItem
   */
  selected: boolean
  /**
   *
   * @type {string}
   * @memberof MenuItem
   */
  menuPath?: string | null
  /**
   *
   * @type {string}
   * @memberof MenuItem
   */
  shortText?: string | null
}

/**
 * Check if a given object implements the MenuItem interface.
 */
export function instanceOfMenuItem(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'hasReadmore' in value
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'selectable' in value
  isInstance = isInstance && 'selected' in value

  return isInstance
}

export function MenuItemFromJSON(json: any): MenuItem {
  return MenuItemFromJSONTyped(json, false)
}

export function MenuItemFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): MenuItem {
  if (json === undefined || json === null) {
    return json
  }
  return {
    hasReadmore: json['hasReadmore'],
    id: json['id'],
    selectable: json['selectable'],
    selected: json['selected'],
    menuPath: !exists(json, 'menuPath') ? undefined : json['menuPath'],
    shortText: !exists(json, 'shortText') ? undefined : json['shortText'],
  }
}

export function MenuItemToJSON(value?: MenuItem | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    hasReadmore: value.hasReadmore,
    id: value.id,
    selectable: value.selectable,
    selected: value.selected,
    menuPath: value.menuPath,
    shortText: value.shortText,
  }
}
