export const FAMILY_ID_OPERATION = '02759'
export const FAMILY_ID_APPLICATION = '02758'
export const FAMILY_ID_TRUCK_TYPE = '18008'
export const FAMILY_ID_WHEEL_CONFIGURATION = '02625'
export const FAMILY_ID_CAB_SERIES = '00042'
export const FAMILY_ID_CAB = '00889'

export const GO_CONTINUE_SENTINEL_VALUE = 'Continue'

export const allGoStepIds = [
  FAMILY_ID_OPERATION,
  FAMILY_ID_APPLICATION,
  FAMILY_ID_TRUCK_TYPE,
  FAMILY_ID_WHEEL_CONFIGURATION,
  FAMILY_ID_CAB_SERIES,
  FAMILY_ID_CAB,
]

export const SMART_DASH_FAMILY_ID = '09488'
export const SMART_DASH_READMORE_FAMILY_ID = '02815'
