import React from 'react'
/**
 * This function returns a text chunk with ascii(10) & ascii(12) converted to
 * {newline} and then replaces those paragraphs with html <p>
 * This way it also handle the case where {newline} is expressed already in
 * the sourced data.
 * In the event that the argument `text` is undefined it returns null which
 * allowes for using it with a default text
 * {convertNewlineToJSX(null) || 'Custom default text...'}
 */

const convertNewlineToJSX = (text) => {
  if (!text) return null
  return (
    <div key={'keyNewline' + text}>
      {text
        .replace(/\r?\n/g, '{newline}')
        .split('{newline}')
        .map((chunk, i) => (
          <p key={i + "newline" + text}>{chunk}</p>
        ))}
    </div>
  )
}

export default convertNewlineToJSX