// import {useCallback} from 'react';
import { useCallback } from 'react'
import { useAppSelector } from '../store/hooks'
import { getTextsState } from '../store/appSlice'

// TODO: Change this to a named union type of string constants to reduce some
// code duplication.
//
// TODO: Enforce this enum as the only argument to TextGetter instead of
// multiple strings. This will allow us to easily access a list of text symbol
// ids that are actually in use in the GUI. Then do the same on the server-side.
export enum TextId {
  ACTION_SWITCH_OFF = 'ACTION_SWITCH_OFF',
  ACTION_SWITCH_ON = 'ACTION_SWITCH_ON',
  ACTION_UPGRADE_YOUR_TRUCK = 'ACTION_UPGRADE_YOUR_TRUCK',
  BODYBUILD_LABEL = 'BODYBUILD_LABEL',
  CAMPAIGN_SMART_DASH_NAME = 'CAMPAIGN_SMART_DASH_NAME',
  CAMPAIGN_SMART_DASH_TEXT = 'CAMPAIGN_SMART_DASH_TEXT',
  DESCRIPTION_QR_FORM = 'DESCRIPTION_QR_FORM',
  DESCRIPTION_UPGRADE_YOUR_FLEET = 'DESCRIPTION_UPGRADE_YOUR_FLEET',
  DESCRIPTION_UPGRADE_YOUR_FLEET_FM = 'DESCRIPTION_UPGRADE_YOUR_FLEET_FM',
  ERROR_SECM_CHANGE_STEP = 'ERROR_SECM_CHANGE_STEP',
  FM_GO_TO_GUIDED_OFFERING_INSTEAD = 'FM_GO_TO_GUIDED_OFFERING_INSTEAD',
  HEADER_QR_FORM = 'HEADER_QR_FORM',
  HEADER_QR_FORM_COMPANY_INFO = 'HEADER_QR_FORM_COMPANY_INFO',
  HEADER_QR_FORM_FIND_DEALER = 'HEADER_QR_FORM_FIND_DEALER',
  HEADER_QR_FORM_USER_INFO = 'HEADER_QR_FORM_USER_INFO',
  HEADER_UPGRADE_YOUR_TRUCK = 'HEADER_UPGRADE_YOUR_TRUCK',
  LABEL_ACTION_CANCEL = 'LABEL_ACTION_CANCEL',
  LABEL_ACTION_REQUEST_QUOTE = 'LABEL_ACTION_REQUEST_QUOTE',
  LABEL_ADDRESS_CITY = 'LABEL_ADDRESS_CITY',
  LABEL_ADDRESS_POSTAL_CODE = 'LABEL_ADDRESS_POSTAL_CODE',
  LABEL_COMMENTS = 'LABEL_COMMENTS',
  LABEL_COMPANY_NAME = 'LABEL_COMPANY_NAME',
  LABEL_EMAIL = 'LABEL_EMAIL',
  LABEL_FIRST_NAME = 'LABEL_FIRST_NAME',
  LABEL_INVALID_ADDRESS_POSTAL_CODE = 'LABEL_INVALID_ADDRESS_POSTAL_CODE',
  LABEL_INVALID_COMPANY_NAME = 'LABEL_INVALID_COMPANY_NAME',
  LABEL_INVALID_EMAIL = 'LABEL_INVALID_EMAIL',
  LABEL_INVALID_FORM_INPUT = 'LABEL_INVALID_FORM_INPUT',
  LABEL_LAST_NAME = 'LABEL_LAST_NAME',
  LABEL_NO_SELECTION = 'LABEL_NO_SELECTION',
  LABEL_ORGANIZATION_NUMBER = 'LABEL_ORGANIZATION_NUMBER',
  LABEL_PHONE_NUMBER = 'LABEL_PHONE_NUMBER',
  LABEL_SHOW_ALL = 'LABEL_SHOW_ALL',
  LABEL_VAT_NUMBER = 'LABEL_VAT_NUMBER',
  LABEL_VIEW_INTERIOR = 'LABEL_VIEW_INTERIOR',
  PHONE_NUMBER_INSTRUCTIONS = 'PHONE_NUMBER_INSTRUCTIONS',
  RFQ1_CUSTOMER_ROLE = 'RFQ1_CUSTOMER_ROLE',
  RFQ1_CUSTOMER_ROLE_DRIVER = 'RFQ1_CUSTOMER_ROLE_DRIVER',
  RFQ1_CUSTOMER_ROLE_OTHER = 'RFQ1_CUSTOMER_ROLE_OTHER',
  RFQ1_CUSTOMER_ROLE_OWNER = 'RFQ1_CUSTOMER_ROLE_OWNER',
  RFQ1_DISCLAIMER_URL = 'RFQ1_DISCLAIMER_URL',
  RFQ1_DISCLAIMER_URL_LABEL = 'RFQ1_DISCLAIMER_URL_LABEL',
  RFQ1_FINANCE_DESCRIPTION = 'RFQ1_FINANCE_DESCRIPTION',
  RFQ1_I_DONT_KNOW_DEALER = 'RFQ1_I_DONT_KNOW_DEALER',
  RFQ1_NEWSLETTER = 'RFQ1_NEWSLETTER',
  RFQ1_NEWSLETTER_DESCRIPTION = 'RFQ1_NEWSLETTER_DESCRIPTION',
  RFQ1_PERSONEL_DATA = 'RFQ1_PERSONEL_DATA',
  RFQ1_PERSONEL_DATA_DESCRIPTION1 = 'RFQ1_PERSONEL_DATA_DESCRIPTION1',
  RFQ1_PERSONEL_DATA_DESCRIPTION2 = 'RFQ1_PERSONEL_DATA_DESCRIPTION2',
}

/**
 * By using this the localization returns empty string instead of crashing.
 *
 * import useTexts from '../../utils/useTexts';
 *
 * usage:
 * const t = useTexts(); // returns a function.
 *
 * In jsx:
 * <div>{t('MY_SYMBOL_KEY')}</div>
 *
 * Getting varible depending symbols, filtering out characters we can't use in symbol keys.
 * <div>{t('MY_SYMBOL_KEY_', my_id_variable)}</div>
 * in case my_id_variable is '1234~AB' the resulting symbol key is 'MY_SYMBOL_KEY_1234AB'.
 *
 * If the symbol is missing t() returns empty string.
 * Unless ?debug=texts is present in url, then 'Missing symbol' is returned.
 *
 * Symbols kan contain placeholders in the format of '{1}'.
 * The number represents index of the third argument (array).
 * All occurences matching placeholder given index will be replaced with the variable in the array with matching index.
 * Example:
 * Symbol: MY_TEST_SYMBOL = 'Snowwhite and the {0} dwarfs.'
 * const numberOfDwarfs = 'seven';
 * t(MY_TEST_SYMBOL, null, [numberOfDwarfs])
 * Yields 'Snowwhite and the seven dwarfs.'
 */

const temp: Record<string, string> = {
  //  FM_PAGE_TITLE: 'Digital showroom',
  //  FM_FILTER_SHOW_ALL: 'All',
  //  FM_GO_TO_GUIDED_OFFERING_INSTEAD: 'Build your own truck'
  //  FM_SEE_ALL_IN_OPERATION_BUTTON_TEXT: 'See all in {}'
}

export type TextGetter = (
  key: string,
  id?: string,
  replaceArr?: string[],
) => string

function useTexts(): TextGetter {
  const textsObj = useAppSelector(getTextsState)
  const texts = useCallback<TextGetter>(
    (key: string, id?: string, replaceArr?: string[]) => {
      if (id) {
        key = key + id.toString().replace(/[\W]+/, '')
      }
      const missingSymbolReplacment = ''
      let t = textsObj || {}
      let localizedText = t[key] || temp[key] || missingSymbolReplacment
      // localizedText = localizedText.replace(/\n/g, returnJSXbr());
      if (replaceArr && /\{[0-9]+\}/g.test(localizedText)) {
        replaceArr.forEach((replacement_text, i) => {
          const regex = new RegExp('\\{' + i + '\\}', 'g')
          new RegExp('\\{' + i + '\\}', 'g')
          localizedText = localizedText.replace(regex, replacement_text)
        })
      }
      return localizedText
    },
    [textsObj],
  )

  // IMPORTANT:
  // This must be a memoized callback from useCallback to allow using the
  // returned TextGetter in React hook dependency lists, or there will be
  // infinite React render loops!
  return texts
}

export type TextGetterV2 = (id: TextId) => string

/** TODO: Replace the original one everywhere. */
export function useTextsV2(): TextGetterV2 {
  const textsInRedux = useAppSelector(getTextsState)
  const textGetter = useCallback<TextGetterV2>(
    (id) => {
      let texts = textsInRedux || {}
      const text = texts[id]
      return text === null || text === undefined ? '' : text
    },
    [textsInRedux],
  )
  return textGetter
}

export default useTexts
