/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { LoginError } from './LoginError'
import {
  LoginErrorFromJSON,
  LoginErrorFromJSONTyped,
  LoginErrorToJSON,
} from './LoginError'

/**
 * This wrapper is needed to match the old API exactly diring the
 * migration, consider using different models and response codes for
 * success and error cases later.
 * @export
 * @interface LoginResponseWrapper
 */
export interface LoginResponseWrapper {
  /**
   *
   * @type {string}
   * @memberof LoginResponseWrapper
   */
  login?: string | null
  /**
   *
   * @type {LoginError}
   * @memberof LoginResponseWrapper
   */
  error?: LoginError | null
}

/**
 * Check if a given object implements the LoginResponseWrapper interface.
 */
export function instanceOfLoginResponseWrapper(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function LoginResponseWrapperFromJSON(json: any): LoginResponseWrapper {
  return LoginResponseWrapperFromJSONTyped(json, false)
}

export function LoginResponseWrapperFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): LoginResponseWrapper {
  if (json === undefined || json === null) {
    return json
  }
  return {
    login: !exists(json, 'login') ? undefined : json['login'],
    error: !exists(json, 'error')
      ? undefined
      : LoginErrorFromJSON(json['error']),
  }
}

export function LoginResponseWrapperToJSON(
  value?: LoginResponseWrapper | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    login: value.login,
    error: LoginErrorToJSON(value.error),
  }
}
