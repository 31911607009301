/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GoConfig } from './GoConfig'
import {
  GoConfigFromJSON,
  GoConfigFromJSONTyped,
  GoConfigToJSON,
} from './GoConfig'
import type { GoQuestion } from './GoQuestion'
import {
  GoQuestionFromJSON,
  GoQuestionFromJSONTyped,
  GoQuestionToJSON,
} from './GoQuestion'
import type { GoState } from './GoState'
import { GoStateFromJSON, GoStateFromJSONTyped, GoStateToJSON } from './GoState'

/**
 *
 * @export
 * @interface GoWizardCloseResponseWrapperGowizardClose
 */
export interface GoWizardCloseResponseWrapperGowizardClose {
  /**
   *
   * @type {boolean}
   * @memberof GoWizardCloseResponseWrapperGowizardClose
   */
  bevFiltersEnabled: boolean
  /**
   * Can be any one or any combination of:
   *   NONE,
   *   REQUIRED,
   *   EXIT,
   *   DONE,
   *   OPTIONAL,
   *   OPTIONAL_EXIT,
   *   FACTORY_MODELS.
   *
   * When multiple variants are combined they are separated by ','
   * inside the string.
   * @type {string}
   * @memberof GoWizardCloseResponseWrapperGowizardClose
   */
  phase: string
  /**
   *
   * @type {string}
   * @memberof GoWizardCloseResponseWrapperGowizardClose
   */
  language: string
  /**
   *
   * @type {string}
   * @memberof GoWizardCloseResponseWrapperGowizardClose
   */
  market: string
  /**
   *
   * @type {boolean}
   * @memberof GoWizardCloseResponseWrapperGowizardClose
   */
  undoAvailable: boolean
  /**
   *
   * @type {string}
   * @memberof GoWizardCloseResponseWrapperGowizardClose
   */
  etelLastUpdated: string
  /**
   *
   * @type {GoQuestion}
   * @memberof GoWizardCloseResponseWrapperGowizardClose
   */
  question?: GoQuestion | null
  /**
   * The final, optional, questions in the Guided Offering sequence, all
   * with slider/interval based inputs. This property will be non-null
   * when the "question" property contains the "Exit" and "Continue"
   * options.
   * @type {Array<GoQuestion>}
   * @memberof GoWizardCloseResponseWrapperGowizardClose
   */
  sliderQuestions?: Array<GoQuestion> | null
  /**
   *
   * @type {GoState}
   * @memberof GoWizardCloseResponseWrapperGowizardClose
   */
  state?: GoState | null
  /**
   *
   * @type {GoConfig}
   * @memberof GoWizardCloseResponseWrapperGowizardClose
   */
  config?: GoConfig | null
}

/**
 * Check if a given object implements the GoWizardCloseResponseWrapperGowizardClose interface.
 */
export function instanceOfGoWizardCloseResponseWrapperGowizardClose(
  value: object,
): boolean {
  let isInstance = true
  isInstance = isInstance && 'bevFiltersEnabled' in value
  isInstance = isInstance && 'phase' in value
  isInstance = isInstance && 'language' in value
  isInstance = isInstance && 'market' in value
  isInstance = isInstance && 'undoAvailable' in value
  isInstance = isInstance && 'etelLastUpdated' in value

  return isInstance
}

export function GoWizardCloseResponseWrapperGowizardCloseFromJSON(
  json: any,
): GoWizardCloseResponseWrapperGowizardClose {
  return GoWizardCloseResponseWrapperGowizardCloseFromJSONTyped(json, false)
}

export function GoWizardCloseResponseWrapperGowizardCloseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GoWizardCloseResponseWrapperGowizardClose {
  if (json === undefined || json === null) {
    return json
  }
  return {
    bevFiltersEnabled: json['bevFiltersEnabled'],
    phase: json['phase'],
    language: json['language'],
    market: json['market'],
    undoAvailable: json['undoAvailable'],
    etelLastUpdated: json['etelLastUpdated'],
    question: !exists(json, 'question')
      ? undefined
      : GoQuestionFromJSON(json['question']),
    sliderQuestions: !exists(json, 'sliderQuestions')
      ? undefined
      : json['sliderQuestions'] === null
      ? null
      : (json['sliderQuestions'] as Array<any>).map(GoQuestionFromJSON),
    state: !exists(json, 'state') ? undefined : GoStateFromJSON(json['state']),
    config: !exists(json, 'config')
      ? undefined
      : GoConfigFromJSON(json['config']),
  }
}

export function GoWizardCloseResponseWrapperGowizardCloseToJSON(
  value?: GoWizardCloseResponseWrapperGowizardClose | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    bevFiltersEnabled: value.bevFiltersEnabled,
    phase: value.phase,
    language: value.language,
    market: value.market,
    undoAvailable: value.undoAvailable,
    etelLastUpdated: value.etelLastUpdated,
    question: GoQuestionToJSON(value.question),
    sliderQuestions:
      value.sliderQuestions === undefined
        ? undefined
        : value.sliderQuestions === null
        ? null
        : (value.sliderQuestions as Array<any>).map(GoQuestionToJSON),
    state: GoStateToJSON(value.state),
    config: GoConfigToJSON(value.config),
  }
}
