import styled from 'styled-components'
import { TestElementTypeId } from '../../types/TestAttributeId'
import useTexts from '../../utils/useTexts'
import { SvgLoading } from '../SvgImports'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { closeAllModals } from '../../store/modalSlice'
import { getShareLink } from '../../store/sessionDataSlice'
import { TdsButton, TdsModal } from '@scania/tegel-react'
import { useCallback, useEffect } from 'react'

const SpinnerWrapper = styled.div`
  margin-left: 32px;

  svg {
    color: var(--tds-blue-600);
  }
`

const TextBox = styled.div`
  margin-bottom: 16px;
`

const LinkShareCopyBox = styled.div`
  display: inline-block;
  margin-right: 16px;
  border: 1px solid var(--tds-grey-300);
  padding: 8px;
  width: calc(100% - 140px);
`

export function ModalShareLink(): JSX.Element {
  const t = useTexts()
  const dispatch = useAppDispatch()
  const url = useAppSelector(getShareLink)

  const copyTextToClipboard = () => {
    navigator.clipboard.writeText(url || '')
  }

  const handleCloseClick = useCallback(() => {
    dispatch(closeAllModals())
  }, [dispatch])

  // Eventlistener to close modal on tdsClose (click outside modal and click on close cross)
  useEffect(() => {
    document.addEventListener('tdsClose', handleCloseClick)
    return () => {
      document.removeEventListener('tdsClose', handleCloseClick)
    }
  }, [handleCloseClick])

  return (
    <TdsModal size="md" header={t('HEADER_SHARE_LINK')} show>
      <span slot="body">
        <TextBox>{t('DESCRIPTION_SHARE_LINK')}</TextBox>
        {url ? (
          <div>
            <LinkShareCopyBox>
              <input
                type="text"
                value={url || ''}
                readOnly
                data-test-element-type={
                  TestElementTypeId.ConfigurationMenuShareLinkCopy
                }
                style={{ border: 'none', width: '100%' }}
              ></input>
            </LinkShareCopyBox>
            <TdsButton
              variant="primary"
              size="md"
              onClick={copyTextToClipboard}
              text={t('LABEL_ACTION_COPY')}
            />
          </div>
        ) : (
          <SpinnerWrapper>
            <SvgLoading />
          </SpinnerWrapper>
        )}
      </span>

      <span slot="actions" className="tds-u-flex tds-u-gap2 tds-u-flex-wrap">
        <TdsButton
          variant="secondary"
          size="md"
          text={t('LABEL_ACTION_CLOSE')}
          onClick={handleCloseClick}
        />
      </span>
    </TdsModal>
  )
}
