/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface Recipient
 */
export interface Recipient {
  /**
   *
   * @type {string}
   * @memberof Recipient
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof Recipient
   */
  email: string
}

/**
 * Check if a given object implements the Recipient interface.
 */
export function instanceOfRecipient(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'name' in value
  isInstance = isInstance && 'email' in value

  return isInstance
}

export function RecipientFromJSON(json: any): Recipient {
  return RecipientFromJSONTyped(json, false)
}

export function RecipientFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Recipient {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: json['Name'],
    email: json['Email'],
  }
}

export function RecipientToJSON(value?: Recipient | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    Name: value.name,
    Email: value.email,
  }
}
