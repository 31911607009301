/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { FlexImageSuccessResultBoundingBoxResult } from './FlexImageSuccessResultBoundingBoxResult'
import {
  FlexImageSuccessResultBoundingBoxResultFromJSON,
  FlexImageSuccessResultBoundingBoxResultFromJSONTyped,
  FlexImageSuccessResultBoundingBoxResultToJSON,
} from './FlexImageSuccessResultBoundingBoxResult'

/**
 *
 * @export
 * @interface FlexImageSuccessResult
 */
export interface FlexImageSuccessResult {
  /**
   *
   * @type {string}
   * @memberof FlexImageSuccessResult
   */
  url: string
  /**
   *
   * @type {FlexImageSuccessResultBoundingBoxResult}
   * @memberof FlexImageSuccessResult
   */
  boundingBoxResult?: FlexImageSuccessResultBoundingBoxResult | null
}

/**
 * Check if a given object implements the FlexImageSuccessResult interface.
 */
export function instanceOfFlexImageSuccessResult(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'url' in value

  return isInstance
}

export function FlexImageSuccessResultFromJSON(
  json: any,
): FlexImageSuccessResult {
  return FlexImageSuccessResultFromJSONTyped(json, false)
}

export function FlexImageSuccessResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): FlexImageSuccessResult {
  if (json === undefined || json === null) {
    return json
  }
  return {
    url: json['url'],
    boundingBoxResult: !exists(json, 'boundingBoxResult')
      ? undefined
      : FlexImageSuccessResultBoundingBoxResultFromJSON(
          json['boundingBoxResult'],
        ),
  }
}

export function FlexImageSuccessResultToJSON(
  value?: FlexImageSuccessResult | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    url: value.url,
    boundingBoxResult: FlexImageSuccessResultBoundingBoxResultToJSON(
      value.boundingBoxResult,
    ),
  }
}
