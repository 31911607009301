// pannellum-react was built and packaged locally from source to get the latest
// version from github, without depending on that github repository in our
// package.json.
//
// Steps used to build and package:
//  - Clone pannellum-react from github.
//  - Navigate to pannellum-react.
//  - `npm install --legacy-peer-deps`
//  - `npm run build`
//  - `npm pack`
//  - Copy the package file to "this projekt root/local_packages/".
//  - `npm install ./local_packages/pannellum-react-1.3.6.tgz`
import { Pannellum } from 'pannellum-react'
import { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { SvgCross, SvgLoading } from '../../components/SvgImports'
import { Size2d } from '../../types/Size2d'
import { setShowPanoramaViewer } from '../../store/sessionDataSlice'
import { useAppDispatch } from '../../store/hooks'

interface PanoramaViewerRootProps {
  $opacity: number
}

const PanoramaViewerRoot = styled.div<PanoramaViewerRootProps>`
  // TODO: Move this element to the root App.tsx and see if that affects
  // performance when clicking and dragging the mouse cursor.
  position: fixed;

  // TODO: Revisit and try to eliminate.
  z-index: 100;

  opacity: ${(props) => props.$opacity};

  // TODO: Transition this to semi transparent of the loading takes a long time.
  background-color: rgba(255, 255, 255, 0);

  transition-property: opacity;
  transition-duration: 300ms;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

interface SpinnerWrapperProps {
  $opacity: number
}

const SpinnerWrapper = styled.div<SpinnerWrapperProps>`
  position: absolute;
  top: 0;
  left: 0;
  transition-property: opacity;
  transition-duration: 300ms;
  opacity: ${(props) => props.$opacity};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  & > svg {
    width: 64px;
    height: 64px;
    color: var(--tds-blue-600);
  }
`

const CrossIcon = styled.div`
  position: absolute;
  border-radius: 3px; // Match the built-in controls.
  background-color: white;
  color: var(--tds-grey-800);
  top: 8px;
  right: 8px;
  width: 32px;
  height: 32px;
  cursor: pointer;
`

interface PannellumWrapperProps {
  $opacity: number
}

const PannellumWrapper = styled.div<PannellumWrapperProps>`
  width: 100%;
  height: 100%;
  transition-property: opacity;
  transition-duration: 300ms;
  opacity: ${(props) => props.$opacity};
`

interface FovLimits {
  maxHorizontalFov: number
  minHorizontalFov: number
}

// Values that seem to look good on various screens: BEGIN
const MAX_HFOV_ARBITRARY_LIMIT = 115
const MIN_HFOV_ARBITRARY_FACTOR = 0.75
const SQUARE_VIEWPORT_ARBITRARY_MAX_HFOV = 80
// Values that seem to look good on various screens: END

export type PanoramaImageBuilder = () => Promise<{ imageUrl: string }>

export interface PanoramaViewerProps {
  buildImage: PanoramaImageBuilder
}

export const PanoramaViewer = ({
  buildImage,
}: PanoramaViewerProps): JSX.Element => {
  const dispatch = useAppDispatch()
  const [imageUrl, setImageUrl] = useState<string | undefined>(undefined)
  const [size, setSize] = useState<Size2d | null>(null)
  const [fovLimits, setFovLimits] = useState<FovLimits | null>(null)
  const [showSpinner, setShowSpinner] = useState(true)
  const [spinnerOpacity, setSpinnerOpacity] = useState(1)
  const [isClosing, setIsClosing] = useState(false)
  const [rootOpacity, setRootOpacity] = useState(0)
  const [pannellumWrapperOpacity, setPannellumWrapperOpacity] = useState(0)
  const panoramaRootRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const asyncWrapper = async () => {
      const response = await buildImage()
      setImageUrl(response.imageUrl)
    }
    asyncWrapper()
  }, [buildImage])

  const handleKeydown = useCallback(
    (ev: KeyboardEvent) => {
      if (ev.key === 'Escape') {
        setIsClosing(true)
      }
    },
    [setIsClosing],
  )

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown)
    return () => {
      document.removeEventListener('keydown', handleKeydown)
    }
  }, [handleKeydown])

  const resizeObserver = useRef(
    new ResizeObserver((entries) => {
      const target = entries[0].target
      const size: Size2d = {
        width: target.clientWidth,
        height: target.clientHeight,
      }
      setSize(size)
    }),
  )

  useEffect(() => {
    if (!panoramaRootRef.current) {
      return
    }
    const observer = resizeObserver
    observer.current.observe(panoramaRootRef.current)
    return () => {
      observer.current.disconnect()
    }
  }, [panoramaRootRef])

  useEffect(() => {
    if (!size) {
      return
    }
    if (size.height === 0) {
      return
    }
    let newHMax =
      (size.width / size.height) * SQUARE_VIEWPORT_ARBITRARY_MAX_HFOV
    newHMax = Math.min(MAX_HFOV_ARBITRARY_LIMIT, newHMax)
    const newState: FovLimits = {
      maxHorizontalFov: newHMax,
      minHorizontalFov: newHMax * MIN_HFOV_ARBITRARY_FACTOR,
    }
    setFovLimits(newState)
  }, [size])

  const handleSpinnerTransitionEnd = useCallback(() => {
    setShowSpinner(false)
  }, [setShowSpinner])

  const handleRootTransitionEnd = useCallback(() => {
    if (isClosing) {
      dispatch(setShowPanoramaViewer(false))
    }
  }, [isClosing, dispatch])

  useEffect(() => {
    setRootOpacity(isClosing ? 0 : 1)
  }, [isClosing])

  const handleCrossClick = useCallback(() => {
    setIsClosing(true)
  }, [setIsClosing])

  return (
    <PanoramaViewerRoot
      ref={panoramaRootRef}
      $opacity={rootOpacity}
      onTransitionEnd={handleRootTransitionEnd}
    >
      {fovLimits && (
        <PannellumWrapper $opacity={pannellumWrapperOpacity}>
          <Pannellum
            width="100%"
            height="100%"
            image={imageUrl}
            pitch={-20}
            // Focus on the driver side, TODO: Detect RHD/LHD market by market code.
            yaw={-25}
            // When the max limit changes, change the current hfov as well.
            hfov={fovLimits.maxHorizontalFov}
            minHfov={fovLimits.minHorizontalFov}
            maxHfov={fovLimits.maxHorizontalFov}
            autoLoad
            onLoad={() => {
              setPannellumWrapperOpacity(1)
              setSpinnerOpacity(0)
            }}
          />
          <CrossIcon onClick={handleCrossClick}>
            <SvgCross />
          </CrossIcon>
        </PannellumWrapper>
      )}
      {
        // TODO: Move the spinner to App.tsx?
      }
      {showSpinner && (
        <SpinnerWrapper
          $opacity={spinnerOpacity}
          onTransitionEnd={handleSpinnerTransitionEnd}
        >
          <SvgLoading />
        </SpinnerWrapper>
      )}
    </PanoramaViewerRoot>
  )
}
