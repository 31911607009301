import styled from 'styled-components'
import { SvgOperationLongDistance } from '../../components/SvgImports'
import { SuperCampaignData } from './SuperCampaignData'

export const SuperCampaignAreaRoot = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  color: white;
  width: 100%;

  .campaign-footer-link {
    font-family: 'Scania Sans Semi Condensed', sans-serif;
  }

  .campaign-footer-link:link,
  .campaign-footer-link:visited {
    color: white;
  }

  @media (hover: hover) and (pointer: fine) {
    .campaign-footer-link:hover {
      color: white;
    }
  }

  .campaign-footer-short-text {
    color: white;
    text-align: right;
    font-family: 'Scania Sans Semi Condensed Bold', sans-serif;
  }

  .campaign-footer-icon {
    margin-left: 16px;
    margin-right: 16px;
    width: 32px;
    height: 32px;
  }
`

export function SuperCampaignFooter({
  shortText,
  linkText,
  linkUrl,
}: SuperCampaignData): JSX.Element {
  return (
    <SuperCampaignAreaRoot>
      <span className="campaign-footer-short-text">{shortText}</span>
      <SvgOperationLongDistance className="campaign-footer-icon" />
      <a
        className="campaign-footer-link"
        href={linkUrl}
        target="_blank"
        rel="noreferrer"
      >
        {linkText}
      </a>
    </SuperCampaignAreaRoot>
  )
}
