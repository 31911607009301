import { GoOption } from './generated'

export function hasReadMore(opt: GoOption): boolean {
  const images = getReadmoreImageUrls(opt)
  if (images && images.length > 0) {
    return true
  }
  if (opt.readmoreText) {
    return true
  }
  return false
}

export function getReadmoreImageUrls(opt: GoOption): string[] {
  const urls = opt?.readmore?.resources?.flatMap((r) => {
    if (r.resourceType !== 'Image') {
      return []
    }
    if (!r.path) {
      return []
    }
    return [r.path]
  })
  return urls || []
}
