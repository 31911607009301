/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface GetCurrentConfigImageRequest
 */
export interface GetCurrentConfigImageRequest {
  /**
   *
   * @type {string}
   * @memberof GetCurrentConfigImageRequest
   */
  sessionId: string
  /**
   *
   * @type {number}
   * @memberof GetCurrentConfigImageRequest
   */
  scaledWidth: number
  /**
   *
   * @type {number}
   * @memberof GetCurrentConfigImageRequest
   */
  scaledHeight: number
  /**
   *
   * @type {string}
   * @memberof GetCurrentConfigImageRequest
   */
  seriesName: string
  /**
   *
   * @type {number}
   * @memberof GetCurrentConfigImageRequest
   */
  frame: number
  /**
   *
   * @type {number}
   * @memberof GetCurrentConfigImageRequest
   */
  cropRatioX: number
  /**
   *
   * @type {number}
   * @memberof GetCurrentConfigImageRequest
   */
  cropRatioY: number
  /**
   *
   * @type {number}
   * @memberof GetCurrentConfigImageRequest
   */
  croppedWidth: number
  /**
   *
   * @type {number}
   * @memberof GetCurrentConfigImageRequest
   */
  croppedHeight: number
  /**
   *
   * @type {string}
   * @memberof GetCurrentConfigImageRequest
   */
  fileType: string
  /**
   *
   * @type {boolean}
   * @memberof GetCurrentConfigImageRequest
   */
  useHd: boolean
  /**
   *
   * @type {boolean}
   * @memberof GetCurrentConfigImageRequest
   */
  autoCrop: boolean
  /**
   *
   * @type {string}
   * @memberof GetCurrentConfigImageRequest
   */
  skip?: string | null
  /**
   *
   * @type {string}
   * @memberof GetCurrentConfigImageRequest
   */
  skipGroups?: string | null
  /**
   *
   * @type {string}
   * @memberof GetCurrentConfigImageRequest
   */
  onlyUse?: string | null
  /**
   *
   * @type {string}
   * @memberof GetCurrentConfigImageRequest
   */
  onlyUseGroups?: string | null
  /**
   *
   * @type {string}
   * @memberof GetCurrentConfigImageRequest
   */
  bgColorHexArgb?: string | null
  /**
   *
   * @type {boolean}
   * @memberof GetCurrentConfigImageRequest
   */
  allowPadding?: boolean
}

/**
 * Check if a given object implements the GetCurrentConfigImageRequest interface.
 */
export function instanceOfGetCurrentConfigImageRequest(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'sessionId' in value
  isInstance = isInstance && 'scaledWidth' in value
  isInstance = isInstance && 'scaledHeight' in value
  isInstance = isInstance && 'seriesName' in value
  isInstance = isInstance && 'frame' in value
  isInstance = isInstance && 'cropRatioX' in value
  isInstance = isInstance && 'cropRatioY' in value
  isInstance = isInstance && 'croppedWidth' in value
  isInstance = isInstance && 'croppedHeight' in value
  isInstance = isInstance && 'fileType' in value
  isInstance = isInstance && 'useHd' in value
  isInstance = isInstance && 'autoCrop' in value

  return isInstance
}

export function GetCurrentConfigImageRequestFromJSON(
  json: any,
): GetCurrentConfigImageRequest {
  return GetCurrentConfigImageRequestFromJSONTyped(json, false)
}

export function GetCurrentConfigImageRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GetCurrentConfigImageRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    sessionId: json['session_id'],
    scaledWidth: json['scaled_width'],
    scaledHeight: json['scaled_height'],
    seriesName: json['series_name'],
    frame: json['frame'],
    cropRatioX: json['crop_ratio_x'],
    cropRatioY: json['crop_ratio_y'],
    croppedWidth: json['cropped_width'],
    croppedHeight: json['cropped_height'],
    fileType: json['file_type'],
    useHd: json['use_hd'],
    autoCrop: json['auto_crop'],
    skip: !exists(json, 'skip') ? undefined : json['skip'],
    skipGroups: !exists(json, 'skip_groups') ? undefined : json['skip_groups'],
    onlyUse: !exists(json, 'only_use') ? undefined : json['only_use'],
    onlyUseGroups: !exists(json, 'only_use_groups')
      ? undefined
      : json['only_use_groups'],
    bgColorHexArgb: !exists(json, 'bg_color_hex_argb')
      ? undefined
      : json['bg_color_hex_argb'],
    allowPadding: !exists(json, 'allow_padding')
      ? undefined
      : json['allow_padding'],
  }
}

export function GetCurrentConfigImageRequestToJSON(
  value?: GetCurrentConfigImageRequest | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    session_id: value.sessionId,
    scaled_width: value.scaledWidth,
    scaled_height: value.scaledHeight,
    series_name: value.seriesName,
    frame: value.frame,
    crop_ratio_x: value.cropRatioX,
    crop_ratio_y: value.cropRatioY,
    cropped_width: value.croppedWidth,
    cropped_height: value.croppedHeight,
    file_type: value.fileType,
    use_hd: value.useHd,
    auto_crop: value.autoCrop,
    skip: value.skip,
    skip_groups: value.skipGroups,
    only_use: value.onlyUse,
    only_use_groups: value.onlyUseGroups,
    bg_color_hex_argb: value.bgColorHexArgb,
    allow_padding: value.allowPadding,
  }
}
