import styled from 'styled-components'
import { TextId, useTextsV2 } from '../../utils/useTexts'
import { TdsToggle } from '@scania/tegel-react'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import {
  getBodyBuildToggled,
  setBodyBuildToggled,
} from '../../store/sessionDataSlice'
import {
  ScaniaAdobeEventId,
  ScaniaAdobeTrackingClickBodyBuildToggleEvent,
  pushAdobeEvent,
} from '../../utils/adobeAnalytics'
import { TdsToggleCustomEvent } from '@scania/tegel/dist/types/components'
import { getMarketSettingsState } from '../../store/appSlice'
import { useEffect } from 'react'
import { BreakpointWidthPx } from '../../css/BreakpointWidthPx'

export enum ButtonSize {
  large,
  small,
}

interface BodyBuildToggleProps {
  $size: ButtonSize
}

const BodyBuildsWrapper = styled.div<BodyBuildToggleProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 4px;
  margin-left: 4px;
  min-width: ${(props) =>
    props.$size === ButtonSize.large ? '125px' : '40px'};

  @media screen and (max-width: ${BreakpointWidthPx.Phone}px) {
    margin-right: 0px;
  }
`

const BodyBuildLabelOnTop = styled.label`
  position: relative;
  top: -10px;
  padding-left: 0px;
  color: var(--tds-grey-700);
`

const ToggleWrapper = styled.div<BodyBuildToggleProps>`
  margin-bottom: ${(props) =>
    props.$size === ButtonSize.large ? '0px' : '16px'};

  input {
    cursor: pointer !important;
  }
`

export interface BodyBuildProps {
  disabled: boolean
  size: ButtonSize
}

export const BodyBuildToggle = ({
  disabled,
  size,
}: BodyBuildProps): JSX.Element => {
  const t = useTextsV2()
  const onOffState = useAppSelector(getBodyBuildToggled)
  const defaultOnOffState = useAppSelector(
    getMarketSettingsState,
  )?.bodyBuildsShowDefault
  const dispatch = useAppDispatch()

  // Update default toggle state for BodyBuild from market settings
  useEffect(() => {
    if (defaultOnOffState !== undefined) {
      dispatch(setBodyBuildToggled(defaultOnOffState))
    }
  }, [dispatch, defaultOnOffState])

  const handleBodyBuildToggle = (
    e: TdsToggleCustomEvent<{
      toggleId: string
      checked: boolean
    }>,
  ) => {
    if (e === null) {
      return
    }
    const checkedInput = e.detail.checked
    const adobeEvent: ScaniaAdobeTrackingClickBodyBuildToggleEvent = {
      event: ScaniaAdobeEventId.BodyBuildToggleClick,
      eventInfo: {
        newState: checkedInput ? 'ON' : 'OFF',
      },
    }
    pushAdobeEvent(adobeEvent)
    dispatch(setBodyBuildToggled(checkedInput))
  }

  return (
    <BodyBuildsWrapper $size={size}>
      {size === ButtonSize.small && (
        <BodyBuildLabelOnTop>{t(TextId.BODYBUILD_LABEL)}</BodyBuildLabelOnTop>
      )}
      <ToggleWrapper $size={size}>
        <TdsToggle
          checked={onOffState}
          size={size === ButtonSize.large ? 'lg' : 'sm'}
          onTdsToggle={handleBodyBuildToggle}
          disabled={disabled}
          key={'TdsToggle - button size' + size + onOffState}
        >
          {size === ButtonSize.large && (
            <div slot="label">{t(TextId.BODYBUILD_LABEL)}</div>
          )}
        </TdsToggle>
      </ToggleWrapper>
    </BodyBuildsWrapper>
  )
}
