import {
  UrlParamMode,
  getUrlCidBevSemiliveParameters,
} from '../utils/getUrlCidParameter'
import { GuiMarketSettings } from './generated'

export enum GoFmMode {
  OnlyGuidedOffering = 0,
  OnlyFactoryModels = 1,
  GuidedOfferingAndFactoryModels = 2,
}

export function factoryModelsEnabled(
  marketSettings: GuiMarketSettings,
): boolean {
  if (marketSettings.factoryModelsMode === GoFmMode.OnlyFactoryModels) {
    return true
  }
  if (
    marketSettings.factoryModelsMode === GoFmMode.GuidedOfferingAndFactoryModels
  ) {
    return true
  }
  const urlCidParams = getUrlCidBevSemiliveParameters(UrlParamMode.IncludeBev)
  if (marketSettings.prepareFm && urlCidParams.has('semilive')) {
    return true
  }
  return false
}

export function guidedOfferingEnabled(
  marketSettings: GuiMarketSettings,
): boolean {
  if (marketSettings.factoryModelsMode === GoFmMode.OnlyGuidedOffering) {
    return true
  }
  if (
    marketSettings.factoryModelsMode === GoFmMode.GuidedOfferingAndFactoryModels
  ) {
    return true
  }
  const urlCidParams = getUrlCidBevSemiliveParameters(UrlParamMode.IncludeBev)
  if (marketSettings.prepareGo && urlCidParams.has('semilive')) {
    return true
  }
  return false
}
