/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface DealerAdditionalEmail
 */
export interface DealerAdditionalEmail {
  /**
   *
   * @type {string}
   * @memberof DealerAdditionalEmail
   */
  type?: string | null
  /**
   *
   * @type {string}
   * @memberof DealerAdditionalEmail
   */
  address?: string | null
  /**
   *
   * @type {string}
   * @memberof DealerAdditionalEmail
   */
  comment?: string | null
}

/**
 * Check if a given object implements the DealerAdditionalEmail interface.
 */
export function instanceOfDealerAdditionalEmail(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function DealerAdditionalEmailFromJSON(
  json: any,
): DealerAdditionalEmail {
  return DealerAdditionalEmailFromJSONTyped(json, false)
}

export function DealerAdditionalEmailFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): DealerAdditionalEmail {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: !exists(json, 'Type') ? undefined : json['Type'],
    address: !exists(json, 'Address') ? undefined : json['Address'],
    comment: !exists(json, 'Comment') ? undefined : json['Comment'],
  }
}

export function DealerAdditionalEmailToJSON(
  value?: DealerAdditionalEmail | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    Type: value.type,
    Address: value.address,
    Comment: value.comment,
  }
}
