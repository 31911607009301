/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const TryChangeConfigResultCode = {
  Activated: 'ACTIVATED',
  MultipleResolvers: 'MULTIPLE_RESOLVERS',
  Resolvers: 'RESOLVERS',
} as const
export type TryChangeConfigResultCode =
  (typeof TryChangeConfigResultCode)[keyof typeof TryChangeConfigResultCode]

export function TryChangeConfigResultCodeFromJSON(
  json: any,
): TryChangeConfigResultCode {
  return TryChangeConfigResultCodeFromJSONTyped(json, false)
}

export function TryChangeConfigResultCodeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): TryChangeConfigResultCode {
  return json as TryChangeConfigResultCode
}

export function TryChangeConfigResultCodeToJSON(
  value?: TryChangeConfigResultCode | null,
): any {
  return value as any
}
