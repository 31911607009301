/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ConfigChangeResult } from './ConfigChangeResult'
import {
  ConfigChangeResultFromJSON,
  ConfigChangeResultFromJSONTyped,
  ConfigChangeResultToJSON,
} from './ConfigChangeResult'
import type { OutResolvers } from './OutResolvers'
import {
  OutResolversFromJSON,
  OutResolversFromJSONTyped,
  OutResolversToJSON,
} from './OutResolvers'
import type { TryChangeConfigResultCode } from './TryChangeConfigResultCode'
import {
  TryChangeConfigResultCodeFromJSON,
  TryChangeConfigResultCodeFromJSONTyped,
  TryChangeConfigResultCodeToJSON,
} from './TryChangeConfigResultCode'

/**
 *
 * @export
 * @interface TryChangeConfigResult
 */
export interface TryChangeConfigResult {
  /**
   *
   * @type {TryChangeConfigResultCode}
   * @memberof TryChangeConfigResult
   */
  resultCode: TryChangeConfigResultCode
  /**
   *
   * @type {ConfigChangeResult}
   * @memberof TryChangeConfigResult
   */
  changeConfigResult?: ConfigChangeResult | null
  /**
   *
   * @type {OutResolvers}
   * @memberof TryChangeConfigResult
   */
  resolvers?: OutResolvers | null
  /**
   *
   * @type {Array<OutResolvers>}
   * @memberof TryChangeConfigResult
   */
  multipleResolvers?: Array<OutResolvers> | null
  /**
   *
   * @type {string}
   * @memberof TryChangeConfigResult
   */
  resolversText?: string | null
}

/**
 * Check if a given object implements the TryChangeConfigResult interface.
 */
export function instanceOfTryChangeConfigResult(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'resultCode' in value

  return isInstance
}

export function TryChangeConfigResultFromJSON(
  json: any,
): TryChangeConfigResult {
  return TryChangeConfigResultFromJSONTyped(json, false)
}

export function TryChangeConfigResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): TryChangeConfigResult {
  if (json === undefined || json === null) {
    return json
  }
  return {
    resultCode: TryChangeConfigResultCodeFromJSON(json['result_code']),
    changeConfigResult: !exists(json, 'change_config_result')
      ? undefined
      : ConfigChangeResultFromJSON(json['change_config_result']),
    resolvers: !exists(json, 'resolvers')
      ? undefined
      : OutResolversFromJSON(json['resolvers']),
    multipleResolvers: !exists(json, 'multiple_resolvers')
      ? undefined
      : json['multiple_resolvers'] === null
      ? null
      : (json['multiple_resolvers'] as Array<any>).map(OutResolversFromJSON),
    resolversText: !exists(json, 'resolvers_text')
      ? undefined
      : json['resolvers_text'],
  }
}

export function TryChangeConfigResultToJSON(
  value?: TryChangeConfigResult | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    result_code: TryChangeConfigResultCodeToJSON(value.resultCode),
    change_config_result: ConfigChangeResultToJSON(value.changeConfigResult),
    resolvers: OutResolversToJSON(value.resolvers),
    multiple_resolvers:
      value.multipleResolvers === undefined
        ? undefined
        : value.multipleResolvers === null
        ? null
        : (value.multipleResolvers as Array<any>).map(OutResolversToJSON),
    resolvers_text: value.resolversText,
  }
}
