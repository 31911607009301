/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface SessionInitDataSecmPublishInfo
 */
export interface SessionInitDataSecmPublishInfo {
  /**
   *
   * @type {Date}
   * @memberof SessionInitDataSecmPublishInfo
   */
  publishDateTime?: Date
}

/**
 * Check if a given object implements the SessionInitDataSecmPublishInfo interface.
 */
export function instanceOfSessionInitDataSecmPublishInfo(
  value: object,
): boolean {
  let isInstance = true

  return isInstance
}

export function SessionInitDataSecmPublishInfoFromJSON(
  json: any,
): SessionInitDataSecmPublishInfo {
  return SessionInitDataSecmPublishInfoFromJSONTyped(json, false)
}

export function SessionInitDataSecmPublishInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SessionInitDataSecmPublishInfo {
  if (json === undefined || json === null) {
    return json
  }
  return {
    publishDateTime: !exists(json, 'PublishDateTime')
      ? undefined
      : new Date(json['PublishDateTime']),
  }
}

export function SessionInitDataSecmPublishInfoToJSON(
  value?: SessionInitDataSecmPublishInfo | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    PublishDateTime:
      value.publishDateTime === undefined
        ? undefined
        : value.publishDateTime.toISOString(),
  }
}
