/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ScaniaFrameFilter
 */
export interface ScaniaFrameFilter {
  /**
   *
   * @type {string}
   * @memberof ScaniaFrameFilter
   */
  series: string
  /**
   *
   * @type {number}
   * @memberof ScaniaFrameFilter
   */
  frame: number
}

/**
 * Check if a given object implements the ScaniaFrameFilter interface.
 */
export function instanceOfScaniaFrameFilter(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'series' in value
  isInstance = isInstance && 'frame' in value

  return isInstance
}

export function ScaniaFrameFilterFromJSON(json: any): ScaniaFrameFilter {
  return ScaniaFrameFilterFromJSONTyped(json, false)
}

export function ScaniaFrameFilterFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ScaniaFrameFilter {
  if (json === undefined || json === null) {
    return json
  }
  return {
    series: json['Series'],
    frame: json['Frame'],
  }
}

export function ScaniaFrameFilterToJSON(value?: ScaniaFrameFilter | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    Series: value.series,
    Frame: value.frame,
  }
}
