import styled from 'styled-components'
import { useCallback, useEffect, useRef, useState } from 'react'
import useTexts from '../../utils/useTexts'
import { UserConfigInfo } from '../../api/generated'
import { AccountView } from './AccountView'
import { SvgCross, SvgLoading } from '../SvgImports'
import { buildRelativeBuildPageUrl } from '../../utils/UrlBuilders'
import { useClient } from '../../utils/useClient'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  NewTruckLoadedHandler,
  NewTruckLoadedInfo,
  TruckLoadedSource,
} from '../../types/TruckLoadedTypes'
import {
  ConsequenceOfChangeDialogData,
  ConsequenceOfChangeHandler,
} from '../../types/ConsequenceOfChangeTypes'
import { SESSION_FAILURE } from '../../api/errors'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { TestElementTypeId } from '../../types/TestAttributeId'
import { getUrlParametersToString } from '../../utils/getUrlParametersToString'
import {
  closePanelLoginGarage,
  selectSidePanelState,
} from '../../store/sidePanelSlice'
import {
  deleteTruckFromGarageList,
  getGarageListState,
  getSessionIdState,
} from '../../store/sessionDataSlice'
import { getMarketLanguageState } from '../../store/appSlice'
import { closeAllModals } from '../../store/modalSlice'
import {
  TdsAccordion,
  TdsAccordionItem,
  TdsButton,
  TdsInlineTab,
  TdsInlineTabs,
  TdsTextField,
} from '@scania/tegel-react'

const GarageRoot = styled.div``

interface TabContentProps {
  $showContent: boolean
}

const TabContentContainer = styled.div<TabContentProps>`
  margin: 16px;
  display: ${(props) => (props.$showContent ? '' : 'none')};
`

const ContentContainer = styled.div`
  margin: 16px;
`

const Header = styled.h4`
  margin-bottom: 16px;
`

const TextWrapper = styled.div``

const TruckCard = styled.div`
  background-color: var(--tds-grey-50);
  border-right: 1px solid var(--tds-grey-300);
  border-left: 1px solid var(--tds-grey-300);
  border-top: 1px solid var(--tds-grey-300);
  margin-top: 16px;
`

const TruckHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 8px;
`

const TruckImageBackground = styled.div`
  width: 72px;
  height: 72px;
  border-radius: 50%;
  background-color: var(--tds-blue-50);
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 64px;
  }
`

const TruckTitleModelWrapper = styled.div`
  margin-left: 16px;
  width: 160px;
`

const TruckTitle = styled.h4`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const TruckModel = styled.div`
  font-weight: bold;
  color: var(--tds-grey-600);
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const TruckSpecificationWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 8px;
`

const TruckSpecificationTitles = styled.div`
  color: var(--tds-grey-700);
  font-weight: bold;
`

const TruckSpecificationText = styled.div`
  color: var(--tds-grey-700);
`

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 16px;
  width: 100%;
`

const SpinnerWrapper = styled.div`
  //margin-left: 32px;
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: var(--tds-blue-600);
  }
`

const CloseIconWrapper = styled.div`
  cursor: pointer;
  border-bottom: 1px solid var(--tds-grey-300);

  svg {
    height: 32px;
    width: 32px;
    margin: 12px;
  }
`

interface GarageViewProps {
  handleConsequenceOfChange: ConsequenceOfChangeHandler
  handleFatalError: () => void
  handleNewTruckIsLoaded: NewTruckLoadedHandler
  handleSessionFailure: () => void
  handleTruckHasBeenDeleted: (truck: UserConfigInfo) => void
  handleCloseModalConsequenseOfChange: () => void
}

export function GarageView({
  handleConsequenceOfChange,
  handleFatalError,
  handleNewTruckIsLoaded,
  handleSessionFailure,
  handleTruckHasBeenDeleted,
  handleCloseModalConsequenseOfChange,
}: GarageViewProps): JSX.Element {
  const dispatch = useAppDispatch()
  const t = useTexts()
  const client = useClient()
  const navigate = useNavigate()
  const location = useLocation()
  const marketLanguage = useAppSelector(getMarketLanguageState)
  const sessionId = useAppSelector(getSessionIdState)
  const garageList = useAppSelector(getGarageListState)
  const {
    loginGarageOpen: sidePanelLogInIsOpen,
    infoOpen: sidePanelInfoIsOpen,
  } = useAppSelector(selectSidePanelState)

  const refGarageTab = useRef<HTMLTdsInlineTabElement | null>(null)
  const refAccountTab = useRef<HTMLTdsInlineTabElement | null>(null)

  const [tabContent, setTabContent] = useState<string>('garage')
  const [searchFilter, setSearchFilter] = useState<string>('')
  const [filteredGarageList, setFilteredGarageList] = useState<
    UserConfigInfo[] | null
  >(garageList)
  const [truckIsLoading, setTruckIsLoading] = useState<UserConfigInfo | null>(
    null,
  )

  const handleLoadTruck = useCallback(
    async (item: UserConfigInfo) => {
      if (!sessionId || !client || !marketLanguage) {
        return
      }
      setTruckIsLoading(item)
      await client.userConfigV2Load(sessionId, item.id)

      const coc = await client.getInitialConsequenceOfChange({
        sessionId,
      })
      if (coc.error === SESSION_FAILURE) {
        console.error('getInitialConsequenceOfChange failed: ' + coc.error)
        handleSessionFailure()
        return
      } else if (coc.error) {
        console.error('getInitialConsequenceOfChange failed: ' + coc.error)
        handleFatalError()
        return
      }
      var deactivatedItems =
        coc.getInitialConsequenceOfChange?.deactivatedItems || []
      var triggerItems = coc.getInitialConsequenceOfChange?.triggerItems || []
      if (deactivatedItems.length > 0 || triggerItems.length > 0) {
        const data: ConsequenceOfChangeDialogData = {
          resolvers: {
            deactivatedItems,
            triggerItems,
          },

          // When loading a new configuration, we don't support rollback and
          // must display the Consequence of Change message with an OK button
          // and witout any Cancel or close buttons.
          //
          // IMPORTANT:
          // In this context, Consequence of Change is actually incorrect
          // terminology and should probably be changed to a dedicated request
          // or at least return type as a property on the userConfigV2Load
          // respone object. What this data represents when loading a new truck
          // is the difference from when it was saved, which parts of the saved
          // configuration that is no longer supported in this combination by
          // the new product rules.
          //
          // TODO: Refactor CoC for load config situations and report a
          // "LoadedConfigurationDifference" as part fo the response object or
          // something like that.
          showCancelButton: false,

          clickedId: null,
        }
        await handleConsequenceOfChange(data)
        dispatch(closeAllModals())
        handleCloseModalConsequenseOfChange()
      }

      const relativeUrl = buildRelativeBuildPageUrl(marketLanguage)
      const params = relativeUrl.params
      const newUrl = relativeUrl.path + getUrlParametersToString(params)
      setTruckIsLoading(null)
      const info: NewTruckLoadedInfo = {
        factoryModelName: coc.factoryModelName || null,
        isFactoryModel: coc.isFactoryModel || false,
        publicConfigId: null,
        savedAsId: item.id,
        savedAsName: item.name,
        source: TruckLoadedSource.USER_GARAGE,
        timeLoaded: new Date(),
      }
      handleNewTruckIsLoaded(info)
      if (!location.pathname.includes('/build/')) {
        navigate(newUrl)
      }
    },
    [
      client,
      dispatch,
      handleConsequenceOfChange,
      handleFatalError,
      handleNewTruckIsLoaded,
      handleSessionFailure,
      location,
      marketLanguage,
      navigate,
      sessionId,
      handleCloseModalConsequenseOfChange,
    ],
  )

  const handleDeleteTruck = useCallback(
    async (item: UserConfigInfo) => {
      try {
        if (!sessionId) {
          throw new Error('Expected sessionId to be defined.')
        }
        if (!client) {
          throw new Error('Expected client to be defined.')
        }
        await client.userConfigV2Delete(sessionId, item.id)
        handleTruckHasBeenDeleted(item)
      } catch (err) {
        console.error(err)
        handleFatalError()
        return
      }
      dispatch(deleteTruckFromGarageList(item.id))
    },
    [client, dispatch, handleFatalError, handleTruckHasBeenDeleted, sessionId],
  )

  const handleSearchInput = (e: any) => {
    const newSearchString = e.target.value
    setSearchFilter(newSearchString)
  }

  // Filter on search string
  useEffect(() => {
    if (!garageList) {
      return
    }
    const updatedGarageList = garageList.filter(
      (truck) =>
        truck.name.toUpperCase().includes(searchFilter.toUpperCase()) ||
        truck.description.toUpperCase().includes(searchFilter.toUpperCase()),
    )
    setFilteredGarageList(updatedGarageList)
  }, [searchFilter, garageList])

  // Initital state on changed view
  useEffect(() => {
    setSearchFilter('')
    setFilteredGarageList(garageList)
    //setTabContent('garage')
    //setErrorMessage('')
  }, [tabContent, sidePanelLogInIsOpen, sidePanelInfoIsOpen, garageList])

  const handleCloseClick = useCallback(() => {
    dispatch(closePanelLoginGarage())
  }, [dispatch])

  return (
    <GarageRoot>
      <CloseIconWrapper onClick={handleCloseClick}>
        <SvgCross />
      </CloseIconWrapper>
      {
        // sdds-navigation-tabs seems to be involved with the bug
        // 'ResizeObserver loop limit exceeded' that occurs when this component
        // re-renders?
        //
        // TODO: GarageView.tsx needs to be cleaned up and have a partial rewrite.
        // TODO: Revisit this bug together with the rewrite.
        //
        // The ugly code duplication below is a workaround for the bug
        // 'ResizeObserver loop limit exceeded' that seems to trigger when React
        // modifies the className property of the inner elements, possibly
        // related to SDDS/Tegel being web components.
        //
        // TdsInlineTabs doesn't seem to give the bug (SC1M-2823)
      }
      <TdsInlineTabs default-selected-index="0">
        <TdsInlineTab
          data-test-element-type={TestElementTypeId.GarageViewGarageTab}
          onClick={() => setTabContent('garage')}
          ref={refGarageTab}
        >
          <button>{t('LABEL_GARAGE')}</button>
        </TdsInlineTab>
        <TdsInlineTab
          data-test-element-type={TestElementTypeId.GarageViewAccountTab}
          onClick={() => setTabContent('account')}
          ref={refAccountTab}
        >
          <button>{t('LABEL_ACCOUNT')}</button>
        </TdsInlineTab>
      </TdsInlineTabs>
      {tabContent === 'garage' && (
        <TabContentContainer
          data-test-element-type={
            TestElementTypeId.GarageViewGarageTabContainter
          }
          $showContent={tabContent === 'garage'}
        >
          {!garageList || garageList.length === 0 ? (
            <ContentContainer>
              <Header>{t('HEADER_GARAGE_IS_EMPTY')}</Header>
              <TextWrapper>{t('TEXT_GARAGE_IS_EMPTY')}</TextWrapper>
            </ContentContainer>
          ) : (
            <ContentContainer>
              <div style={{ width: '100%', marginTop: '16px' }}>
                <TdsTextField
                  style={{ height: '68px', minWidth: '230px' }}
                  type="text"
                  placeholder={t('SEARCH')}
                  onInput={handleSearchInput}
                  value={searchFilter}
                />
              </div>
              {filteredGarageList?.map((item, i) => (
                <TruckCard
                  data-test-element-type={
                    TestElementTypeId.GarageViewGarageTabTruckCard
                  }
                  key={'truck-id-' + item.id + '-' + item.name}
                >
                  {item.id && (
                    <TruckHeaderWrapper>
                      <TruckImageBackground>
                        <img src={item.images[0].url} alt="Truck thumbnail" />
                      </TruckImageBackground>
                      <TruckTitleModelWrapper>
                        <TruckTitle
                          data-test-element-type={
                            TestElementTypeId.GarageViewGarageTabTruckCardTitle
                          }
                        >
                          {item.name}
                        </TruckTitle>
                        <TruckModel>
                          {item.description.toUpperCase()}
                        </TruckModel>
                      </TruckTitleModelWrapper>
                    </TruckHeaderWrapper>
                  )}
                  <TdsAccordion
                    data-test-element-type={
                      TestElementTypeId.GarageViewGarageTabTruckCardDetails
                    }
                  >
                    <TdsAccordionItem header={t('LABEL_DETAILS')}>
                      <TruckSpecificationWrapper key={i}>
                        {item.userConfigAux ? (
                          JSON.parse(item.userConfigAux.activeSummary).flatMap(
                            (data: any, i: number) => [
                              <TruckSpecificationTitles
                                key={
                                  'truck-specification-title-' +
                                  item.id +
                                  '-' +
                                  item.name +
                                  '-' +
                                  i
                                }
                              >
                                {data.group}:&nbsp;
                              </TruckSpecificationTitles>,
                              <TruckSpecificationText
                                key={
                                  'truck-specification-text-' +
                                  item.id +
                                  '-' +
                                  item.name +
                                  '-' +
                                  i
                                }
                              >
                                {data.item}
                              </TruckSpecificationText>,
                            ],
                          )
                        ) : (
                          <></>
                        )}
                      </TruckSpecificationWrapper>
                      <ButtonWrapper>
                        {truckIsLoading?.id === item.id ? (
                          <SpinnerWrapper>
                            <SvgLoading />
                          </SpinnerWrapper>
                        ) : (
                          <TdsButton
                            variant="primary"
                            size="sm"
                            text={t('LABEL_LOAD_TRUCK')}
                            data-test-element-type={
                              TestElementTypeId.GarageViewGarageTabLoadTruckButton
                            }
                            onClick={() => {
                              handleLoadTruck(item)
                            }}
                            style={{
                              margin: '8px',
                              minWidth: '100px',
                            }}
                          />
                        )}
                        <TdsButton
                          variant="danger"
                          size="sm"
                          text={t('LABEL_DELETE_TRUCK')}
                          onClick={() => {
                            handleDeleteTruck(item)
                          }}
                          style={{
                            margin: '8px',
                            minWidth: '100px',
                          }}
                        />
                      </ButtonWrapper>
                    </TdsAccordionItem>
                  </TdsAccordion>
                </TruckCard>
              ))}
            </ContentContainer>
          )}
        </TabContentContainer>
      )}
      {tabContent === 'account' && (
        <TabContentContainer
          data-test-element-type={
            TestElementTypeId.GarageViewAccountTabContainer
          }
          $showContent={tabContent === 'account'}
        >
          <AccountView
            handleFatalError={handleFatalError}
            handleSessionFailure={handleSessionFailure}
            handleTruckHasBeenDeleted={handleTruckHasBeenDeleted}
          />
        </TabContentContainer>
      )}
    </GarageRoot>
  )
}
