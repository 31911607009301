/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface DealerToplevelService
 */
export interface DealerToplevelService {
  /**
   *
   * @type {string}
   * @memberof DealerToplevelService
   */
  code?: string | null
  /**
   *
   * @type {string}
   * @memberof DealerToplevelService
   */
  title?: string | null
  /**
   *
   * @type {Array<string>}
   * @memberof DealerToplevelService
   */
  categories?: Array<string> | null
}

/**
 * Check if a given object implements the DealerToplevelService interface.
 */
export function instanceOfDealerToplevelService(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function DealerToplevelServiceFromJSON(
  json: any,
): DealerToplevelService {
  return DealerToplevelServiceFromJSONTyped(json, false)
}

export function DealerToplevelServiceFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): DealerToplevelService {
  if (json === undefined || json === null) {
    return json
  }
  return {
    code: !exists(json, 'Code') ? undefined : json['Code'],
    title: !exists(json, 'Title') ? undefined : json['Title'],
    categories: !exists(json, 'Categories') ? undefined : json['Categories'],
  }
}

export function DealerToplevelServiceToJSON(
  value?: DealerToplevelService | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    Code: value.code,
    Title: value.title,
    Categories: value.categories,
  }
}
