import { useCallback, useEffect, useRef, useState } from 'react'
import ErrorBoundary from '../../../utils/ErrorBoundary'
import useText from '../../../utils/useTexts'
import styled from 'styled-components'
import { Timeline } from '../Timeline'
import { CustomScrollbarMixin } from '../../../css/CustomScrollbarMixin'
import { NavigationButtons } from '../NavigationButtons'
import { FAMILY_ID_APPLICATION } from '../../../api/constants'
import { ScaniaAdobeTrackingPageName } from '../../../utils/adobeAnalytics'
import {
  useSyncClientAndBackendState,
  GoStateSyncSettings,
  GoPageId,
} from '../syncBackendStateHook'
import { PortraitFooter } from '../../../components/Footer/PortraitFooter'
import { ScrollContent } from '../ScrollContent'
import { useTimelineSkipWithTracking } from '../skipWithTracking'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { BreakpointWidthPx } from '../../../css/BreakpointWidthPx'
import { BuildLinkToNextQuestionParams } from '../BuildLinkToNextQuestionParams'
import { useGoChoiceClickHandlers } from '../goChoiceClickHook'
import {
  UrlParamMode,
  getUrlCidBevSemiliveParameters,
} from '../../../utils/getUrlCidParameter'
import { urlHasBevParam } from '../urlHasBevParam'
import { useHandleGoReadMoreClick } from '../goReadmoreUtil'
import { TestElementTypeId } from '../../../types/TestAttributeId'
import { buildButton } from '../../../components/ScaniaButton/ButtonBuilder'
import { GO_LOW_HEIGHT_LIMIT_ADAPTION_APPLICATION } from '../../../constants'
import { getMarketLanguageState } from '../../../store/appSlice'
import { pushPageViewTrackingEvent } from '../../../store/sessionDataSlice'

const ApplicationRoot = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  ${CustomScrollbarMixin};
  overflow-y: auto;
  overflow-x: hidden;
`

const PageContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  flex-grow: 1;
  width: 100vw;

  @media screen and (min-width: ${BreakpointWidthPx.Tablet}px) {
    margin-top: 64px;
  }

  // Reduce margin at low desktop screen heights
  @media screen and (min-width: ${BreakpointWidthPx.Tablet}px) and (max-height: ${GO_LOW_HEIGHT_LIMIT_ADAPTION_APPLICATION}) {
    margin-top: 16px;
  }
`

const BtnLayoutWrapper = styled.div`
  display: grid;
  grid-gap: 0;
  grid-template-areas: 'btnlayout btnlayout btnlayout';
  grid-template-columns: 1fr 2fr 1fr;
  grid-template-rows: auto 20px 1fr 1fr;
  width: min(1000px, 100%);
  height: 100%;
  padding-left: max(0.25rem, 16px);
  padding-right: max(0.25rem, 16px);

  @media screen and (min-width: ${BreakpointWidthPx.Tablet}px) {
    max-height: 800px;
  }
`

const Grid = styled.div`
  grid-area: btnlayout;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  max-width: 100%;
  & > div {
    max-width: 100%;
  }
  padding-bottom: 0.25rem;
`

function buildLinkToNextQuestion({
  marketLanguage,
  urlSelection,
}: BuildLinkToNextQuestionParams) {
  const urlCidParams = getUrlCidBevSemiliveParameters(
    urlHasBevParam() ? UrlParamMode.IncludeBev : UrlParamMode.ExcludeBev,
  )
  const url = `/go/slider/${marketLanguage.market}/${marketLanguage.language}/${urlSelection}`
  const link = !urlCidParams ? url : url + '?' + urlCidParams.toString()
  return link
}

export interface ApplicationPageProps {
  handleFatalError: VoidFunction
  handleGoBack: VoidFunction
  handleTimelineSkip: VoidFunction
}

const stateSyncSettings: GoStateSyncSettings = {
  pageId: GoPageId.Grid,
}

function ApplicationPage({
  handleFatalError,
  handleGoBack,
  handleTimelineSkip,
}: ApplicationPageProps) {
  const dispatch = useAppDispatch()
  const t = useText()
  const marketLanguage = useAppSelector(getMarketLanguageState)
  const btnGridRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const [isReady, setIsReady] = useState<boolean>(false)
  const goClientState = useSyncClientAndBackendState(
    stateSyncSettings,
    handleFatalError,
  )
  const { handleCardClick, handleSkipQuestion } = useGoChoiceClickHandlers(
    buildLinkToNextQuestion,
  )

  const handleTimelineSkipWithTracking =
    useTimelineSkipWithTracking(handleTimelineSkip)

  const handleReadMoreClick = useHandleGoReadMoreClick({
    stepName: ScaniaAdobeTrackingPageName.GoApplication,
  })

  // Adobe user tracking.
  useEffect(() => {
    if (!marketLanguage) {
      return
    }
    dispatch(
      pushPageViewTrackingEvent({
        pageName: ScaniaAdobeTrackingPageName.GoApplication,
        marketLanguage,
      }),
    )
  }, [dispatch, marketLanguage])

  useEffect(() => {
    const familyId = goClientState?.lastResponse.question?.etelFamily
    setIsReady(familyId === FAMILY_ID_APPLICATION)
  }, [goClientState])

  const getPageHeaderText = useCallback(() => {
    const familyId = goClientState?.lastResponse.question?.etelFamily
    if (!familyId) {
      return ''
    }
    return t('SQ_HEADER_', familyId)
  }, [goClientState, t])

  return (
    <ErrorBoundary>
      <ApplicationRoot>
        <ScrollContent>
          <Timeline
            isReady={isReady}
            header={isReady ? getPageHeaderText() : t('LOADING') + '...'}
          />
          {isReady && (
            <>
              <PageContentWrapper>
                <BtnLayoutWrapper ref={containerRef}>
                  <Grid className="grid" ref={btnGridRef}>
                    {goClientState?.cards &&
                      goClientState?.cards
                        .concat()
                        .sort((a, b) => {
                          if (a.header < b.header) {
                            return -1
                          }
                          if (a.header > b.header) {
                            return 1
                          }
                          return 0
                        })
                        .map((card, i) => {
                          return buildButton({
                            id: card.id,
                            index: i,
                            defaultSelected: card.defaultSelected || false,
                            hasReadmore: !!card.readmore,
                            buttonText: card.header,
                            onClick: () =>
                              handleCardClick({
                                id: card.id,
                                isSkippingToNext: false,
                              }),
                            onIconClick: !!card.readmore
                              ? (e) => handleReadMoreClick(e, card.id)
                              : () => {},
                            testElementTypeId:
                              TestElementTypeId.GuidedOfferingChoiceButton,
                            testEtelVariantId: card.id,
                            testGoId: FAMILY_ID_APPLICATION,
                          })
                        })}
                  </Grid>
                </BtnLayoutWrapper>
              </PageContentWrapper>
              <NavigationButtons
                handleGoBack={handleGoBack}
                handleGoNext={handleSkipQuestion}
                handleStartConfigurationClick={handleTimelineSkipWithTracking}
                isFirstStep={false}
                isLastStep={false}
              ></NavigationButtons>
              <PortraitFooter
                displayAtScreenMaxWidth={BreakpointWidthPx.Tablet}
              />
            </>
          )}
        </ScrollContent>
      </ApplicationRoot>
    </ErrorBoundary>
  )
}

export default ApplicationPage
