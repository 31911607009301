/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface GoConfig
 */
export interface GoConfig {
  /**
   *
   * @type {string}
   * @memberof GoConfig
   */
  encoding: string
  /**
   *
   * @type {string}
   * @memberof GoConfig
   */
  data: string
}

/**
 * Check if a given object implements the GoConfig interface.
 */
export function instanceOfGoConfig(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'encoding' in value
  isInstance = isInstance && 'data' in value

  return isInstance
}

export function GoConfigFromJSON(json: any): GoConfig {
  return GoConfigFromJSONTyped(json, false)
}

export function GoConfigFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GoConfig {
  if (json === undefined || json === null) {
    return json
  }
  return {
    encoding: json['encoding'],
    data: json['data'],
  }
}

export function GoConfigToJSON(value?: GoConfig | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    encoding: value.encoding,
    data: value.data,
  }
}
