// A wrapper component that feeds the inner view component with Factory Models
// specific data and event handlers/callbacks.
//
// Not in use since SC1M-1983, but let's keep it around for now, it could return
// later when real users in various markets have had a change to give feedback.

import styled from 'styled-components'
import {
  SummaryView,
  SummaryViewGroup,
  SummaryViewItem,
} from '../Summary/SummaryView'
import { useCallback, useEffect, useState } from 'react'
import { EditTruckButton } from './EditTruckButton'
import {
  FactoryModelFlexImageParams,
  FlexImageParameters,
} from '../../api/generated'
import { useClient } from '../../utils/useClient'
import useTexts from '../../utils/useTexts'
import { EtelVariant } from '../../utils/EtelVariant'
import { buildRelativeFactoryModelsBuildModeLink } from '../../utils/UrlBuilders'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { EtelMarketAndLanguage } from '../../api/startup'
import { getUrlParametersToString } from '../../utils/getUrlParametersToString'
import {
  getFactoryModelsState,
  getMarketLanguageState,
} from '../../store/appSlice'
import {
  getSessionInitDataState,
  hideInitialLoadingScreen,
} from '../../store/sessionDataSlice'

const FactoryModelSummaryRoot = styled.div`
  background-color: ${({ theme }) => theme.scWhite};
  overflow-y: auto;

  // iOS scroll tweaks: BEGIN
  // Without this, iOS will make this div as large as its contents and as a
  // result will not be scrollable.
  position: absolute;
  top: var(--header-height);
  height: calc(var(--app-height) - var(--header-height));
  // iOS scroll tweaks: END

  // Some more tweaks that's needed as a result of the iOS scroll tweaks.
  width: 100%;
  justify-self: center;
`

function getBuildModeLinkOrNull(
  modelId: number,
  marketLanguage: EtelMarketAndLanguage,
): string | null {
  // TODO: Fix this if we add back the summary page for Firm Models.
  const variants: EtelVariant[] = []

  const relativeUrl = buildRelativeFactoryModelsBuildModeLink(
    modelId,
    variants,
    marketLanguage,
  )
  const params = relativeUrl.params
  return marketLanguage
    ? relativeUrl.path + getUrlParametersToString(params)
    : null
}

export function FactoryModelSummaryPage(): JSX.Element {
  const dispatch = useAppDispatch()
  const params = useParams()
  const marketLanguage = useAppSelector(getMarketLanguageState)
  const [modelId] = useState(Number(params.modelId))
  const [summary, setSummary] = useState<SummaryViewGroup[] | null>(null)
  const factoryModelsState = useAppSelector(getFactoryModelsState)
  const [modelName, setModelName] = useState<string>('')
  const [buildModeLink, setBuildModeLink] = useState<string | null>(null)
  const client = useClient()
  const t = useTexts()
  const sessionInitData = useAppSelector(getSessionInitDataState)

  useEffect(() => {
    if (!sessionInitData?.sessionId) {
      return // Not initialized yet.
    }

    // TODO: Revisit later, use a single call in App.tsx.
    dispatch(hideInitialLoadingScreen())
  }, [dispatch, sessionInitData])

  const buildImage = useCallback(
    async (flexParams: FlexImageParameters): Promise<string | null> => {
      if (!client) {
        return null
      }
      if (!marketLanguage) {
        return null
      }
      const p: FactoryModelFlexImageParams = {
        etelMarket: marketLanguage.market,
        factoryModelId: modelId,
        params: flexParams,
      }
      const result = await client.getFactoryModelFlexImage(p)
      return result.imageUrl || null
    },
    [modelId, marketLanguage, client],
  )

  useEffect(() => {
    if (marketLanguage === null) {
      return
    }
    if (factoryModelsState.factoryModels === null) {
      // Waiting for startup.
      return
    }
    if (!client) {
      return // Not initialized yet.
    }
    const asyncWrapper = async () => {
      const modelSummary = await client.getFactoryModelSummary(
        modelId,
        marketLanguage.market,
        marketLanguage.language,
      )
      if (!modelSummary?.groups) {
        return
      }
      const viewData = modelSummary.groups.flatMap((g) => {
        const items: SummaryViewItem[] = (g.items || []).flatMap((i) => {
          if (!i.etelVariantId) {
            return []
          }
          const item: SummaryViewItem = {
            itemId: i.etelVariantId,
            groupShortText: i.submenuShortText || '',
            itemShortText: i.etelVariantShortText || '',
            itemIconUrl: i.etelVariantIconUrl || undefined,
          }
          return [item]
        })
        if (g.id === undefined || g.id === null) {
          return []
        }
        const group: SummaryViewGroup = {
          id: g.id,
          name: g.name || '',
          items,
        }
        return [group]
      })
      setSummary(viewData)
    }
    asyncWrapper()
  }, [client, modelId, marketLanguage, factoryModelsState])

  useEffect(() => {
    if (!marketLanguage) {
      return
    }
    setBuildModeLink(getBuildModeLinkOrNull(modelId, marketLanguage))
  }, [modelId, marketLanguage])

  useEffect(() => {
    if (!marketLanguage) {
      return
    }
    if (modelId === null) {
      return
    }
    const models = factoryModelsState.factoryModels?.response?.models
    if (!models) {
      return
    }
    const model = models.find((model) => model.id === modelId)
    if (!model) {
      return
    }
    let modelName = model.name?.replaceAll('_', ' ')
    if (model.overrides?.nameOverrides) {
      Object.entries(model.overrides.nameOverrides).forEach(([key, value]) => {
        if (key === marketLanguage.language.toString() && value.length !== 0) {
          modelName = value
        }
      })
    }
    setModelName(modelName || '')
  }, [modelId, factoryModelsState, marketLanguage])

  return (
    <FactoryModelSummaryRoot>
      <SummaryView
        buildImage={buildImage}
        truckName={modelName}
        groups={summary || []}
        goToNextButton={
          <EditTruckButton
            link={buildModeLink || ''}
            mainText={''}
            smallText={t('FM_CONTINUE_BUTTON_SMALL_TEXT')}
          />
        }
      />
    </FactoryModelSummaryRoot>
  )
}
