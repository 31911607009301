/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { FactoryModelFlexImageParamsParams } from './FactoryModelFlexImageParamsParams'
import {
  FactoryModelFlexImageParamsParamsFromJSON,
  FactoryModelFlexImageParamsParamsFromJSONTyped,
  FactoryModelFlexImageParamsParamsToJSON,
} from './FactoryModelFlexImageParamsParams'

/**
 *
 * @export
 * @interface GetFactoryModelFlexImageRequest
 */
export interface GetFactoryModelFlexImageRequest {
  /**
   *
   * @type {number}
   * @memberof GetFactoryModelFlexImageRequest
   */
  etelMarket?: number
  /**
   *
   * @type {number}
   * @memberof GetFactoryModelFlexImageRequest
   */
  factoryModelId?: number
  /**
   *
   * @type {FactoryModelFlexImageParamsParams}
   * @memberof GetFactoryModelFlexImageRequest
   */
  params?: FactoryModelFlexImageParamsParams | null
}

/**
 * Check if a given object implements the GetFactoryModelFlexImageRequest interface.
 */
export function instanceOfGetFactoryModelFlexImageRequest(
  value: object,
): boolean {
  let isInstance = true

  return isInstance
}

export function GetFactoryModelFlexImageRequestFromJSON(
  json: any,
): GetFactoryModelFlexImageRequest {
  return GetFactoryModelFlexImageRequestFromJSONTyped(json, false)
}

export function GetFactoryModelFlexImageRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GetFactoryModelFlexImageRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    etelMarket: !exists(json, 'EtelMarket') ? undefined : json['EtelMarket'],
    factoryModelId: !exists(json, 'FactoryModelId')
      ? undefined
      : json['FactoryModelId'],
    params: !exists(json, 'Params')
      ? undefined
      : FactoryModelFlexImageParamsParamsFromJSON(json['Params']),
  }
}

export function GetFactoryModelFlexImageRequestToJSON(
  value?: GetFactoryModelFlexImageRequest | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    EtelMarket: value.etelMarket,
    FactoryModelId: value.factoryModelId,
    Params: FactoryModelFlexImageParamsParamsToJSON(value.params),
  }
}
