import { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import {
  ScaniaAdobeEventId,
  pushAdobeEvent,
  ScaniaAdobeTrackingRfqFormSubmitEvent,
} from '../../utils/adobeAnalytics'
import { useClient } from '../../utils/useClient'
import useTexts from '../../utils/useTexts'
import { SvgLoading } from '../SvgImports'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { closeAllModals, getModalState } from '../../store/modalSlice'
import {
  getExtendedCheckStatus,
  setExtendedCheckState,
} from '../../store/sessionDataSlice'
import { TdsButton, TdsMessage, TdsModal } from '@scania/tegel-react'
import { ExtendedCheckStatus } from '../../store/types'

function sendTrackingEvent({ success }: { success: boolean }) {
  if (!success) {
    // The new tracking event doesn't carry a success flag so let's not send
    // it at all for failures.
    return
  }
  const ev: ScaniaAdobeTrackingRfqFormSubmitEvent = {
    event: ScaniaAdobeEventId.RfqFormSubmit,
  }
  pushAdobeEvent(ev)
}

const MopinionContainer = styled.div`
  & > *:first-child {
    border-top: solid thin var(--tds-grey-300);
    margin-top: 16px;
  }
`

enum RfqPhase {
  SENDING,
  SUCCESS,
  FAILURE,
}

interface ModalRfqSenderProps {
  handleSuccess: () => void
}

export function ModalRfqSender({
  handleSuccess,
}: ModalRfqSenderProps): JSX.Element {
  const t = useTexts()
  const client = useClient()
  const dispatch = useAppDispatch()
  const isSending = useRef<boolean>(false)
  const extendedCheckState = useAppSelector(getExtendedCheckStatus)
  const extendedCheckSuccess =
    extendedCheckState === ExtendedCheckStatus.Success
  const rfqToSend = useAppSelector(getModalState).modalRfqSendingState
  const [headerText, setHeaderText] = useState<string>('')
  const [firstParagraph, setFirstParagraph] = useState<string>('')
  const [secondParagraph, setSecondParagraph] = useState<string | null>(null)
  const [phase, setPhase] = useState<RfqPhase>(RfqPhase.SENDING)

  // Send the RFQ request.
  useEffect(() => {
    const asyncWrapper = async () => {
      if (!client) {
        return
      }
      if (isSending.current === true) {
        // Should not happen, but it doesn't hurt to have an extra safeguard.
        return
      }
      if (!rfqToSend) {
        throw new Error('Expected rfq params.')
      }
      isSending.current = true
      try {
        await client.sendRfq(rfqToSend)
        setPhase(RfqPhase.SUCCESS)
        sendTrackingEvent({ success: true })
        handleSuccess()
      } catch {
        // If this fails, we don't knows what state the application is in any
        // more and we should restart from the landing page, but the old GUI
        // seems to be using more of a warning or excuse and lets the user try
        // to continue using the app. TODO: Consider using handleFatalError
        // instead.
        setPhase(RfqPhase.FAILURE)
        sendTrackingEvent({ success: false })
      } finally {
        isSending.current = false
      }
    }
    asyncWrapper()
  }, [client, handleSuccess, rfqToSend])

  // React to RfqPhase change.
  useEffect(() => {
    switch (phase) {
      case RfqPhase.SENDING:
        setHeaderText(t('HEADER_WAIT'))
        setFirstParagraph('')
        setSecondParagraph(null)
        break
      case RfqPhase.SUCCESS:
        setHeaderText(t('HEADER_REQUEST_SENT'))
        setFirstParagraph(t('DESCRIPTION_REQUEST_SENT'))
        setSecondParagraph(
          extendedCheckSuccess ? null : t('ERROR_CUSTOMER_EXTENDED_CHECK'),
        )
        break
      case RfqPhase.FAILURE:
        setHeaderText(t('HEADER_ERROR_NOTIFICATION'))
        setFirstParagraph(t('ERROR_SERVER_COMMUNICATION_BREAKDOWN'))
        setSecondParagraph(null)
        break
      default:
        break
    }
  }, [extendedCheckSuccess, phase, t])

  const handleCloseClick = useCallback(() => {
    dispatch(setExtendedCheckState(ExtendedCheckStatus.None))
    dispatch(closeAllModals())
  }, [dispatch])

  // Eventlistener to close modal on tdsClose (click outside modal and click on close cross)
  useEffect(() => {
    document.addEventListener('tdsClose', handleCloseClick)
    return () => {
      document.removeEventListener('tdsClose', handleCloseClick)
    }
  }, [handleCloseClick])

  return (
    <TdsModal
      size="md"
      header={headerText}
      show
      prevent={phase === RfqPhase.SENDING}
    >
      <span slot="body">
        {phase === RfqPhase.SENDING && (
          <div>
            <SvgLoading
              color="var(--tds-blue-300)"
              width={'64px'}
              height={'64px'}
            />
          </div>
        )}
        {phase === RfqPhase.SUCCESS && (
          <div style={{ paddingRight: 0, paddingBottom: 0 }}>
            <TdsMessage variant="success" header={firstParagraph}>
              {secondParagraph}
            </TdsMessage>
            <MopinionContainer id={'mopinion-rfq-form'}></MopinionContainer>
          </div>
        )}
        {phase === RfqPhase.FAILURE && (
          <div style={{ paddingRight: 0, paddingBottom: 0 }}>
            <TdsMessage variant="error" header={firstParagraph}>
              {secondParagraph}
            </TdsMessage>
            <MopinionContainer id="mopinion-rfq-form"></MopinionContainer>
          </div>
        )}
      </span>
      <span slot="actions" className="tds-u-flex tds-u-gap2 tds-u-flex-wrap">
        <TdsButton
          size="md"
          text={t('LABEL_ACTION_CLOSE')}
          onClick={handleCloseClick}
          disabled={phase === RfqPhase.SENDING}
        />
      </span>
    </TdsModal>
  )
}
