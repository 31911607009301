/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ConfigInfoImage } from './ConfigInfoImage'
import {
  ConfigInfoImageFromJSON,
  ConfigInfoImageFromJSONTyped,
  ConfigInfoImageToJSON,
} from './ConfigInfoImage'

/**
 *
 * @export
 * @interface PublicConfigV2InfoResponseWrapperPublicConfigV2Info
 */
export interface PublicConfigV2InfoResponseWrapperPublicConfigV2Info {
  /**
   *
   * @type {string}
   * @memberof PublicConfigV2InfoResponseWrapperPublicConfigV2Info
   */
  guid: string
  /**
   *
   * @type {string}
   * @memberof PublicConfigV2InfoResponseWrapperPublicConfigV2Info
   */
  description: string
  /**
   *
   * @type {Array<ConfigInfoImage>}
   * @memberof PublicConfigV2InfoResponseWrapperPublicConfigV2Info
   */
  images: Array<ConfigInfoImage>
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof PublicConfigV2InfoResponseWrapperPublicConfigV2Info
   */
  configStorageAux?: { [key: string]: string } | null
}

/**
 * Check if a given object implements the PublicConfigV2InfoResponseWrapperPublicConfigV2Info interface.
 */
export function instanceOfPublicConfigV2InfoResponseWrapperPublicConfigV2Info(
  value: object,
): boolean {
  let isInstance = true
  isInstance = isInstance && 'guid' in value
  isInstance = isInstance && 'description' in value
  isInstance = isInstance && 'images' in value

  return isInstance
}

export function PublicConfigV2InfoResponseWrapperPublicConfigV2InfoFromJSON(
  json: any,
): PublicConfigV2InfoResponseWrapperPublicConfigV2Info {
  return PublicConfigV2InfoResponseWrapperPublicConfigV2InfoFromJSONTyped(
    json,
    false,
  )
}

export function PublicConfigV2InfoResponseWrapperPublicConfigV2InfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PublicConfigV2InfoResponseWrapperPublicConfigV2Info {
  if (json === undefined || json === null) {
    return json
  }
  return {
    guid: json['Guid'],
    description: json['Description'],
    images: (json['Images'] as Array<any>).map(ConfigInfoImageFromJSON),
    configStorageAux: !exists(json, 'ConfigStorageAux')
      ? undefined
      : json['ConfigStorageAux'],
  }
}

export function PublicConfigV2InfoResponseWrapperPublicConfigV2InfoToJSON(
  value?: PublicConfigV2InfoResponseWrapperPublicConfigV2Info | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    Guid: value.guid,
    Description: value.description,
    Images: (value.images as Array<any>).map(ConfigInfoImageToJSON),
    ConfigStorageAux: value.configStorageAux,
  }
}
