/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { LanguageInfo } from './LanguageInfo'
import {
  LanguageInfoFromJSON,
  LanguageInfoFromJSONTyped,
  LanguageInfoToJSON,
} from './LanguageInfo'
import type { MopinionSettings } from './MopinionSettings'
import {
  MopinionSettingsFromJSON,
  MopinionSettingsFromJSONTyped,
  MopinionSettingsToJSON,
} from './MopinionSettings'

/**
 * Called GUI market settings to differentiate it from the
 * MarketSettings type in Scania Admin service. This is a subset of
 * the upstream object. On this object only information that's
 * relevant to the GUI is present.
 * @export
 * @interface SessionInitDataMarketSettings
 */
export interface SessionInitDataMarketSettings {
  /**
   *
   * @type {boolean}
   * @memberof SessionInitDataMarketSettings
   */
  bevCampaignEnabled: boolean
  /**
   *
   * @type {boolean}
   * @memberof SessionInitDataMarketSettings
   */
  bodyBuildsEnabled: boolean
  /**
   *
   * @type {boolean}
   * @memberof SessionInitDataMarketSettings
   */
  bodyBuildsShowDefault: boolean
  /**
   *
   * @type {Array<LanguageInfo>}
   * @memberof SessionInitDataMarketSettings
   */
  languages: Array<LanguageInfo>
  /**
   *
   * @type {number}
   * @memberof SessionInitDataMarketSettings
   */
  etelMarket: number
  /**
   *
   * @type {number}
   * @memberof SessionInitDataMarketSettings
   */
  etelViewIdBuildMode: number
  /**
   *
   * @type {number}
   * @memberof SessionInitDataMarketSettings
   */
  etelViewIdGuidedOffering: number
  /**
   *
   * @type {boolean}
   * @memberof SessionInitDataMarketSettings
   */
  extraQuestionsEnabled: boolean
  /**
   *
   * @type {number}
   * @memberof SessionInitDataMarketSettings
   */
  factoryModelsMode: number
  /**
   *
   * @type {boolean}
   * @memberof SessionInitDataMarketSettings
   */
  garageEnabled: boolean
  /**
   *
   * @type {boolean}
   * @memberof SessionInitDataMarketSettings
   */
  linkUpgradeFleetEnabled: boolean
  /**
   *
   * @type {string}
   * @memberof SessionInitDataMarketSettings
   */
  marketFullName: string
  /**
   *
   * @type {string}
   * @memberof SessionInitDataMarketSettings
   */
  marketIso: string
  /**
   *
   * @type {Array<MopinionSettings>}
   * @memberof SessionInitDataMarketSettings
   */
  mopinionSettings: Array<MopinionSettings>
  /**
   *
   * @type {boolean}
   * @memberof SessionInitDataMarketSettings
   */
  newsBannerEnabled: boolean
  /**
   *
   * @type {boolean}
   * @memberof SessionInitDataMarketSettings
   */
  prepareFm: boolean
  /**
   *
   * @type {boolean}
   * @memberof SessionInitDataMarketSettings
   */
  prepareGo: boolean
  /**
   * If true then the client is allowed to send an RFQ request without a
   * dealer id.
   * @type {boolean}
   * @memberof SessionInitDataMarketSettings
   */
  rfqRequiresDealerId: boolean
  /**
   * Based on multiple flags in the upstream data from SC Admin
   * service.
   * @type {boolean}
   * @memberof SessionInitDataMarketSettings
   */
  rfqEnabled: boolean
  /**
   *
   * @type {boolean}
   * @memberof SessionInitDataMarketSettings
   */
  shareEmailEnabled: boolean
  /**
   *
   * @type {boolean}
   * @memberof SessionInitDataMarketSettings
   */
  shareFacebookEnabled: boolean
  /**
   *
   * @type {boolean}
   * @memberof SessionInitDataMarketSettings
   */
  shareLinkEnabled: boolean
  /**
   *
   * @type {boolean}
   * @memberof SessionInitDataMarketSettings
   */
  shareWhatsappEnabled: boolean
  /**
   * This probably means dealer locator enabled?
   * Sales Information System?
   * @type {boolean}
   * @memberof SessionInitDataMarketSettings
   */
  sisEnabled: boolean
  /**
   *
   * @type {boolean}
   * @memberof SessionInitDataMarketSettings
   */
  smartDashCampaignEnabled: boolean
  /**
   *
   * @type {boolean}
   * @memberof SessionInitDataMarketSettings
   */
  superCampaignEnabled: boolean
  /**
   *
   * @type {boolean}
   * @memberof SessionInitDataMarketSettings
   */
  useCampaignVideo: boolean
}

/**
 * Check if a given object implements the SessionInitDataMarketSettings interface.
 */
export function instanceOfSessionInitDataMarketSettings(
  value: object,
): boolean {
  let isInstance = true
  isInstance = isInstance && 'bevCampaignEnabled' in value
  isInstance = isInstance && 'bodyBuildsEnabled' in value
  isInstance = isInstance && 'bodyBuildsShowDefault' in value
  isInstance = isInstance && 'languages' in value
  isInstance = isInstance && 'etelMarket' in value
  isInstance = isInstance && 'etelViewIdBuildMode' in value
  isInstance = isInstance && 'etelViewIdGuidedOffering' in value
  isInstance = isInstance && 'extraQuestionsEnabled' in value
  isInstance = isInstance && 'factoryModelsMode' in value
  isInstance = isInstance && 'garageEnabled' in value
  isInstance = isInstance && 'linkUpgradeFleetEnabled' in value
  isInstance = isInstance && 'marketFullName' in value
  isInstance = isInstance && 'marketIso' in value
  isInstance = isInstance && 'mopinionSettings' in value
  isInstance = isInstance && 'newsBannerEnabled' in value
  isInstance = isInstance && 'prepareFm' in value
  isInstance = isInstance && 'prepareGo' in value
  isInstance = isInstance && 'rfqRequiresDealerId' in value
  isInstance = isInstance && 'rfqEnabled' in value
  isInstance = isInstance && 'shareEmailEnabled' in value
  isInstance = isInstance && 'shareFacebookEnabled' in value
  isInstance = isInstance && 'shareLinkEnabled' in value
  isInstance = isInstance && 'shareWhatsappEnabled' in value
  isInstance = isInstance && 'sisEnabled' in value
  isInstance = isInstance && 'smartDashCampaignEnabled' in value
  isInstance = isInstance && 'superCampaignEnabled' in value
  isInstance = isInstance && 'useCampaignVideo' in value

  return isInstance
}

export function SessionInitDataMarketSettingsFromJSON(
  json: any,
): SessionInitDataMarketSettings {
  return SessionInitDataMarketSettingsFromJSONTyped(json, false)
}

export function SessionInitDataMarketSettingsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SessionInitDataMarketSettings {
  if (json === undefined || json === null) {
    return json
  }
  return {
    bevCampaignEnabled: json['bev_campaign_enabled'],
    bodyBuildsEnabled: json['body_builds_enabled'],
    bodyBuildsShowDefault: json['body_builds_show_default'],
    languages: (json['languages'] as Array<any>).map(LanguageInfoFromJSON),
    etelMarket: json['etel_market'],
    etelViewIdBuildMode: json['etel_view_id_build_mode'],
    etelViewIdGuidedOffering: json['etel_view_id_guided_offering'],
    extraQuestionsEnabled: json['extraQuestionsEnabled'],
    factoryModelsMode: json['factory_models_mode'],
    garageEnabled: json['garageEnabled'],
    linkUpgradeFleetEnabled: json['linkUpgradeFleetEnabled'],
    marketFullName: json['market_full_name'],
    marketIso: json['market_iso'],
    mopinionSettings: (json['mopinionSettings'] as Array<any>).map(
      MopinionSettingsFromJSON,
    ),
    newsBannerEnabled: json['newsBannerEnabled'],
    prepareFm: json['prepare_fm'],
    prepareGo: json['prepare_go'],
    rfqRequiresDealerId: json['rfq_requires_dealer_id'],
    rfqEnabled: json['rfq_enabled'],
    shareEmailEnabled: json['share_email_enabled'],
    shareFacebookEnabled: json['share_facebook_enabled'],
    shareLinkEnabled: json['share_link_enabled'],
    shareWhatsappEnabled: json['share_whatsapp_enabled'],
    sisEnabled: json['sis_enabled'],
    smartDashCampaignEnabled: json['smart_dash_campaign_enabled'],
    superCampaignEnabled: json['super_campaign_enabled'],
    useCampaignVideo: json['use_campaign_video'],
  }
}

export function SessionInitDataMarketSettingsToJSON(
  value?: SessionInitDataMarketSettings | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    bev_campaign_enabled: value.bevCampaignEnabled,
    body_builds_enabled: value.bodyBuildsEnabled,
    body_builds_show_default: value.bodyBuildsShowDefault,
    languages: (value.languages as Array<any>).map(LanguageInfoToJSON),
    etel_market: value.etelMarket,
    etel_view_id_build_mode: value.etelViewIdBuildMode,
    etel_view_id_guided_offering: value.etelViewIdGuidedOffering,
    extraQuestionsEnabled: value.extraQuestionsEnabled,
    factory_models_mode: value.factoryModelsMode,
    garageEnabled: value.garageEnabled,
    linkUpgradeFleetEnabled: value.linkUpgradeFleetEnabled,
    market_full_name: value.marketFullName,
    market_iso: value.marketIso,
    mopinionSettings: (value.mopinionSettings as Array<any>).map(
      MopinionSettingsToJSON,
    ),
    newsBannerEnabled: value.newsBannerEnabled,
    prepare_fm: value.prepareFm,
    prepare_go: value.prepareGo,
    rfq_requires_dealer_id: value.rfqRequiresDealerId,
    rfq_enabled: value.rfqEnabled,
    share_email_enabled: value.shareEmailEnabled,
    share_facebook_enabled: value.shareFacebookEnabled,
    share_link_enabled: value.shareLinkEnabled,
    share_whatsapp_enabled: value.shareWhatsappEnabled,
    sis_enabled: value.sisEnabled,
    smart_dash_campaign_enabled: value.smartDashCampaignEnabled,
    super_campaign_enabled: value.superCampaignEnabled,
    use_campaign_video: value.useCampaignVideo,
  }
}
