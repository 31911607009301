import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { SESSION_FAILURE } from '../../api/errors'
import {
  ScaniaAdobeTrackingDownloadQrEvent,
  pushAdobeEvent,
  ScaniaAdobeEventId,
  ScaniaAdobeDownloadType,
} from '../../utils/adobeAnalytics'
import { useClient } from '../../utils/useClient'
import useTexts from '../../utils/useTexts'
import { SvgLoading } from '../SvgImports'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { TestElementTypeId } from '../../types/TestAttributeId'
import {
  getMarketDenomination,
  getSessionInitDataState,
  getStartupData,
} from '../../store/sessionDataSlice'
import { getMarketLanguageState } from '../../store/appSlice'
import { closeAllModals, getModalState } from '../../store/modalSlice'
import { TdsButton, TdsModal, TdsRadioButton } from '@scania/tegel-react'

interface UrlLoadingProps {
  $linksLoaded: boolean
}

const TextBox = styled.div<UrlLoadingProps>`
  display: ${(props) => (props.$linksLoaded ? '' : 'none')};
  height: 40px;
`

const RadioButtonWrapper = styled.fieldset<UrlLoadingProps>`
  display: ${(props) => (props.$linksLoaded ? '' : 'none')};
  border: 0;
  margin: 0;
  min-width: 0;
  padding: 0;
`

const SpinnerWrapper = styled.div<UrlLoadingProps>`
  display: ${(props) => (props.$linksLoaded ? 'none' : '')};
  margin-left: 32px;
  height: calc(40px + 48px + 160px);

  svg {
    color: var(--tds-blue-600);
  }
`

const QrStreamWrapper = styled.div<UrlLoadingProps>`
  display: ${(props) => (props.$linksLoaded ? '' : 'none')};
  height: 164px;

  img {
    height: 160px;
  }
`

interface ModalSaveQrProps {
  handleSessionFailure: () => void
}

export function ModalSaveQr(props: ModalSaveQrProps): JSX.Element {
  const t = useTexts()
  const FORMAT_PNG = 'Png'
  const FORMAT_SVG = 'Svg'
  const startupData = useAppSelector(getStartupData)
  const client = useClient()
  const dispatch = useAppDispatch()
  const sessionInitData = useAppSelector(getSessionInitDataState)
  const sessionId = sessionInitData?.sessionId || ''
  const marketLanguage = useAppSelector(getMarketLanguageState)
  const marketDenomination = useAppSelector(getMarketDenomination)
  const source = useAppSelector(getModalState).modalSaveQrState?.source
  const [qrPngDownloadUrl, setQrPngDownloadUrl] = useState<string | null>(null)
  const [qrSvgDownloadUrl, setQrSvgDownloadUrl] = useState<string | null>(null)
  const [qrPngStreamUrl, setQrPngStreamUrl] = useState<string | null>(null)
  const [choosenFormat, setChoosenFormat] = useState<string>(FORMAT_PNG)

  // Build urls for Qr download
  useEffect(() => {
    async function buildQrUrl() {
      if (!marketDenomination || !client) {
        return
      }
      if (!startupData || !marketLanguage) {
        return
      }
      const configAux = {}
      const response = await client.publicConfigV2Save(
        sessionId,
        marketDenomination,
        configAux,
      )
      if (response.error === SESSION_FAILURE) {
        props.handleSessionFailure()
        return
      }
      if (!response.publicConfigV2Save) {
        throw new Error('Expected response.publicConfigV2Save to be defined.')
      }
      const config = response.publicConfigV2Save
      const pubCfgInfo = config.guid

      const baseQuery =
        '?etel_market=' +
        marketLanguage.market +
        '&etel_language=' +
        marketLanguage.language
      const baseUrl = startupData.backendUrl + '/public_config/QR-' + pubCfgInfo
      const pngStreamUrl = baseUrl + '.png' + baseQuery
      const pngDownloadUrl = pngStreamUrl + '&download=true'
      const svgDownloadUrl = baseUrl + '.svg' + baseQuery + '&download=true'

      return { pngStreamUrl, pngDownloadUrl, svgDownloadUrl }
    }

    buildQrUrl().then((result) => {
      setQrPngDownloadUrl(result?.pngDownloadUrl || null)
      setQrPngStreamUrl(result?.pngStreamUrl || null)
      setQrSvgDownloadUrl(result?.svgDownloadUrl || null)
    })
  }, [
    props,
    client,
    marketLanguage,
    sessionId,
    startupData,
    marketDenomination,
  ])

  const handleFormatClick = (format: string) => {
    setChoosenFormat(format)
  }

  const handleQrTracking = useCallback(() => {
    if (!source) {
      throw new Error('Expected source.')
    }
    const url =
      choosenFormat === FORMAT_PNG ? qrPngDownloadUrl : qrSvgDownloadUrl
    const trackingEvent: ScaniaAdobeTrackingDownloadQrEvent = {
      event: ScaniaAdobeEventId.Download,
      eventInfo: {
        downloadType: ScaniaAdobeDownloadType.Qr,
        filePath: url || '',
        placement: source,
      },
    }
    pushAdobeEvent(trackingEvent)
    dispatch(closeAllModals())
  }, [choosenFormat, qrPngDownloadUrl, qrSvgDownloadUrl, dispatch, source])

  const handleCloseClick = useCallback(() => {
    dispatch(closeAllModals())
  }, [dispatch])

  // Eventlistener to close modal on tdsClose (click outside modal and click on close cross)
  useEffect(() => {
    document.addEventListener('tdsClose', handleCloseClick)
    return () => {
      document.removeEventListener('tdsClose', handleCloseClick)
    }
  }, [handleCloseClick])

  return (
    <TdsModal size="md" header={t('HEADER_DOWNLOAD_QR')} show>
      <span slot="body">
        <TextBox
          $linksLoaded={qrPngDownloadUrl && qrSvgDownloadUrl ? true : false}
        >
          {t('LABEL_DOWNLOAD_QR')}
        </TextBox>
        <RadioButtonWrapper
          $linksLoaded={qrPngDownloadUrl && qrSvgDownloadUrl ? true : false}
        >
          <TdsRadioButton
            name="qr-radio-button"
            value="png"
            data-test-element-type={TestElementTypeId.ModalSaveQrPngRadioButton}
            onTdsChange={() => handleFormatClick(FORMAT_PNG)}
            checked={true}
          >
            <span slot="label">Png</span>
          </TdsRadioButton>
          <TdsRadioButton
            name="qr-radio-button"
            value="svg"
            data-test-element-type={TestElementTypeId.ModalSaveQrSvgRadioButton}
            onTdsChange={() => handleFormatClick(FORMAT_SVG)}
          >
            <span slot="label">Svg</span>
          </TdsRadioButton>
        </RadioButtonWrapper>
        <QrStreamWrapper $linksLoaded={qrPngStreamUrl ? true : false}>
          <img src={qrPngStreamUrl || ''} alt="qr-code"></img>
        </QrStreamWrapper>
        <SpinnerWrapper
          $linksLoaded={qrPngDownloadUrl && qrSvgDownloadUrl ? true : false}
        >
          <SvgLoading />
        </SpinnerWrapper>
      </span>

      <span slot="actions" className="tds-u-flex tds-u-gap2 tds-u-flex-wrap">
        <a
          href={
            qrPngDownloadUrl && qrSvgDownloadUrl && choosenFormat === FORMAT_PNG
              ? qrPngDownloadUrl
              : qrSvgDownloadUrl || ''
          }
          target={'_blank'}
          rel="noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <TdsButton
            variant="primary"
            size="md"
            text={t('ACTION_LABEL_DOWNLOAD')}
            onClick={handleQrTracking}
            disabled={qrPngDownloadUrl && qrSvgDownloadUrl ? false : true}
            data-test-element-type={TestElementTypeId.ModalSaveQrDownloadButton}
          />
        </a>
        <TdsButton
          variant="secondary"
          size="md"
          text={t('LABEL_ACTION_CLOSE')}
          onClick={handleCloseClick}
          data-test-element-type={TestElementTypeId.ModalCloseButton}
        />
      </span>
    </TdsModal>
  )
}
