import { useCallback, useEffect, useState } from 'react'
import { SendChangesToEysParams } from '../../api/generated'
import {
  ScaniaAdobeTrackingClickEysEvent,
  pushAdobeEvent,
  ScaniaAdobeEventId,
} from '../../utils/adobeAnalytics'
import { useClient } from '../../utils/useClient'
import useTexts from '../../utils/useTexts'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { getSessionIdState } from '../../store/sessionDataSlice'
import { getMarketLanguageState } from '../../store/appSlice'
import { closeAllModals } from '../../store/modalSlice'
import { getTruckInfoState } from '../../store/truckInfoSlice'
import { TdsButton, TdsModal } from '@scania/tegel-react'

function sendTrackingEvent({ success }: { success: boolean }) {
  if (!success) {
    // The new tracking event has no success property, which means we should
    // probably not sent this event for failures.
    return
  }
  const trackingEvent: ScaniaAdobeTrackingClickEysEvent = {
    event: ScaniaAdobeEventId.EysClick,
  }
  pushAdobeEvent(trackingEvent)
}

interface ModalSendChangesToEysProps {
  handleSuccess: () => void
}

enum SendStatus {
  SENDING,
  SUCCESS,
  FAILURE,
}

export function ModalSendChangesToEys({
  handleSuccess,
}: ModalSendChangesToEysProps): JSX.Element {
  const t = useTexts()
  const client = useClient()
  const dispatch = useAppDispatch()
  const sessionId = useAppSelector(getSessionIdState)
  const marketLanguage = useAppSelector(getMarketLanguageState)
  const publicConfigId = useAppSelector(getTruckInfoState)?.publicConfigId
  const [status, setStatus] = useState<SendStatus>(SendStatus.SENDING)
  const [title, setTitle] = useState<string>('')
  const [subHeader, setSubHeader] = useState<string>('')
  const [paragraphOne, setParagraphOne] = useState<string>('')
  const [paragraphTwo, setParagraphTwo] = useState<string>('')

  useEffect(() => {
    const asyncWrapper = async () => {
      if (!client) {
        return
      }
      if (!marketLanguage) {
        return
      }
      const p: SendChangesToEysParams = {
        sessionId,
        etelLanguage: marketLanguage.language.toString(),
        parentPublicConfigId: publicConfigId || undefined,
      }
      try {
        await client.sendChangesToEys(p)
        setStatus(SendStatus.SUCCESS)
        sendTrackingEvent({ success: true })
        handleSuccess()
      } catch {
        setStatus(SendStatus.FAILURE)
        sendTrackingEvent({ success: false })
      }
    }
    asyncWrapper()
  }, [client, marketLanguage, handleSuccess, publicConfigId, sessionId])

  useEffect(() => {
    switch (status) {
      case SendStatus.SUCCESS:
        setTitle(t('EYS_SEND_CHANGES_HEADER'))
        setSubHeader(t('EYS_SEND_CHANGES_SUBHEADER'))
        setParagraphOne(t('EYS_SEND_CHANGES_PARAGRAPH_1'))
        setParagraphTwo(t('EYS_SEND_CHANGES_PARAGRAPH_2'))
        break
      case SendStatus.FAILURE:
        setTitle(t('ERROR_TRY_LATER'))
        setSubHeader(t('ERROR_REQUEST_FAILED'))
        setParagraphOne('')
        setParagraphTwo('')
        break
      case SendStatus.SENDING:
        setTitle(t('HEADER_WAIT'))
        setSubHeader(t('DESCRIPTION_WAIT'))
        setParagraphOne('')
        setParagraphTwo('')
        break
      default:
        setTitle('')
        setSubHeader('')
        setParagraphOne('')
        setParagraphTwo('')
        break
    }
  }, [status, t])

  const handleCloseClick = useCallback(() => {
    dispatch(closeAllModals())
  }, [dispatch])

  // Eventlistener to close modal on tdsClose (click outside modal and click on close cross)
  useEffect(() => {
    document.addEventListener('tdsClose', handleCloseClick)
    return () => {
      document.removeEventListener('tdsClose', handleCloseClick)
    }
  }, [handleCloseClick])

  return (
    <TdsModal size="md" header={title} show>
      <span slot="body">
        <h4>{subHeader}</h4>
        <p>{paragraphOne}</p>
        <p>{paragraphTwo}</p>
      </span>

      <span slot="actions" className="tds-u-flex tds-u-gap2 tds-u-flex-wrap">
        <TdsButton
          variant="secondary"
          size="md"
          text={t('LABEL_ACTION_CLOSE')}
          onClick={handleCloseClick}
          disabled={status === SendStatus.SENDING}
        />
      </span>
    </TdsModal>
  )
}
