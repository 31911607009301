import { useEffect, useState } from 'react'
import { Size2d } from '../types/Size2d'

/**
 * React hook for the standard ResizeObserver.
 *
 * @param ref The element to observe.
 * @param initialValue Genetic type T for initial value, intended to allows both nullable and non-nullable initial state.
 * @returns The current size of the observed element, or the initial value.
 */
export function useResizeObserver<T>(
  ref: React.MutableRefObject<HTMLElement | null>,
  initialValue: T,
): Size2d | T {
  const [size, setSize] = useState<Size2d | T>(initialValue)

  useEffect(() => {
    if (!ref.current) {
      return
    }
    const obs = new ResizeObserver((entries) => {
      const target = entries[0].target
      const size: Size2d = {
        width: target.clientWidth,
        height: target.clientHeight,
      }
      setSize(size)
    })
    obs.observe(ref.current)
    return () => {
      obs.disconnect()
    }
  }, [ref])

  return size
}
