import styled from 'styled-components'

// TODO: Revisit, this is just moved here from Specification.tsx.
export const AccordionIconPositionHackRoot = styled.div`
  position: absolute;

  // TODO: Revisit and try to eliminate.
  z-index: 1;

  margin-top: 5px;
  display: flex;
  align-items: center;
  height: 34px;
  width: 34px;
  justify-content: center;

  svg {
    position: absolute;
    height: 24px;
    width: 24px;
  }
`

export interface AccordionIconPositionHackProps
  extends React.HtmlHTMLAttributes<HTMLDivElement> {}

/**
 * A workaround for the limitatations of tgl-accordion.
 */
export const AccordionIconPositionHack = (
  props: AccordionIconPositionHackProps,
): JSX.Element => {
  return (
    <AccordionIconPositionHackRoot>
      {props.children}
    </AccordionIconPositionHackRoot>
  )
}
