export interface SettingsDotJson {
  demoModeEnabled: boolean
  demoModeEmailAddress: string
  bevCampaignLinkUrl: string | null
  upgradeYourFleetLinkUrl: string | null
}

// TODO: Move to a more appropriate source file.
export async function fetchSettings(): Promise<SettingsDotJson> {
  const settingsResponse = await fetch('/settings.json')
  const settingsDynamic = await settingsResponse.json()
  const emailDynamic = settingsDynamic['demoModeEmailAddress']
  const demoModeEmailAddress: string =
    typeof emailDynamic === 'string' ? emailDynamic : ''
  const validEmailFormat =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i
  const demoModeEnabled: boolean = validEmailFormat.test(demoModeEmailAddress)
  let bevCampaignLinkUrl = settingsDynamic['bevCampaignLinkUrl']
  if (typeof bevCampaignLinkUrl !== 'string') {
    console.error(
      'Expected bevCampaignLinkUrl to be a string, got: ',
      bevCampaignLinkUrl,
    )
    bevCampaignLinkUrl = null
  }
  if ((bevCampaignLinkUrl as string).startsWith('#{')) {
    console.log('BEV campaign link is unset.')
    bevCampaignLinkUrl = null
  }
  let upgradeYourFleetLinkUrl = settingsDynamic['upgradeYourFleetUrl']
  return {
    bevCampaignLinkUrl,
    upgradeYourFleetLinkUrl,
    demoModeEmailAddress,
    demoModeEnabled,
  }
}
