import styled from 'styled-components'
import { SvgCross, SvgLoading } from '../SvgImports'
import { useCallback, useEffect, useRef, useState } from 'react'
import useTexts from '../../utils/useTexts'
import isEmail from '../../utils/isEmail'
import { GarageView } from './GarageView'
import { NewTruckLoadedHandler } from '../../types/TruckLoadedTypes'
import {
  LOGIN_SUBSTATUS_NO_SUCH_USER,
  LOGIN_SUBSTATUS_WRONG_PASSWORD,
  SESSION_FAILURE,
} from '../../api/errors'
import { useClient } from '../../utils/useClient'
import { CreateUserRequest, UserConfigInfo } from '../../api/generated'
import { ConsequenceOfChangeHandler } from '../../types/ConsequenceOfChangeTypes'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import {
  ScaniaAdobeEventId,
  ScaniaAdobeTrackingLoginSuccessEvent,
  ScaniaAdobeTrackingRegistrationFormOpen,
  ScaniaAdobeTrackingRegistrationFormSubmit,
  pushAdobeEvent,
} from '../../utils/adobeAnalytics'
import { TestElementTypeId } from '../../types/TestAttributeId'
import { BreakpointWidthPx } from '../../css/BreakpointWidthPx'
import {
  closePanelLoginGarage,
  selectSidePanelState,
} from '../../store/sidePanelSlice'
import { getMarketLanguageState } from '../../store/appSlice'
import {
  getIsLoggedInState,
  getSessionInitDataState,
  loggedInStatus,
  setGarageList,
} from '../../store/sessionDataSlice'
import {
  TdsAccordion,
  TdsAccordionItem,
  TdsButton,
  TdsCheckbox,
  TdsTextField,
} from '@scania/tegel-react'
import { TdsCheckboxCustomEvent } from '@scania/tegel'

// This sidepanel has 5 different views:
// - Login page (currentForm = login)
// - Register new account view (currentForm = register)
// - Forgotten password view (currentForm = forgottenPassword)
// - Confirm view for success or error from views above (currentForm = confirmView)
// - Garage and my account view (currentForm = garage)

interface LoginRootProps {
  $sidePanelIsOpen: boolean
}

const LoginRoot = styled.div<LoginRootProps>`
  height: calc(100% - var(--header-height));
  width: var(--side-panel-width);
  background-color: var(--tds-white);
  position: absolute;

  // TODO: Revisit and try to eliminate.
  z-index: 10;

  top: var(--header-height);
  border: 1px solid var(--tds-grey-300);
  overflow-y: scroll;
  padding-bottom: 32px;

  --sidepanel-transition-duration: 250ms;
  right: ${(props) =>
    props.$sidePanelIsOpen ? '0px' : 'calc(0px - var(--side-panel-width))'};
  visibility: ${(props) => (props.$sidePanelIsOpen ? 'visible' : 'hidden')};
  transition-duration: var(--sidepanel-transition-duration);
  transition-property: right, visibility;

  @media screen and (max-width: ${BreakpointWidthPx.Tablet}px) {
    height: 100%;
    top: 0;
    max-width: 100vw;
  }
`

interface ContentWrapperProps {
  $currentView: boolean
}

const ContentWrapper = styled.div<ContentWrapperProps>`
  display: ${(props) => (props.$currentView ? '' : 'none')};
`

const CloseIconWrapper = styled.div`
  border-bottom: 1px solid var(--tds-grey-300);
  cursor: pointer;

  svg {
    height: 32px;
    width: 32px;
    margin: 12px;
  }
`

const InputContainer = styled.div`
  margin: 16px;
`

const Header = styled.h4``

const InputWrapper = styled.div`
  margin-top: 16px;
  width: 100%;
`

const TermsAndConditionsWrapper = styled.div`
  margin-top: 32px;
`

interface InputWrapperCheckboxProps {
  $border: boolean
}

const InputWrapperCheckbox = styled.div<InputWrapperCheckboxProps>`
  margin-top: 16px;
  width: 100%;
  padding: 4px;
  border: ${(props) => (props.$border ? '1.5px solid red;' : '')};
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;

  button {
    min-width: 180px;
    justify-content: center;
    margin-bottom: 16px;
  }

  .buttonItalicText {
    button {
      font-style: italic;
    }
  }
`

const LinkForgottenPassword = styled.div`
  margin-top: 8px;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
`

const ButtonWithoutBorder = styled.div`
  font-style: italic;
  margin: 16px;
  cursor: pointer;
`

const ErrorMessageContainer = styled.div`
  color: red;
  margin-top: 16px;
`

const ConfirmViewText = styled.div`
  margin-top: 16px;
`

const SpinnerWrapper = styled.div`
  margin-bottom: 24px;
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: var(--tds-blue-600);
  }
`

export enum SidePanelLoginGarageContent {
  'account',
  'deleteAccount',
}

interface SidePanelLoginAndGarageProps {
  handleConsequenceOfChange: ConsequenceOfChangeHandler
  handleFatalError: () => void
  handleNewTruckIsLoaded: NewTruckLoadedHandler
  handleSessionFailure: () => void
  handleTruckHasBeenDeleted: (truck: UserConfigInfo) => void
  handleUserAccountGdprConsent: () => void
  handleCloseModalConsequenseOfChange: () => void
}

export function SidePanelLoginAndGarage({
  handleConsequenceOfChange,
  handleFatalError,
  handleNewTruckIsLoaded,
  handleSessionFailure,
  handleTruckHasBeenDeleted,
  handleUserAccountGdprConsent,
  handleCloseModalConsequenseOfChange,
}: SidePanelLoginAndGarageProps): JSX.Element {
  const dispatch = useAppDispatch()
  const t = useTexts()
  const marketLanguage = useAppSelector(getMarketLanguageState)
  const isloggedin = useAppSelector(getIsLoggedInState)
  const sessionInitData = useAppSelector(getSessionInitDataState)
  const client = useClient()
  const sidePanelIsOpen = useAppSelector(selectSidePanelState).loginGarageOpen

  const refSidePanelLoginAndGarage = useRef<HTMLDivElement | null>(null)
  const usermail = useRef<HTMLTdsTextFieldElement>(null)
  const userpassword = useRef<HTMLTdsTextFieldElement>(null)
  const password1 = useRef<HTMLTdsTextFieldElement>(null)
  const password2 = useRef<HTMLTdsTextFieldElement>(null)

  const [currentForm, setCurrentForm] = useState<string>('login')
  const [hasReadTerms, setHasReadTerms] = useState<boolean>(false)
  const [hasReadTermsErrorState, setHasReadTermsErrorState] =
    useState<string>('')
  const [userMailValue, setUserMailValue] = useState<string>('')
  const [userMailErrorState, setUserMailErrorState] = useState<
    'default' | 'error' | 'success' | undefined
  >(undefined)
  const [userPasswordValue, setUserPasswordValue] = useState<string>('')
  const [userPasswordErrorState, setPasswordErrorState] = useState<
    'default' | 'error' | 'success' | undefined
  >(undefined)
  const [newPasswordValue1, setNewPasswordValue1] = useState<string>('')
  const [newPasswordValue1ErrorState, setNewPasswordValue1ErrorState] =
    useState<'default' | 'error' | 'success' | undefined>(undefined)
  const [newPasswordValue2, setNewPasswordValue2] = useState<string>('')
  const [newPasswordValue2ErrorState, setNewPasswordValue2ErrorState] =
    useState<'default' | 'error' | 'success' | undefined>(undefined)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [confirmViewContentHeader, setConfirmViewContentHeader] =
    useState<string>('')
  const [confirmViewContentText, setConfirmViewContentText] =
    useState<string>('')
  const [termsAndConditions, setTermsAndConditions] = useState<string[] | null>(
    null,
  )
  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false)

  useEffect(() => {
    const text = t('TEXT_TERMS_AND_CONDITIONS')
    const lines = text.split('{newline}')
    setTermsAndConditions(lines)
  }, [t])

  const handleCloseClick = useCallback(() => {
    // This triggers a strange error in Devlopment builds only, not sure why
    // this was added, let's remove it for now and revisit later. The error was
    // only occurring when clicking outside of the panel, never when clicking
    // directly on the close cross icon.
    //
    // TODO: Investigate 'ResizeObserver loop limit exceeded' later.
    //
    // TODO: Consider handling this inside Redux, closing other popups if
    // clicking certain other elements, eliminating the need to add click event
    // listeners on the document object.
    //setCurrentForm('login')

    dispatch(closePanelLoginGarage())
  }, [dispatch])

  const handleLogin = useCallback(async () => {
    if (!client) {
      return // Not initialized yet.
    }
    if (!sessionInitData) {
      return // Not initialized yet.
    }
    const sessionId = sessionInitData.sessionId
    const newUserEmail = usermail.current?.value.trim()
    if (!newUserEmail || !isEmail(newUserEmail)) {
      setErrorMessage(t('NOTIFICATION_BAD_EMAIL'))
      setUserMailErrorState('error')
      return
    }
    const newUserPassword = userpassword.current?.value.trim()
    if (!newUserPassword) {
      setErrorMessage(t('NOTIFICATION_BAD_MAIL_PASSWORD'))
      setUserPasswordValue('')
      setPasswordErrorState('error')
      return
    }

    try {
      // TODO: Put scdsApi in startupDataBundle and await the entire thing.
      setIsLoggingIn(true)
      const loginResponse = await client.login({
        sessionId,
        userName: newUserEmail,
        password: newUserPassword,
      })
      const err = loginResponse.error
      if (err) {
        if (err.status === SESSION_FAILURE) {
          handleSessionFailure()
          return
        } else if (err.subStatus === LOGIN_SUBSTATUS_NO_SUCH_USER) {
          setErrorMessage(t('LABEL_USER_NOT_REGISTERED'))
          return
        } else if (err.subStatus === LOGIN_SUBSTATUS_WRONG_PASSWORD) {
          setErrorMessage(t('NOTIFICATION_BAD_MAIL_PASSWORD'))
          return
        } else if (err.subStatus === 'WrongMarketForUser') {
          setErrorMessage(t('ERROR_WRONG_MARKET'))
          return
        } else {
          // TODO: Handle more error codes and add translations in SC Admin.
          setErrorMessage(err.status + ' ' + err.subStatus)
          return
        }
      }
      if (!loginResponse.login) {
        handleFatalError()
        return
      }
      const reply = loginResponse.login
      if (reply !== 'SUCCESS') {
        console.error(reply)
        handleFatalError()
        return
      }
      const consentsResponse = await client.getUserConsents(sessionId)
      if (consentsResponse.error === SESSION_FAILURE) {
        handleSessionFailure()
        return
      }
      if (
        !consentsResponse.getUserConsents?.some((v) => v.consentId === 'GDPROK')
      ) {
        handleUserAccountGdprConsent()
      }
      const trackingEvent: ScaniaAdobeTrackingLoginSuccessEvent = {
        event: ScaniaAdobeEventId.LoginSuccess,
      }
      pushAdobeEvent(trackingEvent)
    } catch (err) {
      console.error(err)
      handleFatalError()
      return
    } finally {
      setIsLoggingIn(false)
    }
    dispatch(
      loggedInStatus({
        isloggedin: true,
        userMail: newUserEmail,
      }),
    )
    const garagelistResponse = await client.userConfigV2List(sessionId)
    if (garagelistResponse.error === SESSION_FAILURE) {
      handleSessionFailure()
      return
    } else if (garagelistResponse.error) {
      handleFatalError()
      return
    }
    const garageList = garagelistResponse.userConfigV2List || []
    dispatch(setGarageList(garageList))
    setPasswordErrorState(undefined)
    setUserMailErrorState(undefined)
    setErrorMessage('')
    setCurrentForm('garage')
  }, [
    client,
    dispatch,
    handleFatalError,
    handleSessionFailure,
    handleUserAccountGdprConsent,
    sessionInitData,
    t,
  ])

  const handleRegistration = useCallback(() => {
    const sessionId = sessionInitData?.sessionId
    if (!sessionId) {
      throw new Error('Expected sessionId to be defined.')
    }
    const userEmail = usermail.current?.value.trim()
    const userPassword1 = password1.current?.value.trim()
    const userPassword2 = password2.current?.value.trim()
    if (!isEmail(userEmail || '')) {
      setErrorMessage(t('NOTIFICATION_BAD_EMAIL'))
      setUserMailErrorState('error')
      return
    }
    if (userPassword1 !== userPassword2 || !userPassword1 || !userPassword2) {
      setErrorMessage(t('NOTIFICATION_PASSWORDS_DONT_MATCH'))
      setNewPasswordValue1ErrorState('error')
      setNewPasswordValue2ErrorState('error')
      return
    }
    if (!hasReadTerms) {
      setErrorMessage(t('NOTIFICATION_NEED_ACCEPT_TERMS'))
      setHasReadTermsErrorState('error')
      return
    }
    const createAccountInput: CreateUserRequest = {
      sessionId: sessionInitData?.sessionId,
      userName: userEmail,
      password: userPassword1,
    }
    const trackingEvent: ScaniaAdobeTrackingRegistrationFormSubmit = {
      event: ScaniaAdobeEventId.RegistrationFormSubmit,
    }
    pushAdobeEvent(trackingEvent)
    client
      ?.createUser(createAccountInput)
      .then((status) => {
        if (status.error?.status === SESSION_FAILURE) {
          handleSessionFailure()
          return
        } else if (status.error) {
          console.error(status.error)
          handleFatalError()
          return
        }
        setCurrentForm('login')
      })
      .catch((err) => {
        console.error(err)
        setConfirmViewContentHeader(t('HEADER_ERROR_NOTIFICATION'))
        setConfirmViewContentText(t('ERROR_REGISTRATION_UNAVAILABLE'))
        setCurrentForm('confirmView')
        handleFatalError()
      })
  }, [
    client,
    handleFatalError,
    handleSessionFailure,
    hasReadTerms,
    sessionInitData,
    t,
  ])

  const handleMailPassword = useCallback(async () => {
    const sessionId = sessionInitData?.sessionId
    if (!client) {
      throw new Error('Expected client to be defined.')
    }
    if (!sessionId) {
      throw new Error('Expected sessionId to be defined.')
    }
    if (!marketLanguage) {
      throw new Error('Expected marketLanguage to be defined.')
    }
    const userEmail = usermail.current?.value.trim()
    if (!userEmail || !isEmail(userEmail)) {
      setErrorMessage(t('NOTIFICATION_BAD_EMAIL'))
      setUserMailErrorState('error')
      return
    }
    try {
      await client.sendResetPasswordMail({
        sessionId,
        userName: userEmail,
      })
    } catch (err) {
      // TODO: Get from scadmin.
      setErrorMessage('Could not connect to servers.')
      console.log(err)
      return
    }
    console.log(`Mail sent to ${usermail.current}`)
    setConfirmViewContentHeader(t('HEADER_RESET_EMAIL_SENT'))
    setConfirmViewContentText(t('DESCRIPTION_RESET_EMAIL_SENT'))
    setCurrentForm('confirmView')
  }, [client, sessionInitData, marketLanguage, t])

  // Change view if user is logged in or not
  useEffect(() => {
    if (isloggedin) {
      setCurrentForm('garage')
    } else {
      setCurrentForm('login')
    }
  }, [isloggedin, sidePanelIsOpen])

  // Remove error state and error message when new input
  useEffect(() => {
    setErrorMessage('')
    setUserMailErrorState(undefined)
    setPasswordErrorState(undefined)
    setNewPasswordValue1ErrorState(undefined)
    setNewPasswordValue2ErrorState(undefined)
    setHasReadTermsErrorState('')
    setConfirmViewContentHeader('')
    setConfirmViewContentText('')
  }, [
    userMailValue,
    userPasswordValue,
    newPasswordValue1,
    newPasswordValue2,
    hasReadTerms,
  ])

  // TODO - implement focus() on enter, currently not working
  /*
  const handleEnter = (
    e: any,
    keyCode: string,
    nextInput?: HTMLInputElement | null,
    fn?: () => void,
  ) => {
    if (e.key === keyCode) {
      if (fn) {
        fn()
      } else if (nextInput) {
        nextInput.focus()
        e.preventDefault()
      }
    }
  }
  */

  const handleHasReadTermsToggle = (
    ev: TdsCheckboxCustomEvent<{
      checkboxId: string
      checked: boolean
      indeterminate: boolean
      value?: string | undefined
    }>,
  ) => {
    const checked = ev.detail.checked
    setHasReadTerms(checked)
  }

  const handleUserClickOnScreen = useCallback(
    (e: MouseEvent) => {
      if (!sidePanelIsOpen) {
        return
      }
      const currentElement = refSidePanelLoginAndGarage.current
      const composedPath = e.composedPath()
      const clickIsOutsideSidePanel =
        -1 === composedPath.findIndex((o) => o === currentElement)
      if (clickIsOutsideSidePanel) {
        handleCloseClick()
      }
    },
    [refSidePanelLoginAndGarage, handleCloseClick, sidePanelIsOpen],
  )

  // Close sidePanel on click outside sidepanel
  useEffect(() => {
    document.addEventListener('click', handleUserClickOnScreen)
    return () => {
      document.removeEventListener('click', handleUserClickOnScreen)
    }
  }, [handleUserClickOnScreen])

  return (
    <LoginRoot
      ref={refSidePanelLoginAndGarage}
      $sidePanelIsOpen={sidePanelIsOpen}
    >
      <ContentWrapper $currentView={currentForm === 'login'}>
        <CloseIconWrapper onClick={handleCloseClick}>
          <SvgCross />
        </CloseIconWrapper>
        <InputContainer>
          <Header
            data-test-element-type={TestElementTypeId.GarageLoginFormHeader}
          >
            {t('HEADER_LOGIN_FORM')}
          </Header>
          <InputWrapper>
            <TdsTextField
              data-test-element-type={
                TestElementTypeId.GarageLoginFormEmailAddressInput
              }
              type="text"
              placeholder={t('LABEL_EMAIL')}
              value={userMailValue}
              ref={usermail}
              onInput={(e: any) => setUserMailValue(e.target.value)}
              state={userMailErrorState}
            />
          </InputWrapper>
          <InputWrapper>
            <TdsTextField
              data-test-element-type={
                TestElementTypeId.GarageLoginFormPasswordInput
              }
              type="password"
              placeholder={t('LABEL_PASSWORD')}
              value={userPasswordValue}
              ref={userpassword}
              onInput={(e: any) => setUserPasswordValue(e.target.value)}
              state={userPasswordErrorState}
            />
          </InputWrapper>
          <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
        </InputContainer>

        <ButtonsContainer>
          {isLoggingIn && (
            <SpinnerWrapper
              style={{ visibility: isLoggingIn ? 'visible' : 'hidden' }}
            >
              <SvgLoading />
            </SpinnerWrapper>
          )}
          {!isLoggingIn && (
            <TdsButton
              variant="primary"
              size="md"
              text={t('HEADER_LOGIN_FORM')}
              onClick={handleLogin}
              data-test-element-type={
                TestElementTypeId.GarageLoginFormSubmitButton
              }
            />
          )}

          <TdsButton
            variant="secondary"
            size="md"
            text={t('LABEL_ACTION_REGISTER_ACCOUNT')}
            data-test-element-type={
              TestElementTypeId.GarageLoginFormGotoRegistrationFormButton
            }
            onClick={() => {
              const trackingEvent: ScaniaAdobeTrackingRegistrationFormOpen = {
                event: ScaniaAdobeEventId.RegistrationFormOpen,
              }
              pushAdobeEvent(trackingEvent)
              setCurrentForm('register')
            }}
          />

          <LinkForgottenPassword
            data-test-element-type={
              TestElementTypeId.GarageLoginFormGotoForgottenPasswordFormLink
            }
            onClick={() => setCurrentForm('forgottenPassword')}
          >
            {t('LABEL_FORGOTTEN_PASSWORD')}
          </LinkForgottenPassword>
        </ButtonsContainer>
      </ContentWrapper>
      <ContentWrapper $currentView={currentForm === 'register'}>
        <CloseIconWrapper onClick={handleCloseClick}>
          <SvgCross />
        </CloseIconWrapper>
        <InputContainer>
          <Header
            data-test-element-type={
              TestElementTypeId.GarageRegistrationFormHeader
            }
          >
            {t('HEADER_REGISTRATION_FORM')}
          </Header>
          <InputWrapper>
            <TdsTextField
              data-test-element-type={
                TestElementTypeId.GarageRegistrationFormEmailAddressInput
              }
              type="text"
              placeholder={t('LABEL_EMAIL')}
              value={userMailValue}
              ref={usermail}
              onInput={(e: any) => setUserMailValue(e.target.value)}
              state={userMailErrorState}
            />
          </InputWrapper>
          <InputWrapper>
            <TdsTextField
              data-test-element-type={
                TestElementTypeId.GarageRegistrationFormPasswordInput
              }
              type="password"
              placeholder={t('LABEL_PASSWORD')}
              value={newPasswordValue1}
              ref={password1}
              onInput={(e: any) => setNewPasswordValue1(e.target.value)}
              state={newPasswordValue1ErrorState}
            />
          </InputWrapper>
          <InputWrapper>
            <TdsTextField
              data-test-element-type={
                TestElementTypeId.GarageRegistrationFormPasswordRepeatInput
              }
              type="password"
              placeholder={t('LABEL_PASSWORD_REPEAT')}
              value={newPasswordValue2}
              ref={password2}
              onInput={(e: any) => setNewPasswordValue2(e.target.value)}
              state={newPasswordValue2ErrorState}
            />
          </InputWrapper>
          <TermsAndConditionsWrapper>
            <TdsAccordion>
              <TdsAccordionItem header={t('LABEL_TERMS_AND_CONDITIONS')}>
                {termsAndConditions?.map((line, i) => {
                  return <p key={'terms-and-conditions-line-' + i}>{line}</p>
                })}
              </TdsAccordionItem>
            </TdsAccordion>
          </TermsAndConditionsWrapper>
          <InputWrapperCheckbox $border={hasReadTermsErrorState === 'error'}>
            <TdsCheckbox
              name="gdprConfirm"
              value={hasReadTerms.toString()}
              data-test-element-type={
                TestElementTypeId.GarageRegistrationFormAcceptTermsCheckbox
              }
              checked={hasReadTerms}
              onTdsChange={handleHasReadTermsToggle}
            >
              <span slot="label">{t('GDPR_CONFIRM_TITLE') + ' '}</span>
            </TdsCheckbox>
          </InputWrapperCheckbox>
          <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
        </InputContainer>
        <ButtonsContainer>
          <TdsButton
            variant="primary"
            size="md"
            data-test-element-type={
              TestElementTypeId.GarageRegistrationFormSubmitButton
            }
            onClick={handleRegistration}
            text={t('LABEL_ACTION_SUBMIT_REGISTRATION')}
          />

          <TdsButton
            variant="ghost"
            size="md"
            text={t('LABEL_ACTION_BACKTOLOGGIN')}
            onClick={() => setCurrentForm('login')}
            class="buttonItalicText"
          />
          <LinkForgottenPassword
            onClick={() => setCurrentForm('forgottenPassword')}
          >
            {t('LABEL_FORGOTTEN_PASSWORD')}
          </LinkForgottenPassword>
        </ButtonsContainer>
      </ContentWrapper>
      <ContentWrapper $currentView={currentForm === 'forgottenPassword'}>
        <CloseIconWrapper onClick={handleCloseClick}>
          <SvgCross />
        </CloseIconWrapper>
        <InputContainer>
          <Header
            data-test-element-type={
              TestElementTypeId.GarageForgottenPasswordFormHeader
            }
          >
            {t('HEADER_RESET_PASSWORD')}
          </Header>
          <InputWrapper>
            <TdsTextField
              data-test-element-type={
                TestElementTypeId.GarageForgottenPasswordFormEmailAddressInput
              }
              type="text"
              placeholder={t('LABEL_EMAIL')}
              value={userMailValue}
              ref={usermail}
              onInput={(e: any) => setUserMailValue(e.target.value)}
            />
          </InputWrapper>
          <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
        </InputContainer>
        <ButtonsContainer>
          <TdsButton
            variant="primary"
            size="md"
            data-test-element-type={
              TestElementTypeId.GarageForgottenPasswordFormSubmitButton
            }
            onClick={handleMailPassword}
            text={t('LABEL_ACTION_MAILPASSWORD')}
          />
          <TdsButton
            variant="ghost"
            size="md"
            class="buttonItalicText"
            data-test-element-type={
              TestElementTypeId.GarageForgottenPasswordFormGotoLoginFormButton
            }
            onClick={() => setCurrentForm('login')}
            text={t('HEADER_LOGIN_FORM')}
          />
        </ButtonsContainer>
      </ContentWrapper>
      <ContentWrapper $currentView={currentForm === 'confirmView'}>
        <CloseIconWrapper onClick={handleCloseClick}>
          <SvgCross />
        </CloseIconWrapper>
        <InputContainer>
          <Header>{confirmViewContentHeader}</Header>
          <ConfirmViewText>{confirmViewContentText}</ConfirmViewText>
        </InputContainer>
        <ButtonsContainer>
          <ButtonWithoutBorder onClick={() => setCurrentForm('login')}>
            {t('LABEL_ACTION_LOGIN')}
          </ButtonWithoutBorder>
        </ButtonsContainer>
      </ContentWrapper>
      {currentForm === 'garage' && (
        <GarageView
          handleConsequenceOfChange={handleConsequenceOfChange}
          handleFatalError={handleFatalError}
          handleNewTruckIsLoaded={handleNewTruckIsLoaded}
          handleSessionFailure={handleSessionFailure}
          handleTruckHasBeenDeleted={handleTruckHasBeenDeleted}
          handleCloseModalConsequenseOfChange={
            handleCloseModalConsequenseOfChange
          }
        />
      )}
    </LoginRoot>
  )
}
