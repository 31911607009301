import styled from 'styled-components'
import { SvgCross, SvgInfo } from '../../components/SvgImports'
import { MouseEventHandler } from 'react'
import { TextId, useTextsV2 } from '../../utils/useTexts'

const OrangeBlobRoot = styled.div`
  left: 50%;
  position: absolute;
  top: 27%;
  cursor: pointer;
`

const MainBlobRoot = styled.div`
  aspect-ratio: 1 / 1;
  border-radius: 100%;
  height: max-content;
  left: 0;
  position: absolute;
  top: 0;
  width: max-content;
`

const MainBlobInner = styled.div`
  align-items: center;
  aspect-ratio: 1 / 1;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.5em;
  position: relative;
  row-gap: 2px;

  p {
    margin: 0;
  }
`

const StrongText = styled.p`
  font-weight: bold;
`

const CrossContainer = styled.div`
  --width: 24px;
  --height: 24px;
  --half-width: calc(var(--width) / 2);
  --half-height: calc(var(--height) / 2);
  position: absolute;
  border-radius: 100%;
  margin: 0;
  padding: 2px;
  width: var(--width);
  height: var(--height);

  // Position this element on the parent circle element's border at a 45 degree
  // angle.
  left: calc(50% + (50% * cos(-45deg)) - var(--half-width));
  top: calc(50% + (50% * sin(-45deg)) - var(--half-height));
`

enum MainBlobMode {
  BackgroundOnly,
  ContentOnly,
}

enum TextChunkType {
  Text,
  UnexpandedVariable,
}

interface TextChunk {
  type: TextChunkType
  text: string | null
}

interface MainBlobProps {
  handleCrossClick: MouseEventHandler
  mode: MainBlobMode
}

/**
 * The inner component that is drawn twice, once with the backgrounds
 * semi-transparent and content invisible and once with fully transparent
 * backgrounds and visible content. All to avoid semitransparent content. Why
 * not use rgba for background-color instead? Well, that would cause the
 * backgrounds for MainBlobRoot and CrossContainer to blend together and the
 * intersection of the two will be a different color than the rest, which would
 * be ugly.
 */
function MainBlob({ handleCrossClick, mode }: MainBlobProps) {
  const t = useTextsV2()
  const contentVisibility =
    mode === MainBlobMode.ContentOnly ? 'visible' : 'hidden'
  const bgColor =
    mode === MainBlobMode.BackgroundOnly ? 'rgba(235, 98, 0)' : 'none'
  const opacity = mode === MainBlobMode.BackgroundOnly ? '0.8' : '1'

  // TODO: Figure out how to take the element constructors as parameters and use
  // them in the template. JSXElementConstructor? Seems to work with "any" as
  // generic parameter in combination with styled components, but there must be
  // a better way?
  function buildSmartDashCampaignParagaphs(): JSX.Element[] {
    const smartDashName = t(TextId.CAMPAIGN_SMART_DASH_NAME).trim() // 'Smart Dash'
    const smartDashLinkText = t(TextId.CAMPAIGN_SMART_DASH_TEXT).trim() // "Explore Scania's new"
    const subStrings: TextChunk[] = smartDashLinkText
      .split('{}')
      .flatMap<TextChunk>((s) => {
        if (s === '') {
          return [{ type: TextChunkType.UnexpandedVariable, text: null }]
        }
        if (s.length <= smartDashName.length) {
          return [{ type: TextChunkType.Text, text: s }]
        }
        const middle = Math.round(s.length / 2)
        const left = s.lastIndexOf(' ', middle)
        const right = s.indexOf(' ', middle)
        if (left === -1 && right === -1) {
          // There is only a single word, or characters without space, we
          // can't insert any line breaks in this text.
          return [{ type: TextChunkType.Text, text: s }]
        }
        let i: number = -1
        if (left === -1) {
          i = right
        } else if (right === -1) {
          i = left
        } else {
          // Both left and right are assigned an index, none of them are -1.
          // Choose the one closest to the middle of the string.
          i = middle - left < right - middle ? left : right
        }
        if (i === -1) {
          // Should not happen, but nice to have in case we made a mistake
          // above or if we introduce a mistake later.
          return [{ type: TextChunkType.Text, text: s }]
        }
        return [
          { type: TextChunkType.Text, text: s.slice(0, i).trim() },
          { type: TextChunkType.Text, text: s.slice(i).trim() },
        ]
      })
    const subStringsLastIndex = subStrings.length - 1
    const nbsp = <>&nbsp;</>
    return subStrings.map((s, i) => {
      const maybeNbsp = i === subStringsLastIndex ? '' : nbsp
      const style: React.CSSProperties = { visibility: contentVisibility }
      if (s.type === TextChunkType.UnexpandedVariable) {
        const key = `line-${i}-${smartDashName}`
        return (
          <StrongText key={key} style={style}>
            {smartDashName}
            {maybeNbsp}
          </StrongText>
        )
      } else if (s.type === TextChunkType.Text) {
        const key = `line-${i}-${s}`
        return (
          <p key={key} style={style}>
            {s.text}
            {maybeNbsp}
          </p>
        )
      } else {
        throw new Error('Unexpected TextChunkType: ' + s.type)
      }
    })
  }

  const textLines = buildSmartDashCampaignParagaphs()

  return (
    <MainBlobRoot style={{ backgroundColor: bgColor, opacity }}>
      <MainBlobInner>
        <SvgInfo
          style={{
            visibility: contentVisibility,
            marginTop: '-1em',
            width: '16px',
          }}
        />
        {textLines}
        <CrossContainer
          onClick={handleCrossClick}
          style={{ backgroundColor: bgColor }}
        >
          <SvgCross style={{ visibility: contentVisibility }} />
        </CrossContainer>
      </MainBlobInner>
    </MainBlobRoot>
  )
}

export interface OrangeBlobProps {
  handleClick: MouseEventHandler
  handleCrossClick: MouseEventHandler
}

export function OrangeBlob({ handleClick, handleCrossClick }: OrangeBlobProps) {
  return (
    <OrangeBlobRoot onClick={handleClick}>
      <MainBlob
        handleCrossClick={() => {}}
        mode={MainBlobMode.BackgroundOnly}
      />
      <MainBlob
        handleCrossClick={handleCrossClick}
        mode={MainBlobMode.ContentOnly}
      />
    </OrangeBlobRoot>
  )
}
