import styled from 'styled-components'
import { useAppSelector } from '../../store/hooks'
import useTexts from '../../utils/useTexts'
import { SvgBrokenCable } from '../SvgImports'
import { getErrorState } from '../../store/sessionDataSlice'

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ theme }) => theme.scGreyDark};
  color: ${({ theme }) => theme.scWhite};
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  user-select: none;

  // TODO: Revisit and try to eliminate.
  z-index: 1000;

  svg {
    width: 100px;
  }
  .broken-cable-wrapper svg {
    fill: rgba(255, 255, 255, 0.2);
    margin-bottom: 10vh;
  }
  p {
    max-width: 500px;
  }
`
Overlay.displayName = 'Overlay'

const ErrorLayer = () => {
  const hasError = useAppSelector(getErrorState)

  const t = useTexts()

  if (hasError.errorLevel >= 2) {
    return (
      <Overlay>
        <div className="broken-cable-wrapper">
          <SvgBrokenCable />
        </div>
        {/*
        // TODO: Find a replacement, a simple SVG without dependencies.
        <UseAnimations
          animation={alertCircle}
          size={56}
          style={{ margin: '0 auto' }}
          strokeColor="rgb(255,255,255)"
        />
        */}
        <h2>{t('HEADER_ERROR_OCCURD')}</h2>
        <p>{hasError.friendlyErrorMessage || 'An unknown error occurred.'}</p>
        <p>Error: {JSON.stringify(hasError)}</p>
      </Overlay>
    )
  } else return null
}

export default ErrorLayer
