/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * Parameter structure for flex image requests.
 *
 * TODO: Write documentation with examples.
 * TODO: Remove all unused parameters.
 * @export
 * @interface FactoryModelFlexImageParamsParams
 */
export interface FactoryModelFlexImageParamsParams {
  /**
   *
   * @type {string}
   * @memberof FactoryModelFlexImageParamsParams
   */
  series: string
  /**
   *
   * @type {boolean}
   * @memberof FactoryModelFlexImageParamsParams
   */
  useBoundingBoxes: boolean
  /**
   *
   * @type {number}
   * @memberof FactoryModelFlexImageParamsParams
   */
  frame?: number | null
  /**
   *
   * @type {string}
   * @memberof FactoryModelFlexImageParamsParams
   */
  bgColor?: string | null
  /**
   *
   * @type {string}
   * @memberof FactoryModelFlexImageParamsParams
   */
  bgImage?: string | null
  /**
   *
   * @type {string}
   * @memberof FactoryModelFlexImageParamsParams
   */
  imageType?: string | null
  /**
   *
   * @type {Array<number>}
   * @memberof FactoryModelFlexImageParamsParams
   */
  size?: Array<number> | null
  /**
   *
   * @type {Array<number>}
   * @memberof FactoryModelFlexImageParamsParams
   */
  minBound?: Array<number> | null
  /**
   *
   * @type {Array<number>}
   * @memberof FactoryModelFlexImageParamsParams
   */
  sample?: Array<number> | null
  /**
   *
   * @type {Array<string>}
   * @memberof FactoryModelFlexImageParamsParams
   */
  autoCrop?: Array<string> | null
  /**
   *
   * @type {boolean}
   * @memberof FactoryModelFlexImageParamsParams
   */
  skipBackgroundElementsInAutocrop?: boolean
  /**
   *
   * @type {Array<number>}
   * @memberof FactoryModelFlexImageParamsParams
   */
  align?: Array<number> | null
  /**
   *
   * @type {Array<number>}
   * @memberof FactoryModelFlexImageParamsParams
   */
  margin?: Array<number> | null
}

/**
 * Check if a given object implements the FactoryModelFlexImageParamsParams interface.
 */
export function instanceOfFactoryModelFlexImageParamsParams(
  value: object,
): boolean {
  let isInstance = true
  isInstance = isInstance && 'series' in value
  isInstance = isInstance && 'useBoundingBoxes' in value

  return isInstance
}

export function FactoryModelFlexImageParamsParamsFromJSON(
  json: any,
): FactoryModelFlexImageParamsParams {
  return FactoryModelFlexImageParamsParamsFromJSONTyped(json, false)
}

export function FactoryModelFlexImageParamsParamsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): FactoryModelFlexImageParamsParams {
  if (json === undefined || json === null) {
    return json
  }
  return {
    series: json['series'],
    useBoundingBoxes: json['useBoundingBoxes'],
    frame: !exists(json, 'frame') ? undefined : json['frame'],
    bgColor: !exists(json, 'bgColor') ? undefined : json['bgColor'],
    bgImage: !exists(json, 'bgImage') ? undefined : json['bgImage'],
    imageType: !exists(json, 'imageType') ? undefined : json['imageType'],
    size: !exists(json, 'size') ? undefined : json['size'],
    minBound: !exists(json, 'minBound') ? undefined : json['minBound'],
    sample: !exists(json, 'sample') ? undefined : json['sample'],
    autoCrop: !exists(json, 'autoCrop') ? undefined : json['autoCrop'],
    skipBackgroundElementsInAutocrop: !exists(
      json,
      'skipBackgroundElementsInAutocrop',
    )
      ? undefined
      : json['skipBackgroundElementsInAutocrop'],
    align: !exists(json, 'align') ? undefined : json['align'],
    margin: !exists(json, 'margin') ? undefined : json['margin'],
  }
}

export function FactoryModelFlexImageParamsParamsToJSON(
  value?: FactoryModelFlexImageParamsParams | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    series: value.series,
    useBoundingBoxes: value.useBoundingBoxes,
    frame: value.frame,
    bgColor: value.bgColor,
    bgImage: value.bgImage,
    imageType: value.imageType,
    size: value.size,
    minBound: value.minBound,
    sample: value.sample,
    autoCrop: value.autoCrop,
    skipBackgroundElementsInAutocrop: value.skipBackgroundElementsInAutocrop,
    align: value.align,
    margin: value.margin,
  }
}
