/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ConfigChangeMenuEntry
 */
export interface ConfigChangeMenuEntry {
  /**
   *
   * @type {string}
   * @memberof ConfigChangeMenuEntry
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof ConfigChangeMenuEntry
   */
  guiGroup: string
  /**
   *
   * @type {string}
   * @memberof ConfigChangeMenuEntry
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof ConfigChangeMenuEntry
   */
  shortText: string
  /**
   *
   * @type {boolean}
   * @memberof ConfigChangeMenuEntry
   */
  hasReadmore: boolean
  /**
   *
   * @type {boolean}
   * @memberof ConfigChangeMenuEntry
   */
  visible: boolean
  /**
   *
   * @type {boolean}
   * @memberof ConfigChangeMenuEntry
   */
  selected: boolean
  /**
   *
   * @type {string}
   * @memberof ConfigChangeMenuEntry
   */
  menuPath: string
}

/**
 * Check if a given object implements the ConfigChangeMenuEntry interface.
 */
export function instanceOfConfigChangeMenuEntry(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'guiGroup' in value
  isInstance = isInstance && 'name' in value
  isInstance = isInstance && 'shortText' in value
  isInstance = isInstance && 'hasReadmore' in value
  isInstance = isInstance && 'visible' in value
  isInstance = isInstance && 'selected' in value
  isInstance = isInstance && 'menuPath' in value

  return isInstance
}

export function ConfigChangeMenuEntryFromJSON(
  json: any,
): ConfigChangeMenuEntry {
  return ConfigChangeMenuEntryFromJSONTyped(json, false)
}

export function ConfigChangeMenuEntryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ConfigChangeMenuEntry {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    guiGroup: json['gui_group'],
    name: json['name'],
    shortText: json['short_text'],
    hasReadmore: json['has_readmore'],
    visible: json['visible'],
    selected: json['selected'],
    menuPath: json['menu_path'],
  }
}

export function ConfigChangeMenuEntryToJSON(
  value?: ConfigChangeMenuEntry | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    gui_group: value.guiGroup,
    name: value.name,
    short_text: value.shortText,
    has_readmore: value.hasReadmore,
    visible: value.visible,
    selected: value.selected,
    menu_path: value.menuPath,
  }
}
