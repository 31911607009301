import useTexts from '../../utils/useTexts'
import { SvgLoading } from '../SvgImports'
import { TdsModal } from '@scania/tegel-react'

export function ModalWaitingForExtendedCheck(): JSX.Element {
  const t = useTexts()
  return (
    <TdsModal size="md" header={t('HEADER_WAIT')} show prevent={true}>
      <span slot="body">
        <SvgLoading
          color="var(--tds-blue-300)"
          width={'64px'}
          height={'64px'}
        />
      </span>
    </TdsModal>
  )
}
