/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GowizardUndoV2Request } from './GowizardUndoV2Request'
import {
  GowizardUndoV2RequestFromJSON,
  GowizardUndoV2RequestFromJSONTyped,
  GowizardUndoV2RequestToJSON,
} from './GowizardUndoV2Request'

/**
 * Input structure for gowizard_change_slider.
 * @export
 * @interface GowizardChangeSliderRequest
 */
export interface GowizardChangeSliderRequest {
  /**
   *
   * @type {GowizardUndoV2Request}
   * @memberof GowizardChangeSliderRequest
   */
  state: GowizardUndoV2Request
  /**
   *
   * @type {string}
   * @memberof GowizardChangeSliderRequest
   */
  etelVariant: string
}

/**
 * Check if a given object implements the GowizardChangeSliderRequest interface.
 */
export function instanceOfGowizardChangeSliderRequest(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'state' in value
  isInstance = isInstance && 'etelVariant' in value

  return isInstance
}

export function GowizardChangeSliderRequestFromJSON(
  json: any,
): GowizardChangeSliderRequest {
  return GowizardChangeSliderRequestFromJSONTyped(json, false)
}

export function GowizardChangeSliderRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GowizardChangeSliderRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    state: GowizardUndoV2RequestFromJSON(json['state']),
    etelVariant: json['etel_variant'],
  }
}

export function GowizardChangeSliderRequestToJSON(
  value?: GowizardChangeSliderRequest | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    state: GowizardUndoV2RequestToJSON(value.state),
    etel_variant: value.etelVariant,
  }
}
