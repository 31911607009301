/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface UserConsent
 */
export interface UserConsent {
  /**
   *
   * @type {string}
   * @memberof UserConsent
   */
  consentId: string
  /**
   *
   * @type {string}
   * @memberof UserConsent
   */
  consentText: string
}

/**
 * Check if a given object implements the UserConsent interface.
 */
export function instanceOfUserConsent(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'consentId' in value
  isInstance = isInstance && 'consentText' in value

  return isInstance
}

export function UserConsentFromJSON(json: any): UserConsent {
  return UserConsentFromJSONTyped(json, false)
}

export function UserConsentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserConsent {
  if (json === undefined || json === null) {
    return json
  }
  return {
    consentId: json['consent_id'],
    consentText: json['consent_text'],
  }
}

export function UserConsentToJSON(value?: UserConsent | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    consent_id: value.consentId,
    consent_text: value.consentText,
  }
}
