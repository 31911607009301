/**
 * Since a background image doesn't have the onload
 * event available this is a substitute.
 * Send i a ref to an element with a bg images set
 * and get a promise in return that
 * resolves when the image is loaded.
 * @param {*} ref
 */
const whenBgImageIsLoaded = (ref) => {
  return new Promise((resolve, reject) => {
    let src = window.getComputedStyle(ref).backgroundImage
    const rxImg = new RegExp('.jpg|.jpeg|.webp|.png|.gif', 'i')
    if (src === 'none' || !rxImg.test(src)) {
      resolve(true)
    } else {
      src = src.split(',')[src.split(',').length - 1]
      const url = src.match(/\((.*?)\)/)[1].replace(/('|")/g, '')
      var img = new Image()
      img.onload = () => resolve()
      img.onerror = (err) => reject(err)
      img.src = url
      if (img.complete) img.onload()
    }
  })
}

export default whenBgImageIsLoaded
