/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface SendChangesToEysRequest
 */
export interface SendChangesToEysRequest {
  /**
   *
   * @type {string}
   * @memberof SendChangesToEysRequest
   */
  sessionId?: string | null
  /**
   *
   * @type {string}
   * @memberof SendChangesToEysRequest
   */
  etelLanguage?: string | null
  /**
   *
   * @type {string}
   * @memberof SendChangesToEysRequest
   */
  parentPublicConfigId?: string
}

/**
 * Check if a given object implements the SendChangesToEysRequest interface.
 */
export function instanceOfSendChangesToEysRequest(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function SendChangesToEysRequestFromJSON(
  json: any,
): SendChangesToEysRequest {
  return SendChangesToEysRequestFromJSONTyped(json, false)
}

export function SendChangesToEysRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SendChangesToEysRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    sessionId: !exists(json, 'session_id') ? undefined : json['session_id'],
    etelLanguage: !exists(json, 'etel_language')
      ? undefined
      : json['etel_language'],
    parentPublicConfigId: !exists(json, 'parent_public_config_id')
      ? undefined
      : json['parent_public_config_id'],
  }
}

export function SendChangesToEysRequestToJSON(
  value?: SendChangesToEysRequest | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    session_id: value.sessionId,
    etel_language: value.etelLanguage,
    parent_public_config_id: value.parentPublicConfigId,
  }
}
