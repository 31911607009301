// TODO: Port to Typescript and simplify.
// TODO: Clean this up and delete everything that's no longer needed.

import { createGlobalStyle } from 'styled-components'
import { fluidRange } from 'polished'
import tegelCss from '@scania/tegel/dist/tegel/tegel.css'

const GlobalStyle = createGlobalStyle`
${tegelCss}

p {
  margin: 0.5em 0;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}


// ScaniaSansHeadline-Regular.otf
// ScaniaOffice-Bold.ttf
// ScaniaOffice-Italic.ttf
// ScaniaOffice-Regular.ttf
// ScaniaOfficeCondensed-Bold.ttf
// ScaniaOfficeCondensed-Italic.ttf
// ScaniaOfficeCondensed-Regular.ttf
// ScaniaOfficeHeadline-Bold.ttf
// ScaniaOfficeHeadline-Regular.ttf
// ScaniaSans-Bold.otf
// ScaniaSans-Italic.otf
// ScaniaSans-Regular.otf
// ScaniaSansCondensed-Bold.otf
// ScaniaSansCondensed-Italic.otf
// ScaniaSansCondensed-Regular.otf
// ScaniaSansHeadline-Bold.otf


body {
  margin: 0;
  font-family: 'Scania Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: none;

  // Disable scrolling for this element.
  overflow: hidden;
}
html {
  box-sizing: border-box;

  // Disallow scrolling.
  overflow: hidden;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

html.Chrome {
  #root {
    position: fixed;
    top: 0;
    left: 0;
  }
}

#portal {
  position: fixed;
  width: 100%;
  height: var(--app-height); // TODO: Test this after this variable was moved.
  top: 0;
  left: 0;
  pointer-events: none;
  & > * {
    pointer-events: all;
  }
}

.hidden {
  display: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  color: ${({ theme }) => theme.scBlack};
}

:root {
  /* General variables and constants */
  ${fluidRange(
    {
      prop: '--ahref-width',
      fromSize: '50px',
      toSize: '130px',
    },
    '400px',
    '960px',
  )}

  /* Slider variables and constants */
  --header-height: 64px;

  /* TODO: Merge into one? */
  --footer-height: 48px;
  --footer-height-mobile: 250px;

  --configurator-menu-width: 288px;
  --mini-summary-width-desktop: 300px;
  --side-panel-width: 350px;
}
`

export default GlobalStyle
