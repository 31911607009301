declare global {
  interface Window {
    adobeDataLayer: ScaniaAdobeTrackingEvent[]
  }
}

export const AdobeTrackingGoSkipToBm = 'go-skip-to-bm'

export enum ScaniaAdobeTrackingPageName {
  BuildMode = 'build-mode',
  FactoryModels = 'digital showroom',
  GoApplication = 'go-application',
  GoCab = 'go-cab',
  GoCabSeries = 'go-cab-series',
  GoOperations = 'go-operations',
  GoOptionals = 'assist or view truck',
  GoTruckType = 'go-truck-type',
  GoWheelConfig = 'go-wheel-configuration',
  Home = 'home',
}

export enum ScaniaAdobeEventId {
  ActiveChangesClick = 'activeChangesClick',
  BmReadMoreClick = 'bmReadMoreClick',
  BodyBuildToggleClick = 'bodyBuildToggleClick',
  ConfigChanged = 'productSelectionChanged',
  Download = 'configuratorDownload',
  EysClick = 'eysClick',
  EysSendChangesClick = 'eysSendChangesClick',
  FmModelFilterClick = 'fmModelFilterClick',
  FmReadMoreClick = 'fmReadMoreClick',
  FmShowAllClick = 'fmShowAllClick',
  GoOptionalsClick = 'selectOptionGoOptionals',
  GoReadMoreClick = 'infoIconClicked',
  LoginSuccess = 'loginSuccess',
  MiniSummaryColorClick = 'minisummaryColorClick',
  MiniSummaryMotorClick = 'minisummaryMotorClick',
  MopinionFeedbackSent = 'mopinion_feedback_sent',
  MopinionHidden = 'mopinion_hidden',
  MopinionNext = 'mopinion_next',
  MopinionReady = 'mopinion_ready',
  MopinionRedirect = 'mopinion_redirect',
  MopinionShown = 'mopinion_shown',
  MopinionWillHide = 'mopinion_will_hide',
  MopinionWillShow = 'mopinion_will_show',
  PageView = 'pageView',
  PanoramaClick = 'panoramaClick',
  RegistrationFormOpen = 'registrationFormOpen',
  RegistrationFormSubmit = 'registrationFormSubmit',
  RfqCtaClick = 'rfqCtaClick',
  RfqFormSubmit = 'rfqFormSubmit',
  SaveAsGarageClick = 'saveAsGarageClick',
  SaveGarageClick = 'saveGarageClick',
  SearchResultClick = 'searchResultClick',
  Share = 'configuratorShare',
  SmartDashCampaignClick = 'smartDashCampaignClick',
  SuperClick = 'superClick',
  UpgradeYourTruckClick = 'upgradeYourTruckClick',
  XmlUploadClick = 'xmlUploadClick',
}

export enum ScaniaAdobeDownloadType {
  Image = 'image download',
  Pdf = 'pdf download',
  Qr = 'qr download',
  Xml = 'xml download',
}

export enum ScaniaAdobeShareOption {
  CopyLink = 'CopyLink',
  Email = 'Email',
  Facebook = 'Facebook',
  WhatsApp = 'WhatsApp',
}

// SC1M-2568 Keep as is, change or delete? (clicked an Etel Variant in a leaf
// menu in the configuration part of the main menu)
// TODO: No response from the tracking team, revisit in versions after 9.2.
//export const ADOBE_SELECT_BM_MENU_SELECT = 'selectOptionBmMenuSelect'

export interface ScaniaAdobeTrackingClickActiveChangesEvent {
  event: ScaniaAdobeEventId.ActiveChangesClick
}

export interface ScaniaAdobeTrackingClickBmReadMoreEvent {
  event: ScaniaAdobeEventId.BmReadMoreClick
}

export interface ScaniaAdobeTrackingClickBodyBuildToggleEvent {
  event: ScaniaAdobeEventId.BodyBuildToggleClick
  eventInfo: {
    newState: 'ON' | 'OFF'
  }
}

export interface ScaniaAdobeTrackingClickEysEvent {
  event: ScaniaAdobeEventId.EysClick
}

export interface ScaniaAdobeTrackingClickEysSendChangesEvent {
  event: ScaniaAdobeEventId.EysSendChangesClick
}

export interface ScaniaAdobeTrackingClickFmModelFilterEvent {
  event: ScaniaAdobeEventId.FmModelFilterClick
  eventInfo: {
    optionSelected: string
  }
}

export interface ScaniaAdobeTrackingClickFmReadMoreEvent {
  event: ScaniaAdobeEventId.FmReadMoreClick
}

export interface ScaniaAdobeTrackingClickFmShowAllEvent {
  event: ScaniaAdobeEventId.FmShowAllClick
}

export interface ScaniaAdobeTrackingClickGoReadMoreEvent {
  event: ScaniaAdobeEventId.GoReadMoreClick
  eventInfo: {
    infoName: string
    stepName: string
  }
}

export interface ScaniaAdobeTrackingLoginSuccessEvent {
  event: ScaniaAdobeEventId.LoginSuccess
}

export interface ScaniaAdobeTrackingClickMinisummaryColorEvent {
  event: ScaniaAdobeEventId.MiniSummaryColorClick
}

export interface ScaniaAdobeTrackingClickMinisummaryMotorEvent {
  event: ScaniaAdobeEventId.MiniSummaryMotorClick
}

export interface ScaniaAdobeTrackingMopinionFeedbackSent {
  event: ScaniaAdobeEventId.MopinionFeedbackSent

  /**
   * Mopinion controls this data structure and we just forward it as is which
   * means it needs to be the `any` type.
   **/
  eventInfo: any
}

export interface ScaniaAdobeTrackingMopinionHidden {
  event: ScaniaAdobeEventId.MopinionHidden

  /**
   * Mopinion controls this data structure and we just forward it as is which
   * means it needs to be the `any` type.
   **/
  eventInfo: any
}

export interface ScaniaAdobeTrackingMopinionNext {
  event: ScaniaAdobeEventId.MopinionNext

  /**
   * Mopinion controls this data structure and we just forward it as is which
   * means it needs to be the `any` type.
   **/
  eventInfo: any
}

export interface ScaniaAdobeTrackingMopinionReady {
  event: ScaniaAdobeEventId.MopinionReady

  /**
   * Mopinion controls this data structure and we just forward it as is which
   * means it needs to be the `any` type.
   **/
  eventInfo: any
}

export interface ScaniaAdobeTrackingMopinionRedirect {
  event: ScaniaAdobeEventId.MopinionRedirect

  /**
   * Mopinion controls this data structure and we just forward it as is which
   * means it needs to be the `any` type.
   **/
  eventInfo: any
}

export interface ScaniaAdobeTrackingMopinionShown {
  event: ScaniaAdobeEventId.MopinionShown

  /**
   * Mopinion controls this data structure and we just forward it as is which
   * means it needs to be the `any` type.
   **/
  eventInfo: any
}

export interface ScaniaAdobeTrackingMopinionWillHide {
  event: ScaniaAdobeEventId.MopinionWillHide

  /**
   * Mopinion controls this data structure and we just forward it as is which
   * means it needs to be the `any` type.
   **/
  eventInfo: any
}

export interface ScaniaAdobeTrackingMopinionWillShow {
  event: ScaniaAdobeEventId.MopinionWillShow

  /**
   * Mopinion controls this data structure and we just forward it as is which
   * means it needs to be the `any` type.
   **/
  eventInfo: any
}

export interface ScaniaAdobeTrackingClickPanoramaEvent {
  event: ScaniaAdobeEventId.PanoramaClick
}

/**
 * Intended for main menu Etel variant selections.
 */
export interface ScaniaAdobeTrackingConfigChangedEvent {
  event: ScaniaAdobeEventId.ConfigChanged
  eventInfo: {
    optionSelected: string
    placement: ScaniaAdobeTrackingButtonPlacement
  }
}

export interface ScaniaAdobeTrackingClickRfqCtaEvent {
  event: ScaniaAdobeEventId.RfqCtaClick
}

export interface ScaniaAdobeTrackingClickSaveAsGarageEvent {
  event: ScaniaAdobeEventId.SaveAsGarageClick
  eventInfo: {
    placement: ScaniaAdobeTrackingButtonPlacement
  }
}

export interface ScaniaAdobeTrackingClickSaveGarageEvent {
  event: ScaniaAdobeEventId.SaveGarageClick
}

export interface ScaniaAdobeTrackingClickSmartDashCampaignEvent {
  event: ScaniaAdobeEventId.SmartDashCampaignClick
  eventInfo: {
    clickTarget: 'CLOSE_BUTTON' | 'CAMPAIGN_TEXT'
    campaignVariant: 'VARIANT_A_LINES_AND_CURVE' | 'VARIANT_B_ORANGE_BLOB'
  }
}

export interface ScaniaAdobeTrackingClickSuperEvent {
  event: ScaniaAdobeEventId.SuperClick
}

export enum ScaniaAdobePageName {
  FactoryModels = 'factory model',
  GuidedOffering = 'guided offering',
}

export interface ScaniaAdobeTrackingClickUpgradeYourTruckEvent {
  event: ScaniaAdobeEventId.UpgradeYourTruckClick
  eventInfo: {
    flow: ScaniaAdobePageName
  }
}

export enum ScaniaAdobeTrackingButtonPlacement {
  LeftMenu = 'left menus',
  TopRight = 'top right',
  SummarySection = 'summary section',
}

export interface ScaniaAdobeTrackingDownloadBase {
  filePath: string
  placement: ScaniaAdobeTrackingButtonPlacement
}

export interface ScaniaAdobeTrackingDownloadImageEvent {
  event: ScaniaAdobeEventId.Download
  eventInfo: ScaniaAdobeTrackingDownloadBase & {
    downloadType: ScaniaAdobeDownloadType.Image
  }
}

export interface ScaniaAdobeTrackingDownloadPdfEvent {
  event: ScaniaAdobeEventId.Download
  eventInfo: ScaniaAdobeTrackingDownloadBase & {
    downloadType: ScaniaAdobeDownloadType.Pdf
  }
}

export interface ScaniaAdobeTrackingDownloadQrEvent {
  event: ScaniaAdobeEventId.Download
  eventInfo: ScaniaAdobeTrackingDownloadBase & {
    downloadType: ScaniaAdobeDownloadType.Qr
  }
}

export interface ScaniaAdobeTrackingDownloadXmlEvent {
  event: ScaniaAdobeEventId.Download
  eventInfo: ScaniaAdobeTrackingDownloadBase & {
    downloadType: ScaniaAdobeDownloadType.Xml
  }
}

export interface ScaniaAdobeTrackingGoOptionalsInput {
  event: ScaniaAdobeEventId.GoOptionalsClick
  eventInfo: {
    questionLocalized: string | null | undefined
    selectedOptionLocalized: string | null
    selectedOption: string
  }
}

export interface ScaniaAdobeTrackingRegistrationFormOpen {
  event: ScaniaAdobeEventId.RegistrationFormOpen
}

export interface ScaniaAdobeTrackingRegistrationFormSubmit {
  event: ScaniaAdobeEventId.RegistrationFormSubmit
}

export interface ScaniaAdobeTrackingRfqFormSubmitEvent {
  event: ScaniaAdobeEventId.RfqFormSubmit
}

export interface ScaniaAdobeTrackingSearchResultClick {
  event: ScaniaAdobeEventId.SearchResultClick
  eventInfo: {
    searchTerm: string
    searchResultCount: number
    searchResultClickTitle: string
  }
}

export interface ScaniaAdobeTrackingShareEmailEvent {
  event: ScaniaAdobeEventId.Share
  eventInfo: {
    shareOption: ScaniaAdobeShareOption.Email
    placement: ScaniaAdobeTrackingButtonPlacement
  }
}

export interface ScaniaAdobeTrackingShareFacebookEvent {
  event: ScaniaAdobeEventId.Share
  eventInfo: {
    shareOption: ScaniaAdobeShareOption.Facebook
    placement: ScaniaAdobeTrackingButtonPlacement
  }
}

export interface ScaniaAdobeTrackingShareLinkEvent {
  event: ScaniaAdobeEventId.Share
  eventInfo: {
    shareOption: ScaniaAdobeShareOption.CopyLink
    placement: ScaniaAdobeTrackingButtonPlacement
  }
}

export interface ScaniaAdobeTrackingShareWhatsAppEvent {
  event: ScaniaAdobeEventId.Share
  eventInfo: {
    shareOption: ScaniaAdobeShareOption.WhatsApp
    placement: ScaniaAdobeTrackingButtonPlacement
  }
}

export interface ScaniaAdobeTrackingUploadXmlEvent {
  event: ScaniaAdobeEventId.XmlUploadClick
}

export type ScaniaAdobeTrackingPageViewEvent = {
  /**
   * From the tracking change request document:
   * The name of the event. The event name is used by Adobe Launch to trigger
   * data collection.
   */
  event: ScaniaAdobeEventId.PageView

  /**
   * Information about the current page.
   */
  pageInfo: ScaniaAdobeTrackingPageInfo

  /**
   * Information for the user click that initiated the page navigation (previous
   * page/view).
   */
  flowInfo: ScaniaAdobeTrackingFlowInfo | null
}

export enum ScaniaAdobeTrackingFlowName {
  FactoryModels = 'factory model',
  GuidedOffering = 'build your own truck',
}

export interface ScaniaAdobeTrackingFlowInfo {
  /**
   * Factory model Id, if this selection was a factory model click.
   */
  factoryModelSelected: number | null

  /**
   * Intended to help the tracking team know if the ScaniaAdobeTrackingFlowInfo
   * instance originated from Factory Models or Guided Offering.
   */
  flowName: ScaniaAdobeTrackingFlowName

  /**
   * Can be an Etel Variant code or a special string like "start" for the
   * landing page start button. Can be null when jumping from GO to FM.
   */
  optionSelected: string | null

  /**
   * The name of the Guided Offering "step", can be "start" for the landing
   * page or the Etel Family description for Guided Offering views/pages.
   */
  stepName: string

  /**
   * Not null when the user has pressed either the "skip to next button" or the
   * "quick start button".
   */
  stepSkipped: string | null
}

export const ADOBE_TRACKING_GO_FLOW_OPTION_SELECTED_START_BUTTON = 'start'

export interface ScaniaAdobeTrackingPageInfo {
  /**
   * From the tracking change request document:
   * The name of the page. For example, ‘configure’ and ‘summary’.
   */
  pageName: string

  /**
   * From the tracking change request document:
   * Country code. If the market code is available. For example, ‘uk’. If you
   * want to break down the analytics by market this is needed.
   */
  market: string

  /**
   * From the tracking change request document:
   * Website language.
   */
  language: string

  /**
   * From the tracking change request document:
   * Full url of the page.
   */
  pageURL: string

  /**
   * website domain
   */
  domain: string
}

/**
 * All possible Adobe Analytics tracking event types for this application.
 *
 * This approach increases code size compared to a more dynamic approach, but
 * makes it very easy to find all event dispatch sites and all possible event
 * object shapes (except for the Mopinion related event structures that are
 * provided by Mopinion code that we need to treat as the `any` type).
 */
export type ScaniaAdobeTrackingEvent =
  | ScaniaAdobeTrackingClickActiveChangesEvent
  | ScaniaAdobeTrackingClickBmReadMoreEvent
  | ScaniaAdobeTrackingClickBodyBuildToggleEvent
  | ScaniaAdobeTrackingClickEysEvent
  | ScaniaAdobeTrackingClickEysSendChangesEvent
  | ScaniaAdobeTrackingClickFmModelFilterEvent
  | ScaniaAdobeTrackingClickFmReadMoreEvent
  | ScaniaAdobeTrackingClickFmShowAllEvent
  | ScaniaAdobeTrackingClickGoReadMoreEvent
  | ScaniaAdobeTrackingClickMinisummaryColorEvent
  | ScaniaAdobeTrackingClickMinisummaryMotorEvent
  | ScaniaAdobeTrackingClickPanoramaEvent
  | ScaniaAdobeTrackingClickRfqCtaEvent
  | ScaniaAdobeTrackingClickSaveAsGarageEvent
  | ScaniaAdobeTrackingClickSaveGarageEvent
  | ScaniaAdobeTrackingClickSmartDashCampaignEvent
  | ScaniaAdobeTrackingClickSuperEvent
  | ScaniaAdobeTrackingClickUpgradeYourTruckEvent
  | ScaniaAdobeTrackingConfigChangedEvent
  | ScaniaAdobeTrackingDownloadImageEvent
  | ScaniaAdobeTrackingDownloadPdfEvent
  | ScaniaAdobeTrackingDownloadQrEvent
  | ScaniaAdobeTrackingDownloadXmlEvent
  | ScaniaAdobeTrackingGoOptionalsInput
  | ScaniaAdobeTrackingLoginSuccessEvent
  | ScaniaAdobeTrackingMopinionFeedbackSent
  | ScaniaAdobeTrackingMopinionHidden
  | ScaniaAdobeTrackingMopinionNext
  | ScaniaAdobeTrackingMopinionReady
  | ScaniaAdobeTrackingMopinionRedirect
  | ScaniaAdobeTrackingMopinionShown
  | ScaniaAdobeTrackingMopinionWillHide
  | ScaniaAdobeTrackingMopinionWillShow
  | ScaniaAdobeTrackingPageViewEvent
  | ScaniaAdobeTrackingRegistrationFormOpen
  | ScaniaAdobeTrackingRegistrationFormSubmit
  | ScaniaAdobeTrackingRfqFormSubmitEvent
  | ScaniaAdobeTrackingSearchResultClick
  | ScaniaAdobeTrackingShareEmailEvent
  | ScaniaAdobeTrackingShareFacebookEvent
  | ScaniaAdobeTrackingShareLinkEvent
  | ScaniaAdobeTrackingShareWhatsAppEvent
  | ScaniaAdobeTrackingUploadXmlEvent

export function pushAdobeEvent(event: ScaniaAdobeTrackingEvent): void {
  window.adobeDataLayer.push(event)
  if (!window.initializeAdobeAnalytics) {
    console.log('Adobe analytics not found. Event: ', event)
  }
}
