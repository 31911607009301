export interface EtelVariant {
  family: string
  execution: string
}

/**
 * Split the internal configurator id into Etel Family and Execution.
 */
export function toEtelVariant(id: string): EtelVariant {
  const arr = id.split('~')
  if (arr.length !== 2) {
    throw new Error('Failed to parse internal Etel Variant string: ' + id)
  }
  return {
    family: arr[0],
    execution: arr[1],
  }
}

export function toId(v: EtelVariant) {
  return v.family + '~' + v.execution
}
