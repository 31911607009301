/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ConfigInfoImage } from './ConfigInfoImage'
import {
  ConfigInfoImageFromJSON,
  ConfigInfoImageFromJSONTyped,
  ConfigInfoImageToJSON,
} from './ConfigInfoImage'

/**
 *
 * @export
 * @interface UserConfigInfo
 */
export interface UserConfigInfo {
  /**
   *
   * @type {number}
   * @memberof UserConfigInfo
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof UserConfigInfo
   */
  name: string
  /**
   * Unix time, UTC.
   * TODO: Change to ISO 8601.
   * @type {number}
   * @memberof UserConfigInfo
   */
  timestamp: number
  /**
   *
   * @type {string}
   * @memberof UserConfigInfo
   */
  description: string
  /**
   *
   * @type {Array<ConfigInfoImage>}
   * @memberof UserConfigInfo
   */
  images: Array<ConfigInfoImage>
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof UserConfigInfo
   */
  userConfigAux?: { [key: string]: string } | null
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof UserConfigInfo
   */
  configStorageAux?: { [key: string]: string } | null
}

/**
 * Check if a given object implements the UserConfigInfo interface.
 */
export function instanceOfUserConfigInfo(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'name' in value
  isInstance = isInstance && 'timestamp' in value
  isInstance = isInstance && 'description' in value
  isInstance = isInstance && 'images' in value

  return isInstance
}

export function UserConfigInfoFromJSON(json: any): UserConfigInfo {
  return UserConfigInfoFromJSONTyped(json, false)
}

export function UserConfigInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserConfigInfo {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['Id'],
    name: json['Name'],
    timestamp: json['Timestamp'],
    description: json['Description'],
    images: (json['Images'] as Array<any>).map(ConfigInfoImageFromJSON),
    userConfigAux: !exists(json, 'UserConfigAux')
      ? undefined
      : json['UserConfigAux'],
    configStorageAux: !exists(json, 'ConfigStorageAux')
      ? undefined
      : json['ConfigStorageAux'],
  }
}

export function UserConfigInfoToJSON(value?: UserConfigInfo | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    Id: value.id,
    Name: value.name,
    Timestamp: value.timestamp,
    Description: value.description,
    Images: (value.images as Array<any>).map(ConfigInfoImageToJSON),
    UserConfigAux: value.userConfigAux,
    ConfigStorageAux: value.configStorageAux,
  }
}
