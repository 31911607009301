import styled from 'styled-components'
import { useCallback, useEffect, useState } from 'react'
import useTexts from '../../utils/useTexts'
import { useClient } from '../../utils/useClient'
import { SESSION_FAILURE } from '../../api/errors'
import { UserConfigInfo } from '../../api/generated'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { TestElementTypeId } from '../../types/TestAttributeId'
import {
  selectSidePanelState,
  setPanelLoginGarageContent,
} from '../../store/sidePanelSlice'
import { SidePanelLoginGarageContent } from './SidePanelLoginAndGarage'
import {
  deleteTruckFromGarageList,
  getGarageListState,
  getSessionIdState,
  loggedInStatus,
} from '../../store/sessionDataSlice'
import { TdsButton } from '@scania/tegel-react'

const LoginRoot = styled.div``

const TabContentContainer = styled.div`
  margin: 16px;
`

const Header = styled.h4``

const TextWrapper = styled.div``

const MessageWrapper = styled.div`
  margin-top: 16px;
  color: red;
`

const Divider = styled.div`
  border-bottom: 1.5px solid var(--tds-grey-300);
  margin-top: 24px;
  margin-bottom: 24px;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;

  button {
    min-width: 180px;
    justify-content: center;
  }
`

const ConfirmViewText = styled.div`
  margin-top: 16px;
`

const InputContainer = styled.div`
  margin: 16px;
`

interface AccountViewProps {
  handleFatalError: () => void
  handleSessionFailure: () => void
  handleTruckHasBeenDeleted: (truck: UserConfigInfo) => void
}

export function AccountView({
  handleFatalError,
  handleSessionFailure,
  handleTruckHasBeenDeleted,
}: AccountViewProps): JSX.Element {
  const dispatch = useAppDispatch()
  const t = useTexts()
  const sessionId = useAppSelector(getSessionIdState)
  const garageList = useAppSelector(getGarageListState)
  const client = useClient()
  const currentView = useAppSelector(selectSidePanelState).loginGarageContent
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [messageDeleteAllTrucks, setMessageDeleteAllTrucks] =
    useState<string>('')

  const handleDeleteAccountClick = () => {
    dispatch(
      setPanelLoginGarageContent(SidePanelLoginGarageContent.deleteAccount),
    )
  }

  const handleLogout = useCallback(async () => {
    if (!sessionId) {
      throw new Error('Expected sessionId to be defined.')
    }
    const response = await client?.logout(sessionId)
    if (response?.error === SESSION_FAILURE) {
      handleSessionFailure()
      return
    } else if (response?.error) {
      handleFatalError()
      return
    }
    dispatch(loggedInStatus({ isloggedin: false, userMail: '' }))
  }, [dispatch, client, sessionId, handleFatalError, handleSessionFailure])

  // TODO - display error and confirmation message
  const handleDeleteAccount = useCallback(async () => {
    if (!sessionId) {
      throw new Error('Expected sessionId to be defined.')
    }
    try {
      await client?.sendDeleteUserMail(sessionId)
    } catch (err) {
      setErrorMessage(
        'An error occurred while trying to remove your account. Try again later.',
      ) // TODO - text in sc admin
      console.error(err)
      return
    }
    handleLogout()
  }, [client, handleLogout, sessionId])

  const handleDeleteAllTrucks = useCallback(async () => {
    if (!sessionId) {
      throw new Error('Expected sessionId to be defined.')
    }
    if (!client) {
      throw new Error('Expected client to be defined.')
    }
    if (!garageList || garageList.length === 0) {
      //setMessageDeleteAllTrucks('No saved truck configurations.')
      return
    }
    for (const truck of garageList) {
      await client.userConfigV2Delete(sessionId, truck.id)
      dispatch(deleteTruckFromGarageList(truck.id))
      setMessageDeleteAllTrucks(t('ACCOUNT_ALL_TRUCKS_DELETED'))
      handleTruckHasBeenDeleted(truck)
    }
  }, [client, dispatch, garageList, handleTruckHasBeenDeleted, sessionId, t])

  useEffect(() => {
    setErrorMessage('')
    setMessageDeleteAllTrucks('')
  }, [])

  if (currentView === SidePanelLoginGarageContent.account) {
    return (
      <LoginRoot>
        <TabContentContainer>
          <Header>{t('HEADER_MANAGE_ACCOUNT')}</Header>
          <TextWrapper>{errorMessage}</TextWrapper>
          <Divider></Divider>
          <TextWrapper>{t('ACCOUNT_TEXT_LOGOUT')}</TextWrapper>
          <ButtonContainer>
            <TdsButton
              variant="primary"
              size="md"
              text={t('LABEL_ACTION_LOGOUT')}
              onClick={handleLogout}
              data-test-element-type={
                TestElementTypeId.GarageViewAccountViewSignOutButton
              }
            />
          </ButtonContainer>
          <Divider></Divider>
          <TextWrapper>{t('TEXT_MANAGE_ACCOUNT')}</TextWrapper>
          <ButtonContainer>
            <TdsButton
              variant="danger"
              size="md"
              text={t('ACTION_LABEL_DELETE_ACCOUNT')}
              onClick={handleDeleteAccountClick}
              data-test-element-type={
                TestElementTypeId.GarageViewAccountViewGotoDeleteAccountFormButton
              }
            />
          </ButtonContainer>
          <Divider></Divider>
          <TextWrapper>{t('ACCOUNT_DELETE_ALL_TRUCKS')}</TextWrapper>
          <MessageWrapper>{messageDeleteAllTrucks}</MessageWrapper>
          <ButtonContainer>
            <TdsButton
              variant="danger"
              size="md"
              text={t('ACTION_LABEL_DELETE')}
              onClick={handleDeleteAllTrucks}
              disabled={!garageList || garageList.length === 0}
            />
          </ButtonContainer>
        </TabContentContainer>
      </LoginRoot>
    )
  }

  if (currentView === SidePanelLoginGarageContent.deleteAccount) {
    return (
      <LoginRoot>
        <InputContainer>
          <Header>{t('ACTION_LABEL_DELETE_ACCOUNT')}</Header>
          <ConfirmViewText>{t('ACCOUNT_DELETE_MESSAGE')}</ConfirmViewText>
        </InputContainer>
        <ButtonContainer style={{ marginTop: '24px' }}>
          <TdsButton
            variant="primary"
            size="md"
            onClick={() =>
              dispatch(
                setPanelLoginGarageContent(SidePanelLoginGarageContent.account),
              )
            }
            text={t('LABEL_ACTION_CANCEL')}
          />
        </ButtonContainer>
        <ButtonContainer style={{ marginTop: '24px' }}>
          <TdsButton
            variant="danger"
            size="md"
            text={t('ACTION_LABEL_DELETE_ACCOUNT')}
            onClick={handleDeleteAccount}
            data-test-element-type={
              TestElementTypeId.GarageViewAccountViewDeleteAccountSubmitButton
            }
          />
        </ButtonContainer>
      </LoginRoot>
    )
  } else {
    return <></>
  }
}
