/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Request For Quotation mode.
 * @export
 */
export const RfqMode = {
  Normal: 'Normal',
  Demo: 'Demo',
} as const
export type RfqMode = (typeof RfqMode)[keyof typeof RfqMode]

export function RfqModeFromJSON(json: any): RfqMode {
  return RfqModeFromJSONTyped(json, false)
}

export function RfqModeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): RfqMode {
  return json as RfqMode
}

export function RfqModeToJSON(value?: RfqMode | null): any {
  return value as any
}
