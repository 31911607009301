import useTexts from '../../utils/useTexts'
import { useAppSelector } from '../../store/hooks'
import { getMarketSettingsState } from '../../store/appSlice'
import { getStartupData } from '../../store/sessionDataSlice'

export interface RfqEysButtonState {
  hidden: boolean
  buttonText: string
}

// The same button is used for Explore Your Scania and for Request a quote.
export function useRfqEysButtonState(): RfqEysButtonState {
  const marketSettings = useAppSelector(getMarketSettingsState)
  const t = useTexts()
  const startupData = useAppSelector(getStartupData)
  const exploreYourScaniaMode = startupData?.exploreYourScaniaMode || false

  const buttonText = exploreYourScaniaMode
    ? t('EYS_SEND_CHANGES_BUTTON')
    : t('LABEL_ACTION_REQUEST_QUOTE')

  let hidden = false
  if (!exploreYourScaniaMode) {
    if (marketSettings?.rfqEnabled === false) {
      hidden = true
    }
  }

  return { hidden, buttonText }
}
