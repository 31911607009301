import { GO_LOW_HEIGHT_LIMIT_ADAPTION_OPERATION_NUMBER } from '../constants'
import { BreakpointWidthPx } from '../css/BreakpointWidthPx'

export function isPhoneOrSmaller() {
  return document.documentElement.clientWidth < BreakpointWidthPx.Phone
}

export function isTabletOrSmaller() {
  return document.documentElement.clientWidth < BreakpointWidthPx.Tablet
}

export function isLowHeightDesktopGo() {
  return (
    document.documentElement.clientWidth > BreakpointWidthPx.Tablet &&
    document.documentElement.clientHeight <
      Number(GO_LOW_HEIGHT_LIMIT_ADAPTION_OPERATION_NUMBER)
  )
}
