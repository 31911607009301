import styled from 'styled-components'
import useTexts from '../../utils/useTexts'
import { TestElementTypeId } from '../../types/TestAttributeId'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { getMarketSettingsState } from '../../store/appSlice'
import { getTruckInfoState } from '../../store/truckInfoSlice'
import { useCallback } from 'react'
import {
  openModalSaveGarage,
  setModalSaveImageState,
  setModalSavePdfState,
  setModalSaveQrState,
  setModalSaveXmlState,
} from '../../store/modalSlice'
import { ScaniaAdobeTrackingButtonPlacement } from '../../utils/adobeAnalytics'
import { getSaveEnabled, getSeriesFrame } from '../../store/sessionDataSlice'

const SaveMenuRoot = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
`

const SaveWrapper = styled.div``

const SaveItemWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 24px;
  font-size: 12px;
  font-weight: bold;
  margin-left: 32px;
  color: var(--tds-grey-900);

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: var(--tds-grey-50);
      cursor: pointer;
    }
  }
`

export interface SaveMenuProps {
  handleSaveAsGarageClick: (e: React.MouseEvent) => void
  source: ScaniaAdobeTrackingButtonPlacement
}

export const SaveMenu = ({
  handleSaveAsGarageClick,
  source,
}: SaveMenuProps): JSX.Element => {
  const t = useTexts()
  const dispatch = useAppDispatch()
  const marketSettings = useAppSelector(getMarketSettingsState)
  const truckInfo = useAppSelector(getTruckInfoState)
  const showSaveAs = !truckInfo?.isFactoryModel && marketSettings?.garageEnabled
  const saveEnabled = useAppSelector(getSaveEnabled)
  const showSave = showSaveAs && saveEnabled
  const { seriesId: imageSeries, frameId: imageFrame } =
    useAppSelector(getSeriesFrame)

  const handleSaveGarageClick = useCallback(() => {
    dispatch(openModalSaveGarage())
  }, [dispatch])

  const handleSavePdfClick = () => {
    dispatch(setModalSavePdfState({ source }))
  }

  const handleSaveXmlClick = () => {
    dispatch(setModalSaveXmlState({ source }))
  }

  const handleSaveImageClick = () => {
    dispatch(
      setModalSaveImageState({
        imageSeries,
        imageFrame,
        source,
      }),
    )
  }

  const handleSaveQrClick = () => {
    dispatch(setModalSaveQrState({ source }))
  }

  return (
    <SaveMenuRoot data-name="SaveMenuRoot">
      <SaveWrapper>
        {showSaveAs && (
          <SaveItemWrapper
            data-test-element-type={
              TestElementTypeId.ConfigurationMenuSaveAsButton
            }
            onClick={(e) => handleSaveAsGarageClick(e)}
          >
            {t('LABEL_ACTION_SAVE_AS')}
          </SaveItemWrapper>
        )}
        {showSave && (
          <SaveItemWrapper
            data-test-element-type={
              TestElementTypeId.ConfigurationMenuSaveButton
            }
            onClick={() => handleSaveGarageClick()}
          >
            {t('LABEL_ACTION_SAVE')}
          </SaveItemWrapper>
        )}
        <SaveItemWrapper
          data-test-element-type={
            TestElementTypeId.ConfigurationMenuSavePdfButton
          }
          onClick={handleSavePdfClick}
        >
          {t('LABEL_ACTION_PDF')}
        </SaveItemWrapper>
        <SaveItemWrapper
          data-test-element-type={
            TestElementTypeId.ConfigurationMenuSaveImageButton
          }
          onClick={handleSaveImageClick}
        >
          {t('LABEL_ACTION_IMAGE')}
        </SaveItemWrapper>
        <SaveItemWrapper
          data-test-element-type={
            TestElementTypeId.ConfigurationMenuSaveQrButton
          }
          onClick={handleSaveQrClick}
        >
          QR
        </SaveItemWrapper>
        <SaveItemWrapper
          data-test-element-type={
            TestElementTypeId.ConfigurationMenuSaveXmlButton
          }
          onClick={handleSaveXmlClick}
        >
          {t('LABEL_ACTION_SAVE_XML')}
        </SaveItemWrapper>
      </SaveWrapper>
    </SaveMenuRoot>
  )
}
