import { GuidedOfferingClientState } from '../types/GuidedOffering'

export function getDefaultOrFirstId(
  goClientState: GuidedOfferingClientState | null,
): string {
  const cards = goClientState?.cards
  if (!cards) {
    throw new Error('Expected cards to be defined.')
  }
  const first = cards[0]
  if (!first) {
    throw new Error('Expected cards to contain elements.')
  }
  const nextSelection = cards.find((c) => c.defaultSelected) || first
  return nextSelection.id
}
