import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import useTexts from '../../utils/useTexts'
import { ConfigChangeMenuEntry } from '../../api/generated'
import { useAppSelector } from '../../store/hooks'
import { getMenuState } from '../../store/menuSlice'
import {
  TdsBodyCell,
  TdsButton,
  TdsHeaderCell,
  TdsModal,
  TdsTable,
  TdsTableBody,
  TdsTableBodyRow,
  TdsTableHeader,
} from '@scania/tegel-react'
import {
  ConsequeceOfChangeUserChoice,
  ConsequenceOfChangeDialogData,
} from '../../types/ConsequenceOfChangeTypes'
import { isTabletOrSmaller } from '../../utils/screenQuery'
import React from 'react'
import { TestElementTypeId } from '../../types/TestAttributeId'

const AddedRemovedWrapper = styled.div`
  border-left: 3px solid var(--tds-information);
  padding-left: 24px;
  margin-bottom: 24px;
`

const OptionWrapper = styled.div``

const AddedRemovedHeader = styled.div`
  font-weight: bold;
`

const AddedRemovedText = styled.div`
  color: grey;
`

const MobileTableDivider = styled(TdsTableBodyRow)`
  background-color: var(--tds-grey-200);
`

interface TablePresentation {
  parentGroup: string
  guiGroup: string
  addedText: string
  removedText: string
}

interface GroupedTablePresentations {
  [key: string]: TablePresentation[]
}

interface ModalConsequenceOfChangeProps {
  data: ConsequenceOfChangeDialogData
  handleChoice: (choice: ConsequeceOfChangeUserChoice) => void
}

export function ModalConsequenceOfChange({
  data,
  handleChoice,
}: ModalConsequenceOfChangeProps): JSX.Element {
  const t = useTexts()
  const menuTexts = useAppSelector(getMenuState).menuTexts
  const [tableContent, setTableContent] =
    useState<GroupedTablePresentations | null>(null)
  const [clickedAddedId, setClickedAddedId] = useState<
    string | undefined | null
  >(null)
  const [clickedRemovedId, setClickedRemovedId] = useState<
    string | undefined | null
  >(null)
  const [clickedGuiGroup, setClickedGuiGroup] = useState<
    string | undefined | null
  >(null)

  const handleCancel = useCallback(() => {
    handleChoice('CANCEL')
  }, [handleChoice])

  const handleOk = useCallback(() => {
    handleChoice('OK')
  }, [handleChoice])

  // Get content to coc-table
  useEffect(() => {
    if (!menuTexts) {
      // Not initialized yet.
      console.warn('Expected menuTexts to be defined.')
      return
    }
    const getTexts = (item: ConfigChangeMenuEntry) => {
      const group = menuTexts[item.guiGroup] || item.guiGroup
      const pathArray = item.menuPath.split('/')
      // The first two items are empty. If not a full path is found, return "Unknown"
      const parentGroupId = pathArray.length > 5 ? pathArray[2] : 'Unknown'

      const parentGroup = menuTexts[parentGroupId] || parentGroupId
      const text = item.shortText || item.id.replace('~', '')
      return { group: group, parentGroup: parentGroup, text, source: item }
    }

    const added = data.resolvers.triggerItems
      .filter((i) => i.id !== 'D~MISSING-ITEM') // TODO: Fix this properly in scds_backend.
      .map(getTexts)
    const removed = data.resolvers.deactivatedItems
      .filter((i) => i.id !== 'D~MISSING-ITEM') // TODO: Fix this properly in scds_backend.
      .map(getTexts)

    const clickedGuiGroupId = added.find(
      (item) => item.source.id === data.clickedId,
    )?.source.guiGroup
    const clickedAddedId = added.find(
      (item) => item.source.guiGroup === clickedGuiGroupId,
    )?.text
    const clickedRemovedId = removed.find(
      (item) => item.source.guiGroup === clickedGuiGroupId,
    )?.text
    const clickedGuiGroup = added.find(
      (item) => item.source.id === data.clickedId,
    )?.group

    const tablePresentations: TablePresentation[] = []
    added.forEach((item) => {
      const tableItem: TablePresentation = {
        guiGroup: item.group,
        parentGroup: item.parentGroup,
        addedText: item.text,
        removedText: '',
      }
      if (tableItem.guiGroup !== clickedGuiGroup) {
        tablePresentations.push(tableItem)
      }
    })
    removed.forEach((item) => {
      const existingTableItemIndex = tablePresentations.findIndex(
        (tableItem) => tableItem.guiGroup === item.group,
      )
      if (existingTableItemIndex !== -1) {
        tablePresentations[existingTableItemIndex].removedText = item.text
      } else {
        const tableItem: TablePresentation = {
          guiGroup: item.group,
          parentGroup: item.parentGroup,
          addedText: '',
          removedText: item.text,
        }
        if (tableItem.guiGroup !== clickedGuiGroup) {
          tablePresentations.push(tableItem)
        }
      }
    })
    const groupedTableContent = tablePresentations.reduce(
      (groupedResult: GroupedTablePresentations, item: TablePresentation) => {
        ;(groupedResult[item['parentGroup']] =
          groupedResult[item['parentGroup']] || []).push(item)
        return groupedResult
      },
      {},
    )
    setTableContent(groupedTableContent)
    setClickedAddedId(clickedAddedId)
    setClickedRemovedId(clickedRemovedId)
    setClickedGuiGroup(clickedGuiGroup)
  }, [data, menuTexts])

  // Eventlistener to close modal on tdsClose (click outside modal and click on close cross)
  useEffect(() => {
    document.addEventListener('tdsClose', handleCancel)
    return () => {
      document.removeEventListener('tdsClose', handleCancel)
    }
  }, [handleCancel])

  return (
    <TdsModal
      size="md"
      header={
        data.showCancelButton ? t('HEADER_COC') : t('HEADER_COC_LOADED_TRUCK')
      }
      show
      actions-position="sticky"
      prevent={data.showCancelButton ? false : true}
    >
      <span slot="body">
        {tableContent && (
          <>
            <p style={{ marginBottom: '24px' }}>
              {data.showCancelButton
                ? t('DESCRIPTION_COC')
                : t('DESCRIPTION_COC_LOADED_TRUCK')}
            </p>
            {data.showCancelButton && (
              <AddedRemovedWrapper>
                <OptionWrapper style={{ marginBottom: '16px' }}>
                  <AddedRemovedHeader>
                    {t('HEADER_COC_ADDED')}
                  </AddedRemovedHeader>
                  <AddedRemovedText>
                    {clickedGuiGroup + ' - ' + clickedAddedId}
                  </AddedRemovedText>
                </OptionWrapper>
                <OptionWrapper>
                  <AddedRemovedHeader>
                    {t('HEADER_COC_REMOVED')}
                  </AddedRemovedHeader>
                  <AddedRemovedText>
                    {clickedGuiGroup + ' - ' + clickedRemovedId}
                  </AddedRemovedText>
                </OptionWrapper>
              </AddedRemovedWrapper>
            )}
            {data.showCancelButton && (
              <h5 style={{ marginBottom: '24px' }}>
                {t('HEADER_COC_CHANGES')}
              </h5>
            )}

            <TdsTable
              verticalDividers
              responsive
              noMinWidth
              style={{ marginBottom: '32px' }}
            >
              <TdsTableHeader>
                {!isTabletOrSmaller() && (
                  <TdsHeaderCell cellKey="ParentGroup" cellValue="" />
                )}

                <TdsHeaderCell cellKey="Option" cellValue="Option" />
                <TdsHeaderCell cellKey="Added" cellValue="Added" />
                <TdsHeaderCell cellKey="Removed" cellValue="Removed" />
              </TdsTableHeader>
              <TdsTableBody>
                {Object.keys(tableContent)?.map((parentGroup, index) => (
                  <React.Fragment key={index}>
                    {isTabletOrSmaller() && (
                      <MobileTableDivider key={parentGroup + index}>
                        <TdsBodyCell
                          cellKey="ParentGroup"
                          cellValue={parentGroup}
                          disablePadding
                          style={{ padding: '8px', fontWeight: 'bold' }}
                        />
                        <TdsBodyCell
                          cellKey="Dummy2"
                          cellValue=""
                          disablePadding
                        />
                        <TdsBodyCell
                          cellKey="Dummy3"
                          cellValue=""
                          disablePadding
                        />
                      </MobileTableDivider>
                    )}
                    {tableContent[parentGroup].map((item, i) => (
                      <TdsTableBodyRow key={item.guiGroup + i}>
                        {!isTabletOrSmaller() && (
                          <TdsBodyCell
                            cellKey="ParentGroup"
                            cellValue={i === 0 ? item.parentGroup : ''}
                            style={{ fontWeight: 'bold' }}
                          />
                        )}
                        <TdsBodyCell
                          cellKey="Option"
                          cellValue={item.guiGroup}
                        />

                        <TdsBodyCell
                          cellKey="Added"
                          cellValue={item.addedText}
                        />
                        <TdsBodyCell
                          cellKey="Removed"
                          cellValue={item.removedText}
                        />
                      </TdsTableBodyRow>
                    ))}
                  </React.Fragment>
                ))}
              </TdsTableBody>
            </TdsTable>
          </>
        )}
      </span>

      <span slot="actions" className="tds-u-flex tds-u-gap2 tds-u-flex-wrap">
        <TdsButton
          size="md"
          onClick={handleOk}
          text={t('ACTION_LABEL_ACCEPT')}
          data-test-element-type={
            TestElementTypeId.ConsequenceOfChangeAcceptButton
          }
        />
        {data?.showCancelButton && (
          <TdsButton
            variant="secondary"
            size="md"
            onClick={handleCancel}
            text={t('LABEL_ACTION_CANCEL')}
          />
        )}
      </span>
    </TdsModal>
  )
}
