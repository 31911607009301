/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ConfigChangeResult } from './ConfigChangeResult'
import {
  ConfigChangeResultFromJSON,
  ConfigChangeResultFromJSONTyped,
  ConfigChangeResultToJSON,
} from './ConfigChangeResult'

/**
 *
 * @export
 * @interface ConfigChangeResponseWrapper
 */
export interface ConfigChangeResponseWrapper {
  /**
   *
   * @type {ConfigChangeResult}
   * @memberof ConfigChangeResponseWrapper
   */
  success?: ConfigChangeResult | null
  /**
   *
   * @type {string}
   * @memberof ConfigChangeResponseWrapper
   */
  error?: string | null
}

/**
 * Check if a given object implements the ConfigChangeResponseWrapper interface.
 */
export function instanceOfConfigChangeResponseWrapper(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function ConfigChangeResponseWrapperFromJSON(
  json: any,
): ConfigChangeResponseWrapper {
  return ConfigChangeResponseWrapperFromJSONTyped(json, false)
}

export function ConfigChangeResponseWrapperFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ConfigChangeResponseWrapper {
  if (json === undefined || json === null) {
    return json
  }
  return {
    success: !exists(json, 'success')
      ? undefined
      : ConfigChangeResultFromJSON(json['success']),
    error: !exists(json, 'error') ? undefined : json['error'],
  }
}

export function ConfigChangeResponseWrapperToJSON(
  value?: ConfigChangeResponseWrapper | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    success: ConfigChangeResultToJSON(value.success),
    error: value.error,
  }
}
