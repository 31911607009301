import styled from 'styled-components'
import { SvgSave, SvgShare } from '../../components/SvgImports'
import { useAppSelector } from '../../store/hooks'
import { getMarketSettingsState } from '../../store/appSlice'

const IconsContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
`

const IconBox = styled.div`
  height: 36px;
  width: 36px;
  display: flex;
  cursor: pointer;

  svg {
    height: 20px;
    width: 20px;
    margin: auto;
  }
`

interface ControlIconsProps {
  handleSaveClick: () => void
  handleShareClick: () => void
}

export function ControlIcons(props: ControlIconsProps): JSX.Element {
  const marketSettings = useAppSelector(getMarketSettingsState)
  const showShareIcon =
    marketSettings?.shareEmailEnabled ||
    marketSettings?.shareFacebookEnabled ||
    marketSettings?.shareLinkEnabled ||
    marketSettings?.shareWhatsappEnabled

  return (
    <IconsContainer>
      <IconBox
        onClick={(ev) => {
          ev.stopPropagation()
          props.handleSaveClick()
        }}
      >
        <SvgSave />
      </IconBox>
      {showShareIcon && (
        <IconBox
          onClick={(ev) => {
            ev.stopPropagation()
            props.handleShareClick()
          }}
        >
          <SvgShare />
        </IconBox>
      )}
    </IconsContainer>
  )
}
