/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * Legacy response wrapper structure to allow matching the original
 * configurator web API.
 *
 * TODO: This should be removed after the .Net Core and ScdsClient
 * migration has been completed.
 * @export
 * @interface GetCurrentConfigImageResponseWrapper
 */
export interface GetCurrentConfigImageResponseWrapper {
  /**
   *
   * @type {string}
   * @memberof GetCurrentConfigImageResponseWrapper
   */
  getCurrentConfigImage?: string | null
  /**
   *
   * @type {string}
   * @memberof GetCurrentConfigImageResponseWrapper
   */
  error?: string | null
}

/**
 * Check if a given object implements the GetCurrentConfigImageResponseWrapper interface.
 */
export function instanceOfGetCurrentConfigImageResponseWrapper(
  value: object,
): boolean {
  let isInstance = true

  return isInstance
}

export function GetCurrentConfigImageResponseWrapperFromJSON(
  json: any,
): GetCurrentConfigImageResponseWrapper {
  return GetCurrentConfigImageResponseWrapperFromJSONTyped(json, false)
}

export function GetCurrentConfigImageResponseWrapperFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GetCurrentConfigImageResponseWrapper {
  if (json === undefined || json === null) {
    return json
  }
  return {
    getCurrentConfigImage: !exists(json, 'get_current_config_image')
      ? undefined
      : json['get_current_config_image'],
    error: !exists(json, 'error') ? undefined : json['error'],
  }
}

export function GetCurrentConfigImageResponseWrapperToJSON(
  value?: GetCurrentConfigImageResponseWrapper | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    get_current_config_image: value.getCurrentConfigImage,
    error: value.error,
  }
}
