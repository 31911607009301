import { useEffect, useState } from 'react'
import { buildClient } from '../api/clientStartupUtils'
import { ScaniaConfiguratorApi } from '../api/generated'
import { useAppSelector } from '../store/hooks'
import { getStartupData } from '../store/sessionDataSlice'

export const useClient = () => {
  const startupData = useAppSelector(getStartupData)
  const [client, setClient] = useState<ScaniaConfiguratorApi | null>(null)

  useEffect(() => {
    if (!startupData) {
      return // Not initialized yet.
    }
    const newClient = buildClient(startupData.backendUrl)
    setClient(newClient)
  }, [startupData])

  return client
}
