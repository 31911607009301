import { useCallback } from 'react'
import { buildRelativeLandingPageUrl } from '../../utils/UrlBuilders'
import useTexts from '../../utils/useTexts'
import { useAppSelector } from '../../store/hooks'
import { getUrlParametersToString } from '../../utils/getUrlParametersToString'
import { getMarketLanguageState } from '../../store/appSlice'
import { getModalState } from '../../store/modalSlice'
import { TdsButton, TdsModal } from '@scania/tegel-react'

export function ModalError(): JSX.Element {
  const marketLanguage = useAppSelector(getMarketLanguageState)
  const t = useTexts()
  const errorMessage = useAppSelector(getModalState).errorModalState

  const handleRestartClick = useCallback(() => {
    if (!marketLanguage) {
      return
    }
    const relativeUrl = buildRelativeLandingPageUrl(marketLanguage)
    const params = relativeUrl.params
    document.location.href = relativeUrl.path + getUrlParametersToString(params)
  }, [marketLanguage])

  return (
    <TdsModal
      size="md"
      header={t('HEADER_ERROR_NOTIFICATION')}
      show
      prevent={true}
    >
      <span slot="body">
        <div>{errorMessage || ''}</div>
      </span>
      <span slot="actions" className="tds-u-flex tds-u-gap2 tds-u-flex-wrap">
        <TdsButton
          size="md"
          text={t('LABEL_ACTION_RELOAD_APP')}
          onClick={handleRestartClick}
        />
      </span>
    </TdsModal>
  )
}
