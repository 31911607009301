import styled from 'styled-components'
import { TestElementTypeId } from '../../types/TestAttributeId'
import useTexts from '../../utils/useTexts'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { getMarketSettingsState } from '../../store/appSlice'
import {
  ScaniaAdobeEventId,
  ScaniaAdobeShareOption,
  ScaniaAdobeTrackingButtonPlacement,
  ScaniaAdobeTrackingShareLinkEvent,
  pushAdobeEvent,
} from '../../utils/adobeAnalytics'
import {
  openModalShareLink,
  setModalShareEmailState,
  setModalShareFacebookState,
  setModalShareWhatsAppState,
} from '../../store/modalSlice'

const ShareMenuRoot = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
`

const ShareItem = styled.div`
  display: flex;
  align-items: center;
  height: 24px;
  font-size: 12px;
  font-weight: bold;
  margin-left: 32px;
  color: var(--tds-grey-900);

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: var(--tds-grey-50);
      cursor: pointer;
    }
  }
`

export interface ShareMenuProps {
  source: ScaniaAdobeTrackingButtonPlacement
}

export const ShareMenu = ({ source }: ShareMenuProps): JSX.Element => {
  const t = useTexts()
  const marketSettings = useAppSelector(getMarketSettingsState)
  const dispatch = useAppDispatch()

  const handleShareEmailClick = () => {
    dispatch(setModalShareEmailState({ source }))
  }

  const handleShareFacebookClick = () => {
    dispatch(setModalShareFacebookState({ source }))
  }

  const handleShareWhatsAppClick = () => {
    dispatch(setModalShareWhatsAppState({ source }))
  }

  const handleShareLinkClick = () => {
    const trackingEvent: ScaniaAdobeTrackingShareLinkEvent = {
      event: ScaniaAdobeEventId.Share,
      eventInfo: {
        shareOption: ScaniaAdobeShareOption.CopyLink,
        placement: source,
      },
    }
    pushAdobeEvent(trackingEvent)
    dispatch(openModalShareLink())
  }

  return (
    <ShareMenuRoot data-name="ShareMenuRoot">
      {marketSettings?.shareLinkEnabled && (
        <ShareItem
          data-test-element-type={
            TestElementTypeId.ConfigurationMenuShareLinkButton
          }
          onClick={handleShareLinkClick}
        >
          {t('LABEL_ACTION_SHARE_LINK')}
        </ShareItem>
      )}
      {marketSettings?.shareEmailEnabled && (
        <ShareItem onClick={handleShareEmailClick}>
          {t('LABEL_ACTION_SHARE_EMAIL')}
        </ShareItem>
      )}
      {marketSettings?.shareWhatsappEnabled && (
        <ShareItem onClick={handleShareWhatsAppClick}>
          {t('LABEL_ACTION_SHARE_WHATSAPP')}
        </ShareItem>
      )}
      {marketSettings?.shareFacebookEnabled && (
        <ShareItem onClick={handleShareFacebookClick}>
          {t('LABEL_ACTION_SHARE_FACEBOOK')}
        </ShareItem>
      )}
    </ShareMenuRoot>
  )
}
