/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ReadMoreResourceDefinition
 */
export interface ReadMoreResourceDefinition {
  /**
   * An image URL.
   * @type {string}
   * @memberof ReadMoreResourceDefinition
   */
  path?: string | null
  /**
   * As defined in MetaDataKeys.ReadMoreResourceType
   * @type {string}
   * @memberof ReadMoreResourceDefinition
   */
  resourceType?: string | null
  /**
   * The tag gives a hint to the front end to where it should be placed
   * in the layout.
   * @type {string}
   * @memberof ReadMoreResourceDefinition
   */
  mediaTag?: string | null
  /**
   * The same resource may be presented in different formats by grouping them.
   * Only one media per group should be displayed by the layout.
   * Allowed to be null, interpreted as "group-less".
   * @type {string}
   * @memberof ReadMoreResourceDefinition
   */
  mediaGroup?: string | null
  /**
   * The index will predict the ascending list ordering for media with the same tag.
   * @type {number}
   * @memberof ReadMoreResourceDefinition
   */
  mediaIndex?: number
  /**
   *
   * @type {boolean}
   * @memberof ReadMoreResourceDefinition
   */
  attachment?: boolean
  /**
   *
   * @type {number}
   * @memberof ReadMoreResourceDefinition
   */
  fileSize?: number
  /**
   *
   * @type {string}
   * @memberof ReadMoreResourceDefinition
   */
  attachmentLinkText?: string | null
  /**
   *
   * @type {number}
   * @memberof ReadMoreResourceDefinition
   */
  width?: number | null
  /**
   *
   * @type {number}
   * @memberof ReadMoreResourceDefinition
   */
  height?: number | null
  /**
   *
   * @type {number}
   * @memberof ReadMoreResourceDefinition
   */
  bitrateBitps?: number | null
  /**
   *
   * @type {number}
   * @memberof ReadMoreResourceDefinition
   */
  startTimeMs?: number | null
  /**
   *
   * @type {boolean}
   * @memberof ReadMoreResourceDefinition
   */
  autoPlay?: boolean | null
}

/**
 * Check if a given object implements the ReadMoreResourceDefinition interface.
 */
export function instanceOfReadMoreResourceDefinition(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function ReadMoreResourceDefinitionFromJSON(
  json: any,
): ReadMoreResourceDefinition {
  return ReadMoreResourceDefinitionFromJSONTyped(json, false)
}

export function ReadMoreResourceDefinitionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ReadMoreResourceDefinition {
  if (json === undefined || json === null) {
    return json
  }
  return {
    path: !exists(json, 'path') ? undefined : json['path'],
    resourceType: !exists(json, 'resource_type')
      ? undefined
      : json['resource_type'],
    mediaTag: !exists(json, 'media_tag') ? undefined : json['media_tag'],
    mediaGroup: !exists(json, 'media_group') ? undefined : json['media_group'],
    mediaIndex: !exists(json, 'media_index') ? undefined : json['media_index'],
    attachment: !exists(json, 'attachment') ? undefined : json['attachment'],
    fileSize: !exists(json, 'file_size') ? undefined : json['file_size'],
    attachmentLinkText: !exists(json, 'attachment_link_text')
      ? undefined
      : json['attachment_link_text'],
    width: !exists(json, 'width') ? undefined : json['width'],
    height: !exists(json, 'height') ? undefined : json['height'],
    bitrateBitps: !exists(json, 'bitrate_bitps')
      ? undefined
      : json['bitrate_bitps'],
    startTimeMs: !exists(json, 'start_time_ms')
      ? undefined
      : json['start_time_ms'],
    autoPlay: !exists(json, 'auto_play') ? undefined : json['auto_play'],
  }
}

export function ReadMoreResourceDefinitionToJSON(
  value?: ReadMoreResourceDefinition | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    path: value.path,
    resource_type: value.resourceType,
    media_tag: value.mediaTag,
    media_group: value.mediaGroup,
    media_index: value.mediaIndex,
    attachment: value.attachment,
    file_size: value.fileSize,
    attachment_link_text: value.attachmentLinkText,
    width: value.width,
    height: value.height,
    bitrate_bitps: value.bitrateBitps,
    start_time_ms: value.startTimeMs,
    auto_play: value.autoPlay,
  }
}
