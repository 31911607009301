/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface BoundingBoxResultTruckWithoutShadow
 */
export interface BoundingBoxResultTruckWithoutShadow {
  /**
   *
   * @type {number}
   * @memberof BoundingBoxResultTruckWithoutShadow
   */
  posX: number
  /**
   *
   * @type {number}
   * @memberof BoundingBoxResultTruckWithoutShadow
   */
  posY: number
  /**
   *
   * @type {number}
   * @memberof BoundingBoxResultTruckWithoutShadow
   */
  width: number
  /**
   *
   * @type {number}
   * @memberof BoundingBoxResultTruckWithoutShadow
   */
  height: number
}

/**
 * Check if a given object implements the BoundingBoxResultTruckWithoutShadow interface.
 */
export function instanceOfBoundingBoxResultTruckWithoutShadow(
  value: object,
): boolean {
  let isInstance = true
  isInstance = isInstance && 'posX' in value
  isInstance = isInstance && 'posY' in value
  isInstance = isInstance && 'width' in value
  isInstance = isInstance && 'height' in value

  return isInstance
}

export function BoundingBoxResultTruckWithoutShadowFromJSON(
  json: any,
): BoundingBoxResultTruckWithoutShadow {
  return BoundingBoxResultTruckWithoutShadowFromJSONTyped(json, false)
}

export function BoundingBoxResultTruckWithoutShadowFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BoundingBoxResultTruckWithoutShadow {
  if (json === undefined || json === null) {
    return json
  }
  return {
    posX: json['posX'],
    posY: json['posY'],
    width: json['width'],
    height: json['height'],
  }
}

export function BoundingBoxResultTruckWithoutShadowToJSON(
  value?: BoundingBoxResultTruckWithoutShadow | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    posX: value.posX,
    posY: value.posY,
    width: value.width,
    height: value.height,
  }
}
