import React from 'react'
import styled from 'styled-components'

// interface IProps {}

// interface IState {
//   hasError: boolean;
//   error?: string | null;
//   info?: any;
// }

// Improved from https://css-tricks.com/handling-errors-with-error-boundary/

const Error = styled.div`
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  & > div {
    max-width: 500px;
    max-height: 360px;
    padding: 2em;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    h1 {
      margin-bottom: 2em;
    }
    p {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-height: 1.5em;
      max-width: 100%;
      color: #9a9a9a;
      cursor: pointer;
      &.expand {
        text-overflow: none;
        white-space: normal;
        overflow: unset;
        overflow-y: scroll;
        max-height: 6em;
      }
    }
  }
`

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      error: null,
      info: null,
    }
    this.handleExpandClick = this.handleExpandClick.bind(this)
  }
  componentDidCatch(error, info) {
    this.setState({
      hasError: true,
      error: error,
      info: info,
    })
  }
  handleExpandClick(e) {
    ;[...document.querySelectorAll('p.expand')].map((el) =>
      el.removeAttribute('style'),
    )
    e.currentTarget.classList.toggle('expand')
  }

  render() {
    if (this.state.hasError) {
      return (
        <Error>
          <div>
            <h1>Oops, something went wrong :(</h1>
            <p onClick={this.handleExpandClick}>
              The error: {this.state.error.toString()}
            </p>
            <p onClick={this.handleExpandClick}>
              Where it occured: {this.state.info.componentStack}
            </p>
          </div>
        </Error>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary

// From react docs.
//
// export default class ErrorBoundary extends React.Component<IProps, IState> {
//   constructor(props?: any) {
//     super(props);
//     this.state = { hasError: false };
//   }

//   static getDerivedStateFromError(error?: any) {
//     // Update state so the next render will show the fallback UI.
//     return { hasError: true };
//   }

//   componentDidCatch(error: any, errorInfo: any) {
//     // You can also log the error to an error reporting service
//     // logErrorToMyService(error, errorInfo);
//     console.log('Error boundary catched error: ', error);
//   }

//   render() {
//     if (this.state.hasError) {
//       // You can render any custom fallback UI
//       return <h1>Something went wrong.</h1>;
//     }

//     return this.props.children;
//   }
// }
