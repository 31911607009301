/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const ExpectedAnswerType = {
  EtelItem: 'EtelItem',
  Integer: 'Integer',
  Phase: 'Phase',
} as const
export type ExpectedAnswerType =
  (typeof ExpectedAnswerType)[keyof typeof ExpectedAnswerType]

export function ExpectedAnswerTypeFromJSON(json: any): ExpectedAnswerType {
  return ExpectedAnswerTypeFromJSONTyped(json, false)
}

export function ExpectedAnswerTypeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ExpectedAnswerType {
  return json as ExpectedAnswerType
}

export function ExpectedAnswerTypeToJSON(
  value?: ExpectedAnswerType | null,
): any {
  return value as any
}
