/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { FlexImageSuccessResult } from './FlexImageSuccessResult'
import {
  FlexImageSuccessResultFromJSON,
  FlexImageSuccessResultFromJSONTyped,
  FlexImageSuccessResultToJSON,
} from './FlexImageSuccessResult'

/**
 *
 * @export
 * @interface FlexImageResult
 */
export interface FlexImageResult {
  /**
   *
   * @type {FlexImageSuccessResult}
   * @memberof FlexImageResult
   */
  success?: FlexImageSuccessResult | null
  /**
   *
   * @type {string}
   * @memberof FlexImageResult
   */
  error?: string | null
}

/**
 * Check if a given object implements the FlexImageResult interface.
 */
export function instanceOfFlexImageResult(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function FlexImageResultFromJSON(json: any): FlexImageResult {
  return FlexImageResultFromJSONTyped(json, false)
}

export function FlexImageResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): FlexImageResult {
  if (json === undefined || json === null) {
    return json
  }
  return {
    success: !exists(json, 'success')
      ? undefined
      : FlexImageSuccessResultFromJSON(json['success']),
    error: !exists(json, 'error') ? undefined : json['error'],
  }
}

export function FlexImageResultToJSON(value?: FlexImageResult | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    success: FlexImageSuccessResultToJSON(value.success),
    error: value.error,
  }
}
