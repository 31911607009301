import { SummaryItem } from '../../../api/generated'
import { useEffect, useState } from 'react'

export const useActiveChoicesHighlight = (
  activeChoicesState: SummaryItem[],
) => {
  const [highlightYourChoices, setHighlightActiveChoices] =
    useState<boolean>(false)

  useEffect(() => {
    setHighlightActiveChoices(true)
    const resetHighlightActiveChoices = () => {
      setHighlightActiveChoices(false)
    }
    setTimeout(resetHighlightActiveChoices, 2000)
  }, [activeChoicesState])

  return highlightYourChoices
}
