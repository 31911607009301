/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { FactoryModelSummaryItem } from './FactoryModelSummaryItem'
import {
  FactoryModelSummaryItemFromJSON,
  FactoryModelSummaryItemFromJSONTyped,
  FactoryModelSummaryItemToJSON,
} from './FactoryModelSummaryItem'

/**
 *
 * @export
 * @interface FactoryModelSummaryGroup
 */
export interface FactoryModelSummaryGroup {
  /**
   *
   * @type {string}
   * @memberof FactoryModelSummaryGroup
   */
  id?: string | null
  /**
   *
   * @type {string}
   * @memberof FactoryModelSummaryGroup
   */
  name?: string | null
  /**
   *
   * @type {Array<FactoryModelSummaryItem>}
   * @memberof FactoryModelSummaryGroup
   */
  items?: Array<FactoryModelSummaryItem> | null
}

/**
 * Check if a given object implements the FactoryModelSummaryGroup interface.
 */
export function instanceOfFactoryModelSummaryGroup(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function FactoryModelSummaryGroupFromJSON(
  json: any,
): FactoryModelSummaryGroup {
  return FactoryModelSummaryGroupFromJSONTyped(json, false)
}

export function FactoryModelSummaryGroupFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): FactoryModelSummaryGroup {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'Id') ? undefined : json['Id'],
    name: !exists(json, 'Name') ? undefined : json['Name'],
    items: !exists(json, 'Items')
      ? undefined
      : json['Items'] === null
      ? null
      : (json['Items'] as Array<any>).map(FactoryModelSummaryItemFromJSON),
  }
}

export function FactoryModelSummaryGroupToJSON(
  value?: FactoryModelSummaryGroup | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    Id: value.id,
    Name: value.name,
    Items:
      value.items === undefined
        ? undefined
        : value.items === null
        ? null
        : (value.items as Array<any>).map(FactoryModelSummaryItemToJSON),
  }
}
