import { GoQuestion, GuidedOfferingInfo } from '../../api/generated'
import { GoPageId } from '../../pages/GuidedOffering/syncBackendStateHook'
import { CardData } from './CardData'

// TODO: Consider adding another status, InTransition to allow GO pages to fade
// out the old content and to prevent other clicks. Or implement that for each
// page instead? The plan is to merge all GO "pages" into one eventually and
// have different layout/pure view components inside that "controller page".
export enum GuidedOfferingClientStateStatus {
  /**
   * Guided Offering is allowed modify this state.
   */
  Active,

  /**
   * Guided Offering is not allowed to modify this state and it should be set to
   * null by the BuildModePage when it has loaded.
   */
  WaitingForBuildMode,
}

export interface GuidedOfferingClientState {
  status: GuidedOfferingClientStateStatus

  /**
   * A simplified version of goInfo that's easier to use in the HTML template.
   * Only for normal GO steps, will be null for OptionalsPage.
   */
  cards: CardData[] | null

  /**
   * Data for the last page, OptionalsPage, will be null for all other GO
   * pages/views. Arrives in the response for the 'Continue' request.
   *
   * TODO: Simplify this in the web API.
   */
  sliderQuestions: GoQuestion[] | null

  /**
   * The state reported from the backend for the latest selection.
   */
  lastResponse: GuidedOfferingInfo

  /**
   * Intended for detecting if the state was created on the OptionalsPage and in
   * that case we probably need to close the existing GO state and start a new
   * one with a full replay of desired selections.
   */
  stateUpdatedByPage: GoPageId

  /**
   * The selections performed by the user to reach this state. Should be empty
   * when only goWizardStart has been completed.
   */
  userSelections: string[]

  /**
   * Will only be reported by the response from gowizardStart and needs to be
   * stored for later use in the Info/About side panel.
   */
  etelLastUpdated: string
}
