import {
  ScaniaAdobeTrackingClickSaveGarageEvent,
  pushAdobeEvent,
  ScaniaAdobeEventId,
} from '../../utils/adobeAnalytics'
import { useClient } from '../../utils/useClient'
import useTexts from '../../utils/useTexts'
import { useCallback, useEffect } from 'react'
import { SESSION_FAILURE } from '../../api/errors'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { TestElementTypeId } from '../../types/TestAttributeId'
import {
  getSessionInitDataState,
  setGarageList,
} from '../../store/sessionDataSlice'
import { closeAllModals } from '../../store/modalSlice'
import { getTruckInfoState } from '../../store/truckInfoSlice'
import { TdsButton, TdsModal } from '@scania/tegel-react'

interface ModalSaveGarageProps {
  handleFatalError: () => void
  handleSessionFailure: () => void
  handleSuccess: () => void
}

export function ModalSaveGarage(props: ModalSaveGarageProps): JSX.Element {
  const t = useTexts()
  const client = useClient()
  const dispatch = useAppDispatch()
  const sessionInitData = useAppSelector(getSessionInitDataState)
  const sessionId = sessionInitData?.sessionId
  const truckInfo = useAppSelector(getTruckInfoState)

  const handleSaveClick = useCallback(async () => {
    if (!truckInfo?.savedAsId) {
      // TODO: Why is this needed? Throw instead?
      return
    }
    async function save() {
      if (!truckInfo?.savedAsId) {
        // TODO: Why is this needed? Throw instead?
        return
      }
      if (!sessionId) {
        throw new Error('Expected sessionId to be defined.')
      }
      if (!client) {
        throw new Error('Expected client to be defined.')
      }
      await client.userConfigV2Save({
        sessionId,
        userConfigId: truckInfo.savedAsId,
        description: truckInfo.savedAsName || '',
      })
      props.handleSuccess()
      const garagelistResponse = await client.userConfigV2List(sessionId)
      if (garagelistResponse.error === SESSION_FAILURE) {
        props.handleSessionFailure()
        return
      } else if (garagelistResponse.error) {
        props.handleFatalError()
        return
      }
      const garageList = garagelistResponse.userConfigV2List || []
      dispatch(setGarageList(garageList))
      return
    }
    save()
    dispatch(closeAllModals())

    const trackingEvent: ScaniaAdobeTrackingClickSaveGarageEvent = {
      event: ScaniaAdobeEventId.SaveGarageClick,
    }
    pushAdobeEvent(trackingEvent)
  }, [client, dispatch, props, sessionId, truckInfo])

  const handleCloseClick = useCallback(() => {
    dispatch(closeAllModals())
  }, [dispatch])

  // Eventlistener to close modal on tdsClose (click outside modal and click on close cross)
  useEffect(() => {
    document.addEventListener('tdsClose', handleCloseClick)
    return () => {
      document.removeEventListener('tdsClose', handleCloseClick)
    }
  }, [handleCloseClick])

  return (
    <TdsModal size="md" header={t('HEADER_SAVE_TRUCK')} show>
      <span slot="actions" className="tds-u-flex tds-u-gap2 tds-u-flex-wrap">
        <TdsButton
          variant="primary"
          size="md"
          text={t('LABEL_ACTION_SAVE')}
          onClick={handleSaveClick}
          data-test-element-type={TestElementTypeId.ModalSaveGarageSaveButton}
        />
        <TdsButton
          variant="secondary"
          size="md"
          text={t('LABEL_ACTION_CLOSE')}
          onClick={handleCloseClick}
          data-test-element-type={TestElementTypeId.ModalCloseButton}
        />
      </span>
    </TdsModal>
  )
}
