/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface UserConfigV2SaveAsRequest
 */
export interface UserConfigV2SaveAsRequest {
  /**
   *
   * @type {string}
   * @memberof UserConfigV2SaveAsRequest
   */
  sessionId: string
  /**
   *
   * @type {string}
   * @memberof UserConfigV2SaveAsRequest
   */
  configName: string
  /**
   *
   * @type {string}
   * @memberof UserConfigV2SaveAsRequest
   */
  description: string
}

/**
 * Check if a given object implements the UserConfigV2SaveAsRequest interface.
 */
export function instanceOfUserConfigV2SaveAsRequest(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'sessionId' in value
  isInstance = isInstance && 'configName' in value
  isInstance = isInstance && 'description' in value

  return isInstance
}

export function UserConfigV2SaveAsRequestFromJSON(
  json: any,
): UserConfigV2SaveAsRequest {
  return UserConfigV2SaveAsRequestFromJSONTyped(json, false)
}

export function UserConfigV2SaveAsRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserConfigV2SaveAsRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    sessionId: json['session_id'],
    configName: json['config_name'],
    description: json['description'],
  }
}

export function UserConfigV2SaveAsRequestToJSON(
  value?: UserConfigV2SaveAsRequest | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    session_id: value.sessionId,
    config_name: value.configName,
    description: value.description,
  }
}
