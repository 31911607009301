import { useEffect, useState } from 'react'
import styled from 'styled-components'

const PointOfInterestRoot = styled.div`
  position: absolute;
  pointer-events: none;

  svg {
    height: 100%;
    width: 100%;

    path {
      stroke: var(--tds-grey-300);
    }

    circle {
      fill: var(--tds-grey-200);
    }
  }
`

export interface PointOfInterestProps {
  horizontalLineLeft: number
  horizontalLineRight: number
  horizontalLineTop: number
  initialTransitionDurationMs: number
  logicalPxHeight: number
  logicalPxWidth: number
  poiMarkerRadiusPx: number
  poiX: number
  poiY: number
}

export function PointOfInterest({
  horizontalLineLeft,
  horizontalLineRight,
  horizontalLineTop,
  initialTransitionDurationMs,
  logicalPxHeight,
  logicalPxWidth,
  poiMarkerRadiusPx,
  poiX,
  poiY,
}: PointOfInterestProps): JSX.Element {
  const [animateToFullLength, setAnimateToFullLength] = useState<boolean>(false)

  const cornerSize = 12
  const lineStrokeWidth = 1.5
  const horizontalLineY = horizontalLineTop + lineStrokeWidth / 2
  const cornerControlPointX = horizontalLineLeft - cornerSize
  const cornerControlPointY = horizontalLineY
  const directionVector = [
    cornerControlPointX - poiX,
    cornerControlPointY - poiY,
  ]
  const magnitude = Math.sqrt(
    Math.pow(directionVector[0], 2) + Math.pow(directionVector[1], 2),
  )
  const normalized = directionVector.map((n) => n / magnitude)
  const newMagnitude = magnitude - cornerSize
  const newDirectionVector = normalized.map((n) => n * newMagnitude)
  const cornerStartX = newDirectionVector[0] + poiX
  const cornerStartY = newDirectionVector[1] + poiY

  // Move to Point Of Interest center.
  let pathDefinition = 'M' + poiX + ',' + poiY

  // Line to start of corner.
  pathDefinition += ' L' + cornerStartX + ',' + cornerStartY

  // Quadric bezier to the start of the horizontal line.
  pathDefinition +=
    ' Q' +
    cornerControlPointX +
    ',' +
    cornerControlPointY +
    ' ' +
    horizontalLineLeft +
    ',' +
    horizontalLineY

  // Line to end of the horizontal line.
  pathDefinition += ' L' + horizontalLineRight + ',' + horizontalLineY

  // For animation.
  const approxLength = magnitude + horizontalLineRight - cornerControlPointX

  useEffect(() => {
    // TODO: Review this. For some reason the transition doesn't trigger if we
    // set the flag to true at once. Test in Firefox, that browser needs two
    // nested requestAnimationFrame calls sometimes to display the same behavior
    // as Chrome.
    requestAnimationFrame(() => {
      setAnimateToFullLength(true)
    })
  }, [])

  // TODO: Replace the CSS transition with CSS animations. It seem like any CSS
  // change during the transition will pause and resume it even if the change is
  // not touching the specified transitionProperty, which means we can't change
  // the color of the SVG while the transition is in progress, which is
  // annoying. We DO want a hover effect, some Scania blue color perhaps on this
  // component when the related text hitbox is hovered in the parent component.
  // Hopefully CSS animations will allow changing the CSS while the animation is
  // playing.
  return (
    <PointOfInterestRoot
      style={{
        width: logicalPxWidth + 'px',
        height: logicalPxHeight + 'px',
        left: 0,
        top: 0,
        strokeDasharray: animateToFullLength
          ? approxLength
          : 0 + ',' + approxLength,
        transitionDuration: initialTransitionDurationMs + 'ms',
        transitionTimingFunction: 'ease-in-out',
        transitionDelay: '150ms',
        transitionProperty: 'stroke-dasharray',
      }}
    >
      <svg viewBox={`0 0 ${logicalPxWidth} ${logicalPxHeight}`}>
        <path fill="none" strokeWidth={lineStrokeWidth} d={pathDefinition} />
        <circle cx={poiX} cy={poiY} r={poiMarkerRadiusPx} />
      </svg>
    </PointOfInterestRoot>
  )
}
