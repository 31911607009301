import { createRoot } from 'react-dom/client'
import App, { ExternalIntegrationLoaders } from './App'
import { Provider } from 'react-redux'
import { store } from './store'
import { BreakpointProvider, BreakpointQueries } from './utils/breakpoint'
import { defineCustomElements as defineCustomElementsTegel } from '@scania/tegel-react'
import { BrowserRouter } from 'react-router-dom'
import { BreakpointWidthPx } from './css/BreakpointWidthPx'
import {
  UrlParamMode,
  getUrlCidBevSemiliveParameters,
} from './utils/getUrlCidParameter'
import { getUrlParametersToString } from './utils/getUrlParametersToString'

defineCustomElementsTegel()

// Avoid popping the context menu if mobile users take too long pressing down
// before starting a swipe gesture. Just set it to null in dev-tools to get it
// back for debugging on desktop.
//window.oncontextmenu = (e: Event) => {
//  e.preventDefault()
//}

// TODO: Review later.
declare global {
  interface Window {
    preventAppStart?: boolean
    initializeAdobeAnalytics: VoidFunction | undefined | null
    initializeMopinion: VoidFunction | undefined | null
  }
}

;({
  reloadIfPathIsNotFromStart: (global: Window & typeof globalThis) => {
    // Remove this when correct url allowes to start in the middle of GO again.
    let etel_market,
      etel_language,
      page = ''
    const path = window.document.location.pathname
    const urlParams = new URLSearchParams(window.location.search)

    const hasMarketAndLanguageParams =
      urlParams.has('etel_market') && urlParams.has('etel_language')

    if (hasMarketAndLanguageParams) {
      etel_market = urlParams.get('etel_market')
      etel_language = urlParams.get('etel_language')
    }

    // TODO: Finish the route handing for all pages. The normal Guided Offering
    // views can be right under /go/, followed by market, languare and
    // "sliderHistory"/"clickHistory" (desiredselection after the partial
    // rewrite).
    let whitelistPages = [
      'build',
      'factory-model-summary',
      'factory-models',
      'go-start',
      'grid',
      'link',
      'reset-password',
      'slider',
    ]
    const pageShouldBeRedirectedToStart = (pageId: string) => {
      return !whitelistPages.includes(pageId)
    }

    // TODO: Revisit later. Should market and language be the first fragments
    // after /go/ ? That would allow nesting deeper pages like
    // /go/1234/5678/factory-models/summary/123
    // Needs to be discussed with Scania since this could affect user tracking.
    //
    // const isNotStartPageWithCorrectParams = path === '/go/'
    const hasComposedUrlWithMarketLanguage = path.match(
      // eslint-disable-next-line
      /\/go\/([^\/]*)\/(\d{4})\/(\d{4})/i,
    )
    if (hasComposedUrlWithMarketLanguage) {
      page = hasComposedUrlWithMarketLanguage[1]
      etel_market = hasComposedUrlWithMarketLanguage[2]
      etel_language = hasComposedUrlWithMarketLanguage[3]
    }
    const isOldLandingPage = hasMarketAndLanguageParams && path.includes('/go/')
    if (
      isOldLandingPage ||
      (!hasMarketAndLanguageParams &&
        hasComposedUrlWithMarketLanguage &&
        pageShouldBeRedirectedToStart(page))
    ) {
      const urlParams = getUrlCidBevSemiliveParameters(UrlParamMode.ExcludeBev)
      if (!etel_market) {
        throw new Error('Expected etel_market to be defined.')
      }
      if (!etel_language) {
        throw new Error('Expected etel_language to be defined.')
      }
      urlParams.append('etel_market', etel_market)
      urlParams.append('etel_language', etel_language)
      const reloadpath = '/' + getUrlParametersToString(urlParams)
      document.location.replace(reloadpath)
      console.log('reload path', reloadpath)
      global.preventAppStart = true
    }
  },
}).reloadIfPathIsNotFromStart(window)

console.time('StartTimer')
/* JS media queries, keep in sync with utils/themes.js */
const queries: BreakpointQueries = {
  phone: `(max-width: ${BreakpointWidthPx.Phone}px)`,
  tablet: `(max-width: ${BreakpointWidthPx.Tablet}px)`,
  desktop: `(max-width: ${BreakpointWidthPx.Desktop}px)`,
  desktop_large: `(max-width: ${BreakpointWidthPx.DesktopLarge}px)`,
  or: '(orientation: portrait)', // TODO: Is this in use?
}

const adobeAnalyticsInitFailure = () => {
  // This is expected when running 'npm run start' since the post build script
  // doesn't seem to run on recompilation/hot reload.
  if (document.location.hostname.includes('scania.com')) {
    console.error('Failed to find the Adobe Analytics init function.')
  }
}

const mopinionInitFailure = () => {
  // This is expected when running 'npm run start' since the post build script
  // doesn't seem to run on recompilation/hot reload.
  if (document.location.hostname.includes('scania.com')) {
    console.warn('Failed to find the Mopinion init function.')
  }
}

const externalLoaders: ExternalIntegrationLoaders = {
  initializeAdobeAnalytics:
    window.initializeAdobeAnalytics || adobeAnalyticsInitFailure,
  initializeMopinion: window.initializeMopinion || mopinionInitFailure,
}

const container = document.getElementById('root')
if (!container) {
  throw new Error('Expected container to be defined.')
}
const root = createRoot(container)
root.render(
  <BreakpointProvider queries={queries}>
    <Provider store={store}>
      {!window.preventAppStart && (
        <BrowserRouter>
          <App externalLoaders={externalLoaders} />
        </BrowserRouter>
      )}
    </Provider>
  </BreakpointProvider>,
)
