import { useDrag } from '@use-gesture/react'
import {
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import styled, { css } from 'styled-components'
import {
  ConfigChangeResult,
  FlexImageParameters,
  FlexImageV2RequestParams,
  GuiMarketSettings,
} from '../../api/generated'
import { SvgInfo, SvgPanorama } from '../../components/SvgImports'
import { TestElementTypeId } from '../../types/TestAttributeId'
import { useLayoutViewportSize } from '../../utils/resizeHook'
import { Disclaimer } from './Disclaimer'
import { Size2dLogicalPixels } from '../../types/Size2d'
import { PointOfInterest, PointOfInterestProps } from './PointOfInterest'
import { useClient } from '../../utils/useClient'
import { OrangeBlob } from './OrangeBlob'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import {
  ScaniaAdobeEventId,
  ScaniaAdobeTrackingClickSmartDashCampaignEvent,
  pushAdobeEvent,
} from '../../utils/adobeAnalytics'
import { TextId, useTextsV2 } from '../../utils/useTexts'
import { SMART_DASH_READMORE_FAMILY_ID } from '../../api/constants'
import { BreakpointWidthPx } from '../../css/BreakpointWidthPx'
import { BodyBuildToggle, ButtonSize } from './BodyBuildToggle'
import { openPanelReadMore, setReadMoreNode } from '../../store/sidePanelSlice'
import { getMarketSettingsState } from '../../store/appSlice'
import {
  getBodyBuildToggled,
  getUnavailableImageFrames,
} from '../../store/sessionDataSlice'
import { TdsChip } from '@scania/tegel-react'
import { ImageSeriesFrame, SmartDashCampaignMode } from '../../store/types'

const MainImageAreaRoot = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`

interface ImageRootProps {
  /**
   * IMPORTANT: We must calculate this as whole pixels to avoid resampling the
   * child image and causing blurryness.
   */
  $heightPx: number
  $widthPx: number
}

const TRANSITION_MS = 200

const ImageRoot = styled.div<ImageRootProps>`
  touch-action: pan-y;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: grab;

  img {
    position: absolute;
    transition: opacity ${TRANSITION_MS}ms ease-out;
  }

  // Needed to make desktop browsers behave well with useDrag.
  img {
    touch-action: pan-y;
    -moz-user-select: none;
    -webkit-user-drag: none;
    user-select: none;

    // Firefox seems to need this as well.
    pointer-events: none;
  }
`

const PoiTextAllowedArea = styled.div`
  //background-color: rgba(255, 0, 255, 0.3);
  position: absolute;
  display: flex;
  align-items: center;
`

const PoiSvgInfoWrapper = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  margin-right: 6px;

  svg {
    color: var(--tds-blue-400);
    position: absolute;
    margin-top: 6px;
    width: 16px;
    height: 16px;
  }
`

const PoiTextStrong = styled.span`
  font-weight: bold;
  white-space: nowrap;
`

// This seems to work and if it does work the way I think, then this is due to
// the script code that measures the text child node positions does so in
// relation to the MainImageArea component root and by using viewport relative
// coordinates, which means that intermediate elements and their positions and
// sizes are not affecting the measurements, which let's us get away with
// negative margins for the surrounding hitbox.
const PoiTextHitbox = styled.div`
  position: absolute;
  margin: -8px -16px -8px -16px;
  padding: 8px 16px 8px 16px;
  cursor: pointer;

  @media (hover: hover) and (pointer: fine) {
    &:hover span {
      color: var(--tds-blue-400);
    }
  }
`

const FrameIconsWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: ${BreakpointWidthPx.Tablet}px) {
    max-width: 100%;
    align-items: normal;
  }
`

const ViewFrameIconWrapper = styled.div`
  cursor: pointer;

  @media screen and (min-width: ${BreakpointWidthPx.Tablet}px) {
    @media (hover: hover) and (pointer: fine) {
      &:hover > div {
        background-color: var(--tds-grey-700);
        height: 12px;
        margin: 18px 5px 18px 5px;
      }
    }
  }

  @media screen and (max-width: ${BreakpointWidthPx.Tablet}px) {
    @media (hover: hover) and (pointer: fine) {
      &:hover > div {
        background-color: var(--tds-grey-700);
        height: 13px;
        width: 13px;
        margin: 17px 7px 17px 6px;
      }
    }
  }
`

const PanoramaTdsChip = styled(TdsChip)`
  padding-left: 5px;
  display: flex;
  align-items: center;

  label {
    box-shadow: 0px 3px 2px var(--tds-grey-400);
  }

  svg {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  @media screen and (max-width: ${BreakpointWidthPx.Phone}px) {
    padding-left: 0px;
  }
`

interface ViewFrameIconProps {
  $isSelected: boolean
  $hasBodyBuild: boolean
}

// TODO: Update this calculation, these are not constants just rough estimations
const imageButtonConstants = {
  INTERIOR_360_WIDTH: '110px',
  BODYBUILD_TOGGLE_WIDTH_DESKTOP: '125px',
  BODYBUILD_TOGGLE_WIDTH_TABLET: '70px',
  NUMBER_VIEW_BUTTONS: '6',
  VIEW_BUTTON_MARGIN: '10px',
}

const ViewFrameIcon = styled.div<ViewFrameIconProps>`
  height: 8px;
  width: 50px;
  margin: 20px 5px 20px 5px;
  background-color: ${(props) =>
    props.$isSelected
      ? 'var(--tds-btn-primary-background)'
      : 'var(--tds-grey-400)'};

  @media screen and (max-width: ${BreakpointWidthPx.Tablet}px) {
    border-radius: 50%;
    height: 10px;
    width: 10px;
    margin: 20px 8px 20px 8px;
  }

  @media screen and (min-width: ${BreakpointWidthPx.Tablet}px) {
    ${({ $hasBodyBuild }) =>
      $hasBodyBuild
        ? css`
            max-width: calc(
              (
                  100vw - var(--mini-summary-width-desktop) -
                    var(--configurator-menu-width) -
                    ${imageButtonConstants.INTERIOR_360_WIDTH} -
                    ${imageButtonConstants.BODYBUILD_TOGGLE_WIDTH_TABLET}
                ) / ${imageButtonConstants.NUMBER_VIEW_BUTTONS} -
                ${imageButtonConstants.VIEW_BUTTON_MARGIN}
            );
          `
        : css`
            max-width: calc(
              (
                  100vw - var(--mini-summary-width-desktop) -
                    var(--configurator-menu-width) -
                    ${imageButtonConstants.INTERIOR_360_WIDTH}
                ) / ${imageButtonConstants.NUMBER_VIEW_BUTTONS} -
                ${imageButtonConstants.VIEW_BUTTON_MARGIN}
            );
          `}
  }

  @media screen and (min-width: ${BreakpointWidthPx.Desktop}px) {
    ${({ $hasBodyBuild }) =>
      $hasBodyBuild
        ? css`
            max-width: calc(
              (
                  100vw - var(--mini-summary-width-desktop) -
                    var(--configurator-menu-width) -
                    ${imageButtonConstants.INTERIOR_360_WIDTH} -
                    ${imageButtonConstants.BODYBUILD_TOGGLE_WIDTH_DESKTOP}
                ) / ${imageButtonConstants.NUMBER_VIEW_BUTTONS} -
                ${imageButtonConstants.VIEW_BUTTON_MARGIN}
            );
          `
        : css`
            max-width: calc(
              (
                  100vw - var(--mini-summary-width-desktop) -
                    var(--configurator-menu-width) -
                    ${imageButtonConstants.INTERIOR_360_WIDTH}
                ) / ${imageButtonConstants.NUMBER_VIEW_BUTTONS} -
                ${imageButtonConstants.VIEW_BUTTON_MARGIN}
            );
          `}
  }

  @media screen and (max-width: 300px) {
    border-radius: 50%;
    height: 8px;
    width: 8px;
    margin: 20px 4px 20px 4px;
  }
`

const DisclaimerWrapper = styled.div`
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 16px;
  padding-bottom: 4px;
  font-size: 12px;
  color: var(--tds-grey-600);

  @media screen and (max-width: ${BreakpointWidthPx.Tablet}px) {
    display: none;
  }
`

/**
 * @returns Returns null if textRects is an empty array.
 */
function calculateOuterDOMRect(textRects: DOMRect[]): DOMRect | null {
  const first = textRects[0]
  if (first === undefined) {
    return null
  }
  let minX = first.x
  let minY = first.y
  let maxRight = first.right
  let maxBottom = first.bottom
  for (const rect of textRects) {
    minX = Math.min(minX, rect.x)
    minY = Math.min(minY, rect.y)
    maxRight = Math.max(maxRight, rect.right)
    maxBottom = Math.max(maxBottom, rect.bottom)
  }
  return new DOMRect(minX, minY, maxRight - minX, maxBottom - minY)
}

/**
 * A separate type for CSS/logical pixels. The type BoundingBox2d in the
 * generated client code is using physical pixels and we do not want to
 * accidentally mix them up.
 * https://developer.mozilla.org/en-US/docs/Web/API/Window/devicePixelRatio
 *
 * TODO: Rename the API type BoundingBox2d to BoundingBox2dPhysicalPixels.
 */
interface BoundingBox2dLogicalPixels {
  logicalPosX: number
  logicalPosY: number
  logicalWidth: number
  logicalHeight: number
}

interface ImageBoundingBoxState {
  wipers: BoundingBox2dLogicalPixels
  truckWithoutShadow: BoundingBox2dLogicalPixels
}

interface ImageState {
  imageRootSize: Size2dLogicalPixels
  imageParams: FlexImageParameters
  url: string
  boundingBoxes: ImageBoundingBoxState | null
}

function imageAndConfigAreCompatibleWithSmartDashCampaign(
  imageState: ImageState | null,
  configChangeResult: ConfigChangeResult | null,
): boolean {
  if ((configChangeResult?.smartDashItems?.length || 0) < 1) {
    return false
  }
  if (
    imageState?.imageParams.series !== 'EXTERIOR' ||
    imageState?.imageParams.frame !== 2
  ) {
    return false
  }
  return true
}

function canShowBodyBuildForSeriesAndFrame(
  seriesFrame: ImageSeriesFrame | null,
): boolean {
  return (
    seriesFrame !== null &&
    seriesFrame.frameId === 2 &&
    seriesFrame.seriesId === 'EXTERIOR'
  )
}

function canShowBodyBuildForMarketAndConfig(
  marketSettings: GuiMarketSettings | null,
  latestConfigChangeResult: ConfigChangeResult | null,
): boolean {
  return (
    marketSettings !== null &&
    marketSettings.bodyBuildsEnabled &&
    latestConfigChangeResult !== null &&
    latestConfigChangeResult.canShowBodyBuild
  )
}

interface MainImageAreaProps {
  handlePanoramaClick: () => void
  handleViewChange: (view: ImageSeriesFrame) => void
  latestConfigChangeResult: ConfigChangeResult | null
  navigateToMenu: (
    id: string,
    path: string,
    preventMainMenuOpening: boolean,
  ) => Promise<void>
  seriesFrame: ImageSeriesFrame | null
  sessionId: string | null
  smartDashCampaignMode: SmartDashCampaignMode
}

export function MainImageArea({
  handlePanoramaClick,
  handleViewChange,
  latestConfigChangeResult,
  navigateToMenu,
  seriesFrame,
  sessionId,
  smartDashCampaignMode,
}: MainImageAreaProps): JSX.Element {
  const dispatch = useAppDispatch()
  const client = useClient()
  const t = useTextsV2()
  const timerId = useRef<number | undefined>()
  const imageRoot = useRef<HTMLDivElement | null>(null)
  const poiTextRef = useRef<HTMLDivElement | null>(null)
  const poiTextHitboxRef = useRef<HTMLDivElement | null>(null)
  const componentRoot = useRef<HTMLDivElement>(null)
  const marketSettings = useAppSelector(getMarketSettingsState)
  const bodyBuildOnOffButtonState = useAppSelector(getBodyBuildToggled)
  const unavailableImageFrames = useAppSelector(getUnavailableImageFrames)

  const [imageRootSize, setImageRootSize] =
    useState<Size2dLogicalPixels | null>(null)
  const [imageRootLazySize, setImageRootLazySize] =
    useState<Size2dLogicalPixels | null>(null)
  const [poiProps, setPoiProps] = useState<PointOfInterestProps | null>(null)
  const [allowedPoiTextBoundingBox, setAllowedPoiTextBoundingBox] =
    useState<BoundingBox2dLogicalPixels | null>(null)
  const [visibleImageState, setVisibleImageState] = useState<ImageState | null>(
    null,
  )
  const [userHasClickedOrangeBlobCross, setUserHasClickedOrangeBlobCross] =
    useState<boolean>(false)

  const { viewportWidth, viewportHeight } = useLayoutViewportSize()

  const updateDelayMs = 500

  const frameIsAvailable = (seriesId: string, frameId: number): boolean => {
    return (
      -1 ===
      unavailableImageFrames.findIndex(
        (u) => u.seriesId === seriesId && u.frameId === frameId,
      )
    )
  }

  const viewAlternatives = [
    { series: 'EXTERIOR', frame: 1, key: 1 },
    { series: 'EXTERIOR', frame: 2, key: 2 },
    { series: 'EXTERIOR', frame: 3, key: 3 },
    { series: 'INTERIOR', frame: 1, key: 4 },
    { series: 'INTERIOR', frame: 2, key: 5 },
    { series: 'INTERIOR', frame: 3, key: 6 },
    { series: 'INTERIOR', frame: 4, key: 7 },
  ].filter((v) => frameIsAvailable(v.series, v.frame))

  const resizeObserver = useRef(
    new ResizeObserver((entries) => {
      const target = entries[0].target
      const size: Size2dLogicalPixels = {
        logicalWidth: target.clientWidth,
        logicalHeight: target.clientHeight,
      }
      setImageRootSize(size)
    }),
  )

  useEffect(() => {
    if (!imageRoot.current) {
      return
    }
    const observer = resizeObserver
    observer.current.observe(imageRoot.current)
    return () => {
      observer.current.disconnect()
    }
  }, [imageRoot])

  useEffect(() => {
    if (!imageRootSize) {
      return
    }

    // ------------------------------------------
    // Immediate resize of image elements - BEGIN
    // ------------------------------------------
    if (!imageRoot.current) {
      return
    }
    const childNodes = imageRoot.current.hasChildNodes()
      ? imageRoot.current.childNodes
      : null
    if (childNodes) {
      for (const c of childNodes) {
        if (!(c instanceof HTMLImageElement)) {
          console.warn('Expected image element, found: ', c)
          continue
        }
        const img = c as HTMLImageElement

        // Set image element sizes to match the new available area, this may
        // cause blurryness, but will be corrected when the new image is
        // generated. Since we don't know which part of the image contains the
        // truck pixels, we can't scale it perfectly and have to make a best
        // guess. Always using width to scale the image seems to feel the best.
        //
        // TODO: Review Math.round vs Math.floor in other places, be consistent!
        // floor is probably what we want to never go outside the maximum width,
        // but I'm not sure that it matters.
        //
        const containerWidth = Math.round(imageRootSize.logicalWidth)
        const containerHeight = Math.round(imageRootSize.logicalHeight)
        const idealLogicalWidth = Math.round(
          img.naturalWidth / window.devicePixelRatio,
        )
        const idealLogicalHeight = Math.round(
          img.naturalHeight / window.devicePixelRatio,
        )
        const dwf = containerWidth / idealLogicalWidth // Delta Width Factor
        const scaleFactor = dwf
        const w = Math.round(idealLogicalWidth * scaleFactor)
        const h = Math.round(idealLogicalHeight * scaleFactor)
        const top = Math.round((containerHeight - h) / 2)
        img.width = w
        img.height = h
        img.style.top = top.toFixed() + 'px'
      }
    }
    // ------------------------------------------
    // Immediate resize of image elements - END
    // ------------------------------------------

    window.clearTimeout(timerId.current)
    timerId.current = window.setTimeout(() => {
      setImageRootLazySize({
        logicalWidth: imageRootSize.logicalWidth,
        logicalHeight: imageRootSize.logicalHeight,
      })
    }, updateDelayMs)
    return () => {
      window.clearTimeout(timerId.current)
    }
  }, [imageRootSize, setImageRootLazySize, updateDelayMs, imageRoot])

  useEffect(() => {
    const asyncWrapper = async () => {
      if (
        !client ||
        !sessionId ||
        !latestConfigChangeResult === null ||
        !seriesFrame ||
        !imageRootLazySize
      ) {
        return
      }
      const containerLogicalWidth = imageRootLazySize.logicalWidth
      const containerLogicalHeight = imageRootLazySize.logicalHeight
      const containerPhysicalWidth = Math.round(
        containerLogicalWidth * window.devicePixelRatio,
      )
      const containerPhysicalHeight = Math.round(
        containerLogicalHeight * window.devicePixelRatio,
      )
      if (containerPhysicalWidth === 0 || containerPhysicalHeight === 0) {
        console.error('Width or height is 0.')
        return
      }
      // TODO: This doesn't seem to work properly for INTERIOR. The INTERIOR
      // series should always autocrop to cover the requested area, there should
      // never be any margins in the resulting image for interior views.
      // Investigate if get_current_config_image is what we need for the
      // INTERIOR image series.
      let margin = [0, 0, 0, 0]

      if (document.documentElement.clientWidth < BreakpointWidthPx.Tablet) {
        if (seriesFrame.seriesId === 'EXTERIOR') {
          if (
            seriesFrame.frameId === 2 &&
            smartDashCampaignMode ===
              SmartDashCampaignMode.FancyAnimatedLinesAndCurve
          ) {
            const px = Math.round(containerPhysicalHeight * 0.1)
            const reasonableTopMarginPx = Math.round(64 * devicePixelRatio)
            margin = [Math.max(px, reasonableTopMarginPx), px, px, px]
          } else {
            const px = Math.round(containerPhysicalHeight * 0.1)
            margin = [px, px, px, px]
          }
        }
      } else {
        // Desktop / large screen.
        if (seriesFrame.seriesId === 'EXTERIOR') {
          const hPx = Math.round(containerPhysicalHeight * 0.1)
          const wPx = Math.round(containerPhysicalWidth * 0.1)
          margin = [hPx, wPx, hPx, wPx]
        }
      }
      const argb = '#FFF9FAFB'
      const flexParams: FlexImageParameters = {
        series: seriesFrame.seriesId,
        frame: seriesFrame.frameId,
        bgColor: argb,
        autoCrop: ['top', 'bottom', 'left', 'right'],
        skipBackgroundElementsInAutocrop: true,
        align: [0.5, 0.5],
        margin,
        imageType: 'jpg',
        size: [containerPhysicalWidth, containerPhysicalHeight],
        useBoundingBoxes: true, // TODO: Review, use false for all but one frame?
      }
      const showBodyBuild =
        bodyBuildOnOffButtonState &&
        canShowBodyBuildForMarketAndConfig(
          marketSettings,
          latestConfigChangeResult,
        ) &&
        canShowBodyBuildForSeriesAndFrame(seriesFrame)
      const requestParams: FlexImageV2RequestParams = {
        sessionId,
        flexParams,
        showBodyBuild,
      }
      const response = await client.flexImageV2(requestParams)
      if (!response.success?.url) {
        console.log('Expected url')
        return
      }
      if (imageRoot.current === null) {
        throw new Error('Expected imageRoot.current to be non null')
      }
      const img = new Image()
      const src = response.success.url
      img.onload = () => {
        if (imageRoot.current?.children) {
          const listChildElements = imageRoot.current.children
          const toRemove = []
          for (let child of listChildElements) {
            if (child instanceof HTMLImageElement) {
              if (child.style.opacity === '0') {
                toRemove.push(child)
              }
              child.style.opacity = '0'
              setTimeout(() => {
                // For some reason the faded out image may capture clicks the
                // the image view buttons, even when giving the buttons a higher
                // z-index, so we must remove the element after the transition.
                child.remove()
              }, TRANSITION_MS)
            }
          }
          for (let child of toRemove) {
            imageRoot.current.removeChild(child)
          }
        }
        const imageLogicalWidth = Math.round(
          img.naturalWidth / window.devicePixelRatio,
        )
        const imageLogicalHeight = Math.round(
          img.naturalHeight / window.devicePixelRatio,
        )
        img.width = imageLogicalWidth
        img.height = imageLogicalHeight
        img.style.top = '0px'
        img.style.opacity = '0'
        imageRoot.current?.appendChild(img)
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            // Firefox bug workaround
            img.style.opacity = '1'

            // For automated tests:
            img.dataset['testElementType'] =
              TestElementTypeId.BuildModeMainImage

            const boundingBoxResult = response.success?.boundingBoxResult
            let newBoundingBoxes: ImageBoundingBoxState | null = null
            if (flexParams.series === 'EXTERIOR' && flexParams.frame === 2) {
              if (boundingBoxResult) {
                const truckBox = boundingBoxResult.truckWithoutShadow
                const newTruckBox: BoundingBox2dLogicalPixels = {
                  logicalPosX: truckBox.posX / window.devicePixelRatio,
                  logicalPosY: truckBox.posY / window.devicePixelRatio,
                  logicalWidth: truckBox.width / window.devicePixelRatio,
                  logicalHeight: truckBox.height / window.devicePixelRatio,
                }
                const wiperBox = boundingBoxResult.wipers
                const newWiperBox: BoundingBox2dLogicalPixels = {
                  logicalPosX: wiperBox.posX / window.devicePixelRatio,
                  logicalPosY: wiperBox.posY / window.devicePixelRatio,
                  logicalWidth: wiperBox.width / window.devicePixelRatio,
                  logicalHeight: wiperBox.height / window.devicePixelRatio,
                }
                newBoundingBoxes = {
                  truckWithoutShadow: newTruckBox,
                  wipers: newWiperBox,
                }
              } else {
                console.error(
                  'Expected to find the bounding box for the wipers.',
                )
              }
            }
            const newImageState: ImageState = {
              imageParams: flexParams,
              imageRootSize: {
                logicalWidth: containerLogicalWidth,
                logicalHeight: containerLogicalHeight,
              },
              boundingBoxes: newBoundingBoxes,
              url: src,
            }
            setVisibleImageState(newImageState)
          })
        })
      }
      img.onerror = () => {
        console.error('Loading image...Failed.')
      }
      img.src = src
    }
    asyncWrapper()
  }, [
    bodyBuildOnOffButtonState,
    client,
    imageRootLazySize,
    latestConfigChangeResult,
    marketSettings,
    seriesFrame,
    sessionId,
    smartDashCampaignMode,
  ])

  // Calculate the DDW position based on the wiper bounding box.
  //
  // DDW = Digital Driver Workspace.
  //
  // 1. Wait for image to load and wait for size and lazySize to match.
  // 2. Store the Wiper bounding box.
  // 3. Calculate text position based on lazySize and wiper bounding box.
  // 4. Render text, but with visibility 'hidden'.
  // 5. Wait for layout.
  // 6. Measure the resulting text bounding box.
  // 7. Calculate POI props based on text and wiper bounding box.
  // 8. Show a new POI component instance with a fresh transition and change
  //    text visibility from 'hidden' to 'visible'.
  //
  // The DDW is not visible in the camera angle EXTERIOR/frame 2, so we must
  // calculate the DDW position based on some other truck component.
  //
  // We calculate the position of the DDW in relation to the position and
  // size of the windscreen wipers. The reason for using the windscreen wipers
  // as a source for position and scale is that the windscreen wipers
  // component image should always be present and should always be positioned
  // a similar distance from the DDW regardless of Cab type.
  //
  // TODO: Refactor more parts of the POI code out to a separate component that
  // includes both the text and the SVG? Feed the component with the allowed
  // bounding box for the text and make that component handle the text?
  //
  useEffect(() => {
    if (
      !imageAndConfigAreCompatibleWithSmartDashCampaign(
        visibleImageState,
        latestConfigChangeResult,
      )
    ) {
      setPoiProps(null)
      return
    }
    if (
      smartDashCampaignMode !== SmartDashCampaignMode.FancyAnimatedLinesAndCurve
    ) {
      setPoiProps(null)
      return
    }
    if (!visibleImageState?.boundingBoxes) {
      setPoiProps(null)
      return
    }
    if (!imageRootSize) {
      setPoiProps(null)
      return
    }
    if (!imageRootLazySize) {
      setPoiProps(null)
      return
    }
    if (
      imageRootLazySize.logicalWidth !== imageRootSize.logicalWidth ||
      imageRootLazySize.logicalHeight !== imageRootSize.logicalHeight
    ) {
      setPoiProps(null)
      return
    }
    if (
      visibleImageState.imageRootSize.logicalWidth !==
        imageRootLazySize.logicalWidth ||
      visibleImageState.imageRootSize.logicalHeight !==
        imageRootLazySize.logicalHeight
    ) {
      setPoiProps(null)
      return
    }
    const truckBox = visibleImageState.boundingBoxes.truckWithoutShadow
    const wiperBox = visibleImageState.boundingBoxes.wipers

    // TODO: Take RHD/LHD into consideration.

    // TODO: We need the total truck bounding box to position the text in a
    // reasonable place.
    const logicalPxMinVerticalTextMargin = 16

    // TODO: Use the real size and pass down as a CSS variable or component
    // property? This seems to work fine for now.
    const logicalPxSaveMenuWidth = 64

    function calculateAllowedTextBox(
      imageRootSize: Size2dLogicalPixels,
    ): BoundingBox2dLogicalPixels {
      if (imageRootSize.logicalWidth >= BreakpointWidthPx.Tablet) {
        const logicalPosX = wiperBox.logicalPosX + wiperBox.logicalWidth
        return {
          logicalHeight:
            truckBox.logicalPosY - logicalPxMinVerticalTextMargin * 2,
          logicalPosX: Math.round(logicalPosX),
          logicalPosY: logicalPxMinVerticalTextMargin,
          logicalWidth: Math.floor(
            imageRootSize.logicalWidth - logicalPosX - logicalPxSaveMenuWidth,
          ),
        }
      } else {
        const logicalPosX = wiperBox.logicalPosX + wiperBox.logicalWidth * 0.6
        return {
          logicalHeight:
            truckBox.logicalPosY - logicalPxMinVerticalTextMargin * 2,
          logicalPosX: Math.round(logicalPosX),
          logicalPosY: logicalPxMinVerticalTextMargin,
          logicalWidth: Math.floor(
            imageRootSize.logicalWidth - logicalPosX - logicalPxSaveMenuWidth,
          ),
        }
      }
    }

    const newAllowedTextBox: BoundingBox2dLogicalPixels =
      calculateAllowedTextBox(imageRootSize)
    setAllowedPoiTextBoundingBox(newAllowedTextBox)
    requestAnimationFrame(() => {
      const paragraphElement = poiTextRef.current
      if (!paragraphElement) {
        console.error('Expected textDiv to be defined.')
        return
      }
      if (!(paragraphElement instanceof HTMLParagraphElement)) {
        console.error(
          'Expected paragraphElement to be an HTMLParagraphElement.',
        )
        return
      }
      const spanNodes = paragraphElement.childNodes
      if (!spanNodes) {
        console.error('Expected spanNodes to be defined.')
        return
      }
      const spanRects = Object.values(spanNodes).flatMap((node) => {
        if (!(node instanceof HTMLSpanElement)) {
          console.error('Expected node to be a HTMLSpanElement.')
          return []
        }
        return [node.getBoundingClientRect()]
      })
      const outerTextRect = calculateOuterDOMRect(spanRects)
      if (!outerTextRect) {
        console.error('Expected outerTextRect to be defined.')
        return
      }
      const componentRootElement = componentRoot.current
      if (!componentRootElement) {
        console.error('Expected parentElement to be defined.')
        return
      }
      const componentRect = componentRootElement.getBoundingClientRect()

      // Calculate the Smart Dash position.
      const poiX = wiperBox.logicalPosX + wiperBox.logicalWidth * 0.6
      const poiY = wiperBox.logicalPosY - wiperBox.logicalHeight * 0.4

      // Calculate the resulting text coordinates.
      const textMarginBottomPx = 4
      const textRelativeBottom =
        outerTextRect.bottom - componentRect.top + textMarginBottomPx
      const textRelativeRight = outerTextRect.right - componentRect.left
      const textRelativeLeft = outerTextRect.left - componentRect.left

      const poiMarkerRadiusPx = 6

      // Calculate the POI component parameters.
      // TODO: Make the POI component cover the entire MainImageAreaRoot to
      // avoid accidental clipping of the SVG shapes.
      const newPoiProps: PointOfInterestProps = {
        horizontalLineLeft: textRelativeLeft,
        horizontalLineRight: textRelativeRight,
        horizontalLineTop: textRelativeBottom,
        initialTransitionDurationMs: 600,
        logicalPxHeight: Math.floor(imageRootSize.logicalHeight),
        logicalPxWidth: Math.floor(imageRootSize.logicalWidth),
        poiX,
        poiY,
        poiMarkerRadiusPx,
      }

      // Save state and trigger re-render.
      setPoiProps(newPoiProps)
    })
  }, [
    imageRootLazySize,
    imageRootSize,
    latestConfigChangeResult,
    smartDashCampaignMode,
    visibleImageState,
  ])

  const getTruckImageRootHeightPx = (): number => {
    // IMPORTANT: We must calculate this as whole pixels to avoid resampling the
    // child image and causing blurryness.
    const headerHeight = parseInt(
      getComputedStyle(document.documentElement).getPropertyValue(
        '--header-height',
      ),
    )
    const margins = 16
    let height = null
    if (imageRootSize?.logicalHeight) {
      height = imageRootSize.logicalHeight - margins
    }
    // Make room for header and view selectors
    return Math.round(height || viewportHeight - headerHeight - 60)
  }

  const getTruckImageRootWidthPx = (): number => {
    // IMPORTANT: We must calculate this as whole pixels to avoid resampling the
    // child image and causing blurryness.
    return Math.round(imageRootSize?.logicalWidth || viewportWidth)
  }

  const handleViewFrameIconClick = (frame: number, series: string) => {
    handleViewChange({ seriesId: series, frameId: frame })
  }

  const handleSwipe = useCallback(
    (direction: number) => {
      if (!seriesFrame) {
        return
      }
      const { seriesId, frameId } = seriesFrame
      if (seriesId === '360') {
        // Should not happen, 360 has a dedicated component.
        console.warn('Unexpected viewSeries ' + seriesId)
        return
      }
      const i = viewAlternatives.findIndex(
        (view) => view.series === seriesId && view.frame === frameId,
      )
      let j = i + Math.round(direction)
      j = j < 0 ? viewAlternatives.length + j : j
      j = j % viewAlternatives.length
      const next = viewAlternatives[j]
      handleViewChange({ seriesId: next.series, frameId: next.frame })
    },
    [handleViewChange, viewAlternatives, seriesFrame],
  )

  const bindDrag = useDrag(
    (drag) => {
      const xSwipe = drag.swipe[0]
      if (xSwipe === 0) {
        return
      }
      handleSwipe(xSwipe)
    },
    {
      axis: 'x',
      swipe: { distance: 25, duration: 2000, velocity: [0.05, 0.05] },
    },
  )

  const disclaimer = <Disclaimer />
  if (disclaimer === null) {
    alert('no disclaimer')
  }

  const handleCampaignClick: MouseEventHandler = useCallback(
    (ev) => {
      ev.stopPropagation()
      const readmoreSubmenuId = 'SUBMENU_' + SMART_DASH_READMORE_FAMILY_ID
      const selectedCampaignItem =
        latestConfigChangeResult?.smartDashItems?.find((item) => item.selected)
      const firstCampaignItem = latestConfigChangeResult?.smartDashItems?.at(0)
      const campaignItem = selectedCampaignItem ?? firstCampaignItem
      if (!campaignItem) {
        throw new Error('Expected campaignItem to be defined.')
      }
      if (!campaignItem.menuPath) {
        throw new Error('Expected campaignItem.menuPath to be defined.')
      }
      navigateToMenu(campaignItem.id, campaignItem.menuPath, true)

      // TODO: Merge the callback and the Redux action.
      dispatch(
        setReadMoreNode({
          id: readmoreSubmenuId,
          title: null,
        }),
      )
      dispatch(openPanelReadMore())
    },
    [latestConfigChangeResult, navigateToMenu, dispatch],
  )

  const handleCampaignClickWithTrackingForVariantA: MouseEventHandler =
    useCallback(
      (ev) => {
        const adobeEvent: ScaniaAdobeTrackingClickSmartDashCampaignEvent = {
          event: ScaniaAdobeEventId.SmartDashCampaignClick,
          eventInfo: {
            clickTarget: 'CAMPAIGN_TEXT',
            campaignVariant: 'VARIANT_A_LINES_AND_CURVE',
          },
        }
        pushAdobeEvent(adobeEvent)
        handleCampaignClick(ev)
      },
      [handleCampaignClick],
    )

  const handleCampaignClickWithTrackingForVariantB: MouseEventHandler =
    useCallback(
      (ev) => {
        const adobeEvent: ScaniaAdobeTrackingClickSmartDashCampaignEvent = {
          event: ScaniaAdobeEventId.SmartDashCampaignClick,
          eventInfo: {
            clickTarget: 'CAMPAIGN_TEXT',
            campaignVariant: 'VARIANT_B_ORANGE_BLOB',
          },
        }
        pushAdobeEvent(adobeEvent)
        handleCampaignClick(ev)
      },
      [handleCampaignClick],
    )

  const handleCampaignCloseClickWithTrackingForVariantB: MouseEventHandler =
    useCallback((ev) => {
      ev.stopPropagation()
      const adobeEvent: ScaniaAdobeTrackingClickSmartDashCampaignEvent = {
        event: ScaniaAdobeEventId.SmartDashCampaignClick,
        eventInfo: {
          clickTarget: 'CLOSE_BUTTON',
          campaignVariant: 'VARIANT_B_ORANGE_BLOB',
        },
      }
      pushAdobeEvent(adobeEvent)
      setUserHasClickedOrangeBlobCross(true)
    }, [])

  // TODO: Figure out how to take the element constructors as parameters and use
  // them in the template. JSXElementConstructor? Seems to work with "any" as
  // generic parameter in combination with styled components, but there must be
  // a better way?
  function buildSmartDashCampaignSpans() {
    const smartDashName = t(TextId.CAMPAIGN_SMART_DASH_NAME).trim() // 'Smart Dash'
    const smartDashLinkText = t(TextId.CAMPAIGN_SMART_DASH_TEXT).trim() // "Explore Scania's new"
    const subStrings = smartDashLinkText.split('{}')
    const subStringsLastIndex = subStrings.length - 1
    const nbsp = <>&nbsp;</>
    return subStrings
      .map((s) => s.trim())
      .map((s, i) => {
        const maybeNbsp = i === subStringsLastIndex ? '' : nbsp
        return s === '' ? (
          <PoiTextStrong key={`smart-dash-campaign-text-${i}-${smartDashName}`}>
            {smartDashName}
            {maybeNbsp}
          </PoiTextStrong>
        ) : (
          <span key={`smart-dash-campaign-text-${i}-${s}`}>
            {s}
            {maybeNbsp}
          </span>
        )
      })
  }
  const smartDashSpans = buildSmartDashCampaignSpans()

  const canShowBodyBuildForMarketAndConfigResult =
    canShowBodyBuildForMarketAndConfig(marketSettings, latestConfigChangeResult)

  return (
    <MainImageAreaRoot ref={componentRoot}>
      <ImageRoot
        data-name="ImageRoot"
        ref={imageRoot}
        $heightPx={getTruckImageRootHeightPx()}
        $widthPx={getTruckImageRootWidthPx()}
        {...bindDrag()}
      ></ImageRoot>
      {smartDashCampaignMode === SmartDashCampaignMode.OrangeBlob &&
        !userHasClickedOrangeBlobCross &&
        imageAndConfigAreCompatibleWithSmartDashCampaign(
          visibleImageState,
          latestConfigChangeResult,
        ) && (
          <OrangeBlob
            handleClick={handleCampaignClickWithTrackingForVariantB}
            handleCrossClick={handleCampaignCloseClickWithTrackingForVariantB}
          />
        )}
      {
        // Using the entire poiProps as key here to always trigger a fresh
        // instance of this component including a fresh reset animation for any
        // input change. If this feel annoying, consider limiting when the
        // animation plays, perhaps only for frame or series change.
        poiProps && (
          <PointOfInterest key={JSON.stringify(poiProps)} {...poiProps} />
        )
      }
      {allowedPoiTextBoundingBox && (
        <PoiTextAllowedArea
          data-name={'PoiAllowedTextBox'}
          style={{
            visibility: poiProps ? 'visible' : 'hidden',
            top: allowedPoiTextBoundingBox.logicalPosY,
            left: allowedPoiTextBoundingBox.logicalPosX,
            height: allowedPoiTextBoundingBox.logicalHeight,
            width: allowedPoiTextBoundingBox.logicalWidth,
          }}
        >
          <PoiTextHitbox
            onClick={handleCampaignClickWithTrackingForVariantA}
            ref={poiTextHitboxRef}
          >
            <p ref={poiTextRef} style={{ textAlign: 'right' }}>
              <PoiSvgInfoWrapper>
                <SvgInfo />
              </PoiSvgInfoWrapper>
              {smartDashSpans}
            </p>
          </PoiTextHitbox>
        </PoiTextAllowedArea>
      )}
      <FrameIconsWrapper>
        {canShowBodyBuildForMarketAndConfigResult && (
          <BodyBuildToggle
            disabled={!canShowBodyBuildForSeriesAndFrame(seriesFrame)}
            size={
              (imageRootSize?.logicalWidth || 0) > 600
                ? ButtonSize.large
                : ButtonSize.small
            }
          />
        )}
        {viewAlternatives.map((view) => (
          <ViewFrameIconWrapper
            onClick={() => handleViewFrameIconClick(view.frame, view.series)}
            key={view.key}
          >
            <ViewFrameIcon
              $hasBodyBuild={canShowBodyBuildForMarketAndConfigResult}
              key={view.key}
              $isSelected={
                seriesFrame?.seriesId === view.series &&
                seriesFrame.frameId === view.frame
                  ? true
                  : false
              }
            ></ViewFrameIcon>
          </ViewFrameIconWrapper>
        ))}
        <PanoramaTdsChip
          size={(imageRootSize?.logicalWidth || 0) > 600 ? 'lg' : 'sm'}
          onClick={handlePanoramaClick}
        >
          <span slot="label">
            <SvgPanorama />
            {t(TextId.LABEL_VIEW_INTERIOR)}
          </span>
        </PanoramaTdsChip>
      </FrameIconsWrapper>
      {disclaimer && <DisclaimerWrapper>{disclaimer}</DisclaimerWrapper>}
    </MainImageAreaRoot>
  )
}
