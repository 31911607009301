import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import {
  getAppState,
  setUserHasMouseEnabledDevice,
  setUserHasMouseWheelEnabledDevice,
} from '../store/appSlice'

const useDetectMouseCapabilities = (): void => {
  const { isMouseEnabled, hasScrollWheel } = useAppSelector(getAppState)
  const dispatch = useAppDispatch()

  useEffect(() => {
    /* Detect if user has mouse capabilities */
    if (typeof isMouseEnabled === 'boolean') {
      window.removeEventListener('mouseover', setMouseOverEnabled)
      return
    }
    window.addEventListener('mouseover', setMouseOverEnabled)
    function setMouseOverEnabled() {
      dispatch(setUserHasMouseEnabledDevice(true))
      window.removeEventListener('mouseover', setMouseOverEnabled)
    }
    return () => {
      window.removeEventListener('mouseover', setMouseOverEnabled)
    }
  }, [dispatch, isMouseEnabled])

  useEffect(() => {
    /* Detect if user has mouse wheel capabilities */
    if (typeof hasScrollWheel === 'boolean') {
      window.removeEventListener('wheel', setWheelEnabled)
      return
    }
    window.addEventListener('wheel', setWheelEnabled)
    function setWheelEnabled() {
      dispatch(setUserHasMouseWheelEnabledDevice(true))
      window.removeEventListener('wheel', setWheelEnabled)
    }
    return () => {
      window.removeEventListener('wheel', setWheelEnabled)
    }
  }, [dispatch, hasScrollWheel])
}

export default useDetectMouseCapabilities
