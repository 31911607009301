import styled from 'styled-components'
import { useCallback, useEffect, useState } from 'react'

// TODO: Bring back the new panels and remove the old ones.
import { useLocation } from 'react-router-dom'

import useTexts from '../../utils/useTexts'

// IMPORTANT: THIS SVG MUST BE IMPORTED AS A DATA URL, not like a react
// component. The reason seems to be that this file contains id attributes and
// references that could cause rendering errors when using multiple instances of
// this SVG.
import ScaniaSymbol from '../../assets/img/scania-symbol.svg'

import {
  SvgBurger,
  SvgInfo,
  SvgNotification,
  SvgProfile,
  SvgScaniaWordmark,
} from '../SvgImports'
import { TestElementTypeId } from '../../types/TestAttributeId'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { BreakpointWidthPx } from '../../css/BreakpointWidthPx'
import { toggleMainMenu } from '../../store/menuSlice'
import {
  closePanelInfo,
  closePanelLoginGarage,
  openPanelInfo,
  openPanelLoginGarage,
  selectSidePanelState,
} from '../../store/sidePanelSlice'
import { getMarketSettingsState } from '../../store/appSlice'

const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  height: var(--header-height);
  background: var(--tds-blue-800) !important;
`

const DesktopContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-left: 24px;

  @media screen and (max-width: ${BreakpointWidthPx.Tablet}px) {
    display: none;
  }
`

const LogotypeWrapper = styled.div`
  cursor: pointer;

  svg {
    height: 22px;
    width: auto;
    path {
      fill: var(--tds-white);
    }
  }
`

const Separator = styled.div`
  height: 2em;
  width: 1px;
  background-color: var(--tds-blue-700);
  margin: 0 16px;
  display: block;
`

const Market = styled.div`
  font-family: 'Scania Sans Headline', Helvetica, Arial, sans-serif;
  white-space: nowrap;
  font-size: 14px;
  text-transform: uppercase;
  color: var(--tds-white);
  opacity: 60%;
  cursor: default;
  display: block;
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 64px;
  cursor: pointer;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: var(--tds-blue-700);
    }
  }

  svg {
    height: 20px;
    width: 20px;
    color: var(--tds-white);
  }
`

const LogoSymbolWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 64px;
  cursor: pointer;

  @media screen and (max-width: ${BreakpointWidthPx.Tablet}px) {
    width: 20%;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: var(--tds-blue-700);
    }
  }

  img {
    width: 32px;
  }
`

const MobileContainer = styled.div`
  display: none;
  width: 100%;
  height: 100%;
  justify-content: space-between;

  @media screen and (max-width: ${BreakpointWidthPx.Tablet}px) {
    display: flex;
  }
`

const MobileContainerRight = styled.div`
  display: flex;
  justify-content: flex-end;
`

const IconContainerPortrait = styled.div`
  width: 64px;
  min-width: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: var(--tds-blue-700);
    }
  }

  svg {
    height: 20px;
    width: 20px;
    color: var(--tds-white);
  }
`

const RedDot = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: var(--tds-red);
  color: var(--tds-white);
  text-align: center;
  font-size: 10px;
  position: absolute;
  right: 22px;
  top: 17px;
`

const SeparatorFullHeigth = styled.div`
  height: 64px;
  width: 1px;
  background-color: var(--tds-blue-700);
`

export function AppHeader() {
  const marketSettings = useAppSelector(getMarketSettingsState)
  const dispatch = useAppDispatch()
  const t = useTexts()
  const {
    loginGarageOpen: sidePanelLogInIsOpen,
    infoOpen: sidePanelInfoIsOpen,
  } = useAppSelector(selectSidePanelState)
  const location = useLocation()
  const [isBuildModePage, setIsBuildModePage] = useState<boolean>(false)

  useEffect(() => {
    const page = location.pathname
    if (page.split('/').includes('build')) {
      setIsBuildModePage(true)
    }
  }, [location.pathname])

  const handleMainMenuIconClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      dispatch(toggleMainMenu())
    },
    [dispatch],
  )

  const onAccountClick = useCallback(
    (e: React.MouseEvent) => {
      if (!sidePanelLogInIsOpen) {
        e.stopPropagation()
        dispatch(openPanelLoginGarage())
      } else {
        dispatch(closePanelLoginGarage())
      }
    },
    [dispatch, sidePanelLogInIsOpen],
  )

  const onMoreClick = useCallback(
    (e: React.MouseEvent) => {
      if (!sidePanelInfoIsOpen) {
        e.stopPropagation()
        dispatch(openPanelInfo())
      } else {
        dispatch(closePanelInfo())
      }
    },
    [dispatch, sidePanelInfoIsOpen],
  )

  const onNotificationClick = () => {
    console.log('TODO')
    alert('Not implemneted yet')
  }

  const showUserButton = marketSettings?.garageEnabled

  return (
    <StyledHeader data-name="Header">
      <MobileContainer>
        {isBuildModePage ? (
          <IconContainerPortrait onClick={handleMainMenuIconClick}>
            <SvgBurger />
          </IconContainerPortrait>
        ) : (
          <div></div>
        )}
        <MobileContainerRight>
          <IconContainerPortrait
            onClick={onNotificationClick}
            style={{ display: 'none' }}
          >
            <SvgNotification />
            <RedDot>1</RedDot>
          </IconContainerPortrait>
          {showUserButton && (
            <IconContainerPortrait onClick={onAccountClick}>
              <SvgProfile />
            </IconContainerPortrait>
          )}
          <IconContainerPortrait onClick={onMoreClick}>
            <SvgInfo />
          </IconContainerPortrait>
          <LogoSymbolWrapper style={{ width: '64px' }}>
            <a href={t('LINK_WORD_MARK')} target={'_blank'} rel="noreferrer">
              <img src={ScaniaSymbol} alt="Scania Symbol" />
            </a>
          </LogoSymbolWrapper>
        </MobileContainerRight>
      </MobileContainer>
      <DesktopContainer>
        <LogotypeWrapper
          data-test-element-type={TestElementTypeId.HeaderLogoType}
        >
          <a href={t('LINK_WORD_MARK')} target={'_blank'} rel="noreferrer">
            <SvgScaniaWordmark title="Home" />
          </a>
        </LogotypeWrapper>
        <Separator />
        <Market data-test-element-type={TestElementTypeId.HeaderMarketName}>
          {t('MARKET')}
        </Market>
      </DesktopContainer>
      <DesktopContainer>
        <SeparatorFullHeigth style={{ display: 'none' }} />
        <IconContainer
          onClick={onNotificationClick}
          style={{ display: 'none' }}
        >
          <SvgNotification />
        </IconContainer>
        {showUserButton && (
          <>
            <SeparatorFullHeigth />
            <IconContainer
              data-test-element-type={TestElementTypeId.HeaderAccountIcon}
              className="account"
              onClick={onAccountClick}
            >
              <SvgProfile />
            </IconContainer>
          </>
        )}
        <SeparatorFullHeigth />
        <IconContainer
          data-test-element-type={TestElementTypeId.HeaderInfoIcon}
          onClick={onMoreClick}
        >
          <SvgInfo />
        </IconContainer>
        <SeparatorFullHeigth />
        <LogoSymbolWrapper
          data-test-element-type={TestElementTypeId.HeaderLogoTypeIcon}
        >
          <a href={t('LINK_WORD_MARK')} target={'_blank'} rel="noreferrer">
            <img src={ScaniaSymbol} alt="Scania Symbol" />
          </a>
        </LogoSymbolWrapper>
      </DesktopContainer>
    </StyledHeader>
  )
}
