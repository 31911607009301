// TODO: Use this page for all Guided Offering views and use
// /go/[desiredselections] as URL. Also rename this page to just
// GuidedOfferingPage.tsx as part of that change.

import { useCallback, useEffect, useRef } from 'react'
import ErrorBoundary from '../../../utils/ErrorBoundary'
import useText from '../../../utils/useTexts'
import { ImgCarousel } from './ImgCarousel'
import TrucktypePage from './_TrucktypePage'
import WheelconfPage from './_WheelconfPage'
import styled from 'styled-components'
import { Timeline } from '../Timeline'
import { CustomScrollbarMixin } from '../../../css/CustomScrollbarMixin'
import { NavigationButtons } from '../NavigationButtons'
import { CardData } from '../../../types/GuidedOffering/CardData'
import {
  FAMILY_ID_CAB,
  FAMILY_ID_CAB_SERIES,
  FAMILY_ID_TRUCK_TYPE,
  FAMILY_ID_WHEEL_CONFIGURATION,
} from '../../../api/constants'
import {
  GoPageId,
  GoStateSyncSettings,
  useSyncClientAndBackendState,
} from '../syncBackendStateHook'
import { PortraitFooter } from '../../../components/Footer/PortraitFooter'
import { ScrollContent } from '../ScrollContent'
import { BreakpointWidthPx } from '../../../css/BreakpointWidthPx'
import { useTimelineSkipWithTracking } from '../skipWithTracking'
import { BuildLinkToNextQuestionParams } from '../BuildLinkToNextQuestionParams'
import {
  GoChoiceClickHandler,
  useGoChoiceClickHandlers,
} from '../goChoiceClickHook'
import {
  GoReadMoreClickHandler,
  useHandleGoReadMoreClick,
} from '../goReadmoreUtil'
import { resolveTrackingStepName } from '../goUserTracking'
import {
  UrlParamMode,
  getUrlCidBevSemiliveParameters,
} from '../../../utils/getUrlCidParameter'
import { urlHasBevParam } from '../urlHasBevParam'

const MultiLayoutRoot = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  ${CustomScrollbarMixin};
  overflow-y: auto;
  overflow-x: hidden;
`

const PageContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  justify-content: center;
`

// Needs to be absolute to span the expected height in this component.
// The ImgCarousel should be using 100% width and height.
const ImgCarouselAbsoluteRoot = styled.div`
  display: block;

  height: 100%;
  max-height: 100%;

  max-width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  position: absolute;
  width: 100%;
`

const familyIdsForThisPage: string[] = [
  FAMILY_ID_TRUCK_TYPE,
  FAMILY_ID_WHEEL_CONFIGURATION,
  FAMILY_ID_CAB_SERIES,
  FAMILY_ID_CAB,
]

// TODO: This can be simplified if we use this page for all Guided Offering
// views!
const stateSyncSettings: GoStateSyncSettings = {
  pageId: GoPageId.Slider,
}

export interface MultiLayoutPageProps {
  handleFatalError: VoidFunction // TODO: Move to Redux?
  handleGoBack: VoidFunction
  handleTimelineSkip: VoidFunction
}

export function MultiLayoutPage({
  handleFatalError,
  handleGoBack,
  handleTimelineSkip,
}: MultiLayoutPageProps) {
  const t = useText()
  const goClientState = useSyncClientAndBackendState(
    stateSyncSettings,
    handleFatalError,
  )
  const { handleCardClick, handleSkipQuestion } = useGoChoiceClickHandlers(
    buildLinkToNextQuestion,
  )
  const handleTimelineSkipWithTracking =
    useTimelineSkipWithTracking(handleTimelineSkip)
  const rootElement = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    // When a new set of choices arrives, scroll to the top.
    rootElement.current?.scrollTo(0, 0)
  }, [goClientState])

  const getPageHeaderText = useCallback(() => {
    const familyId = goClientState?.lastResponse?.question?.etelFamily
    if (!familyId) {
      return ''
    }
    if (!familyIdsForThisPage.some((id) => id === familyId)) {
      return ''
    }
    return t('SQ_HEADER_', familyId)
  }, [goClientState, t])

  const familyId = goClientState?.lastResponse.question?.etelFamily || null

  const isReady =
    goClientState?.cards && familyIdsForThisPage.some((id) => id === familyId)

  const stepName = (familyId && resolveTrackingStepName(familyId)) || null
  const handleReadMoreClick = useHandleGoReadMoreClick({
    stepName,
  })

  return (
    <ErrorBoundary>
      <MultiLayoutRoot ref={rootElement}>
        <ScrollContent>
          <Timeline
            header={isReady ? getPageHeaderText() : t('LOADING') + '...'}
            isReady={isReady}
          />
          {isReady && goClientState.cards && (
            <>
              <PageContentWrapper data-name="PageContentWrapper">
                <InnerContentWrapper>
                  {contentsSelector({
                    familyId,
                    handleCardClick,
                    handleReadMoreClick,
                    cards: goClientState.cards,
                  })}
                </InnerContentWrapper>
              </PageContentWrapper>
              <NavigationButtons
                handleStartConfigurationClick={handleTimelineSkipWithTracking}
                handleGoBack={handleGoBack}
                handleGoNext={handleSkipQuestion}
                isFirstStep={false}
                isLastStep={false}
              />
              <PortraitFooter
                displayAtScreenMaxWidth={BreakpointWidthPx.Tablet}
              />
            </>
          )}
        </ScrollContent>
      </MultiLayoutRoot>
    </ErrorBoundary>
  )
}

// TODO: Eliminate this? Temporary refactoring helper replacing `SliderContainerSC`.
const InnerContentWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100vw;
  min-height: 300px;
`

interface SelectorParams {
  cards: CardData[]
  handleCardClick: GoChoiceClickHandler
  handleReadMoreClick: GoReadMoreClickHandler
  familyId: string | null
}

function buildLinkToNextQuestion({
  id,
  marketLanguage,
  urlSelection,
}: BuildLinkToNextQuestionParams) {
  const pageName = id.split('~')[0] === FAMILY_ID_CAB ? 'options' : 'slider' // TODO: use /go/ for all Guided Offering views.
  const urlCidParams = getUrlCidBevSemiliveParameters(
    urlHasBevParam() ? UrlParamMode.IncludeBev : UrlParamMode.ExcludeBev,
  )
  const url = `/go/${pageName}/${marketLanguage.market}/${marketLanguage.language}/${urlSelection}`
  const link = !urlCidParams ? url : url + '?' + urlCidParams.toString()
  return link
}

function contentsSelector({
  familyId,
  handleCardClick,
  handleReadMoreClick,
  cards,
}: SelectorParams) {
  if (familyId === FAMILY_ID_TRUCK_TYPE) {
    return (
      <TrucktypePage
        cards={cards}
        handleCardClick={handleCardClick}
        handleReadMoreClick={handleReadMoreClick}
      />
    )
  } else if (familyId === FAMILY_ID_WHEEL_CONFIGURATION) {
    return (
      <WheelconfPage
        cards={cards}
        etelFamilyId={familyId}
        handleCardClick={handleCardClick}
        handleReadMoreClick={handleReadMoreClick}
      />
    )
  } else if (familyId === FAMILY_ID_CAB_SERIES || familyId === FAMILY_ID_CAB) {
    return (
      <ImgCarouselAbsoluteRoot>
        <ImgCarousel
          cards={cards}
          etelFamilyId={familyId}
          handleCardClick={handleCardClick}
          handleReadMoreClick={handleReadMoreClick}
        />
      </ImgCarouselAbsoluteRoot>
    )
  } else {
    throw new Error('Unexpected familyId for contentSelector: ' + familyId)
  }
}
