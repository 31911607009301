/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface MainMenuInfo
 */
export interface MainMenuInfo {
  /**
   *
   * @type {string}
   * @memberof MainMenuInfo
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof MainMenuInfo
   */
  shortText?: string | null
  /**
   *
   * @type {string}
   * @memberof MainMenuInfo
   */
  name?: string | null
}

/**
 * Check if a given object implements the MainMenuInfo interface.
 */
export function instanceOfMainMenuInfo(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value

  return isInstance
}

export function MainMenuInfoFromJSON(json: any): MainMenuInfo {
  return MainMenuInfoFromJSONTyped(json, false)
}

export function MainMenuInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): MainMenuInfo {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    shortText: !exists(json, 'short_text') ? undefined : json['short_text'],
    name: !exists(json, 'name') ? undefined : json['name'],
  }
}

export function MainMenuInfoToJSON(value?: MainMenuInfo | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    short_text: value.shortText,
    name: value.name,
  }
}
