import styled from 'styled-components'

interface AppStyleProps {
  $appHeight: number
}

const AppStyle = styled.div<AppStyleProps>`
  overflow: hidden;
  width: 100vw;
  min-width: 100vw;
  //height: var(--app-height);
  height: ${(props) => props.$appHeight + 'px'};
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
  align-items: center;
  //background: hsl(227, 9%, 61%);
  font-family: 'Scania Sans';
  & > h2 {
    text-align: center;
    color: #cccccc;
    text-shadow: 1px 1px 0px rgba(0, 0, 0, 1);
    margin: 0;
  }
`

export default AppStyle
