/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface SummaryItem
 */
export interface SummaryItem {
  /**
   *
   * @type {string}
   * @memberof SummaryItem
   */
  itemId: string
  /**
   *
   * @type {string}
   * @memberof SummaryItem
   */
  itemText: string
  /**
   *
   * @type {string}
   * @memberof SummaryItem
   */
  menuId: string
  /**
   *
   * @type {string}
   * @memberof SummaryItem
   */
  menuText: string
  /**
   *
   * @type {string}
   * @memberof SummaryItem
   */
  menuPath?: string | null
}

/**
 * Check if a given object implements the SummaryItem interface.
 */
export function instanceOfSummaryItem(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'itemId' in value
  isInstance = isInstance && 'itemText' in value
  isInstance = isInstance && 'menuId' in value
  isInstance = isInstance && 'menuText' in value

  return isInstance
}

export function SummaryItemFromJSON(json: any): SummaryItem {
  return SummaryItemFromJSONTyped(json, false)
}

export function SummaryItemFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SummaryItem {
  if (json === undefined || json === null) {
    return json
  }
  return {
    itemId: json['ItemId'],
    itemText: json['ItemText'],
    menuId: json['MenuId'],
    menuText: json['MenuText'],
    menuPath: !exists(json, 'MenuPath') ? undefined : json['MenuPath'],
  }
}

export function SummaryItemToJSON(value?: SummaryItem | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    ItemId: value.itemId,
    ItemText: value.itemText,
    MenuId: value.menuId,
    MenuText: value.menuText,
    MenuPath: value.menuPath,
  }
}
