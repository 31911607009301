import { useState } from 'react'
import styled from 'styled-components'
import { SvgChevronDown } from '../SvgImports'
import useTexts from '../../utils/useTexts'
import { TdsBanner } from '@scania/tegel-react'

const NewsBannerRoot = styled.div`
  position: absolute;
  //top: calc(0 - var(--header-height));
  top: -64px;
  width: 100%;
  z-index: 20;
  padding: 8px;
`

interface ReadMoreProps {
  $showReadMore: boolean
}

const ReadMoreWrapper = styled.div<ReadMoreProps>`
  margin-top: 16px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      cursor: pointer;
      color: var(--tds-grey-700);
    }
  }

  svg {
    height: 16px;
    width: 16px;
    margin-left: 24px;

    transform: ${(props) =>
      props.$showReadMore ? 'rotate(0deg)' : 'rotate(-90deg)'};
    transition-duration: 150ms;
    transition-property: transform;
  }
`

export function NewsBanner() {
  const t = useTexts()
  const [showMore, setShowMore] = useState<boolean>(false)

  const handleReadMoreClick = () => {
    if (showMore) {
      setShowMore(false)
    } else setShowMore(true)
  }

  return (
    <NewsBannerRoot data-name="NewsBanner">
      <TdsBanner variant="information" header={t('BANNER_HEADER')}>
        <div slot="subheader">
          {t('BANNER_SUBHEADER')}
          <ReadMoreWrapper
            slot="actions"
            $showReadMore={showMore}
            onClick={handleReadMoreClick}
          >
            {t('LABEL_READ_MORE')}
            <SvgChevronDown />
          </ReadMoreWrapper>
          {showMore && <div>{t('BANNER_TEXT')}</div>}
        </div>
      </TdsBanner>
    </NewsBannerRoot>
  )
}
