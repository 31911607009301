import styled from 'styled-components'
import { BreakpointWidthPx } from '../../css/BreakpointWidthPx'

export const ScrollContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: ${BreakpointWidthPx.Tablet}px) {
    min-height: calc(
      var(--app-height) - var(--header-height) + var(--footer-height-mobile)
    );
  }
`
