/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * Name and version of the backend application.
 *
 * Deprecated, remove this when all client code is using the new
 * properties.
 * @export
 * @interface BuildInfoApplicationVersion
 */
export interface BuildInfoApplicationVersion {
  /**
   * Name of the versioned item
   * @type {string}
   * @memberof BuildInfoApplicationVersion
   */
  id: string
  /**
   * Version of the item
   * @type {string}
   * @memberof BuildInfoApplicationVersion
   */
  version: string
}

/**
 * Check if a given object implements the BuildInfoApplicationVersion interface.
 */
export function instanceOfBuildInfoApplicationVersion(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'version' in value

  return isInstance
}

export function BuildInfoApplicationVersionFromJSON(
  json: any,
): BuildInfoApplicationVersion {
  return BuildInfoApplicationVersionFromJSONTyped(json, false)
}

export function BuildInfoApplicationVersionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BuildInfoApplicationVersion {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['Id'],
    version: json['Version'],
  }
}

export function BuildInfoApplicationVersionToJSON(
  value?: BuildInfoApplicationVersion | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    Id: value.id,
    Version: value.version,
  }
}
