/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GoWizardCloseResponseWrapperGowizardClose } from './GoWizardCloseResponseWrapperGowizardClose'
import {
  GoWizardCloseResponseWrapperGowizardCloseFromJSON,
  GoWizardCloseResponseWrapperGowizardCloseFromJSONTyped,
  GoWizardCloseResponseWrapperGowizardCloseToJSON,
} from './GoWizardCloseResponseWrapperGowizardClose'

/**
 * Legacy response wrapper structure to allow matching the original
 * configurator web API.
 *
 * TODO: This should be removed after the .Net Core and ScdsClient
 * migration has been completed.
 * @export
 * @interface GoWizardContinueV2ResponseWrapper
 */
export interface GoWizardContinueV2ResponseWrapper {
  /**
   *
   * @type {GoWizardCloseResponseWrapperGowizardClose}
   * @memberof GoWizardContinueV2ResponseWrapper
   */
  gowizardContinueV2: GoWizardCloseResponseWrapperGowizardClose
}

/**
 * Check if a given object implements the GoWizardContinueV2ResponseWrapper interface.
 */
export function instanceOfGoWizardContinueV2ResponseWrapper(
  value: object,
): boolean {
  let isInstance = true
  isInstance = isInstance && 'gowizardContinueV2' in value

  return isInstance
}

export function GoWizardContinueV2ResponseWrapperFromJSON(
  json: any,
): GoWizardContinueV2ResponseWrapper {
  return GoWizardContinueV2ResponseWrapperFromJSONTyped(json, false)
}

export function GoWizardContinueV2ResponseWrapperFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GoWizardContinueV2ResponseWrapper {
  if (json === undefined || json === null) {
    return json
  }
  return {
    gowizardContinueV2: GoWizardCloseResponseWrapperGowizardCloseFromJSON(
      json['gowizard_continue_v2'],
    ),
  }
}

export function GoWizardContinueV2ResponseWrapperToJSON(
  value?: GoWizardContinueV2ResponseWrapper | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    gowizard_continue_v2: GoWizardCloseResponseWrapperGowizardCloseToJSON(
      value.gowizardContinueV2,
    ),
  }
}
