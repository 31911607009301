import { keyframes } from 'styled-components'

export const blinkKeyframes = keyframes`
  0% {
    background-color: var(--bg-color);
  }
  50% {
    background-color: var(--tds-blue-200);
  }
  100% {
    background-color: var(--bg-color);
  }
`
