import { configureStore } from '@reduxjs/toolkit'
import { sidePanelSlice } from './sidePanelSlice'
import { menuSlice } from './menuSlice'
import { appSlice } from './appSlice'
import { sessionDataSlice } from './sessionDataSlice'
import { modalSlice } from './modalSlice'
import { truckInfoSlice } from './truckInfoSlice'
import { listenerMiddleware } from './listenerMiddleware'

export const store = configureStore({
  reducer: {
    appData: appSlice.reducer,
    sessionData: sessionDataSlice.reducer,
    sidePanel: sidePanelSlice.reducer,
    menu: menuSlice.reducer,
    modal: modalSlice.reducer,
    truckInfo: truckInfoSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(listenerMiddleware.middleware),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
