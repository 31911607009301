/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ConfigChangeResult } from './ConfigChangeResult'
import {
  ConfigChangeResultFromJSON,
  ConfigChangeResultFromJSONTyped,
  ConfigChangeResultToJSON,
} from './ConfigChangeResult'

/**
 *
 * @export
 * @interface ConfigBop
 */
export interface ConfigBop {
  /**
   *
   * @type {string}
   * @memberof ConfigBop
   */
  resultCode: string
  /**
   *
   * @type {Array<ConfigChangeResult>}
   * @memberof ConfigBop
   */
  bops?: Array<ConfigChangeResult> | null
}

/**
 * Check if a given object implements the ConfigBop interface.
 */
export function instanceOfConfigBop(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'resultCode' in value

  return isInstance
}

export function ConfigBopFromJSON(json: any): ConfigBop {
  return ConfigBopFromJSONTyped(json, false)
}

export function ConfigBopFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ConfigBop {
  if (json === undefined || json === null) {
    return json
  }
  return {
    resultCode: json['result_code'],
    bops: !exists(json, 'bops')
      ? undefined
      : json['bops'] === null
      ? null
      : (json['bops'] as Array<any>).map(ConfigChangeResultFromJSON),
  }
}

export function ConfigBopToJSON(value?: ConfigBop | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    result_code: value.resultCode,
    bops:
      value.bops === undefined
        ? undefined
        : value.bops === null
        ? null
        : (value.bops as Array<any>).map(ConfigChangeResultToJSON),
  }
}
