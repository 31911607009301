// https://styled-components.com/docs/api#create-a-declarations-file

import { ReactNode } from 'react'
import { ThemeProvider } from 'styled-components'

interface themeProps {
  children: ReactNode
}

const Theme = ({ children }: themeProps) => {
  const colors = {
    scDarkBlue: '#041E42',
    scWhite: '#FFFFFF',
    scGreyLight: '#CBC9C7',
    scGreyDark: '#53565A',
    scBlack: '#000000',
    colorSelected: '#16417F',
  }

  return (
    <ThemeProvider
      theme={{
        ...colors,
      }}
    >
      {children}
    </ThemeProvider>
  )
}

export default Theme
