/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface FactoryModelSummaryItem
 */
export interface FactoryModelSummaryItem {
  /**
   *
   * @type {string}
   * @memberof FactoryModelSummaryItem
   */
  submenuId?: string | null
  /**
   *
   * @type {string}
   * @memberof FactoryModelSummaryItem
   */
  submenuShortText?: string | null
  /**
   *
   * @type {string}
   * @memberof FactoryModelSummaryItem
   */
  etelVariantId?: string | null
  /**
   *
   * @type {string}
   * @memberof FactoryModelSummaryItem
   */
  etelVariantShortText?: string | null
  /**
   *
   * @type {string}
   * @memberof FactoryModelSummaryItem
   */
  etelVariantIconUrl?: string | null
}

/**
 * Check if a given object implements the FactoryModelSummaryItem interface.
 */
export function instanceOfFactoryModelSummaryItem(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function FactoryModelSummaryItemFromJSON(
  json: any,
): FactoryModelSummaryItem {
  return FactoryModelSummaryItemFromJSONTyped(json, false)
}

export function FactoryModelSummaryItemFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): FactoryModelSummaryItem {
  if (json === undefined || json === null) {
    return json
  }
  return {
    submenuId: !exists(json, 'SubmenuId') ? undefined : json['SubmenuId'],
    submenuShortText: !exists(json, 'SubmenuShortText')
      ? undefined
      : json['SubmenuShortText'],
    etelVariantId: !exists(json, 'EtelVariantId')
      ? undefined
      : json['EtelVariantId'],
    etelVariantShortText: !exists(json, 'EtelVariantShortText')
      ? undefined
      : json['EtelVariantShortText'],
    etelVariantIconUrl: !exists(json, 'EtelVariantIconUrl')
      ? undefined
      : json['EtelVariantIconUrl'],
  }
}

export function FactoryModelSummaryItemToJSON(
  value?: FactoryModelSummaryItem | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    SubmenuId: value.submenuId,
    SubmenuShortText: value.submenuShortText,
    EtelVariantId: value.etelVariantId,
    EtelVariantShortText: value.etelVariantShortText,
    EtelVariantIconUrl: value.etelVariantIconUrl,
  }
}
