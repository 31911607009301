/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { RfqContactRole } from './RfqContactRole'
import {
  RfqContactRoleFromJSON,
  RfqContactRoleFromJSONTyped,
  RfqContactRoleToJSON,
} from './RfqContactRole'
import type { RfqMode } from './RfqMode'
import { RfqModeFromJSON, RfqModeFromJSONTyped, RfqModeToJSON } from './RfqMode'

/**
 *
 * @export
 * @interface SendRfqRequest
 */
export interface SendRfqRequest {
  /**
   *
   * @type {string}
   * @memberof SendRfqRequest
   */
  sessionId: string
  /**
   *
   * @type {number}
   * @memberof SendRfqRequest
   */
  language: number
  /**
   * Request For Quotation mode.
   * @type {RfqMode}
   * @memberof SendRfqRequest
   */
  mode: RfqMode
  /**
   *
   * @type {string}
   * @memberof SendRfqRequest
   */
  contactEmail: string
  /**
   *
   * @type {string}
   * @memberof SendRfqRequest
   */
  companyName: string
  /**
   *
   * @type {string}
   * @memberof SendRfqRequest
   */
  companyPostalCode: string
  /**
   *
   * @type {RfqContactRole}
   * @memberof SendRfqRequest
   */
  contactRole: RfqContactRole
  /**
   *
   * @type {boolean}
   * @memberof SendRfqRequest
   */
  disclaimerAccepted: boolean
  /**
   *
   * @type {boolean}
   * @memberof SendRfqRequest
   */
  newsletter: boolean
  /**
   * Is allowed to be null if:
   *  - "The Virtual dealer id" is specified in SC Admin. In
   *    this case the GUI should present the user with an
   *    optional checkbox "I don't know" for the dealer choice.
   * @type {string}
   * @memberof SendRfqRequest
   */
  dealerId?: string | null
  /**
   *
   * @type {string}
   * @memberof SendRfqRequest
   */
  contactFirstName?: string | null
  /**
   *
   * @type {string}
   * @memberof SendRfqRequest
   */
  contactFamilyName?: string | null
  /**
   *
   * @type {string}
   * @memberof SendRfqRequest
   */
  companyCity?: string | null
  /**
   *
   * @type {string}
   * @memberof SendRfqRequest
   */
  contactPhone?: string | null
  /**
   *
   * @type {string}
   * @memberof SendRfqRequest
   */
  debugEmail?: string | null
  /**
   *
   * @type {boolean}
   * @memberof SendRfqRequest
   */
  financeInterest?: boolean | null
  /**
   *
   * @type {string}
   * @memberof SendRfqRequest
   */
  messageToDealer?: string | null
  /**
   *
   * @type {string}
   * @memberof SendRfqRequest
   */
  campaignIdentity?: string | null
  /**
   *
   * @type {string}
   * @memberof SendRfqRequest
   */
  organizationNumber?: string | null
  /**
   *
   * @type {string}
   * @memberof SendRfqRequest
   */
  vatNumber?: string | null
}

/**
 * Check if a given object implements the SendRfqRequest interface.
 */
export function instanceOfSendRfqRequest(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'sessionId' in value
  isInstance = isInstance && 'language' in value
  isInstance = isInstance && 'mode' in value
  isInstance = isInstance && 'contactEmail' in value
  isInstance = isInstance && 'companyName' in value
  isInstance = isInstance && 'companyPostalCode' in value
  isInstance = isInstance && 'contactRole' in value
  isInstance = isInstance && 'disclaimerAccepted' in value
  isInstance = isInstance && 'newsletter' in value

  return isInstance
}

export function SendRfqRequestFromJSON(json: any): SendRfqRequest {
  return SendRfqRequestFromJSONTyped(json, false)
}

export function SendRfqRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SendRfqRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    sessionId: json['SessionId'],
    language: json['Language'],
    mode: RfqModeFromJSON(json['Mode']),
    contactEmail: json['ContactEmail'],
    companyName: json['CompanyName'],
    companyPostalCode: json['CompanyPostalCode'],
    contactRole: RfqContactRoleFromJSON(json['ContactRole']),
    disclaimerAccepted: json['DisclaimerAccepted'],
    newsletter: json['Newsletter'],
    dealerId: !exists(json, 'DealerId') ? undefined : json['DealerId'],
    contactFirstName: !exists(json, 'ContactFirstName')
      ? undefined
      : json['ContactFirstName'],
    contactFamilyName: !exists(json, 'ContactFamilyName')
      ? undefined
      : json['ContactFamilyName'],
    companyCity: !exists(json, 'CompanyCity') ? undefined : json['CompanyCity'],
    contactPhone: !exists(json, 'ContactPhone')
      ? undefined
      : json['ContactPhone'],
    debugEmail: !exists(json, 'DebugEmail') ? undefined : json['DebugEmail'],
    financeInterest: !exists(json, 'FinanceInterest')
      ? undefined
      : json['FinanceInterest'],
    messageToDealer: !exists(json, 'MessageToDealer')
      ? undefined
      : json['MessageToDealer'],
    campaignIdentity: !exists(json, 'CampaignIdentity')
      ? undefined
      : json['CampaignIdentity'],
    organizationNumber: !exists(json, 'OrganizationNumber')
      ? undefined
      : json['OrganizationNumber'],
    vatNumber: !exists(json, 'VatNumber') ? undefined : json['VatNumber'],
  }
}

export function SendRfqRequestToJSON(value?: SendRfqRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    SessionId: value.sessionId,
    Language: value.language,
    Mode: RfqModeToJSON(value.mode),
    ContactEmail: value.contactEmail,
    CompanyName: value.companyName,
    CompanyPostalCode: value.companyPostalCode,
    ContactRole: RfqContactRoleToJSON(value.contactRole),
    DisclaimerAccepted: value.disclaimerAccepted,
    Newsletter: value.newsletter,
    DealerId: value.dealerId,
    ContactFirstName: value.contactFirstName,
    ContactFamilyName: value.contactFamilyName,
    CompanyCity: value.companyCity,
    ContactPhone: value.contactPhone,
    DebugEmail: value.debugEmail,
    FinanceInterest: value.financeInterest,
    MessageToDealer: value.messageToDealer,
    CampaignIdentity: value.campaignIdentity,
    OrganizationNumber: value.organizationNumber,
    VatNumber: value.vatNumber,
  }
}
