import { HTMLProps } from 'react'
import { TdsButton, TdsIcon } from '@scania/tegel-react'
import { useAppSelector } from '../../store/hooks'
import { getRfqOrEysButtonEnabled } from '../../store/sessionDataSlice'

export interface RfqEysButtonProps extends HTMLProps<HTMLButtonElement> {
  text: string
  handleClick: (() => void) | null
}

export const RfqEysButton = ({
  text,
  handleClick,
}: RfqEysButtonProps): JSX.Element => {
  const arrowSize = '20px'
  const rfqOrEysButtonEnabled = useAppSelector(getRfqOrEysButtonEnabled)

  return (
    <TdsButton
      variant="primary"
      fullbleed
      onClick={handleClick || undefined}
      text={text}
      disabled={!rfqOrEysButtonEnabled}
    >
      <TdsIcon slot="icon" name="arrow_right" size={arrowSize}></TdsIcon>
    </TdsButton>
  )
}
