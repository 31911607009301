import { useCallback } from 'react'
import styled from 'styled-components'
import {
  SvgChevronDown,
  SvgSkipBackwards,
  SvgSkipForward,
} from '../../components/SvgImports'
import { buildRelativeLandingPageUrl } from '../../utils/UrlBuilders'
import { setSessionIdInSessionStorage } from '../../utils/sessionStorageUtil'
import useTexts from '../../utils/useTexts'
import { useAppSelector } from '../../store/hooks'
import { TestElementTypeId } from '../../types/TestAttributeId'
import { getUrlParametersToString } from '../../utils/getUrlParametersToString'
import { getMarketLanguageState } from '../../store/appSlice'
import {
  getIsLoggedInState,
  getSessionInitDataState,
} from '../../store/sessionDataSlice'
import { TdsTooltip } from '@scania/tegel-react'

const NavigationRoot = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  // Quickfix for misbehaving WheelConfPage sizing, sometimes covering some
  // navigation buttons.
  z-index: 1;
`

const NavigationButtonsWrapper = styled.div`
  display: flex;
  margin: 16px;
`

const NavigationButton = styled.div`
  height: 28px;
  width: 28px;
  border: 1px solid var(--tds-grey-958);
  border-radius: 4px;
  margin-left: 4px;
  margin-right: 4px;
  color: var(--tds-grey-958);
  padding: 6px;
  cursor: pointer;
`

export interface NavigationButtonsProps {
  handleStartConfigurationClick: () => void
  isFirstStep: boolean
  isLastStep: boolean
  handleGoBack: VoidFunction
  handleGoNext: VoidFunction
}

export function NavigationButtons(props: NavigationButtonsProps): JSX.Element {
  const marketLanguage = useAppSelector(getMarketLanguageState)
  const sessionInitData = useAppSelector(getSessionInitDataState)
  const sessionId = sessionInitData?.sessionId
  const isloggedin = useAppSelector(getIsLoggedInState)
  const t = useTexts()

  const handleMenuNewClick = useCallback(() => {
    if (!marketLanguage) {
      return
    }
    if (sessionId && isloggedin) {
      setSessionIdInSessionStorage(sessionId)
    }
    // Reload the application from the landing page.
    const relativeUrl = buildRelativeLandingPageUrl(marketLanguage)
    const params = relativeUrl.params
    document.location.href = relativeUrl.path + getUrlParametersToString(params)
  }, [marketLanguage, sessionId, isloggedin])

  return (
    <NavigationRoot data-name="NavigationRoot">
      <NavigationButtonsWrapper>
        <TdsTooltip
          placement="top"
          selector="#NavigateNew"
          text={t('ACTION_LABEL_RESTART')}
        />
        <NavigationButton
          data-test-element-type={
            TestElementTypeId.GuidedOfferingNavigationRestart
          }
          id="NavigateNew"
          onClick={handleMenuNewClick}
        >
          <SvgSkipBackwards />
        </NavigationButton>
        {!props.isFirstStep && (
          <>
            <TdsTooltip
              placement="top"
              selector="#NavigatePrevious"
              text={t('ACTION_LABEL_PREVIOUS')}
            />
            <NavigationButton
              data-test-element-type={
                TestElementTypeId.GuidedOfferingNavigationPrevious
              }
              id="NavigatePrevious"
              onClick={props.handleGoBack}
            >
              <SvgChevronDown style={{ rotate: '90deg' }} />
            </NavigationButton>
          </>
        )}
      </NavigationButtonsWrapper>
      <NavigationButtonsWrapper>
        {!props.isLastStep && (
          <>
            <TdsTooltip
              placement="top"
              selector="#NavigateSkip"
              text={t('ACTION_LABEL_SKIP')}
            />
            <NavigationButton
              data-test-element-type={
                TestElementTypeId.GuidedOfferingNavigationSkip
              }
              id="NavigateSkip"
              onClick={props.handleGoNext}
            >
              <SvgChevronDown style={{ rotate: '270deg' }} />
            </NavigationButton>
          </>
        )}
        <TdsTooltip
          placement="top"
          selector="#NavigateQuickStart"
          text={t('ACTION_LABEL_QUICK_START')}
        />
        <NavigationButton
          data-test-element-type={
            TestElementTypeId.GuidedOfferingNavigationQuickStart
          }
          id="NavigateQuickStart"
          onClick={props.handleStartConfigurationClick}
        >
          <SvgSkipForward />
        </NavigationButton>
      </NavigationButtonsWrapper>
    </NavigationRoot>
  )
}
