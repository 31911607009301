/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const RfqContactRole = {
  Other: 'Other',
  Owner: 'Owner',
  Driver: 'Driver',
} as const
export type RfqContactRole =
  (typeof RfqContactRole)[keyof typeof RfqContactRole]

export function RfqContactRoleFromJSON(json: any): RfqContactRole {
  return RfqContactRoleFromJSONTyped(json, false)
}

export function RfqContactRoleFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): RfqContactRole {
  return json as RfqContactRole
}

export function RfqContactRoleToJSON(value?: RfqContactRole | null): any {
  return value as any
}
