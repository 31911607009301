/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface CreateUserRequest
 */
export interface CreateUserRequest {
  /**
   *
   * @type {string}
   * @memberof CreateUserRequest
   */
  sessionId?: string | null
  /**
   *
   * @type {string}
   * @memberof CreateUserRequest
   */
  userName?: string | null
  /**
   *
   * @type {string}
   * @memberof CreateUserRequest
   */
  password?: string | null
}

/**
 * Check if a given object implements the CreateUserRequest interface.
 */
export function instanceOfCreateUserRequest(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function CreateUserRequestFromJSON(json: any): CreateUserRequest {
  return CreateUserRequestFromJSONTyped(json, false)
}

export function CreateUserRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CreateUserRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    sessionId: !exists(json, 'session_id') ? undefined : json['session_id'],
    userName: !exists(json, 'user_name') ? undefined : json['user_name'],
    password: !exists(json, 'password') ? undefined : json['password'],
  }
}

export function CreateUserRequestToJSON(value?: CreateUserRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    session_id: value.sessionId,
    user_name: value.userName,
    password: value.password,
  }
}
