import { RefObject, useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { SESSION_FAILURE } from '../../api/errors'
import { useClient } from '../../utils/useClient'
import useTexts from '../../utils/useTexts'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { TestElementTypeId } from '../../types/TestAttributeId'
import {
  getMarketDenomination,
  getSessionInitDataState,
  setGarageList,
} from '../../store/sessionDataSlice'
import { closeAllModals, getModalState } from '../../store/modalSlice'
import { TdsButton, TdsModal, TdsTextField } from '@scania/tegel-react'
import {
  ScaniaAdobeEventId,
  ScaniaAdobeTrackingClickSaveAsGarageEvent,
  pushAdobeEvent,
} from '../../utils/adobeAnalytics'

const InputWrapper = styled.div`
  margin-top: 16px;
  width: 100%;
`

interface ModalSaveAsGarageProps {
  handleFatalError: () => void
  handleSessionFailure: () => void
  handleTruckIsSavedAs: (id: number | null, name: string | null) => void
}

export function ModalSaveAsGarage({
  handleFatalError,
  handleSessionFailure,
  handleTruckIsSavedAs,
}: ModalSaveAsGarageProps): JSX.Element {
  const t = useTexts()
  const client = useClient()
  const dispatch = useAppDispatch()
  const sessionInitData = useAppSelector(getSessionInitDataState)
  const sessionId = sessionInitData?.sessionId
  const truckNameInput: RefObject<HTMLTdsTextFieldElement> =
    useRef<HTMLTdsTextFieldElement>(null)
  const marketDenomination = useAppSelector(getMarketDenomination)
  const source = useAppSelector(getModalState).modalSaveAsState?.source
  const [truckName, setTruckName] = useState<string>('')

  const handleSaveAsClick = useCallback(() => {
    async function saveAs() {
      if (!sessionId) {
        return
      }
      if (!client) {
        return
      }
      const response = await client.userConfigV2SaveAs({
        sessionId,
        configName: truckName,
        description: marketDenomination || truckName,
      })
      if (response.error === SESSION_FAILURE) {
        handleSessionFailure()
        return
      }
      if (!response.userConfigV2SaveAs) {
        throw new Error('Expected response.userConfigV2SaveAs to be defined.')
      }
      handleTruckIsSavedAs(response.userConfigV2SaveAs, truckName)
      const garagelistResponse = await client.userConfigV2List(sessionId)
      if (garagelistResponse.error === SESSION_FAILURE) {
        handleSessionFailure()
        return
      } else if (garagelistResponse.error) {
        handleFatalError()
        return
      }
      const garageList = garagelistResponse.userConfigV2List || []
      dispatch(setGarageList(garageList))
      return
    }
    saveAs()
    dispatch(closeAllModals())

    if (!source) {
      throw new Error('Expected click placement to be defined.')
    }
    const trackingEvent: ScaniaAdobeTrackingClickSaveAsGarageEvent = {
      event: ScaniaAdobeEventId.SaveAsGarageClick,
      eventInfo: {
        placement: source,
      },
    }
    pushAdobeEvent(trackingEvent)
  }, [
    client,
    dispatch,
    handleFatalError,
    handleSessionFailure,
    handleTruckIsSavedAs,
    marketDenomination,
    sessionId,
    truckName,
    source,
  ])

  const handleEnter = (e: any, key: string, fn: () => void) => {
    if (e.key === key) {
      fn()
    }
  }

  const handleCloseClick = useCallback(() => {
    dispatch(closeAllModals())
  }, [dispatch])

  // Eventlistener to close modal on tdsClose (click outside modal and click on close cross)
  useEffect(() => {
    document.addEventListener('tdsClose', handleCloseClick)
    return () => {
      document.removeEventListener('tdsClose', handleCloseClick)
    }
  }, [handleCloseClick])

  return (
    <TdsModal size="md" header={t('HEADER_SAVE_AS')} show>
      <span slot="body">
        <InputWrapper>
          <TdsTextField
            data-test-element-type={
              TestElementTypeId.ModalSaveAsGarageTruckNameInput
            }
            type="text"
            placeholder={t('LABEL_TRUCK_NAME')}
            ref={truckNameInput}
            onInput={(e: any) => setTruckName(e.target.value)}
            onKeyUp={(event: any) =>
              handleEnter(event, 'Enter', handleSaveAsClick)
            }
          />
        </InputWrapper>
      </span>

      <span slot="actions" className="tds-u-flex tds-u-gap2 tds-u-flex-wrap">
        <TdsButton
          variant="primary"
          size="md"
          text={t('LABEL_ACTION_SAVE')}
          onClick={handleSaveAsClick}
          data-test-element-type={TestElementTypeId.ModalSaveAsGarageSaveButton}
        />
        <TdsButton
          variant="secondary"
          size="md"
          text={t('LABEL_ACTION_CLOSE')}
          onClick={handleCloseClick}
          data-test-element-type={TestElementTypeId.ModalCloseButton}
        />
      </span>
    </TdsModal>
  )
}
