import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './index'
import { ReadMoreEntry } from '../components/SidePanels/SidePanelReadMore'
import { ReadMoreData } from './types'
import { SidePanelLoginGarageContent } from '../components/SidePanels/SidePanelLoginAndGarage'

export interface SidePanelState {
  readMoreOpen: boolean
  readMoreNode: ReadMoreEntry | null
  readMoreData: ReadMoreData | null
  loginGarageOpen: boolean
  loginGarageContent: SidePanelLoginGarageContent
  infoOpen: boolean
}

const initialState: SidePanelState = {
  readMoreOpen: false,
  readMoreNode: null,
  readMoreData: null,
  loginGarageOpen: false,
  loginGarageContent: SidePanelLoginGarageContent.account,
  infoOpen: false,
}

export const sidePanelSlice = createSlice({
  name: 'sidePanel',
  initialState,
  reducers: {
    openPanelReadMore: (state) => {
      state.readMoreOpen = true
      state.loginGarageOpen = false
      state.infoOpen = false
    },
    closePanelReadMore: (state) => {
      state.readMoreOpen = false
    },
    setReadMoreNode: (state, action: PayloadAction<ReadMoreEntry | null>) => {
      state.readMoreNode = action.payload
    },
    setReadMoreData: (state, action: PayloadAction<ReadMoreData | null>) => {
      state.readMoreData = action.payload
    },
    openPanelLoginGarage: (state) => {
      state.loginGarageOpen = true
      state.infoOpen = false
      state.readMoreOpen = false
    },
    closePanelLoginGarage: (state) => {
      state.loginGarageOpen = false
    },
    setPanelLoginGarageContent: (
      state,
      action: PayloadAction<SidePanelLoginGarageContent>,
    ) => {
      state.loginGarageContent = action.payload
    },
    openPanelInfo: (state) => {
      state.infoOpen = true
      state.loginGarageOpen = false
      state.readMoreOpen = false
    },
    closePanelInfo: (state) => {
      state.infoOpen = false
    },
  },
})

export const {
  openPanelReadMore,
  closePanelReadMore,
  setReadMoreNode,
  setReadMoreData,
  openPanelLoginGarage,
  closePanelLoginGarage,
  setPanelLoginGarageContent,
  openPanelInfo,
  closePanelInfo,
} = sidePanelSlice.actions

export const selectSidePanelState = (state: RootState) => state.sidePanel

export default sidePanelSlice.reducer
