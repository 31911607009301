/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ChangeConfigRequest
 */
export interface ChangeConfigRequest {
  /**
   *
   * @type {string}
   * @memberof ChangeConfigRequest
   */
  sessionId: string
  /**
   *
   * @type {string}
   * @memberof ChangeConfigRequest
   */
  input: string
}

/**
 * Check if a given object implements the ChangeConfigRequest interface.
 */
export function instanceOfChangeConfigRequest(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'sessionId' in value
  isInstance = isInstance && 'input' in value

  return isInstance
}

export function ChangeConfigRequestFromJSON(json: any): ChangeConfigRequest {
  return ChangeConfigRequestFromJSONTyped(json, false)
}

export function ChangeConfigRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ChangeConfigRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    sessionId: json['session_id'],
    input: json['input'],
  }
}

export function ChangeConfigRequestToJSON(
  value?: ChangeConfigRequest | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    session_id: value.sessionId,
    input: value.input,
  }
}
