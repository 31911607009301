import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { SummaryItem } from '../../../../api/generated'
import { CustomScrollbarMixin } from '../../../../css/CustomScrollbarMixin'
import useTexts from '../../../../utils/useTexts'
import { blinkKeyframes } from '../../MenuBlinkAnimation'
import { BlendColorRgb, BlendColors } from './BlendColors'
import { ColorIcon } from './ColorIcon'
import { toEtelVariant } from '../../../../utils/EtelVariant'
import { BreakpointWidthPx } from '../../../../css/BreakpointWidthPx'
import { useAppSelector } from '../../../../store/hooks'
import { getMenuState } from '../../../../store/menuSlice'
import { getLatestConfigChangeResult } from '../../../../store/truckInfoSlice'

const ColorMenuRoot = styled.div`
  width: 100%;
`

const CurrentSelectionWrapper = styled.div`
  width: 100%;
  background-color: var(--tds-grey-100);
  border-bottom: thin solid var(--tds-blue-400);
`

const CurrentSelectionTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: bold;
  padding-top: 16px;
  padding-left: 15px;
  padding-right: 15px;
`

const CurrentSelectionColorContainer = styled.div`
  width: 100%;
  height: 64px;
  align-items: center;
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
`

interface ColorIconSelectedBigProps {
  $rgb: BlendColorRgb
}

const CurrentSelectionColorIcon = styled.div<ColorIconSelectedBigProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 50%;
  border: 3px solid var(--tds-blue-700);
  background-color: ${(props) => blendColorToCss(props.$rgb)};
`

const CurrentSelectionColorText = styled.div`
  margin-left: 10px;
  font-weight: bold;
  text-transform: capitalize;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const ScrollContainer = styled.div`
  width: 100%;
  border-top: thin solid var(--tds-blue-400);

  @media screen and (min-width: ${BreakpointWidthPx.Tablet}px) {
    ${CustomScrollbarMixin};
    overflow-x: hidden;
    overflow-y: auto;
    max-height: calc(100vh - 48px - 68px - 68px - 68px - var(--header-height));
  }
`

const ColorsContainer = styled.div`
  display: grid;
  font-size: 10px;
  font-style: italic;
  grid-template-columns: 1fr 1fr 1fr;
  height: 100%;
  padding-bottom: 12px;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 4px;
  width: 100%;
`

interface ColorsContainerProps {
  $isHighlighted: boolean
}

const ColorAndTextWrapper = styled.div<ColorsContainerProps>`
  --bg-color: unset;
  --hover-indicator-visibility: hidden;
  --active-indicator-visibility: hidden;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
  padding: 4px;

  &:active {
    --active-indicator-visibility: visible;
  }

  & .active-indicator {
    visibility: var(--active-indicator-visibility);
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      --hover-indicator-visibility: visible;
    }

    & .hover-indicator {
      visibility: var(--hover-indicator-visibility);
    }
  }

  animation-duration: 700ms;
  animation-iteration-count: 4;
  animation-name: ${(props) =>
    props.$isHighlighted ? blinkKeyframes : 'unset'};
  border-radius: 4px;
`

// Important to not include background-color here and set it with inline CSS on
// the element attribute instead to avoid styled-components generating "over 200
// classes", causing a visible lag in the GUI.
// This can be solved with the .attrs function but that results in some super
// ugly syntax.

const blendColorToCss = (rgb: BlendColorRgb): string => {
  return `rgb(${rgb.R}, ${rgb.G}, ${rgb.B})`
}

const getColorVariantRgb = (id: string): BlendColorRgb | undefined => {
  return BlendColors[id]
}

export interface ColorMenuProps {
  handleItemSelection: (id: string) => void
  highlightedId: string | null
}

export const ColorMenu = (props: ColorMenuProps): JSX.Element => {
  const lastColorFamily = useRef<string | null>(null)
  const scrollToSelectedColorRef = useRef<HTMLDivElement | null>(null)
  const scrollToSearchResultRef = useRef<HTMLDivElement | null>(null)
  const t = useTexts()
  const { visibleMenuState, currentlyLoadingId } = useAppSelector(getMenuState)
  const [visibleHighlightedId, setVisibleHighlightedId] = useState<
    string | null
  >(null)
  const colorIds = visibleMenuState?.colorInfo?.colorIds || null
  const leafNodes = visibleMenuState?.leafNodes

  // TODO: Only provide the selected colors, not the entire mini summary state.
  // There should be no dependency between this component and mini summary.
  const miniSummaryState = useAppSelector(
    getLatestConfigChangeResult,
  )?.miniSummary

  const [selectedColorNode, setSelectedColorNode] =
    useState<SummaryItem | null>(null)

  // Scroll to color on search or on mini summary click.
  useEffect(() => {
    if (!leafNodes) {
      console.error('Expected props.leafNodes to be defined.')
      return
    }
    const firstColorId = leafNodes[0]?.id
    if (!firstColorId) {
      console.error('Expected firstColorId to be defined.')
      return
    }
    const firstColorVariant = toEtelVariant(firstColorId)
    const familyId = firstColorVariant.family
    const colorFamilyHasChanged = lastColorFamily.current !== familyId
    lastColorFamily.current = familyId

    // It seems like Chrome cancels ongoing smooth scrolls when starting a new,
    // at least some times? That's why this scroll delay is here, the main menu
    // scroll must finish before starting this one.
    //
    // TODO: Consider using this event instead at a later time:
    // https://caniuse.com/mdn-api_element_scrollend_event
    //
    // Also delay the blink on selected item until scroll is finished.
    const scrollDelayMs = 700
    setTimeout(() => {
      if (scrollToSearchResultRef.current) {
        scrollToSearchResultRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
        setVisibleHighlightedId(props.highlightedId)
      } else if (scrollToSelectedColorRef.current && colorFamilyHasChanged) {
        scrollToSelectedColorRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
        setVisibleHighlightedId(props.highlightedId)
      }
    }, scrollDelayMs)
  }, [leafNodes, props.highlightedId])

  // Set selected color to be used for all colors in the menu
  useEffect(() => {
    if (!leafNodes || !miniSummaryState || !visibleMenuState) {
      return
    }
    const openMenuParentId = visibleMenuState?.activeMenuId
    const openMenuParentParentId =
      visibleMenuState?.menuNodes[openMenuParentId].parentId

    // TODO: Clean this up.
    // TODO: Only send in the data needed, not the entire mini summary state.
    const colors = []
    if (miniSummaryState.cabColor) {
      colors.push(miniSummaryState.cabColor)
    }
    if (miniSummaryState.chassisColor) {
      colors.push(miniSummaryState.chassisColor)
    }
    if (miniSummaryState.contrastColor) {
      colors.push(miniSummaryState.contrastColor)
    }
    for (let i = 0; i < colors.length; i++) {
      const parentParentId = colors[i].menuPath?.split('/')[2]
      if (parentParentId === openMenuParentParentId) {
        const currentSelectedColorNode = colors[i]
        setSelectedColorNode(currentSelectedColorNode)
        return
      }
    }
    setSelectedColorNode(null)
  }, [miniSummaryState, leafNodes, visibleMenuState])

  return (
    <ColorMenuRoot>
      {selectedColorNode ? (
        <CurrentSelectionWrapper>
          <CurrentSelectionTitle>
            {t('LABEL_CURRENT_SELECTION')}
          </CurrentSelectionTitle>
          <CurrentSelectionColorContainer>
            <CurrentSelectionColorIcon
              $rgb={
                getColorVariantRgb(selectedColorNode.itemId) || {
                  R: 255,
                  G: 0,
                  B: 255,
                }
              }
            />
            <CurrentSelectionColorText>
              {selectedColorNode.itemText}
            </CurrentSelectionColorText>
          </CurrentSelectionColorContainer>
        </CurrentSelectionWrapper>
      ) : (
        <CurrentSelectionWrapper>
          <CurrentSelectionTitle>
            {t('LABEL_CURRENT_SELECTION')}
          </CurrentSelectionTitle>
          <CurrentSelectionColorContainer>-</CurrentSelectionColorContainer>
        </CurrentSelectionWrapper>
      )}
      <ScrollContainer>
        <ColorsContainer>
          {leafNodes?.map((node) =>
            colorIds?.includes(node.id.split('~')[0]) ? (
              <ColorAndTextWrapper
                key={node.id}
                onClick={() => {
                  props.handleItemSelection(node.id)
                }}
                $isHighlighted={node.id === visibleHighlightedId}
                ref={
                  node.isSelected
                    ? scrollToSearchResultRef
                    : node.id === props.highlightedId
                    ? scrollToSearchResultRef
                    : null
                }
              >
                <ColorIcon
                  $isLoading={currentlyLoadingId === node.id}
                  $isSelected={node.isSelected}
                  $color={blendColorToCss(
                    getColorVariantRgb(node.id) || { R: 255, G: 0, B: 255 },
                  )}
                />
                {node.text}
              </ColorAndTextWrapper>
            ) : (
              <></>
            ),
          )}
        </ColorsContainer>
      </ScrollContainer>
    </ColorMenuRoot>
  )
}
