/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ConfigChangeMenuEntry } from './ConfigChangeMenuEntry'
import {
  ConfigChangeMenuEntryFromJSON,
  ConfigChangeMenuEntryFromJSONTyped,
  ConfigChangeMenuEntryToJSON,
} from './ConfigChangeMenuEntry'
import type { ConfigChangeResultAuxCarrier } from './ConfigChangeResultAuxCarrier'
import {
  ConfigChangeResultAuxCarrierFromJSON,
  ConfigChangeResultAuxCarrierFromJSONTyped,
  ConfigChangeResultAuxCarrierToJSON,
} from './ConfigChangeResultAuxCarrier'
import type { ConfigChangeResultMiniSummary } from './ConfigChangeResultMiniSummary'
import {
  ConfigChangeResultMiniSummaryFromJSON,
  ConfigChangeResultMiniSummaryFromJSONTyped,
  ConfigChangeResultMiniSummaryToJSON,
} from './ConfigChangeResultMiniSummary'
import type { MenuItem } from './MenuItem'
import {
  MenuItemFromJSON,
  MenuItemFromJSONTyped,
  MenuItemToJSON,
} from './MenuItem'
import type { SummaryItem } from './SummaryItem'
import {
  SummaryItemFromJSON,
  SummaryItemFromJSONTyped,
  SummaryItemToJSON,
} from './SummaryItem'

/**
 *
 * @export
 * @interface ConfigChangeResult
 */
export interface ConfigChangeResult {
  /**
   *
   * @type {Array<ConfigChangeMenuEntry>}
   * @memberof ConfigChangeResult
   */
  updates: Array<ConfigChangeMenuEntry>
  /**
   *
   * @type {string}
   * @memberof ConfigChangeResult
   */
  activeMenu?: string | null
  /**
   *
   * @type {string}
   * @memberof ConfigChangeResult
   */
  activeItem?: string | null
  /**
   *
   * @type {ConfigChangeResultAuxCarrier}
   * @memberof ConfigChangeResult
   */
  auxCarrier: ConfigChangeResultAuxCarrier
  /**
   *
   * @type {Array<string>}
   * @memberof ConfigChangeResult
   */
  menusWithSelectableItems: Array<string>
  /**
   *
   * @type {ConfigChangeResultMiniSummary}
   * @memberof ConfigChangeResult
   */
  miniSummary: ConfigChangeResultMiniSummary
  /**
   *
   * @type {Array<SummaryItem>}
   * @memberof ConfigChangeResult
   */
  activeChoices: Array<SummaryItem>
  /**
   *
   * @type {Array<MenuItem>}
   * @memberof ConfigChangeResult
   */
  superEngines?: Array<MenuItem> | null
  /**
   *
   * @type {Array<MenuItem>}
   * @memberof ConfigChangeResult
   */
  smartDashItems?: Array<MenuItem> | null
  /**
   *
   * @type {boolean}
   * @memberof ConfigChangeResult
   */
  canShowBodyBuild: boolean
}

/**
 * Check if a given object implements the ConfigChangeResult interface.
 */
export function instanceOfConfigChangeResult(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'updates' in value
  isInstance = isInstance && 'auxCarrier' in value
  isInstance = isInstance && 'menusWithSelectableItems' in value
  isInstance = isInstance && 'miniSummary' in value
  isInstance = isInstance && 'activeChoices' in value
  isInstance = isInstance && 'canShowBodyBuild' in value

  return isInstance
}

export function ConfigChangeResultFromJSON(json: any): ConfigChangeResult {
  return ConfigChangeResultFromJSONTyped(json, false)
}

export function ConfigChangeResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ConfigChangeResult {
  if (json === undefined || json === null) {
    return json
  }
  return {
    updates: (json['updates'] as Array<any>).map(ConfigChangeMenuEntryFromJSON),
    activeMenu: !exists(json, 'active_menu') ? undefined : json['active_menu'],
    activeItem: !exists(json, 'active_item') ? undefined : json['active_item'],
    auxCarrier: ConfigChangeResultAuxCarrierFromJSON(json['aux_carrier']),
    menusWithSelectableItems: json['menus_with_selectable_items'],
    miniSummary: ConfigChangeResultMiniSummaryFromJSON(json['mini_summary']),
    activeChoices: (json['active_choices'] as Array<any>).map(
      SummaryItemFromJSON,
    ),
    superEngines: !exists(json, 'super_engines')
      ? undefined
      : json['super_engines'] === null
      ? null
      : (json['super_engines'] as Array<any>).map(MenuItemFromJSON),
    smartDashItems: !exists(json, 'smart_dash_items')
      ? undefined
      : json['smart_dash_items'] === null
      ? null
      : (json['smart_dash_items'] as Array<any>).map(MenuItemFromJSON),
    canShowBodyBuild: json['can_show_body_build'],
  }
}

export function ConfigChangeResultToJSON(
  value?: ConfigChangeResult | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    updates: (value.updates as Array<any>).map(ConfigChangeMenuEntryToJSON),
    active_menu: value.activeMenu,
    active_item: value.activeItem,
    aux_carrier: ConfigChangeResultAuxCarrierToJSON(value.auxCarrier),
    menus_with_selectable_items: value.menusWithSelectableItems,
    mini_summary: ConfigChangeResultMiniSummaryToJSON(value.miniSummary),
    active_choices: (value.activeChoices as Array<any>).map(SummaryItemToJSON),
    super_engines:
      value.superEngines === undefined
        ? undefined
        : value.superEngines === null
        ? null
        : (value.superEngines as Array<any>).map(MenuItemToJSON),
    smart_dash_items:
      value.smartDashItems === undefined
        ? undefined
        : value.smartDashItems === null
        ? null
        : (value.smartDashItems as Array<any>).map(MenuItemToJSON),
    can_show_body_build: value.canShowBodyBuild,
  }
}
