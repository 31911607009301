/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ConfigBop } from './ConfigBop'
import {
  ConfigBopFromJSON,
  ConfigBopFromJSONTyped,
  ConfigBopToJSON,
} from './ConfigBop'

/**
 *
 * @export
 * @interface LoadConfigFromStringResponseWrapper
 */
export interface LoadConfigFromStringResponseWrapper {
  /**
   *
   * @type {ConfigBop}
   * @memberof LoadConfigFromStringResponseWrapper
   */
  loadConfigFromString?: ConfigBop | null
  /**
   *
   * @type {string}
   * @memberof LoadConfigFromStringResponseWrapper
   */
  error?: string | null
}

/**
 * Check if a given object implements the LoadConfigFromStringResponseWrapper interface.
 */
export function instanceOfLoadConfigFromStringResponseWrapper(
  value: object,
): boolean {
  let isInstance = true

  return isInstance
}

export function LoadConfigFromStringResponseWrapperFromJSON(
  json: any,
): LoadConfigFromStringResponseWrapper {
  return LoadConfigFromStringResponseWrapperFromJSONTyped(json, false)
}

export function LoadConfigFromStringResponseWrapperFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): LoadConfigFromStringResponseWrapper {
  if (json === undefined || json === null) {
    return json
  }
  return {
    loadConfigFromString: !exists(json, 'load_config_from_string')
      ? undefined
      : ConfigBopFromJSON(json['load_config_from_string']),
    error: !exists(json, 'error') ? undefined : json['error'],
  }
}

export function LoadConfigFromStringResponseWrapperToJSON(
  value?: LoadConfigFromStringResponseWrapper | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    load_config_from_string: ConfigBopToJSON(value.loadConfigFromString),
    error: value.error,
  }
}
