import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  ScaniaAdobeEventId,
  ScaniaAdobePageName,
  ScaniaAdobeTrackingClickUpgradeYourTruckEvent,
  ScaniaAdobeTrackingFlowName,
  ScaniaAdobeTrackingPageName,
  pushAdobeEvent,
} from '../../../utils/adobeAnalytics'
import useTexts, { TextId } from '../../../utils/useTexts'
import { hasReadMore } from '../../../api/readmoreUtil'
import styled from 'styled-components'
import { SvgInfo } from '../../../components/SvgImports'
import { Timeline } from '../Timeline'
import { CustomScrollbarMixin } from '../../../css/CustomScrollbarMixin'
import { NavigationButtons } from '../NavigationButtons'
import {
  isLowHeightDesktopGo,
  isTabletOrSmaller,
} from '../../../utils/screenQuery'
import { useClient } from '../../../utils/useClient'
import {
  GuidedOfferingClientState,
  GuidedOfferingClientStateStatus,
} from '../../../types/GuidedOffering/GuidedOfferingClientState'
import { buildCardData } from '../../../utils/cardDataUtil'
import { FAMILY_ID_OPERATION } from '../../../api/constants'
import { GoPageId } from '../syncBackendStateHook'
import { TestElementTypeId } from '../../../types/TestAttributeId'
import { PortraitFooter } from '../../../components/Footer/PortraitFooter'
import { ScrollContent } from '../ScrollContent'
import { useTimelineSkipWithTracking } from '../skipWithTracking'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { BreakpointWidthPx } from '../../../css/BreakpointWidthPx'
import { urlHasBevParam } from '../urlHasBevParam'
import { useGoChoiceClickHandlers } from '../goChoiceClickHook'
import { BuildLinkToNextQuestionParams } from '../BuildLinkToNextQuestionParams'
import { useHandleGoReadMoreClick } from '../goReadmoreUtil'
import {
  UrlParamMode,
  getUrlCidBevSemiliveParameters,
} from '../../../utils/getUrlCidParameter'
import { getUrlParametersToString } from '../../../utils/getUrlParametersToString'
import {
  GO_LOW_HEIGHT_LIMIT_ADAPTION_OPERATION,
  GO_OPERATIONS_MAX_WIDTH,
} from '../../../constants'
import {
  getFactoryModelsState,
  getMarketLanguageState,
  getMarketSettingsState,
} from '../../../store/appSlice'
import {
  getGuidedOfferingClientState,
  getSessionInitDataState,
  getStartupData,
  hideInitialLoadingScreen,
  pushPageViewTrackingEvent,
  setGuidedOfferingState,
  setPendingUserTrackingPageNavigation,
} from '../../../store/sessionDataSlice'
import { TdsButton } from '@scania/tegel-react'
import UyfImage from '../../../assets/img/UYF.png'
import { ShowRoomContainer } from './ShowRoomContainer'

const OperationsRoot = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  ${CustomScrollbarMixin};
  overflow-y: auto;
  overflow-x: hidden;
  align-items: center;
`

const PageContentWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;

  @media screen and (min-width: ${BreakpointWidthPx.Tablet}px) {
    margin-top: 64px;
  }

  // Reduce margin at low desktop screen heights
  @media screen and (min-width: ${BreakpointWidthPx.Tablet}px) and (max-height: ${GO_LOW_HEIGHT_LIMIT_ADAPTION_OPERATION}) {
    margin-top: 24px;
  }
`

const OptionsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100vw;
  max-width: ${GO_OPERATIONS_MAX_WIDTH};

  @media screen and (max-width: ${BreakpointWidthPx.Tablet}px) {
    flex-flow: column nowrap;
  }
`

const OptionGroup = styled.div`
  display: flex;
  align-items: center;

  @media screen and (min-width: ${BreakpointWidthPx.Tablet}px) {
    justify-content: flex-end;
    flex-flow: column nowrap;
  }

  @media screen and (max-width: ${BreakpointWidthPx.Tablet}px) {
    justify-content: center;
    flex-flow: nowrap;
    width: 90vw;
    margin-bottom: 16px;
  }
`

const IconsWrapper = styled.div`
  @media screen and (max-width: ${BreakpointWidthPx.Tablet}px) {
    margin-right: 16px;
  }

  @media screen and (min-width: ${BreakpointWidthPx.Tablet}px) {
    margin-bottom: 16px;
  }

  // Reduce margin at low desktop screen heights
  @media screen and (min-width: ${BreakpointWidthPx.Tablet}px) and (max-height: ${GO_LOW_HEIGHT_LIMIT_ADAPTION_OPERATION}) {
    margin-bottom: 8px;
  }
`

const ButtonWrapper = styled.div`
  min-width: 140px;
`

const OptionIconWrapper = styled.div`
  @media screen and (min-width: ${BreakpointWidthPx.Tablet}px) {
    img {
      width: 120px;
      height: 120px;
      display: block;
      margin-bottom: 16px;
      cursor: pointer;
    }
  }

  @media screen and (max-width: ${BreakpointWidthPx.Tablet}px) {
    img {
      width: 48px;
      height: 48px;
    }
  }

  // Reduce size at low desktop screen heights
  @media screen and (min-width: ${BreakpointWidthPx.Tablet}px) and (max-height: ${GO_LOW_HEIGHT_LIMIT_ADAPTION_OPERATION}) {
    img {
      width: 60px;
      height: 60px;
      margin-bottom: 8px;
    }
  }
`

const InfoIconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 24px;
    color: var(--tds-blue-800);
  }
`

const UysAndShowroomWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100vw;
  max-width: ${GO_OPERATIONS_MAX_WIDTH};
  margin-top: 32px;

  @media screen and (max-width: ${BreakpointWidthPx.Tablet}px) {
    flex-flow: column nowrap;
  }
`

const UysContainer = styled.div`
  display: flex;
  flex-direction: row;
  border: thin solid var(--tds-grey-300);
  border-radius: 10px;
  padding-top: 16px;
  margin-left: 8px;
  margin-right: 8px;

  @media screen and (max-width: ${BreakpointWidthPx.Tablet}px) {
    flex-direction: column;
  }
`

const UysImageWrapper = styled.div`
  & img {
    height: 150px;
  }
`

const UysTextWrapper = styled.div`
  max-width: 300px;
  margin-left: 16px;
  margin-bottom: 16px;

  & h5 {
  }

  & p {
    font-size: 12px;
  }

  & a {
    margin-bottom: 8px;
  }
`

function buildNextQuestionLink({
  marketLanguage,
  urlSelection,
}: BuildLinkToNextQuestionParams) {
  const urlCidParams = getUrlCidBevSemiliveParameters(
    urlHasBevParam() ? UrlParamMode.IncludeBev : UrlParamMode.ExcludeBev,
  )
  const url = `/go/grid/${marketLanguage.market}/${marketLanguage.language}/${urlSelection}`
  const link = !urlCidParams ? url : url + '?' + urlCidParams.toString()
  return link
}

export interface OperationsPageProps {
  handleGoBack: VoidFunction
  handleTimelineSkip: VoidFunction
}

export const OperationsPage = ({
  handleGoBack,
  handleTimelineSkip,
}: OperationsPageProps) => {
  const navigate = useNavigate()
  const marketLanguage = useAppSelector(getMarketLanguageState)
  const startupData = useAppSelector(getStartupData)
  const client = useClient()
  const goClientState = useAppSelector(getGuidedOfferingClientState)
  const sessionInitData = useAppSelector(getSessionInitDataState)
  const marketSettings = useAppSelector(getMarketSettingsState)
  const t = useTexts()
  const dispatch = useAppDispatch()
  const container = useRef<HTMLDivElement | null>(null)
  const { mode: factoryModelsMode } = useAppSelector(getFactoryModelsState)
  const [isReady, setIsReady] = useState<boolean>(false)
  const [upgradeYourFleetUrl, setUpgradeYourFleetUrl] = useState<
    string | undefined
  >(undefined)

  const { handleCardClick, handleSkipQuestion } = useGoChoiceClickHandlers(
    buildNextQuestionLink,
  )

  const handleTimelineSkipWithTracking =
    useTimelineSkipWithTracking(handleTimelineSkip)
  const handleReadMoreClick = useHandleGoReadMoreClick({
    stepName: ScaniaAdobeTrackingPageName.GoOperations,
  })

  // Adobe user tracking.
  useEffect(() => {
    if (!marketLanguage) {
      return
    }
    dispatch(
      pushPageViewTrackingEvent({
        pageName: ScaniaAdobeTrackingPageName.GoOperations,
        marketLanguage,
      }),
    )
  }, [dispatch, marketLanguage])

  useEffect(() => {
    if (!client) {
      return // Not initialized yet.
    }
    if (!marketLanguage) {
      return // Not initialized yet.
    }
    if (!startupData) {
      return // Not initialized yet.
    }
    if (
      goClientState?.status ===
      GuidedOfferingClientStateStatus.WaitingForBuildMode
    ) {
      return
    }
    const wantBev = urlHasBevParam()
    const asyncWrapper = async () => {
      if (
        goClientState?.userSelections.length !== 0 ||
        goClientState.lastResponse.bevFiltersEnabled !== wantBev
      ) {
        const showTextPreview =
          window.tempStartupCriticalState.appData.show_preview_texts
        const startResult = (
          await client.gowizardStart({
            language: marketLanguage.language.toString(),
            market: marketLanguage.market.toString(),
            useBevFilters: wantBev,
            timeoutSeconds: startupData?.sessionTimeoutSeconds,
            showTextPreview,
          })
        ).gowizardStart
        const cards = buildCardData(startResult)
        if (!cards) {
          throw new Error('Expected cards to be defined.')
        }
        const startState: GuidedOfferingClientState = {
          cards,
          etelLastUpdated: startResult.etelLastUpdated,
          lastResponse: startResult,
          sliderQuestions: null,
          stateUpdatedByPage: GoPageId.Operations,
          status: GuidedOfferingClientStateStatus.Active,
          userSelections: [],
        }
        dispatch(setGuidedOfferingState(startState))
      }
    }
    asyncWrapper()
  }, [client, dispatch, marketLanguage, goClientState, startupData])

  const handleFirmModelClick = () => {
    if (!marketLanguage) {
      return
    }
    dispatch(
      setPendingUserTrackingPageNavigation({
        factoryModelSelected: null,
        flowName: ScaniaAdobeTrackingFlowName.GuidedOffering,
        optionSelected: null,
        stepName: ScaniaAdobeTrackingPageName.GoOperations,
        stepSkipped: null,
      }),
    )
    const urlCidParams = getUrlCidBevSemiliveParameters(UrlParamMode.ExcludeBev)
    const url = `/factory-models/${marketLanguage.market}/${marketLanguage.language}/`
    const link = url + getUrlParametersToString(urlCidParams)
    setTimeout(() => navigate(link), 300) // TODO: See the comment ~15 lines above.
  }

  // Use a different icon SVG file to get a different color.
  //
  // TODO: Consider trying the svgr library or some other way of overriding SVG
  // styling using CSS inside React code to avoid the extra icon image file
  // copy.
  const getIconUrl = (id: string) => {
    //if (id.startsWith(FAMILY_ID_OPERATION + '~')) {
    return `${process.env.PUBLIC_URL}/images/${id}.svg`
    //}
  }

  useEffect(() => {
    if (!sessionInitData?.sessionId) {
      return // Not initialized yet.
    }

    // TODO: Revisit later, use a single call in App.tsx?
    dispatch(hideInitialLoadingScreen())
  }, [dispatch, sessionInitData])

  useEffect(() => {
    const familyId = goClientState?.lastResponse.question?.etelFamily
    setIsReady(familyId === FAMILY_ID_OPERATION)
  }, [goClientState])

  const handleUpgradeYourTruckClick = () => {
    const trackingEvent: ScaniaAdobeTrackingClickUpgradeYourTruckEvent = {
      event: ScaniaAdobeEventId.UpgradeYourTruckClick,
      eventInfo: {
        flow: ScaniaAdobePageName.GuidedOffering,
      },
    }
    pushAdobeEvent(trackingEvent)
  }

  // Set link to upgrade your fleet
  useEffect(() => {
    const generealUyfUrl = startupData?.settings.upgradeYourFleetLinkUrl
    if (!generealUyfUrl) {
      return
    }
    const marketCid = marketLanguage?.isoMarketCode
      ? '/?cid=scgo_' + marketLanguage.isoMarketCode.toLowerCase()
      : '/?cid=scgo_unknown'
    const uyfUrl = generealUyfUrl + marketCid
    setUpgradeYourFleetUrl(uyfUrl)
  }, [marketLanguage, startupData?.settings])

  return (
    <OperationsRoot>
      <ScrollContent>
        <Timeline
          header={
            isReady
              ? t('SQ_HEADER_', FAMILY_ID_OPERATION)
              : t('LOADING') + '...'
          }
          isReady={isReady}
        />
        {isReady && goClientState?.cards && (
          <>
            <PageContentWrapper>
              <OptionsContainer ref={container}>
                {goClientState.cards.map((card, i) => (
                  <OptionGroup key={card.id}>
                    <IconsWrapper>
                      <OptionIconWrapper
                        data-id={card.id}
                        onClick={() =>
                          handleCardClick({
                            id: card.id,
                            isSkippingToNext: false,
                          })
                        }
                      >
                        <img alt={card.header} src={getIconUrl(card.id)} />
                      </OptionIconWrapper>

                      {hasReadMore(card.option) ? (
                        <InfoIconWrapper
                          data-test-element-type={
                            TestElementTypeId.GuidedOfferingInfoIcon
                          }
                          onClick={(e) => handleReadMoreClick(e, card.id)}
                        >
                          <SvgInfo />
                        </InfoIconWrapper>
                      ) : (
                        <></>
                      )}
                    </IconsWrapper>
                    <ButtonWrapper>
                      <TdsButton
                        variant="primary"
                        size={
                          isTabletOrSmaller() || isLowHeightDesktopGo()
                            ? 'md'
                            : 'lg'
                        }
                        fullbleed={true}
                        text={card.header}
                        data-test-element-type={
                          TestElementTypeId.GuidedOfferingChoiceButton
                        }
                        data-test-etel-variant-id={card.id}
                        data-test-go-group-id={FAMILY_ID_OPERATION}
                        onClick={() =>
                          handleCardClick({
                            id: card.id,
                            isSkippingToNext: false,
                          })
                        }
                      />
                    </ButtonWrapper>
                  </OptionGroup>
                ))}
              </OptionsContainer>
              {marketSettings?.linkUpgradeFleetEnabled &&
              upgradeYourFleetUrl ? (
                <UysAndShowroomWrapper>
                  <UysContainer>
                    <UysImageWrapper>
                      <img src={UyfImage} alt="Upgrade your fleet page" />
                    </UysImageWrapper>
                    <UysTextWrapper>
                      <h5>{t(TextId.HEADER_UPGRADE_YOUR_TRUCK)}</h5>
                      <p> {t(TextId.DESCRIPTION_UPGRADE_YOUR_FLEET)}</p>
                      <a
                        href={upgradeYourFleetUrl}
                        target="_blank"
                        rel="noreferrer"
                        onClick={handleUpgradeYourTruckClick}
                      >
                        {t(TextId.ACTION_UPGRADE_YOUR_TRUCK)}
                      </a>
                    </UysTextWrapper>
                  </UysContainer>

                  {factoryModelsMode !== 0 && (
                    <ShowRoomContainer
                      handleFirmModelClick={handleFirmModelClick}
                      showUpgradeYourFleet={true}
                    />
                  )}
                </UysAndShowroomWrapper>
              ) : (
                <>
                  {factoryModelsMode !== 0 && (
                    <ShowRoomContainer
                      handleFirmModelClick={handleFirmModelClick}
                      showUpgradeYourFleet={false}
                    />
                  )}
                </>
              )}
            </PageContentWrapper>
            <NavigationButtons
              handleStartConfigurationClick={handleTimelineSkipWithTracking}
              handleGoBack={handleGoBack}
              handleGoNext={handleSkipQuestion}
              isFirstStep={true}
              isLastStep={false}
            ></NavigationButtons>
            <PortraitFooter
              displayAtScreenMaxWidth={BreakpointWidthPx.Tablet}
            />
          </>
        )}
      </ScrollContent>
    </OperationsRoot>
  )
}
