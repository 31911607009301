import { useRef, useEffect, useState, useCallback } from 'react'
import AppStyle from './AppStyle'
import { Route, Routes, useNavigate } from 'react-router-dom'
import Theme from './utils/media_theme'
import clsx from 'clsx'
import { AppHeader } from './components/Header/Header'
import { FactoryModelSummaryPage } from './pages/FactoryModels/FactoryModelSummaryPage'
import { FactoryModels } from './pages/FactoryModels/FactoryModels'
import ResetPassword from './pages/ResetPassword/ResetPassword'
import ErrorLayer from './components/ErrorLayer/ErrorLayer'
import { startApplication } from './api/startup'
import useDidUpdateEffect from './utils/useDidUpdateEffect'
import { SidePanelLoginAndGarage } from './components/SidePanels/SidePanelLoginAndGarage'
import { SidePanelInfo } from './components/SidePanels/SidePanelInfo'
import useTexts, { TextGetter, TextId, useTextsV2 } from './utils/useTexts'
import Portal from './components/Portal'
import GlobalStyle from './GlobalStyles'
import { useLayoutViewportSize } from './utils/resizeHook'
import useDetectMouseCapabilities from './utils/useDetectMouseCapabilities'
import {
  deleteEysStartConfigId,
  getAndDeleteSessionIdFromSessionStorage,
  setSessionIdInSessionStorage,
} from './utils/sessionStorageUtil'
import {
  CookieCategoryChoices,
  ModalCookieConfigurator,
} from './components/CookieConfigurator/ModalCookieConfigurator'
import {
  pushAdobeEvent,
  ScaniaAdobeTrackingClickRfqCtaEvent,
  ScaniaAdobeEventId,
  ScaniaAdobeTrackingMopinionFeedbackSent,
  ScaniaAdobeTrackingMopinionHidden,
  ScaniaAdobeTrackingMopinionNext,
  ScaniaAdobeTrackingMopinionReady,
  ScaniaAdobeTrackingMopinionRedirect,
  ScaniaAdobeTrackingMopinionShown,
  ScaniaAdobeTrackingMopinionWillHide,
  ScaniaAdobeTrackingMopinionWillShow,
  ScaniaAdobeTrackingClickEysSendChangesEvent,
} from './utils/adobeAnalytics'
import { BuildModePage } from './pages/BuildMode/BuildModePage'
import { ModalRequestAQuote } from './components/Modal/ModalRequestAQuote'
import { ModalConsequenceOfChange } from './components/Modal/ModalConsequenceOfChange'
import { ModalChangesLost } from './components/Modal/ModalChangesLost'
import { ModalUploadXml } from './components/Modal/ModalUploadXml'
import { ModalError } from './components/Modal/ModalError'
import { ModalRfqSender } from './components/Modal/ModalRfqSender'
import { ModalShareEmail } from './components/Modal/ModalShareEmail'
import { ModalShareLink } from './components/Modal/ModalShareLink'
import {
  buildAbsolutePublicConfigLink,
  buildRelativeBuildPageUrl,
  buildRelativeLandingPageUrl,
} from './utils/UrlBuilders'
import { ModalSaveAsGarage } from './components/Modal/ModalSaveAsGarage'
import { ModalShareWhatsApp } from './components/Modal/ModalShareWhatsApp'
import { ModalSavePdf } from './components/Modal/ModalSavePdf'
import { ModalSaveImage } from './components/Modal/ModalSaveImage'
import { ModalSaveQr } from './components/Modal/ModalSaveQr'
import { ModalSaveXml } from './components/Modal/ModalSaveXml'
import { ModalSaveGarage } from './components/Modal/ModalSaveGarage'
import { SidePanelReadMore } from './components/SidePanels/SidePanelReadMore'
import { LinkLandingPage } from './pages/LinkLandingPage/LinkLandingPage'
import { useClient } from './utils/useClient'
import { ModalSendChangesToEys } from './components/Modal/ModalSendChangesToEys'
import {
  ExtendedCheckStatus,
  MarketLanguageState,
  SmartDashCampaignMode,
} from './store/types'
import {
  NewTruckLoadedHandler,
  NewTruckLoadedInfo,
  NewTruckLoadedInfoRedux,
  TruckLoadedSource,
} from './types/TruckLoadedTypes'
import { SESSION_FAILURE } from './api/errors'
import { GuiMarketSettings, RfqParams, UserConfigInfo } from './api/generated'
import { ModalUserAccountGdprConsent } from './components/Modal/ModalUserAccountGdprConsent'
import { ModalShareFacebook } from './components/Modal/ModalShareFacebook'
import { buildBackendUrl, buildClient } from './api/clientStartupUtils'
import { GuidedOfferingClientStateStatus } from './types/GuidedOffering/GuidedOfferingClientState'
import ApplicationPage from './pages/GuidedOffering/B_ApplicationPage/ApplicationPage'
import { MultiLayoutPage } from './pages/GuidedOffering/C_MultiLayoutPage/MultiLayoutPage'
import OptionalsPage from './pages/GuidedOffering/D_OptionalsPage/OptionalsPage'
import {
  GuidedOfferingExitResult,
  exitGuidedOfferingAndLoadConfigIntoBuildMode,
  stepThroughGuidedOfferingToEnd,
} from './api/guidedOffering'
import { PageRoot } from './containers/GridContainer/GridContainer'
import { ModalWaitingForExtendedCheck } from './components/Modal/ModalWaitingForExtendedCheck'
import { LandingHomeNew } from './pages/LandingHome/LandingHomeNew'
import { GoFmMode } from './api/goFmMode'
import { useAppDispatch, useAppSelector } from './store/hooks'
import { urlHasBevParam } from './pages/GuidedOffering/urlHasBevParam'
import { getUrlParametersToString } from './utils/getUrlParametersToString'
import { OperationsPage } from './pages/GuidedOffering/A_OperationsPage/OperationsPage'
import {
  closePanelLoginGarage,
  openPanelLoginGarage,
  selectSidePanelState,
} from './store/sidePanelSlice'
import {
  getFactoryModelsState,
  getInitialMarketLanguageState,
  getMarketLanguageState,
  getMarketSettingsState,
  setInitData,
} from './store/appSlice'
import {
  getExtendedCheckStatus,
  getGarageListState,
  getGuidedOfferingClientState,
  getHidePreloaderState,
  getIsLoggedInState,
  getMarketDenomination,
  getSaveEnabled,
  getSessionInitDataState,
  getShareLink,
  hideInitialLoadingScreen,
  setError,
  setExtendedCheckState,
  setGuidedOfferingStateStatus,
  setInitState,
  setRfqInput,
  setSaveEnabled,
  setSessionId,
  setShareLink,
} from './store/sessionDataSlice'
import {
  ModalConsequenceOfChangeProps,
  closeModalChangesLost,
  closeModalRequestAQuote,
  getModalState,
  openModalChangesLost,
  openModalRequestAQuote,
  openModalSaveAsGarage,
  openModalSendChangesToEys,
  openModalUserAccountGdprConsent,
  setErrorIsFatal,
  setErrorModalState,
  setModalRfqSendingState,
} from './store/modalSlice'
import { getTruckInfoState, setTruckInfo } from './store/truckInfoSlice'
import { buildMenuTexts } from './utils/menuTexts'
import { setMenuTexts } from './store/menuSlice'
import {
  ConsequeceOfChangeUserChoice,
  ConsequenceOfChangeDialogData,
  ConsequenceOfChangeHandler,
} from './types/ConsequenceOfChangeTypes'
import { LoadingTruck } from './pages/GuidedOffering/LoadingTruck'

declare global {
  interface Window {
    /** Provided for Mopinion triggers. */
    languageIso: string | undefined

    /** Provided for Mopinion triggers. */
    etelMarket: string | undefined

    /** Provided for Mopinion triggers. */
    etelLanguage: string | undefined
  }
}

// TODO: Investigate and delete this as part of 9.0. Why was it added??
//       Had to remove this when moving this GUI application to the root path
//       "/" to replace the old GUI.
// First check "go" is the first subdirectory.
//if (!document.location.pathname.split('/').includes('go')) {
//  const slowDownRiscOfHorribleLoop = 5000
//  console.log(`%c Reloading with subpath /go in 5 sec `, 'background: red;')
//  setTimeout(() => (document.location.href = '/'), slowDownRiscOfHorribleLoop)
//}

const endPreloader = async () => {
  const preloader = document.body.querySelector<HTMLDivElement>('.preloader')
  if (!preloader) {
    throw new Error('Expected preloader to be defined.')
  }
  if (preloader.style.display === 'none') return
  preloader.classList.add('beginHidePreloader')
  // Get the time the preloader will transition out.
  const preloaderTransitionMs =
    parseInt(
      getComputedStyle(preloader).getPropertyValue('transition-duration'),
    ) * 1000
  setTimeout(() => {
    preloader.style.display = 'none'
  }, preloaderTransitionMs)
}

interface CookieConfig {
  allowPerformanceCookies?: boolean
  allowFunctionalCookies?: boolean
}

const COOKIES_CONFIG_KEY = 'scaniaConfiguratorCookieConfig'

const saveCookieConfigToLocalStorage = (conf: CookieConfig): void => {
  const json = JSON.stringify(conf)
  localStorage.setItem(COOKIES_CONFIG_KEY, json)
}

const getCookieConfigFromLocalStorage = (): CookieConfig | null => {
  const cookieConfigJson = localStorage.getItem(COOKIES_CONFIG_KEY)
  if (!cookieConfigJson) {
    return null
  }
  let cookieConfig: CookieConfig | null = null
  try {
    cookieConfig = JSON.parse(cookieConfigJson)
  } catch (e) {
    // Don't rethrow, returning null is enough.
    console.error(e)
  }
  return cookieConfig
}

const cookieConfigIsComplete = (config: CookieConfig): boolean => {
  // For some reason this works with npm run build but not for npm run start.
  // Probably different build tools.
  // TODO: Try this again after upgrading everything to the latest.
  //let result = true
  //result &&=
  //  config.allowFunctionalCookies === true ||
  //  config.allowFunctionalCookies === false
  //result &&=
  //  config.allowPerformanceCookies === true ||
  //  config.allowPerformanceCookies === false
  //return result
  let result = true
  result =
    result &&
    (config.allowFunctionalCookies === true ||
      config.allowFunctionalCookies === false)
  result =
    result &&
    (config.allowPerformanceCookies === true ||
      config.allowPerformanceCookies === false)
  return result
}

export interface ExternalIntegrationLoaders {
  // This will be a real loader function or the default empty loader function.
  // We could store the default loader function on an additional property to
  // compare the function reference for equality if we want to detect the
  // default empty loader.
  initializeAdobeAnalytics: VoidFunction

  initializeMopinion: VoidFunction
}

const loadPermittedCookieConjuringFeatures = (
  loaders: ExternalIntegrationLoaders,
  conf: CookieConfig,
  marketSettings: GuiMarketSettings,
  languageIso: string,
) => {
  if (conf.allowPerformanceCookies === true) {
    console.log('Performance cookies are allowed.')
  }
  if (conf.allowPerformanceCookies === true && conf.allowFunctionalCookies) {
    console.log('Performance cookies & Functional cookies are allowed.')

    // According to
    // https://www.scania.com/group/en/home/admin/misc/privacy-statement/cookies.html
    // Adobe Analytics require the user to accept both "Performance cookies" and
    // "Functional cookies".
    loaders.initializeAdobeAnalytics()

    // We don't know yet if Mopinion is causing cookies or not, but let's assume
    // for now that it is. TODO: Revisit later.
    const mop = marketSettings.mopinionSettings.find(
      (s) => s.languageIso === languageIso,
    )
    if (mop?.mopinionEnabled) {
      loaders.initializeMopinion()
    }
  }
}

interface ActionWaitingForLogin {
  run: () => void
}

interface AppProps {
  externalLoaders: ExternalIntegrationLoaders
}

function App(props: AppProps): JSX.Element {
  const dispatch = useAppDispatch()
  const client = useClient()
  const t = useTexts()
  const t2 = useTextsV2()
  const loading = useRef(false)
  useSetDocumentTitle(t)
  const { viewportHeight } = useLayoutViewportSize()
  const navigate = useNavigate()

  const sessionInitData = useAppSelector(getSessionInitDataState)
  const hidePreloader = useAppSelector(getHidePreloaderState)
  const marketLanguage = useAppSelector(getMarketLanguageState)
  const initialMarketLanguage = useAppSelector(getInitialMarketLanguageState)
  const sidePanelLogInIsOpen =
    useAppSelector(selectSidePanelState).loginGarageOpen
  const isLoggedIn = useAppSelector(getIsLoggedInState)
  const marketSettings = useAppSelector(getMarketSettingsState)
  const factoryModelsMode = useAppSelector(getFactoryModelsState).mode

  const extendedCheckState = useAppSelector(getExtendedCheckStatus)
  const saveEnabled = useAppSelector(getSaveEnabled)
  const garageList = useAppSelector(getGarageListState)
  const goClientState = useAppSelector(getGuidedOfferingClientState)
  const marketDenomination = useAppSelector(getMarketDenomination)
  const shareLink = useAppSelector(getShareLink)

  const {
    modalRequestAQuoteIsOpen,
    modalSendChangesToEysIsOpen,
    modalSaveAsGarageIsOpen,
    modalSaveGarageIsOpen,
    modalShareLinkIsOpen,
    modalChangesLostIsOpen,
    modalUploadXmlIsOpen,
    modalUserAccountGdprConsentIsOpen,
    modalRfqSendingState,
    modalShareEmailState,
    modalShareWhatsAppState,
    modalShareFacebookState,
    modalSavePdfState,
    modalSaveQrState,
    modalSaveXmlState,
    modalSaveImageState,
    errorModalState,
    errorIsFatal,
  } = useAppSelector(getModalState)

  const isFirstCheckSavedInGarage = useRef(true)
  const [modalConsequenceOfChangeProps, setModalConsequenceOfChangeProps] =
    useState<ModalConsequenceOfChangeProps | null>(null)
  const [preparingJumpToBuildMode, setPreparingJumpToBuildMode] =
    useState(false)
  const actionWaitingForLogin = useRef<ActionWaitingForLogin | null>(null)

  // Intended for triggering hook updates based on configuration changes.
  // The configuration itself is too large to report to the client, so we just
  // keep this counter in combination with the session id to represent a unique
  // configuration state.
  //
  // TODO: When the old GUI is retired, refactor the OutBop and rename it to
  // ConfigChangeResponse or something like that. Store that new refactored
  // response object instead of userSelectionCount.
  // EDIT: We have started to refactor OutBop now and it's stored in this
  // component as latestConfigChangeResult.
  const [userSelectionTimestamp, setUserSelectionTimestamp] =
    useState<Date | null>(null)

  const [rfqSentWithTimestamp, setRfqSentWithTimestamp] = useState<Date | null>(
    null,
  )
  const [eysChangesSentWithTimestamp, setEysChangesSentWithTimestamp] =
    useState<Date | null>(null)

  // TODO: Merge with bop etc. and rename to SessionConfigurationInfo?
  const truckInfo = useAppSelector(getTruckInfoState)

  const [smartDashCampaignMode, setSmartDashCampaignMode] =
    useState<SmartDashCampaignMode>(SmartDashCampaignMode.None)

  // Cookie related things: Begin
  const [showCookieConfigGui, setShowCookieConfigGui] = useState(false)
  const cookieRelatedFeaturesLoaded = useRef(false)
  const cookieConfig = getCookieConfigFromLocalStorage()
  const cookieConfigComplete =
    cookieConfig && cookieConfigIsComplete(cookieConfig)

  if (
    !cookieRelatedFeaturesLoaded.current &&
    cookieConfigComplete &&
    cookieConfig &&
    marketSettings &&
    marketLanguage
  ) {
    cookieRelatedFeaturesLoaded.current = true
    loadPermittedCookieConjuringFeatures(
      props.externalLoaders,
      cookieConfig,
      marketSettings,
      marketLanguage.isoLanguageCode,
    )
  }
  if (!cookieConfigComplete && !showCookieConfigGui) {
    setShowCookieConfigGui(true)
  }
  // Cookie related things: End.

  const exitFromOptionalsPageAndJumpToBuildMode = () => {
    exitAndJumpToBuildMode(true)
  }

  const handleFatalError = useCallback(
    (message?: string) => {
      dispatch(setErrorIsFatal(true))
      dispatch(setErrorModalState(message || t('ERROR_FATAL')))
      dispatch(hideInitialLoadingScreen())
    },
    [dispatch, t],
  )

  const handleSessionFailure = useCallback(() => {
    const message = t('ERROR_SESSION')
    if (errorIsFatal) {
      console.error(message)
      return
    }
    dispatch(setErrorModalState(message))
    dispatch(hideInitialLoadingScreen())
  }, [dispatch, errorIsFatal, t])

  const acceptAllCookies = useCallback(() => {
    if (!marketSettings) {
      throw new Error('Expected marketSettings to be defined.')
    }
    if (!marketLanguage) {
      throw new Error('Expected marketSettings to be defined.')
    }
    const conf: CookieConfig = {
      allowFunctionalCookies: true,
      allowPerformanceCookies: true,
    }
    saveCookieConfigToLocalStorage(conf)
    cookieRelatedFeaturesLoaded.current = true
    loadPermittedCookieConjuringFeatures(
      props.externalLoaders,
      conf,
      marketSettings,
      marketLanguage.isoLanguageCode,
    )
    setShowCookieConfigGui(false)
  }, [marketSettings, props.externalLoaders, marketLanguage])

  const saveCookieConfig = useCallback(
    (choices: CookieCategoryChoices) => {
      if (!marketSettings) {
        throw new Error('Expected marketSettings to be defined.')
      }
      if (!marketLanguage) {
        throw new Error('Expected marketSettings to be defined.')
      }
      saveCookieConfigToLocalStorage(choices)
      cookieRelatedFeaturesLoaded.current = true
      loadPermittedCookieConjuringFeatures(
        props.externalLoaders,
        choices,
        marketSettings,
        marketLanguage.isoLanguageCode,
      )
      setShowCookieConfigGui(false)
    },
    [marketSettings, props.externalLoaders, marketLanguage],
  )

  const handleNewTruckIsLoaded: NewTruckLoadedHandler = useCallback(
    (info: NewTruckLoadedInfo) => {
      const infoToRedux = { ...info, timeLoaded: info.timeLoaded.toISOString() }
      dispatch(setTruckInfo(infoToRedux))
      setUserSelectionTimestamp(info.timeLoaded)
      if (info.source === TruckLoadedSource.EXPLORE_YOUR_SCANIA_START_CONFIG) {
        // The EYS start config is considered to be in sync with the EYS
        // database and the "Send changes" button should be disabled.
        setEysChangesSentWithTimestamp(info.timeLoaded)
      }
      dispatch(closePanelLoginGarage())
    },
    [dispatch],
  )

  const exitAndJumpToBuildMode = useCallback(
    async (isOptionalsPageExit?: boolean) => {
      if (loading.current) {
        return
      }
      if (!marketLanguage) {
        return
      }
      if (!client) {
        return
      }
      if (goClientState?.status !== GuidedOfferingClientStateStatus.Active) {
        return
      }
      const sessionId = sessionInitData?.sessionId
      if (!sessionId) {
        return
      }
      loading.current = true
      setPreparingJumpToBuildMode(true)
      dispatch(
        setGuidedOfferingStateStatus(
          GuidedOfferingClientStateStatus.WaitingForBuildMode,
        ),
      )
      try {
        let newGoInfo = goClientState.lastResponse
        if (!isOptionalsPageExit) {
          newGoInfo = await stepThroughGuidedOfferingToEnd(client, newGoInfo)
        }
        const loadResult = await exitGuidedOfferingAndLoadConfigIntoBuildMode(
          client,
          sessionId,
          newGoInfo,
        )
        switch (loadResult) {
          case GuidedOfferingExitResult.Success:
            break
          case GuidedOfferingExitResult.ChangeStepError:
            handleFatalError(t2(TextId.ERROR_SECM_CHANGE_STEP))
            return
          case GuidedOfferingExitResult.SessionFailure:
          case GuidedOfferingExitResult.UnknownError:
          default:
            throw new Error(
              'Failed to load Guided Offering configuration into Build Mode.',
            )
        }
        const info: NewTruckLoadedInfo = {
          factoryModelName: null,
          isFactoryModel: false,
          publicConfigId: null,
          savedAsId: null,
          savedAsName: null,
          source: TruckLoadedSource.GUIDED_OFFERING,
          timeLoaded: new Date(),
        }
        handleNewTruckIsLoaded(info)
        const relativeUrl = buildRelativeBuildPageUrl(marketLanguage)
        const params = relativeUrl.params
        let link = relativeUrl.path + getUrlParametersToString(params)
        setPreparingJumpToBuildMode(false)
        loading.current = false
        replaceHistoryBeforeJump(t, marketLanguage)
        navigate(link)
      } catch (err) {
        console.error(err)
        handleFatalError(t('ERROR_SERVICE_UNAVAILABLE'))
        return
      }
    },
    [
      client,
      handleFatalError,
      navigate,
      dispatch,
      goClientState,
      marketLanguage,
      handleNewTruckIsLoaded,
      sessionInitData,
      t,
      t2,
    ],
  )

  const isPublicConfigGuid = (s: string) => {
    let exampleGuid = '0f8fad5b-d9cb-469f-a165-70867728950e'
    const regex = new RegExp(
      '^[0-9A-Fa-f]{8}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{12}$',
    )
    return s.length === exampleGuid.length && regex.test(s)
  }

  const handleTimelineJumpToBuildMode = useCallback(async () => {
    if (!marketLanguage) {
      return
    }
    try {
      // Changed as part of SC1M-1256.
      const pubCfgId = t('SKIP_TO_BUILD_MODE_CONFIG_ID').trim()
      if (isPublicConfigGuid(pubCfgId) && !urlHasBevParam()) {
        console.log('Using custom configuration ')
        if (loading.current) {
          console.log('Already loading, ignoring click.')
          return
        }
        loading.current = true
        setPreparingJumpToBuildMode(true)
        const etelMarket = marketLanguage.market
        const etelLanguage = marketLanguage.language
        const sessionId = sessionInitData?.sessionId
        if (!sessionId) {
          throw new Error('Expected sessionId to be defined.')
        }
        if (!etelMarket) {
          throw new Error('Expected etelMarket to be defined.')
        }
        if (!etelLanguage) {
          throw new Error('Expected etelLanguage to be defined.')
        }
        if (!client) {
          throw new Error('Expected client to be defined.')
        }
        await client.publicConfigV2Load(sessionId, pubCfgId)

        // TODO: Handle the response, display the consequence of change dialog
        // when needed, but with only the OK button, it's not possible to
        // rollback the configuration here.
        const coc = await client.getInitialConsequenceOfChange({
          sessionId,
        })
        if (!coc.getInitialConsequenceOfChange) {
          if (coc.error === SESSION_FAILURE) {
            handleSessionFailure()
            return
          }
          handleFatalError()
          return
        }
        const info: NewTruckLoadedInfo = {
          factoryModelName: coc.factoryModelName || null,
          isFactoryModel: coc.isFactoryModel || false,
          publicConfigId: pubCfgId,
          savedAsId: null,
          savedAsName: null,
          source: TruckLoadedSource.PUBLIC_CONFIG,
          timeLoaded: new Date(),
        }
        handleNewTruckIsLoaded(info)
        const relativeUrl = buildRelativeBuildPageUrl(marketLanguage)
        const params = relativeUrl.params
        let link = relativeUrl.path + getUrlParametersToString(params)
        setPreparingJumpToBuildMode(false)
        loading.current = false
        replaceHistoryBeforeJump(t, marketLanguage)
        navigate(link)
      } else {
        exitAndJumpToBuildMode()
      }
    } catch (err) {
      console.error(err)
      handleFatalError()
    }
  }, [
    client,
    exitAndJumpToBuildMode,
    handleFatalError,
    handleNewTruckIsLoaded,
    handleSessionFailure,
    navigate,
    marketLanguage,
    sessionInitData,
    t,
  ])

  useDetectMouseCapabilities()

  // TODO: Review this later.
  const appClass = clsx({
    App: true,
  })

  // Handle session heartbeat.
  useEffect(() => {
    if (!client) {
      return
    }
    const sessionId = sessionInitData?.sessionId
    if (!sessionId) {
      return
    }
    const id = setInterval(async () => {
      try {
        await client.heartbeat(sessionId)
      } catch {
        clearInterval(id)
        handleSessionFailure()
      }
    }, 30 * 1000) // 30 seconds, same as the old GUI.
    return () => {
      clearInterval(id)
    }
  }, [client, handleSessionFailure, sessionInitData])

  // Get startup data, store startup data.
  const doStartupOnce = useRef(false)
  useEffect(() => {
    async function doAsyncStuff() {
      doStartupOnce.current = true
      try {
        const storedSessionId = getAndDeleteSessionIdFromSessionStorage()
        if (!initialMarketLanguage) {
          throw new Error('Expected initialMarketLanguage to be defined.')
        }
        const backendUrl = buildBackendUrl()
        const client = buildClient(backendUrl)

        // Try to access a protected path to see if we need to trigger a login.
        const startLoginUrl =
          backendUrl +
          '/api/start_login?originalUrl=' +
          encodeURIComponent(window.location.href)
        const testAuthUrl = backendUrl + '/api/build_info'
        try {
          const response = await fetch(testAuthUrl)
          if (response.redirected || !response.ok) {
            throw new Error('Try to login.')
          }
        } catch {
          // Interpret any error as a need to login, the request above should
          // never fail for other reasons. TODO: Detect other errors (network
          // errors can always happen, or bugs). Navigate the web browser tab to
          // a protected resource and allow the tab to be redirected to the
          // login.
          window.location.href = startLoginUrl
          return
        }

        const startAPIResponse = await startApplication(
          backendUrl,
          client,
          initialMarketLanguage,
          window.tempStartupCriticalState.appData.show_preview_texts,
          storedSessionId ?? undefined,
          window.tempStartupCriticalState.appData.sessionTimeoutSeconds,
        )

        // Test API, to avoid depending on intercepting http requests, which
        // doesn't seem to only support matching the path and not any part of
        // the http body which would be necessary for the create_session
        // request.

        // Build menu texts and store in redux
        const newTexts = buildMenuTexts(startAPIResponse.sessionInitData)
        dispatch(setMenuTexts(newTexts))

        const languageInfo = startAPIResponse.marketSettings.languages.find(
          (l) => l.etelLanguage === initialMarketLanguage.language,
        )
        if (!languageInfo) {
          throw new Error('Expected languageInfo to be defined.')
        }
        let smartDashCampaignOverride: SmartDashCampaignMode | null = null
        if (
          window.tempStartupCriticalState.appData.smartDashCampaignVersion ===
          'ORANGE_BLOB'
        ) {
          smartDashCampaignOverride = SmartDashCampaignMode.OrangeBlob
        } else if (
          window.tempStartupCriticalState.appData.smartDashCampaignVersion ===
          'LINES_AND_CURVE'
        ) {
          smartDashCampaignOverride =
            SmartDashCampaignMode.FancyAnimatedLinesAndCurve
        }
        const smartDashCampaignRandomChoice =
          SmartDashCampaignMode.FancyAnimatedLinesAndCurve
        let newSmartDashCampaignMode = SmartDashCampaignMode.None
        if (startAPIResponse.marketSettings.smartDashCampaignEnabled) {
          newSmartDashCampaignMode =
            smartDashCampaignOverride === null
              ? smartDashCampaignRandomChoice
              : smartDashCampaignOverride
        }
        setSmartDashCampaignMode(newSmartDashCampaignMode)
        window.languageIso = languageInfo.isoCode
        window.etelMarket = initialMarketLanguage.market.toFixed(0)
        window.etelLanguage = initialMarketLanguage.language.toFixed(0)
        registerMopinionEventListeners()
        dispatch(setInitData(startAPIResponse))
        dispatch(setInitState(startAPIResponse))
        dispatch(setSessionId(startAPIResponse.sessionInitData.sessionId))
      } catch (err) {
        endPreloader()
        console.log('Cought a custom error:', err)
        dispatch(
          setError({
            errorLevel: 2,
            friendlyErrorMessage: t('ERROR_FRIENDLY_SERVER_DISCONNECTED'),
            //errorMessage: `Could not connect to Scania servers.`,
            errorMessage: ('' + err).slice(0, 300),
          }),
        )
      }
    }
    if (!doStartupOnce.current) doAsyncStuff()
  }, [dispatch, initialMarketLanguage, t])
  // End: Get session.

  const preloadDone = useRef(false)
  useDidUpdateEffect(() => {
    if (hidePreloader && !preloadDone.current) {
      endPreloader()
      preloadDone.current = true
    }
  }, [hidePreloader])

  const handleBack = () => {
    // TODO: If we move the BrowserRouter to index.js in the ReactDOM.render
    // block, it may be possible to interact with the router api here instead of
    // window.history. Going back with the browser back button should behave in
    // the same way though so this should be correct.
    //
    // TODO: The above TODO is almost done! Try to use the useHistory part of
    // React Router instead of window!
    window.history.back()
  }

  // Moved here from GlobalStyles.js.
  // TODO: Consider eliminating --app-height from the entire application.
  // TODO: Try depending only on height and re-test on all devices after 8.3.2.
  useEffect(() => {
    document.body.style.setProperty('--app-height', viewportHeight + 'px')
  }, [viewportHeight])

  // Bridge the Consequence of Change modal dialog interaction through a Promise
  // to allow using `await` for the dialog user input.
  const handleConsequenceOfChange: ConsequenceOfChangeHandler = useCallback(
    (
      data: ConsequenceOfChangeDialogData,
    ): Promise<ConsequeceOfChangeUserChoice> => {
      return new Promise((resolve) => {
        let handleChoice = (choice: ConsequeceOfChangeUserChoice): void => {
          resolve(choice)
        }
        const props: ModalConsequenceOfChangeProps = {
          data,
          handleChoice,
        }
        setModalConsequenceOfChangeProps(props)
      })
    },
    [],
  )

  // TODO: Revisit this later.
  useEffect(() => {
    const truckInfoDate = convertReduxStateToTruckInfo(truckInfo)
    if (!marketSettings?.garageEnabled) {
      dispatch(setSaveEnabled(false))
      return
    }
    if (factoryModelsMode === GoFmMode.OnlyFactoryModels) {
      dispatch(setSaveEnabled(false))
      return
    }
    if (truckInfo?.isFactoryModel) {
      dispatch(setSaveEnabled(false))
      return
    }
    if (!truckInfo?.savedAsId) {
      dispatch(setSaveEnabled(false))
      return
    }
    if (
      userSelectionTimestamp?.getTime() === truckInfoDate?.timeLoaded.getTime()
    ) {
      dispatch(setSaveEnabled(false))
      return
    }
    dispatch(setSaveEnabled(true))
  }, [
    factoryModelsMode,
    marketSettings,
    truckInfo,
    userSelectionTimestamp,
    dispatch,
  ])

  const handleNewClick = useCallback(() => {
    // TODO: Revisit, seemed wrong. Changes as part of Garage cleanup and
    // bugfix.
    //if (
    //  marketsettings?.garageenabled &&
    //  factorymodelsmode !== only_factory_models_mode &&
    //  !trucksavedasid &&
    //  userselectiontimestamp !== truckinfo?.timeloaded
    //) {

    // garage enabled, changes but truck is not saved
    const truckInfoDate = convertReduxStateToTruckInfo(truckInfo)
    if (
      marketSettings?.garageEnabled &&
      factoryModelsMode !== GoFmMode.OnlyFactoryModels &&
      userSelectionTimestamp?.getTime() !== truckInfoDate?.timeLoaded.getTime()
    ) {
      dispatch(openModalChangesLost())
      return
    }
    // saved truck with changes
    if (saveEnabled) {
      dispatch(openModalChangesLost())
      return
    }
    // saved truck with no changes or garage not enabled
    if (!saveEnabled) {
      if (!marketLanguage) {
        throw new Error('Expected marketLanguage to be defined.')
      }
      if (!sessionInitData) {
        throw new Error('Expected sessionInitData to be defined.')
      }
      deleteEysStartConfigId()
      if (isLoggedIn) {
        setSessionIdInSessionStorage(sessionInitData.sessionId)
      }
      const relativeUrl = buildRelativeLandingPageUrl(marketLanguage)
      const params = relativeUrl.params
      const restartUrl = relativeUrl.path + getUrlParametersToString(params)
      console.log('Reload')
      document.location.href = restartUrl
      return
    }
  }, [
    marketLanguage,
    factoryModelsMode,
    isLoggedIn,
    marketSettings,
    sessionInitData,
    dispatch,
    truckInfo,
    userSelectionTimestamp,
    saveEnabled,
  ])

  const handleSaveSuccess = useCallback(() => {
    const newTime = new Date()
    if (!truckInfo) {
      dispatch(setTruckInfo(null))
    } else {
      const result = { ...truckInfo }
      result.timeLoaded = newTime.toISOString()
      dispatch(setTruckInfo(result))
    }
    setUserSelectionTimestamp(newTime)
  }, [truckInfo, dispatch])

  const handleSaveAsFromNew = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      dispatch(closeModalChangesLost())
      if (isLoggedIn) {
        dispatch(openModalSaveAsGarage())
      } else {
        actionWaitingForLogin.current = {
          run: () => {
            dispatch(openModalSaveAsGarage())
          },
        }
        dispatch(openPanelLoginGarage())
      }
    },
    [dispatch, isLoggedIn],
  )

  const openRfqModal = useCallback(() => {
    const ev: ScaniaAdobeTrackingClickRfqCtaEvent = {
      event: ScaniaAdobeEventId.RfqCtaClick,
    }
    pushAdobeEvent(ev)
    const asyncWrapper = async () => {
      const sessionId = sessionInitData?.sessionId
      if (!client) {
        return
      }
      if (!sessionId) {
        return
      }
      dispatch(setExtendedCheckState(ExtendedCheckStatus.None))
      dispatch(openModalRequestAQuote())
      try {
        dispatch(setExtendedCheckState(ExtendedCheckStatus.WaitingForResult))
        const prepResult = await client.prepareRfq(sessionId)
        const phase = prepResult.extendedCheckSuccess
          ? ExtendedCheckStatus.Success
          : ExtendedCheckStatus.Failure
        dispatch(setExtendedCheckState(phase))
      } catch (e) {
        console.error(e)
        handleFatalError()
        return
      }
    }
    asyncWrapper()
  }, [client, handleFatalError, sessionInitData, dispatch])

  const openSendChangesToEysModal = useCallback(() => {
    const ev: ScaniaAdobeTrackingClickEysSendChangesEvent = {
      event: ScaniaAdobeEventId.EysSendChangesClick,
    }
    pushAdobeEvent(ev)
    dispatch(openModalSendChangesToEys())
  }, [dispatch])

  const handleSendChangesToEysSuccess = useCallback(() => {
    setEysChangesSentWithTimestamp(userSelectionTimestamp)
  }, [userSelectionTimestamp])

  const handleSendRfq = useCallback(
    (rfq: RfqParams) => {
      dispatch(closeModalRequestAQuote())
      dispatch(setModalRfqSendingState(rfq))
    },
    [dispatch],
  )

  useEffect(() => {
    if (!sidePanelLogInIsOpen) {
      actionWaitingForLogin.current = null
      return
    }
    if (!actionWaitingForLogin.current) {
      return
    }
    if (!isLoggedIn) {
      return
    }
    console.log('Running deferred login action.')
    const action = actionWaitingForLogin.current
    actionWaitingForLogin.current = null
    action.run()
  }, [isLoggedIn, sidePanelLogInIsOpen])

  // TODO: Move marketDenomination to a new state object together with
  // userSelectionTimestamp.
  const handleSaveAsGarageModalClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      if (!isLoggedIn) {
        actionWaitingForLogin.current = {
          run: () => {
            dispatch(openModalSaveAsGarage())
          },
        }
        dispatch(openPanelLoginGarage())
      } else {
        dispatch(openModalSaveAsGarage())
      }
    },
    [dispatch, isLoggedIn],
  )

  const handleTruckIsSavedAs = useCallback(
    (id: number | null, name: string | null) => {
      if (!userSelectionTimestamp) {
        throw new Error('Expected userSelectionTimestamp to be defined.')
      }
      if (!truckInfo) {
        dispatch(setTruckInfo(null))
      } else {
        const result = { ...truckInfo }
        result.timeLoaded = userSelectionTimestamp.toISOString()
        result.savedAsId = id
        result.savedAsName = name
        dispatch(setTruckInfo(result))
      }
    },
    [userSelectionTimestamp, truckInfo, dispatch],
  )

  const handleTruckHasBeenDeleted = useCallback(
    (truck: UserConfigInfo) => {
      if (!truckInfo) {
        dispatch(setTruckInfo(null))
      } else if (truckInfo?.savedAsId === truck.id) {
        const result = { ...truckInfo }
        result.savedAsId = null
        result.savedAsName = null
        dispatch(setTruckInfo(result))
      }
    },
    [truckInfo, dispatch],
  )

  // Reset check if the truck is already saved in garage if logged out
  useEffect(() => {
    if (!isFirstCheckSavedInGarage.current) {
      return
    }
    if (!isLoggedIn) {
      if (!truckInfo) {
        dispatch(setTruckInfo(null))
      } else {
        const result = { ...truckInfo, savedAsId: null, savedAsName: null }
        dispatch(setTruckInfo(result))
      }
      isFirstCheckSavedInGarage.current = false
    }
    if (isLoggedIn) {
      isFirstCheckSavedInGarage.current = true
    }
  }, [garageList, isLoggedIn, truckInfo, dispatch])

  useEffect(() => {
    if (truckInfo) {
      // TODO: Investigate and refactor the preloader thing, it's from the early
      // days of the Guided Offering prototype.
      dispatch(hideInitialLoadingScreen())
    }
  }, [dispatch, truckInfo])

  const handleRfqSuccess = useCallback(() => {
    setRfqSentWithTimestamp(userSelectionTimestamp)
    dispatch(setRfqInput(null))
  }, [userSelectionTimestamp, dispatch])

  // Configuration has changed, erase share link cache.
  useEffect(() => {
    dispatch(setShareLink(null))
  }, [truckInfo, userSelectionTimestamp, dispatch])

  // Save a new share link when needed.
  useEffect(() => {
    if (!client) {
      return
    }
    if (!sessionInitData) {
      return
    }
    if (!marketLanguage) {
      return
    }
    // TODO: Move marketDenomination to a new state object together with
    // userSelectionTimestamp.
    if (!marketDenomination) {
      return
    }
    if (!modalShareLinkIsOpen) {
      // Share link dialog is closed or was closed just now, no need to save a
      // new link.
      return
    }
    if (shareLink) {
      // The share link is up to date, or it would be null.
      return
    }
    const asyncWrapper = async () => {
      const configAux = {}
      const response = await client.publicConfigV2Save(
        sessionInitData.sessionId,
        marketDenomination,
        configAux,
      )
      if (response.error === SESSION_FAILURE) {
        handleSessionFailure()
        return
      }
      if (!response.publicConfigV2Save) {
        throw new Error('Expected response.publicConfigV2Save to be defined.')
      }
      const config = response.publicConfigV2Save
      const linkToPublicConfig = buildAbsolutePublicConfigLink(
        marketLanguage,
        config.guid,
      )
      if (!linkToPublicConfig) {
        console.error('Failed to save share link.')
        handleFatalError()
        return
      }
      dispatch(setShareLink(linkToPublicConfig))
    }
    asyncWrapper()
  }, [
    handleFatalError,
    handleSessionFailure,
    marketDenomination,
    shareLink,
    modalShareLinkIsOpen,
    client,
    marketLanguage,
    sessionInitData,
    t,
    dispatch,
  ])

  const openUserAccountGdprConsentModal = useCallback(() => {
    dispatch(openModalUserAccountGdprConsent())
  }, [dispatch])

  return (
    <Theme>
      <GlobalStyle />
      <AppStyle className={appClass} $appHeight={viewportHeight || 0}>
        {
          // TODO: Consider removing GridContainer, or removing the grid related
          // rules and renaming it.
        }
        <PageRoot>
          <AppHeader />
          <Routes>
            <Route path="/" element={<LandingHomeNew />} />

            <Route
              path="/reset-password/:market/:language"
              element={<ResetPassword />}
            />
            <Route
              path="/factory-models/:market/:language"
              element={
                <FactoryModels
                  handleFatalError={handleFatalError}
                  handleNewTruckIsLoaded={handleNewTruckIsLoaded}
                  handleSessionFailure={handleFatalError}
                />
              }
            />
            <Route
              path="/factory-model-summary/:market/:language/:modelId"
              element={<FactoryModelSummaryPage />}
            />
            <Route
              path="/go/go-start/:market/:language"
              element={
                <OperationsPage
                  handleGoBack={handleBack}
                  handleTimelineSkip={handleTimelineJumpToBuildMode}
                />
              }
            />
            <Route
              path="/go/grid/:market/:language/:desiredselection"
              element={
                <ApplicationPage
                  handleFatalError={handleFatalError}
                  handleGoBack={handleBack}
                  handleTimelineSkip={handleTimelineJumpToBuildMode}
                />
              }
            />
            <Route
              path="/go/slider/:market/:language/:desiredselection"
              element={
                <MultiLayoutPage
                  handleFatalError={handleFatalError}
                  handleGoBack={handleBack}
                  handleTimelineSkip={handleTimelineJumpToBuildMode}
                />
              }
            />
            <Route
              path="/go/options/:market/:language/:desiredselection"
              element={
                <OptionalsPage
                  handleFatalError={handleFatalError}
                  handleGoBack={handleBack}
                  handleJumpToBuildMode={
                    exitFromOptionalsPageAndJumpToBuildMode
                  }
                  handleTimelineSkip={exitFromOptionalsPageAndJumpToBuildMode}
                />
              }
            />
            <Route
              path="/build/:market/:language"
              element={
                <BuildModePage
                  eysChangesSentWithTimestamp={eysChangesSentWithTimestamp}
                  handleConsequenceOfChange={handleConsequenceOfChange}
                  handleFatalError={handleFatalError}
                  handleNewClick={handleNewClick}
                  handleRequestAQuoteClick={openRfqModal}
                  handleSaveAsGarageClick={handleSaveAsGarageModalClick}
                  handleSendChangesToEysClick={openSendChangesToEysModal}
                  handleSessionFailure={handleSessionFailure}
                  handleUserSelection={() =>
                    setUserSelectionTimestamp(new Date())
                  }
                  handleCloseModalConsequenseOfChange={() =>
                    setModalConsequenceOfChangeProps(null)
                  }
                  rfqSentWithTimestamp={rfqSentWithTimestamp}
                  smartDashCampaignMode={smartDashCampaignMode}
                  userSelectionTimestamp={userSelectionTimestamp}
                />
              }
            />
            <Route
              path="/link/:market/:language/:pubc"
              element={
                <LinkLandingPage
                  handleFatalError={handleFatalError}
                  handleNewTruckIsLoaded={handleNewTruckIsLoaded}
                  handleSessionFailure={handleSessionFailure}
                />
              }
            />
          </Routes>
        </PageRoot>
        <SidePanelInfo />
        <SidePanelLoginAndGarage
          handleConsequenceOfChange={handleConsequenceOfChange}
          handleFatalError={handleFatalError}
          handleNewTruckIsLoaded={handleNewTruckIsLoaded}
          handleSessionFailure={handleSessionFailure}
          handleTruckHasBeenDeleted={handleTruckHasBeenDeleted}
          handleUserAccountGdprConsent={openUserAccountGdprConsentModal}
          handleCloseModalConsequenseOfChange={() =>
            setModalConsequenceOfChangeProps(null)
          }
        />
        <SidePanelReadMore />
        {modalRequestAQuoteIsOpen && (
          <ModalRequestAQuote
            data-name="ModalPopUpRFQ"
            handleRfqSendClick={handleSendRfq}
          />
        )}
        {modalSendChangesToEysIsOpen && truckInfo?.publicConfigId && (
          <ModalSendChangesToEys
            handleSuccess={handleSendChangesToEysSuccess}
            data-name="ModalPopUpEys"
          />
        )}
        {!modalRequestAQuoteIsOpen &&
          extendedCheckState === ExtendedCheckStatus.WaitingForResult && (
            <ModalWaitingForExtendedCheck data-name="ModalWaitingForExtendedCheck" />
          )}
        {modalRfqSendingState &&
          (extendedCheckState === ExtendedCheckStatus.Success ||
            extendedCheckState === ExtendedCheckStatus.Failure) && (
            <ModalRfqSender
              handleSuccess={handleRfqSuccess}
              data-name="ModalRfqSentConfirmation"
            />
          )}

        {modalSaveAsGarageIsOpen && (
          <ModalSaveAsGarage
            handleFatalError={handleFatalError}
            handleSessionFailure={handleSessionFailure}
            handleTruckIsSavedAs={handleTruckIsSavedAs}
            data-name="ModalSaveAsGarage"
          />
        )}
        {modalSaveGarageIsOpen && truckInfo && (
          <ModalSaveGarage
            handleFatalError={handleFatalError}
            handleSessionFailure={handleSessionFailure}
            handleSuccess={handleSaveSuccess}
            data-name="ModalSaveGarage"
          />
        )}
        {modalSavePdfState && <ModalSavePdf data-name="ModalSavePdf" />}
        {modalSaveImageState && truckInfo && (
          <ModalSaveImage
            handleFatalError={handleFatalError}
            handleSessionFailure={handleSessionFailure}
            data-name="ModalSaveImage"
          />
        )}
        {modalSaveQrState && (
          <ModalSaveQr
            handleSessionFailure={handleSessionFailure}
            data-name="ModalSaveQr"
          />
        )}
        {modalSaveXmlState && <ModalSaveXml data-name="ModalSaveXml" />}
        {modalShareEmailState && (
          <ModalShareEmail
            handleSessionFailure={handleSessionFailure}
            data-name="ModalShareEmail"
          />
        )}
        {modalShareLinkIsOpen && <ModalShareLink data-name="ModalShareLink" />}
        {modalShareFacebookState && (
          <ModalShareFacebook
            data-name="ModalShareFacebook"
            handleFatalError={handleFatalError}
            handleSessionFailure={handleSessionFailure}
          />
        )}
        {modalShareWhatsAppState && (
          <ModalShareWhatsApp
            data-name="ModalShareWhatsApp"
            handleSessionFailure={handleSessionFailure}
          />
        )}
        {modalConsequenceOfChangeProps ? (
          <ModalConsequenceOfChange
            data-name="ModalConsequenceOfChange"
            data={modalConsequenceOfChangeProps.data}
            handleChoice={modalConsequenceOfChangeProps.handleChoice}
          />
        ) : null}
        {modalChangesLostIsOpen && (
          <ModalChangesLost
            handleSaveAsClick={handleSaveAsFromNew}
            data-name="ModalChangesLost"
          />
        )}
        {modalUploadXmlIsOpen && marketLanguage && (
          <ModalUploadXml
            data-name="ModalUploadXml"
            handleFatalError={handleFatalError}
            handleNewTruckIsLoaded={handleNewTruckIsLoaded}
            handleSessionFailure={handleSessionFailure}
            handleConsequenceOfChange={handleConsequenceOfChange}
            handleCloseModalConsequenseOfChange={() =>
              setModalConsequenceOfChangeProps(null)
            }
          />
        )}
        {modalUserAccountGdprConsentIsOpen && (
          <ModalUserAccountGdprConsent
            handleFatalError={handleFatalError}
            handleSessionFailure={handleSessionFailure}
            data-name="ModalUserAccountGdprConsent"
          />
        )}
        {errorModalState !== null && <ModalError data-name="ModalErrorXml" />}
        {showCookieConfigGui && (
          <ModalCookieConfigurator
            onAcceptAllCookies={acceptAllCookies}
            onSaveCookieSettings={saveCookieConfig}
          />
        )}
        <Portal>
          <ErrorLayer />
        </Portal>
        {preparingJumpToBuildMode && <LoadingTruck />}
      </AppStyle>
    </Theme>
  )
}

export default App

// Date is stored as string in Redux
export function convertReduxStateToTruckInfo(
  truckInfoRedux: NewTruckLoadedInfoRedux | null,
): NewTruckLoadedInfo | null {
  if (!truckInfoRedux) {
    return null
  }
  return {
    ...truckInfoRedux,
    timeLoaded: new Date(truckInfoRedux.timeLoaded), // Convert string to Date
  }
}

// TODO: Revisit later, still needed after the partial rewrite of GO?
function replaceHistoryBeforeJump(
  t: TextGetter,
  marketLanguage: MarketLanguageState,
) {
  const url = `/?etel_market=${marketLanguage.market}&etel_language=${marketLanguage.language}`
  // https://bugzilla.mozilla.org/show_bug.cgi?id=753264
  window.history.replaceState(
    { cause: 'JUMP_TO_BUILD_MODE' },
    `${t('WINDOW_TITLE')} - Guided Offering`,
    url,
  )
}

function useSetDocumentTitle(t: TextGetter) {
  useEffect(() => {
    window.document.title = t('WINDOW_TITLE')
  }, [t])
}

/**
 * https://mopinion.atlassian.net/wiki/spaces/KB/pages/509313085/How+do+I+use+Mopinion+events
 **/
function registerMopinionEventListeners() {
  document.addEventListener('mopinion_feedback_sent', function (e: any) {
    const adobeEvent: ScaniaAdobeTrackingMopinionFeedbackSent = {
      event: ScaniaAdobeEventId.MopinionFeedbackSent,
      eventInfo: e?.detail,
    }
    pushAdobeEvent(adobeEvent)
  })
  document.addEventListener('mopinion_hidden', function (e: any) {
    const adobeEvent: ScaniaAdobeTrackingMopinionHidden = {
      event: ScaniaAdobeEventId.MopinionHidden,
      eventInfo: e?.detail,
    }
    pushAdobeEvent(adobeEvent)
  })
  document.addEventListener('mopinion_next', function (e: any) {
    const adobeEvent: ScaniaAdobeTrackingMopinionNext = {
      event: ScaniaAdobeEventId.MopinionNext,
      eventInfo: e?.detail,
    }
    pushAdobeEvent(adobeEvent)
  })
  document.addEventListener('mopinion_ready', function (e: any) {
    const adobeEvent: ScaniaAdobeTrackingMopinionReady = {
      event: ScaniaAdobeEventId.MopinionReady,
      eventInfo: e?.detail,
    }
    pushAdobeEvent(adobeEvent)
  })
  document.addEventListener('mopinion_redirect', function (e: any) {
    const adobeEvent: ScaniaAdobeTrackingMopinionRedirect = {
      event: ScaniaAdobeEventId.MopinionRedirect,
      eventInfo: e?.detail,
    }
    pushAdobeEvent(adobeEvent)
  })
  document.addEventListener('mopinion_shown', function (e: any) {
    const adobeEvent: ScaniaAdobeTrackingMopinionShown = {
      event: ScaniaAdobeEventId.MopinionShown,
      eventInfo: e?.detail,
    }
    pushAdobeEvent(adobeEvent)
  })
  document.addEventListener('mopinion_will_hide', function (e: any) {
    const adobeEvent: ScaniaAdobeTrackingMopinionWillHide = {
      event: ScaniaAdobeEventId.MopinionWillHide,
      eventInfo: e?.detail,
    }
    pushAdobeEvent(adobeEvent)
  })
  document.addEventListener('mopinion_will_show', function (e: any) {
    const adobeEvent: ScaniaAdobeTrackingMopinionWillShow = {
      event: ScaniaAdobeEventId.MopinionWillShow,
      eventInfo: e?.detail,
    }
    pushAdobeEvent(adobeEvent)
  })
}
