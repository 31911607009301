/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { FactoryModelSummaryGroup } from './FactoryModelSummaryGroup'
import {
  FactoryModelSummaryGroupFromJSON,
  FactoryModelSummaryGroupFromJSONTyped,
  FactoryModelSummaryGroupToJSON,
} from './FactoryModelSummaryGroup'

/**
 *
 * @export
 * @interface FactoryModelSummary
 */
export interface FactoryModelSummary {
  /**
   *
   * @type {Array<FactoryModelSummaryGroup>}
   * @memberof FactoryModelSummary
   */
  groups?: Array<FactoryModelSummaryGroup> | null
}

/**
 * Check if a given object implements the FactoryModelSummary interface.
 */
export function instanceOfFactoryModelSummary(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function FactoryModelSummaryFromJSON(json: any): FactoryModelSummary {
  return FactoryModelSummaryFromJSONTyped(json, false)
}

export function FactoryModelSummaryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): FactoryModelSummary {
  if (json === undefined || json === null) {
    return json
  }
  return {
    groups: !exists(json, 'Groups')
      ? undefined
      : json['Groups'] === null
      ? null
      : (json['Groups'] as Array<any>).map(FactoryModelSummaryGroupFromJSON),
  }
}

export function FactoryModelSummaryToJSON(
  value?: FactoryModelSummary | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    Groups:
      value.groups === undefined
        ? undefined
        : value.groups === null
        ? null
        : (value.groups as Array<any>).map(FactoryModelSummaryGroupToJSON),
  }
}
