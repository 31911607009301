export interface BlendColorRgb {
  R: Number
  G: Number
  B: Number
}

// Based on blend_colors.json from the image package.
//
// TODO: Add missing values to the blend_colors.json and fetch the data through
// the web API (?).
export const BlendColors: Record<string, BlendColorRgb> = {
  '02575~A': { R: 0, G: 22, B: 65 },
  '02575~AA': { R: 111, G: 4, B: 21 },
  '02575~AC': { R: 194, G: 198, B: 199 },
  '02575~AF': { R: 224, G: 215, B: 204 },
  '02575~AG': { R: 35, G: 39, B: 42 },
  '02575~AH': { R: 49, G: 77, B: 98 },
  '02575~AJ': { R: 0, G: 43, B: 65 },
  '02575~AL': { R: 64, G: 110, B: 159 },
  '02575~AN': { R: 23, G: 21, B: 22 },
  '02575~B': { R: 62, G: 115, B: 231 },
  '02575~BA': { R: 16, G: 14, B: 15 },
  '02575~BB': { R: 139, G: 143, B: 144 },
  '02575~BC': { R: 102, G: 116, B: 137 },
  '02575~BD': { R: 87, G: 87, B: 84 },
  '02575~BE': { R: 137, G: 128, B: 106 },
  '02575~BF': { R: 227, G: 158, B: 48 },
  '02575~C': { R: 139, G: 175, B: 87 },
  '02575~R': { R: 161, G: 138, B: 51 },
  '06481~A': { R: 209, G: 183, B: 139 },
  '06481~AA': { R: 231, G: 217, B: 187 },
  '06481~AB': { R: 230, G: 198, B: 130 },
  '06481~AC': { R: 222, G: 203, B: 158 },
  '06481~AD': { R: 23, G: 21, B: 22 },
  '06481~AF': { R: 65, G: 82, B: 136 },
  '06481~AG': { R: 93, G: 108, B: 132 },
  '06481~AH': { R: 165, G: 156, B: 129 },
  '06481~AJ': { R: 217, G: 191, B: 161 },
  '06481~AK': { R: 58, G: 59, B: 60 },
  '06481~AL': { R: 60, G: 61, B: 63 },
  '06481~AM': { R: 104, G: 80, B: 114 },
  '06481~AN': { R: 61, G: 61, B: 62 },
  '06481~AP': { R: 61, G: 71, B: 114 },
  '06481~AQ': { R: 48, G: 87, B: 145 },
  '06481~AR': { R: 62, G: 69, B: 88 },
  '06481~AS': { R: 60, G: 65, B: 72 },
  '06481~AT': { R: 65, G: 84, B: 105 },
  '06481~AU': { R: 16, G: 96, B: 152 },
  '06481~AV': { R: 57, G: 74, B: 130 },
  '06481~AX': { R: 59, G: 72, B: 100 },
  '06481~AY': { R: 90, G: 130, B: 181 },
  '06481~AZ': { R: 60, G: 100, B: 127 },
  '06481~B': { R: 38, G: 124, B: 183 },
  '06481~BA': { R: 0, G: 124, B: 129 },
  '06481~BB': { R: 70, G: 91, B: 122 },
  '06481~BC': { R: 42, G: 78, B: 117 },
  '06481~BD': { R: 54, G: 70, B: 91 },
  '06481~BE': { R: 56, G: 73, B: 89 },
  '06481~BF': { R: 43, G: 99, B: 137 },
  '06481~BG': { R: 21, G: 107, B: 156 },
  '06481~BH': { R: 57, G: 70, B: 117 },
  '06481~BJ': { R: 41, G: 86, B: 133 },
  '06481~BK': { R: 59, G: 83, B: 102 },
  '06481~BL': { R: 47, G: 143, B: 147 },
  '06481~BM': { R: 81, G: 172, B: 219 },
  '06481~BN': { R: 60, G: 95, B: 158 },
  '06481~BP': { R: 75, G: 71, B: 120 },
  '06481~BQ': { R: 50, G: 112, B: 153 },
  '06481~BR': { R: 57, G: 65, B: 90 },
  '06481~BS': { R: 48, G: 74, B: 109 },
  '06481~BT': { R: 63, G: 136, B: 187 },
  '06481~BU': { R: 9, G: 95, B: 147 },
  '06481~BV': { R: 77, G: 109, B: 144 },
  '06481~BX': { R: 61, G: 70, B: 97 },
  '06481~BY': { R: 65, G: 67, B: 101 },
  '06481~BZ': { R: 35, G: 87, B: 142 },
  '06481~C': { R: 87, G: 167, B: 194 },
  '06481~CA': { R: 22, G: 170, B: 193 },
  '06481~CB': { R: 53, G: 68, B: 104 },
  '06481~CC': { R: 8, G: 145, B: 183 },
  '06481~CD': { R: 59, G: 69, B: 97 },
  '06481~CE': { R: 0, G: 126, B: 148 },
  '06481~CF': { R: 55, G: 134, B: 185 },
  '06481~CG': { R: 26, G: 91, B: 141 },
  '06481~CH': { R: 108, G: 150, B: 176 },
  '06481~CJ': { R: 96, G: 133, B: 182 },
  '06481~CK': { R: 56, G: 65, B: 91 },
  '06481~CL': { R: 112, G: 186, B: 220 },
  '06481~CM': { R: 50, G: 74, B: 119 },
  '06481~CN': { R: 56, G: 71, B: 120 },
  '06481~CP': { R: 59, G: 61, B: 69 },
  '06481~CQ': { R: 44, G: 95, B: 132 },
  '06481~CR': { R: 93, G: 175, B: 211 },
  '06481~CS': { R: 39, G: 100, B: 140 },
  '06481~CT': { R: 59, G: 64, B: 75 },
  '06481~CU': { R: 88, G: 176, B: 221 },
  '06481~CV': { R: 60, G: 68, B: 88 },
  '06481~CX': { R: 29, G: 104, B: 144 },
  '06481~CY': { R: 62, G: 97, B: 163 },
  '06481~CZ': { R: 57, G: 66, B: 80 },
  '06481~D': { R: 22, G: 104, B: 148 },
  '06481~DA': { R: 95, G: 117, B: 130 },
  '06481~DB': { R: 0, G: 140, B: 186 },
  '06481~DC': { R: 86, G: 112, B: 123 },
  '06481~DD': { R: 0, G: 136, B: 194 },
  '06481~DE': { R: 8, G: 99, B: 153 },
  '06481~DF': { R: 47, G: 75, B: 123 },
  '06481~DG': { R: 43, G: 140, B: 147 },
  '06481~DH': { R: 70, G: 87, B: 150 },
  '06481~DJ': { R: 63, G: 100, B: 169 },
  '06481~DK': { R: 53, G: 69, B: 92 },
  '06481~DL': { R: 54, G: 89, B: 124 },
  '06481~DM': { R: 60, G: 92, B: 159 },
  '06481~DN': { R: 160, G: 193, B: 206 },
  '06481~DP': { R: 0, G: 113, B: 172 },
  '06481~DQ': { R: 59, G: 61, B: 68 },
  '06481~DR': { R: 0, G: 106, B: 164 },
  '06481~DS': { R: 107, G: 109, B: 110 },
  '06481~DT': { R: 43, G: 80, B: 104 },
  '06481~DU': { R: 56, G: 86, B: 110 },
  '06481~DV': { R: 18, G: 148, B: 199 },
  '06481~DX': { R: 2, G: 98, B: 151 },
  '06481~DY': { R: 132, G: 169, B: 197 },
  '06481~DZ': { R: 73, G: 77, B: 84 },
  '06481~E': { R: 122, G: 162, B: 36 },
  '06481~EA': { R: 57, G: 76, B: 124 },
  '06481~EB': { R: 0, G: 143, B: 155 },
  '06481~EC': { R: 32, G: 47, B: 69 },
  '06481~ED': { R: 207, G: 187, B: 137 },
  '06481~EE': { R: 5, G: 102, B: 148 },
  '06481~EF': { R: 96, G: 139, B: 62 },
  '06481~EG': { R: 0, G: 119, B: 161 },
  '06481~EJ': { R: 55, G: 69, B: 111 },
  '06481~EK': { R: 55, G: 80, B: 131 },
  '06481~EL': { R: 69, G: 90, B: 130 },
  '06481~EN': { R: 66, G: 91, B: 71 },
  '06481~EQ': { R: 0, G: 124, B: 169 },
  '06481~ER': { R: 16, G: 98, B: 143 },
  '06481~ES': { R: 137, G: 128, B: 106 },
  '06481~ET': { R: 0, G: 116, B: 182 },
  '06481~EU': { R: 152, G: 59, B: 99 },
  '06481~EV': { R: 0, G: 42, B: 91 },
  '06481~EX': { R: 0, G: 175, B: 205 },
  '06481~EY': { R: 56, G: 67, B: 77 },
  '06481~EZ': { R: 61, G: 71, B: 119 },
  '06481~F': { R: 122, G: 155, B: 199 },
  '06481~FA': { R: 48, G: 79, B: 118 },
  '06481~FB': { R: 0, G: 152, B: 198 },
  '06481~FC': { R: 0, G: 146, B: 188 },
  '06481~FD': { R: 0, G: 149, B: 170 },
  '06481~FE': { R: 0, G: 78, B: 104 },
  '06481~FF': { R: 0, G: 93, B: 130 },
  '06481~FG': { R: 0, G: 195, B: 221 },
  '06481~FH': { R: 0, G: 80, B: 100 },
  '06481~FJ': { R: 0, G: 97, B: 150 },
  '06481~FK': { R: 105, G: 189, B: 214 },
  '06481~FL': { R: 70, G: 68, B: 65 },
  '06481~FN': { R: 90, G: 69, B: 62 },
  '06481~FQ': { R: 111, G: 82, B: 65 },
  '06481~FR': { R: 70, G: 45, B: 43 },
  '06481~FS': { R: 16, G: 107, B: 79 },
  '06481~FT': { R: 58, G: 75, B: 71 },
  '06481~FU': { R: 38, G: 91, B: 77 },
  '06481~FV': { R: 45, G: 137, B: 97 },
  '06481~FX': { R: 73, G: 125, B: 108 },
  '06481~FY': { R: 65, G: 106, B: 69 },
  '06481~FZ': { R: 81, G: 119, B: 73 },
  '06481~G': { R: 0, G: 120, B: 81 },
  '06481~GA': { R: 97, G: 119, B: 103 },
  '06481~GB': { R: 27, G: 114, B: 93 },
  '06481~GC': { R: 41, G: 100, B: 73 },
  '06481~GD': { R: 55, G: 84, B: 66 },
  '06481~GE': { R: 138, G: 163, B: 130 },
  '06481~GF': { R: 83, G: 149, B: 67 },
  '06481~GG': { R: 0, G: 129, B: 71 },
  '06481~GH': { R: 66, G: 91, B: 67 },
  '06481~GJ': { R: 64, G: 67, B: 64 },
  '06481~GK': { R: 40, G: 96, B: 93 },
  '06481~GL': { R: 60, G: 124, B: 69 },
  '06481~GM': { R: 50, G: 93, B: 75 },
  '06481~GN': { R: 71, G: 123, B: 84 },
  '06481~GP': { R: 59, G: 80, B: 66 },
  '06481~GQ': { R: 54, G: 86, B: 86 },
  '06481~GR': { R: 11, G: 103, B: 93 },
  '06481~GS': { R: 65, G: 116, B: 74 },
  '06481~GT': { R: 113, G: 135, B: 103 },
  '06481~GU': { R: 85, G: 162, B: 75 },
  '06481~GV': { R: 63, G: 73, B: 66 },
  '06481~GX': { R: 90, G: 138, B: 83 },
  '06481~GY': { R: 40, G: 146, B: 88 },
  '06481~GZ': { R: 79, G: 82, B: 75 },
  '06481~H': { R: 62, G: 73, B: 66 },
  '06481~HA': { R: 181, G: 211, B: 178 },
  '06481~HB': { R: 68, G: 164, B: 160 },
  '06481~HC': { R: 64, G: 117, B: 75 },
  '06481~HD': { R: 40, G: 136, B: 95 },
  '06481~HE': { R: 124, G: 175, B: 175 },
  '06481~HF': { R: 52, G: 79, B: 72 },
  '06481~HG': { R: 48, G: 96, B: 70 },
  '06481~HH': { R: 81, G: 156, B: 82 },
  '06481~HK': { R: 58, G: 75, B: 65 },
  '06481~HL': { R: 7, G: 103, B: 93 },
  '06481~HM': { R: 60, G: 98, B: 97 },
  '06481~HN': { R: 88, G: 109, B: 81 },
  '06481~HP': { R: 85, G: 82, B: 70 },
  '06481~HQ': { R: 86, G: 163, B: 76 },
  '06481~HR': { R: 182, G: 195, B: 59 },
  '06481~HT': { R: 59, G: 73, B: 67 },
  '06481~HU': { R: 53, G: 83, B: 73 },
  '06481~HV': { R: 49, G: 98, B: 107 },
  '06481~HY': { R: 61, G: 80, B: 69 },
  '06481~HZ': { R: 1, G: 116, B: 80 },
  '06481~J': { R: 41, G: 99, B: 99 },
  '06481~JC': { R: 62, G: 150, B: 70 },
  '06481~JD': { R: 151, G: 192, B: 63 },
  '06481~JF': { R: 193, G: 213, B: 84 },
  '06481~JH': { R: 76, G: 87, B: 74 },
  '06481~JJ': { R: 0, G: 121, B: 80 },
  '06481~JK': { R: 83, G: 88, B: 62 },
  '06481~JL': { R: 115, G: 178, B: 73 },
  '06481~JM': { R: 100, G: 156, B: 55 },
  '06481~JQ': { R: 134, G: 178, B: 79 },
  '06481~JR': { R: 59, G: 80, B: 70 },
  '06481~JU': { R: 90, G: 140, B: 109 },
  '06481~JV': { R: 77, G: 80, B: 65 },
  '06481~JX': { R: 48, G: 97, B: 78 },
  '06481~JY': { R: 109, G: 145, B: 104 },
  '06481~JZ': { R: 85, G: 80, B: 70 },
  '06481~KA': { R: 142, G: 203, B: 81 },
  '06481~KB': { R: 126, G: 127, B: 101 },
  '06481~KD': { R: 75, G: 107, B: 100 },
  '06481~KE': { R: 34, G: 64, B: 56 },
  '06481~KF': { R: 50, G: 75, B: 70 },
  '06481~KK': { R: 91, G: 118, B: 91 },
  '06481~KL': { R: 133, G: 177, B: 41 },
  '06481~KM': { R: 182, G: 203, B: 68 },
  '06481~KN': { R: 0, G: 168, B: 81 },
  '06481~KP': { R: 0, G: 146, B: 121 },
  '06481~KQ': { R: 0, G: 139, B: 122 },
  '06481~KR': { R: 0, G: 177, B: 91 },
  '06481~KS': { R: 209, G: 210, B: 99 },
  '06481~KT': { R: 133, G: 176, B: 41 },
  '06481~KU': { R: 0, G: 85, B: 95 },
  '06481~KV': { R: 37, G: 153, B: 139 },
  '06481~KX': { R: 0, G: 151, B: 134 },
  '06481~KY': { R: 66, G: 66, B: 59 },
  '06481~KZ': { R: 179, G: 181, B: 174 },
  '06481~L': { R: 69, G: 72, B: 74 },
  '06481~LA': { R: 154, G: 150, B: 149 },
  '06481~LB': { R: 85, G: 88, B: 94 },
  '06481~LC': { R: 198, G: 202, B: 198 },
  '06481~LD': { R: 156, G: 168, B: 179 },
  '06481~LE': { R: 184, G: 181, B: 166 },
  '06481~LF': { R: 150, G: 154, B: 154 },
  '06481~LG': { R: 130, G: 141, B: 148 },
  '06481~LH': { R: 148, G: 156, B: 162 },
  '06481~LJ': { R: 118, G: 122, B: 119 },
  '06481~LK': { R: 202, G: 202, B: 202 },
  '06481~LL': { R: 125, G: 125, B: 112 },
  '06481~LM': { R: 102, G: 114, B: 119 },
  '06481~LP': { R: 165, G: 173, B: 176 },
  '06481~LQ': { R: 159, G: 165, B: 169 },
  '06481~LS': { R: 150, G: 149, B: 140 },
  '06481~LU': { R: 92, G: 92, B: 88 },
  '06481~LV': { R: 79, G: 89, B: 98 },
  '06481~LX': { R: 156, G: 156, B: 158 },
  '06481~LY': { R: 150, G: 143, B: 119 },
  '06481~LZ': { R: 145, G: 156, B: 148 },
  '06481~M': { R: 75, G: 80, B: 83 },
  '06481~MA': { R: 151, G: 152, B: 142 },
  '06481~MB': { R: 91, G: 95, B: 95 },
  '06481~MC': { R: 109, G: 114, B: 122 },
  '06481~ME': { R: 146, G: 152, B: 149 },
  '06481~MF': { R: 115, G: 115, B: 115 },
  '06481~MH': { R: 147, G: 147, B: 148 },
  '06481~MJ': { R: 217, G: 217, B: 214 },
  '06481~MK': { R: 62, G: 115, B: 231 },
  '06481~ML': { R: 16, G: 14, B: 15 },
  '06481~MM': { R: 0, G: 22, B: 65 },
  '06481~MN': { R: 111, G: 4, B: 21 },
  '06481~MP': { R: 194, G: 198, B: 199 },
  '06481~MQ': { R: 224, G: 215, B: 204 },
  '06481~MR': { R: 35, G: 39, B: 42 },
  '06481~MS': { R: 49, G: 77, B: 98 },
  '06481~MT': { R: 0, G: 43, B: 65 },
  '06481~MU': { R: 161, G: 138, B: 51 },
  '06481~MV': { R: 139, G: 143, B: 144 },
  '06481~MX': { R: 64, G: 110, B: 159 },
  '06481~MY': { R: 189, G: 100, B: 59 },
  '06481~MZ': { R: 208, G: 77, B: 55 },
  '06481~N': { R: 197, G: 91, B: 61 },
  '06481~NA': { R: 223, G: 94, B: 75 },
  '06481~NB': { R: 212, G: 82, B: 61 },
  '06481~NC': { R: 217, G: 89, B: 55 },
  '06481~ND': { R: 200, G: 120, B: 59 },
  '06481~NE': { R: 200, G: 76, B: 60 },
  '06481~NF': { R: 234, G: 102, B: 54 },
  '06481~NG': { R: 233, G: 127, B: 57 },
  '06481~NH': { R: 232, G: 125, B: 55 },
  '06481~NJ': { R: 221, G: 127, B: 47 },
  '06481~NK': { R: 236, G: 120, B: 60 },
  '06481~NL': { R: 204, G: 75, B: 53 },
  '06481~NM': { R: 228, G: 104, B: 56 },
  '06481~NN': { R: 228, G: 99, B: 54 },
  '06481~NP': { R: 200, G: 75, B: 60 },
  '06481~NQ': { R: 192, G: 74, B: 55 },
  '06481~NR': { R: 181, G: 78, B: 58 },
  '06481~NS': { R: 223, G: 125, B: 46 },
  '06481~NT': { R: 215, G: 96, B: 68 },
  '06481~NU': { R: 233, G: 128, B: 59 },
  '06481~NV': { R: 175, G: 88, B: 70 },
  '06481~NX': { R: 255, G: 128, B: 0 },
  '06481~NY': { R: 89, G: 62, B: 78 },
  '06481~NZ': { R: 142, G: 103, B: 138 },
  '06481~P': { R: 117, G: 59, B: 78 },
  '06481~PA': { R: 154, G: 70, B: 122 },
  '06481~PB': { R: 182, G: 79, B: 130 },
  '06481~PC': { R: 103, G: 79, B: 127 },
  '06481~PF': { R: 34, G: 9, B: 18 },
  '06481~PG': { R: 171, G: 67, B: 118 },
  '06481~PH': { R: 167, G: 52, B: 61 },
  '06481~PJ': { R: 137, G: 53, B: 62 },
  '06481~PK': { R: 104, G: 57, B: 62 },
  '06481~PL': { R: 146, G: 56, B: 63 },
  '06481~PM': { R: 181, G: 56, B: 62 },
  '06481~PN': { R: 103, G: 60, B: 65 },
  '06481~PP': { R: 166, G: 54, B: 59 },
  '06481~PQ': { R: 121, G: 58, B: 63 },
  '06481~PR': { R: 185, G: 52, B: 58 },
  '06481~PS': { R: 195, G: 68, B: 60 },
  '06481~PT': { R: 96, G: 58, B: 63 },
  '06481~PU': { R: 137, G: 56, B: 63 },
  '06481~PV': { R: 167, G: 60, B: 63 },
  '06481~PX': { R: 174, G: 65, B: 63 },
  '06481~PY': { R: 194, G: 60, B: 58 },
  '06481~PZ': { R: 167, G: 59, B: 61 },
  '06481~Q': { R: 169, G: 73, B: 61 },
  '06481~QA': { R: 194, G: 59, B: 58 },
  '06481~QB': { R: 125, G: 63, B: 64 },
  '06481~QC': { R: 171, G: 54, B: 60 },
  '06481~QD': { R: 224, G: 95, B: 54 },
  '06481~QE': { R: 133, G: 60, B: 62 },
  '06481~QF': { R: 173, G: 58, B: 60 },
  '06481~QG': { R: 164, G: 57, B: 61 },
  '06481~QH': { R: 128, G: 62, B: 63 },
  '06481~QJ': { R: 158, G: 58, B: 62 },
  '06481~QK': { R: 179, G: 68, B: 65 },
  '06481~QN': { R: 190, G: 57, B: 57 },
  '06481~QP': { R: 151, G: 56, B: 62 },
  '06481~QQ': { R: 167, G: 53, B: 61 },
  '06481~QR': { R: 201, G: 64, B: 63 },
  '06481~QS': { R: 138, G: 59, B: 64 },
  '06481~QT': { R: 168, G: 59, B: 62 },
  '06481~QU': { R: 177, G: 77, B: 67 },
  '06481~QV': { R: 178, G: 54, B: 61 },
  '06481~QX': { R: 161, G: 56, B: 60 },
  '06481~QY': { R: 165, G: 53, B: 59 },
  '06481~QZ': { R: 176, G: 52, B: 57 },
  '06481~R': { R: 188, G: 54, B: 57 },
  '06481~RA': { R: 163, G: 60, B: 63 },
  '06481~RC': { R: 188, G: 63, B: 60 },
  '06481~RD': { R: 118, G: 63, B: 99 },
  '06481~RF': { R: 173, G: 52, B: 57 },
  '06481~RG': { R: 139, G: 56, B: 61 },
  '06481~RJ': { R: 187, G: 113, B: 85 },
  '06481~RK': { R: 184, G: 61, B: 82 },
  '06481~RM': { R: 162, G: 58, B: 63 },
  '06481~RN': { R: 191, G: 52, B: 58 },
  '06481~RP': { R: 201, G: 60, B: 62 },
  '06481~RQ': { R: 227, G: 0, B: 46 },
  '06481~RR': { R: 187, G: 62, B: 45 },
  '06481~RT': { R: 255, G: 0, B: 255 },
  '06481~RU': { R: 233, G: 231, B: 223 },
  '06481~RV': { R: 230, G: 223, B: 204 },
  '06481~RX': { R: 240, G: 242, B: 238 },
  '06481~RY': { R: 220, G: 224, B: 227 },
  '06481~RZ': { R: 231, G: 233, B: 231 },
  '06481~S': { R: 241, G: 239, B: 229 },
  '06481~SA': { R: 237, G: 230, B: 217 },
  '06481~SB': { R: 238, G: 239, B: 238 },
  '06481~SC': { R: 218, G: 218, B: 209 },
  '06481~SD': { R: 244, G: 245, B: 238 },
  '06481~SE': { R: 238, G: 239, B: 230 },
  '06481~SF': { R: 237, G: 229, B: 206 },
  '06481~SG': { R: 242, G: 244, B: 241 },
  '06481~SH': { R: 238, G: 238, B: 238 },
  '06481~SJ': { R: 238, G: 240, B: 237 },
  '06481~SM': { R: 217, G: 229, B: 238 },
  '06481~SN': { R: 240, G: 239, B: 237 },
  '06481~SP': { R: 205, G: 226, B: 232 },
  '06481~SQ': { R: 224, G: 176, B: 82 },
  '06481~SR': { R: 235, G: 220, B: 86 },
  '06481~SS': { R: 230, G: 169, B: 64 },
  '06481~ST': { R: 242, G: 221, B: 126 },
  '06481~SU': { R: 239, G: 195, B: 1 },
  '06481~SV': { R: 240, G: 212, B: 125 },
  '06481~SX': { R: 233, G: 156, B: 29 },
  '06481~SY': { R: 247, G: 214, B: 76 },
  '06481~SZ': { R: 240, G: 206, B: 23 },
  '06481~T': { R: 246, G: 226, B: 61 },
  '06481~TA': { R: 224, G: 175, B: 52 },
  '06481~TB': { R: 247, G: 173, B: 34 },
  '06481~TC': { R: 237, G: 185, B: 29 },
  '06481~TD': { R: 240, G: 173, B: 12 },
  '06481~TE': { R: 230, G: 173, B: 39 },
  '06481~TF': { R: 244, G: 181, B: 8 },
  '06481~TG': { R: 230, G: 161, B: 42 },
  '06481~TH': { R: 255, G: 167, B: 8 },
  '06481~TJ': { R: 243, G: 207, B: 41 },
  '06481~TK': { R: 245, G: 157, B: 27 },
  '06481~TL': { R: 241, G: 179, B: 31 },
  '06481~TM': { R: 226, G: 175, B: 54 },
  '06481~TN': { R: 237, G: 193, B: 16 },
  '06481~TP': { R: 244, G: 170, B: 32 },
  '06481~TQ': { R: 240, G: 166, B: 21 },
  '06481~TR': { R: 169, G: 142, B: 57 },
  '06481~TS': { R: 255, G: 213, B: 45 },
  '06481~TT': { R: 188, G: 155, B: 91 },
  '06481~TU': { R: 225, G: 149, B: 43 },
  '06481~TV': { R: 216, G: 211, B: 119 },
  '06481~TX': { R: 248, G: 180, B: 26 },
  '06481~TY': { R: 255, G: 210, B: 81 },
  '06481~TZ': { R: 246, G: 181, B: 30 },
  '06481~U': { R: 211, G: 180, B: 122 },
  '06481~UA': { R: 193, G: 132, B: 62 },
  '06481~UB': { R: 245, G: 200, B: 41 },
  '06481~UC': { R: 232, G: 159, B: 55 },
  '06481~UD': { R: 255, G: 178, B: 41 },
  '06481~UE': { R: 236, G: 168, B: 35 },
  '06481~UF': { R: 226, G: 165, B: 58 },
  '06481~UJ': { R: 238, G: 197, B: 57 },
  '06481~UK': { R: 224, G: 198, B: 102 },
  '06481~UL': { R: 234, G: 141, B: 0 },
  '06481~UM': { R: 216, G: 139, B: 70 },
  '06481~UN': { R: 255, G: 200, B: 0 },
  '06481~UP': { R: 102, G: 116, B: 137 },
  '06481~UQ': { R: 87, G: 87, B: 84 },
  '06481~UT': { R: 250, G: 237, B: 188 },
  '06481~UX': { R: 146, G: 88, B: 73 },
  '06481~UZ': { R: 41, G: 126, B: 120 },
  '06481~VA': { R: 200, G: 223, B: 234 },
  '06481~VC': { R: 176, G: 190, B: 82 },
  '06481~VD': { R: 139, G: 175, B: 87 },
  '06481~VE': { R: 227, G: 158, B: 48 },
  '06482~A': { R: 209, G: 183, B: 139 },
  '06482~AA': { R: 231, G: 217, B: 187 },
  '06482~AB': { R: 230, G: 198, B: 130 },
  '06482~AC': { R: 222, G: 203, B: 158 },
  '06482~AD': { R: 207, G: 187, B: 137 },
  '06482~AH': { R: 165, G: 156, B: 129 },
  '06482~AJ': { R: 217, G: 191, B: 161 },
  '06482~AK': { R: 250, G: 237, B: 188 },
  '06483~A': { R: 58, G: 59, B: 60 },
  '06483~AA': { R: 60, G: 61, B: 63 },
  '06483~AC': { R: 61, G: 61, B: 62 },
  '06484~A': { R: 61, G: 71, B: 114 },
  '06484~AA': { R: 48, G: 87, B: 145 },
  '06484~AB': { R: 62, G: 69, B: 88 },
  '06484~AC': { R: 60, G: 65, B: 72 },
  '06484~AD': { R: 65, G: 84, B: 105 },
  '06484~AE': { R: 16, G: 96, B: 152 },
  '06484~AF': { R: 57, G: 74, B: 130 },
  '06484~AG': { R: 59, G: 72, B: 100 },
  '06484~AH': { R: 90, G: 130, B: 181 },
  '06484~AJ': { R: 60, G: 100, B: 127 },
  '06484~AK': { R: 38, G: 124, B: 183 },
  '06484~AL': { R: 0, G: 124, B: 129 },
  '06484~AM': { R: 70, G: 91, B: 122 },
  '06484~AN': { R: 42, G: 78, B: 117 },
  '06484~AP': { R: 54, G: 70, B: 91 },
  '06484~AQ': { R: 56, G: 73, B: 89 },
  '06484~AR': { R: 43, G: 99, B: 137 },
  '06484~AS': { R: 21, G: 107, B: 156 },
  '06484~AT': { R: 57, G: 70, B: 117 },
  '06484~AU': { R: 41, G: 86, B: 133 },
  '06484~AV': { R: 59, G: 83, B: 102 },
  '06484~AX': { R: 47, G: 143, B: 147 },
  '06484~AY': { R: 81, G: 172, B: 219 },
  '06484~AZ': { R: 60, G: 95, B: 158 },
  '06484~B': { R: 75, G: 71, B: 120 },
  '06484~BA': { R: 50, G: 112, B: 153 },
  '06484~BB': { R: 57, G: 65, B: 90 },
  '06484~BC': { R: 48, G: 74, B: 109 },
  '06484~BD': { R: 63, G: 136, B: 187 },
  '06484~BE': { R: 9, G: 95, B: 147 },
  '06484~BF': { R: 77, G: 109, B: 144 },
  '06484~BG': { R: 61, G: 70, B: 97 },
  '06484~BH': { R: 65, G: 67, B: 101 },
  '06484~BJ': { R: 35, G: 87, B: 142 },
  '06484~BK': { R: 87, G: 167, B: 194 },
  '06484~BL': { R: 22, G: 170, B: 193 },
  '06484~BM': { R: 53, G: 68, B: 104 },
  '06484~BN': { R: 8, G: 145, B: 183 },
  '06484~BP': { R: 59, G: 69, B: 97 },
  '06484~BQ': { R: 0, G: 126, B: 148 },
  '06484~BR': { R: 55, G: 134, B: 185 },
  '06484~BS': { R: 26, G: 91, B: 141 },
  '06484~BT': { R: 108, G: 150, B: 176 },
  '06484~BU': { R: 96, G: 133, B: 182 },
  '06484~BV': { R: 56, G: 65, B: 91 },
  '06484~BX': { R: 112, G: 186, B: 220 },
  '06484~BY': { R: 50, G: 74, B: 119 },
  '06484~BZ': { R: 56, G: 71, B: 120 },
  '06484~C': { R: 59, G: 61, B: 69 },
  '06484~CA': { R: 44, G: 95, B: 132 },
  '06484~CB': { R: 93, G: 175, B: 211 },
  '06484~CC': { R: 39, G: 100, B: 140 },
  '06484~CD': { R: 59, G: 64, B: 75 },
  '06484~CE': { R: 88, G: 176, B: 221 },
  '06484~CF': { R: 60, G: 68, B: 88 },
  '06484~CG': { R: 29, G: 104, B: 144 },
  '06484~CH': { R: 62, G: 97, B: 163 },
  '06484~CJ': { R: 57, G: 66, B: 80 },
  '06484~CK': { R: 22, G: 104, B: 148 },
  '06484~CL': { R: 95, G: 117, B: 130 },
  '06484~CM': { R: 0, G: 140, B: 186 },
  '06484~CN': { R: 86, G: 112, B: 123 },
  '06484~CP': { R: 0, G: 136, B: 194 },
  '06484~CQ': { R: 8, G: 99, B: 153 },
  '06484~CR': { R: 47, G: 75, B: 123 },
  '06484~CS': { R: 43, G: 140, B: 147 },
  '06484~CT': { R: 70, G: 87, B: 150 },
  '06484~CU': { R: 63, G: 100, B: 169 },
  '06484~CV': { R: 53, G: 69, B: 92 },
  '06484~CX': { R: 54, G: 89, B: 124 },
  '06484~CY': { R: 60, G: 92, B: 159 },
  '06484~CZ': { R: 160, G: 193, B: 206 },
  '06484~D': { R: 0, G: 113, B: 172 },
  '06484~DA': { R: 59, G: 61, B: 68 },
  '06484~DB': { R: 0, G: 106, B: 164 },
  '06484~DC': { R: 56, G: 67, B: 77 },
  '06484~DD': { R: 43, G: 80, B: 104 },
  '06484~DE': { R: 56, G: 86, B: 110 },
  '06484~DF': { R: 18, G: 148, B: 199 },
  '06484~DG': { R: 2, G: 98, B: 151 },
  '06484~DH': { R: 132, G: 169, B: 197 },
  '06484~DJ': { R: 73, G: 77, B: 84 },
  '06484~DK': { R: 122, G: 155, B: 199 },
  '06484~DL': { R: 57, G: 76, B: 124 },
  '06484~DM': { R: 0, G: 143, B: 155 },
  '06484~DQ': { R: 5, G: 102, B: 148 },
  '06484~DS': { R: 0, G: 119, B: 161 },
  '06484~DU': { R: 55, G: 69, B: 111 },
  '06484~DV': { R: 55, G: 80, B: 131 },
  '06484~DX': { R: 69, G: 90, B: 130 },
  '06484~EA': { R: 0, G: 124, B: 169 },
  '06484~EB': { R: 16, G: 98, B: 143 },
  '06484~EC': { R: 32, G: 47, B: 69 },
  '06484~ED': { R: 0, G: 116, B: 182 },
  '06484~EF': { R: 0, G: 42, B: 91 },
  '06484~EG': { R: 0, G: 175, B: 205 },
  '06484~EJ': { R: 61, G: 71, B: 119 },
  '06484~EL': { R: 48, G: 79, B: 118 },
  '06484~EM': { R: 0, G: 152, B: 198 },
  '06484~EN': { R: 0, G: 146, B: 188 },
  '06484~EP': { R: 0, G: 149, B: 170 },
  '06484~EQ': { R: 0, G: 78, B: 104 },
  '06484~ER': { R: 0, G: 93, B: 130 },
  '06484~ES': { R: 0, G: 195, B: 221 },
  '06484~ET': { R: 0, G: 80, B: 100 },
  '06484~EU': { R: 0, G: 97, B: 150 },
  '06484~EV': { R: 105, G: 189, B: 214 },
  '06484~EX': { R: 65, G: 82, B: 136 },
  '06484~EY': { R: 93, G: 108, B: 132 },
  '06485~A': { R: 70, G: 68, B: 65 },
  '06485~AB': { R: 90, G: 69, B: 62 },
  '06485~AD': { R: 111, G: 82, B: 65 },
  '06485~AE': { R: 70, G: 45, B: 43 },
  '06485~AF': { R: 146, G: 88, B: 73 },
  '06486~A': { R: 16, G: 107, B: 79 },
  '06486~AA': { R: 58, G: 75, B: 71 },
  '06486~AB': { R: 38, G: 91, B: 77 },
  '06486~AC': { R: 45, G: 137, B: 97 },
  '06486~AD': { R: 73, G: 125, B: 108 },
  '06486~AE': { R: 65, G: 106, B: 69 },
  '06486~AF': { R: 81, G: 119, B: 73 },
  '06486~AG': { R: 0, G: 120, B: 81 },
  '06486~AH': { R: 97, G: 119, B: 103 },
  '06486~AJ': { R: 27, G: 114, B: 93 },
  '06486~AK': { R: 41, G: 100, B: 73 },
  '06486~AL': { R: 55, G: 84, B: 66 },
  '06486~AM': { R: 138, G: 163, B: 130 },
  '06486~AN': { R: 83, G: 149, B: 67 },
  '06486~AP': { R: 0, G: 129, B: 71 },
  '06486~AQ': { R: 66, G: 91, B: 67 },
  '06486~AR': { R: 64, G: 67, B: 64 },
  '06486~AS': { R: 40, G: 96, B: 93 },
  '06486~AT': { R: 60, G: 124, B: 69 },
  '06486~AU': { R: 50, G: 93, B: 75 },
  '06486~AV': { R: 71, G: 123, B: 84 },
  '06486~AX': { R: 59, G: 80, B: 66 },
  '06486~AY': { R: 54, G: 86, B: 86 },
  '06486~AZ': { R: 11, G: 103, B: 93 },
  '06486~B': { R: 65, G: 116, B: 74 },
  '06486~BA': { R: 113, G: 135, B: 103 },
  '06486~BB': { R: 85, G: 162, B: 75 },
  '06486~BC': { R: 63, G: 73, B: 66 },
  '06486~BD': { R: 90, G: 138, B: 83 },
  '06486~BE': { R: 40, G: 146, B: 88 },
  '06486~BF': { R: 79, G: 82, B: 75 },
  '06486~BG': { R: 62, G: 73, B: 66 },
  '06486~BH': { R: 181, G: 211, B: 178 },
  '06486~BJ': { R: 68, G: 164, B: 160 },
  '06486~BK': { R: 64, G: 117, B: 75 },
  '06486~BL': { R: 40, G: 136, B: 95 },
  '06486~BM': { R: 124, G: 175, B: 175 },
  '06486~BN': { R: 96, G: 139, B: 62 },
  '06486~BP': { R: 48, G: 96, B: 70 },
  '06486~BR': { R: 66, G: 91, B: 71 },
  '06486~BS': { R: 58, G: 75, B: 65 },
  '06486~BT': { R: 7, G: 103, B: 93 },
  '06486~BU': { R: 60, G: 98, B: 97 },
  '06486~BV': { R: 88, G: 109, B: 81 },
  '06486~BX': { R: 85, G: 82, B: 70 },
  '06486~BY': { R: 86, G: 163, B: 76 },
  '06486~BZ': { R: 182, G: 195, B: 59 },
  '06486~CA': { R: 59, G: 73, B: 67 },
  '06486~CB': { R: 53, G: 83, B: 73 },
  '06486~CC': { R: 49, G: 98, B: 107 },
  '06486~CE': { R: 61, G: 80, B: 69 },
  '06486~CF': { R: 1, G: 116, B: 80 },
  '06486~CG': { R: 41, G: 99, B: 99 },
  '06486~CK': { R: 62, G: 150, B: 70 },
  '06486~CL': { R: 151, G: 192, B: 63 },
  '06486~CN': { R: 193, G: 213, B: 84 },
  '06486~CQ': { R: 76, G: 87, B: 74 },
  '06486~CR': { R: 0, G: 121, B: 80 },
  '06486~CS': { R: 83, G: 88, B: 62 },
  '06486~CT': { R: 115, G: 178, B: 73 },
  '06486~CU': { R: 100, G: 156, B: 55 },
  '06486~CY': { R: 134, G: 178, B: 79 },
  '06486~CZ': { R: 59, G: 80, B: 70 },
  '06486~DB': { R: 90, G: 140, B: 109 },
  '06486~DC': { R: 77, G: 80, B: 65 },
  '06486~DD': { R: 48, G: 97, B: 78 },
  '06486~DE': { R: 109, G: 145, B: 104 },
  '06486~DF': { R: 85, G: 80, B: 70 },
  '06486~DH': { R: 142, G: 203, B: 81 },
  '06486~DJ': { R: 126, G: 127, B: 101 },
  '06486~DL': { R: 75, G: 107, B: 100 },
  '06486~DM': { R: 34, G: 64, B: 56 },
  '06486~DN': { R: 50, G: 75, B: 70 },
  '06486~DS': { R: 91, G: 118, B: 91 },
  '06486~DT': { R: 133, G: 177, B: 41 },
  '06486~DU': { R: 182, G: 203, B: 68 },
  '06486~DV': { R: 0, G: 168, B: 81 },
  '06486~DX': { R: 0, G: 146, B: 121 },
  '06486~DY': { R: 0, G: 139, B: 122 },
  '06486~DZ': { R: 0, G: 177, B: 91 },
  '06486~E': { R: 209, G: 210, B: 99 },
  '06486~EA': { R: 133, G: 176, B: 41 },
  '06486~EB': { R: 0, G: 85, B: 95 },
  '06486~EC': { R: 37, G: 153, B: 139 },
  '06486~ED': { R: 0, G: 151, B: 134 },
  '06486~EE': { R: 66, G: 66, B: 59 },
  '06486~EF': { R: 52, G: 79, B: 72 },
  '06486~EG': { R: 81, G: 156, B: 82 },
  '06486~EH': { R: 122, G: 162, B: 36 },
  '06486~EQ': { R: 41, G: 126, B: 120 },
  '06486~ER': { R: 176, G: 190, B: 82 },
  '06486~ES': { R: 71, G: 74, B: 66 },
  '06486~ET': { R: 33, G: 150, B: 75 },
  '06487~A': { R: 179, G: 181, B: 174 },
  '06487~AA': { R: 69, G: 72, B: 74 },
  '06487~AB': { R: 154, G: 150, B: 149 },
  '06487~AC': { R: 85, G: 88, B: 94 },
  '06487~AD': { R: 198, G: 202, B: 198 },
  '06487~AE': { R: 156, G: 168, B: 179 },
  '06487~AF': { R: 184, G: 181, B: 166 },
  '06487~AG': { R: 150, G: 154, B: 154 },
  '06487~AH': { R: 130, G: 141, B: 148 },
  '06487~AJ': { R: 148, G: 156, B: 162 },
  '06487~AK': { R: 118, G: 122, B: 119 },
  '06487~AL': { R: 202, G: 202, B: 202 },
  '06487~AM': { R: 125, G: 125, B: 112 },
  '06487~AN': { R: 102, G: 114, B: 119 },
  '06487~AP': { R: 107, G: 109, B: 110 },
  '06487~AQ': { R: 165, G: 173, B: 176 },
  '06487~AS': { R: 159, G: 165, B: 169 },
  '06487~AU': { R: 150, G: 149, B: 140 },
  '06487~AX': { R: 92, G: 92, B: 88 },
  '06487~AY': { R: 79, G: 89, B: 98 },
  '06487~AZ': { R: 156, G: 156, B: 158 },
  '06487~B': { R: 150, G: 143, B: 119 },
  '06487~BA': { R: 145, G: 156, B: 148 },
  '06487~BB': { R: 75, G: 80, B: 83 },
  '06487~BC': { R: 151, G: 152, B: 142 },
  '06487~BD': { R: 91, G: 95, B: 95 },
  '06487~BE': { R: 109, G: 114, B: 122 },
  '06487~BG': { R: 146, G: 152, B: 149 },
  '06487~BH': { R: 115, G: 115, B: 115 },
  '06487~BK': { R: 147, G: 147, B: 148 },
  '06487~BL': { R: 217, G: 217, B: 214 },
  '06488~A': { R: 189, G: 100, B: 59 },
  '06488~AA': { R: 208, G: 77, B: 55 },
  '06488~AB': { R: 197, G: 91, B: 61 },
  '06488~AC': { R: 223, G: 94, B: 75 },
  '06488~AD': { R: 212, G: 82, B: 61 },
  '06488~AE': { R: 217, G: 89, B: 55 },
  '06488~AF': { R: 200, G: 120, B: 59 },
  '06488~AG': { R: 200, G: 76, B: 60 },
  '06488~AH': { R: 234, G: 102, B: 54 },
  '06488~AJ': { R: 233, G: 127, B: 57 },
  '06488~AK': { R: 232, G: 125, B: 55 },
  '06488~AL': { R: 221, G: 127, B: 47 },
  '06488~AM': { R: 236, G: 120, B: 60 },
  '06488~AN': { R: 204, G: 75, B: 53 },
  '06488~AP': { R: 228, G: 104, B: 56 },
  '06488~AQ': { R: 228, G: 99, B: 54 },
  '06488~AR': { R: 200, G: 75, B: 60 },
  '06488~AS': { R: 192, G: 74, B: 55 },
  '06488~AT': { R: 181, G: 78, B: 58 },
  '06488~AU': { R: 223, G: 125, B: 46 },
  '06488~AV': { R: 215, G: 96, B: 68 },
  '06488~AX': { R: 233, G: 128, B: 59 },
  '06488~AY': { R: 175, G: 88, B: 70 },
  '06488~AZ': { R: 255, G: 128, B: 0 },
  '06489~A': { R: 89, G: 62, B: 78 },
  '06489~AA': { R: 142, G: 103, B: 138 },
  '06489~AB': { R: 117, G: 59, B: 78 },
  '06489~AC': { R: 154, G: 70, B: 122 },
  '06489~AD': { R: 182, G: 79, B: 130 },
  '06489~AE': { R: 103, G: 79, B: 127 },
  '06489~AF': { R: 104, G: 80, B: 114 },
  '06489~AH': { R: 34, G: 9, B: 18 },
  '06489~AJ': { R: 171, G: 67, B: 118 },
  '06490~A': { R: 167, G: 52, B: 61 },
  '06490~AA': { R: 137, G: 53, B: 62 },
  '06490~AB': { R: 104, G: 57, B: 62 },
  '06490~AC': { R: 146, G: 56, B: 63 },
  '06490~AD': { R: 181, G: 56, B: 62 },
  '06490~AE': { R: 103, G: 60, B: 65 },
  '06490~AF': { R: 166, G: 54, B: 59 },
  '06490~AG': { R: 121, G: 58, B: 63 },
  '06490~AH': { R: 185, G: 52, B: 58 },
  '06490~AJ': { R: 195, G: 68, B: 60 },
  '06490~AK': { R: 96, G: 58, B: 63 },
  '06490~AL': { R: 137, G: 56, B: 63 },
  '06490~AM': { R: 167, G: 60, B: 63 },
  '06490~AN': { R: 174, G: 65, B: 63 },
  '06490~AP': { R: 194, G: 60, B: 58 },
  '06490~AQ': { R: 167, G: 59, B: 61 },
  '06490~AR': { R: 169, G: 73, B: 61 },
  '06490~AS': { R: 194, G: 59, B: 58 },
  '06490~AT': { R: 125, G: 63, B: 64 },
  '06490~AU': { R: 171, G: 54, B: 60 },
  '06490~AV': { R: 224, G: 95, B: 54 },
  '06490~AX': { R: 133, G: 60, B: 62 },
  '06490~AY': { R: 173, G: 58, B: 60 },
  '06490~AZ': { R: 164, G: 57, B: 61 },
  '06490~B': { R: 128, G: 62, B: 63 },
  '06490~BA': { R: 158, G: 58, B: 62 },
  '06490~BB': { R: 179, G: 68, B: 65 },
  '06490~BC': { R: 152, G: 59, B: 99 },
  '06490~BE': { R: 190, G: 57, B: 57 },
  '06490~BF': { R: 151, G: 56, B: 62 },
  '06490~BG': { R: 167, G: 53, B: 61 },
  '06490~BH': { R: 201, G: 64, B: 63 },
  '06490~BJ': { R: 138, G: 59, B: 64 },
  '06490~BK': { R: 168, G: 59, B: 62 },
  '06490~BM': { R: 178, G: 54, B: 61 },
  '06490~BN': { R: 161, G: 56, B: 60 },
  '06490~BP': { R: 165, G: 53, B: 59 },
  '06490~BQ': { R: 176, G: 52, B: 57 },
  '06490~BR': { R: 188, G: 54, B: 57 },
  '06490~BS': { R: 163, G: 60, B: 63 },
  '06490~BU': { R: 188, G: 63, B: 60 },
  '06490~BV': { R: 118, G: 63, B: 99 },
  '06490~BY': { R: 173, G: 52, B: 57 },
  '06490~BZ': { R: 139, G: 56, B: 61 },
  '06490~CA': { R: 187, G: 113, B: 85 },
  '06490~CB': { R: 184, G: 61, B: 82 },
  '06490~CD': { R: 162, G: 58, B: 63 },
  '06490~CE': { R: 191, G: 52, B: 58 },
  '06490~CF': { R: 201, G: 60, B: 62 },
  '06490~CG': { R: 177, G: 77, B: 67 },
  '06490~CH': { R: 227, G: 0, B: 46 },
  '06490~CJ': { R: 187, G: 62, B: 45 },
  '06490~CL': { R: 255, G: 0, B: 255 },
  '06491~A': { R: 233, G: 231, B: 223 },
  '06491~AA': { R: 230, G: 223, B: 204 },
  '06491~AB': { R: 240, G: 242, B: 238 },
  '06491~AC': { R: 220, G: 224, B: 227 },
  '06491~AD': { R: 231, G: 233, B: 231 },
  '06491~AE': { R: 241, G: 239, B: 229 },
  '06491~AF': { R: 237, G: 230, B: 217 },
  '06491~AG': { R: 238, G: 239, B: 238 },
  '06491~AH': { R: 218, G: 218, B: 209 },
  '06491~AJ': { R: 244, G: 245, B: 238 },
  '06491~AK': { R: 238, G: 239, B: 230 },
  '06491~AL': { R: 237, G: 229, B: 206 },
  '06491~AM': { R: 242, G: 244, B: 241 },
  '06491~AN': { R: 238, G: 238, B: 238 },
  '06491~AP': { R: 238, G: 240, B: 237 },
  '06491~AS': { R: 217, G: 229, B: 238 },
  '06491~AT': { R: 240, G: 239, B: 237 },
  '06491~AU': { R: 205, G: 226, B: 232 },
  '06491~AV': { R: 200, G: 223, B: 234 },
  '06492~A': { R: 224, G: 176, B: 82 },
  '06492~AA': { R: 235, G: 220, B: 86 },
  '06492~AB': { R: 230, G: 169, B: 64 },
  '06492~AC': { R: 242, G: 221, B: 126 },
  '06492~AD': { R: 239, G: 195, B: 1 },
  '06492~AE': { R: 240, G: 212, B: 125 },
  '06492~AF': { R: 233, G: 156, B: 29 },
  '06492~AG': { R: 247, G: 214, B: 76 },
  '06492~AH': { R: 240, G: 206, B: 23 },
  '06492~AJ': { R: 246, G: 226, B: 61 },
  '06492~AK': { R: 224, G: 175, B: 52 },
  '06492~AL': { R: 247, G: 173, B: 34 },
  '06492~AM': { R: 237, G: 185, B: 29 },
  '06492~AN': { R: 240, G: 173, B: 12 },
  '06492~AP': { R: 230, G: 173, B: 39 },
  '06492~AQ': { R: 244, G: 181, B: 8 },
  '06492~AR': { R: 230, G: 161, B: 42 },
  '06492~AS': { R: 255, G: 167, B: 8 },
  '06492~AT': { R: 243, G: 207, B: 41 },
  '06492~AU': { R: 245, G: 157, B: 27 },
  '06492~AV': { R: 241, G: 179, B: 31 },
  '06492~AX': { R: 226, G: 175, B: 54 },
  '06492~AY': { R: 237, G: 193, B: 16 },
  '06492~AZ': { R: 244, G: 170, B: 32 },
  '06492~B': { R: 240, G: 166, B: 21 },
  '06492~BA': { R: 169, G: 142, B: 57 },
  '06492~BB': { R: 255, G: 213, B: 45 },
  '06492~BC': { R: 188, G: 155, B: 91 },
  '06492~BD': { R: 225, G: 149, B: 43 },
  '06492~BE': { R: 216, G: 211, B: 119 },
  '06492~BF': { R: 248, G: 180, B: 26 },
  '06492~BG': { R: 255, G: 210, B: 81 },
  '06492~BH': { R: 246, G: 181, B: 30 },
  '06492~BJ': { R: 211, G: 180, B: 122 },
  '06492~BK': { R: 193, G: 132, B: 62 },
  '06492~BL': { R: 245, G: 200, B: 41 },
  '06492~BM': { R: 232, G: 159, B: 55 },
  '06492~BN': { R: 255, G: 178, B: 41 },
  '06492~BP': { R: 236, G: 168, B: 35 },
  '06492~BQ': { R: 226, G: 165, B: 58 },
  '06492~BT': { R: 238, G: 197, B: 57 },
  '06492~BU': { R: 224, G: 198, B: 102 },
  '06492~BV': { R: 234, G: 141, B: 0 },
  '06492~BX': { R: 216, G: 139, B: 70 },
  '06492~BY': { R: 255, G: 200, B: 0 },
  '06676~AE': { R: 146, G: 88, B: 73 },
  '06773~A': { R: 209, G: 183, B: 139 },
  '06773~AA': { R: 231, G: 217, B: 187 },
  '06773~AB': { R: 207, G: 187, B: 137 },
  '06773~AC': { R: 250, G: 237, B: 188 },
  '06773~B': { R: 222, G: 203, B: 158 },
  '06773~C': { R: 230, G: 198, B: 130 },
  '06773~D': { R: 165, G: 156, B: 129 },
  '06773~E': { R: 217, G: 191, B: 161 },
  '06774~A': { R: 58, G: 59, B: 60 },
  '06774~AA': { R: 60, G: 61, B: 63 },
  '06774~AC': { R: 61, G: 61, B: 62 },
  '06774~AD': { R: 62, G: 64, B: 65 },
  '06775~A': { R: 61, G: 71, B: 114 },
  '06775~AA': { R: 48, G: 87, B: 145 },
  '06775~AB': { R: 62, G: 69, B: 88 },
  '06775~AC': { R: 60, G: 65, B: 72 },
  '06775~AD': { R: 65, G: 84, B: 105 },
  '06775~AE': { R: 16, G: 96, B: 152 },
  '06775~AF': { R: 57, G: 74, B: 130 },
  '06775~AG': { R: 59, G: 72, B: 100 },
  '06775~AH': { R: 90, G: 130, B: 181 },
  '06775~AJ': { R: 60, G: 100, B: 127 },
  '06775~AK': { R: 38, G: 124, B: 183 },
  '06775~AL': { R: 0, G: 124, B: 129 },
  '06775~AM': { R: 70, G: 91, B: 122 },
  '06775~AN': { R: 42, G: 78, B: 117 },
  '06775~AP': { R: 54, G: 70, B: 91 },
  '06775~AR': { R: 43, G: 99, B: 137 },
  '06775~AS': { R: 21, G: 107, B: 156 },
  '06775~AT': { R: 57, G: 70, B: 117 },
  '06775~AU': { R: 41, G: 86, B: 133 },
  '06775~AV': { R: 29, G: 104, B: 144 },
  '06775~AX': { R: 47, G: 143, B: 147 },
  '06775~AY': { R: 81, G: 172, B: 219 },
  '06775~AZ': { R: 60, G: 95, B: 158 },
  '06775~B': { R: 60, G: 92, B: 159 },
  '06775~BA': { R: 112, G: 186, B: 220 },
  '06775~BB': { R: 57, G: 65, B: 90 },
  '06775~BC': { R: 48, G: 74, B: 109 },
  '06775~BD': { R: 63, G: 136, B: 187 },
  '06775~BE': { R: 9, G: 95, B: 147 },
  '06775~BF': { R: 77, G: 109, B: 144 },
  '06775~BG': { R: 61, G: 70, B: 97 },
  '06775~BH': { R: 65, G: 67, B: 101 },
  '06775~BJ': { R: 35, G: 87, B: 142 },
  '06775~BK': { R: 87, G: 167, B: 194 },
  '06775~BL': { R: 44, G: 95, B: 132 },
  '06775~BM': { R: 88, G: 176, B: 221 },
  '06775~BN': { R: 8, G: 145, B: 183 },
  '06775~BP': { R: 59, G: 69, B: 97 },
  '06775~BQ': { R: 0, G: 126, B: 148 },
  '06775~BR': { R: 55, G: 134, B: 185 },
  '06775~BS': { R: 26, G: 91, B: 141 },
  '06775~BT': { R: 108, G: 150, B: 176 },
  '06775~BU': { R: 96, G: 133, B: 182 },
  '06775~BV': { R: 56, G: 65, B: 91 },
  '06775~BX': { R: 59, G: 83, B: 102 },
  '06775~BY': { R: 50, G: 74, B: 119 },
  '06775~BZ': { R: 56, G: 71, B: 120 },
  '06775~C': { R: 59, G: 61, B: 69 },
  '06775~CA': { R: 62, G: 97, B: 163 },
  '06775~CB': { R: 22, G: 104, B: 148 },
  '06775~CC': { R: 39, G: 100, B: 140 },
  '06775~CD': { R: 59, G: 64, B: 75 },
  '06775~CE': { R: 0, G: 140, B: 186 },
  '06775~CF': { R: 60, G: 68, B: 88 },
  '06775~CG': { R: 56, G: 73, B: 89 },
  '06775~CH': { R: 75, G: 71, B: 120 },
  '06775~CJ': { R: 57, G: 66, B: 80 },
  '06775~CK': { R: 50, G: 112, B: 153 },
  '06775~CL': { R: 95, G: 117, B: 130 },
  '06775~CM': { R: 54, G: 89, B: 124 },
  '06775~CN': { R: 86, G: 112, B: 123 },
  '06775~CP': { R: 0, G: 136, B: 194 },
  '06775~CQ': { R: 8, G: 99, B: 153 },
  '06775~CR': { R: 47, G: 75, B: 123 },
  '06775~CS': { R: 160, G: 193, B: 206 },
  '06775~CT': { R: 70, G: 87, B: 150 },
  '06775~CU': { R: 63, G: 100, B: 169 },
  '06775~CV': { R: 53, G: 69, B: 92 },
  '06775~CX': { R: 43, G: 80, B: 104 },
  '06775~CY': { R: 18, G: 148, B: 199 },
  '06775~CZ': { R: 132, G: 169, B: 197 },
  '06775~D': { R: 0, G: 113, B: 172 },
  '06775~DA': { R: 59, G: 61, B: 68 },
  '06775~DB': { R: 0, G: 106, B: 164 },
  '06775~DC': { R: 22, G: 170, B: 193 },
  '06775~DD': { R: 0, G: 116, B: 182 },
  '06775~DE': { R: 56, G: 86, B: 110 },
  '06775~DF': { R: 55, G: 69, B: 111 },
  '06775~DG': { R: 2, G: 98, B: 151 },
  '06775~DH': { R: 57, G: 76, B: 124 },
  '06775~DJ': { R: 73, G: 77, B: 84 },
  '06775~DK': { R: 69, G: 90, B: 130 },
  '06775~DL': { R: 16, G: 98, B: 143 },
  '06775~DM': { R: 0, G: 143, B: 155 },
  '06775~DN': { R: 53, G: 68, B: 104 },
  '06775~DP': { R: 5, G: 102, B: 148 },
  '06775~DR': { R: 61, G: 71, B: 119 },
  '06775~DS': { R: 0, G: 119, B: 161 },
  '06775~DT': { R: 43, G: 140, B: 147 },
  '06775~DU': { R: 0, G: 146, B: 188 },
  '06775~DV': { R: 55, G: 80, B: 131 },
  '06775~DX': { R: 0, G: 149, B: 170 },
  '06775~DY': { R: 0, G: 78, B: 104 },
  '06775~DZ': { R: 0, G: 93, B: 130 },
  '06775~E': { R: 32, G: 47, B: 69 },
  '06775~EA': { R: 0, G: 124, B: 169 },
  '06775~EB': { R: 0, G: 80, B: 100 },
  '06775~EC': { R: 0, G: 175, B: 205 },
  '06775~ED': { R: 0, G: 97, B: 150 },
  '06775~EE': { R: 65, G: 82, B: 136 },
  '06775~EF': { R: 93, G: 108, B: 132 },
  '06775~EG': { R: 93, G: 175, B: 211 },
  '06775~EH': { R: 56, G: 67, B: 77 },
  '06775~EJ': { R: 122, G: 155, B: 199 },
  '06775~EL': { R: 48, G: 79, B: 118 },
  '06775~ES': { R: 0, G: 195, B: 221 },
  '06775~EV': { R: 105, G: 189, B: 214 },
  '06775~EX': { R: 73, G: 160, B: 219 },
  '06776~A': { R: 70, G: 68, B: 65 },
  '06776~AB': { R: 90, G: 69, B: 62 },
  '06776~AC': { R: 70, G: 45, B: 43 },
  '06776~AD': { R: 111, G: 82, B: 65 },
  '06777~A': { R: 0, G: 85, B: 95 },
  '06777~AA': { R: 96, G: 139, B: 62 },
  '06777~AB': { R: 122, G: 162, B: 36 },
  '06777~AC': { R: 45, G: 137, B: 97 },
  '06777~AD': { R: 73, G: 125, B: 108 },
  '06777~AE': { R: 65, G: 106, B: 69 },
  '06777~AF': { R: 81, G: 119, B: 73 },
  '06777~AG': { R: 0, G: 120, B: 81 },
  '06777~AJ': { R: 27, G: 114, B: 93 },
  '06777~AK': { R: 83, G: 149, B: 67 },
  '06777~AL': { R: 55, G: 84, B: 66 },
  '06777~AM': { R: 138, G: 163, B: 130 },
  '06777~AN': { R: 97, G: 119, B: 103 },
  '06777~AP': { R: 0, G: 129, B: 71 },
  '06777~AQ': { R: 66, G: 91, B: 67 },
  '06777~AR': { R: 66, G: 91, B: 71 },
  '06777~AS': { R: 68, G: 164, B: 160 },
  '06777~AT': { R: 41, G: 100, B: 73 },
  '06777~AU': { R: 50, G: 93, B: 75 },
  '06777~AV': { R: 124, G: 175, B: 175 },
  '06777~AX': { R: 48, G: 96, B: 70 },
  '06777~AY': { R: 54, G: 86, B: 86 },
  '06777~AZ': { R: 11, G: 103, B: 93 },
  '06777~B': { R: 65, G: 116, B: 74 },
  '06777~BA': { R: 113, G: 135, B: 103 },
  '06777~BB': { R: 85, G: 162, B: 75 },
  '06777~BC': { R: 63, G: 73, B: 66 },
  '06777~BD': { R: 90, G: 138, B: 83 },
  '06777~BE': { R: 40, G: 146, B: 88 },
  '06777~BF': { R: 79, G: 82, B: 75 },
  '06777~BG': { R: 62, G: 73, B: 66 },
  '06777~BH': { R: 181, G: 211, B: 178 },
  '06777~BJ': { R: 142, G: 203, B: 81 },
  '06777~BK': { R: 64, G: 117, B: 75 },
  '06777~BL': { R: 40, G: 136, B: 95 },
  '06777~BM': { R: 85, G: 82, B: 70 },
  '06777~BN': { R: 182, G: 195, B: 59 },
  '06777~BP': { R: 64, G: 67, B: 64 },
  '06777~BQ': { R: 40, G: 96, B: 93 },
  '06777~BR': { R: 16, G: 107, B: 79 },
  '06777~BS': { R: 58, G: 75, B: 65 },
  '06777~BT': { R: 7, G: 103, B: 93 },
  '06777~BU': { R: 37, G: 153, B: 139 },
  '06777~BV': { R: 88, G: 109, B: 81 },
  '06777~BY': { R: 86, G: 163, B: 76 },
  '06777~BZ': { R: 60, G: 98, B: 97 },
  '06777~C': { R: 60, G: 124, B: 69 },
  '06777~CA': { R: 59, G: 73, B: 67 },
  '06777~CB': { R: 53, G: 83, B: 73 },
  '06777~CC': { R: 49, G: 98, B: 107 },
  '06777~CD': { R: 71, G: 123, B: 84 },
  '06777~CE': { R: 61, G: 80, B: 69 },
  '06777~CF': { R: 1, G: 116, B: 80 },
  '06777~CG': { R: 41, G: 99, B: 99 },
  '06777~CH': { R: 0, G: 151, B: 134 },
  '06777~CJ': { R: 59, G: 80, B: 66 },
  '06777~CK': { R: 62, G: 150, B: 70 },
  '06777~CL': { R: 58, G: 75, B: 71 },
  '06777~CM': { R: 52, G: 79, B: 72 },
  '06777~CN': { R: 193, G: 213, B: 84 },
  '06777~CP': { R: 151, G: 192, B: 63 },
  '06777~CQ': { R: 76, G: 87, B: 74 },
  '06777~CR': { R: 0, G: 121, B: 80 },
  '06777~CS': { R: 75, G: 107, B: 100 },
  '06777~CT': { R: 115, G: 178, B: 73 },
  '06777~CU': { R: 100, G: 156, B: 55 },
  '06777~CV': { R: 83, G: 88, B: 62 },
  '06777~CX': { R: 134, G: 178, B: 79 },
  '06777~CY': { R: 59, G: 80, B: 70 },
  '06777~CZ': { R: 81, G: 156, B: 82 },
  '06777~D': { R: 38, G: 91, B: 77 },
  '06777~DA': { R: 48, G: 97, B: 78 },
  '06777~DB': { R: 90, G: 140, B: 109 },
  '06777~DC': { R: 77, G: 80, B: 65 },
  '06777~DD': { R: 109, G: 145, B: 104 },
  '06777~DE': { R: 50, G: 75, B: 70 },
  '06777~DF': { R: 85, G: 80, B: 70 },
  '06777~DG': { R: 133, G: 177, B: 41 },
  '06777~DH': { R: 182, G: 203, B: 68 },
  '06777~DJ': { R: 126, G: 127, B: 101 },
  '06777~DL': { R: 0, G: 146, B: 121 },
  '06777~DM': { R: 34, G: 64, B: 56 },
  '06777~DN': { R: 0, G: 177, B: 91 },
  '06777~DP': { R: 209, G: 210, B: 99 },
  '06777~DV': { R: 0, G: 168, B: 81 },
  '06777~DY': { R: 0, G: 139, B: 122 },
  '06777~EG': { R: 76, G: 114, B: 67 },
  '06777~EQ': { R: 41, G: 126, B: 120 },
  '06777~ER': { R: 176, G: 190, B: 82 },
  '06778~A': { R: 179, G: 181, B: 174 },
  '06778~AA': { R: 69, G: 72, B: 74 },
  '06778~AB': { R: 165, G: 173, B: 176 },
  '06778~AC': { R: 85, G: 88, B: 94 },
  '06778~AD': { R: 198, G: 202, B: 198 },
  '06778~AE': { R: 156, G: 168, B: 179 },
  '06778~AF': { R: 184, G: 181, B: 166 },
  '06778~AG': { R: 150, G: 154, B: 154 },
  '06778~AH': { R: 130, G: 141, B: 148 },
  '06778~AJ': { R: 148, G: 156, B: 162 },
  '06778~AK': { R: 118, G: 122, B: 119 },
  '06778~AL': { R: 154, G: 150, B: 149 },
  '06778~AM': { R: 125, G: 125, B: 112 },
  '06778~AN': { R: 102, G: 114, B: 119 },
  '06778~AP': { R: 75, G: 77, B: 79 },
  '06778~AQ': { R: 79, G: 89, B: 98 },
  '06778~AR': { R: 151, G: 152, B: 142 },
  '06778~AS': { R: 159, G: 165, B: 169 },
  '06778~AT': { R: 202, G: 202, B: 202 },
  '06778~AU': { R: 150, G: 149, B: 140 },
  '06778~AV': { R: 146, G: 152, B: 149 },
  '06778~AX': { R: 92, G: 92, B: 88 },
  '06778~AY': { R: 115, G: 115, B: 115 },
  '06778~AZ': { R: 156, G: 156, B: 158 },
  '06778~B': { R: 150, G: 143, B: 119 },
  '06778~BA': { R: 145, G: 156, B: 148 },
  '06778~BB': { R: 75, G: 80, B: 83 },
  '06778~BC': { R: 147, G: 147, B: 148 },
  '06778~BD': { R: 91, G: 95, B: 95 },
  '06778~BE': { R: 109, G: 114, B: 122 },
  '06778~BF': { R: 107, G: 109, B: 110 },
  '06778~BG': { R: 217, G: 217, B: 214 },
  '06779~A': { R: 208, G: 77, B: 55 },
  '06779~AA': { R: 234, G: 102, B: 54 },
  '06779~AB': { R: 197, G: 91, B: 61 },
  '06779~AC': { R: 223, G: 94, B: 75 },
  '06779~AD': { R: 212, G: 82, B: 61 },
  '06779~AE': { R: 217, G: 89, B: 55 },
  '06779~AF': { R: 192, G: 74, B: 55 },
  '06779~AG': { R: 200, G: 76, B: 60 },
  '06779~AH': { R: 200, G: 120, B: 59 },
  '06779~AJ': { R: 233, G: 127, B: 57 },
  '06779~AK': { R: 232, G: 125, B: 55 },
  '06779~AL': { R: 221, G: 127, B: 47 },
  '06779~AM': { R: 236, G: 120, B: 60 },
  '06779~AN': { R: 204, G: 75, B: 53 },
  '06779~AP': { R: 228, G: 104, B: 56 },
  '06779~AQ': { R: 228, G: 99, B: 54 },
  '06779~AR': { R: 200, G: 75, B: 60 },
  '06779~AS': { R: 181, G: 78, B: 58 },
  '06779~AT': { R: 233, G: 128, B: 59 },
  '06779~AU': { R: 223, G: 125, B: 46 },
  '06779~AV': { R: 189, G: 100, B: 59 },
  '06779~AX': { R: 175, G: 88, B: 70 },
  '06780~A': { R: 89, G: 62, B: 78 },
  '06780~AA': { R: 142, G: 103, B: 138 },
  '06780~AB': { R: 117, G: 59, B: 78 },
  '06780~AC': { R: 154, G: 70, B: 122 },
  '06780~AD': { R: 182, G: 79, B: 130 },
  '06780~AE': { R: 103, G: 79, B: 127 },
  '06780~AF': { R: 171, G: 67, B: 118 },
  '06780~AG': { R: 104, G: 80, B: 114 },
  '06781~A': { R: 167, G: 52, B: 61 },
  '06781~AA': { R: 137, G: 53, B: 62 },
  '06781~AB': { R: 181, G: 56, B: 62 },
  '06781~AC': { R: 146, G: 56, B: 63 },
  '06781~AD': { R: 152, G: 59, B: 99 },
  '06781~AE': { R: 103, G: 60, B: 65 },
  '06781~AF': { R: 166, G: 54, B: 59 },
  '06781~AG': { R: 121, G: 58, B: 63 },
  '06781~AH': { R: 185, G: 52, B: 58 },
  '06781~AJ': { R: 195, G: 68, B: 60 },
  '06781~AL': { R: 137, G: 56, B: 63 },
  '06781~AM': { R: 167, G: 60, B: 63 },
  '06781~AN': { R: 174, G: 65, B: 63 },
  '06781~AP': { R: 194, G: 60, B: 58 },
  '06781~AQ': { R: 167, G: 59, B: 61 },
  '06781~AS': { R: 194, G: 59, B: 58 },
  '06781~AT': { R: 125, G: 63, B: 64 },
  '06781~AU': { R: 171, G: 54, B: 60 },
  '06781~AV': { R: 224, G: 95, B: 54 },
  '06781~AX': { R: 133, G: 60, B: 62 },
  '06781~AY': { R: 173, G: 58, B: 60 },
  '06781~AZ': { R: 164, G: 57, B: 61 },
  '06781~B': { R: 128, G: 62, B: 63 },
  '06781~BA': { R: 158, G: 58, B: 62 },
  '06781~BB': { R: 179, G: 68, B: 65 },
  '06781~BE': { R: 190, G: 57, B: 57 },
  '06781~BF': { R: 151, G: 56, B: 62 },
  '06781~BJ': { R: 138, G: 59, B: 64 },
  '06781~BK': { R: 168, G: 59, B: 62 },
  '06781~BM': { R: 178, G: 54, B: 61 },
  '06781~BN': { R: 161, G: 56, B: 60 },
  '06781~BP': { R: 165, G: 53, B: 59 },
  '06781~BQ': { R: 176, G: 52, B: 57 },
  '06781~BR': { R: 188, G: 54, B: 57 },
  '06781~BS': { R: 163, G: 60, B: 63 },
  '06781~BU': { R: 188, G: 63, B: 60 },
  '06781~BV': { R: 118, G: 63, B: 99 },
  '06781~BY': { R: 173, G: 52, B: 57 },
  '06781~BZ': { R: 139, G: 56, B: 61 },
  '06781~CA': { R: 96, G: 58, B: 63 },
  '06781~CB': { R: 184, G: 61, B: 82 },
  '06781~CC': { R: 201, G: 64, B: 63 },
  '06781~CD': { R: 167, G: 53, B: 61 },
  '06781~CE': { R: 191, G: 52, B: 58 },
  '06781~CF': { R: 187, G: 113, B: 85 },
  '06781~CG': { R: 177, G: 77, B: 67 },
  '06781~CH': { R: 162, G: 58, B: 63 },
  '06781~CK': { R: 201, G: 60, B: 62 },
  '06781~CL': { R: 104, G: 57, B: 62 },
  '06781~CM': { R: 114, G: 67, B: 67 },
  '06781~CN': { R: 169, G: 73, B: 61 },
  '06781~CP': { R: 227, G: 0, B: 46 },
  '06782~A': { R: 233, G: 231, B: 223 },
  '06782~AA': { R: 230, G: 223, B: 204 },
  '06782~AC': { R: 244, G: 245, B: 238 },
  '06782~AD': { R: 240, G: 242, B: 238 },
  '06782~AE': { R: 241, G: 239, B: 229 },
  '06782~AF': { R: 237, G: 229, B: 206 },
  '06782~AG': { R: 238, G: 239, B: 238 },
  '06782~AH': { R: 218, G: 218, B: 209 },
  '06782~AJ': { R: 220, G: 224, B: 227 },
  '06782~AK': { R: 238, G: 239, B: 230 },
  '06782~AL': { R: 231, G: 233, B: 231 },
  '06782~AM': { R: 242, G: 244, B: 241 },
  '06782~AN': { R: 238, G: 238, B: 238 },
  '06782~AP': { R: 238, G: 240, B: 237 },
  '06782~AQ': { R: 237, G: 230, B: 217 },
  '06782~AR': { R: 240, G: 239, B: 237 },
  '06782~AS': { R: 217, G: 229, B: 238 },
  '06782~AV': { R: 200, G: 223, B: 234 },
  '06783~AA': { R: 240, G: 173, B: 12 },
  '06783~AB': { R: 230, G: 169, B: 64 },
  '06783~AD': { R: 239, G: 195, B: 1 },
  '06783~AF': { R: 233, G: 156, B: 29 },
  '06783~AG': { R: 247, G: 214, B: 76 },
  '06783~AK': { R: 224, G: 175, B: 52 },
  '06783~AP': { R: 230, G: 173, B: 39 },
  '06783~AQ': { R: 244, G: 181, B: 8 },
  '06783~AR': { R: 230, G: 161, B: 42 },
  '06783~AS': { R: 255, G: 167, B: 8 },
  '06783~AX': { R: 226, G: 175, B: 54 },
  '06783~AY': { R: 237, G: 193, B: 16 },
  '06783~AZ': { R: 244, G: 170, B: 32 },
  '06783~B': { R: 242, G: 221, B: 126 },
  '06783~BA': { R: 235, G: 220, B: 86 },
  '06783~BB': { R: 243, G: 207, B: 41 },
  '06783~BC': { R: 245, G: 157, B: 27 },
  '06783~BD': { R: 241, G: 179, B: 31 },
  '06783~BE': { R: 240, G: 212, B: 125 },
  '06783~BF': { R: 240, G: 166, B: 21 },
  '06783~BG': { R: 169, G: 142, B: 57 },
  '06783~BH': { R: 246, G: 181, B: 30 },
  '06783~BJ': { R: 211, G: 180, B: 122 },
  '06783~BK': { R: 188, G: 155, B: 91 },
  '06783~BL': { R: 225, G: 149, B: 43 },
  '06783~BM': { R: 216, G: 211, B: 119 },
  '06783~BN': { R: 240, G: 206, B: 23 },
  '06783~BP': { R: 248, G: 180, B: 26 },
  '06783~BQ': { R: 255, G: 210, B: 81 },
  '06783~BR': { R: 246, G: 226, B: 61 },
  '06783~BS': { R: 193, G: 132, B: 62 },
  '06783~BT': { R: 245, G: 200, B: 41 },
  '06783~BU': { R: 232, G: 159, B: 55 },
  '06783~BV': { R: 255, G: 178, B: 41 },
  '06783~BX': { R: 236, G: 168, B: 35 },
  '06783~BY': { R: 247, G: 173, B: 34 },
  '06783~C': { R: 226, G: 165, B: 58 },
  '06783~CA': { R: 237, G: 185, B: 29 },
  '06783~CB': { R: 224, G: 176, B: 82 },
  '06783~CC': { R: 224, G: 198, B: 102 },
  '06783~CD': { R: 216, G: 139, B: 70 },
  '06783~CE': { R: 255, G: 213, B: 45 },
}
