import { css } from 'styled-components'

/**
 * Based on SDDS scrollbar css rules in:
 * node_modules/@scania/components/dist/collection/components/theme/theme.css
 */
export const CustomScrollbarMixin = css`
  scrollbar-width: thin; // Missing in the original code from SDDS.
  &::-webkit-scrollbar {
    width: 4px;
    background-color: inherit;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--tds-grey-300);
  }
  &::-webkit-scrollbar-button {
    height: 0;
    width: 0;
  }
`
