/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * If this property is null/undefined, then the operation was a
 * success.
 * @export
 * @interface ScaniaImportXmlResponseWrapperError
 */
export interface ScaniaImportXmlResponseWrapperError {
  /**
   *
   * @type {string}
   * @memberof ScaniaImportXmlResponseWrapperError
   */
  message: string
  /**
   *
   * @type {string}
   * @memberof ScaniaImportXmlResponseWrapperError
   */
  status: string
}

/**
 * Check if a given object implements the ScaniaImportXmlResponseWrapperError interface.
 */
export function instanceOfScaniaImportXmlResponseWrapperError(
  value: object,
): boolean {
  let isInstance = true
  isInstance = isInstance && 'message' in value
  isInstance = isInstance && 'status' in value

  return isInstance
}

export function ScaniaImportXmlResponseWrapperErrorFromJSON(
  json: any,
): ScaniaImportXmlResponseWrapperError {
  return ScaniaImportXmlResponseWrapperErrorFromJSONTyped(json, false)
}

export function ScaniaImportXmlResponseWrapperErrorFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ScaniaImportXmlResponseWrapperError {
  if (json === undefined || json === null) {
    return json
  }
  return {
    message: json['message'],
    status: json['status'],
  }
}

export function ScaniaImportXmlResponseWrapperErrorToJSON(
  value?: ScaniaImportXmlResponseWrapperError | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    message: value.message,
    status: value.status,
  }
}
