import styled from 'styled-components'
import { SvgBattery } from '../../components/SvgImports'
import useTexts from '../../utils/useTexts'

const BevButtonRoot = styled.div`
  align-items: center;
  border-radius: 2px;
  border-style: solid;
  border-width: thin;
  color: var(--tds-grey-958);
  display: flex;
  height: 36px;
  justify-content: center;
  padding-left: 12px;
  padding-right: 12px;
  position: relative;
  svg {
    height: 26px;
    margin-right: -6px;
    margin-top: -2px;
  }
`

export function BevButton(): JSX.Element {
  const t = useTexts()
  return (
    <BevButtonRoot>
      <span>{t('FM_ELECTRIC_TRUCK')}</span>
      <SvgBattery />
    </BevButtonRoot>
  )
}
