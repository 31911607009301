// Data attribute values for automated testing

export enum TestElementTypeId {
  AboutBackendVersion = 'ABOUT_BACKEND_VERSION',
  AboutContent = 'ABOUT_CONTENT',
  AboutContentImagesVersion = 'ABOUT_CONTENT_IMAGES_VERSION',
  AboutContentResourcesVersion = 'ABOUT_CONTENT_RESOURCES_VERSION',
  AccordionAbout = 'ACCORDION_ABOUT',
  AccordionTermsAndCondition = 'ACCORDION_TERMS_AND_CONDITIONS',
  AccordionUploadConfig = 'ACCORDION_UPLOAD_CONFIG',
  BuildModeMainImage = 'BUILD_MODE_MAIN_IMAGE',
  BuildModeMiniSummaryNonColorSection = 'BUILD_MODE_MINI_SUMMARY_NON_COLOR_SECTION',
  BuildModeMiniSummaryTitle = 'BUILD_MODE_MINI_SUMMARY_TITLE',
  ConfigurationMenuCurrentSelectionText = 'CONFIGURATION_MENU_CURRENT_SELECTION_TEXT',
  ConfigurationMenuSaveAsButton = 'CONFIGURATION_MENU_SAVE_AS_BUTTON',
  ConfigurationMenuSaveButton = 'CONFIGURATION_MENU_SAVE_BUTTON',
  ConfigurationMenuSaveImageButton = 'CONFIGURATION_MENU_SAVE_IMAGE_BUTTON',
  ConfigurationMenuSaveMenu = 'CONFIGURATION_MENU_SAVE_MENU',
  ConfigurationMenuSavePdfButton = 'CONFIGURATION_MENU_SAVE_PDF_BUTTON',
  ConfigurationMenuSaveQrButton = 'CONFIGURATION_MENU_SAVE_QR_BUTTON',
  ConfigurationMenuSaveXmlButton = 'CONFIGURATION_MENU_SAVE_XML_BUTTON',
  ConfigurationMenuShareButton = 'CONFIGURATION_MENU_SHARE_BUTTON',
  ConfigurationMenuShareLinkButton = 'CONFIGURATION_MENU_SHARE_LINK_BUTTON',
  ConfigurationMenuShareLinkCopy = 'CONFIGURATION_MENU_SHARE_LINK_COPY',
  ConsequenceOfChangeAcceptButton = 'CONSEQUENCE_OF_CHANGE_ACCEPT_BUTTON',
  CookieAcceptAllButton = 'COOKIE_ACCEPT_ALL_BUTTON',
  DescriptionXmlUpload = 'DESCRIPTION_XML_UPLOAD',
  FactoryModelChoiceButton = 'FACTORY_MODEL_CHOICE_BUTTON',
  FactoryModelControlPanelDropDown = 'FACTORY_MODEL_CONTROL_PANEL_DROPDOWN',
  FactoryModelInfoIcon = 'FACTORY_MODEL_INFO_ICON',
  FactoryModelTruckCardTitle = 'FACTORY_MODEL_TRUCK_CARD_TITLE',
  FactoryModelViewAllButton = 'FACTORY_MODEL_VIEW_ALL_BUTTON',
  FactoryModelViewAllNumberText = 'FACTORY_MODEL_VIEW_ALL_NUMBER_TEXT',
  GarageForgottenPasswordFormEmailAddressInput = 'GARAGE_FORGOTTEN_PASSWORD_FORM_EMAIL_ADDRESS_INPUT',
  GarageForgottenPasswordFormGotoLoginFormButton = 'GARAGE_FORGOTTEN_PASSWORD_FORM_GOTO_LOGIN_FORM_BUTTON',
  GarageForgottenPasswordFormHeader = 'GARAGE_FORGOTTEN_PASSWORD_FORM_HEADER',
  GarageForgottenPasswordFormSubmitButton = 'GARAGE_FORGOTTEN_PASSWORD_FORM_SUBMIT_BUTTON',
  GarageLoginFormEmailAddressInput = 'GARAGE_LOGIN_FORM_EMAIL_ADDRESS_INPUT',
  GarageLoginFormGotoForgottenPasswordFormLink = 'GARAGE_LOGIN_FORM_GOTO_FORGOTTEN_PASSWORD_FORM_LINK',
  GarageLoginFormGotoRegistrationFormButton = 'GARAGE_LOGIN_FORM_GOTO_REGISTRATION_FORM_BUTTON',
  GarageLoginFormHeader = 'GARAGE_LOGIN_FORM_HEADER',
  GarageLoginFormPasswordInput = 'GARAGE_LOGIN_FORM_PASSWORD_INPUT',
  GarageLoginFormSubmitButton = 'GARAGE_LOGIN_FORM_SUBMIT_BUTTON',
  GarageRegistrationFormAcceptTermsCheckbox = 'GARAGE_REGISTRATION_FORM_ACCEPT_TERMS_CHECKBOX',
  GarageRegistrationFormEmailAddressInput = 'GARAGE_REGISTRATION_FORM_EMAIL_ADDRESS_INPUT',
  GarageRegistrationFormHeader = 'GARAGE_REGISTRATION_FORM_HEADER',
  GarageRegistrationFormPasswordInput = 'GARAGE_REGISTRATION_FORM_PASSWORD_INPUT',
  GarageRegistrationFormPasswordRepeatInput = 'GARAGE_REGISTRATION_FORM_PASSWORD_REPEAT_INPUT',
  GarageRegistrationFormSubmitButton = 'GARAGE_REGISTRATION_FORM_SUBMIT_BUTTON',
  GarageViewAccountTab = 'GARAGE_VIEW_ACCOUNT_TAB',
  GarageViewAccountTabContainer = 'GARAGE_VIEW_ACCOUNT_TAB_CONTAINER',
  GarageViewAccountViewGotoDeleteAccountFormButton = 'GARAGE_VIEW_ACCOUNT_VIEW_GOTO_DELETE_ACCOUNT_FORM_BUTTON',
  GarageViewAccountViewDeleteAccountSubmitButton = 'GARAGE_VIEW_ACCOUNT_VIEW_DELETE_ACCOUNT_SUBMIT_BUTTON',
  GarageViewAccountViewSignOutButton = 'GARAGE_VIEW_ACCOUNT_VIEW_SIGN_OUT_BUTTON',
  GarageViewGarageTab = 'GARAGE_VIEW_GARAGE_TAB',
  GarageViewGarageTabContainter = 'GARAGE_VIEW_GARAGE_TAB_CONTAINER',
  GarageViewGarageTabLoadTruckButton = 'GARAGE_VIEW_GARAGE_TAB_LOAD_TRUCK_BUTTON',
  GarageViewGarageTabTruckCard = 'GARAGE_VIEW_GARAGE_TAB_TRUCK_CARD',
  GarageViewGarageTabTruckCardDetails = 'GARAGE_VIEW_GARAGE_TAB_TRUCK_CARD_DETAILS',
  GarageViewGarageTabTruckCardTitle = 'GARAGE_VIEW_GARAGE_TAB_TRUCK_CARD_TITLE',
  GuidedOfferingChoiceButton = 'GUIDED_OFFERING_CHOICE_BUTTON',
  GuidedOfferingInfoIcon = 'GUIDED_OFFERING_INFO_ICON',
  GuidedOfferingInfoReadMoreCloseIcon = 'GUIDED_OFFERING_INFO_READ_MORE_CLOSE_ICON',
  GuidedOfferingInfoReadMoreText = 'GUIDED_OFFERING_INFO_READ_MORE_TEXT',
  GuidedOfferingNavigationPrevious = 'GUIDED_OFFERING_NAVIGATION_PREVIOUS',
  GuidedOfferingNavigationQuickStart = 'GUIDED_OFFERING_NAVIGATION_QUICK_START',
  GuidedOfferingNavigationRestart = 'GUIDED_OFFERING_NAVIGATION_RESTART',
  GuidedOfferingNavigationSkip = 'GUIDED_OFFERING_NAVIGATION_SKIP',
  HeaderAccountIcon = 'HEADER_ACCOUNT_ICON',
  HeaderInfoIcon = 'HEADER_INFO_ICON',
  HeaderLogoType = 'HEADER_LOGOTYPE',
  HeaderLogoTypeIcon = 'HEADER_LOGOTYPE_ICON',
  HeaderMarketName = 'HEADER_MARKETNAME',
  LandingPageBackgroundImage = 'LANDING_PAGE_BACKGROUND_IMAGE',
  LandingPageCampaignFooter = 'LANDING_PAGE_CAMPAIGNFOOTER',
  LandingPageStartFactoryModelsButton = 'LANDING_PAGE_START_FACTORY_MODELS_BUTTON',
  LandingPageStartGuidedOfferingBevButton = 'LANDING_PAGE_START_GUIDED_OFFERING_BEV_BUTTON',
  LandingPageStartGuidedOfferingButton = 'LANDING_PAGE_START_GUIDED_OFFERING_BUTTON',
  LinkDisclaimer = 'LINK_DISCLAIMER',
  LinkLegal = 'LINK_LEGAL',
  ModalCloseButton = 'MODAL_CLOSE_BUTTON',
  ModalSaveAsGarageTruckNameInput = 'MODAL_SAVE_AS_GARAGE_TRUCK_NAME_INPUT',
  ModalSaveAsGarageSaveButton = 'MODAL_SAVE_AS_GARAGE_SAVE_BUTTON',
  ModalSaveGarageSaveButton = 'MODAL_SAVE_GARAGE_SAVE_BUTTON',
  ModalSaveImageDownloadButton = 'MODAL_SAVE_IMAGE_DOWNLOAD_BUTTON',
  ModalSaveImageJpgRadioButton = 'MODAL_SAVE_IMAGE_JPG_RADIO_BUTTON',
  ModalSaveImagePngRadioButton = 'MODAL_SAVE_IMAGE_PNG_RADIO_BUTTON',
  ModalSavePdfDownloadButton = 'MODAL_SAVE_PDF_DOWNLOAD_BUTTON',
  ModalSaveQrDownloadButton = 'MODAL_SAVE_QR_DOWNLOAD_BUTTON',
  ModalSaveQrPngRadioButton = 'MODAL_SAVE_QR_PNG_RADIO_BUTTON',
  ModalSaveQrSvgRadioButton = 'MODAL_SAVE_QR_SVG_RADIO_BUTTON',
  ModalSaveXmlDownloadButton = 'MODAL_SAVE_XML_DOWNLOAD_BUTTON',
  TextTermsAndCondition = 'TEXT_TERMS_AND_CONDITIONS',
  UploadConfigButton = 'UPLOAD_CONFIG_BUTTON',
  UploadConfigFileSelector = 'UPLOAD_CONFIG_FILE_SELECTOR',
  UploadConfigLoadButton = 'UPLOAD_CONFIG_LOAD_BUTTON',
}

export const TEST_GO_GROUP_ID_DONE_BUTTON = 'TEST_GO_GROUP_ID_DONE_BUTTON'
