import styled, { css } from 'styled-components'
import { SvgConfigurator } from '../../components/SvgImports'
import useTexts, { TextId } from '../../utils/useTexts'
import { EtelVariant, toEtelVariant } from '../../utils/EtelVariant'
import { ViewFilter } from './FactoryModels'
import { useCallback, useEffect, useRef, useState } from 'react'
import {
  ScaniaAdobeTrackingClickFmModelFilterEvent,
  pushAdobeEvent,
  ScaniaAdobeEventId,
  ScaniaAdobeTrackingPageName,
  ScaniaAdobeTrackingFlowName,
} from '../../utils/adobeAnalytics'
import { GoFmMode } from '../../api/goFmMode'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { useNavigate } from 'react-router-dom'
import { TestElementTypeId } from '../../types/TestAttributeId'
import {
  getUrlCidBevSemiliveParameters,
  UrlParamMode,
} from '../../utils/getUrlCidParameter'
import { getUrlParametersToString } from '../../utils/getUrlParametersToString'
import { BreakpointWidthPx } from '../../css/BreakpointWidthPx'
import {
  getFactoryModelsState,
  getMarketLanguageState,
  getMarketSettingsState,
} from '../../store/appSlice'
import {
  getSessionInitDataState,
  setPendingUserTrackingPageNavigation,
} from '../../store/sessionDataSlice'
import {
  TdsButton,
  TdsDropdown,
  TdsDropdownOption,
  TdsToggle,
} from '@scania/tegel-react'

const ControlBox = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 12px;
  flex-wrap: wrap;
  width: min(100%, 1200px);

  @media screen and (min-width: ${BreakpointWidthPx.Tablet}px) {
    justify-content: space-between;
  }

  @media screen and (max-width: 925px) {
    justify-content: center;
  }
`

/** Overrides some TDS styles */
const BuildYourOwnTruckButton = styled(TdsButton)`
  --svg-color: var(--tds-blue-800);
  color: var(--tds-blue-800);
  background-color: white !important;
  border-radius: 0.25rem;

  & > button {
    justify-content: flex-start !important;

    & > div {
      display: flex;
      align-items: center;

      & > svg {
        color: var(--svg-color);
        height: 32px;
        width: 32px;
      }
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: white !important;
      --svg-color: white;
    }
  }
`

const BevToggleButton = styled.div`
  background-color: white;
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
  height: 48px;
  align-items: center;
  border-radius: 0.25rem;
  border-top: none;
`

interface FilterControlsRootProps {
  $itemCount: number
}

const FilterControlsRoot = styled.div<FilterControlsRootProps>`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 8px;
  row-gap: 8px;
  width: 100%;
  ${({ $itemCount }) =>
    $itemCount < 4 &&
    css`
      margin-bottom: 28px;
    `};

  @media screen and (min-width: 769px) {
    grid-template-columns: repeat(${({ $itemCount }) => $itemCount}, 1fr);
  }
`

export const FILTER_CHOICE_ID_SHOW_ALL =
  'WILL_NEVER_BE_AN_ETEL_FAMILY_ID_PLEASE_SHOW_ALL'
export const FILTER_CHOICE_ID_INACTIVE =
  'WILL_NEVER_BE_AN_ETEL_FAMILY_ID_PLEASE_DEACTIVATE_FILTER'

const getActiveViewVariant = (
  viewFilter: ViewFilter | null,
  etelFamilyId: string,
): EtelVariant | null => {
  const etelExecution = (viewFilter || {})[etelFamilyId]
  if (etelExecution === undefined || etelExecution === null) {
    return null
  }
  const result: EtelVariant = {
    family: etelFamilyId,
    execution: etelExecution,
  }
  return result
}

export interface FactoryModelsFilterSelection {
  etelFamilyId: string

  /**
   * Sentinel values:
   *   FILTER_CHOICE_ID_INACTIVE
   *   FILTER_CHOICE_ID_SHOW_ALL
   */
  etelExecutionId: string
}

interface DropDownData {
  id: string
  selectedId: string
  text: string
  choices: DropDownChoice[]
}

interface DropDownChoice {
  id: string
  text: string
}

interface DropDownEventDetails {
  label?: string
  value?: string
}

export interface FactoryModelsControlPanelProps {
  allowedFilterFamilyIds: string[]
  filterFamilies: Record<string, string[]>
  getFactoryModelText: (id: string) => string
  handleBevToggle: (checked: boolean) => void
  handleFilterSelection: (selection: FactoryModelsFilterSelection) => void
  showBevButton: boolean
  viewFilter: ViewFilter | null
}

export const FactoryModelsControlPanel = ({
  allowedFilterFamilyIds,
  filterFamilies,
  getFactoryModelText,
  handleBevToggle,
  handleFilterSelection,
  showBevButton,
  viewFilter,
}: FactoryModelsControlPanelProps): JSX.Element => {
  const t = useTexts()
  const marketLanguage = useAppSelector(getMarketLanguageState)
  const marketSettings = useAppSelector(getMarketSettingsState)
  const { mode: factoryModelsMode } = useAppSelector(getFactoryModelsState)
  const sessionInitData = useAppSelector(getSessionInitDataState)
  const [dropDownState, setDropDownSate] = useState<DropDownData[]>([])
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  var refDropdown1 = useRef<HTMLTdsDropdownElement>(null)
  var refDropdown2 = useRef<HTMLTdsDropdownElement>(null)
  var refDropdown3 = useRef<HTMLTdsDropdownElement>(null)

  const handleGoToGuidedOffering = useCallback(() => {
    if (!marketLanguage) {
      throw new Error('Expected marketLanguage to be defined.')
    }
    if (!sessionInitData) {
      throw new Error('Expected etelMarketCode to be defined.')
    }
    const urlCidParams = getUrlCidBevSemiliveParameters(UrlParamMode.ExcludeBev)
    const url = `/go/go-start/${marketLanguage.market}/${marketLanguage.language}/`
    const goUrl = url + getUrlParametersToString(urlCidParams)
    dispatch(
      setPendingUserTrackingPageNavigation({
        factoryModelSelected: null,
        flowName: ScaniaAdobeTrackingFlowName.FactoryModels,
        optionSelected: null,
        stepName: ScaniaAdobeTrackingPageName.FactoryModels,
        stepSkipped: null,
      }),
    )
    navigate(goUrl)
  }, [dispatch, marketLanguage, navigate, sessionInitData])

  // Build dropDownState.
  useEffect(() => {
    var newState = allowedFilterFamilyIds.flatMap<DropDownData>((familyId) => {
      const executionIds = filterFamilies[familyId]
      if (!executionIds) {
        return []
      }
      const choices: DropDownChoice[] = executionIds.map((execId) => ({
        id: familyId + '~' + execId,
        text: getFactoryModelText(familyId + '~' + execId),
      }))
      choices.unshift({
        id: familyId + '~' + FILTER_CHOICE_ID_SHOW_ALL,
        text: t(TextId.LABEL_SHOW_ALL).trim(),
      })
      choices.unshift({
        id: familyId + '~' + FILTER_CHOICE_ID_INACTIVE,
        text: t(TextId.LABEL_NO_SELECTION).trim(),
      })
      const activeVariant = getActiveViewVariant(viewFilter, familyId)
      const getSelectedId = () => {
        let id = null
        if (activeVariant === null) {
          id = FILTER_CHOICE_ID_INACTIVE
        } else if (activeVariant === undefined) {
          id = FILTER_CHOICE_ID_INACTIVE
        } else {
          id = activeVariant.execution
        }
        return familyId + '~' + id
      }
      const selectedId = getSelectedId()
      const result: DropDownData = {
        choices,
        id: familyId,
        selectedId,
        text: t('SQ_HEADER_', familyId),
      }
      return [result]
    })
    setDropDownSate(newState)
    // Sync all dropdowns
    newState.forEach((data, index) => {
      const ref =
        index === 0 ? refDropdown1 : index === 1 ? refDropdown2 : refDropdown3
      if (ref.current?.getAttribute('selected-option') !== data.selectedId) {
        ref.current?.setValue(data.selectedId)
      }
    })
  }, [
    allowedFilterFamilyIds,
    filterFamilies,
    getFactoryModelText,
    viewFilter,
    t,
  ])

  const handleChange = useCallback(
    (ev: any) => {
      const detail: DropDownEventDetails | undefined = ev.detail
      const id = detail?.value
      if (!id) {
        // The user closed the menu without changing the selection.
        return
      }
      const variant = toEtelVariant(id)
      const etelExecutionIdOrSentinelValue = variant.execution
      const selection: FactoryModelsFilterSelection = {
        etelFamilyId: variant.family,
        etelExecutionId: etelExecutionIdOrSentinelValue,
      }
      const trackingEvent: ScaniaAdobeTrackingClickFmModelFilterEvent = {
        event: ScaniaAdobeEventId.FmModelFilterClick,
        eventInfo: {
          optionSelected: id,
        },
      }
      pushAdobeEvent(trackingEvent)
      handleFilterSelection(selection)
    },
    [handleFilterSelection],
  )

  const urlCidParams = getUrlCidBevSemiliveParameters(UrlParamMode.ExcludeBev)
  const showGoToGuidedOfferingButton =
    factoryModelsMode !== GoFmMode.OnlyFactoryModels ||
    (marketSettings?.prepareGo && urlCidParams.has('semilive'))

  const itemCount =
    3 + (showGoToGuidedOfferingButton ? 1 : 0) + (showBevButton ? 1 : 0)

  return (
    <ControlBox>
      <FilterControlsRoot $itemCount={itemCount}>
        {showGoToGuidedOfferingButton && (
          <BuildYourOwnTruckButton
            variant="secondary"
            size="md"
            onClick={handleGoToGuidedOffering}
            fullbleed
          >
            <div slot="label">
              <SvgConfigurator />
              {t(TextId.FM_GO_TO_GUIDED_OFFERING_INSTEAD)}
            </div>
          </BuildYourOwnTruckButton>
        )}
        {showBevButton && (
          <BevToggleButton>
            <TdsToggle
              onTdsToggle={(ev) => {
                handleBevToggle(ev.target.checked)
              }}
            >
              <div slot="label" style={{ fontSize: '14px' }}>
                {t('FM_ELECTRIC_TRUCK')}
              </div>
            </TdsToggle>
          </BevToggleButton>
        )}
        {dropDownState.map((dds, i) => (
          <TdsDropdown
            key={dds.id}
            placeholder={t(TextId.LABEL_NO_SELECTION).trim()}
            label={dds.text}
            labelPosition="inside"
            size="md"
            selected-option={dds.selectedId}
            open-direction="down"
            data-test-element-type={
              TestElementTypeId.FactoryModelControlPanelDropDown
            }
            onTdsChange={handleChange}
            ref={i === 0 ? refDropdown1 : i === 1 ? refDropdown2 : refDropdown3}
          >
            {dds.choices.map((c) => (
              <TdsDropdownOption key={'dropdown-choice-' + c.id} value={c.id}>
                {c.text}
              </TdsDropdownOption>
            ))}
          </TdsDropdown>
        ))}
      </FilterControlsRoot>
    </ControlBox>
  )
}
