export enum UrlParamMode {
  ExcludeBev = 0,
  IncludeBev = 1,
}

export function getUrlCidBevSemiliveParameters(
  urlParamMode: UrlParamMode,
): URLSearchParams {
  const urlParams = new URLSearchParams()
  if (urlParamMode === UrlParamMode.IncludeBev) {
    urlParams.set('bev', '1')
  }
  const cidValue = getUrlCidValue()
  if (cidValue) {
    urlParams.set('cid', cidValue)
  }
  const semiliveValue = getSemiliveValue()
  if (semiliveValue) {
    urlParams.set('semilive', semiliveValue)
  }
  return urlParams
}

export function getUrlCidValue(): string | null {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get('cid')
}

export function getSemiliveValue(): string | null {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get('semilive')
}
