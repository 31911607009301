const SCDS_SESSION_ID_KEY = 'scds_session_id'
const SCDS_EYS_START_CFG_ID = 'scds_eys_start_cfg_id'

export function setSessionIdInSessionStorage(sessionId: string) {
  sessionStorage.setItem(SCDS_SESSION_ID_KEY, sessionId)
}

export function getAndDeleteSessionIdFromSessionStorage(): string | null {
  const sessionId = sessionStorage.getItem(SCDS_SESSION_ID_KEY)
  sessionStorage.removeItem(SCDS_SESSION_ID_KEY)
  return sessionId
}

export function setEysStartConfigId(id: string) {
  sessionStorage.setItem(SCDS_EYS_START_CFG_ID, id)
}

export function getAndDeleteEysStartConfigId(): string | null {
  const id = sessionStorage.getItem(SCDS_EYS_START_CFG_ID)
  sessionStorage.removeItem(SCDS_EYS_START_CFG_ID)
  return id
}

export function deleteEysStartConfigId() {
  sessionStorage.removeItem(SCDS_EYS_START_CFG_ID)
}
