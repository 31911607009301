import styled from 'styled-components'
import { ColorMenu } from './ColorMenu'
import { LeafMenuEntry } from '../ConfiguratorMenu'
import { SvgInfo, SvgLoading, SvgTick } from '../../../../components/SvgImports'
import { useCallback, useLayoutEffect, useRef, useState } from 'react'
import { blinkKeyframes } from '../../MenuBlinkAnimation'
import { CustomScrollbarMixin } from '../../../../css/CustomScrollbarMixin'
import {
  ScaniaAdobeTrackingClickBmReadMoreEvent,
  pushAdobeEvent,
  ScaniaAdobeEventId,
} from '../../../../utils/adobeAnalytics'
import useTexts from '../../../../utils/useTexts'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { TestElementTypeId } from '../../../../types/TestAttributeId'
import { BreakpointWidthPx } from '../../../../css/BreakpointWidthPx'
import {
  openPanelReadMore,
  setReadMoreNode,
} from '../../../../store/sidePanelSlice'
import { getMenuState } from '../../../../store/menuSlice'

const CurrentSelectionRoot = styled.div`
  @media screen and (max-width: ${BreakpointWidthPx.Tablet}px) {
    height: 100%;
    background-color: white;
  }

  @media screen and (min-width: ${BreakpointWidthPx.Tablet}px) {
    max-height: calc(100% - 68px - 68px);
    width: 100%;
  }
`

const CurrentSelectionWrapper = styled.div`
  width: 100%;
  background-color: var(--tds-grey-100);
  border-bottom: thin solid var(--tds-blue-400);
`

const CurrentSelectionTitle = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  font-weight: bold;
  padding-left: 15px;
  padding-right: 15px;

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`

const CurrentSelectionText = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  height: 48px;
  align-items: center;
  display: flex;
  font-weight: bold;

  ::first-letter {
    text-transform: capitalize;
  }

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`

const ScrollContainer = styled.div`
  width: 100%;
  overflow-x: hidden;
  border-top: thin solid var(--tds-blue-400);

  @media screen and (min-width: ${BreakpointWidthPx.Tablet}px) {
    ${CustomScrollbarMixin};
    overflow-y: auto;
    max-height: calc(
      100vh - var(--header-height) - 68px - 68px - 107px - 2px
    ); // The height of CurrentSelection is 107px
  }
`

interface CurrentSelectionItemWrapperProps {
  $isSelected: boolean
  $isHighlighted: boolean
}

const MenuItemWrapper = styled.div<CurrentSelectionItemWrapperProps>`
  width: 100%;
  height: 48px;
  align-items: center;
  position: relative;
  display: flex;
  border-bottom: thin solid var(--tds-grey-300);

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: var(--tds-grey-50);
    }
  }

  animation-duration: 500ms;
  animation-iteration-count: 3;
  animation-name: ${(props) =>
    props.$isHighlighted ? blinkKeyframes : 'unset'};
`

const MenuItem = styled.div`
  width: 100%;
  height: 48px;
  align-items: center;
  display: flex;
  text-overflow: ellipsis;
  cursor: pointer;

  & > span {
    display: -webkit-box;
    max-width: 200px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`

interface IconWrapperProps {
  $hasReadMore: boolean
}

const IconWrapper = styled.div<IconWrapperProps>`
  width: 52px;
  min-width: 52px;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: ${(props) => (props.$hasReadMore ? 'help' : 'auto')};

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: ${(props) =>
        props.$hasReadMore ? 'var(--tds-grey-50)' : ''};
    }
  }

  svg {
    height: 20px;
    width: 20px;
    margin-right: 16px;
    margin-left: 16px;
  }
`

const ItemStatusIconWrapper = styled.div`
  height: 24px;
  width: 24px;
  margin-right: 12px;
  margin-left: 12px;

  svg {
    height: 24px;
    width: 24px;
  }
`

export interface CurrentSelectionProps {
  handleItemSelection: (id: string) => void
  highlightedId: string | null
}

export function CurrentSelection({
  handleItemSelection,
  highlightedId,
}: CurrentSelectionProps): JSX.Element {
  const scrollToSearchResultRef = useRef<HTMLDivElement | null>(null)
  const dispatch = useAppDispatch()
  const t = useTexts()
  const { visibleMenuState, currentlyLoadingId } = useAppSelector(getMenuState)
  const colorIds = visibleMenuState?.colorInfo?.colorIds || null
  const isColorMenu = visibleMenuState?.colorInfo?.isColorMenu || false
  const leafNodes = visibleMenuState?.leafNodes
  const [visibleHighlightedId, setVisibleHighlightedId] = useState<
    string | null
  >(null)

  // Scroll to item on search, minisummary click, activeChanges click, campaign click
  useLayoutEffect(() => {
    // It seems like Chrome cancels ongoing smooth scrolls when starting a new,
    // at least some times? That's why this scroll delay is here, the main menu
    // scroll must finish before starting this one.
    //
    // TODO: Consider using this event instead at a later time:
    // https://caniuse.com/mdn-api_element_scrollend_event
    //
    //Also delay the blink on selected item until scroll is finished.
    const scrollDelayMs = 700

    setTimeout(() => {
      if (scrollToSearchResultRef.current) {
        scrollToSearchResultRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
        setVisibleHighlightedId(highlightedId)
      }
    }, scrollDelayMs)
  }, [highlightedId])

  const buildItemStatusIcon = (
    currentlyLoadingId: string | null,
    entry: LeafMenuEntry,
  ): JSX.Element | null => {
    if (entry.isSelected) {
      return (
        <ItemStatusIconWrapper>
          <SvgTick color="var(--tds-blue-400)" />
        </ItemStatusIconWrapper>
      )
    }
    if (currentlyLoadingId === entry.id) {
      return (
        <ItemStatusIconWrapper>
          <SvgLoading color="var(--tds-blue-400)" />
        </ItemStatusIconWrapper>
      )
    }
    return null
  }

  const handleReadmoreClick = useCallback(
    async (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      node: LeafMenuEntry,
    ) => {
      e.stopPropagation()
      e.preventDefault()
      dispatch(openPanelReadMore())
      dispatch(setReadMoreNode({ id: node.id, title: node.text }))

      const trackingEvent: ScaniaAdobeTrackingClickBmReadMoreEvent = {
        event: ScaniaAdobeEventId.BmReadMoreClick,
      }
      pushAdobeEvent(trackingEvent)
    },
    [dispatch],
  )

  function buildNonColorItems(
    leafNodes: LeafMenuEntry[] | null | undefined,
    handleItemSelection: (id: string) => void,
  ): JSX.Element {
    if (!leafNodes) {
      throw new Error('Expected leaf nodes.')
    }
    let selectedItemExist = false
    let selectedNode = leafNodes[0]
    leafNodes.forEach((node) => {
      if (node.isSelected) {
        selectedItemExist = true
        selectedNode = node
      }
    })
    const result = (
      <CurrentSelectionRoot>
        <CurrentSelectionWrapper>
          <CurrentSelectionTitle>
            <span>{t('LABEL_CURRENT_SELECTION')}</span>
          </CurrentSelectionTitle>
          {selectedItemExist ? (
            <CurrentSelectionText>
              <span
                data-test-element-type={
                  TestElementTypeId.ConfigurationMenuCurrentSelectionText
                }
                data-etel-variant-id={selectedNode.id}
              >
                {selectedNode.text}
              </span>
            </CurrentSelectionText>
          ) : (
            <CurrentSelectionText>-</CurrentSelectionText>
          )}
        </CurrentSelectionWrapper>
        <ScrollContainer>
          {leafNodes.map((node, i) => (
            <MenuItemWrapper
              $isSelected={node.isSelected}
              key={node.id}
              $isHighlighted={node.id === visibleHighlightedId}
              ref={
                node.isSelected
                  ? scrollToSearchResultRef
                  : node.id === highlightedId
                  ? scrollToSearchResultRef
                  : null
              }
            >
              {node.hasReadmore ? (
                <IconWrapper
                  $hasReadMore={node.hasReadmore}
                  onClick={(e) => handleReadmoreClick(e, node)}
                >
                  <SvgInfo />
                </IconWrapper>
              ) : (
                <IconWrapper $hasReadMore={node.hasReadmore}></IconWrapper>
              )}
              <MenuItem
                onClick={() => {
                  handleItemSelection(node.id)
                }}
              >
                <span>{node.text}</span>
              </MenuItem>
              {buildItemStatusIcon(currentlyLoadingId, node)}
            </MenuItemWrapper>
          ))}
        </ScrollContainer>
      </CurrentSelectionRoot>
    )
    return result
  }

  return (
    <>
      {isColorMenu && colorIds ? (
        <CurrentSelectionRoot data-name="CurrentSelectionRoot">
          <ColorMenu
            handleItemSelection={handleItemSelection}
            highlightedId={highlightedId}
          />
        </CurrentSelectionRoot>
      ) : (
        buildNonColorItems(leafNodes, handleItemSelection)
      )}
    </>
  )
}
