/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface FactoryModelImageResponse
 */
export interface FactoryModelImageResponse {
  /**
   *
   * @type {string}
   * @memberof FactoryModelImageResponse
   */
  imageUrl?: string | null
}

/**
 * Check if a given object implements the FactoryModelImageResponse interface.
 */
export function instanceOfFactoryModelImageResponse(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function FactoryModelImageResponseFromJSON(
  json: any,
): FactoryModelImageResponse {
  return FactoryModelImageResponseFromJSONTyped(json, false)
}

export function FactoryModelImageResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): FactoryModelImageResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    imageUrl: !exists(json, 'ImageUrl') ? undefined : json['ImageUrl'],
  }
}

export function FactoryModelImageResponseToJSON(
  value?: FactoryModelImageResponse | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    ImageUrl: value.imageUrl,
  }
}
