/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface DealerVisitingAddress
 */
export interface DealerVisitingAddress {
  /**
   *
   * @type {string}
   * @memberof DealerVisitingAddress
   */
  street?: string | null
  /**
   *
   * @type {string}
   * @memberof DealerVisitingAddress
   */
  streetLocal?: string | null
  /**
   *
   * @type {string}
   * @memberof DealerVisitingAddress
   */
  city?: string | null
  /**
   *
   * @type {string}
   * @memberof DealerVisitingAddress
   */
  cityLocal?: string | null
  /**
   *
   * @type {string}
   * @memberof DealerVisitingAddress
   */
  postalCode?: string | null
}

/**
 * Check if a given object implements the DealerVisitingAddress interface.
 */
export function instanceOfDealerVisitingAddress(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function DealerVisitingAddressFromJSON(
  json: any,
): DealerVisitingAddress {
  return DealerVisitingAddressFromJSONTyped(json, false)
}

export function DealerVisitingAddressFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): DealerVisitingAddress {
  if (json === undefined || json === null) {
    return json
  }
  return {
    street: !exists(json, 'Street') ? undefined : json['Street'],
    streetLocal: !exists(json, 'StreetLocal') ? undefined : json['StreetLocal'],
    city: !exists(json, 'City') ? undefined : json['City'],
    cityLocal: !exists(json, 'CityLocal') ? undefined : json['CityLocal'],
    postalCode: !exists(json, 'PostalCode') ? undefined : json['PostalCode'],
  }
}

export function DealerVisitingAddressToJSON(
  value?: DealerVisitingAddress | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    Street: value.street,
    StreetLocal: value.streetLocal,
    City: value.city,
    CityLocal: value.cityLocal,
    PostalCode: value.postalCode,
  }
}
