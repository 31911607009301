/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { FactoryModelOverrides } from './FactoryModelOverrides'
import {
  FactoryModelOverridesFromJSON,
  FactoryModelOverridesFromJSONTyped,
  FactoryModelOverridesToJSON,
} from './FactoryModelOverrides'
import type { MenuItem } from './MenuItem'
import {
  MenuItemFromJSON,
  MenuItemFromJSONTyped,
  MenuItemToJSON,
} from './MenuItem'

/**
 *
 * @export
 * @interface FactoryModelV2
 */
export interface FactoryModelV2 {
  /**
   *
   * @type {number}
   * @memberof FactoryModelV2
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof FactoryModelV2
   */
  name: string
  /**
   * The Etel codes for the initial filter choices.
   *
   * These should be as few as possible to avoid bloating the response
   * object.
   * @type {Array<string>}
   * @memberof FactoryModelV2
   */
  configurationSubset: Array<string>
  /**
   * The Etel codes for the initial filter choices.
   *
   * These should be as few as possible to avoid bloating the response
   * object.
   * @type {{ [key: string]: Array<string>; }}
   * @memberof FactoryModelV2
   */
  optionsSubset: { [key: string]: Array<string> }
  /**
   *
   * @type {FactoryModelOverrides}
   * @memberof FactoryModelV2
   */
  overrides?: FactoryModelOverrides | null
  /**
   *
   * @type {MenuItem}
   * @memberof FactoryModelV2
   */
  superEngine?: MenuItem | null
  /**
   *
   * @type {MenuItem}
   * @memberof FactoryModelV2
   */
  bevPropulsion?: MenuItem | null
}

/**
 * Check if a given object implements the FactoryModelV2 interface.
 */
export function instanceOfFactoryModelV2(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'name' in value
  isInstance = isInstance && 'configurationSubset' in value
  isInstance = isInstance && 'optionsSubset' in value

  return isInstance
}

export function FactoryModelV2FromJSON(json: any): FactoryModelV2 {
  return FactoryModelV2FromJSONTyped(json, false)
}

export function FactoryModelV2FromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): FactoryModelV2 {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['Id'],
    name: json['Name'],
    configurationSubset: json['ConfigurationSubset'],
    optionsSubset: json['OptionsSubset'],
    overrides: !exists(json, 'Overrides')
      ? undefined
      : FactoryModelOverridesFromJSON(json['Overrides']),
    superEngine: !exists(json, 'SuperEngine')
      ? undefined
      : MenuItemFromJSON(json['SuperEngine']),
    bevPropulsion: !exists(json, 'BevPropulsion')
      ? undefined
      : MenuItemFromJSON(json['BevPropulsion']),
  }
}

export function FactoryModelV2ToJSON(value?: FactoryModelV2 | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    Id: value.id,
    Name: value.name,
    ConfigurationSubset: value.configurationSubset,
    OptionsSubset: value.optionsSubset,
    Overrides: FactoryModelOverridesToJSON(value.overrides),
    SuperEngine: MenuItemToJSON(value.superEngine),
    BevPropulsion: MenuItemToJSON(value.bevPropulsion),
  }
}
