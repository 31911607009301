import { useDrag } from '@use-gesture/react'
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'
import { CardData } from '../../../../types/GuidedOffering/CardData'
import { SvgInfo } from '../../../../components/SvgImports'
import { TestElementTypeId } from '../../../../types/TestAttributeId'
import { ScaniaAdobeTrackingPageName } from '../../../../utils/adobeAnalytics'
import { FAMILY_ID_CAB, FAMILY_ID_CAB_SERIES } from '../../../../api/constants'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { Size2d } from '../../../../types/Size2d'
import { GoChoiceClickHandler } from '../../goChoiceClickHook'
import { GoReadMoreClickHandler } from '../../goReadmoreUtil'
import { BreakpointWidthPx } from '../../../../css/BreakpointWidthPx'
import { getMarketLanguageState } from '../../../../store/appSlice'
import {
  hideInitialLoadingScreen,
  pushPageViewTrackingEvent,
} from '../../../../store/sessionDataSlice'

const ImgCarouselRoot = styled.div`
  height: 100%;
  position: relative;
  width: 100%;
  user-select: none;
  display: grid;
  grid-template-rows: auto 1fr;
`

const ImgCarouselInnerRoot = styled.div`
  // Needed to make mobile browsers behave well with useDrag.
  touch-action: pan-y;

  //background-color: rgb(50, 50, 50);
  height: 100%;
  //overflow-x: hidden;
  //overflow-y: hidden;
  position: relative;
  width: 100%;
  cursor: pointer;
  user-select: none;

  transition-duration: 200ms;
  transition-property: opacity;
  transition-timing-function: ease-in-out;
`

const TruckContainer = styled.div`
  --image-bottom-margin: 8px;
  --button-wrapper-height: 44px;
  display: flex;
  position: absolute;
  transition-duration: 200ms;
  transition-property: left, height, width, opacity;
  transition-timing-function: ease-in-out;
  flex-direction: column;
  justify-content: space-between;

  img {
    display: block;
    position: relative;

    // Needed to make desktop browsers behave well with useDrag.
    touch-action: pan-y;
    -moz-user-select: none;
    -webkit-user-drag: none;
    user-select: none;

    // Firefox seems to need this as well.
    pointer-events: none;

    transition-duration: 200ms;
    transition-property: left, height, width, opacity;
    transition-timing-function: ease-in-out;
  }
`

const TruckHeaderWrapper = styled.div`
  align-items: center;
  display: flex;
  height: var(--button-wrapper-height);
  justify-content: center;
  max-width: 100%;
  position: relative;
  width: 100%;
`

const TruckHeaderInnerWrapper = styled.div`
  align-items: center;
  display: flex;
  column-gap: 8px;
  height: var(--button-wrapper-height);
  justify-content: center;
  max-width: 100%;
  position: relative;
  width: fit-content;

  svg {
    color: var(--tds-blue-800);
    cursor: pointer;
  }
`

const SelectorMiniButtonsWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  height: 32px;
  justify-content: center;
  min-height: 32px;
  position: relative;
  width: 100%;

  @media screen and (min-width: ${BreakpointWidthPx.Tablet}px) {
    max-width: 100%;
  }
`

const SelectorMiniButtonWrapper = styled.div`
  --big-button-height: 8px;
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;

  @media (hover: hover) and (pointer: fine) {
    &:hover > div {
      background-color: var(--tds-grey-700);
      --big-button-height: 12px;
    }
  }
`

interface SelectorMiniButtonProps {
  $isSelected: boolean
}

const SelectorMiniButton = styled.div<SelectorMiniButtonProps>`
  height: var(--big-button-height);
  margin: 0px 5px 0px 5px;
  transition-duration: 70ms;
  transition-property: margin, background-color, height;
  transition-timing-function: linear;
  width: 50px;
  background-color: ${(props) =>
    props.$isSelected
      ? 'var(--tds-btn-primary-background)'
      : 'var(--tds-grey-400)'};

  @media screen and (max-width: ${BreakpointWidthPx.Tablet}px) {
    border-radius: 50%;
    height: 10px;
    width: 10px;
    margin: 0px 8px 0px 8px;
  }

  @media screen and (min-width: ${BreakpointWidthPx.Tablet}px) {
    max-width: 70vw;
  }
`

interface LoadedImageState {
  etelFamilyId: string
  loadedImagesByUrl: Record<string, HTMLImageElement>
}

function resolveTrackingStepName(
  etelFamilyId: string,
): ScaniaAdobeTrackingPageName | null {
  if (etelFamilyId === FAMILY_ID_CAB_SERIES) {
    return ScaniaAdobeTrackingPageName.GoCabSeries
  }
  if (etelFamilyId === FAMILY_ID_CAB) {
    return ScaniaAdobeTrackingPageName.GoCab
  }
  return null
}

export interface ImgCarouselParams {
  cards: CardData[]
  etelFamilyId: string
  handleCardClick: GoChoiceClickHandler
  handleReadMoreClick: GoReadMoreClickHandler
}

// TODO: Make the transition smooth when `cards` change.
// This component does script based layout.
export const ImgCarousel = ({
  cards,
  etelFamilyId,
  handleCardClick,
  handleReadMoreClick: handleReadmoreClick,
}: ImgCarouselParams): JSX.Element => {
  const dispatch = useAppDispatch()
  const marketLanguage = useAppSelector(getMarketLanguageState)
  const observedElement = useRef<HTMLDivElement | null>(null)
  const [size, setSize] = useState<Size2d | null>(null)
  const [loadedImages, setLoadedImages] = useState<LoadedImageState | null>(
    null,
  )
  const urlParams = new URLSearchParams(window.location.search)
  const isBev = urlParams.has('bev')

  // Only one truck Cab/Cab Series is active/selected/focused at a time.
  const [focusIndex, setFocusIndex] = useState<number | null>(null)

  // Adobe user tracking.
  useEffect(() => {
    if (!marketLanguage) {
      return
    }
    const stepName = resolveTrackingStepName(etelFamilyId)
    if (!stepName) {
      return
    }
    dispatch(pushPageViewTrackingEvent({ pageName: stepName, marketLanguage }))
  }, [dispatch, etelFamilyId, marketLanguage])

  useEffect(() => {
    setLoadedImages((prev) => {
      if (prev?.etelFamilyId === etelFamilyId) {
        // Same as before, do nothing.
        return prev
      }
      return null
    })
    cards.forEach((c) => {
      const img = new Image()
      img.onload = (ev) => {
        setLoadedImages((prev) => {
          let newState: LoadedImageState
          if (prev === null) {
            newState = {
              etelFamilyId,
              loadedImagesByUrl: {},
            }
          } else if (prev.etelFamilyId !== etelFamilyId) {
            // This image doesn't belong in this view, ignore it.
            console.error(
              'Expected pre.etelFamilyId to be ' +
                etelFamilyId +
                ', found: ' +
                prev.etelFamilyId,
            )
            return prev
          } else {
            newState = {
              ...prev,
            }
          }
          const url = isBev
            ? c.imageUrl.replace('.png', '_BEV.png')
            : c.imageUrl
          newState.loadedImagesByUrl[url] = img
          return newState
        })
      }
      const url = isBev ? c.imageUrl.replace('.png', '_BEV.png') : c.imageUrl
      img.src = url
    })
  }, [etelFamilyId, cards, isBev])

  // Initialize focusIndex.
  useEffect(() => {
    const i = cards.findIndex((c) => c.defaultSelected)
    setFocusIndex(i === -1 ? 0 : i)
  }, [cards])

  const resizeObserver = useRef(
    new ResizeObserver((entries) => {
      const target = entries[0].target
      const size: Size2d = {
        width: target.clientWidth,
        height: target.clientHeight,
      }
      setSize(size)
    }),
  )

  useEffect(() => {
    if (!observedElement.current) {
      return
    }
    const observer = resizeObserver
    observer.current.observe(observedElement.current)
    return () => {
      observer.current.disconnect()
    }
  }, [observedElement])

  useLayoutEffect(() => {
    if (!observedElement.current) {
      throw new Error(
        'Expected observedElement to always be bound to an element.',
      )
    }
    const width = observedElement.current.clientWidth
    const height = observedElement.current.clientHeight
    setSize({ width, height })
  }, [])

  const allImagesAreLoaded = useCallback(() => {
    return cards.every((c) => {
      if (cards.length === 0) {
        return false
      }
      if (loadedImages?.etelFamilyId !== etelFamilyId) {
        return false
      }
      const url = isBev ? c.imageUrl.replace('.png', '_BEV.png') : c.imageUrl
      return (loadedImages?.loadedImagesByUrl || {})[url] !== undefined
    })
  }, [cards, etelFamilyId, loadedImages, isBev])

  // React to size changes and image load completion.
  useEffect(() => {
    if (!size) {
      return // Not initialized yet.
    }
    if (focusIndex === null) {
      return // Not initialized yet.
    }
    if (!observedElement.current) {
      console.warn('Expected observedElement.current to be defined.')
      return
    }
    if (!loadedImages) {
      return // Not initialized yet.
    }
    if (!allImagesAreLoaded()) {
      console.log('Waiting for images to load...')
      return
    }
    const truckNodes = observedElement.current.hasChildNodes()
      ? observedElement.current.childNodes
      : null
    if (!truckNodes) {
      return
    }
    for (const [i, c] of truckNodes.entries()) {
      if (!(c instanceof HTMLDivElement)) {
        console.warn('Expected div element, found: ', c)
        continue
      }
      if (!(c.firstChild instanceof HTMLImageElement)) {
        const card = cards[i]
        if (!card) {
          throw new Error('Expected card to be defined.')
        }
        let imageUrl = card.imageUrl
        if (isBev) {
          imageUrl = card.imageUrl.replace('.png', '_BEV.png')
        }
        const matchingImage = loadedImages.loadedImagesByUrl[imageUrl]
        if (!matchingImage) {
          throw new Error('Expected matchingImage to be defined.')
        }
        c.prepend(matchingImage)
      }
    }
    for (const [i, c] of truckNodes.entries()) {
      if (!(c instanceof HTMLDivElement)) {
        console.warn('Expected div element, found: ', c)
        continue
      }
      const cardData = cards[i]
      if (!cardData) {
        console.error('Expected to find cardData for index: ' + i)
      }

      //const height = size.height;
      //const width = Math.min(size.width, size.height)

      //const opacity = focusIndex === i ? '1.0' : '0.15'
      const el = c as HTMLDivElement
      const img = el.getElementsByTagName('img')[0]
      if (!img) {
        console.error('Expected img to be defined.')
        continue
      }
      const minimumSideMarginPx = 16
      const buttonHeightPx = 44 + 8
      let imgHeight = size.height - buttonHeightPx
      let imgScale = imgHeight / img.naturalHeight
      let imgWidth = imgScale * img.naturalWidth
      if (imgWidth + minimumSideMarginPx > size.width) {
        imgWidth = size.width - minimumSideMarginPx
        imgScale = imgWidth / img.naturalWidth
        imgHeight = imgScale * img.naturalHeight
      }
      img.style.height = imgHeight + 'px'
      img.style.width = imgWidth + 'px'
      el.style.width = imgWidth + 'px'
      el.style.height = size.height + 'px'
      const gridWidth = imgWidth + 16
      el.style.left =
        (i - focusIndex) * gridWidth + size.width * 0.5 - imgWidth * 0.5 + 'px'

      // For automated tests:
      el.dataset['testElementType'] =
        TestElementTypeId.GuidedOfferingChoiceButton
      el.dataset['testGoGroupId'] = etelFamilyId
      el.dataset['testIsFocused'] = (focusIndex === i) + ''

      //el.style.opacity = opacity
      //const randomChannelValue = () => {
      //  return Math.round((Math.random() * 255) / 2 + 127)
      //}
      //const r = randomChannelValue()
      //const g = randomChannelValue()
      //const b = randomChannelValue()
      //el.style.backgroundColor = 'rgb(' + r + ", " + g + ", " + b + ")"
    }
  }, [
    cards,
    etelFamilyId,
    focusIndex,
    size,
    observedElement,
    loadedImages,
    allImagesAreLoaded,
    isBev,
  ])

  const handleSwipe = useCallback(
    (direction: number) => {
      if (!cards) {
        return
      }
      setFocusIndex((prev) => {
        const i = prev || 0
        let j = i - Math.round(direction)
        j = j < 0 ? cards.length + j : j
        j = j % cards.length
        return j
      })
    },
    [cards],
  )

  const bindDrag = useDrag(
    (drag) => {
      if (drag.tap) {
        if (!(drag.target instanceof HTMLDivElement)) {
          return
        }
        const target = drag.target as HTMLDivElement
        const cardIndexStr = target.dataset['cardIndex']
        if (cardIndexStr === undefined) {
          return
        }
        const cardIndex = Number(cardIndexStr)
        if (isNaN(cardIndex)) {
          return
        }
        const etelId = target.dataset['etelVariantId']
        if (!etelId) {
          return
        }
        if (focusIndex === cardIndex) {
          setLoadedImages(null)
          handleCardClick({ id: etelId, isSkippingToNext: false })
        }
        setFocusIndex(cardIndex)
        return
      }
      const xSwipe = drag.swipe[0]
      if (xSwipe === 0) {
        return
      }
      handleSwipe(xSwipe)
    },
    {
      axis: 'x',
      filterTaps: true,
      swipe: { distance: 15, duration: 2000, velocity: [0.02, 0.02] },
    },
  )

  useEffect(() => {
    if (allImagesAreLoaded()) {
      dispatch(hideInitialLoadingScreen())
    }
  }, [dispatch, allImagesAreLoaded])

  const showImages = allImagesAreLoaded()

  return (
    <ImgCarouselRoot>
      <SelectorMiniButtonsWrapper>
        {size &&
          cards.map((card, i) => {
            return (
              <SelectorMiniButtonWrapper
                key={'selector-mini-button-' + i + '-' + card.id}
                onClick={() => setFocusIndex(i)}
              >
                <SelectorMiniButton $isSelected={i === focusIndex} />
              </SelectorMiniButtonWrapper>
            )
          })}
      </SelectorMiniButtonsWrapper>
      <ImgCarouselInnerRoot
        data-name="ImgCarouselRoot"
        ref={observedElement}
        {...bindDrag()}
        style={{ opacity: showImages ? 1 : 0 }}
      >
        {size &&
          showImages &&
          cards.map((card, i) => {
            return (
              <TruckContainer
                data-card-index={i}
                data-etel-variant-id={card.id}
                data-name="TruckContainer"
                key={`card-${card.id}-${card.index}`}
                style={{ opacity: i === focusIndex ? 1 : 0.35 }}
              >
                <TruckHeaderWrapper data-name="TruckButtonWrapper">
                  <TruckHeaderInnerWrapper data-name="TruckButtonInnerWrapper">
                    {card.readmore && (
                      <SvgInfo
                        data-test-element-type={
                          TestElementTypeId.GuidedOfferingInfoIcon
                        }
                        style={{ height: '24px' }}
                        onClick={(e) => handleReadmoreClick(e, card.id)}
                      ></SvgInfo>
                    )}
                    <h5>{card.header}</h5>
                  </TruckHeaderInnerWrapper>
                </TruckHeaderWrapper>
              </TruckContainer>
            )
          })}
      </ImgCarouselInnerRoot>
    </ImgCarouselRoot>
  )
}
