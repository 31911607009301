import { useState, useEffect, useCallback } from 'react'
import useText from '../../../utils/useTexts'
import { GoQuestion } from '../../../api/generated'
import {
  AdobeTrackingGoSkipToBm,
  ScaniaAdobeEventId,
  ScaniaAdobeTrackingFlowName,
  ScaniaAdobeTrackingGoOptionalsInput,
  ScaniaAdobeTrackingPageName,
  pushAdobeEvent,
} from '../../../utils/adobeAnalytics'
import styled from 'styled-components'
import { Timeline } from '../Timeline'
import { CustomScrollbarMixin } from '../../../css/CustomScrollbarMixin'
import { NavigationButtons } from '../../GuidedOffering/NavigationButtons'
import {
  GoPageId,
  useSyncClientAndBackendState,
  GoStateSyncSettings,
} from '../../GuidedOffering/syncBackendStateHook'
import { useClient } from '../../../utils/useClient'
import { GuidedOfferingClientState } from '../../../types/GuidedOffering'
import { GuidedOfferingClientStateStatus } from '../../../types/GuidedOffering/GuidedOfferingClientState'
import {
  TestElementTypeId,
  TEST_GO_GROUP_ID_DONE_BUTTON,
} from '../../../types/TestAttributeId'
import { PortraitFooter } from '../../../components/Footer/PortraitFooter'
import { ScrollContent } from '../ScrollContent'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { BreakpointWidthPx } from '../../../css/BreakpointWidthPx'
import { DropDownComponent } from './DropDownComponent'
import { ArrowRight } from 'react-feather'
import { GO_LOW_HEIGHT_LIMIT_ADAPTION_OPTIONALS } from '../../../constants'
import { lighten } from 'polished'
import { getMarketLanguageState } from '../../../store/appSlice'
import {
  pushPageViewTrackingEvent,
  setGuidedOfferingState,
  setPendingUserTrackingPageNavigation,
} from '../../../store/sessionDataSlice'
import { SliderComponent } from './SliderComponent'

const OptionalsRoot = styled.div`
  width: 100%;
  height: 100%;
  top: var(--header-height);
  display: flex;
  flex-direction: column;
  ${CustomScrollbarMixin};
  overflow-y: auto;
  overflow-x: hidden;
`

const PageContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  flex-grow: 1;
  justify-content: flex-start;

  @media screen and (min-width: ${BreakpointWidthPx.Tablet}px) {
    margin-top: 64px;
  }

  // Reduce margin at low desktop screen heights
  @media screen and (min-width: ${BreakpointWidthPx.Tablet}px) and (max-height: ${GO_LOW_HEIGHT_LIMIT_ADAPTION_OPTIONALS}) {
    margin-top: 16px;
  }
`

const OptionalText = styled.p`
  font-size: 1.14em;
  display: flex;
  align-items: center;
  justify-content: center;
`

const BelowTheFold = styled.div`
  width: 100vw;
  display: block;
  justify-content: space-between;
  padding: 1rem;
`

const GridWrap = styled.div`
  margin: 0 auto;
  display: grid;
  grid-gap: 1.25rem;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  max-width: 768px;
  margin-bottom: 1.14em;
`

// TODO: Change this to tegel-button
const ButtonSC = styled.div`
  position: relative;
  cursor: pointer;
  padding: 0 3.3em 0 0.3em;
  min-width: 12.5em; /* 200px */
  max-width: 18.75em; /* 300px */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1rem;
  line-height: 3rem;
  text-align: center;
  box-shadow: 3px 1px 5px rgba(0, 0, 0, 0.1), -3px 1px 5px rgba(0, 0, 0, 0.1);
  font-style: 'normal';
  font-family: 'Scania Sans Bold', Arial, Helvetica, sans-serif;
  background-color: ${({ theme }) => theme.colorSelected};
  color: ${({ theme }) => theme.scWhite};

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: ${({ theme }) => lighten(0.1, theme.colorSelected)};
    }
  }

  .icon {
    position: absolute;
    top: 0;
    width: 3rem;
    right: 0;
    background: rgba(255, 255, 255, 0.1);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export interface OptionalsPageRouteProps {
  market: string
  language: string
  desiredselection: string
}

const stateSyncProps: GoStateSyncSettings = {
  pageId: GoPageId.Optionals,
}

// Needed due to every page containing the entire header and timeline.
// TODO: Move header and timeline to App.tsx root component.
export interface OptionalsPageProps {
  handleFatalError: VoidFunction
  handleGoBack: VoidFunction
  handleJumpToBuildMode: VoidFunction
  handleTimelineSkip: VoidFunction
}

function OptionalsPage({
  handleFatalError,
  handleGoBack,
  handleJumpToBuildMode,
  handleTimelineSkip,
}: OptionalsPageProps) {
  const t = useText()
  const client = useClient()
  const [optionalQuestions, setOptionalQuestions] =
    useState<LocalSpecialGoQuestion[]>()
  const goClientState = useSyncClientAndBackendState(
    stateSyncProps,
    handleFatalError,
  )
  const marketLanguage = useAppSelector(getMarketLanguageState)
  const dispatch = useAppDispatch()

  const handleTimelineSkipWithTracking = () => {
    dispatch(
      setPendingUserTrackingPageNavigation({
        factoryModelSelected: null,
        flowName: ScaniaAdobeTrackingFlowName.GuidedOffering,
        optionSelected: AdobeTrackingGoSkipToBm,
        stepName: ScaniaAdobeTrackingPageName.GoOptionals,
        stepSkipped: `skip from last step to quick start`,
      }),
    )
    handleTimelineSkip()
  }

  // Adobe user tracking.
  useEffect(() => {
    if (!marketLanguage) {
      return
    }
    dispatch(
      pushPageViewTrackingEvent({
        pageName: ScaniaAdobeTrackingPageName.GoOptionals,
        marketLanguage,
      }),
    )
  }, [dispatch, marketLanguage])

  // Adobe user tracking.
  const handleJumpToBuildModeWithTracking = useCallback(() => {
    dispatch(
      setPendingUserTrackingPageNavigation({
        factoryModelSelected: null,
        flowName: ScaniaAdobeTrackingFlowName.GuidedOffering,
        optionSelected: 'exit',
        stepName: ScaniaAdobeTrackingPageName.GoOptionals,
        stepSkipped: null,
      }),
    )
    handleJumpToBuildMode()
  }, [dispatch, handleJumpToBuildMode])

  enum InputType {
    Scalar,
    Dropdown,
  }

  const sendOptionalSelection = useCallback(
    (id: string, header: string | null | undefined, input: InputType) => {
      if (!client) {
        throw new Error('Expected client to be defined.')
      }
      const goState = goClientState?.lastResponse.state
      if (!goState) {
        throw new Error('Expected goState to be defined.')
      }
      if (!goClientState.sliderQuestions) {
        throw new Error('Expected goClientState.sliderQuestions to be defined.')
      }
      const trackingEvent: ScaniaAdobeTrackingGoOptionalsInput = {
        event: ScaniaAdobeEventId.GoOptionalsClick,
        eventInfo: {
          questionLocalized: header,
          selectedOptionLocalized:
            input === InputType.Dropdown ? t(`INPUT_OPTION_`, id) : null,
          selectedOption: id,
        },
      }
      pushAdobeEvent(trackingEvent)

      const asyncWrapper = async () => {
        const result = await client.gowizardChangeSlider({
          etelVariant: id,
          state: goState,
        })
        const newState: GuidedOfferingClientState = {
          cards: null,
          etelLastUpdated: goClientState.etelLastUpdated,
          lastResponse: result,
          sliderQuestions: goClientState.sliderQuestions,
          stateUpdatedByPage: GoPageId.Optionals,
          status: GuidedOfferingClientStateStatus.Active,
          userSelections: goClientState.userSelections,
        }
        dispatch(setGuidedOfferingState(newState))
      }
      asyncWrapper()
    },
    [client, dispatch, goClientState, InputType, t],
  )

  useEffect(() => {
    if (!goClientState?.sliderQuestions) {
      return
    }
    const nonScalarEtelFamilyIdsHardcoded: Record<string, string> = {
      '18014': 'ROADCONDITION',
      '18009': 'TOPOGRAPHY',
      '18011': 'TRAFFIC',
    }
    const nonScalarFamilyIds = Object.keys(nonScalarEtelFamilyIdsHardcoded)
    const isScalarQuestion = (q: GoQuestion) => {
      if (!q.etelFamily) {
        return false
      }
      const isNonScalar = nonScalarFamilyIds.includes(q.etelFamily)
      return !isNonScalar
    }
    const specials: LocalSpecialGoQuestion[] =
      goClientState.sliderQuestions.map((question) => {
        return {
          ...question,
          isScalar: isScalarQuestion(question),
        }
      })
    setOptionalQuestions(specials)
  }, [setOptionalQuestions, goClientState])

  return (
    <OptionalsRoot>
      <ScrollContent>
        <Timeline isReady={true} header={t('LABEL_OPTIONALS_PAGE_HEADER')} />
        {optionalQuestions && (
          <>
            <PageContentWrapper>
              <ButtonSC
                className="optionals-page-button"
                data-test-element-type={
                  TestElementTypeId.GuidedOfferingChoiceButton
                }
                data-test-go-group-id={TEST_GO_GROUP_ID_DONE_BUTTON}
                onClick={handleJumpToBuildModeWithTracking}
              >
                {t('BTN_VIEW_TRUCK').toUpperCase()}
                <div className="icon">
                  <ArrowRight />
                </div>
              </ButtonSC>

              <OptionalText>{t('LABEL_OPTIONALS_PAGE_QUESTIONS')}</OptionalText>
              <BelowTheFold className="belowthefold">
                <GridWrap>
                  {optionalQuestions &&
                    optionalQuestions.map((question, i) =>
                      question.isScalar ? (
                        <SliderComponent
                          question={question}
                          sendInputSelection={(id: string | null) => {
                            if (!id) {
                              return
                            }
                            sendOptionalSelection(
                              id,
                              question.header,
                              InputType.Scalar,
                            )
                          }}
                          key={question.etelFamily}
                        ></SliderComponent>
                      ) : (
                        <DropDownComponent
                          question={question}
                          sendInputSelection={(id: string | null) => {
                            if (!id) {
                              return
                            }
                            sendOptionalSelection(
                              id,
                              question.header,
                              InputType.Dropdown,
                            )
                          }}
                          key={question.etelFamily}
                        ></DropDownComponent>
                      ),
                    )}
                </GridWrap>
              </BelowTheFold>
            </PageContentWrapper>
            <NavigationButtons
              handleStartConfigurationClick={handleTimelineSkipWithTracking}
              handleGoBack={handleGoBack}
              handleGoNext={() => {}}
              isFirstStep={false}
              isLastStep={true}
            ></NavigationButtons>
            <PortraitFooter
              displayAtScreenMaxWidth={BreakpointWidthPx.Tablet}
            />
          </>
        )}
      </ScrollContent>
    </OptionalsRoot>
  )
}

export default OptionalsPage

export interface LocalSpecialGoQuestion extends GoQuestion {
  isScalar?: boolean
}
