import styled from 'styled-components'
import useTexts from '../../utils/useTexts'
import { useState } from 'react'
import { TestElementTypeId } from '../../types/TestAttributeId'
import {
  TdsAccordion,
  TdsAccordionItem,
  TdsButton,
  TdsCheckbox,
  TdsModal,
} from '@scania/tegel-react'
import { TdsCheckboxCustomEvent } from '@scania/tegel'

// Original: p .cookie-config-message
const CookieConfigMessage = styled.div`
  font-size: 14px;
  margin-top: 8px;
  margin-bottom: 24px;
  line-height: 22px;
`

// Original: p .cookie-category-description
const CookieCategoryDescription = styled.p`
  font-size: 12px;
`

const ExternalCookieLink = styled.div`
  display: inline-flex;
  top: 2px;
  position: relative;

  // The svg seems to need this offset to line up with the text.i con
  //margin-top: 1px;

  & > a {
    color: rgb(83, 86, 90);
  }
  @media (hover: hover) and (pointer: fine) {
    & > a:hover {
      color: #041e42;
    }
  }
`

export interface CookieCategoryChoices {
  allowPerformanceCookies: boolean
  allowFunctionalCookies: boolean
}

export interface ModalCookieConfiguratorProps {
  onAcceptAllCookies: VoidFunction
  onSaveCookieSettings: (choices: CookieCategoryChoices) => void
}

export const ModalCookieConfigurator = (
  props: ModalCookieConfiguratorProps,
) => {
  const t = useTexts()

  // Default must be false (SC1M-2207).
  const [performanceCookiesChecked, setPerformanceCookiesChecked] =
    useState(false)

  // Default must be false (SC1M-2207).
  const [functionalCookiesChecked, setFunctionalCookiesChecked] =
    useState(false)

  const saveCookieSettings = () => {
    props.onSaveCookieSettings({
      allowPerformanceCookies: performanceCookiesChecked,
      allowFunctionalCookies: functionalCookiesChecked,
    })
  }

  const handlePerformanceCookiesChange = (
    ev: TdsCheckboxCustomEvent<{
      checkboxId: string
      checked: boolean
      indeterminate: boolean
      value?: string | undefined
    }>,
  ) => {
    const checked = ev.detail.checked
    setPerformanceCookiesChecked(checked)
  }

  const handleFunctionalCookiesChange = (
    ev: TdsCheckboxCustomEvent<{
      checkboxId: string
      checked: boolean
      indeterminate: boolean
      value?: string | undefined
    }>,
  ) => {
    const checked = ev.detail.checked
    setFunctionalCookiesChecked(checked)
  }

  return (
    <TdsModal size="md" header={t('COOKIE_CONFIG_PRIVACY_SETTINGS')} show>
      <span slot="body">
        <CookieConfigMessage>
          {t('COOKIE_CONFIG_MESSAGE')}
          &nbsp;
          <ExternalCookieLink>
            <a
              href={t('COOKIE_INFORMATION_LINK')}
              target="_blank"
              rel="noreferrer"
              style={{ height: '16px' }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-external-link"
              >
                <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                <polyline points="15 3 21 3 21 9"></polyline>
                <line x1="10" y1="14" x2="21" y2="3"></line>
              </svg>
            </a>
          </ExternalCookieLink>
        </CookieConfigMessage>
        <TdsAccordion>
          <TdsAccordionItem header={t('COOKIE_CONFIG_CUSTOMIZE_SETTINGS')}>
            <TdsCheckbox
              name="necessaryCookies"
              value="necessaryCookies"
              checked
              disabled
            >
              <span slot="label" style={{ fontWeight: 'bold' }}>
                {t('COOKIE_CONFIG_NECESSARY_NAME')}
              </span>
            </TdsCheckbox>
            <CookieCategoryDescription>
              {t('COOKIE_CONFIG_NECESSARY_DESCRIPTION')}
            </CookieCategoryDescription>
            <TdsCheckbox
              name="performanceCookies"
              value="performanceCookies"
              checked={performanceCookiesChecked}
              onTdsChange={handlePerformanceCookiesChange}
            >
              <span slot="label" style={{ fontWeight: 'bold' }}>
                {t('COOKIE_CONFIG_PERFORMANCE_NAME')}
              </span>
            </TdsCheckbox>
            <CookieCategoryDescription>
              {t('COOKIE_CONFIG_PERFORMANCE_DESCRIPTION')}
            </CookieCategoryDescription>
            <TdsCheckbox
              name="functionalCookies"
              value="functionalCookies"
              checked={functionalCookiesChecked}
              onTdsChange={handleFunctionalCookiesChange}
            >
              <span slot="label" style={{ fontWeight: 'bold' }}>
                {t('COOKIE_CONFIG_FUNCTIONAL_NAME')}
              </span>
            </TdsCheckbox>
            <CookieCategoryDescription>
              {t('COOKIE_CONFIG_FUNCTIONAL_DESCRIPTION')}
            </CookieCategoryDescription>
            <TdsButton
              variant="secondary"
              size="md"
              text={t('COOKIE_CONFIG_SAVE_SETTINGS')}
              onClick={saveCookieSettings}
              style={{ marginTop: '16px' }}
            />
          </TdsAccordionItem>
        </TdsAccordion>
      </span>

      <span slot="actions" className="tds-u-flex tds-u-gap2 tds-u-flex-wrap">
        <TdsButton
          variant="primary"
          size="md"
          text={t('COOKIE_CONFIG_ACCEPT_ALL')}
          onClick={props.onAcceptAllCookies}
          data-test-element-type={TestElementTypeId.CookieAcceptAllButton}
        />
      </span>
    </TdsModal>
  )
}
