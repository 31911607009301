/**
  TODO: Remove this component when the Application page has been given Tegel
  styling.
  
  A simplified version of the original ScaniaButton.jsx.

  This button is supposed to have the same appearance as the ScaniaButton did on
  the Application page, with a few small tweaks for long texts to allow two rows
  of texts when needed (to begin with, SC1M-1713).
 */

import React from 'react'
import { MouseEventHandler } from 'react'
import styled, { css } from 'styled-components'

const glassboxMixin = css`
  --icon-border: 1px solid rgba(255, 255, 255, 0.4);
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: #141517;
  color: ${({ theme }) => theme.scDarkBlue};
  text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.2);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  font-family: 'Scania Sans Bold';
  text-decoration: none;
  text-align: left;
  padding-left: 3.3em;
  user-select: none;
  cursor: pointer;
  background: linear-gradient(
    150deg,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(255, 255, 255, 0.1) 50%
  );
  & > div.icon {
    border-right: var(--icon-border);
    background: hsla(207, 13%, 86%, 1);
    box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.8);
    transform: scale(1);
    svg {
      transform: scale(1);

      // TODO: Revisit and try to eliminate.
      z-index: 1;
    }
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: -5.6px;
      transform: translateY(-50%) rotate(45deg);
      width: 10px;
      height: 10px;
      background: hsla(207, 13%, 86%, 1);
      border-top: var(--icon-border);
      border-right: var(--icon-border);
      box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.2);
    }
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background: hsla(207, 13%, 96%, 1);
        svg {
          transform: scale(1.1);
        }
        &:after {
          background: hsla(207, 13%, 96%, 1);
        }
      }
    }
  }
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      border: 1px solid rgba(255, 255, 255, 0.8);
      outline: 1px solid rgba(0, 0, 0, 0.1);
      background: linear-gradient(
        150deg,
        rgba(255, 255, 255, 0.5) 0%,
        rgba(255, 255, 255, 0.1) 70%
      );
    }
  }
`

const SimpleScaniaButtonStyledComponent = styled.div`
  & {
    display: flex;
    align-items: center;
    position: relative;
    min-height: 2.43em;
    user-select: none;
    cursor: default;
    padding: 0 12px 0 3.3em;
    min-width: 12.5em;

    // Removed this constraint to allow the buttons on the Operations page to
    // fill the width on portrait mobile screens.
    // TODO: Revisit later if needed.
    //max-width: 18.75em;

    overflow: hidden;
    font-size: 1em;
    line-height: 1.15em;
    text-align: center;
    box-shadow: 3px 1px 5px rgba(0, 0, 0, 0.1), -3px 1px 5px rgba(0, 0, 0, 0.1);
    font-style: 'normal';
    font-family: 'Scania Sans Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    ${glassboxMixin}
    div {
      display: flex;
      align-items: center;
    }
    .icon {
      position: absolute;
      top: 0;
      width: 2.43em;
      left: 0;
      background: rgba(255, 255, 255, 0.1);
      height: 100%;
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`
SimpleScaniaButtonStyledComponent.displayName =
  'SimpleScaniaButtonStyledComponent'

export interface SimpleScaniaButtonProps
  extends React.HtmlHTMLAttributes<HTMLDivElement> {
  handleIconClick: MouseEventHandler<HTMLDivElement>
  icon: JSX.Element
}

export const SimpleScaniaButton = ({
  children,
  className,
  handleIconClick,
  icon,
  ...rest
}: SimpleScaniaButtonProps) => {
  return (
    <SimpleScaniaButtonStyledComponent
      className={'simple-scbtn'}
      title={(typeof children === 'string' && children) || ''}
      {...rest}
    >
      {typeof children === 'string' ? children.toUpperCase() : children}
      {icon && (
        <div className="icon" onClick={handleIconClick}>
          {icon}
        </div>
      )}
    </SimpleScaniaButtonStyledComponent>
  )
}
