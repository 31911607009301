// A pure View component that needs a wrapper component that acts Controller
// that push data/Model and handles events/callbacks.
//
// The point of this is to allow both Factory Models (cached static
// configurations) and normal dynamic build mode configurations to be rendered
// by this pure view component.
//
// IMPORTANT:
// Make sure to position and size the main image using whole pixels, never use
// any units other than 'px' for the position and size of the main image and its
// parent elements.
//
// Not in use since SC1M-1983, but this component is intended to be used as the
// summary view in the "New Build Mode", the code is already decoupled from Firm
// Models and should work fine for the new build mode as well.

import { useCallback, useEffect, useState } from 'react'
import { X } from 'react-feather'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { FlexImageParameters } from '../../api/generated'
import { useLayoutViewportSize } from '../../utils/resizeHook'
import { DynamicImage } from './DynamicImage'
import { Size2d } from '../../types/Size2d'
import { BreakpointWidthPx } from '../../css/BreakpointWidthPx'

const SummaryViewRoot = styled.div`
  background-color: ${({ theme }) => theme.scWhite};
`

interface TruckImageSliderProps {
  /**
   * IMPORTANT: We must calculate this as whole pixels to avoid resampling the
   * child image and causing blurryness.
   */
  $heightPx: number
}

const TruckImageSliderRoot = styled.div<TruckImageSliderProps>`
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;

  // TODO: Probably needs tweaking for mobile portrait mode.
  height: ${(props) => props.$heightPx + 'px'};

  margin-bottom: 20px;

  // Original, quite dark:
  //background: radial-gradient(
  //  51% 22% at 50% 94%,
  //  hsl(0, 0%, 38%) 58%,
  //  hsl(0, 0%, 0%) 158%
  //);

  // More light:
  background: radial-gradient(
    51% 22% at 50% 94%,
    hsl(0deg 0% 72%) 58%,
    hsl(0deg 0% 47%) 111%,
    hsl(0deg 0% 0%) 393%
  );
`

const TruckImageSlider = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 8px 0px 8px 0px;

  @media screen and (min-width: ${BreakpointWidthPx.Tablet}px) {
    padding: 32px 32px 32px 32px;
  }
`

const TruckInfoContainer = styled.div`
  width: min(80%, 1200px);
  margin: auto;
`

const TruckInfo = styled.div`
  color: ${({ theme }) => theme.scGreyDark};
`

const PageTitle = styled.div`
  border-bottom: thin solid ${({ theme }) => theme.scGreyLight};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  padding-bottom: 10px;
  margin-bottom: 10px;

  h1 {
    color: ${({ theme }) => theme.scDarkBlue};
  }
`

const Specifications = styled.div`
  display: flex;
  border-bottom: solid thin #ccc;
  width: 100%;
  flex-wrap: wrap;

  p:first-letter {
    text-transform: uppercase;
  }

  @media screen and (min-width: ${BreakpointWidthPx.Tablet}px) {
    flex-wrap: nowrap;
  }
`

const SpecificationSectionText = styled.h2`
  margin-top: 20px;
  margin-bottom: 10px;
`

const SpecificationsText = styled.div`
  margin-right: 10px;
  width: 100%;
  padding-right: 10px;
  padding-top: 5px;

  p {
    margin: 0.2em 0;
    font-weight: 600;
  }

  @media screen and (min-width: ${BreakpointWidthPx.Tablet}px) {
    border-right: thin solid #ccc;
    width: 40%;

    p {
      margin: 0.5em 0;
      font-weight: 200;
    }
  }
`

const SpecificationsDescription = styled.div`
  width: 100%;
  padding-bottom: 5px;
  p {
    margin: 0.2em 0;
  }

  @media screen and (min-width: ${BreakpointWidthPx.Tablet}px) {
    width: 60%;

    p {
      margin: 0.5em 0;
    }
  }
`

const Images = styled.div`
  //width: 200px;
  //height: 200px;
  background-color: #ccc;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  max-width: 100%;

  @media screen and (min-width: ${BreakpointWidthPx.Desktop}px) {
    margin-right: 0px;
    max-width: 100% !important;
  }

  @media screen and (min-width: ${BreakpointWidthPx.Phone}px) {
    max-width: 48%;
  }
`

const SmallImage = styled.img`
  max-width: 100%;
`

const SpecificationsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

const SpecTextContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;

  @media screen and (min-width: ${BreakpointWidthPx.Desktop}px) {
    width: 80%;
  }
`

const ImageContainer = styled.div`
  width: 100%;

  @media screen and (min-width: ${BreakpointWidthPx.Desktop}px) {
    width: 19%;
  }
`

const GoToNextButtonAbsoluteWrapper = styled.div`
  position: absolute;
  bottom: 8px;
  right: unset;

  // Larger than phone, TODO: refactor breakpoints to match SDS.
  @media screen and (min-width: ${BreakpointWidthPx.Phone}px) {
    right: 8px;
  }
`

const CrossIconWrapper = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 16px;
  color: white;
  cursor: pointer;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      filter: brightness(0.8);
    }
  }

  &:active {
    filter: unset;
  }
`

const GalleryContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;

  @media screen and (min-width: ${BreakpointWidthPx.Desktop}px) {
    flex-direction: column;
  }
`

const drawPageTitle = (truckName: string) => {
  return (
    <PageTitle>
      <h1>{truckName}</h1>
    </PageTitle>
  )
}

const drawTruckSpecifications = (groups: SummaryViewGroup[]) => {
  const fragments: JSX.Element[] = []
  for (const g of groups) {
    fragments.push(
      <SpecificationSectionText key={g.id}>{g.name}</SpecificationSectionText>,
    )
    for (const item of g.items) {
      fragments.push(
        <Specifications key={item.itemId}>
          <SpecificationsText>
            <p>{item.groupShortText}</p>
          </SpecificationsText>
          <SpecificationsDescription>
            <p>{item.itemShortText}</p>
          </SpecificationsDescription>
        </Specifications>,
      )
    }
  }

  return fragments
}

const drawGallery = (imageUrls: Array<string | null>) => {
  const fragments: JSX.Element[] = []

  for (let i = 0; i < imageUrls.length; i++) {
    const url = imageUrls[i]
    const img = url ? (
      <SmallImage alt={'Small truck #' + i} src={url} />
    ) : (
      <span>{i}</span>
    )
    fragments.push(<Images key={'small_image_' + i}>{img}</Images>)
  }

  return fragments
}

const drawTruckInfo = (
  specification: SummaryViewGroup[],
  imageUrls: Array<string | null>,
) => {
  return (
    <TruckInfo>
      <SpecificationsContainer>
        <SpecTextContainer>
          {drawTruckSpecifications(specification)}
        </SpecTextContainer>
        <ImageContainer>
          <p>Gallery</p>
          <GalleryContainer>{drawGallery(imageUrls)}</GalleryContainer>
        </ImageContainer>
      </SpecificationsContainer>
    </TruckInfo>
  )
}

export interface SummaryViewProps {
  readonly buildImage: (
    flexParams: FlexImageParameters,
  ) => Promise<string | null>
  readonly truckName: string
  readonly groups: Array<SummaryViewGroup>
  readonly goToNextButton: JSX.Element
}

export interface SummaryViewGroup {
  readonly id: string
  readonly name: string
  readonly items: Array<SummaryViewItem>
}

export interface SummaryViewItem {
  readonly itemId: string
  readonly groupShortText: string
  readonly itemShortText: string
  readonly itemIconUrl?: string
}

export function SummaryView({
  buildImage,
  truckName,
  groups,
  goToNextButton,
}: SummaryViewProps) {
  const [smallImageUrls, setSmallImageUrls] = useState<Array<string | null>>([
    null,
    null,
    null,
    null,
    null,
  ])
  const navigate = useNavigate()

  // Needed since CSS rules like 60% or 60vh can't (???) be rounded to whole
  // pixels and we must use only whole pixels for position and size of the main
  // image to avoid blurryness.
  const { viewportHeight } = useLayoutViewportSize()

  const buildMainSummaryImage = useCallback(
    (size: Size2d) => {
      const flexParams: FlexImageParameters = {
        series: 'EXTERIOR',
        frame: 2,
        bgColor: '#00000000',
        imageType: 'png',
        autoCrop: ['top', 'bottom', 'left', 'right'],
        align: [0.5, 0.5],
        margin: [0, 0, 0, 0],
        size: [
          Math.round(size.width * window.devicePixelRatio),
          Math.round(size.height * window.devicePixelRatio),
        ],
        useBoundingBoxes: false,
      }
      return buildImage(flexParams)
    },
    [buildImage],
  )

  useEffect(() => {
    const asyncWrapper = async () => {
      const buildSmallImage = (series: string, frame: number) => {
        const image_size = Math.round(220 * window.devicePixelRatio)
        const flexParams: FlexImageParameters = {
          series,
          frame,
          bgColor: '#00000000',
          imageType: 'png',
          autoCrop: ['top', 'bottom', 'left', 'right'],
          align: [0.5, 0.5],
          margin: [0, 0, 0, 0],
          size: [image_size, image_size],
          useBoundingBoxes: false,
        }
        return buildImage(flexParams)
      }
      // TODO: Cange to getFactoryModelImage for INTERIOR series to avoid
      // causing padding and to get appropriate cropping.
      const imageResults = await Promise.allSettled([
        buildSmallImage('EXTERIOR', 1),
        buildSmallImage('EXTERIOR', 2),
        buildSmallImage('EXTERIOR', 3),
        buildSmallImage('INTERIOR', 1),
        buildSmallImage('INTERIOR', 2),
      ])
      const newSmallUrls = imageResults.map((r) => {
        return r.status === 'fulfilled' ? r.value : null
      })
      setSmallImageUrls(newSmallUrls)
    }
    asyncWrapper()
  }, [buildImage])

  const getTruckImageSliderHeightPx = (): number => {
    // IMPORTANT: We must calculate this as whole pixels to avoid resampling the
    // child image and causing blurryness.
    return Math.min(Math.round(viewportHeight * 0.6), 600)
  }

  return (
    <SummaryViewRoot>
      <TruckImageSliderRoot $heightPx={getTruckImageSliderHeightPx()}>
        <TruckImageSlider>
          <DynamicImage
            buildImage={buildMainSummaryImage}
            updateDelayMs={500}
            alt="Truck"
          />
        </TruckImageSlider>
        <CrossIconWrapper
          onClick={() => {
            navigate(-1)
          }}
        >
          <X />
        </CrossIconWrapper>
        <GoToNextButtonAbsoluteWrapper>
          {goToNextButton}
        </GoToNextButtonAbsoluteWrapper>
      </TruckImageSliderRoot>
      <TruckInfoContainer>
        {drawPageTitle(truckName)}
        {drawTruckInfo(groups, smallImageUrls)}
      </TruckInfoContainer>
    </SummaryViewRoot>
  )
}
