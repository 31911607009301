// The configurator menu for the Build Mode/step 2/main configurator view.

import styled from 'styled-components'
import { SvgCross, SvgSearch, SvgTruck } from '../../../components/SvgImports'
import { CustomScrollbarMixin } from '../../../css/CustomScrollbarMixin'
import { SubMenu } from './Submenu'
import { ScaniaSearchResult } from '../../../api/generated'
import { useClient } from '../../../utils/useClient'
import { useCallback, useState } from 'react'
import useTexts from '../../../utils/useTexts'
import { SaveMenu } from '../SaveMenu'
import { ShareMenu } from '../ShareMenu'
import { SESSION_FAILURE } from '../../../api/errors'
import { TestElementTypeId } from '../../../types/TestAttributeId'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import {
  ScaniaAdobeEventId,
  ScaniaAdobeTrackingButtonPlacement,
  ScaniaAdobeTrackingSearchResultClick,
  pushAdobeEvent,
} from '../../../utils/adobeAnalytics'
import { BreakpointWidthPx } from '../../../css/BreakpointWidthPx'
import { closeMainMenu, getMenuState } from '../../../store/menuSlice'
import { getSessionInitDataState } from '../../../store/sessionDataSlice'
import {
  getMarketLanguageState,
  getMarketSettingsState,
} from '../../../store/appSlice'
import { setModalSaveAsGarageState } from '../../../store/modalSlice'
import React from 'react'

export const MENU_ROOT_ID = '/'

const ConfiguratorMenuRoot = styled.div`
  ${CustomScrollbarMixin}
  width: var(--configurator-menu-width);
  height: 100%;
  position: relative;
  display: flex;
  flex-flow: column;
  overflow-y: auto;
  overflow-x: hidden;
`

interface ItemWrapperProps {
  $isHiddenOnDesktop: boolean
}

const ItemWrapper = styled.div<ItemWrapperProps>`
  height: 68px;
  align-items: center;
  position: relative;
  display: ${(props) => (props.$isHiddenOnDesktop ? 'none' : 'flex')};
  border-bottom: 1px solid var(--tds-grey-300);

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: var(--tds-grey-50);
      cursor: pointer;
    }
  }

  @media screen and (max-width: ${BreakpointWidthPx.Tablet}px) {
    display: flex;
  }
`

const IconWrapper = styled.div`
  width: 68px;
  height: 100%;
  display: flex;
  align-items: center;

  // TODO: Revisit and try to eliminate.
  z-index: 5;

  svg {
    height: 20px;
    width: 20px;
    margin-left: 24px;
    margin-right: 24px;
  }
`

const MenuItem = styled.div`
  height: 68px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  font-weight: bold;
  text-overflow: ellipsis;
`

const ItemWrapperSearch = styled.div<ItemWrapperProps>`
  height: 68px;
  width: 100%;
  align-items: center;
  position: relative;
  display: ${(props) => (props.$isHiddenOnDesktop ? 'none' : 'flex')};
  border-bottom: 1px solid var(--tds-grey-400);

  @media screen and (max-width: ${BreakpointWidthPx.Tablet}px) {
    display: flex;
  }
`

const SearchInput = styled.input`
  height: 68px;
  width: 174px;
  border: none;
  font-size: 14px;
  background-color: transparent;

  :focus {
    outline: none;
    border-bottom: 2px var(--tds-blue-300) solid;
  }
`

interface IconWrapperSearchCloseProps {
  $showClose: boolean
}

const IconWrapperSearchClose = styled.div<IconWrapperSearchCloseProps>`
  width: 68px;
  height: 100%;
  display: flex;
  align-items: center;

  // TODO: Revisit and try to eliminate.
  z-index: 5;

  display: ${(props) => (props.$showClose ? 'flex' : 'none')};

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      cursor: pointer;
    }
  }

  svg {
    height: 20px;
    width: 20px;
    margin-left: 24px;
    margin-right: 24px;
  }
`

const IconWrapperSearch = styled.div`
  width: 56px;
  height: 100%;
  display: flex;
  align-items: center;

  // TODO: Revisit and try to eliminate.
  z-index: 5;

  svg {
    height: 20px;
    width: 20px;
    margin-left: 24px;
    margin-right: 12px;
  }
`

interface SearchResultContainerProps {
  $showSearchResult: boolean
}

const SearchResultContainer = styled.div<SearchResultContainerProps>`
  display: flex;
  flex: 1;
  display: ${(props) => (props.$showSearchResult ? 'flex' : 'none')};
  width: 100%;
`

const SearchResultList = styled.ul`
  width: 100%;
  padding: 0;
  list-style-type: none;
`

const SearchResultItem = styled.li`
  // To match the main menu icon left margin, should probably be 24px but looks
  // like 25.
  padding-left: 25px;

  width: 100%;
  font-weight: bold;
  padding-top: 8px;
  cursor: pointer;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: var(--tds-grey-700);
    }
  }
`

const NoSearchResult = styled.div<SearchResultContainerProps>`
  display: ${(props) => (props.$showSearchResult ? 'flex' : 'none')};
  font-style: italic;
  padding: 16px;
`

const SubMenuWrapper = styled.div``

const DividerToSmallItems = styled.div<SearchResultContainerProps>`
  height: 32px;
  min-height: 32px;
  display: ${(props) => (props.$showSearchResult ? 'none' : 'flex')};
`

const SmallItemWrapper = styled.div`
  border-bottom: 1px solid var(--tds-grey-300);

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: var(--tds-grey-50);
      cursor: pointer;
    }
  }
`

interface SelectedIndicatorProps {
  $openMenu: boolean
}

const SelectedIndicator = styled.div<SelectedIndicatorProps>`
  width: 8px;
  height: 100%;
  float: left;

  // TODO: Revisit and try to eliminate.
  z-index: 5;

  background-color: ${(props) =>
    props.$openMenu ? 'var(--tds-blue-400)' : ''};
`

interface SmallItemProps {
  $showSearchResult: boolean
  $openMenu: boolean
}

const SmallItem = styled.div<SmallItemProps>`
  height: 58px;
  min-height: 58px;
  align-items: center;
  position: relative;
  padding-left: 16px;
  display: ${(props) => (props.$showSearchResult ? 'none' : 'flex')};
  font-weight: ${(props) => (props.$openMenu ? 'bold' : '')};
  background-color: ${(props) => (props.$openMenu ? 'var(--tds-grey-50)' : '')};
  text-overflow: ellipsis;
`

const FillTheSpace = styled.div<SearchResultContainerProps>`
  height: 100%;
  flex: 1;
  display: ${(props) => (props.$showSearchResult ? 'none' : 'flex')};
`

/**
 * A node in the currently visible menu tree.
 */
export interface VisibleMenuEntry {
  childIds: string[] | null
  hasReadmore: boolean
  id: string
  isOpen: boolean
  parentId: string | null
  text: string
}

/**
 * An item that is selected or can be selected inside one of
 * deepest/outmost/leaf menus.
 */
export interface LeafMenuEntry {
  hasReadmore: boolean
  id: string
  isSelected: boolean
  text: string
}

export interface ConfiguratorMenuProps {
  handleItemSelection: (
    id: string,
    placement: ScaniaAdobeTrackingButtonPlacement,
  ) => void
  handleMainMenuCloseToRoot: () => void
  handleMenuSelection: (id: string) => void
  handleNewClick: () => void
  handleSaveAsGarageClick: (e: React.MouseEvent) => void
  handleSaveItemClick: () => void
  handleSearchClick: (id: string, path: string) => void
  handleSessionFailure: () => void
  handleShareItemClick: () => void
  highlightedId: string | null
}

export function ConfiguratorMenu(props: ConfiguratorMenuProps): JSX.Element {
  const t = useTexts()
  const client = useClient()
  const sessionInitData = useAppSelector(getSessionInitDataState)
  const marketLanguage = useAppSelector(getMarketLanguageState)
  const dispatch = useAppDispatch()
  const { menuSaveMainMenuIsOpen, menuShareMainMenuIsOpen, visibleMenuState } =
    useAppSelector(getMenuState)
  const rootNode = visibleMenuState?.menuNodes[MENU_ROOT_ID]
  const marketSettings = useAppSelector(getMarketSettingsState)
  const showShareIcon =
    marketSettings?.shareEmailEnabled ||
    marketSettings?.shareFacebookEnabled ||
    marketSettings?.shareLinkEnabled ||
    marketSettings?.shareWhatsappEnabled

  const [searchString, setSearchString] = useState<string>('')
  const [searchResult, setSearchResult] = useState<ScaniaSearchResult[] | null>(
    null,
  )
  let searchLenMin = 2

  const handleSearch = useCallback(
    async (event: any) => {
      if (!sessionInitData) {
        throw new Error('Expected sessionInitData to be defined.')
      }
      if (!marketLanguage) {
        throw new Error('Expected marketLanguage to be defined.')
      }
      if (!client) {
        throw new Error('Expected client to be defined.')
      }

      const typedSearchString = event.target.value
      setSearchString(typedSearchString)

      // Allow lenght of 1 for asian languages
      // TODO - from old Gui. Check if it is working and how
      //if (/[\u3400-\u9FBF]/.test(typedSearchString)) {
      //  searchLenMin = 1
      //}

      if (typedSearchString && typedSearchString.length >= searchLenMin) {
        let searchResultTyped
        try {
          const response = await client.scaniaSearch(
            sessionInitData.sessionId,
            typedSearchString,
            marketLanguage.market,
            marketLanguage.language,
          )
          if (response.error === SESSION_FAILURE) {
            props.handleSessionFailure()
            return
          }

          // TODO: Investigate if null or undefined in the response is an error
          // or not.
          searchResultTyped = response.scaniaSearch || null
        } catch {
          console.log('Search went wrong.')
          return
        }
        setSearchResult(searchResultTyped)
      } else {
        setSearchResult(null)
      }
    },
    [client, marketLanguage, sessionInitData, searchLenMin, props],
  )

  const handleSearchClose = () => {
    setSearchString('')
    setSearchResult(null)
  }

  const handleSearchResultClick = (
    clickedResult: ScaniaSearchResult,
    resultCount: number,
  ) => {
    const trackingEvent: ScaniaAdobeTrackingSearchResultClick = {
      event: ScaniaAdobeEventId.SearchResultClick,
      eventInfo: {
        searchTerm: searchString,
        searchResultCount: resultCount,
        searchResultClickTitle: clickedResult.description,
      },
    }
    pushAdobeEvent(trackingEvent)
    props.handleSearchClick(clickedResult.id, clickedResult.path)
    setSearchString('')
    setSearchResult(null)
  }

  if (!rootNode) {
    throw new Error('Expected to find the root menu node.')
  }

  const handleSaveAsGarageClickWithPlacement = (e: React.MouseEvent) => {
    dispatch(
      setModalSaveAsGarageState({
        source: ScaniaAdobeTrackingButtonPlacement.LeftMenu,
      }),
    )
    props.handleSaveAsGarageClick(e)
  }

  return (
    <ConfiguratorMenuRoot data-name="MainMenu">
      <ItemWrapper
        onClick={() => dispatch(closeMainMenu())}
        $isHiddenOnDesktop={true}
      >
        <IconWrapper>
          <SvgCross />
        </IconWrapper>
        <MenuItem data-name="Close"></MenuItem>
      </ItemWrapper>
      <ItemWrapper $isHiddenOnDesktop={false} onClick={props.handleNewClick}>
        <IconWrapper>
          <SvgTruck />
        </IconWrapper>
        <MenuItem data-name="Home">{t('LABEL_ACTION_NEW')}</MenuItem>
      </ItemWrapper>
      <ItemWrapperSearch $isHiddenOnDesktop={false}>
        <IconWrapperSearch>
          <SvgSearch />
        </IconWrapperSearch>
        <SearchInput
          type="text"
          placeholder={t('SEARCH')}
          value={searchString}
          onInput={handleSearch}
        ></SearchInput>
        <IconWrapperSearchClose
          $showClose={searchString ? true : false}
          onClick={handleSearchClose}
        >
          <SvgCross />
        </IconWrapperSearchClose>
      </ItemWrapperSearch>
      <SearchResultContainer
        $showSearchResult={
          searchResult && searchResult.length !== 0 ? true : false
        }
      >
        <SearchResultList>
          {searchResult?.map((text) => {
            return (
              <SearchResultItem
                key={text.id}
                onClick={() =>
                  handleSearchResultClick(text, searchResult.length)
                }
              >
                {text.description}
              </SearchResultItem>
            )
          })}
        </SearchResultList>
      </SearchResultContainer>
      <NoSearchResult
        $showSearchResult={
          searchResult && searchResult.length === 0 ? true : false
        }
      >
        {t('SEARCH_NO_HITS')}
      </NoSearchResult>
      <NoSearchResult
        $showSearchResult={
          searchString && searchString.length <= searchLenMin ? true : false
        }
      >
        {t('SEARCH_MIN_LENGTH')}
      </NoSearchResult>
      <SubMenuWrapper>
        {rootNode.childIds?.map((id) => {
          const node = visibleMenuState.menuNodes[id]
          if (!node) {
            console.log('Expected to find a menu node with id: ' + id)
            return <React.Fragment key={id}></React.Fragment>
          }
          if (!searchString) {
            return (
              <SubMenu
                handleMenuSelection={props.handleMenuSelection}
                handleItemSelection={props.handleItemSelection}
                key={node.id}
                menuLevel={1}
                node={node}
                highlightedId={props.highlightedId}
              />
            )
          }
          return <React.Fragment key={id}></React.Fragment>
        })}
      </SubMenuWrapper>
      <DividerToSmallItems $showSearchResult={searchString ? true : false} />
      {showShareIcon && (
        <SmallItemWrapper
          data-test-element-type={
            TestElementTypeId.ConfigurationMenuShareButton
          }
        >
          <SelectedIndicator $openMenu={menuShareMainMenuIsOpen} />
          <SmallItem
            $showSearchResult={searchString ? true : false}
            $openMenu={menuShareMainMenuIsOpen}
            onClick={props.handleShareItemClick}
          >
            {t('LABEL_ACTION_SHARE')}
          </SmallItem>
        </SmallItemWrapper>
      )}
      {menuShareMainMenuIsOpen && (
        <ShareMenu source={ScaniaAdobeTrackingButtonPlacement.LeftMenu} />
      )}

      <SmallItemWrapper
        data-test-element-type={TestElementTypeId.ConfigurationMenuSaveMenu}
      >
        <SelectedIndicator $openMenu={menuSaveMainMenuIsOpen} />
        <SmallItem
          $showSearchResult={searchString ? true : false}
          $openMenu={menuSaveMainMenuIsOpen}
          onClick={props.handleSaveItemClick}
        >
          {t('LABEL_ACTION_SAVE')}
        </SmallItem>
      </SmallItemWrapper>
      {menuSaveMainMenuIsOpen && (
        <SaveMenu
          handleSaveAsGarageClick={handleSaveAsGarageClickWithPlacement}
          source={ScaniaAdobeTrackingButtonPlacement.LeftMenu}
        />
      )}
      <FillTheSpace $showSearchResult={searchString ? true : false} />
    </ConfiguratorMenuRoot>
  )
}
