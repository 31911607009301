/**
 * A custom hook similar to useEffect
 * except it doesn't run on mount.
 */
import { useRef, useEffect } from 'react'

function useDidUpdateEffect(fn, inputs) {
  const didMountRef = useRef(false)

  useEffect(() => {
    if (didMountRef.current) fn()
    else didMountRef.current = true
    // eslint-disable-next-line
  }, [...inputs, fn])
}

export default useDidUpdateEffect
