import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Dealer, RfqContactRole, RfqMode, RfqParams } from '../../api/generated'
import { useClient } from '../../utils/useClient'
import { TextId, useTextsV2 } from '../../utils/useTexts'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { getUrlCidValue } from '../../utils/getUrlCidParameter'
import {
  getMarketLanguageState,
  getMarketSettingsState,
} from '../../store/appSlice'
import {
  getRfqInput,
  getSessionInitDataState,
  getStartupData,
  setRfqInput,
} from '../../store/sessionDataSlice'
import { closeAllModals } from '../../store/modalSlice'
import {
  TdsTextField,
  TdsTextarea,
  TdsModal,
  TdsButton,
  TdsCheckbox,
  TdsDropdown,
  TdsDropdownOption,
} from '@scania/tegel-react'
import { TdsCheckboxCustomEvent } from '@scania/tegel'

const InfoText = styled.div`
  padding-top: 16px;
  padding-bottom: 24px;
`

const SmallHeader = styled.h5`
  margin-top: 24px;
`

const LabelCompulsory = styled.div`
  display: inline-block;
  color: red;
  margin-left: 6px;
  font-size: 16px;
  font-weight: bold;
`

const LabelInputField = styled.div`
  margin-top: 16px;
  margin-bottom: 8px;
  display: inline-block;
  font-size: 14px;
`

const DealerTextBox = styled.div`
  border: 1px solid var(--tds-grey-300);
  margin-top: 16px;
`

const DealerTextBoxHeader = styled.h5`
  margin-left: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
`

const DealerTextBoxAddress = styled.div`
  font-size: 11px;
  margin-left: 16px;
`

const DealerTextBoxPhone = styled.div`
  font-size: 11px;
  margin-top: 16px;
  margin-left: 16px;
`

const DealerTextBoxWeb = styled.div`
  font-size: 11px;
  margin-top: 16px;
  margin-left: 16px;
  margin-bottom: 16px;
`

const Divider = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  border-bottom: 1.5px solid var(--tds-grey-100);
`

const CheckboxSmallText = styled.p`
  font-size: 10px;
  font-weight: normal;
  line-height: normal;
  color: var(--tds-checkbox-color);
`

const CheckboxSmallTextWrapper = styled.div`
  margin-left: 24px;
`

const ErrorMessage = styled.div`
  color: red;
  margin-bottom: 12px;
  margin-top: 12px;
  font-size: 14px;
  line-height: normal;
`

const DemoModeMessage = styled.div`
  margin-top: 20px;
  margin-bottom: 16px;
  color: red;
`

interface ModalRequestAQuoteProps {
  handleRfqSendClick: (rfq: RfqParams) => void
}

export function ModalRequestAQuote({
  handleRfqSendClick,
}: ModalRequestAQuoteProps): JSX.Element {
  const t = useTextsV2()
  const client = useClient()
  const dispatch = useAppDispatch()
  const marketLanguage = useAppSelector(getMarketLanguageState)
  const marketSettings = useAppSelector(getMarketSettingsState)
  const startupData = useAppSelector(getStartupData)
  const sessionInitData = useAppSelector(getSessionInitDataState)
  const phoneNumberInstructions = t(TextId.PHONE_NUMBER_INSTRUCTIONS)
  const previousRfqInput = useAppSelector(getRfqInput)

  const [dealers, setDealers] = useState<Dealer[] | null>(null)
  const [enteredEmail, setEnteredEmail] = useState<string>(
    previousRfqInput?.contactEmail || '',
  )
  const [enteredFirstName, setEnteredFirstName] = useState<string>(
    previousRfqInput?.contactFirstName || '',
  )
  const [enteredSurname, setEnteredSurname] = useState<string>(
    previousRfqInput?.contactFamilyName || '',
  )
  const [enteredTelephone, setEnteredTelephone] = useState<string>(
    previousRfqInput?.contactPhone || '',
  )
  const [enteredCompanyName, setEnteredCompanyName] = useState<string>(
    previousRfqInput?.companyName || '',
  )
  const [enteredPostcode, setEnteredPostcode] = useState<string>(
    previousRfqInput?.companyPostalCode || '',
  )
  const [enteredCity, setEnteredCity] = useState<string>(
    previousRfqInput?.companyCity || '',
  )
  const [enteredOrganizationNumber, setEnteredOrganizationNumber] = useState<
    string | null
  >(previousRfqInput?.organizationNumber || '')
  const [enteredVatNumber, setEnteredVatNumber] = useState<string | null>(
    previousRfqInput?.vatNumber || '',
  )
  const [enteredDealer, setEnteredDealer] = useState<Dealer | null>(
    dealers?.filter((dealer) => dealer.iD === previousRfqInput?.dealerId)[0] ||
      null,
  )
  const [enteredRole, setEnteredRole] = useState<RfqContactRole | null>(
    previousRfqInput?.contactRole || null,
  )
  const [checkedDontKnowDealer, setCheckedDontKnowDealer] =
    useState<boolean>(false)
  const [checkedSubscribe, setCheckedSubscribe] = useState<boolean>(
    previousRfqInput?.newsletter || false,
  )
  const [checkedFinance, setCheckedFinance] = useState<boolean>(
    previousRfqInput?.financeInterest || false,
  )
  const [checkedConsent, setCheckedConsent] = useState<boolean>(
    previousRfqInput?.disclaimerAccepted || false,
  )
  const [enteredMessage, setEnteredMessage] = useState<string>(
    previousRfqInput?.messageToDealer || '',
  )
  const [enteredEmailError, setEnteredEmailError] = useState<
    'default' | 'error' | 'success' | undefined
  >(undefined)
  const [enteredCompanyNameError, setEnteredCompanyNameError] = useState<
    'default' | 'error' | 'success' | undefined
  >(undefined)
  const [enteredPostcodeError, setEnteredPostcodeError] = useState<
    'default' | 'error' | 'success' | undefined
  >(undefined)
  const [enteredDealerError, setEnteredDealerError] = useState<
    'default' | 'error' | 'success' | undefined
  >(undefined)
  const [checkedConsentError, setCheckedConsentError] = useState<string>('')
  const [errorMessageSend, setErrorMessageSend] = useState<string>('')
  const [enteredEmailErrorMessage, setEnteredEmailErrorMessage] =
    useState<string>('')
  const [enteredCompanyNameErrorMessage, setEnteredCompanyNameErrorMessage] =
    useState<string>('')
  const [enteredPostcodeErrorMessage, setEnteredPostcodeErrorMessage] =
    useState<string>('')
  const [enteredDealerErrorMessage, setEnteredDealerErrorMessage] =
    useState<string>('')
  const shouldShowDontKnowDealerCheckbox =
    marketSettings?.rfqRequiresDealerId === false &&
    marketSettings.sisEnabled === true

  const dealerChangeHandler = useCallback(
    (event: any) => {
      if (!dealers) {
        return
      }
      const selectedDealerId = event.detail.value
      const selectedDealer = dealers.filter((dealer) => {
        return dealer.iD === selectedDealerId
      })
      if (selectedDealer) {
        setEnteredDealer(selectedDealer[0])
        setEnteredDealerError('success')
        setEnteredDealerErrorMessage('')
      } else {
        setEnteredDealer(null)
        setEnteredDealerError('error')
        setEnteredDealerErrorMessage('Please select a dealer') // TODO: No text in SC-admin, but the message is probably never showed
      }
    },
    [dealers],
  )

  // Populate email field using demo mode email address if provided.
  useEffect(() => {
    if (!startupData) {
      return // Not started yet.
    }
    const settings = startupData.settings
    if (settings.demoModeEnabled) {
      const emailStatus = 'success'
      const emailAddress = settings.demoModeEmailAddress
      setEnteredEmail(emailAddress)
      setEnteredEmailError(emailStatus)
    }
  }, [startupData])

  useEffect(() => {
    const marketIso = marketSettings?.marketIso

    if (!marketIso) {
      return
    }
    if (!client) {
      return
    }
    const asyncWrapper = async () => {
      try {
        const dealers = await (await client.getDealers(marketIso)).getDealers
        setDealers(dealers)
      } catch {
        console.log('No dealers found.')
      }
    }
    asyncWrapper()
  }, [marketSettings, client])

  const emailChangeHandler = (event: any) => {
    const newEmail = event.target.value
    validateEmail(newEmail)
  }

  const validateEmail = useCallback(
    (email: string | null | undefined) => {
      const validFormat =
        /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i
      if (!email) {
        setEnteredEmail('')
      } else if (validFormat.test(email)) {
        setEnteredEmail(email)
        setEnteredEmailError('success')
        setEnteredEmailErrorMessage('')
      } else {
        setEnteredEmail(email)
        setEnteredEmailErrorMessage(t(TextId.LABEL_INVALID_EMAIL))
        setEnteredEmailError('error')
      }
    },
    [t],
  )

  const firstNameChangeHandler = (event: any) => {
    if (!event.target.value) {
      setEnteredFirstName('')
    } else {
      setEnteredFirstName(event.target.value)
    }
  }

  const surnameChangeHandler = (event: any) => {
    if (!event.target.value) {
      setEnteredSurname('')
    } else {
      setEnteredSurname(event.target.value)
    }
  }

  const telephoneChangeHandler = (event: any) => {
    if (!event.target.value) {
      setEnteredTelephone('')
    } else {
      setEnteredTelephone(event.target.value)
    }
  }

  const companyNameChangeHandler = (event: any) => {
    const newCompanyName = event.target.value
    validateCompanyName(newCompanyName)
  }

  const validateCompanyName = useCallback(
    (companyName: string | null | undefined) => {
      if (!companyName) {
        setEnteredCompanyName('')
        setEnteredCompanyNameError('error')
        setEnteredCompanyNameErrorMessage(t(TextId.LABEL_INVALID_COMPANY_NAME))
      } else {
        setEnteredCompanyName(companyName)
        setEnteredCompanyNameError('success')
        setEnteredCompanyNameErrorMessage('')
      }
    },
    [t],
  )

  const postCodeChangeHandler = (event: any) => {
    const newPostCode = event.target.value
    validatePostCode(newPostCode)
  }

  const validatePostCode = useCallback(
    (postCode: string | null | undefined) => {
      if (!postCode) {
        setEnteredPostcode('')
        setEnteredPostcodeError('error')
        setEnteredPostcodeErrorMessage(
          t(TextId.LABEL_INVALID_ADDRESS_POSTAL_CODE),
        )
      } else {
        setEnteredPostcode(postCode)
        setEnteredPostcodeError('success')
        setEnteredPostcodeErrorMessage('')
      }
    },
    [t],
  )

  const cityChangeHandler = (event: any) => {
    if (!event.target.value) {
      setEnteredCity('')
    } else {
      setEnteredCity(event.target.value)
    }
  }

  const organizationNumberChangeHandler = (event: any) => {
    if (!event.target.value) {
      setEnteredOrganizationNumber('')
    } else {
      setEnteredOrganizationNumber(event.target.value)
    }
  }
  const vatNumberChangeHandler = (event: any) => {
    if (!event.target.value) {
      setEnteredVatNumber('')
    } else {
      setEnteredVatNumber(event.target.value)
    }
  }

  const roleChangeHandler = (event: any) => {
    if (!event.target.value) {
      setEnteredRole(RfqContactRole.Other)
    } else {
      setEnteredRole(event.target.value)
    }
  }

  const commentsChangeHandler = (event: { target: { value: any } }) => {
    if (!event.target.value) {
      setEnteredMessage('')
    } else {
      setEnteredMessage(event.target.value)
    }
  }

  const getPhoneNumberForSales = (dealer: Dealer | null) => {
    if (!dealer) return
    if (!dealer.phones) return
    const phoneGeneral = dealer.phones.filter((phone) => {
      return phone.type === 'phonegeneral'
    })
    return phoneGeneral[0].number
  }

  const toggleDontKnowDealerChangeHandler = (
    ev: TdsCheckboxCustomEvent<{
      checkboxId: string
      checked: boolean
      indeterminate: boolean
      value?: string | undefined
    }>,
  ) => {
    const checked = ev.detail.checked
    setCheckedDontKnowDealer(checked)
  }

  const toggleSubscribeChangeHandler = (
    event: CustomEvent<{
      checkboxId: string
      checked: boolean
      indeterminate: boolean
      value?: string
    }>,
  ) => {
    const checked = event.detail.checked
    setCheckedSubscribe(checked)
  }

  const toggleFinanceChangeHandler = (
    ev: TdsCheckboxCustomEvent<{
      checkboxId: string
      checked: boolean
      indeterminate: boolean
      value?: string | undefined
    }>,
  ) => {
    const checked = ev.detail.checked
    setCheckedFinance(checked)
  }

  const toggleConsentChangeHandler = (
    ev: TdsCheckboxCustomEvent<{
      checkboxId: string
      checked: boolean
      indeterminate: boolean
      value?: string | undefined
    }>,
  ) => {
    const checked = ev.detail.checked
    setCheckedConsent(checked)
    if (checked === true) {
      setCheckedConsentError('success')
    }
  }

  const dealerInputValid = useCallback(() => {
    if (!marketSettings?.sisEnabled) {
      return true
    }
    if (checkedDontKnowDealer) {
      return true
    }
    if (enteredDealer) {
      return true
    }
    return false
  }, [marketSettings, checkedDontKnowDealer, enteredDealer])

  const emailValid = useCallback(() => {
    const validFormat =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i
    if (!enteredEmail || !validFormat.test(enteredEmail)) {
      setEnteredEmailError('error')
      setEnteredEmailErrorMessage(t(TextId.LABEL_INVALID_EMAIL))
      return false
    } else {
      setEnteredEmailError('success')
      setEnteredEmailErrorMessage('')
      return true
    }
  }, [enteredEmail, t])

  const resetInputForm = useCallback(() => {
    setEnteredEmail('')
    setEnteredFirstName('')
    setEnteredSurname('')
    setEnteredTelephone('')
    setEnteredCompanyName('')
    setEnteredOrganizationNumber('')
    setEnteredPostcode('')
    setEnteredCity('')
    setEnteredMessage('')
    setEnteredDealer(null)
    setEnteredRole(RfqContactRole.Other)
    setCheckedSubscribe(false)
    setCheckedConsent(false)
    setCheckedFinance(false)
    setCheckedDontKnowDealer(false)
    setErrorMessageSend('')
    setCheckedConsentError('')
  }, [])

  // Update and validate input data from redux if any
  useEffect(() => {
    if (!previousRfqInput) {
      return
    }
    validateEmail(previousRfqInput.contactEmail)
    validateCompanyName(previousRfqInput.companyName)
    validatePostCode(previousRfqInput.companyPostalCode)
  }, [previousRfqInput, validateCompanyName, validateEmail, validatePostCode])

  const submitHandler = useCallback(
    (event: { preventDefault: () => void }) => {
      event.preventDefault()
      if (!startupData) {
        return
      }
      const sessionId = sessionInitData?.sessionId
      if (!sessionId) {
        return
      }
      if (!marketLanguage) {
        return
      }
      let mandatoryFieldsEntered = false
      if (
        emailValid() &&
        enteredCompanyNameError === 'success' &&
        enteredPostcodeError === 'success' &&
        checkedConsent &&
        dealerInputValid()
      ) {
        mandatoryFieldsEntered = true
        setErrorMessageSend('')
      } else {
        mandatoryFieldsEntered = false
        setErrorMessageSend(t(TextId.LABEL_INVALID_FORM_INPUT))

        if (enteredCompanyNameError !== 'success') {
          setEnteredCompanyNameError('error')
          setEnteredCompanyNameErrorMessage(
            t(TextId.LABEL_INVALID_COMPANY_NAME),
          )
        }
        if (enteredPostcodeError !== 'success') {
          setEnteredPostcodeError('error')
          setEnteredPostcodeErrorMessage(
            t(TextId.LABEL_INVALID_ADDRESS_POSTAL_CODE),
          )
        }
        if (!checkedConsent) {
          setCheckedConsentError('error')
        }
      }
      const settings = startupData.settings
      const mode = settings.demoModeEnabled ? RfqMode.Demo : RfqMode.Normal
      const debugEmail = settings.demoModeEnabled
        ? settings.demoModeEmailAddress
        : null
      const campaignIdentity = getUrlCidValue()
      if (mandatoryFieldsEntered) {
        const rfqParams: RfqParams = {
          campaignIdentity,
          mode,
          debugEmail,
          dealerId: enteredDealer?.iD,
          companyName: enteredCompanyName,
          companyPostalCode: enteredPostcode,
          companyCity: enteredCity,
          contactPhone: enteredTelephone,
          disclaimerAccepted: checkedConsent,
          financeInterest: checkedFinance,
          newsletter: checkedSubscribe,
          contactFirstName: enteredFirstName,
          contactFamilyName: enteredSurname,
          contactEmail: enteredEmail,
          contactRole: enteredRole || RfqContactRole.Other,
          messageToDealer: enteredMessage,
          organizationNumber: enteredOrganizationNumber,
          vatNumber: enteredVatNumber,
          language: marketLanguage.language,
          sessionId: sessionId,
        }
        handleRfqSendClick(rfqParams)
        dispatch(setRfqInput(rfqParams))

        // TODO: Investigate if this is needed now. Should not be needed when
        // destroying and creating a new element instead of hiding it.
        resetInputForm()
      }
    },
    [
      checkedConsent,
      checkedFinance,
      checkedSubscribe,
      dealerInputValid,
      dispatch,
      emailValid,
      enteredCity,
      enteredCompanyName,
      enteredCompanyNameError,
      enteredDealer,
      enteredEmail,
      enteredFirstName,
      enteredMessage,
      enteredOrganizationNumber,
      enteredPostcode,
      enteredPostcodeError,
      enteredRole,
      enteredSurname,
      enteredTelephone,
      enteredVatNumber,
      handleRfqSendClick,
      marketLanguage,
      resetInputForm,
      sessionInitData,
      startupData,
      t,
    ],
  )

  const handleCloseClick = useCallback(() => {
    dispatch(closeAllModals())
  }, [dispatch])

  // Eventlistener to close modal on tdsClose (click outside modal and click on close cross)
  useEffect(() => {
    document.addEventListener('tdsClose', handleCloseClick)
    return () => {
      document.removeEventListener('tdsClose', handleCloseClick)
    }
  }, [handleCloseClick])

  return (
    <form onSubmit={submitHandler}>
      <TdsModal header={t(TextId.HEADER_QR_FORM)} size="md" show>
        <span slot="body">
          <InfoText>{t(TextId.DESCRIPTION_QR_FORM)}</InfoText>
          <SmallHeader>{t(TextId.HEADER_QR_FORM_USER_INFO)}</SmallHeader>
          <LabelInputField>{t(TextId.LABEL_EMAIL)}</LabelInputField>
          <LabelCompulsory>*</LabelCompulsory>
          <TdsTextField
            type="text"
            onInput={emailChangeHandler}
            state={enteredEmailError}
            helper={enteredEmailErrorMessage}
            value={enteredEmail}
          />
          <LabelInputField>{t(TextId.LABEL_FIRST_NAME)}</LabelInputField>
          <TdsTextField
            type="text"
            onInput={firstNameChangeHandler}
            value={enteredFirstName}
          />
          <LabelInputField>{t(TextId.LABEL_LAST_NAME)}</LabelInputField>
          <TdsTextField
            type="text"
            onInput={surnameChangeHandler}
            value={enteredSurname}
          />
          <LabelInputField>{t(TextId.LABEL_PHONE_NUMBER)}</LabelInputField>
          <TdsTextField
            type="text"
            placeholder={phoneNumberInstructions}
            onInput={telephoneChangeHandler}
            value={enteredTelephone}
          />
          <SmallHeader>{t(TextId.HEADER_QR_FORM_COMPANY_INFO)}</SmallHeader>
          <LabelInputField>{t(TextId.LABEL_COMPANY_NAME)}</LabelInputField>
          <LabelCompulsory>*</LabelCompulsory>
          <TdsTextField
            type="text"
            onInput={companyNameChangeHandler}
            value={enteredCompanyName}
            state={enteredCompanyNameError}
            helper={enteredCompanyNameErrorMessage}
          />
          <LabelInputField>
            {t(TextId.LABEL_ADDRESS_POSTAL_CODE)}
          </LabelInputField>
          <LabelCompulsory>*</LabelCompulsory>
          <TdsTextField
            type="text"
            onInput={postCodeChangeHandler}
            value={enteredPostcode}
            state={enteredPostcodeError}
            helper={enteredPostcodeErrorMessage}
          />
          <LabelInputField>{t(TextId.LABEL_ADDRESS_CITY)}</LabelInputField>
          <TdsTextField
            type="text"
            onInput={cityChangeHandler}
            value={enteredCity}
          />
          <LabelInputField>
            {t(TextId.LABEL_ORGANIZATION_NUMBER)}
          </LabelInputField>
          <TdsTextField
            type="text"
            onInput={organizationNumberChangeHandler}
            value={enteredOrganizationNumber || ''}
          />
          <LabelInputField>{t(TextId.LABEL_VAT_NUMBER)}</LabelInputField>
          <TdsTextField
            type="text"
            onInput={vatNumberChangeHandler}
            value={enteredVatNumber || ''}
          />
          <SmallHeader>{t(TextId.RFQ1_CUSTOMER_ROLE)}</SmallHeader>
          <TdsDropdown
            size="lg"
            open-direction="auto"
            defaultValue={enteredRole || undefined}
            onTdsChange={roleChangeHandler}
          >
            {[
              { text: t(TextId.RFQ1_CUSTOMER_ROLE_OWNER), id: 'Owner' },
              { text: t(TextId.RFQ1_CUSTOMER_ROLE_DRIVER), id: 'Driver' },
              { text: t(TextId.RFQ1_CUSTOMER_ROLE_OTHER), id: 'Other' },
            ].map((role) => (
              <TdsDropdownOption key={role.id} value={role.id}>
                {role.text}
              </TdsDropdownOption>
            ))}
          </TdsDropdown>
          {marketSettings?.sisEnabled === true && (
            <h5 style={{ display: 'inline-block', marginTop: '24px' }}>
              {t(TextId.HEADER_QR_FORM_FIND_DEALER)}{' '}
              <LabelCompulsory>*</LabelCompulsory>
            </h5>
          )}
          {shouldShowDontKnowDealerCheckbox && (
            <TdsCheckbox
              name="dontKnowDealer"
              value="dontKnowDealer"
              checked={checkedDontKnowDealer}
              onTdsChange={toggleDontKnowDealerChangeHandler}
            >
              <span slot="label">{t(TextId.RFQ1_I_DONT_KNOW_DEALER)}</span>
            </TdsCheckbox>
          )}
          {marketSettings?.sisEnabled === true && (
            <TdsDropdown
              helper={enteredDealerErrorMessage}
              error={enteredDealerError === 'error'}
              defaultValue={enteredDealer?.iD || undefined}
              filter={true}
              onTdsChange={dealerChangeHandler}
            >
              {dealers &&
                dealers.map((dealer) => (
                  <TdsDropdownOption
                    key={dealer.iD}
                    value={dealer.iD || undefined}
                  >
                    {dealer.name}
                  </TdsDropdownOption>
                ))}
            </TdsDropdown>
          )}
          {!(
            marketSettings?.sisEnabled === false ||
            (marketSettings?.sisEnabled === true && !enteredDealer)
          ) && (
            <DealerTextBox>
              <DealerTextBoxHeader>
                {enteredDealer?.nameLocal || enteredDealer?.name}
              </DealerTextBoxHeader>
              <DealerTextBoxAddress>
                {enteredDealer?.visitingAddress?.streetLocal ||
                  enteredDealer?.visitingAddress?.street}
              </DealerTextBoxAddress>
              <DealerTextBoxAddress>
                {enteredDealer?.visitingAddress?.postalCode}
              </DealerTextBoxAddress>
              <DealerTextBoxAddress>
                {enteredDealer?.cityLocal || enteredDealer?.city}
              </DealerTextBoxAddress>
              <DealerTextBoxPhone>
                {getPhoneNumberForSales(enteredDealer)}
              </DealerTextBoxPhone>
              <DealerTextBoxWeb>
                <a href={'//' + enteredDealer?.wWW || undefined}>Website</a>
              </DealerTextBoxWeb>
            </DealerTextBox>
          )}
          <Divider />
          <SmallHeader>{t(TextId.LABEL_COMMENTS)}</SmallHeader>
          <TdsTextarea
            placeholder=""
            onTdsChange={commentsChangeHandler}
            value={enteredMessage}
          />
          <Divider />
          <TdsCheckbox
            name="subscribed"
            value="subscribed"
            checked={checkedSubscribe}
            onTdsChange={toggleSubscribeChangeHandler}
          >
            <span slot="label" style={{ fontWeight: 'bold' }}>
              {t(TextId.RFQ1_NEWSLETTER)}{' '}
              <CheckboxSmallText>
                {t(TextId.RFQ1_NEWSLETTER_DESCRIPTION)}
              </CheckboxSmallText>
            </span>
          </TdsCheckbox>
          <TdsCheckbox
            name="finance"
            value="finance"
            checked={checkedFinance}
            onTdsChange={toggleFinanceChangeHandler}
          >
            <span
              slot="label"
              style={{ fontWeight: 'bold', marginBottom: '8px' }}
            >
              {t(TextId.RFQ1_FINANCE_DESCRIPTION)}
            </span>
          </TdsCheckbox>
          <TdsCheckbox
            name="consent"
            value="consent"
            checked={checkedConsent}
            onTdsChange={toggleConsentChangeHandler}
          >
            <span
              slot="label"
              style={{
                fontWeight: 'bold',
                color: checkedConsentError === 'error' ? 'red' : '',
              }}
            >
              {t(TextId.RFQ1_PERSONEL_DATA)}{' '}
              <LabelCompulsory>*</LabelCompulsory>{' '}
            </span>
          </TdsCheckbox>
          <CheckboxSmallTextWrapper>
            <CheckboxSmallText>
              {t(TextId.RFQ1_PERSONEL_DATA_DESCRIPTION1)}
            </CheckboxSmallText>
            <CheckboxSmallText>
              {t(TextId.RFQ1_PERSONEL_DATA_DESCRIPTION2)}
            </CheckboxSmallText>
            <a
              href={t(TextId.RFQ1_DISCLAIMER_URL)}
              target="_blank"
              rel="noreferrer"
              style={{ fontSize: '10px', fontWeight: 'normal' }}
            >
              {t(TextId.RFQ1_DISCLAIMER_URL_LABEL)}
            </a>
          </CheckboxSmallTextWrapper>
          {startupData?.settings.demoModeEnabled && (
            <DemoModeMessage>
              <h3>Demo Mode is active.</h3>
              <p>
                Using demo mode address:{' '}
                {startupData.settings.demoModeEmailAddress}
              </p>
            </DemoModeMessage>
          )}
          <Divider style={{ marginBottom: '0px', marginTop: '8px' }} />
        </span>

        <span slot="actions" className="tds-u-flex tds-u-gap2 tds-u-flex-wrap">
          {errorMessageSend && <ErrorMessage>{errorMessageSend}</ErrorMessage>}
          <TdsButton
            type="submit"
            size="md"
            text={t(TextId.LABEL_ACTION_REQUEST_QUOTE)}
          />
          <TdsButton
            size="md"
            variant="secondary"
            onClick={handleCloseClick}
            text={t(TextId.LABEL_ACTION_CANCEL)}
          />
        </span>
      </TdsModal>
    </form>
  )
}
