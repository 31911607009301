/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { SessionInitDataMarketSettings } from './SessionInitDataMarketSettings'
import {
  SessionInitDataMarketSettingsFromJSON,
  SessionInitDataMarketSettingsFromJSONTyped,
  SessionInitDataMarketSettingsToJSON,
} from './SessionInitDataMarketSettings'
import type { SessionInitDataMenuInfo } from './SessionInitDataMenuInfo'
import {
  SessionInitDataMenuInfoFromJSON,
  SessionInitDataMenuInfoFromJSONTyped,
  SessionInitDataMenuInfoToJSON,
} from './SessionInitDataMenuInfo'
import type { SessionInitDataSecmPublishInfo } from './SessionInitDataSecmPublishInfo'
import {
  SessionInitDataSecmPublishInfoFromJSON,
  SessionInitDataSecmPublishInfoFromJSONTyped,
  SessionInitDataSecmPublishInfoToJSON,
} from './SessionInitDataSecmPublishInfo'

/**
 *
 * @export
 * @interface CreateSessionResponseWrapperCreateSession
 */
export interface CreateSessionResponseWrapperCreateSession {
  /**
   *
   * @type {string}
   * @memberof CreateSessionResponseWrapperCreateSession
   */
  sessionId: string
  /**
   *
   * @type {SessionInitDataMenuInfo}
   * @memberof CreateSessionResponseWrapperCreateSession
   */
  menuInfo: SessionInitDataMenuInfo
  /**
   *
   * @type {string}
   * @memberof CreateSessionResponseWrapperCreateSession
   */
  composedUrl: string
  /**
   *
   * @type {SessionInitDataMarketSettings}
   * @memberof CreateSessionResponseWrapperCreateSession
   */
  marketSettings: SessionInitDataMarketSettings
  /**
   *
   * @type {number}
   * @memberof CreateSessionResponseWrapperCreateSession
   */
  resetTimeSec: number
  /**
   *
   * @type {SessionInitDataSecmPublishInfo}
   * @memberof CreateSessionResponseWrapperCreateSession
   */
  secmPublishInfo: SessionInitDataSecmPublishInfo
}

/**
 * Check if a given object implements the CreateSessionResponseWrapperCreateSession interface.
 */
export function instanceOfCreateSessionResponseWrapperCreateSession(
  value: object,
): boolean {
  let isInstance = true
  isInstance = isInstance && 'sessionId' in value
  isInstance = isInstance && 'menuInfo' in value
  isInstance = isInstance && 'composedUrl' in value
  isInstance = isInstance && 'marketSettings' in value
  isInstance = isInstance && 'resetTimeSec' in value
  isInstance = isInstance && 'secmPublishInfo' in value

  return isInstance
}

export function CreateSessionResponseWrapperCreateSessionFromJSON(
  json: any,
): CreateSessionResponseWrapperCreateSession {
  return CreateSessionResponseWrapperCreateSessionFromJSONTyped(json, false)
}

export function CreateSessionResponseWrapperCreateSessionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CreateSessionResponseWrapperCreateSession {
  if (json === undefined || json === null) {
    return json
  }
  return {
    sessionId: json['session_id'],
    menuInfo: SessionInitDataMenuInfoFromJSON(json['menu_info']),
    composedUrl: json['composed_url'],
    marketSettings: SessionInitDataMarketSettingsFromJSON(
      json['market_settings'],
    ),
    resetTimeSec: json['reset_time_sec'],
    secmPublishInfo: SessionInitDataSecmPublishInfoFromJSON(
      json['secm_publish_info'],
    ),
  }
}

export function CreateSessionResponseWrapperCreateSessionToJSON(
  value?: CreateSessionResponseWrapperCreateSession | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    session_id: value.sessionId,
    menu_info: SessionInitDataMenuInfoToJSON(value.menuInfo),
    composed_url: value.composedUrl,
    market_settings: SessionInitDataMarketSettingsToJSON(value.marketSettings),
    reset_time_sec: value.resetTimeSec,
    secm_publish_info: SessionInitDataSecmPublishInfoToJSON(
      value.secmPublishInfo,
    ),
  }
}
