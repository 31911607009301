import styled, { css } from 'styled-components'
import {
  CSSProperties,
  ReactEventHandler,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { FactoryModelV2, MenuItem } from '../../api/generated'
import { ViewAllTrucksForApplicationButton } from './ViewAllTrucksForApplicationButton'
import {
  FactoryModelsControlPanel,
  FactoryModelsFilterSelection,
  FILTER_CHOICE_ID_INACTIVE,
  FILTER_CHOICE_ID_SHOW_ALL,
} from './FactoryModelsControlPanel'
import { EtelVariant, toEtelVariant, toId } from '../../utils/EtelVariant'
import { FactoryModelsState, ReadMoreData } from '../../store/types'
import useTexts, { TextId } from '../../utils/useTexts'
import { useClient } from '../../utils/useClient'
import { Info } from 'react-feather'
import {
  ScaniaAdobeTrackingClickFmReadMoreEvent,
  ScaniaAdobeTrackingClickFmShowAllEvent,
  pushAdobeEvent,
  ScaniaAdobeEventId,
  ScaniaAdobeTrackingPageName,
  ScaniaAdobeTrackingFlowName,
  ScaniaAdobeTrackingClickUpgradeYourTruckEvent,
  ScaniaAdobePageName,
} from '../../utils/adobeAnalytics'
import {
  SvgLoading,
  SvgScaniaSuper,
  SvgTruck,
} from '../../components/SvgImports'
import { buildRelativeBuildPageUrl } from '../../utils/UrlBuilders'
import { buildFactoryModelConfigString } from '../BuildMode/LegacyEncoding'
import { useNavigate } from 'react-router-dom'
import {
  NewTruckLoadedHandler,
  NewTruckLoadedInfo,
  TruckLoadedSource,
} from '../../types/TruckLoadedTypes'
import { SESSION_FAILURE } from '../../api/errors'
import { ReadMoreEntry } from '../../components/SidePanels/SidePanelReadMore'
import {
  FAMILY_ID_APPLICATION,
  FAMILY_ID_CAB,
  FAMILY_ID_WHEEL_CONFIGURATION,
} from '../../api/constants'
import { ScrollContent } from '../GuidedOffering/ScrollContent'
import { PortraitFooter } from '../../components/Footer/PortraitFooter'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { BreakpointWidthPx } from '../../css/BreakpointWidthPx'
import { BevButton } from './BevButton'
import { urlHasBevParam } from '../GuidedOffering/urlHasBevParam'
import { GoFmMode } from '../../api/goFmMode'
import { TestElementTypeId } from '../../types/TestAttributeId'
import { getUrlParametersToString } from '../../utils/getUrlParametersToString'
import {
  openPanelReadMore,
  setReadMoreData,
  setReadMoreNode,
} from '../../store/sidePanelSlice'
import {
  getFactoryModelsState,
  getMarketLanguageState,
  getMarketSettingsState,
} from '../../store/appSlice'
import {
  getSessionInitDataState,
  getStartupData,
  hideInitialLoadingScreen,
  pushPageViewTrackingEvent,
  setPendingUserTrackingPageNavigation,
} from '../../store/sessionDataSlice'
import { TdsButton, TdsIcon } from '@scania/tegel-react'

// 65px left shadow width / 400px requested image scaled height seems to be the
// ratio for the left shadow margin in frame 2.
// TODO: Calculate this using the image request size.
// TODO: Later, implement some way to get this information from the backend.
const PIXEL_RATIO = window.devicePixelRatio
const IMAGE_WIDTH = Math.round(350 * PIXEL_RATIO) // This is ok to tweak.
const SHADOW_MARGIN_FACTOR = 65 / 400 // This should not change unless content change.
const SHADOW_MARGIN = Math.round(SHADOW_MARGIN_FACTOR * IMAGE_WIDTH)

// TODO: Consider using this for placeholder while waiting for images to load.
//import truck_silhouette from '../../assets/img/truck_silhouette.svg'

const FactoryModelsView = styled.div`
  background-color: white;
  overflow-y: scroll;

  // iOS scroll tweaks: BEGIN
  // Without this, iOS will make this div as large as its contents and as a
  // result will not be scrollable.
  position: absolute;
  top: var(--header-height);
  height: calc(var(--app-height) - var(--header-height));
  // iOS scroll tweaks: END

  width: 100%;
  justify-self: center;
`

const pageSidePadding = css`
  // The 80px is there to match the design images. If that looks good on larger
  // screens remains to be seen. We may need to limit the max width of the
  // content further.
  // TODO: Review again, is this too tight on small screens? Probably?
  padding-left: min(10%, 80px);
  padding-right: min(10%, 80px);
`

const PageContent = styled.div`
  width: 100%;
  justify-self: center;
  padding-bottom: 10vh;
  flex-grow: 1;
`

const ApplicationDividerContainer = styled.div`
  display: flex;
  width: 100%;
  color: ${({ theme }) => theme.scDarkBlue};

  // This seems to cause half a pixel or a pixel large gap when zooming the page
  // or using a Windows device with larger font settings.
  //border-bottom-width: 2px;

  border-bottom-style: solid;
  margin-bottom: 1.5rem;
`

const OperationsDividerRemainingSpace = styled.div`
  flex-grow: 100;
`

/**
 * The only purpose of this element is to allow using 'space-between' to
 * position the page title in the available space above the button area.
 */
const LayoutDummy = styled.div``

const PageTitleContainer = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 2rem;
  margin-top: 2rem;
  user-select: none;
  & span {
    text-align: left !important;

    // TODO: Review this and see if we need to switch between Tegel css
    // classes with media queries and breakpoints instead.
    font-size: min(24px, 4vw);
    line-height: 1em;
  }
  & span:nth-child(3) {
    align-self: flex-end;
    text-decoration: underline;
  }
`

const PageTitle = styled.div`
  color: white;
  text-align: left;
  text-transform: uppercase;
  & p {
    margin: 0;

    // TODO: Review this and see if we need to switch between Tegel css
    // classes with media queries and breakpoints instead.
    font-size: min(80px, 9vw);
    line-height: 1em;
  }
`

const TruckImageGroup = styled.div`
  width: 100%;
`

const TruckImageGrid = styled.div<{ $filters: boolean }>`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  @media screen and (min-width: ${BreakpointWidthPx.Tablet}px) {
    flex-direction: row;
    align-items: flex-end;

    &:after {
      content: '';
      flex: 0 1 320px;
    }
  }

  @media only screen and (max-width: 1141px) {
    justify-content: center;
  }
`

const TruckImageDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media screen and (max-width: 720px) {
    img {
      max-width: ${IMAGE_WIDTH / PIXEL_RATIO + 'px'};
    }
  }
`

const TruckImageText = styled.h5`
  font-size: 1.37em;
  margin-left: ${SHADOW_MARGIN / 2 / PIXEL_RATIO}px;
  height: 48px;

  @media screen and (min-width: ${BreakpointWidthPx.Tablet}px) {
    font-size: 0.95em;
    //height: 1em; // Needed to align truck images with different number of text lines under.
  }
`

const TruckSubIconButtonWrapper = styled.div`
  position: absolute;
  width: inherit;
  display: flex;
  justify-content: left;
  margin-left: ${SHADOW_MARGIN / 2 / PIXEL_RATIO}px;
`

const TruckImageSuperButton = styled.div`
  align-items: center;
  background-color: white;
  border-color: var(--tds-grey-958);
  border-radius: 2px;
  border-style: solid;
  border-width: thin;
  display: flex;
  height: 36px;
  justify-content: center;
  padding-left: 12px;
  padding-right: 12px;
  position: relative;
  svg {
    height: 1.2em;
  }
`

const PageHeaderContainer = styled.div`
  ${pageSidePadding}

  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  background-image: url(${`${process.env.PUBLIC_URL}/images/YNT_Header.jpg`});
  min-height: min(
    500px,
    calc((var(--app-height) - var(--header-height)) * 0.5)
  );
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

const ControlBoxFlexCenter = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

interface ShowUpgradeYourFleet {
  $showUYF: boolean
}

const TruckImageAreaFlexCenter = styled.div<ShowUpgradeYourFleet>`
  ${pageSidePadding}

  margin-top: ${(props) => (props.$showUYF ? '24px' : '64px')};
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
`

const TruckImageAreaWidthLimiter = styled.div`
  width: min(100%, 1200px);
`

const UpgradeYourFleetWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 16px;
`

const UpgradeYourFleetText = styled.div`
  margin-right: 8px;
  font-weight: bold;
`

const TruckImageButton = styled.div`
  padding: 0;
  margin: 0;
  cursor: pointer;
  display: flex;
  a {
    color: unset;
  }
  @media (hover: hover) and (pointer: fine) {
    a:hover {
      color: unset;
    }
  }

  a:active {
    color: unset;
  }
  a:visited {
    color: unset;
  }
  color: unset;
`

const TruckImageContainer = styled.div`
  cursor: pointer;
  width: ${(IMAGE_WIDTH - SHADOW_MARGIN) / PIXEL_RATIO + 'px'};

  margin-bottom: 45px;

  @media screen and (min-width: ${BreakpointWidthPx.Tablet}px) {
    margin-right: ${SHADOW_MARGIN / 2 / PIXEL_RATIO + 'px'};
  }

  @media screen and (min-width: ${BreakpointWidthPx.DesktopLarge}px) {
    margin-right: 5px;
  }

  @media (hover: hover) and (pointer: fine) {
    img:hover {
      filter: brightness(1.04) drop-shadow(0px 0px 1px #00000044);
    }
  }

  img:active {
    filter: unset;
  }
`

const LoadingIndicatorContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--tds-blue-400);
  svg {
    width: 48px;
    height: 48px;
  }
`

const ReadMoreInfoButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: rgb(4, 30, 66);
  svg {
    stroke: white;
    width: 20px;
    margin-right: 4px;
  }
`

const MissingTrucksContainer = styled.div`
  display: flex;
  max-width: 300px;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 30px;
  border-radius: 4px;
  text-align: center;
  font-weight: bold;
  background: #f9f9f9;
  color: ${({ theme }) => theme.scDarkBlue};
  .icon {
    width: 110px;
    color: #fff;
    margin-bottom: 30px;
    background: ${({ theme }) => theme.scDarkBlue};
    padding: 30px;
    border-radius: 100%;
  }
`

const IMAGE_LOADING: string = 'IMAGE_LOADING'

const modelShouldBeVisible = (m: FactoryModelV2, viewFilter: ViewFilter) => {
  if (!m.configurationSubset) {
    return false
  }
  if (m.overrides) {
    if (m.overrides.visible === false) {
      return false
    }
  }
  if (!m.optionsSubset) {
    console.error('Expected .optionsSubset to be defined.')
    return false
  }
  const selectedVariants = m.configurationSubset.map(toEtelVariant)
  const selectedMap: Record<string, string> = {}
  selectedVariants.forEach((value) => {
    selectedMap[value.family] = value.execution
  })
  for (const [k, v] of Object.entries(viewFilter)) {
    if (v === FILTER_CHOICE_ID_INACTIVE) {
      console.error('Did not expect FILTER_CHOICE_ID_INACTIVE.')
      continue
    }
    if (v === FILTER_CHOICE_ID_SHOW_ALL) {
      continue
    }
    const selectedExecution = selectedMap[k]
    if (selectedExecution === v) {
      continue
    }
    const optionalExecutions = m.optionsSubset[k] || []
    if (
      -1 ===
      optionalExecutions.findIndex((value) => {
        return value === v
      })
    ) {
      return false
    }
  }
  return true
}

const getVariantsForModel = (
  model: FactoryModelV2,
  family: string,
): string[] => {
  const selected = model.configurationSubset?.find((item) =>
    item.startsWith(family),
  )
  if (selected === undefined) {
    console.error('Expected to find selected execution for family: ' + family)
    return []
  }
  const resultSet: Record<string, boolean> = {}
  resultSet[selected] = true
  if (!model.optionsSubset) {
    console.error('Expected .optionsSubset to be defined.')
    return []
  }
  const options = model.optionsSubset[family]
  if (options) {
    options.forEach((execution) => {
      const variantCode = family + '~' + execution
      resultSet[variantCode] = true
    })
  }
  const result: string[] = []
  Object.keys(resultSet).forEach((k) => {
    result.push(k)
  })
  return result
}

const getModelsByApplication = (
  models: FactoryModelV2[],
  etelLanguage: number,
): Record<string, FactoryModelV2[]> => {
  const result: Record<string, FactoryModelV2[]> = {}
  for (const model of models) {
    const applications = getVariantsForModel(model, FAMILY_ID_APPLICATION)
    if (applications.length === 0) {
      console.error('Expected to find application(s) for model.')
      continue
    }
    for (const application of applications) {
      const models = result[application] || []
      models.push(model)
      result[application] = models
    }
  }

  // Check for duplicates and sort.
  Object.entries(result).forEach(([k, v]) => {
    const models: FactoryModelV2[] = []
    v.forEach((e) => {
      let duplicate = false
      models.forEach((m) => {
        if (m.id === e.id) {
          duplicate = true
        }
      })
      if (!duplicate) {
        models.push(e)
      }
    })
    sortModels(models, etelLanguage)
    result[k] = models
  })

  return result
}

const DEFAULT_MODELS_VISIBLE_PER_APPLICATION = 3

const filterModels = (
  allModels: FactoryModelV2[],
  viewFilter: ViewFilter,
  etelLanguage: number,
): FactoryModelV2[] => {
  const isDefault = isDefaultViewFilter(viewFilter)
  const resultSet: Record<number, FactoryModelV2> = {}
  if (isDefault) {
    const byApplication = getModelsByApplication(allModels, etelLanguage)
    for (const models of Object.values(byApplication)) {
      const firstFew = models.slice(0, DEFAULT_MODELS_VISIBLE_PER_APPLICATION)
      for (const m of firstFew) {
        if (m.id === undefined || m.id === null) {
          console.error('Expected m.id to be defined.')
          continue
        }
        resultSet[m.id] = m
      }
    }
  } else {
    const shouldBeVisible = (m: FactoryModelV2): boolean => {
      return modelShouldBeVisible(m, viewFilter)
    }
    const allFiltered = allModels.filter(shouldBeVisible)
    allFiltered.forEach((model) => {
      if (model.id === undefined || model.id === null) {
        console.error('Expected m.id to be defined.')
        return
      }
      resultSet[model.id] = model
    })
  }
  const result = Object.values(resultSet)
  sortModels(result, etelLanguage)
  return result
}

const buildAllowedFilterFamilyIds = (): string[] => {
  return [FAMILY_ID_APPLICATION, FAMILY_ID_WHEEL_CONFIGURATION, FAMILY_ID_CAB]
}

const allowedFilterFamilyIds = buildAllowedFilterFamilyIds()

const buildFilterFamilies = (
  factoryModelsState: FactoryModelsState,
): Record<string, string[]> => {
  const texts = factoryModelsState.factoryModels?.response.shortTexts || {}
  const models = factoryModelsState?.factoryModels?.response?.models || []
  const temp: Record<string, Record<string, boolean>> = {}
  models.forEach((model) => {
    model.configurationSubset?.map(toEtelVariant).forEach((v) => {
      const executionIdSet = temp[v.family] || {}
      executionIdSet[v.execution] = true
      temp[v.family] = executionIdSet
    })
    if (!model.optionsSubset) {
      throw new Error('Expected .optionsSubset to be defined.')
    }
    Object.entries(model.optionsSubset).forEach(([family, executions]) => {
      executions.forEach((execution) => {
        const executionIdSet = temp[family] || {}
        executionIdSet[execution] = true
        temp[family] = executionIdSet
      })
    })
  })
  const result: Record<string, string[]> = {}
  Object.entries(temp).forEach(([familyId, executionIdSet]) => {
    const executions = Object.entries(executionIdSet).flatMap(
      ([executionId, flag]) => {
        return flag === true ? [executionId] : []
      },
    )
    executions.sort((a, b) => {
      const aVariantId = familyId + '~' + a
      const bVariantId = familyId + '~' + b
      let textA = texts[aVariantId]
      if (textA === undefined || textA === null) {
        textA = ''
      }
      let textB = texts[bVariantId]
      if (textB === undefined || textB === null) {
        textB = ''
      }
      return textA.localeCompare(textB)
    })
    result[familyId] = executions
  })
  return result
}

const newViewFilter = (initValue: string): ViewFilter => {
  const result: ViewFilter = {}
  result[FAMILY_ID_APPLICATION] = initValue
  result[FAMILY_ID_CAB] = initValue
  result[FAMILY_ID_WHEEL_CONFIGURATION] = initValue
  return result
}

const buildDefaultViewFilter = (): ViewFilter => {
  return newViewFilter(FILTER_CHOICE_ID_INACTIVE)
}

const getFactoryModelText = (
  id: string,
  state: FactoryModelsState | null,
): string => {
  const s = (state?.factoryModels?.response.shortTexts || {})[id]
  if (s === undefined) {
    // Remove the internal ~ internal encoding separator.
    const fallback = id.replace('~', '')
    return fallback
  }
  return s
}

const isDefaultViewFilter = (viewFilter: ViewFilter) => {
  for (const v of Object.values(viewFilter)) {
    if (v !== FILTER_CHOICE_ID_INACTIVE) {
      return false
    }
  }
  return true
}

const getModelName = (
  model: FactoryModelV2,
  etelLanguage: number | null,
): string => {
  let modelName = model.name?.replaceAll('_', ' ')
  const nameOverrides = model.overrides?.nameOverrides || []
  Object.entries(nameOverrides).forEach(([key, value]) => {
    if (key === etelLanguage?.toString() && value.length !== 0) {
      modelName = value
    }
  })
  if (modelName === undefined || modelName === null) {
    modelName = ''
  }
  return modelName
}

const sortModels = (models: FactoryModelV2[], etelLanguage: number): void => {
  models.sort((a, b) => {
    // Sort according to SC1M-2859.
    // Primary sorting key. 1 is first. 0, negative values and undefined is last. 999 is max sort order value.
    const aOrder =
      a.overrides?.sortOrder && a.overrides?.sortOrder > 0
        ? a.overrides?.sortOrder
        : 1000
    const bOrder =
      b.overrides?.sortOrder && b.overrides?.sortOrder > 0
        ? b.overrides?.sortOrder
        : 1000
    const cmp = aOrder - bOrder
    if (cmp !== 0) {
      return cmp
    }
    // Secondary sorting key
    const aName = getModelName(a, etelLanguage)
    const bName = getModelName(b, etelLanguage)
    return aName.localeCompare(bName)
  })
}

// Needed for loading indicator placement since the same model can be part of
// multiple applications.
interface ModelButtonId {
  application: EtelVariant | null
  modelId: number
}

function modelButtonIdEquals(a: ModelButtonId | null, b: ModelButtonId | null) {
  if (a === b) {
    return true
  }
  if (a === null) {
    return false
  }
  if (b === null) {
    return false
  }
  if (a.modelId !== b.modelId) {
    return false
  }
  if (a.application?.family !== b.application?.family) {
    return false
  }
  if (a.application?.execution !== b.application?.execution) {
    return false
  }
  return true
}

// The key is the Etel Family id.
// The value is the Etel Execution to show for this Family.
// Null as value means no filter for this Etel Family.
//
// If the entire filter is null, then the user has just arrived and hasn't
// clicked any any filter button yet. In this case the page should render a few
// trucks from each Operation Etel Family and group them by Operation.
export type ViewFilter = Record<string, string>

function deepCopy<T>(src: T): T {
  return JSON.parse(JSON.stringify(src))
}

export interface FactoryModelsProps {
  handleFatalError: () => void
  handleNewTruckIsLoaded: NewTruckLoadedHandler
  handleSessionFailure: () => void
}

export const FactoryModels = ({
  handleFatalError,
  handleNewTruckIsLoaded,
  handleSessionFailure,
}: FactoryModelsProps): JSX.Element => {
  const sessionInitData = useAppSelector(getSessionInitDataState)
  const marketLanguage = useAppSelector(getMarketLanguageState)
  const marketSettings = useAppSelector(getMarketSettingsState)
  const startupData = useAppSelector(getStartupData)
  const navigate = useNavigate()
  const componentRootRef = useRef<HTMLDivElement>(null)
  const [viewFilter, setViewFilter] = useState<ViewFilter>(
    buildDefaultViewFilter(),
  )
  const [filterFamilies, setFilterFamilies] = useState<
    Record<string, string[]>
  >({})
  const factoryModelsStateUnfiltered = useAppSelector(getFactoryModelsState)
  const [factoryModelsState, setFactoryModelsState] =
    useState<FactoryModelsState>({
      factoryModels: null,
      hasBevModels: false,
      mode: factoryModelsStateUnfiltered.mode,
    })
  const [visibleFactoryModels, setVisibleFactoryModels] = useState<
    FactoryModelV2[]
  >([])
  const truckImageUrlsRef = useRef<Record<string, string>>({})
  const [truckImageUrls, setTruckImageUrls] = useState<Record<string, string>>(
    {},
  )
  const [currentlyLoadingModel, setCurrentlyLoadingModel] =
    useState<ModelButtonId | null>(null)
  const dispatch = useAppDispatch()
  const t = useTexts()
  const client = useClient()
  const [bevToggleButtonChecked, setBevToggleButtonChecked] =
    useState<boolean>(false)
  const [upgradeYourFleetUrl, setUpgradeYourFleetUrl] = useState<
    string | undefined
  >(undefined)

  // Filter models for BEV mode.
  useEffect(() => {
    if (urlHasBevParam() || bevToggleButtonChecked) {
      const fmStateCopy = deepCopy(factoryModelsStateUnfiltered)
      if (!fmStateCopy.factoryModels) {
        return // Not initialized (?)
      }
      fmStateCopy.factoryModels.response.models =
        fmStateCopy.factoryModels.response.models.filter(
          (m) => !!m.bevPropulsion,
        )
      setFactoryModelsState(fmStateCopy)
    } else {
      setFactoryModelsState(factoryModelsStateUnfiltered)
    }
  }, [factoryModelsStateUnfiltered, bevToggleButtonChecked])

  // Adobe user tracking.
  useEffect(() => {
    if (!marketLanguage) {
      return
    }
    dispatch(
      pushPageViewTrackingEvent({
        pageName: ScaniaAdobeTrackingPageName.FactoryModels,
        marketLanguage,
      }),
    )
  }, [dispatch, marketLanguage])

  const getFactoryModelTextMemoized = useCallback(
    (id: string) => {
      return getFactoryModelText(id, factoryModelsState)
    },
    [factoryModelsState],
  )

  const [itemReadMore, setItemReadMore] = useState<
    Record<string, ReadMoreData>
  >({})

  useEffect(() => {
    const allReadMores = factoryModelsState?.factoryModels?.response?.readMore
    if (!allReadMores) {
      return
    }
    const result: Record<string, ReadMoreData> = {}
    allReadMores.forEach((r) => {
      if (r.id === null || r.id === undefined) {
        console.error('Expected r.id to be defined.')
        return
      }
      if (
        (r.imageUrls === undefined ||
          r.imageUrls === null ||
          r.imageUrls.length === 0) &&
        (r.text === null || r.text === undefined || r.text.length < 1)
      ) {
        return
      }
      const rmData: ReadMoreData = {
        label: getFactoryModelTextMemoized(r.id),
        images: r.imageUrls || [],
        text: r.text,
      }
      result[r.id] = rmData
    })
    setItemReadMore(result)
  }, [client, factoryModelsState, getFactoryModelTextMemoized])

  const handlePreloadedReadmoreClick = useCallback(
    (variant: EtelVariant) => {
      const trackingEvent: ScaniaAdobeTrackingClickFmReadMoreEvent = {
        event: ScaniaAdobeEventId.FmReadMoreClick,
      }
      pushAdobeEvent(trackingEvent)
      const id = toId(variant)
      const readMoreData = itemReadMore[id]
      if (!readMoreData) {
        console.error(`Failed to find readmore data for item ${id}.`)
        return
      }
      dispatch(setReadMoreData(readMoreData))
      dispatch(openPanelReadMore())
    },
    [dispatch, itemReadMore],
  )

  // Called Deferred since the readmore text and image URLs will be loaded by
  // the readmore component.
  const handleDeferredReadmoreClick = useCallback(
    (entry: ReadMoreEntry) => {
      const trackingEvent: ScaniaAdobeTrackingClickFmReadMoreEvent = {
        event: ScaniaAdobeEventId.FmReadMoreClick,
      }
      pushAdobeEvent(trackingEvent)

      dispatch(setReadMoreNode(entry))
      dispatch(openPanelReadMore())
    },
    [dispatch],
  )

  useEffect(() => {
    if (!sessionInitData?.sessionId) {
      return // Not initialized yet.
    }

    // TODO: Revisit later, use a single call in App.tsx.
    dispatch(hideInitialLoadingScreen())
  }, [dispatch, sessionInitData])

  // From attempt_bip:
  //   Name: "MarketDenomination"
  //   Value: "G 370 A4x2NZ"
  //
  // TODO: Build this for each factory model in scds_backend before returning?
  // No. Make one request for each factory model to fetch extra information to
  // allow progressive loading.

  // TODO: Revisit later.
  //usePreventAndroidKeyboardBug()
  const headerClassName = 'factory-models-operations-header'

  useEffect(() => {
    if (visibleFactoryModels.length === 0) {
      return
    }
    const asyncWrapper = async () => {
      const sourceModels = factoryModelsState?.factoryModels?.response?.models
      if (!client) {
        // Waiting for startup.
        return
      }
      if (!sourceModels) {
        return
      }
      if (!marketLanguage) {
        return
      }
      const { keysAndCount, visibleModelsByApplication } =
        getSortedModelsByApplication(
          sourceModels,
          marketLanguage.language,
          visibleFactoryModels,
        )

      let nextSortIndex = 0

      // Sparse array, index -> model id, value -> sort order.
      const sortOrder: number[] = []

      // Build sort order.
      keysAndCount.forEach(({ application }) => {
        visibleModelsByApplication[application]?.forEach((model) => {
          if (sortOrder[model.id] !== undefined) {
            return
          }
          sortOrder[model.id] = nextSortIndex++
        })
      })

      const models = sourceModels.concat()
      models.sort((a, b) => {
        const aOrder = sortOrder[a.id]
        const bOrder = sortOrder[b.id]
        if (aOrder === undefined && bOrder !== undefined) {
          return 1
        }
        if (aOrder !== undefined && bOrder === undefined) {
          return -1
        }
        if (aOrder !== undefined && bOrder !== undefined) {
          // Both a and b are visible models.
          if (aOrder < bOrder) {
            return -1
          }
          if (aOrder > bOrder) {
            return 1
          }
        }
        // Both a and b are currently not visible and should have lower priority
        // than visible models.
        if (a.id < b.id) {
          // Fallback.
          return -1
        }
        if (a.id > b.id) {
          // Fallback.
          return 1
        }
        // Fallback.
        return 0
      })

      for (var i = 0; i < models.length; i++) {
        const model = models[i]
        const modelId = model.id
        if (!modelId) {
          console.error('Found factory model with id: ' + model.id)
          continue
        }
        const loaderStatus = truckImageUrlsRef.current[modelId]
        if (loaderStatus === IMAGE_LOADING) {
          // Already loading, skip.
          continue
        }
        if (loaderStatus !== null && loaderStatus !== undefined) {
          // Already completed.
          continue
        }
        truckImageUrlsRef.current[modelId] = IMAGE_LOADING
        const imgResponse = await client.getFactoryModelImage(
          marketLanguage.market,
          modelId,
          0,
          IMAGE_WIDTH,
        )
        const url = imgResponse.imageUrl
        if (!url) {
          console.error('Expected url to be defined.')
          continue
        }
        const loader = new Image()
        loader.onload = () => {
          truckImageUrlsRef.current[modelId] = url
          const oldUrls: Record<string, string> = {}
          for (const [key, value] of Object.entries(
            truckImageUrlsRef.current,
          )) {
            if (value === IMAGE_LOADING) {
              continue
            }
            if (value === null || value === undefined) {
              console.error('Expected value to be defined.')
              continue
            }
            oldUrls[key] = value
          }
          const newState: Record<string, string> = {
            ...oldUrls,
          }
          newState[modelId] = url
          setTruckImageUrls(newState)
        }
        loader.onerror = (ev) => {
          console.error('Failed to load image: ', ev)
        }
        loader.onabort = (ev) => {
          console.error('Image, onabort : ', ev)
        }
        loader.src = url
      }
    }
    asyncWrapper()
  }, [
    client,
    factoryModelsState,
    marketLanguage,
    setTruckImageUrls,
    truckImageUrlsRef,
    visibleFactoryModels,
  ])

  // Set visible factory models
  useEffect(() => {
    if (!factoryModelsState) {
      return
    }
    if (!marketLanguage) {
      return
    }
    const allModels = factoryModelsState?.factoryModels?.response?.models || []
    const filtered = filterModels(
      allModels,
      viewFilter,
      marketLanguage.language,
    )
    setVisibleFactoryModels(filtered)
  }, [factoryModelsState, viewFilter, marketLanguage])

  useEffect(() => {
    const families = buildFilterFamilies(factoryModelsState)
    setFilterFamilies(families)
  }, [factoryModelsState, setFilterFamilies])

  // TODO: Review this with Lena and Catharina. This visual detail did not work
  // as intended and the application headers did not have the same width. This
  // is fixed now.
  useLayoutEffect(() => {
    const elements = document.getElementsByClassName(headerClassName)
    const headers: HTMLElement[] = []
    for (var i = 0; i < elements.length; i++) {
      const el = elements[i]
      if (el instanceof HTMLElement) {
        headers.push(el)
      } else {
        console.error('Expected HTMLElement.', el)
      }
    }
    let max = 0
    headers.forEach((h) => (max = Math.max(max, h.clientWidth)))
    headers.forEach((h) => (h.style.width = max + 'px'))
  }, [visibleFactoryModels])

  const headerStyle: CSSProperties = {
    backgroundColor: '#041e42',
    color: 'white',
    padding: '4px 16px 2px 16px',
    textAlign: 'center',
    fontSize: '16px',
  }

  const buildImageElement = useCallback(
    (modelId: number | null | undefined): JSX.Element | null => {
      if (modelId === null || modelId === undefined) {
        console.error('Expected modelId to be defined.')
        return null
      }
      const imageUrl: string = truckImageUrls[modelId]
      if (!imageUrl) {
        return null
      }
      const altText: string = '' + modelId
      const onError: ReactEventHandler<HTMLImageElement> = (ev) => {
        console.error('Failed to load image: ', ev)
      }

      const onLoad: ReactEventHandler<HTMLImageElement> = (ev) => {
        ev.currentTarget.width = Math.round(
          ev.currentTarget.naturalWidth / PIXEL_RATIO,
        )
        ev.currentTarget.height = Math.round(
          ev.currentTarget.naturalHeight / PIXEL_RATIO,
        )
        ev.currentTarget.style.display = 'block'
      }
      return (
        <img
          style={{ display: 'none' }}
          alt={altText}
          src={imageUrl}
          onError={onError}
          onLoad={onLoad}
        />
      )
    },
    [truckImageUrls],
  )

  /**
   * @param modelButtonApplication May be null when all view filters are set to 'All'.
   */
  const getFilterChoicesForBuildModeLink = useCallback(
    (modelButtonApplication: EtelVariant | null): EtelVariant[] => {
      const result = Object.entries(viewFilter).flatMap(
        ([family, execution]) => {
          switch (execution) {
            case FILTER_CHOICE_ID_INACTIVE:
              return []
            case FILTER_CHOICE_ID_SHOW_ALL:
              return []
            default:
              return { family, execution }
          }
        },
      )
      const viewFilterApplication = viewFilter[FAMILY_ID_APPLICATION]
      let applicationIsPartOfFilters =
        viewFilterApplication !== FILTER_CHOICE_ID_INACTIVE &&
        viewFilterApplication !== FILTER_CHOICE_ID_SHOW_ALL
      if (!applicationIsPartOfFilters && modelButtonApplication !== null) {
        result.unshift({
          family: FAMILY_ID_APPLICATION,
          execution: modelButtonApplication.execution,
        })
      }
      return result
    },
    [viewFilter],
  )

  const handleModelButtonClick = useCallback(
    async (
      buttonId: ModelButtonId,
      modelName: string,
      extraVariants: EtelVariant[],
    ) => {
      const sessionId = sessionInitData?.sessionId
      if (!sessionId) {
        return
      }
      if (!client) {
        return
      }
      if (!marketLanguage) {
        return
      }
      if (currentlyLoadingModel !== null) {
        console.log('Ignoring click.')
        return
      }
      setCurrentlyLoadingModel(buttonId)
      const cfg = buildFactoryModelConfigString(
        marketLanguage.market,
        buttonId.modelId,
        extraVariants.map(toId),
      )
      const response = await client.loadConfigFromString({
        sessionId,
        config: cfg,
        encoding: 'default',
      })
      if (response.error === SESSION_FAILURE) {
        handleSessionFailure()
        return
      }
      if (!response.loadConfigFromString) {
        console.error('Expected response.loadConfigFromString to be defined.')
        handleFatalError()
        return
      }
      const result = response.loadConfigFromString
      if (result.resultCode !== 'SUCCESS') {
        throw new Error(
          `Failed to load factory model (${buttonId.modelId}), result code: ${result.resultCode}`,
        )
      }

      // For some reason getInitialConsequenceOfChange is not allowed for
      // loading factory models. Seems to be the same in the old GUI.
      // TODO: Investigate why and make all kinds of configuration loading use
      // the same API pattern.
      //const cocResponse = await client.getInitialConsequenceOfChange(
      //  sessionId,
      //  etelLanguage.toString())

      const info: NewTruckLoadedInfo = {
        factoryModelName: modelName,
        isFactoryModel: true,
        publicConfigId: null,
        savedAsId: null,
        savedAsName: null,
        source: TruckLoadedSource.FACTORY_MODEL,
        timeLoaded: new Date(),
      }
      handleNewTruckIsLoaded(info)
      const relativeUrl = buildRelativeBuildPageUrl(marketLanguage)
      const params = relativeUrl.params
      const buildModeUrl = relativeUrl.path + getUrlParametersToString(params)

      // Adobe user tracking.
      dispatch(
        setPendingUserTrackingPageNavigation({
          factoryModelSelected: buttonId.modelId,
          flowName: ScaniaAdobeTrackingFlowName.FactoryModels,
          optionSelected: null,
          stepName: ScaniaAdobeTrackingPageName.FactoryModels,
          stepSkipped: null,
        }),
      )

      navigate(buildModeUrl)
    },
    [
      client,
      currentlyLoadingModel,
      dispatch,
      handleFatalError,
      handleNewTruckIsLoaded,
      handleSessionFailure,
      marketLanguage,
      navigate,
      sessionInitData,
    ],
  )

  const handleUpgradeYourTruckClick = () => {
    const trackingEvent: ScaniaAdobeTrackingClickUpgradeYourTruckEvent = {
      event: ScaniaAdobeEventId.UpgradeYourTruckClick,
      eventInfo: {
        flow: ScaniaAdobePageName.FactoryModels,
      },
    }
    pushAdobeEvent(trackingEvent)
  }

  const buildModelImageButton = useCallback(
    (
      buttonId: ModelButtonId,
      modelName: string,
      superMotor: MenuItem | undefined | null,
      bevPropulsion: MenuItem | undefined | null,
    ): JSX.Element | null => {
      const appId =
        buttonId.application == null
          ? 'no-application-filter-selected'
          : toId(buttonId.application)
      const choices = getFilterChoicesForBuildModeLink(buttonId.application)
      const isLoading = modelButtonIdEquals(buttonId, currentlyLoadingModel)

      return (
        <TruckImageButton
          key={
            'model-' + buttonId.modelId + '-application-' + appId + '-button'
          }
          data-test-element-type={TestElementTypeId.FactoryModelChoiceButton}
          onClick={() => {
            handleModelButtonClick(buttonId, modelName, choices)
          }}
        >
          <TruckImageContainer>
            <TruckImageDiv>
              {buildImageElement(buttonId.modelId)}
              {isLoading && (
                <LoadingIndicatorContainer>
                  <SvgLoading />
                </LoadingIndicatorContainer>
              )}
            </TruckImageDiv>
            <TruckImageText
              data-test-element-type={
                TestElementTypeId.FactoryModelTruckCardTitle
              }
            >
              {modelName}
            </TruckImageText>
            {superMotor && (
              <TruckSubIconButtonWrapper
                data-test-element-type={TestElementTypeId.FactoryModelInfoIcon}
                onClick={(ev) => {
                  // To prevent the click handler for clicks outside the
                  // SidePanelReadMore component from firing. TODO: Use an
                  // invisible div that covers everything except the Read More
                  // panel to close the panel instead.
                  ev.stopPropagation()

                  handleDeferredReadmoreClick({
                    id: superMotor.id,
                    title: superMotor.shortText || null,
                  })
                }}
              >
                <TruckImageSuperButton>
                  <SvgScaniaSuper />
                </TruckImageSuperButton>
              </TruckSubIconButtonWrapper>
            )}
            {bevPropulsion && (
              <TruckSubIconButtonWrapper
                data-test-element-type={TestElementTypeId.FactoryModelInfoIcon}
                onClick={(ev) => {
                  // To prevent the click handler for clicks outside the
                  // SidePanelReadMore component from firing. TODO: Use an
                  // invisible div that covers everything except the Read More
                  // panel to close the panel instead.
                  ev.stopPropagation()

                  handleDeferredReadmoreClick({
                    id: bevPropulsion.id,
                    title: bevPropulsion.shortText || null,
                  })
                }}
              >
                <BevButton />
              </TruckSubIconButtonWrapper>
            )}
          </TruckImageContainer>
        </TruckImageButton>
      )
    },
    [
      buildImageElement,
      currentlyLoadingModel,
      getFilterChoicesForBuildModeLink,
      handleDeferredReadmoreClick,
      handleModelButtonClick,
    ],
  )

  const buildTruckImageComponents = (
    models: FactoryModelV2[],
    application: EtelVariant,
  ) => {
    return models.map((model) => {
      if (model.id === undefined || model.id === null) {
        console.error('Expected model.id to be defined.')
        return null
      }
      //const link = `/factory-model-summary/${etelMarket}/${etelLanguage}/${model.id}`
      let modelName = getModelName(model, marketLanguage?.language || null)
      return buildModelImageButton(
        { modelId: model.id, application },
        modelName,
        model.superEngine,
        model.bevPropulsion,
      )
    })
  }

  // TODO: This looks like copy paste, try to eliminate.
  const buildTruckImageComponentsForActiveFilters = (
    models: FactoryModelV2[],
  ) => {
    const duplicate: Number[] = []
    return models.map((model) => {
      if (model.id === undefined || model.id === null) {
        console.error('Expected model.id to be defined.')
        return null
      }
      if (duplicate.includes(model.id)) {
        return null
      }
      duplicate.push(model.id)
      const modelName = getModelName(model, marketLanguage?.language || null)
      const application = null
      return buildModelImageButton(
        { modelId: model.id, application },
        modelName,
        model.superEngine,
        model.bevPropulsion,
      )
    })
  }

  const buildDefaultTruckImageGrids = (): React.ReactNode => {
    if (!marketLanguage) {
      return null
    }
    const allModels = factoryModelsState.factoryModels?.response?.models || []
    const { keysAndCount, visibleModelsByApplication, allModelsByApplication } =
      getSortedModelsByApplication(
        allModels,
        marketLanguage.language,
        visibleFactoryModels,
      )
    const fragments: JSX.Element[] = []
    for (const keyAndCount of keysAndCount) {
      const application = keyAndCount.application
      const models = visibleModelsByApplication[application]
      if (!models) {
        continue
      }
      const appVariant = toEtelVariant(application)
      const firstFew = models.slice(0, DEFAULT_MODELS_VISIBLE_PER_APPLICATION)
      const visibleTrucks = buildTruckImageComponents(firstFew, appVariant)
      if (!visibleTrucks) {
        continue
      }
      const allTrucksForApp = allModelsByApplication[application]
      if (!allTrucksForApp) {
        continue
      }
      const textTemplate = t('FM_SEE_ALL_IN_OPERATION_BUTTON_TEXT')
      const buttonText = textTemplate.replace(
        '{}',
        getFactoryModelTextMemoized(application),
      )
      const extraTrucks = allTrucksForApp.length - visibleTrucks.length
      const viewAllButtonStyle: CSSProperties =
        extraTrucks > 0
          ? {
              visibility: 'visible',
            }
          : {
              visibility: 'hidden',
              height: '0',
              marginBottom: '0',
            }
      const viewAllButton = (
        <ViewAllTrucksForApplicationButton
          style={viewAllButtonStyle}
          onClick={() => {
            const ev: ScaniaAdobeTrackingClickFmShowAllEvent = {
              event: ScaniaAdobeEventId.FmShowAllClick,
            }
            pushAdobeEvent(ev)
            const f = newViewFilter(FILTER_CHOICE_ID_SHOW_ALL)
            f[appVariant.family] = appVariant.execution
            setViewFilter(f)
          }}
          number={extraTrucks}
          text={buttonText}
          imageWidth={(IMAGE_WIDTH - SHADOW_MARGIN) / PIXEL_RATIO}
        />
      )
      fragments.push(
        <TruckImageGroup key={application}>
          <ApplicationDividerContainer>
            <div className={headerClassName} style={headerStyle}>
              {getFactoryModelTextMemoized(application)}
            </div>
            <ReadMoreInfoButton
              data-test-element-type={TestElementTypeId.FactoryModelInfoIcon}
              onClick={(ev) => {
                ev.stopPropagation()
                handlePreloadedReadmoreClick(appVariant)
              }}
            >
              <Info></Info>
            </ReadMoreInfoButton>
            <OperationsDividerRemainingSpace />
          </ApplicationDividerContainer>
          <TruckImageGrid $filters={false}>
            {visibleTrucks}
            {viewAllButton}
          </TruckImageGrid>
        </TruckImageGroup>,
      )
    }
    return fragments
  }

  const buildTruckImageGridForFilters = (): JSX.Element => {
    const visibleTrucks =
      buildTruckImageComponentsForActiveFilters(visibleFactoryModels)

    if (visibleTrucks.length === 0) {
      return (
        <MissingTrucksContainer>
          <SvgTruck className="icon" />
          <p>{t('FM_NO_VISIBLE_FILTERED_TRUCKS')}</p>
        </MissingTrucksContainer>
      )
    }

    return (
      <TruckImageGroup>
        <TruckImageGrid $filters={true}>{visibleTrucks}</TruckImageGrid>
      </TruckImageGroup>
    )
  }

  const handleFilterSelection = useCallback(
    (selection: FactoryModelsFilterSelection) => {
      const replaceInactiveWithShowAll = (filter: ViewFilter) => {
        for (const [k, v] of Object.entries(filter)) {
          if (v === FILTER_CHOICE_ID_INACTIVE) {
            filter[k] = FILTER_CHOICE_ID_SHOW_ALL
          }
        }
      }
      if (selection.etelExecutionId === FILTER_CHOICE_ID_INACTIVE) {
        setViewFilter(buildDefaultViewFilter())
        return
      } else if (selection.etelExecutionId === FILTER_CHOICE_ID_SHOW_ALL) {
        setViewFilter((prev) => {
          const newViewFilter = { ...viewFilter }
          newViewFilter[selection.etelFamilyId] = selection.etelExecutionId
          replaceInactiveWithShowAll(newViewFilter)
          return newViewFilter
        })
      } else {
        setViewFilter((prev) => {
          const newViewFilter = { ...viewFilter }
          newViewFilter[selection.etelFamilyId] = selection.etelExecutionId
          replaceInactiveWithShowAll(newViewFilter)
          return newViewFilter
        })
      }
    },
    [viewFilter],
  )

  // The design requires multiple lines of large text that in turn positions the
  // smaller texts both to the left and the right.
  const titleLines = t('FM_PAGE_TITLE')
    .split(' ')
    .filter((w) => w !== '')
    .reduce<string[]>((acc, word) => {
      const result = acc.concat()
      const MAX_LINE_LENGTH = 15 // A rough visual estimate.
      let lastLine = result[result.length - 1]
      if (lastLine && lastLine.length + 1 + word.length < MAX_LINE_LENGTH) {
        result[result.length - 1] = lastLine + ' ' + word
      } else {
        result.push(word)
      }
      return result
    }, [])

  // Set link to upgrade your fleet
  useEffect(() => {
    const generealUyfUrl = startupData?.settings.upgradeYourFleetLinkUrl
    if (!generealUyfUrl) {
      return
    }
    const marketCid = marketLanguage?.isoMarketCode
      ? '/?cid=dsstart_' + marketLanguage.isoMarketCode.toLowerCase()
      : '/?cid=dsstart_unknown'
    const uyfUrl = generealUyfUrl + marketCid
    setUpgradeYourFleetUrl(uyfUrl)
  }, [marketLanguage, startupData?.settings])

  return (
    <FactoryModelsView ref={componentRootRef}>
      <ScrollContent>
        <PageContent>
          <PageHeaderContainer>
            <LayoutDummy />
            <PageTitleContainer>
              <span className="tds-paragraph-01">
                {t('FM_PAGE_TITLE_HEAD')}
              </span>
              <PageTitle className="tds-expressive-headline-01">
                {titleLines.map((line, i) => (
                  <p key={'fm-title-line-' + i}>{line}</p>
                ))}
              </PageTitle>
              <span className="tds-paragraph-01">
                {t('FM_PAGE_TITLE_TAIL')}
              </span>
            </PageTitleContainer>
            <ControlBoxFlexCenter>
              <FactoryModelsControlPanel
                viewFilter={viewFilter}
                allowedFilterFamilyIds={allowedFilterFamilyIds}
                filterFamilies={filterFamilies}
                getFactoryModelText={getFactoryModelTextMemoized}
                handleFilterSelection={handleFilterSelection}
                showBevButton={
                  factoryModelsStateUnfiltered.hasBevModels &&
                  factoryModelsStateUnfiltered.mode ===
                    GoFmMode.OnlyFactoryModels
                }
                handleBevToggle={(checked: boolean) => {
                  setBevToggleButtonChecked(checked)
                }}
              />
            </ControlBoxFlexCenter>
          </PageHeaderContainer>
          <TruckImageAreaFlexCenter
            $showUYF={marketSettings?.linkUpgradeFleetEnabled || false}
          >
            <TruckImageAreaWidthLimiter>
              {marketSettings?.linkUpgradeFleetEnabled && (
                <UpgradeYourFleetWrapper>
                  <UpgradeYourFleetText>
                    {t(TextId.DESCRIPTION_UPGRADE_YOUR_FLEET_FM)}
                  </UpgradeYourFleetText>

                  <a href={upgradeYourFleetUrl}>
                    <TdsButton
                      variant="secondary"
                      text={t(TextId.ACTION_UPGRADE_YOUR_TRUCK)}
                      size="sm"
                      onClick={handleUpgradeYourTruckClick}
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      <TdsIcon slot="icon" name="chevron_right" size="16px" />
                    </TdsButton>
                  </a>
                </UpgradeYourFleetWrapper>
              )}
              {
                // TODO: Refactor all build* functions to proper React component
                // functions and call them with the <Element> syntax. Introduce
                // a separate data transform step first if needed as input to
                // the new elements.
              }
              {isDefaultViewFilter(viewFilter)
                ? buildDefaultTruckImageGrids()
                : buildTruckImageGridForFilters()}
            </TruckImageAreaWidthLimiter>
          </TruckImageAreaFlexCenter>
        </PageContent>
        <PortraitFooter displayAtScreenMaxWidth={BreakpointWidthPx.Tablet} />
      </ScrollContent>
    </FactoryModelsView>
  )
}

function getSortedModelsByApplication(
  models: FactoryModelV2[],
  etelLanguage: number,
  visibleFactoryModels: FactoryModelV2[],
) {
  const allModelsByApplication = getModelsByApplication(models, etelLanguage)
  const keysAndCount = []
  for (const application in allModelsByApplication) {
    const models = allModelsByApplication[application]
    const count = models.length
    keysAndCount.push({ application, count })
  }
  keysAndCount.sort((a, b) => b.count - a.count)
  const visibleModelsByApplication = getModelsByApplication(
    visibleFactoryModels,
    etelLanguage,
  )
  return { keysAndCount, visibleModelsByApplication, allModelsByApplication }
}
