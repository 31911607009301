/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const EtelBuildModeStatus = {
  Ok: 'Ok',
  NoConfigurationLoaded: 'NoConfigurationLoaded',
} as const
export type EtelBuildModeStatus =
  (typeof EtelBuildModeStatus)[keyof typeof EtelBuildModeStatus]

export function EtelBuildModeStatusFromJSON(json: any): EtelBuildModeStatus {
  return EtelBuildModeStatusFromJSONTyped(json, false)
}

export function EtelBuildModeStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): EtelBuildModeStatus {
  return json as EtelBuildModeStatus
}

export function EtelBuildModeStatusToJSON(
  value?: EtelBuildModeStatus | null,
): any {
  return value as any
}
