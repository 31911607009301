import { ChevronRight } from 'react-feather'
import styled from 'styled-components'
// import { ReactComponent as TruckConfiguratorIcon } from '../../assets/icons/truck-configurator-24-48px.svg'

const EditTruckButtonRoot = styled.a`
  --color: ${({ theme }) => theme.scWhite};
  --bg-color: #3d71ce;
  cursor: pointer;
  background-color: var(--bg-color);
  display: flex;
  align-items: center;
  padding: 8px 12px 8px 12px;
  color: var(--color);
  text-decoration: none;
  height: 100%;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      //filter: drop-shadow(8px 8px 5px #041e4222);
      filter: brightness(1.15);
    }
  }

  &:active {
    filter: unset;
  }
  .truck-configurator-icon path {
    fill: var(--color);
  }
  .truck-configurator-icon circle {
    stroke: var(--color);
  }
  .truck-configurator-icon line {
    stroke: var(--color);
  }
`

const MainText = styled.p`
  font-size: 1.1rem;
  text-transform: uppercase;
  font-family: 'Scania Sans Bold', Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 0;
`

const SmallText = styled.p`
  font-size: 0.7rem;
  padding: 0;
  margin: 0;
`

const RightIconWrapper = styled.div`
  display: flex;
  margin-left: 12px;
  animation: 500ms ease-in-out 0s infinite alternate horizontal-bounce;

  @keyframes horizontal-bounce {
    from {
      transform: translateX(0%);
    }
    to {
      transform: translateX(20%);
    }
  }
`

export interface EditTruckButtonProps {
  mainText: string
  smallText: string
  link: string
}

export const EditTruckButton = (props: EditTruckButtonProps): JSX.Element => {
  return (
    <EditTruckButtonRoot href={props.link}>
      {/*
        <TruckConfiguratorIcon
          style={{
            width: '40px',
            height: '40px',
            marginRight: '8px',
          }}
          className="truck-configurator-icon"
        />
        */}
      <div>
        <MainText>{props.mainText}</MainText>
        <SmallText>{props.smallText}</SmallText>
      </div>
      <RightIconWrapper>
        <ChevronRight />
      </RightIconWrapper>
    </EditTruckButtonRoot>
  )
}
