/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ScaniaSearchResult
 */
export interface ScaniaSearchResult {
  /**
   *
   * @type {string}
   * @memberof ScaniaSearchResult
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof ScaniaSearchResult
   */
  path: string
  /**
   *
   * @type {string}
   * @memberof ScaniaSearchResult
   */
  description: string
}

/**
 * Check if a given object implements the ScaniaSearchResult interface.
 */
export function instanceOfScaniaSearchResult(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'path' in value
  isInstance = isInstance && 'description' in value

  return isInstance
}

export function ScaniaSearchResultFromJSON(json: any): ScaniaSearchResult {
  return ScaniaSearchResultFromJSONTyped(json, false)
}

export function ScaniaSearchResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ScaniaSearchResult {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['Id'],
    path: json['Path'],
    description: json['Description'],
  }
}

export function ScaniaSearchResultToJSON(
  value?: ScaniaSearchResult | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    Id: value.id,
    Path: value.path,
    Description: value.description,
  }
}
