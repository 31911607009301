/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { FlexImageV2RequestParamsFlexParams } from './FlexImageV2RequestParamsFlexParams'
import {
  FlexImageV2RequestParamsFlexParamsFromJSON,
  FlexImageV2RequestParamsFlexParamsFromJSONTyped,
  FlexImageV2RequestParamsFlexParamsToJSON,
} from './FlexImageV2RequestParamsFlexParams'

/**
 * Parameter object for flex_image_v2. TODO: Remove the _v2 suffix when
 * the old flex_image is gone.
 * @export
 * @interface FlexImageV2Request
 */
export interface FlexImageV2Request {
  /**
   *
   * @type {string}
   * @memberof FlexImageV2Request
   */
  sessionId: string
  /**
   *
   * @type {FlexImageV2RequestParamsFlexParams}
   * @memberof FlexImageV2Request
   */
  flexParams: FlexImageV2RequestParamsFlexParams
  /**
   *
   * @type {boolean}
   * @memberof FlexImageV2Request
   */
  showBodyBuild: boolean
}

/**
 * Check if a given object implements the FlexImageV2Request interface.
 */
export function instanceOfFlexImageV2Request(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'sessionId' in value
  isInstance = isInstance && 'flexParams' in value
  isInstance = isInstance && 'showBodyBuild' in value

  return isInstance
}

export function FlexImageV2RequestFromJSON(json: any): FlexImageV2Request {
  return FlexImageV2RequestFromJSONTyped(json, false)
}

export function FlexImageV2RequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): FlexImageV2Request {
  if (json === undefined || json === null) {
    return json
  }
  return {
    sessionId: json['sessionId'],
    flexParams: FlexImageV2RequestParamsFlexParamsFromJSON(json['flexParams']),
    showBodyBuild: json['showBodyBuild'],
  }
}

export function FlexImageV2RequestToJSON(
  value?: FlexImageV2Request | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    sessionId: value.sessionId,
    flexParams: FlexImageV2RequestParamsFlexParamsToJSON(value.flexParams),
    showBodyBuild: value.showBodyBuild,
  }
}
