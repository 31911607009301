import { useCallback, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import { SvgChevronDown, SvgInfo } from '../../../components/SvgImports'
import {
  ScaniaAdobeTrackingClickBmReadMoreEvent,
  pushAdobeEvent,
  ScaniaAdobeEventId,
  ScaniaAdobeTrackingButtonPlacement,
} from '../../../utils/adobeAnalytics'
import { VisibleMenuEntry } from './ConfiguratorMenu'
import { CurrentSelection } from './LeafMenu/CurrentSelection'
import { blinkKeyframes } from '../MenuBlinkAnimation'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { BreakpointWidthPx } from '../../../css/BreakpointWidthPx'
import { isTabletOrSmaller } from '../../../utils/screenQuery'
import {
  openPanelReadMore,
  setReadMoreNode,
} from '../../../store/sidePanelSlice'
import { getMenuState } from '../../../store/menuSlice'

const SubMenuRoot = styled.div``

interface SubMenuItemProps {
  $menuLevel: number
  $isActive: boolean
  $isHighlighted: boolean
  $hasSelectableItems: boolean
}

const SubMenuItemWrapper = styled.div<SubMenuItemProps>`
  --bg-color: ${(props) =>
    props.$isActive ? 'var(--tds-grey-100)' : 'var(--tds-white)'};
  background-color: var(--bg-color);
  color: ${({ $hasSelectableItems }) =>
    $hasSelectableItems ? 'var(--tds-grey-958)' : 'var(--tds-grey-500)'};
  display: flex;
  align-items: center;
  position: relative;
  height: ${(props) => {
    if (props.$menuLevel === 1) {
      return '68px'
    } else {
      return '48px'
    }
  }};

  ${(props) =>
    props.$menuLevel === 1 &&
    css`
      border-bottom: thin solid var(--tds-grey-300);
    `}

  ${(props) =>
    props.$menuLevel > 2 &&
    props.$isActive &&
    css`
      @media screen and (max-width: ${BreakpointWidthPx.Tablet}px) {
        border-bottom: thin solid var(--tds-blue-400);
      }
    `}

    @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: var(--tds-grey-50);
      cursor: pointer;
    }
  }

  animation-duration: 500ms;
  animation-iteration-count: 3;
  animation-name: ${(props) =>
    props.$isHighlighted ? blinkKeyframes : 'unset'};
`

const CurrentSelectionWrapper = styled.div`
  display: none;
  margin-left: 24px;
  border-right: thin solid var(--tds-blue-400);
  border-bottom: thin solid var(--tds-blue-400);
  border-left: thin solid var(--tds-blue-400);

  @media screen and (max-width: ${BreakpointWidthPx.Tablet}px) {
    display: block;
  }
`

const BlueOpenIndicator = styled.div`
  min-width: 8px;
  height: 48px;
  float: left;
  background-color: var(--tds-blue-400);
`

const Level3Container = styled.div``

interface IconWrapperProps {
  $hasReadMore: boolean
}

const IconSpisaWrapper = styled.div<IconWrapperProps>`
  margin-right: 16px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  // TODO: Revisit and try to eliminate.
  z-index: 5;

  visibility: hidden;

  ${({ $hasReadMore }) =>
    $hasReadMore &&
    css`
      cursor: help;
      visibility: visible;
    `};

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: ${(props) =>
        props.$hasReadMore ? 'var(--tds-grey-50)' : ''};
    }
  }

  svg {
    margin-left: 6px;
    height: 20px;
    width: 20px;
  }
`

interface MenuItemProps {
  $menuLevel: number
  $hasSelectableItems: boolean
}

const MenuItem = styled.div<MenuItemProps>`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  font-weight: ${(props) => (props.$hasSelectableItems ? 'bold' : '')};
  /*
  padding-left: ${(props) =>
    props.$menuLevel === 1
      ? '16px'
      : '16px'}; // No padding for level 1 if there are icons
  */
  padding-left: ${(props) => (props.$menuLevel > 2 ? '' : '16px')};
  text-overflow: ellipsis;
`

const MenuItemTextEllipsis = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

interface ChevronWrapperProps {
  $isOpen: boolean
  $menuLevel: number
}

const ChevronWrapper = styled.div<ChevronWrapperProps>`
  ${(props) =>
    props.$menuLevel > 2 &&
    css`
      visibility: hidden;

      @media screen and (max-width: ${BreakpointWidthPx.Tablet}px) {
        visibility: visible;
      }
    `}

  svg {
    height: 16px;
    width: 16px;
    margin-right: 24px;
    margin-left: 24px;

    transform: ${(props) => (props.$isOpen ? 'rotate(180deg)' : '')};
    transition-duration: 150ms;
    transition-property: transform;
  }
`

function leafMenuInsideMainMenuMode() {
  return isTabletOrSmaller()
}

function shouldScrollIntoView(props: SubMenuProps) {
  if (props.node.id === props.highlightedId) {
    return true
  }
  if (leafMenuInsideMainMenuMode()) {
    return false
  }
  if (props.menuLevel !== 3) {
    return false
  }
  if (!props.node.isOpen) {
    return false
  }
  if (!props.highlightedId) {
    return false
  }
  return true
}

export interface SubMenuProps {
  handleMenuSelection: (id: string) => void
  handleItemSelection: (
    id: string,
    placement: ScaniaAdobeTrackingButtonPlacement,
  ) => void
  menuLevel: number
  node: VisibleMenuEntry
  highlightedId: string | null
}

export const SubMenu = (props: SubMenuProps): JSX.Element => {
  const rootRef = useRef<HTMLDivElement | null>(null)
  const dispatch = useAppDispatch()
  const { visibleMenuState } = useAppSelector(getMenuState)
  const isActiveMenu = props.node.id === visibleMenuState?.activeMenuId

  useEffect(() => {
    if (!rootRef.current) {
      return
    }
    if (!shouldScrollIntoView(props)) {
      return
    }
    // This is a desperate quickfix, for some reason scrolling will not work
    // without the nested requestAnimationFrame calls.
    //
    // TODO: When there is time, try moving this scroll code out to
    // ConfiguratorMenu or BuildModePage?
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          if (!rootRef.current) {
            console.warn('Expected rootRef.current to be defined.')
            return
          }
          rootRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          })
        })
      })
    })
  }, [props])

  const handleReadmoreClick = useCallback(
    async (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      node: VisibleMenuEntry,
    ) => {
      e.stopPropagation()
      e.preventDefault()

      dispatch(openPanelReadMore())
      dispatch(setReadMoreNode({ id: node.id, title: node.text }))

      const trackingEvent: ScaniaAdobeTrackingClickBmReadMoreEvent = {
        event: ScaniaAdobeEventId.BmReadMoreClick,
      }
      pushAdobeEvent(trackingEvent)
    },
    [dispatch],
  )

  return (
    <SubMenuRoot data-name="SubMenuRoot" ref={rootRef}>
      <SubMenuItemWrapper
        data-name="ItemWrapper"
        data-test-menu-id={props.node.id}
        $hasSelectableItems={
          visibleMenuState?.menusWithSelectableItems[props.node.id] === true
        }
        $isActive={isActiveMenu}
        $isHighlighted={props.node.id === props.highlightedId}
        $menuLevel={props.menuLevel}
        onClick={() => props.handleMenuSelection(props.node.id)}
      >
        {props.menuLevel > 2 && (
          <Level3Container>
            <IconSpisaWrapper
              $hasReadMore={props.node.hasReadmore}
              onClick={(e) => handleReadmoreClick(e, props.node)}
            >
              <BlueOpenIndicator
                style={{ visibility: props.node.isOpen ? 'visible' : 'hidden' }}
              />
              <SvgInfo />
            </IconSpisaWrapper>
          </Level3Container>
        )}
        <MenuItem
          $menuLevel={props.menuLevel}
          $hasSelectableItems={
            visibleMenuState?.menusWithSelectableItems[props.node.id] === true
          }
        >
          <MenuItemTextEllipsis>{props.node.text}</MenuItemTextEllipsis>
        </MenuItem>
        <ChevronWrapper
          data-name="Chevron"
          $isOpen={props.node.isOpen}
          $menuLevel={props.menuLevel}
        >
          <SvgChevronDown />
        </ChevronWrapper>
      </SubMenuItemWrapper>
      {props.menuLevel > 2 &&
        props.node.isOpen &&
        visibleMenuState?.leafNodes &&
        visibleMenuState?.leafNodes.length > 0 && (
          <CurrentSelectionWrapper>
            <CurrentSelection
              handleItemSelection={(id: string) =>
                props.handleItemSelection(
                  id,
                  ScaniaAdobeTrackingButtonPlacement.LeftMenu,
                )
              }
              highlightedId={props.highlightedId}
            ></CurrentSelection>
          </CurrentSelectionWrapper>
        )}
      {props.node.isOpen
        ? props.node.childIds?.map((id) => {
            const childNode = visibleMenuState?.menuNodes[id]
            if (!childNode) {
              throw new Error('Expected to find a menu node with id: ' + id)
            }
            return (
              <SubMenu
                handleMenuSelection={props.handleMenuSelection}
                handleItemSelection={props.handleItemSelection}
                key={id}
                menuLevel={props.menuLevel + 1}
                node={childNode}
                highlightedId={props.highlightedId}
              />
            )
          })
        : null}
    </SubMenuRoot>
  )
}
