/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface GetStatelessScaniaSystemInfoResponseWrapperGetStatelessScaniaSystemInfo
 */
export interface GetStatelessScaniaSystemInfoResponseWrapperGetStatelessScaniaSystemInfo {
  /**
   *
   * @type {string}
   * @memberof GetStatelessScaniaSystemInfoResponseWrapperGetStatelessScaniaSystemInfo
   */
  partPeriod: string
  /**
   *
   * @type {string}
   * @memberof GetStatelessScaniaSystemInfoResponseWrapperGetStatelessScaniaSystemInfo
   */
  desiredDealerDate: string
  /**
   *
   * @type {Array<string>}
   * @memberof GetStatelessScaniaSystemInfoResponseWrapperGetStatelessScaniaSystemInfo
   */
  tiledMenus: Array<string>
}

/**
 * Check if a given object implements the GetStatelessScaniaSystemInfoResponseWrapperGetStatelessScaniaSystemInfo interface.
 */
export function instanceOfGetStatelessScaniaSystemInfoResponseWrapperGetStatelessScaniaSystemInfo(
  value: object,
): boolean {
  let isInstance = true
  isInstance = isInstance && 'partPeriod' in value
  isInstance = isInstance && 'desiredDealerDate' in value
  isInstance = isInstance && 'tiledMenus' in value

  return isInstance
}

export function GetStatelessScaniaSystemInfoResponseWrapperGetStatelessScaniaSystemInfoFromJSON(
  json: any,
): GetStatelessScaniaSystemInfoResponseWrapperGetStatelessScaniaSystemInfo {
  return GetStatelessScaniaSystemInfoResponseWrapperGetStatelessScaniaSystemInfoFromJSONTyped(
    json,
    false,
  )
}

export function GetStatelessScaniaSystemInfoResponseWrapperGetStatelessScaniaSystemInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GetStatelessScaniaSystemInfoResponseWrapperGetStatelessScaniaSystemInfo {
  if (json === undefined || json === null) {
    return json
  }
  return {
    partPeriod: json['PartPeriod'],
    desiredDealerDate: json['DesiredDealerDate'],
    tiledMenus: json['TiledMenus'],
  }
}

export function GetStatelessScaniaSystemInfoResponseWrapperGetStatelessScaniaSystemInfoToJSON(
  value?: GetStatelessScaniaSystemInfoResponseWrapperGetStatelessScaniaSystemInfo | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    PartPeriod: value.partPeriod,
    DesiredDealerDate: value.desiredDealerDate,
    TiledMenus: value.tiledMenus,
  }
}
