/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface GetScaniaCultureInfoResponseWrapperGetScaniaCultureInfo
 */
export interface GetScaniaCultureInfoResponseWrapperGetScaniaCultureInfo {
  /**
   *
   * @type {string}
   * @memberof GetScaniaCultureInfoResponseWrapperGetScaniaCultureInfo
   */
  languageIso: string
  /**
   *
   * @type {string}
   * @memberof GetScaniaCultureInfoResponseWrapperGetScaniaCultureInfo
   */
  marketIso: string
  /**
   *
   * @type {string}
   * @memberof GetScaniaCultureInfoResponseWrapperGetScaniaCultureInfo
   */
  localization: string
}

/**
 * Check if a given object implements the GetScaniaCultureInfoResponseWrapperGetScaniaCultureInfo interface.
 */
export function instanceOfGetScaniaCultureInfoResponseWrapperGetScaniaCultureInfo(
  value: object,
): boolean {
  let isInstance = true
  isInstance = isInstance && 'languageIso' in value
  isInstance = isInstance && 'marketIso' in value
  isInstance = isInstance && 'localization' in value

  return isInstance
}

export function GetScaniaCultureInfoResponseWrapperGetScaniaCultureInfoFromJSON(
  json: any,
): GetScaniaCultureInfoResponseWrapperGetScaniaCultureInfo {
  return GetScaniaCultureInfoResponseWrapperGetScaniaCultureInfoFromJSONTyped(
    json,
    false,
  )
}

export function GetScaniaCultureInfoResponseWrapperGetScaniaCultureInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GetScaniaCultureInfoResponseWrapperGetScaniaCultureInfo {
  if (json === undefined || json === null) {
    return json
  }
  return {
    languageIso: json['LanguageIso'],
    marketIso: json['MarketIso'],
    localization: json['Localization'],
  }
}

export function GetScaniaCultureInfoResponseWrapperGetScaniaCultureInfoToJSON(
  value?: GetScaniaCultureInfoResponseWrapperGetScaniaCultureInfo | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    LanguageIso: value.languageIso,
    MarketIso: value.marketIso,
    Localization: value.localization,
  }
}
