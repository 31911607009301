import styled from 'styled-components'
import useTexts from '../../utils/useTexts'

const LoadingTruckRoot = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.scDarkBlue};

  // TODO: Revisit and try to eliminate.
  z-index: 10;

  #animLayer,
  #circle {
    stroke: white;
    stroke-width: 3px;
  }
  #animLayer {
    --easing: cubic-bezier(0.8, 0.16, 0.9, 0.39);
    transition: all 800ms cubic-bezier(1, 0, 0.97, 0.9);
    transform: scale(1) rotateY(0deg);
    transform-origin: center center;
  }
  #roof {
    animation-name: roof;
    animation-duration: 3.4s;
    animation-iteration-count: infinite;
  }
  #body {
    transform: translate(2px, 2px);
    animation-name: body;
    animation-duration: 3.4s;
    animation-iteration-count: infinite;
  }
  #left {
    animation-name: left;
    animation-duration: 3.4s;
    animation-iteration-count: infinite;
  }
  #right {
    animation-name: right;
    animation-duration: 3.4s;
    animation-iteration-count: infinite;
  }
  @keyframes roof {
    0% {
      transform: translateY(0px);
      animation-timing-function: var(--easing);
    }
    60% {
      transform: translateY(-20px);
    }
    88% {
      transform: translateY(0px);
      animation-timing-function: ease-out;
    }
    100% {
      transform: translateY(0px);
      animation-timing-function: var(--easing);
    }
  }
  @keyframes body {
    0% {
      transform: translate(2px, 2px);
      animation-timing-function: var(--easing);
    }
    60% {
      transform: translate(2px, 22px);
    }
    88% {
      transform: translate(2px, 2px);
      animation-timing-function: ease-out;
    }
    100% {
      transform: translate(2px, 2px);
    }
  }
  @keyframes left {
    0% {
      transform: translateX(0px);
      animation-timing-function: var(--easing);
    }
    60% {
      transform: translateX(-20px);
    }
    88% {
      transform: translateX(0px);
      animation-timing-function: ease-out;
    }
    100% {
      transform: translateX(0px);
    }
  }
  @keyframes right {
    0% {
      transform: translateX(0);
      animation-timing-function: var(--easing);
    }
    60% {
      transform: translateX(20px);
    }
    88% {
      transform: translateX(0);
      animation-timing-function: ease-out;
    }
    100% {
      transform: translateX(0);
    }
  }
`

const IconTextWrapper = styled.div`
  --timing: 1000ms;
  width: 100vw;
  height: 100vh;
  transition: opacity var(--timing) linear 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  box-shadow: inset 0 0 10vw rgba(0, 0, 0, 0.8);
  will-change: contents;

  svg {
    --svg-width: 50vh;
    width: var(--svg-width);
    height: var(--svg-width);
    max-width: 80vmin;
    min-width: 80vw;
    opacity: 0.1;
    transition: var(--timing) all ease-out;
  }
`

const TextLoading = styled.span`
  font-size: clamp(0.25rem, 2.5vh, 1rem);
  font-family: 'Scania Sans Bold', sans-serif;
  color: #ccc;

  opacity: 0.3;
  transition: opacity 1s linear 0.5s;
  letter-spacing: 0.4em;
  transition: letter-spacing 10s ease-out 0.5s;

  .transitionin {
    opacity: 0;
    letter-spacing: -0.1em;
  }
`

export function LoadingTruck(): JSX.Element {
  const t = useTexts()

  function loadLoadingText() {
    const textNode = document.querySelector('.loadingtextJumpToBm')
    if (!textNode) {
      return
    }
    textNode.classList.remove('transitionin')
  }
  loadLoadingText()

  return (
    <LoadingTruckRoot>
      <IconTextWrapper className="loadingJumpToBm">
        <svg viewBox="0 0 228 228">
          <g
            id="animLayer"
            data-name="Lager 2"
            fill="none"
            stroke="#fff"
            strokeWidth="{4}"
          >
            <path
              d="M145.18 74.15S133.32 67.4 114 67.4s-31.21 6.75-31.21 6.75v18.31h62.42z"
              id="roof"
            />
            <path
              d="M80.76 108.92s11.85 6.75 31.21 6.75 31.21-6.75 31.21-6.75V90.61H80.76z"
              transform="translate(2 2)"
              id="window"
            />
            <path d="M73.15 106.56l9.6 4.51V92.46h-9.6v14.1z" id="left" />
            <path d="M154.78 106.56l-9.6 4.51V92.46h9.6v14.1z" id="right" />
            <path
              d="M137.75 111.17l-9 25.51a48.28 48.28 0 01-16.91 3.3 46.39 46.39 0 01-16.7-3.3l-9-25.51-5.37-2.1v50.11H92l3.58-12.3h32.71l3.58 12.3h11.28v-50.11z"
              transform="translate(2 2)"
              id="body"
            />
          </g>
          <g
            id="circle"
            data-name="circle"
            fill="none"
            stroke="#fff"
            strokeWidth="4"
          >
            <circle cx="114" cy="114" r="107" id="ring" />
          </g>
        </svg>
        <TextLoading className="loadingtextJumpToBm transitionin">
          {(t('HEADER_LOAD_WARMING_UP') || '').replace(/\.*$/, '') + '...'}
        </TextLoading>
      </IconTextWrapper>
    </LoadingTruckRoot>
  )
}
