import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import type { RootState } from './index'
import { RfqParams, UserConfigInfo } from '../api/generated'
import { GuidedOfferingClientState } from '../types/GuidedOffering'
import { ScaniaAdobeTrackingFlowInfo } from '../utils/adobeAnalytics'
import {
  ExtendedCheckStatus,
  ImageSeriesFrame,
  LoginStatus,
  PageViewTrackingEventPayload,
  ReadMoreData,
  SessionError,
} from './types'
import { StartupDataBundle } from '../api/startup'
import { GuidedOfferingClientStateStatus } from '../types/GuidedOffering/GuidedOfferingClientState'

export interface SessionState {
  account: { isloggedin: boolean; userMail: string }
  bodyBuildToggled: boolean
  counter: number
  error: SessionError
  extendedCheckState: ExtendedCheckStatus
  garage: { list: UserConfigInfo[] | null }

  /**
   * Should be populated by the response object from goWizadsStart, this data is
   * only available on that response object, not on goWizardContinue response
   * objects.
   */
  goEtelLastUpdated: string | null // Samma som finns på GuidedOfferingClientState?

  /**
   * The current Guided Offering state, should first be set by storing the
   * response from goWizardStart and then overwritten by response objects from
   * goWizardContinue and finally set to null again when the Guided Offering
   * sequence is completed.
   *
   * Should be null for pure Factory Model markets.
   */
  guidedOfferingState: GuidedOfferingClientState | null

  loadingOverlayEnabled: boolean
  marketDenomination: string | null
  navopen: boolean
  pendingUserTrackingGoSelection: ScaniaAdobeTrackingFlowInfo | null
  preload: {
    initTime: number | null
    minDuration: number
    hidePreloader: boolean
    tasks: { bgimage: boolean | null; createSession: boolean | null }
  }
  readmore: ReadMoreData | null
  rfqInput: RfqParams | null
  rfqOrEysButtonEnabled: boolean
  saveEnabled: boolean
  seriesFrame: ImageSeriesFrame
  sessionId: string | null
  shareLink: string | null
  showNewsBanner: boolean
  showPanoramaViewer: boolean
  startupDataBundle: StartupDataBundle | null // Null before session initialization.
  userOptedOutOfFactoryModelsMode: boolean
  unavailableImageFrames: ImageSeriesFrame[]
}

var initialState: SessionState = {
  account: { isloggedin: false, userMail: '' },
  bodyBuildToggled: true,
  counter: 0,
  error: {
    errorLevel: 0,
    friendlyErrorMessage: '',
    errorMessage: null,
  },
  extendedCheckState: ExtendedCheckStatus.None,
  garage: { list: null },
  goEtelLastUpdated: null,
  guidedOfferingState: null,

  loadingOverlayEnabled: false,
  marketDenomination: null,
  navopen: false,
  pendingUserTrackingGoSelection: null,
  preload: {
    initTime: null,
    minDuration: 2000,
    hidePreloader: false,
    tasks: { bgimage: null, createSession: null },
  },
  readmore: null,
  rfqInput: null,
  rfqOrEysButtonEnabled: true,
  sessionId: null,
  startupDataBundle: null,
  userOptedOutOfFactoryModelsMode: false,
  seriesFrame: {
    seriesId: 'EXTERIOR',
    frameId: 2,
  },
  shareLink: null,
  saveEnabled: false,
  showPanoramaViewer: false,
  showNewsBanner: true,
  unavailableImageFrames: [],
}

if (window.tempStartupCriticalState) {
  initialState = {
    ...initialState,
    ...window.tempStartupCriticalState.sessionData,
  }
}

export const sessionDataSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    deleteTruckFromGarageList: (state, action: PayloadAction<number>) => {
      if (!state.garage.list) {
        console.error('Expected garage.list to be defined.')
      } else {
        const updatedList = state.garage.list.filter(
          (truck: UserConfigInfo) => truck.id !== action.payload,
        )
        state.garage = { ...state.garage, list: updatedList }
      }
    },
    setUserOverrideFactoryModels: (state, action: PayloadAction<boolean>) => {
      state.userOptedOutOfFactoryModelsMode = action.payload
    },
    setPreloadTasks: (state, action: PayloadAction<boolean>) => {
      state.preload = {
        ...state.preload,
        //tasks: { ...state.preload.tasks, ...action.payload },
        tasks: { ...state.preload.tasks },
      }
    },
    setRfqInput: (state, action: PayloadAction<RfqParams | null>) => {
      state.rfqInput = action.payload
    },
    setRfqOrEysButtonEnabled: (state, action: PayloadAction<boolean>) => {
      state.rfqOrEysButtonEnabled = action.payload
    },
    setError: (state, action: PayloadAction<SessionError>) => {
      console.error(action.payload.errorMessage)
      if (state.error.errorLevel <= 2) {
        state.error = { ...state.error, ...action.payload }
      }
    },
    setGarageList: (state, action: PayloadAction<UserConfigInfo[] | null>) => {
      state.garage = { ...state.garage, list: action.payload }
    },
    // BuildModePage should set this to null when initializing itself.
    setGuidedOfferingState: (
      state,
      action: PayloadAction<GuidedOfferingClientState | null>,
    ) => {
      state.guidedOfferingState = action.payload
      const goEtelLastUpdated = action.payload?.etelLastUpdated
      if (goEtelLastUpdated) {
        state.goEtelLastUpdated = goEtelLastUpdated
      }
    },
    setGuidedOfferingStateStatus: (
      state,
      action: PayloadAction<GuidedOfferingClientStateStatus>,
    ) => {
      const oldState = state.guidedOfferingState
      if (!oldState) {
        return
      }
      const newState: GuidedOfferingClientState = { ...oldState }
      newState.status = action.payload
      setGuidedOfferingState(newState)
    },
    setInitState: (state, action: PayloadAction<StartupDataBundle>) => {
      if (action.payload.userConfigs !== null) {
        // This can happen when pressing the New button to restart the
        // application while logged in.
        state.garage = {
          ...state.garage,
          list: action.payload.userConfigs,
        }
        state.account = { isloggedin: true, userMail: '' }
      }
      state.startupDataBundle = action.payload
    },
    loggedInStatus: (state, action: PayloadAction<LoginStatus>) => {
      state.account = { ...state.account, ...action.payload }
    },

    /**
     * Needed to allow the destination page to pick up this information and include
     * it in the pageView event.
     */
    setPendingUserTrackingPageNavigation: (
      state,
      action: PayloadAction<ScaniaAdobeTrackingFlowInfo | null>,
    ) => {
      state.pendingUserTrackingGoSelection = action.payload
    },
    pushPageViewTrackingEvent: (
      state,
      action: PayloadAction<PageViewTrackingEventPayload>,
    ) => {},
    setSessionId: (state, action: PayloadAction<string | null>) => {
      state.sessionId = action.payload
    },
    hideInitialLoadingScreen: (state) => {
      state.preload = { ...state.preload, hidePreloader: true }
    },
    setExtendedCheckState: (
      state,
      action: PayloadAction<ExtendedCheckStatus>,
    ) => {
      state.extendedCheckState = action.payload
    },
    setMarketDenomination: (state, action: PayloadAction<string | null>) => {
      state.marketDenomination = action.payload
    },
    setSeriesFrame: (state, action: PayloadAction<ImageSeriesFrame>) => {
      state.seriesFrame = action.payload
    },
    setShareLink: (state, action: PayloadAction<string | null>) => {
      state.shareLink = action.payload
    },
    setSaveEnabled: (state, action: PayloadAction<boolean>) => {
      state.saveEnabled = action.payload
    },
    setShowPanoramaViewer: (state, action: PayloadAction<boolean>) => {
      state.showPanoramaViewer = action.payload
    },
    setShowNewsBanner: (state, action: PayloadAction<boolean>) => {
      state.showNewsBanner = action.payload
    },
    setBodyBuildToggled: (state, action: PayloadAction<boolean>) => {
      state.bodyBuildToggled = action.payload
    },
    setUnavailableImageFrames: (
      state,
      action: PayloadAction<ImageSeriesFrame[]>,
    ) => {
      state.unavailableImageFrames = action.payload
    },
  },
})

export const {
  deleteTruckFromGarageList,
  setUserOverrideFactoryModels,
  setPreloadTasks,
  setRfqInput,
  setRfqOrEysButtonEnabled,
  setError,
  setExtendedCheckState,
  setGarageList,
  setGuidedOfferingState,
  setGuidedOfferingStateStatus,
  setInitState,
  loggedInStatus,
  setPendingUserTrackingPageNavigation,
  pushPageViewTrackingEvent,
  setSessionId,
  hideInitialLoadingScreen,
  setMarketDenomination,
  setSeriesFrame,
  setShareLink,
  setSaveEnabled,
  setShowPanoramaViewer,
  setShowNewsBanner,
  setBodyBuildToggled,
  setUnavailableImageFrames,
} = sessionDataSlice.actions

export const getSessionState = (state: RootState) => state.sessionData
export const getOverrideFactoryModelsState = (state: RootState) =>
  state.sessionData.userOptedOutOfFactoryModelsMode
export const getErrorState = (state: RootState) => state.sessionData.error
export const getLoadingOverlayEnabledState = (state: RootState) =>
  state.sessionData.loadingOverlayEnabled
export const getGarageListState = (state: RootState) =>
  state.sessionData.garage.list
export const getIsLoggedInState = (state: RootState) =>
  state.sessionData.account.isloggedin
export const getPreloadTasks = (state: RootState) =>
  state.sessionData.preload.tasks
export const getHidePreloaderState = (state: RootState) =>
  state.sessionData.preload.hidePreloader
export const getRfqInput = (state: RootState) => state.sessionData.rfqInput
export const getRfqOrEysButtonEnabled = (state: RootState) =>
  state.sessionData.rfqOrEysButtonEnabled
export const getSessionIdState = (state: RootState) =>
  state.sessionData.sessionId
export const getSessionInitDataState = (state: RootState) =>
  state.sessionData.startupDataBundle?.sessionInitData
export const getStartupData = (state: RootState) =>
  state.sessionData.startupDataBundle
export const getGuidedOfferingClientState = (state: RootState) =>
  state.sessionData.guidedOfferingState
export const getGoEtelLastUpdatedState = (state: RootState) => {
  const timeAsNumber =
    state.sessionData.startupDataBundle?.sessionInitData
      .secmLastUpdatedTimeAsNumber
  if (!timeAsNumber) {
    return null
  }
  const date = new Date()
  date.setTime(timeAsNumber)
  return date
}
export const getExtendedCheckStatus = (state: RootState) =>
  state.sessionData.extendedCheckState
export const getMarketDenomination = (state: RootState) =>
  state.sessionData.marketDenomination
export const getSeriesFrame = (state: RootState) =>
  state.sessionData.seriesFrame
export const getShareLink = (state: RootState) => state.sessionData.shareLink
export const getSaveEnabled = (state: RootState) =>
  state.sessionData.saveEnabled
export const getBodyBuildToggled = (state: RootState) =>
  state.sessionData.bodyBuildToggled
export const getUnavailableImageFrames = (state: RootState) =>
  state.sessionData.unavailableImageFrames

export default sessionDataSlice.reducer
