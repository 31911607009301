import { getCurrentQuestionIndex } from './GoStepperEntry'
import { GuidedOfferingClientState } from '../../types/GuidedOffering'
import { ScaniaAdobeTrackingPageName } from '../../utils/adobeAnalytics'
import {
  FAMILY_ID_APPLICATION,
  FAMILY_ID_CAB,
  FAMILY_ID_CAB_SERIES,
  FAMILY_ID_OPERATION,
  FAMILY_ID_TRUCK_TYPE,
  FAMILY_ID_WHEEL_CONFIGURATION,
} from '../../api/constants'

export function buildStepSkippedTrackingMessage(
  goClientState: GuidedOfferingClientState,
  familyId: string,
  stepSkipped: string | null,
) {
  const i = getCurrentQuestionIndex(goClientState)
  if (i < 0) {
    const fallback = `skip from family ${familyId} to next step`
    stepSkipped = fallback
  } else {
    stepSkipped = `skip from step${i + 1} to next step`
  }
  return stepSkipped
}

export function resolveTrackingStepName(
  etelFamilyId: string,
): ScaniaAdobeTrackingPageName | null {
  switch (etelFamilyId) {
    case FAMILY_ID_OPERATION:
      return ScaniaAdobeTrackingPageName.GoOperations
    case FAMILY_ID_APPLICATION:
      return ScaniaAdobeTrackingPageName.GoApplication
    case FAMILY_ID_TRUCK_TYPE:
      return ScaniaAdobeTrackingPageName.GoTruckType
    case FAMILY_ID_WHEEL_CONFIGURATION:
      return ScaniaAdobeTrackingPageName.GoWheelConfig
    case FAMILY_ID_CAB_SERIES:
      return ScaniaAdobeTrackingPageName.GoCabSeries
    case FAMILY_ID_CAB:
      return ScaniaAdobeTrackingPageName.GoCab
  }
  return null
}
