import styled from 'styled-components'
import { useActiveChoicesHighlight } from './ActiveChoicesHighlight'
import {
  SvgCross,
  SvgDocument,
  SvgDocumentCheck,
  SvgInfo,
  SvgLoading,
  SvgRedirect,
  SvgScaniaSuper,
  SvgTick,
} from '../../../components/SvgImports'
import { MenuItem, SummaryItem } from '../../../api/generated'
import { BlendColorRgb, BlendColors } from '../Menu/LeafMenu/BlendColors'
import React, { useCallback, useEffect, useState } from 'react'
import {
  ScaniaAdobeTrackingClickActiveChangesEvent,
  ScaniaAdobeTrackingClickMinisummaryColorEvent,
  ScaniaAdobeTrackingClickMinisummaryMotorEvent,
  ScaniaAdobeTrackingDownloadPdfEvent,
  ScaniaAdobeTrackingClickSuperEvent,
  pushAdobeEvent,
  ScaniaAdobeEventId,
  ScaniaAdobeDownloadType,
  ScaniaAdobeTrackingButtonPlacement,
} from '../../../utils/adobeAnalytics'
import useTexts from '../../../utils/useTexts'
import { AccordionIconPositionHack } from './SummaryAndOtherOptionsIcon'
import { ActiveChangesIcon } from './ActiveChoicesIcon'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { buildPdfUrl } from '../../../utils/UrlBuilders'
import { TestElementTypeId } from '../../../types/TestAttributeId'
import { BreakpointWidthPx } from '../../../css/BreakpointWidthPx'
import {
  openPanelReadMore,
  setReadMoreNode,
} from '../../../store/sidePanelSlice'
import {
  getMarketDenomination,
  getSessionInitDataState,
  getStartupData,
} from '../../../store/sessionDataSlice'
import {
  getMarketLanguageState,
  getVersionInfoState,
} from '../../../store/appSlice'
import {
  getLatestConfigChangeResult,
  getTruckInfoState,
} from '../../../store/truckInfoSlice'
import { getMenuState } from '../../../store/menuSlice'
import {
  TdsAccordion,
  TdsAccordionItem,
  TdsButton,
  TdsTooltip,
} from '@scania/tegel-react'

const SpecificationRoot = styled.div`
  width: 100%;
  background-color: var(--tds-white);
  position: relative;
  border-top: 1.5px solid var(--tds-grey-100);
  border-left: 1.5px solid var(--tds-grey-100);
  border-bottom: 1.5px solid var(--tds-grey-100);
  cursor: default;

  @media screen and (max-width: ${BreakpointWidthPx.Tablet}px) {
    display: flex;
    flex-direction: column;
    border: none;

    @media screen and (min-width: ${BreakpointWidthPx.Phone}px) and (max-width: ${BreakpointWidthPx.Tablet}px) {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
`

const MiniSummaryTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin: 16px;
  margin-bottom: 24px;

  @media screen and (min-width: ${BreakpointWidthPx.Phone}px) and (max-width: ${BreakpointWidthPx.Tablet}px) {
    display: flex;
    width: 100%;
    justify-content: center;
  }
`

const MiniSummaryInititalSelectionSectionWrapper = styled.div`
  margin-right: 8px;
  margin-left: 8px;
  padding-bottom: 16px;

  @media screen and (min-width: ${BreakpointWidthPx.Phone}px) and (max-width: ${BreakpointWidthPx.Tablet}px) {
    width: calc(50% - 2px);
    background-color: var(--tds-grey-100);
    padding: 16px;
    margin-right: 0px;
    margin-left: 0px;
    margin-bottom: 8px;
  }
`

const MiniSummaryMotorSectionWrapper = styled.div`
  width: 100%;
  margin-bottom: 8px;
  padding: 16px;

  @media screen and (min-width: ${BreakpointWidthPx.Phone}px) and (max-width: ${BreakpointWidthPx.Tablet}px) {
    width: calc(50% - 2px);
    background-color: var(--tds-grey-100);
  }
`

const MiniSummaryRowWrapper = styled.div`
  // IMPORTANT:
  // Without this line iPhone in landscape mode may use different font sizes for
  // the text and the Dots .... elements!!
  text-size-adjust: 100%;

  margin: 0;
  font-size: 12px;
  width: 100%;
  display: grid;
  grid-gap: 0;
  grid-template-columns: fit-content(40%) auto fit-content(40%);
  line-height: normal;
`

const MiniSummaryRowEngineWrapper = styled.div`
  // IMPORTANT:
  // Without this line iPhone in landscape mode may use different font sizes for
  // the text and the Dots .... elements!!
  text-size-adjust: 100%;

  margin: 0;
  font-size: 12px;
  width: 100%;
  display: grid;
  grid-gap: 0;
  grid-template-columns: fit-content(40%) auto fit-content(40%);
`

const MiniSummaryRowTitle = styled.div`
  color: var(--tds-grey-700);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-family: 'Scania Sans Condensed';
`

const MiniSummaryRowText = styled.div`
  color: var(--tds-grey-958);
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-family: 'Scania Sans Condensed';
`

const Dots = styled.div`
  color: var(--tds-grey-700);
  overflow: hidden;
  white-space: nowrap;
  font-weight: unset;
`

const MinisummaryEngineAtTablet = styled.div`
  display: none;

  @media screen and (min-width: ${BreakpointWidthPx.Phone}px) and (max-width: ${BreakpointWidthPx.Tablet}px) {
    display: block;
  }
`

const MinisummaryEngineAtNotTablet = styled.div`
  display: block;

  @media screen and (min-width: ${BreakpointWidthPx.Phone}px) and (max-width: ${BreakpointWidthPx.Tablet}px) {
    display: none;
  }
`

const SuperButtonWrapper = styled.div`
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 16px;
  display: flex;
  justify-content: right;
  align-items: center;

  // Override Tds
  button {
    background-color: unset !important;
    color: var(--tds-grey-958) !important;
    display: flex;
    justify-content: center;
    padding-right: 0px !important;
  }

  @media screen and (min-width: ${BreakpointWidthPx.Phone}px) and (max-width: ${BreakpointWidthPx.Tablet}px) {
    justify-content: center;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      cursor: pointer;
    }
  }
`

const SuperIconBorderWrapper = styled.div`
  border-style: solid;
  border-radius: 2px;
  border-color: var(--tds-grey-600);
  border-width: thin;

  // Somewhat arbitrary, looks good for now.
  margin-top: calc(0px - 0.4em - 4px);

  margin-left: 8px;

  svg {
    height: 1.2em;
    margin-top: 4px;
    margin-left: 8px;

    // TODO: Tweak svg file to fix this.
    margin-bottom: 1px;

    margin-right: 8px;
  }
`

const SuperIconNoBorderWrapper = styled.div`
  // Somewhat arbitrary, looks good for now.
  margin-top: calc(0px - 0.25em);

  svg {
    height: 1.2em;
  }
`

const SuperButtonText = styled.div`
  display: flex;
  font-family: 'Scania Sans Semi Condensed', Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 14px;
  @media screen and (min-width: ${BreakpointWidthPx.Tablet}px) {
    @media screen and (max-width: 740px) {
      font-size: 10px;
    }
  }
`

const MiniSummaryColorContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: stretch;
  margin-bottom: 4px;
`

const MiniSummaryColorWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  cursor: pointer;
`

const ColorSeparator = styled.div`
  height: 32px;
  border-left: thin solid var(--tds-grey-400);
  align-self: flex-end;
`

const MiniSummaryColorText = styled.div`
  font-family: 'Scania Sans Semi Condensed', Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 12px;
  line-height: 1rem;
  letter-spacing: -0.01em;
  color: var(--tds-grey-958);
  text-align: center;
`

const MiniSummaryColorNameText = styled.div`
  font-style: italic;
  text-align: center;
  color: var(--tds-grey-700);
  margin-bottom: 8px;
`

const MiniSummaryColorIcon = styled.div`
  width: 32px;
  height: 32px;
  min-width: 32px;
  margin-top: auto;
  border-radius: 50%;
  border: 1px solid var(--tds-grey-700);
  display: flex;
`

const ActiveChangesWrapper = styled.div`
  margin-right: 8px;
  margin-left: 8px;
  padding-top: 16px;
  padding-bottom: 16px;

  @media screen and (min-width: ${BreakpointWidthPx.Phone}px) and (max-width: ${BreakpointWidthPx.Tablet}px) {
    display: flex;
    width: 100%;
  }
`

const AccordionWrapper = styled.div`
  @media screen and (min-width: ${BreakpointWidthPx.Phone}px) and (max-width: ${BreakpointWidthPx.Tablet}px) {
    width: 50%;
    padding-right: 16px;
  }
`

const ActiveChangesIconContainer = styled.div`
  position: relative;
  display: flex;
`

const ActiveChangesItemWrapper = styled.div`
  font-size: 12px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
`

const ActiveChangesTitle = styled.div`
  color: var(--tds-grey-700);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const ActiveChangesText = styled.div`
  color: var(--tds-grey-958);
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 8px;
`

const SummaryButtonWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  margin-left: -24px;
`

const InsteadOfSuperEngineSpacer = styled.div`
  margin-bottom: 16px;
`

const SuperPopOutWrapper = styled.div`
  width: 100%;
  background-color: white;
  color: var(--tds-grey-958);
  margin-top: 4px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 16px;
  border-radius: 4px;
`

const SuperPopOutHeader = styled.div`
  width: 100%;
  max-width: 100%;
  padding-top: 11px;
  padding-right: 20px;
  padding-left: 20px;
  display: grid;
  grid-template-columns: auto 20px;
  max-width: 100%;
  align-items: center;

  // To match tds buttons...
  letter-spacing: -0.01em;
`

const SuperPopOutTextAndIconWrapper = styled.div`
  // xxx
`

const SuperCloseIconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: right;
  align-items: center;

  svg {
    margin-right: -4px;
    height: 20px;
    width: 20px;
    color: var(--tds-grey-958);
  }
`

const SuperPopOutTitle = styled.div`
  float: left;
  display: flex;
  justify-content: left;
  overflow-x: hidden;
  text-overflow: ellipsis;
  margin-bottom: 4px;
  margin-right: 0.33em;

  // Override TDS
  button {
    background-color: white !important;
    color: var(--tds-grey-958) !important;
    display: flex;
    justify-content: left;
  }
`

const SuperPopOutBody = styled.div`
  padding-left: 20px;
  padding-right: 20px;

  a {
    color: unset;
  }
  a:visited {
    color: unset;
  }
  @media (hover: hover) and (pointer: fine) {
    a:hover {
      color: unset;
    }
  }
  a:active {
    color: unset;
  }
`

const SuperText = styled.div`
  margin-top: 8px;
  margin-bottom: 16px;
  font-size: 10px;
`

const SuperReadmoreButton = styled.div`
  font-size: 10px;
  padding-top: 4px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    height: 16px;
  }
`

const SuperEngineButtonsWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
`

const SuperEngineRowWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
`

interface SuperInfoWrapperProps {
  $hasReadmore: boolean
}

const SuperInfoWrapper = styled.div<SuperInfoWrapperProps>`
  align-items: center;
  cursor: ${({ $hasReadmore }) => ($hasReadmore ? 'pointer' : 'unset')};
  display: flex;
  height: 40px;
  justify-content: center;
  min-height: 40px;

  svg {
    visibility: ${({ $hasReadmore }) => ($hasReadmore ? 'visible' : 'hidden')};
    width: 16px;
    height: 16px;
  }
`
interface SuperEngineButtonProps {
  $selected: boolean
}

const SuperEngineButton = styled.div<SuperEngineButtonProps>`
  align-items: center;
  border-radius: 4px;
  border: 0.5px solid var(--tds-grey-600);
  cursor: ${({ $selected }) => ($selected ? 'unset' : 'pointer')};
  display: flex;
  font-size: 12px;
  font-weight: ${({ $selected }) => ($selected ? 'bold' : 'normal')};
  height: 40px;
  justify-content: space-between;
  margin-left: 12px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 8px;
  padding-left: 8px;
  width: 100%;

  svg {
    color: var(--tds-blue-400);
    height: 20px;
    width: 20px;
  }
`

const MobileDivider = styled.div`
  background-color: var(--tds-grey-100);
  margin-bottom: 8px;
  align-items: center;
  width: 4px;
  display: none;

  @media screen and (min-width: ${BreakpointWidthPx.Phone}px) and (max-width: ${BreakpointWidthPx.Tablet}px) {
    display: flex;
  }
`

const MobileDividerLine = styled.div`
  height: 120px;
  border-right: 1px solid var(--tds-grey-400);
`

interface SpecificationProps {
  activeChoicesState: SummaryItem[]

  // This is a very special hack!
  // The SDDS/Tegel Accordion component has an internal expanded/collapsed state
  // that it toggles when the user clicks the component. It also has an
  // attribute called `expanded` that is intended to control the initial state.
  // This attribute can be repurposed to open the Accordion multiple times if we
  // provide a different value for the attribute every time we want to open it,
  // any number seems to be interpreted as truethy so we can just keep
  // incrementing the previous number to keep opening the Accordion!
  activeChoicesExpanded: number | undefined

  activeChoicesElementId: string
  navigateToMenu: (id: string, path: string) => void
  handleEngineSelection: (id: string) => void
  superEngines: MenuItem[]
}

interface ColorStateItem {
  src: SummaryItem
  text: string
}

const OPERATION_FAMILY_ID = '02759'

export function Specification({
  activeChoicesElementId,
  activeChoicesExpanded,
  activeChoicesState,
  navigateToMenu,
  handleEngineSelection,
  superEngines,
}: SpecificationProps): JSX.Element {
  const dispatch = useAppDispatch()
  const startupData = useAppSelector(getStartupData)
  const sessionInitData = useAppSelector(getSessionInitDataState)
  const marketLanguage = useAppSelector(getMarketLanguageState)
  const verInfo = useAppSelector(getVersionInfoState)
  const marketDenomination = useAppSelector(getMarketDenomination)
  const truckInfo = useAppSelector(getTruckInfoState)
  const currentlyLoadingId = useAppSelector(getMenuState).currentlyLoadingId
  const miniSummaryState = useAppSelector(
    getLatestConfigChangeResult,
  )?.miniSummary
  const [isSuper, setIsSuper] = useState<boolean>(false)
  const [superIsOpen, setSuperIsOpen] = useState<boolean>(false)
  const [miniSummaryStateNonColor, setMiniSummaryStateNonColor] = useState<
    SummaryItem[]
  >([])
  const [miniSummaryStateEngine, setMiniSummaryStateEngine] =
    useState<SummaryItem | null>(null)
  const [miniSummaryStateColor, setMiniSummaryStateColor] = useState<
    ColorStateItem[]
  >([])
  const highlightYourChoices = useActiveChoicesHighlight(activeChoicesState)
  const t = useTexts()

  const blendColorToCss = (rgb: BlendColorRgb): string => {
    return `rgb(${rgb.R}, ${rgb.G}, ${rgb.B})`
  }

  const getColorVariantRgb = (id: string): BlendColorRgb | undefined => {
    return BlendColors[id]
  }

  useEffect(() => {
    let result = false
    superEngines.forEach((engine) => {
      if (engine.selected === true) {
        result = true
      }
    })
    setIsSuper(result)
  }, [superEngines])

  const buildSuperButtonText = useCallback(() => {
    let text = isSuper ? t('SUPER_BUTTON_THIS_IS') : t('SUPER_BUTTON_MAKE_THIS')
    //let text = (isSuper ? t('SUPER_BUTTON_THIS_IS') : t('SUPER_BUTTON_MAKE_THIS')) + ' test test test'
    return (
      <SuperButtonText>
        <span>{text}</span>
      </SuperButtonText>
    )
  }, [isSuper, t])

  const handleSuperClick = () => {
    setSuperIsOpen(true)
    const trackingEvent: ScaniaAdobeTrackingClickSuperEvent = {
      event: ScaniaAdobeEventId.SuperClick,
    }
    pushAdobeEvent(trackingEvent)
  }

  const handleMiniSummaryColorClick = (itemId: string, menuPath: string) => {
    navigateToMenu(itemId || '', menuPath || '')

    const trackingEvent: ScaniaAdobeTrackingClickMinisummaryColorEvent = {
      event: ScaniaAdobeEventId.MiniSummaryColorClick,
    }
    pushAdobeEvent(trackingEvent)
  }

  const handleMiniSummaryEngineClick = (itemId: string, menuPath: string) => {
    navigateToMenu(itemId || '', menuPath || '')
    const trackingEvent: ScaniaAdobeTrackingClickMinisummaryMotorEvent = {
      event: ScaniaAdobeEventId.MiniSummaryMotorClick,
    }
    pushAdobeEvent(trackingEvent)
  }

  const handleActiveChangesClick = (itemId: string, menuPath: string) => {
    navigateToMenu(itemId || '', menuPath || '')

    const trackingEvent: ScaniaAdobeTrackingClickActiveChangesEvent = {
      event: ScaniaAdobeEventId.ActiveChangesClick,
    }
    pushAdobeEvent(trackingEvent)
  }

  // Get colorItems and nonColorItems from miniSummaryState
  useEffect(() => {
    const newMiniSummaryStateNonColor: SummaryItem[] = []
    let newMiniSummaryStateEngine: SummaryItem | null = null
    const newColors: ColorStateItem[] = []

    // market denomination is not part of miniSummaryState but added to the minisummary with dummydata
    const elementMarketDenomination: SummaryItem = {
      itemId: 'dummydata',
      itemText: marketDenomination || 'unknown',
      menuId: 'dummydata',
      menuPath: 'dummydata',
      menuText: t('LABEL_TYPE_DESIGNATION'),
    }
    newMiniSummaryStateNonColor.push(elementMarketDenomination)
    miniSummaryState?.items?.forEach((element) => {
      if (
        element.itemId.startsWith(OPERATION_FAMILY_ID) &&
        truckInfo?.isFactoryModel
      ) {
        return
      }
      if (element.menuId === 'SUBMENU_00408') {
        newMiniSummaryStateEngine = element
      } else {
        newMiniSummaryStateNonColor.push(element)
      }
    })
    setMiniSummaryStateNonColor(newMiniSummaryStateNonColor)
    setMiniSummaryStateEngine(newMiniSummaryStateEngine)
    const cabColor = miniSummaryState?.cabColor
    const chassisColor = miniSummaryState?.chassisColor
    const contrastColor = miniSummaryState?.contrastColor
    if (cabColor) {
      newColors.push({ src: cabColor, text: t('LABEL_COLOR_CAB') })
    }
    if (chassisColor) {
      newColors.push({ src: chassisColor, text: t('LABEL_COLOR_CHASSIS') })
    }
    if (contrastColor) {
      newColors.push({ src: contrastColor, text: t('LABEL_COLOR_CONTRAST') })
    }
    setMiniSummaryStateColor(newColors)
  }, [marketDenomination, miniSummaryState, t, truckInfo, verInfo])

  const handlePdfDownloadButtonClick = useCallback(() => {
    if (!startupData) {
      throw new Error('Expected startupData to be defined.')
    }
    if (!marketLanguage) {
      throw new Error('Expected marketLanguage to be defined.')
    }
    if (!sessionInitData) {
      throw new Error('Expected sessionInitData to be defined.')
    }
    const pdfLink = buildPdfUrl(
      startupData.backendUrl,
      sessionInitData.sessionId,
      marketLanguage.language,
      marketDenomination,
    )
    const trackingEvent: ScaniaAdobeTrackingDownloadPdfEvent = {
      event: ScaniaAdobeEventId.Download,
      eventInfo: {
        downloadType: ScaniaAdobeDownloadType.Pdf,
        filePath: pdfLink,
        placement: ScaniaAdobeTrackingButtonPlacement.SummarySection,
      },
    }
    pushAdobeEvent(trackingEvent)
    window.open(pdfLink, '_blank', 'noreferrer')
  }, [marketDenomination, marketLanguage, sessionInitData, startupData])

  function buildColorItem(): JSX.Element {
    const result = (
      <MiniSummaryColorContainer>
        {miniSummaryStateColor.flatMap((item, index) => {
          const colorElement = (
            <MiniSummaryColorWrapper
              key={'minisummarycolor' + item.src.itemId + '-' + index}
              onClick={() =>
                handleMiniSummaryColorClick(
                  item.src.itemId || '',
                  item.src.menuPath || '',
                )
              }
            >
              <MiniSummaryColorText id={'color' + index}>
                {item.text}
              </MiniSummaryColorText>
              <MiniSummaryColorNameText className="tds-detail-05">
                {item.src.itemText}
              </MiniSummaryColorNameText>
              <MiniSummaryColorIcon
                style={{
                  backgroundColor: blendColorToCss(
                    getColorVariantRgb(item.src.itemId || '') || {
                      R: 255,
                      G: 0,
                      B: 255,
                    },
                  ),
                }}
              />
            </MiniSummaryColorWrapper>
          )
          return index === 0
            ? [colorElement]
            : [
                <ColorSeparator
                  key={
                    'minisummarycolor-separator' + item.src.itemId + '-' + index
                  }
                />,
                colorElement,
              ]
        })}
      </MiniSummaryColorContainer>
    )
    return result
  }

  function buildNonColorSection(): JSX.Element {
    const windowWidth = window.innerWidth

    if (windowWidth > BreakpointWidthPx.Tablet) {
      const result = (
        <TdsAccordion>
          <TdsAccordionItem header="Initial selections" expanded paddingReset>
            {buildNonColorItem()}
          </TdsAccordionItem>
        </TdsAccordion>
      )
      return result
    }
    return buildNonColorItem()
  }

  function buildNonColorItem(): JSX.Element {
    const result = (
      <>
        {miniSummaryStateNonColor.map((item, index) => {
          return (
            <MiniSummaryRowWrapper
              key={'minisummarynoncolor' + item.itemId + '-' + index}
            >
              <MiniSummaryRowTitle>{item.menuText}</MiniSummaryRowTitle>
              <Dots>
                .....................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................
              </Dots>
              <MiniSummaryRowText id={'Noncolor' + index}>
                <TdsTooltip
                  placement="bottom"
                  selector={'#Noncolor' + index}
                  text={item.itemText}
                ></TdsTooltip>
                <span
                  data-test-go-group-id={item.itemId.split('~')[0]}
                  data-etel-variant-id={item.itemId}
                >
                  {item.itemText}
                </span>
              </MiniSummaryRowText>
            </MiniSummaryRowWrapper>
          )
        })}
        <MinisummaryEngineAtTablet>
          {buildEngineItem()}
        </MinisummaryEngineAtTablet>
      </>
    )
    return result
  }

  function buildEngineItem(): JSX.Element {
    if (!miniSummaryStateEngine) {
      return <></>
    }
    return (
      <MiniSummaryRowEngineWrapper
        key={'minisummaryEngine' + miniSummaryStateEngine.itemId}
        style={{ cursor: 'pointer' }}
        onClick={() =>
          handleMiniSummaryEngineClick(
            miniSummaryStateEngine.itemId || '',
            miniSummaryStateEngine.menuPath || '',
          )
        }
      >
        <MiniSummaryRowTitle style={{ backgroundColor: 'var(--tds-grey-100)' }}>
          {miniSummaryStateEngine.menuText}
        </MiniSummaryRowTitle>
        <Dots>
          .....................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................
        </Dots>
        <MiniSummaryRowText
          id={'engineItem'}
          style={{ backgroundColor: 'var(--tds-grey-100)' }}
        >
          <TdsTooltip
            placement="top"
            selector={'#engineItem'}
            text={miniSummaryStateEngine.itemText}
          ></TdsTooltip>
          {miniSummaryStateEngine.itemText}
        </MiniSummaryRowText>
      </MiniSummaryRowEngineWrapper>
    )
  }

  return (
    <SpecificationRoot>
      <MiniSummaryTitle
        data-test-element-type={TestElementTypeId.BuildModeMiniSummaryTitle}
      >
        {truckInfo?.savedAsName ||
          truckInfo?.factoryModelName ||
          t('HEADER_CURRENT_CONFIGURATION')}
      </MiniSummaryTitle>

      <MiniSummaryInititalSelectionSectionWrapper
        data-test-element-type={
          TestElementTypeId.BuildModeMiniSummaryNonColorSection
        }
      >
        {buildNonColorSection()}
      </MiniSummaryInititalSelectionSectionWrapper>
      <MobileDivider>
        <MobileDividerLine></MobileDividerLine>
      </MobileDivider>
      <MiniSummaryMotorSectionWrapper
        style={{ backgroundColor: 'var(--tds-grey-100)' }}
      >
        <MinisummaryEngineAtNotTablet>
          {buildEngineItem()}
        </MinisummaryEngineAtNotTablet>
        {miniSummaryStateEngine && superEngines.length > 0 && !superIsOpen && (
          <SuperButtonWrapper>
            <TdsButton variant="primary" size="sm" onClick={handleSuperClick}>
              {buildSuperButtonText()}
              <SuperIconBorderWrapper>
                <SvgScaniaSuper />
              </SuperIconBorderWrapper>
            </TdsButton>
          </SuperButtonWrapper>
        )}
        {miniSummaryStateEngine && superEngines.length > 0 && superIsOpen && (
          <SuperPopOutWrapper>
            <SuperPopOutHeader data-name="SuperPopOutHeader">
              <SuperPopOutTextAndIconWrapper>
                <SuperPopOutTitle>{buildSuperButtonText()}</SuperPopOutTitle>
                <SuperIconNoBorderWrapper>
                  <SvgScaniaSuper />
                </SuperIconNoBorderWrapper>
              </SuperPopOutTextAndIconWrapper>
              <SuperCloseIconWrapper onClick={() => setSuperIsOpen(false)}>
                <SvgCross />
              </SuperCloseIconWrapper>
            </SuperPopOutHeader>
            <SuperPopOutBody>
              <SuperText>{t('SUPER_DESCRIPTION')}</SuperText>
              <SuperEngineButtonsWrapper>
                {superEngines.map((engine, i) => {
                  return (
                    <React.Fragment key={'superEngine' + engine.id + '-' + i}>
                      <SuperEngineRowWrapper>
                        <SuperInfoWrapper
                          onClick={(ev) => {
                            ev.stopPropagation()
                            dispatch(openPanelReadMore())
                            dispatch(
                              setReadMoreNode({
                                id: engine.id,
                                title: engine.shortText || null,
                              }),
                            )
                          }}
                          $hasReadmore={engine.hasReadmore}
                        >
                          <SvgInfo />
                        </SuperInfoWrapper>
                        <SuperEngineButton
                          $selected={engine.selected}
                          onClick={() => handleEngineSelection(engine.id)}
                        >
                          {engine.shortText} {engine.selected && <SvgTick />}
                          {!engine.selected &&
                            engine.id === currentlyLoadingId && <SvgLoading />}
                        </SuperEngineButton>
                      </SuperEngineRowWrapper>
                    </React.Fragment>
                  )
                })}
              </SuperEngineButtonsWrapper>
              <a target="_blank" rel="noreferrer" href={t('CAMPAIGN_LINK_URL')}>
                <SuperReadmoreButton>
                  {t('LABEL_READ_MORE')}
                  <SvgRedirect />
                </SuperReadmoreButton>
              </a>{' '}
            </SuperPopOutBody>
          </SuperPopOutWrapper>
        )}
        {miniSummaryStateEngine && superEngines.length === 0 && (
          <InsteadOfSuperEngineSpacer />
        )}
        {buildColorItem()}
      </MiniSummaryMotorSectionWrapper>

      <ActiveChangesWrapper id={activeChoicesElementId}>
        <AccordionWrapper style={{ marginBottom: '16px' }}>
          <AccordionIconPositionHack>
            <ActiveChangesIconContainer>
              <ActiveChangesIcon
                choiceCount={activeChoicesState.length}
                isHighlighted={highlightYourChoices}
              />
            </ActiveChangesIconContainer>
          </AccordionIconPositionHack>
          <TdsAccordion>
            <TdsAccordionItem
              header={'\xa0\xa0\xa0\xa0\xa0\xa0' + t('LABEL_ACTIVE_CHANGES')}
              padding-reset="true"
              {...(activeChoicesExpanded && { expanded: true })}
            >
              {activeChoicesState.map((item, i) => {
                return (
                  <ActiveChangesItemWrapper
                    key={'ActiveChoices' + item.itemId + '-' + i}
                    onClick={() =>
                      handleActiveChangesClick(
                        item.itemId || '',
                        item.menuPath || '',
                      )
                    }
                  >
                    <ActiveChangesTitle>
                      {item.menuText}:&nbsp;
                    </ActiveChangesTitle>
                    <ActiveChangesText>{item.itemText}</ActiveChangesText>
                  </ActiveChangesItemWrapper>
                )
              })}
            </TdsAccordionItem>
          </TdsAccordion>
        </AccordionWrapper>
        <AccordionWrapper>
          <AccordionIconPositionHack>
            <SvgDocumentCheck />
          </AccordionIconPositionHack>
          <TdsAccordion>
            <TdsAccordionItem
              header={'\xa0\xa0\xa0\xa0\xa0\xa0' + t('LABEL_ACTION_SUMMARY')}
              padding-reset="true"
            >
              <SummaryButtonWrapper>
                <TdsButton
                  variant="secondary"
                  size="lg"
                  onClick={handlePdfDownloadButtonClick}
                >
                  <div
                    slot="label"
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    {t('BTN_VIEW_FULL_SUMMARY')}
                    <span
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: '8px',
                      }}
                    >
                      <SvgDocument
                        style={{ height: '16px', color: 'var(--tds-grey-958' }}
                      />
                    </span>
                  </div>
                </TdsButton>
              </SummaryButtonWrapper>
            </TdsAccordionItem>
          </TdsAccordion>
        </AccordionWrapper>
      </ActiveChangesWrapper>
    </SpecificationRoot>
  )
}
