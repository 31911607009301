// The root component for the Build Mode/step 2/main configurator view.

import { useEffect, useState, useCallback, useRef } from 'react'
import styled from 'styled-components'
import { useClient } from '../../utils/useClient'
import {
  ConfiguratorMenu,
  LeafMenuEntry,
  MENU_ROOT_ID,
  VisibleMenuEntry,
} from './Menu/ConfiguratorMenu'
import { BuildModePortraitFooter } from './BuildModePortraitFooter'
import { MainImageArea } from './MainImageArea'
import { MiniSummaryDesktop } from './MiniSummary/MiniSummaryDesktop'
import {
  BuildInfoResponseWrapper,
  ConfigChangeMenuEntry,
  ConfigChangeResult,
  ScaniaConfiguratorApi,
  ScaniaSystemInfo,
} from '../../api/generated'
import { interpretSelectionResultItems, isMenuId } from './ItemSelectionApiUtil'
import { CurrentSelection } from './Menu/LeafMenu/CurrentSelection'
import { PanoramaImageBuilder, PanoramaViewer } from './PanoramaViewer'
import {
  buildRelativeLandingPageUrl,
  buildRelativePublicConfigLink,
} from '../../utils/UrlBuilders'
import {
  ScaniaAdobeTrackingClickPanoramaEvent,
  pushAdobeEvent,
  ScaniaAdobeEventId,
  ScaniaAdobeTrackingPageName,
  ScaniaAdobeTrackingButtonPlacement,
  ScaniaAdobeTrackingConfigChangedEvent,
} from '../../utils/adobeAnalytics'
import { SessionError, SESSION_FAILURE } from '../../api/errors'
import { MobileConfigureButton } from './MiniSummary/MobileConfigureButton'
import { ControlIcons } from './ControlIcons'
import { PopUpWrapper } from './PopUpWrapper'
import { SaveMenu } from './SaveMenu'
import { ShareMenu } from './ShareMenu'
import useTexts from '../../utils/useTexts'
import { getAndDeleteEysStartConfigId } from '../../utils/sessionStorageUtil'
import { isTabletOrSmaller } from '../../utils/screenQuery'
import { Disclaimer } from './Disclaimer'
import {
  ConsequenceOfChangeDialogData,
  ConsequenceOfChangeHandler,
} from '../../types/ConsequenceOfChangeTypes'
import { Specification } from './MiniSummary/Specification'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { SessionInitDataReduxCompatible } from '../../api/startup'
import { SMART_DASH_FAMILY_ID } from '../../api/constants'
import { NewsBanner } from '../../components/NewsBanner/NewsBanner'
import { MINI_SUMMARY_WIDTH_MAX } from '../../constants'
import { getUrlParametersToString } from '../../utils/getUrlParametersToString'
import { BreakpointWidthPx } from '../../css/BreakpointWidthPx'
import {
  closeMainMenu,
  openMainMenu,
  getMenuState,
  closeMenuSharePopUp,
  openMenuSharePopUp,
  openMenuSavePopUp,
  closeMenuSavePopUp,
  closeMenuSaveMainMenu,
  closeMenuShareMainMenu,
  openMenuSaveMainMenu,
  openMenuShareMainMenu,
  setVisibleMenuState,
  setCurrentlyLoadingId,
} from '../../store/menuSlice'
import {
  getMarketLanguageState,
  getMarketSettingsState,
  getVersionInfoState,
} from '../../store/appSlice'
import {
  getRfqOrEysButtonEnabled,
  getSeriesFrame,
  getSessionInitDataState,
  getSessionState,
  getStartupData,
  pushPageViewTrackingEvent,
  setGuidedOfferingState,
  setMarketDenomination,
  setRfqOrEysButtonEnabled,
  setSeriesFrame,
  setShowPanoramaViewer,
  setUnavailableImageFrames,
} from '../../store/sessionDataSlice'
import {
  closeAllModals,
  setModalSaveAsGarageState,
} from '../../store/modalSlice'
import {
  getLatestConfigChangeResult,
  getTruckInfoState,
  setLatestConfigChangeResult,
} from '../../store/truckInfoSlice'
import { convertReduxStateToTruckInfo } from '../../App'
import {
  ImageSeriesFrame,
  SmartDashCampaignMode,
  VisibleMenuState,
} from '../../store/types'

const BuildModeRoot = styled.div`
  background-color: var(--tds-white);
  // iOS scroll tweaks: BEGIN
  // Without this, iOS will make this div as large as its contents.
  position: absolute;
  top: var(--header-height);
  height: calc(var(--app-height) - var(--header-height));
  // iOS scroll tweaks: END
  // Some more tweaks that's needed as a result of the iOS scroll tweaks.
  width: 100%;
  justify-self: center;
`

const MainArea = styled.div`
  position: absolute;
  height: calc(var(--app-height) - var(--header-height));
  width: 100%;
  overflow-x: hidden;

  background-color: var(--tds-grey-50);
  display: flex;

  // TODO: Revisit. Last minute tweak to compensate for other last minute changes...
  flex-flow: wrap;

  --configuratormenu-transition-duration: 250ms;
  --configuratormenu-collapsed-transition-duration: 150ms;

  @media screen and (max-width: ${BreakpointWidthPx.Tablet}px) {
    height: calc(var(--app-height) - var(--header-height));
    display: block;
    overflow-y: scroll;
    --configuratormenu-collapsed-transition-duration: 0ms;
  }
`

interface MobileMainMenuClickOutsideCatcherProps {
  $isOpen: boolean
}

const MobileMainMenuClickOutsideCatcher = styled.div<MobileMainMenuClickOutsideCatcherProps>`
  display: none;
  height: 100%;
  position: fixed;
  top: 0;
  width: 100%;

  // TODO: Revisit and try to eliminate.
  z-index: 10;

  @media screen and (max-width: ${BreakpointWidthPx.Tablet}px) {
    display: ${({ $isOpen }) => ($isOpen ? 'block' : 'none')};
  }
`

interface ConfiguratorMenuProps {
  $isOpen: boolean
}

const ConfiguratorMenuContainer = styled.div<ConfiguratorMenuProps>`
  position: fixed;
  --main-menu-border-right-width: 1px;

  // Seems to be needed due to position: fixed.
  height: calc(var(--app-height) - var(--header-height));

  width: var(--configurator-menu-width);
  border-right: var(--main-menu-border-right-width) solid var(--tds-grey-300);

  // TODO: Revisit and try to eliminate.
  z-index: 10;

  // We want the border outside the content to allow the content to have
  // non-transparent background colors while not hiding the border.
  box-sizing: content-box;

  background-color: var(--tds-white);
  left: 0;

  @media screen and (max-width: ${BreakpointWidthPx.Tablet}px) {
    height: var(--app-height);

    // Menu should be placed over header
    top: 0px;

    left: ${(props) =>
      props.$isOpen
        ? '0'
        : 'calc(0px - var(--configurator-menu-width) - var(--main-menu-border-right-width))'};
    transition-duration: var(--configuratormenu-transition-duration);
    transition-property: left;

    // isOpen is flipped directly at click
    transition-delay: ${(props) =>
      props.$isOpen
        ? 'var(--configuratormenu-collapsed-transition-duration)'
        : '0ms'};
  }
`

interface ConfiguratorMenuCurrentSelectionProps {
  $isOpen: boolean
}

const ConfiguratorMenuCurrentSelectionContainer = styled.div<ConfiguratorMenuCurrentSelectionProps>`
  border: thin solid var(--tds-grey-100);
  position: absolute;
  max-height: calc(100% - 68px - 68px);
  width: var(--configurator-menu-width);
  background-color: rgba(255, 255, 255, 0.8);

  // TODO: Revisit and try to eliminate.
  z-index: 1;

  top: calc(68px + 68px);
  left: ${(props) =>
    props.$isOpen ? 'var(--configurator-menu-width)' : '0px'};
  transition-duration: var(--configuratormenu-transition-duration);
  transition-property: left;
  display: ${(props) => (props.$isOpen ? '' : 'none')};

  //  isCollapsed is flipped directly at click
  transition-delay: ${(props) =>
    props.$isOpen ? 'var(--configuratormenu-transition-duration)' : '0ms'};

  @media screen and (max-width: ${BreakpointWidthPx.Tablet}px) {
    display: none;
  }
`

const MainImageAreaContainer = styled.div<ConfiguratorMenuProps>`
  height: 100%;
  position: absolute;
  width: calc(
    100vw - var(--configurator-menu-width) -
      min(var(--mini-summary-width-desktop), ${MINI_SUMMARY_WIDTH_MAX})
  );
  left: var(--configurator-menu-width);

  @media screen and (max-width: ${BreakpointWidthPx.Tablet}px) {
    position: relative;
    width: 100%;
    height: 100%;
    left: 0px;
  }

  @media screen and (max-width: ${BreakpointWidthPx.Phone}px) {
    height: max(100vw, 50%);
  }
`

const MiniSummaryContainerLandscape = styled.div`
  position: absolute;
  right: 0px;
  width: var(--mini-summary-width-desktop);

  // TODO: Revisit this, why var(--mini-summary-width-desktop) ?
  max-width: ${MINI_SUMMARY_WIDTH_MAX};

  @media screen and (max-width: ${BreakpointWidthPx.Tablet}px) {
    display: none;
  }
`

const MiniSummaryContainerMobile = styled.div`
  display: none;

  @media screen and (max-width: ${BreakpointWidthPx.Tablet}px) {
    display: block;
  }
`

const ControlIconsWrapper = styled.div`
  position: absolute;
  top: 8px;

  // TODO: Revisit and try to eliminate.
  z-index: 5;

  overflow: visible;
  right: calc(
    8px + min(var(--mini-summary-width-desktop), ${MINI_SUMMARY_WIDTH_MAX})
  );
  @media screen and (max-width: ${BreakpointWidthPx.Tablet}px) {
    right: 16px;
  }
`

const DisclaimerWrapper = styled.div`
  display: none;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 8px;
  font-size: 12px;
  color: var(--tds-grey-600);

  @media screen and (max-width: ${BreakpointWidthPx.Tablet}px) {
    display: flex;
  }
`

const buildInititalMainMenu = (
  initData: SessionInitDataReduxCompatible,
): Record<string, VisibleMenuEntry> => {
  const mainMenuEntries = initData.menuInfo.menus.map<VisibleMenuEntry>(
    (info) => {
      const entry: VisibleMenuEntry = {
        childIds: null,
        hasReadmore: false,
        id: info.id,
        isOpen: false,
        parentId: MENU_ROOT_ID,
        text: info.shortText || info.id,
      }
      return entry
    },
  )
  const root: VisibleMenuEntry = {
    childIds: mainMenuEntries.map((entry) => entry.id),
    hasReadmore: false,
    id: MENU_ROOT_ID,
    isOpen: true,
    parentId: null,
    text: '',
  }
  const result: Record<string, VisibleMenuEntry> = {}
  result[MENU_ROOT_ID] = root
  mainMenuEntries.forEach((entry) => {
    result[entry.id] = entry
  })
  return result
}

/**
 * Make a deep-copy.
 */
const copyVisibleMenuEntry = (ent: VisibleMenuEntry): VisibleMenuEntry => {
  const newChildIds: string[] | null = ent.childIds
    ? ent.childIds.concat([])
    : null
  const result: VisibleMenuEntry = {
    childIds: newChildIds,
    hasReadmore: ent.hasReadmore,
    id: ent.id,
    isOpen: ent.isOpen,
    parentId: ent.parentId,
    text: ent.text,
  }
  return result
}

/**
 * Helper for buildNewVisibleMenuState.
 */
function copyChildNodeForNewMenuState(
  id: string,
  state: VisibleMenuState,
): VisibleMenuEntry {
  const childNodeFromOldState = state.menuNodes[id]
  if (!childNodeFromOldState) {
    throw new Error('Expected to find a menu node by id: ' + id)
  }
  const newChildNode = copyVisibleMenuEntry(childNodeFromOldState)
  newChildNode.childIds = null
  newChildNode.isOpen = false
  return newChildNode
}

/**
 * Make a partial deep-copy of the visible menu tree and truncate the
 * unnecessary branches while performing the copy.
 *
 * Copy only the parts of the tree that will be visible after the new menu is
 * activated.
 *
 * TODO: implement a new web API request: explore_menu that provides all of
 * this logic and returns a new menu stucture with the selected meny branches
 * populated. Rename the various bip, attempt_bip etc. to change_config or
 * select_item and return the visible menu structure as part of that response as
 * well, to minimize client side complexity.
 */
const buildNewVisibleMenuState = (
  state: VisibleMenuState,
  newActiveMenuId: string,
): VisibleMenuState => {
  const newNodes: Record<string, VisibleMenuEntry> = {}
  const newActiveMenuEntryFromOldState = state.menuNodes[newActiveMenuId]
  if (!newActiveMenuEntryFromOldState) {
    throw new Error('Expected to find a menu node by id: ' + newActiveMenuId)
  }
  const newActiveMenuEntry = copyVisibleMenuEntry(
    newActiveMenuEntryFromOldState,
  )
  newActiveMenuEntry.isOpen = true
  newNodes[newActiveMenuEntry.id] = newActiveMenuEntry

  // If childsIds is not null in the old state for the new active menu, that
  // means that we are navigating upwards in the open menu tree and in that case
  // we need to truncate the tree so that only one level down is visible in the
  // new active menu.
  newActiveMenuEntry.childIds?.forEach((childId) => {
    const newChildNode = copyChildNodeForNewMenuState(childId, state)
    newNodes[newChildNode.id] = newChildNode
  })

  // Traverse upwards in the menu tree and copy the needed parts to the new
  // state.
  let previousId = newActiveMenuEntry.id
  let currentId = newActiveMenuEntry.parentId
  let currentNodeFromOldState: VisibleMenuEntry | undefined
  while (currentId !== null) {
    currentNodeFromOldState = state.menuNodes[currentId]
    if (!currentNodeFromOldState) {
      throw new Error('Expected to find a menu node by id: ' + currentId)
    }
    const newCurrentNode = copyVisibleMenuEntry(currentNodeFromOldState)
    newNodes[newCurrentNode.id] = newCurrentNode
    for (const childId of newCurrentNode.childIds || []) {
      if (childId === previousId) {
        // Skip this child node, it's already processed since it's the child
        // node we came from.
        continue
      }
      const newChildNode = copyChildNodeForNewMenuState(childId, state)
      newNodes[newChildNode.id] = newChildNode
    }
    previousId = newCurrentNode.id
    currentId = newCurrentNode.parentId
  }
  const newState: VisibleMenuState = {
    activeMenuId: newActiveMenuId,
    colorInfo: null,
    leafNodes: null,
    menuNodes: newNodes,
    menusWithSelectableItems: state.menusWithSelectableItems,
  }
  return newState
}

// TODO: Make a proper type or split verInfo to multiple objects
const buildColorMenuInfo = (
  verInfo: null | (BuildInfoResponseWrapper & ScaniaSystemInfo),
  leafNodes: LeafMenuEntry[] | null,
): ColorMenuInfo | null => {
  if (!verInfo) {
    return null
  }

  // TODO - simplify color menu handling
  let isColorMenu = false
  const colorIds = verInfo?.tiledMenus.map((id) => id.split('_')[1]) || null
  const leafNodeIds = leafNodes?.map((node) => node.id.split('~')[0])
  if (leafNodeIds && colorIds) {
    const firstLeafId = leafNodeIds[0].split('~')[0]
    isColorMenu = colorIds.includes(firstLeafId)
  }
  const result: ColorMenuInfo = {
    colorIds,
    isColorMenu,
  }
  return result
}

export interface ColorMenuInfo {
  colorIds: string[] | null
  isColorMenu: boolean
}

// Helps set nullable callbacks with setState.
interface ConfigDependentImageParams {
  // Is expected to change on configuration changes, which in turn should
  // trigger image updates in the image component.
  buildPanoramaImage: PanoramaImageBuilder | null
}

const exploreMenu = async (
  menuIdToActivate: string,
  newVisibleMenuState: VisibleMenuState,
  sessionInitData: SessionInitDataReduxCompatible,
  client: ScaniaConfiguratorApi,
  verInfo: BuildInfoResponseWrapper & ScaniaSystemInfo,
): Promise<VisibleMenuState | SessionError> => {
  const clickedNode = newVisibleMenuState.menuNodes[menuIdToActivate]
  if (!clickedNode) {
    throw new Error('Expected clickedNode to be defined.')
  }

  // Ancient API usage: Begin.
  //
  // TODO: Consider replacing this with a new request, named something like
  // "exploreMenu", dedicated to menus, separating item selection from menu
  // exploration.
  const tryChangeConfigResponse = await client.tryChangeConfig({
    sessionId: sessionInitData.sessionId,
    input: menuIdToActivate,
  })
  if (tryChangeConfigResponse.error === SESSION_FAILURE) {
    return SessionError.SESSION_FAILURE
  }
  if (!tryChangeConfigResponse.success) {
    throw new Error('Expected .success to be defined.')
  }
  const selectionResult = tryChangeConfigResponse.success
  const resultCode = selectionResult.resultCode
  if (resultCode !== 'ACTIVATED') {
    throw new Error(`Unexpected attempBipV2 result code: '${resultCode}'.`)
  }
  const configChange = selectionResult.changeConfigResult
  if (!configChange) {
    throw new Error('Expected configChange to be defined.')
  }
  const activeItem = configChange.activeItem
  if (activeItem !== menuIdToActivate) {
    throw new Error(
      `Expected activeItem to be "${menuIdToActivate}, but found "${activeItem}".`,
    )
  }
  const updates = configChange.updates
  if (!updates.length) {
    throw new Error(
      'Expected updates.length to be a positive number, found: ' +
        updates.length,
    )
  }
  const { childSubmenuEntries, leafEntries } =
    interpretSelectionResultItems(updates)
  if (childSubmenuEntries.length === 0 && leafEntries.length === 0) {
    throw new Error('Expected child submenus or leaf entries, found none.')
  }
  if (childSubmenuEntries.length === updates.length) {
    // This is a submenu with submenus, not a leaf menu.
    const newActiveMenuNode = newVisibleMenuState.menuNodes[menuIdToActivate]
    if (!newActiveMenuNode) {
      throw new Error(
        'Expected to find a menu node by id: ' + newActiveMenuNode,
      )
    }
    newActiveMenuNode.childIds = childSubmenuEntries.map((ent) => ent.id)
    childSubmenuEntries.forEach((ent) => {
      newVisibleMenuState.menuNodes[ent.id] = ent
    })
  } else if (childSubmenuEntries.length === 0 && leafEntries.length > 0) {
    // This is a leaf menu.
    newVisibleMenuState.leafNodes = leafEntries
    newVisibleMenuState.colorInfo = buildColorMenuInfo(verInfo, leafEntries)
  } else {
    throw new Error('Failed to interpret the item activation response.')
  }
  newVisibleMenuState.menusWithSelectableItems = stringArrayToHashSet(
    configChange.menusWithSelectableItems,
  )
  // Ancient API usage: End.
  return newVisibleMenuState
}

export interface BuildModePageProps {
  handleConsequenceOfChange: ConsequenceOfChangeHandler
  handleFatalError: () => void
  handleNewClick: () => void
  handleRequestAQuoteClick: () => void
  handleSaveAsGarageClick: (e: React.MouseEvent) => void
  handleSendChangesToEysClick: () => void
  handleSessionFailure: () => void
  handleCloseModalConsequenseOfChange: () => void

  // TODO: Eliminate this or the local latestConfigChangeResult. Consider
  // replacing both with a Redux state, but after refactoring to the new Redux
  // Toolkit slices pattern.
  handleUserSelection: () => void

  // Intended for triggering hook updates based on configuration changes.
  // The configuration itself is too large to report to the client, so we just
  // keep this counter in combination with the session id to represent a unique
  // configuration state.
  //
  // TODO: When the old GUI is retired, refactor the ConfigChangeResult and
  // rename it to ConfigChangeResponse or something like that. Store that new
  // refactored response object instead of userSelectionCount.
  // EDIT: We have started to refactor ConfigChangeResult now and it's stored in
  // this component as latestConfigChangeResult.
  userSelectionTimestamp: Date | null

  rfqSentWithTimestamp: Date | null
  eysChangesSentWithTimestamp: Date | null

  smartDashCampaignMode: SmartDashCampaignMode
}

const MOBILE_ACTIVE_CHOICES_ELEMENT_ID = 'mobile-active-choices-element-id'

export function BuildModePage({
  eysChangesSentWithTimestamp,
  handleConsequenceOfChange,
  handleFatalError,
  handleNewClick,
  handleRequestAQuoteClick,
  handleSaveAsGarageClick,
  handleSendChangesToEysClick,
  handleSessionFailure,
  handleUserSelection,
  handleCloseModalConsequenseOfChange,
  rfqSentWithTimestamp,
  smartDashCampaignMode,
  userSelectionTimestamp,
}: BuildModePageProps): JSX.Element {
  const dispatch = useAppDispatch()
  const t = useTexts()
  const marketLanguage = useAppSelector(getMarketLanguageState)
  const client = useClient()
  const marketSettings = useAppSelector(getMarketSettingsState)
  const seriesFrame = useAppSelector(getSeriesFrame)
  const truckInfo = useAppSelector(getTruckInfoState)
  const latestConfigChangeResult = useAppSelector(getLatestConfigChangeResult)
  const {
    mainMenuIsOpen,
    visibleMenuState,
    currentlyLoadingId,
    menuSaveMainMenuIsOpen,
    menuShareMainMenuIsOpen,
    menuSavePopUpIsOpen,
    menuSharePopUpIsOpen,
  } = useAppSelector(getMenuState)

  const sessionInitData = useAppSelector(getSessionInitDataState)
  const startupData = useAppSelector(getStartupData)
  const { showPanoramaViewer, showNewsBanner } = useAppSelector(getSessionState)
  const [highlightedId, setHighlightedId] = useState<string | null>(null)

  // This is a very special hack!
  // The SDDS/Tegel Accordion component has an internal expanded/collapsed state
  // that it toggles when the user clicks the component. It also has an
  // attribute called `expanded` that is intended to control the initial state.
  // This attribute can be abused to open the Accordion multiple times if we
  // provide a different value for the attribute every time we want to open it,
  // any number seems to be interpreted as truethy so we can just keep
  // incrementing the previous number to keep opening the Accordion!
  const [activeChoicesIsExpanded, setActiveChoicesIsExpanded] = useState<
    number | undefined
  >(undefined)

  // #########################################################################
  // #             Configuration change update state: BEGIN                  #
  // #########################################################################

  // TODO: Store all configuratin state update on a single object using a single
  // useState.
  //
  // TODO: Store together with other configuration state data.
  const [configDependentImageParams, setConfigDependentImageParams] =
    useState<ConfigDependentImageParams>({
      buildPanoramaImage: null,
    })

  // #########################################################################
  // #             Configuration change update state: END                    #
  // #########################################################################

  const refMainImageArea = useRef<HTMLDivElement | null>(null)
  const refConfiguratorMenu = useRef<HTMLDivElement | null>(null)
  const refMainArea = useRef<HTMLDivElement | null>(null)
  const refDesktopCurrentSelectionContainer = useRef<HTMLDivElement | null>(
    null,
  )
  const waitingForResult = useRef(false)
  const verInfo = useAppSelector(getVersionInfoState)
  const rfqOrEysButtonEnabled = useAppSelector(getRfqOrEysButtonEnabled)

  const suppressNativePageReloadWarning = useRef<boolean>(false)

  const highlightTimerId = useRef<number | null>(null)

  useEffect(() => {
    const onBeforeUnload = (e: BeforeUnloadEvent) => {
      if (suppressNativePageReloadWarning.current) {
        // Used for the New button since that one will pop a custom dialog
        // instead of the browser built-in warning.
        return
      }
      const truckInfoDate = convertReduxStateToTruckInfo(truckInfo)
      if (
        userSelectionTimestamp?.getTime() ===
        truckInfoDate?.timeLoaded.getTime()
      ) {
        // No changes have been applied to the truck.
        return
      }
      e.returnValue = t('WARNING_LEAVING_PAGE')
      return t('WARNING_LEAVING_PAGE')
    }
    window.addEventListener('beforeunload', onBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload)
    }
  }, [t, userSelectionTimestamp, truckInfo])

  const handleNewClickWrapper = useCallback(() => {
    // We have a custom dialog for this warning in this case.
    suppressNativePageReloadWarning.current = true

    handleNewClick()
  }, [handleNewClick])

  const handleRfqOrEysClick = useCallback(() => {
    if (!startupData) {
      return
    }
    if (startupData.exploreYourScaniaMode) {
      handleSendChangesToEysClick()
    } else {
      handleRequestAQuoteClick()
    }
  }, [startupData, handleRequestAQuoteClick, handleSendChangesToEysClick])

  // Initialize or reinitialize after a configuration has been loaded.
  useEffect(() => {
    const sessionId = sessionInitData?.sessionId
    if (!startupData) {
      return // Not initialized yet.
    }
    if (!client) {
      return // Not initialized yet.
    }
    if (!sessionId) {
      return // Not initialized yet.
    }
    if (!marketLanguage) {
      return // Not initialized yet.
    }
    if (!truckInfo) {
      const eysStartConfig = getAndDeleteEysStartConfigId()
      let reloadUrl
      if (eysStartConfig) {
        console.log('Explore Your Scania mode, reloading start configuration.')
        const relativeUrl = buildRelativePublicConfigLink(
          marketLanguage,
          eysStartConfig,
        )
        const params = relativeUrl.params
        params.append('eys', '1')
        reloadUrl = relativeUrl.path + getUrlParametersToString(params)
      } else {
        // This is the expected result if the user reloads the browser tab while
        // viewing the BuildModePage, or if a user navigates to this URL without a
        // initialized session with a loaded configuration.
        //
        // TODO: Consider using sessionStorage to store the sessionId to allow
        // refreshing the browser tab and resuming the previous session.
        //
        console.warn(
          'Expected the session to have a loaded configuration, reloading the landing page.',
        )
        const relativeUrl = buildRelativeLandingPageUrl(marketLanguage)
        const params = relativeUrl.params
        reloadUrl = relativeUrl.path + getUrlParametersToString(params)
      }
      document.location.href = reloadUrl

      // IMPORTANT: The browser may execute code while the new URL is loading.
      return
    }

    dispatch(setGuidedOfferingState(null))

    const asyncWrapper = async () => {
      // Assume the session is in build mode and has a loaded configuration.
      // TODO: Store the expected session stage, GO or Build Mode in Redux or in
      // App.tsx.
      const latestConfigChangeResponse =
        await client.getLatestConfigChangeResult(sessionId)
      if (latestConfigChangeResponse.error === SESSION_FAILURE) {
        handleSessionFailure()
        return
      }
      if (!latestConfigChangeResponse.success) {
        console.error('Expected .success to be defined.')
        handleFatalError()
        return
      }
      const rebopResult = latestConfigChangeResponse.success
      dispatch(setLatestConfigChangeResult(rebopResult))
      const newMarketDenomination =
        rebopResult.auxCarrier.auxiliaries['MarketDenomination'].value
      dispatch(setMarketDenomination(newMarketDenomination || null))
      const unavailable = getUnavailableFrames(rebopResult)
      dispatch(setUnavailableImageFrames(unavailable))
      const newMenuTree = buildInititalMainMenu(sessionInitData)
      const newVisibleMenuState: VisibleMenuState = {
        activeMenuId: MENU_ROOT_ID,
        colorInfo: null,
        leafNodes: null,
        menuNodes: newMenuTree,
        menusWithSelectableItems: stringArrayToHashSet(
          rebopResult.menusWithSelectableItems,
        ),
      }
      dispatch(setVisibleMenuState(newVisibleMenuState))
      dispatch(
        pushPageViewTrackingEvent({
          pageName: ScaniaAdobeTrackingPageName.BuildMode,
          marketLanguage,
        }),
      )
    }
    asyncWrapper()
  }, [
    client,
    dispatch,
    handleFatalError,
    handleSessionFailure,
    marketLanguage,
    sessionInitData,
    startupData,
    truckInfo,
  ])

  // Handle click in configurator menu
  const handleConfiguratorMenuClick = useCallback(
    async (id: string) => {
      if (!sessionInitData) {
        throw new Error('Expected sessionInitData to be defined.')
      }
      if (!marketLanguage) {
        throw new Error('Expected marketLanguage to be defined.')
      }
      if (!client) {
        throw new Error('Expected client to be defined.')
      }
      if (!visibleMenuState) {
        throw new Error('Expected visibleMenuState to be defined.')
      }
      const clickedNode = visibleMenuState.menuNodes[id]
      if (!clickedNode) {
        throw new Error('Expected clickedNode to be defined.')
      }
      if (!verInfo) {
        throw new Error('Expected verInfo to be defined.')
      }

      // Open main menu if it is collapsed for mobile
      if (!mainMenuIsOpen) {
        dispatch(openMainMenu())
      }

      // Close Save and Share menu
      dispatch(closeMenuSaveMainMenu())
      dispatch(closeMenuShareMainMenu())

      let menuIdToActivate: string | null = null

      // Close the clicked open entry and explore the parent menu.
      if (clickedNode.isOpen) {
        if (clickedNode.parentId === null) {
          throw new Error('Expected parentId of clicked menu to be non-null.')
        }
        menuIdToActivate = clickedNode.parentId
      } else {
        menuIdToActivate = id
      }

      // Build a partial deep-copy for the new menu state.
      let newVisibleMenuState = buildNewVisibleMenuState(
        visibleMenuState,
        menuIdToActivate,
      )

      // The web service currently doesn't support exploring the root menu, so
      // we just modify the client side menu state for now.
      if (clickedNode.isOpen && menuIdToActivate === '/') {
        dispatch(setVisibleMenuState(newVisibleMenuState))
        return
      }

      // The clicked menu was not open, so we need to request the menu contents
      // from the web service.
      try {
        const exploreMenuResult = await exploreMenu(
          menuIdToActivate,
          newVisibleMenuState,
          sessionInitData,
          client,
          verInfo,
        )
        if (exploreMenuResult === SessionError.SESSION_FAILURE) {
          handleSessionFailure()
          return
        }
        newVisibleMenuState = exploreMenuResult
      } catch (err) {
        console.error(err)
        console.error('Failed to explore menu.')
        handleFatalError()
        return
      }
      dispatch(setVisibleMenuState(newVisibleMenuState))
    },
    [
      client,
      dispatch,
      handleFatalError,
      handleSessionFailure,
      mainMenuIsOpen,
      marketLanguage,
      sessionInitData,
      verInfo,
      visibleMenuState,
    ],
  )

  // Handle navigation and highlight choice for 1. Click on search result 2. Click in Minisummary 3. Click in ActiveChanges 4. Click Campaign
  // TODO: Declare a named interface type for the function parameters.
  const navigateToMenu = useCallback(
    async (
      id: string,
      path: string,
      preventMainMenuOpening: boolean = false,
    ): Promise<void> => {
      if (!sessionInitData) {
        throw new Error('Expected sessionInitData to be defined.')
      }
      if (!marketLanguage) {
        throw new Error('Expected marketLanguage to be defined.')
      }
      if (!client) {
        throw new Error('Expected client to be defined.')
      }
      if (!verInfo) {
        throw new Error('Expected verInfo to be defined.')
      }
      if (!visibleMenuState) {
        throw new Error('Expected visibleMenuState to be defined.')
      }
      const pathArray = path.split('/')
      if (!pathArray[0]) {
        pathArray.shift() // remove the first two empty elements from search result
      }
      if (!pathArray[0]) {
        pathArray.shift() // remove the first two empty elements from search result
      }
      if (isMenuId(id)) {
        pathArray.push(id)
      }
      let newVisibleMenuState = visibleMenuState

      for (let i = 0; i < pathArray.length; i++) {
        const menuIdToActivate = pathArray[i]
        newVisibleMenuState = buildNewVisibleMenuState(
          newVisibleMenuState,
          menuIdToActivate,
        )

        // Request the menu contents from the web service.
        try {
          const exploreMenuResult = await exploreMenu(
            menuIdToActivate,
            newVisibleMenuState,
            sessionInitData,
            client,
            verInfo,
          )
          if (exploreMenuResult === SessionError.SESSION_FAILURE) {
            handleSessionFailure()
            return
          }
          newVisibleMenuState = exploreMenuResult
        } catch {
          handleFatalError()
          return
        }
      }
      dispatch(setVisibleMenuState(newVisibleMenuState))
      setHighlightedId(id)
      const resetHighlightedId = () => {
        setHighlightedId(null)
      }
      if (highlightTimerId.current !== null) {
        window.clearTimeout(highlightTimerId.current)
      }
      highlightTimerId.current = window.setTimeout(resetHighlightedId, 3000)
      if (!preventMainMenuOpening) {
        if (isTabletOrSmaller()) {
          dispatch(openMainMenu())
        }
      }
    },
    [
      client,
      dispatch,
      handleFatalError,
      handleSessionFailure,
      marketLanguage,
      sessionInitData,
      verInfo,
      visibleMenuState,
    ],
  )

  const handleItemSelection = useCallback(
    async (id: string, placement: ScaniaAdobeTrackingButtonPlacement) => {
      if (!sessionInitData) {
        throw new Error('Expected sessionInitData to be defined.')
      }
      if (!marketLanguage) {
        throw new Error('Expected marketLanguage to be defined.')
      }
      if (!client) {
        throw new Error('Expected client to be defined.')
      }
      if (waitingForResult.current) {
        console.log('Ignoring selection, waiting for previous result.')
        return
      }
      if (!visibleMenuState) {
        throw new Error('Expected visibleMenuState to be defined.')
      }
      const isSmartDashCampaignItem = id.startsWith(SMART_DASH_FAMILY_ID + '~')
      const smartDashCampaignPreferredSeriesFrame: ImageSeriesFrame = {
        seriesId: 'INTERIOR',
        frameId: 1,
      }

      // Build a partial deep-copy for the new menu state.
      const newVisibleMenuState = buildNewVisibleMenuState(
        visibleMenuState,
        visibleMenuState.activeMenuId,
      )

      const trackingEvent: ScaniaAdobeTrackingConfigChangedEvent = {
        event: ScaniaAdobeEventId.ConfigChanged,
        eventInfo: {
          optionSelected: id,
          placement,
        },
      }
      pushAdobeEvent(trackingEvent)

      const sessionId = sessionInitData.sessionId
      waitingForResult.current = true
      let tryChangeConfigResponse
      try {
        dispatch(setCurrentlyLoadingId(id))
        tryChangeConfigResponse = await client.tryChangeConfig({
          sessionId,
          input: id,
        })
      } catch {
        handleFatalError()
        dispatch(setCurrentlyLoadingId(null))
        waitingForResult.current = false
        return
      }
      if (tryChangeConfigResponse.error === SESSION_FAILURE) {
        handleSessionFailure()
        return
      }
      if (!tryChangeConfigResponse.success) {
        handleFatalError()
        return
      }
      const selectionResult = tryChangeConfigResponse.success
      dispatch(setCurrentlyLoadingId(null))
      const handleConfigChangeResult = (configChange: ConfigChangeResult) => {
        const updates = configChange.updates
        if (!updates) {
          throw new Error('Expected updates to be defined.')
        }
        const { childSubmenuEntries, leafEntries } =
          interpretSelectionResultItems(updates)
        if (childSubmenuEntries.length > 0) {
          // May happen if using the SUPER engine campaign buttons in
          // combination with not having a leaf menu open.
          // TODO: Revisit this later.
          //throw new Error('Expected selection result to contain zero submenus.')
          handleUserSelection()
          return
        }
        if (leafEntries.length === 0) {
          // May happen if using the SUPER engine campaign buttons in
          // combination with not having a leaf menu open.
          // TODO: Revisit this later.
          //throw new Error('Expected selection result to contain leaf entries.')
          handleUserSelection()
          return
        }
        const activeMenuNode =
          newVisibleMenuState.menuNodes[newVisibleMenuState.activeMenuId]
        if (!activeMenuNode) {
          throw new Error('Expected activeMenuNode to be defined.')
        }
        newVisibleMenuState.leafNodes = leafEntries
        newVisibleMenuState.colorInfo = buildColorMenuInfo(verInfo, leafEntries)
        newVisibleMenuState.menusWithSelectableItems = stringArrayToHashSet(
          configChange.menusWithSelectableItems,
        )
        dispatch(setVisibleMenuState(newVisibleMenuState))
        handleUserSelection()
      }

      const resultCode = selectionResult.resultCode
      if (resultCode === 'ACTIVATED') {
        if (!selectionResult.changeConfigResult) {
          throw new Error('Expected .changeConfigResult to be defined.')
        }

        // TODO: Eliminate one of setLatestConfigChangeResult and
        // handleUserSelection!
        dispatch(
          setLatestConfigChangeResult(selectionResult.changeConfigResult),
        )
        handleConfigChangeResult(selectionResult.changeConfigResult)

        const newMarketDenomination =
          selectionResult.changeConfigResult.auxCarrier.auxiliaries[
            'MarketDenomination'
          ].value
        dispatch(setMarketDenomination(newMarketDenomination || null))
        const unavailable = getUnavailableFrames(
          selectionResult.changeConfigResult,
        )
        dispatch(setUnavailableImageFrames(unavailable))
        if (isSmartDashCampaignItem) {
          dispatch(setSeriesFrame(smartDashCampaignPreferredSeriesFrame))
        }
      } else if (resultCode === 'RESOLVERS') {
        // TODO: THIS IS IMPORTANT AND WILL SIMPLIFY THE SCDS_BACKEND CODE AND
        // THIS GUI CODE SIGNIFICANTLY. Change the Consequence Of Change
        // handling to require a explicit rollback/cancel request instead of the
        // current solution where the rollback is implicit at the next
        // configuration update interaction unless the Consequence Of Change
        // suggestion is confirmed by the `bip` request.
        //
        // Change to EXPLICIT ROLLBACK as a dedicated request sent by the
        // client!
        if (!selectionResult.resolvers) {
          throw new Error('Expected selectionResult.resolvers to be defined.')
        }
        const cocData: ConsequenceOfChangeDialogData = {
          resolvers: selectionResult.resolvers,
          showCancelButton: true,
          clickedId: id,
        }
        const choice = await handleConsequenceOfChange(cocData)
        switch (choice) {
          case 'CANCEL':
            dispatch(closeAllModals())
            handleCloseModalConsequenseOfChange()
            break
          case 'OK':
            const ids = selectionResult.resolvers.triggerItems.map(
              (item: ConfigChangeMenuEntry) => item.id,
            )
            const changeConfigResult = await client.changeConfig({
              sessionId,
              input: ids.join(';'),
            })
            if (changeConfigResult.error === SESSION_FAILURE) {
              handleSessionFailure()
              return
            }
            if (!changeConfigResult.success) {
              console.error('Expected .success to be defined.')
              handleFatalError()
              return
            }

            // TODO: Eliminate one of setLatestConfigChangeResult and
            // handleUserSelection!
            dispatch(setLatestConfigChangeResult(changeConfigResult.success))
            dispatch(
              setMarketDenomination(
                changeConfigResult.success.auxCarrier.auxiliaries[
                  'MarketDenomination'
                ].value || null,
              ),
            )
            const unavailable = getUnavailableFrames(changeConfigResult.success)
            dispatch(setUnavailableImageFrames(unavailable))
            handleConfigChangeResult(changeConfigResult.success)

            dispatch(closeAllModals())
            handleCloseModalConsequenseOfChange()

            if (isSmartDashCampaignItem) {
              dispatch(setSeriesFrame(smartDashCampaignPreferredSeriesFrame))
            }
            break
          default:
            console.error('Unknown choice variant: ' + choice)
            handleFatalError()
            return
        }
      } else {
        handleFatalError()
        return
      }
      waitingForResult.current = false
    },
    [
      client,
      dispatch,
      handleConsequenceOfChange,
      handleFatalError,
      handleSessionFailure,
      handleUserSelection,
      handleCloseModalConsequenseOfChange,
      verInfo,
      marketLanguage,
      sessionInitData,
      visibleMenuState,
    ],
  )

  // Rebind the image builder callback on changes that may affect the resulting
  // images and in turn triggering image generation.
  useEffect(() => {
    if (!sessionInitData) {
      return
    }
    if (!client) {
      //setConfigDependentImageBuilder({ buildFlexImage: null, buildPanoramaImage: null })
      return
    }
    const newImageBuilders: ConfigDependentImageParams = {
      buildPanoramaImage: async () => {
        let composedBase = sessionInitData.composedUrl
        if (!composedBase.endsWith('/')) {
          composedBase += '/'
        }
        const scaledWidth = 4000
        const scaledHeight = 2000
        const seriesName = '360'
        const frame = 1
        const cropRatioX = 0.5
        const cropRatioY = 0.5
        const croppedWidth = 0
        const croppedHeight = 0
        const fileType = 'jpg'
        const useHd = false
        const autoCrop = false
        const skip = null
        const skipGroups = null
        const onlyUse = null
        const onlyUseGroups = null
        const bgColorHexArgb = 'FFFFFFFF'
        const allowPadding = false
        const relativeUrl = (
          await client.getCurrentConfigImage({
            sessionId: sessionInitData.sessionId,
            scaledWidth,
            scaledHeight,
            seriesName,
            frame,
            cropRatioX,
            cropRatioY,
            croppedWidth,
            croppedHeight,
            fileType,
            useHd,
            autoCrop,
            skip,
            skipGroups,
            onlyUse,
            onlyUseGroups,
            bgColorHexArgb,
            allowPadding,
          })
        ).getCurrentConfigImage
        const absoluteUrl = composedBase + relativeUrl
        return { imageUrl: absoluteUrl }
      },
    }
    setConfigDependentImageParams(newImageBuilders)
  }, [client, sessionInitData, userSelectionTimestamp, truckInfo])

  const handleOpenPanoramaViewer = useCallback(() => {
    dispatch(setShowPanoramaViewer(true))

    const trackingEvent: ScaniaAdobeTrackingClickPanoramaEvent = {
      event: ScaniaAdobeEventId.PanoramaClick,
    }
    pushAdobeEvent(trackingEvent)
  }, [dispatch])

  // TODO: Replace this with an invisible click catcher hitbox like for the
  // mobile main menu. This will probably require elimination or overhaul of
  // z-index usage.
  const handleCloseDesktopLeafMenuOnClickOutside: React.MouseEventHandler<HTMLDivElement> =
    useCallback(
      async (e) => {
        if (!sessionInitData) {
          return
        }
        if (!marketLanguage) {
          return
        }
        if (!client) {
          return
        }
        if (!verInfo) {
          return
        }
        const configuratorMenuElement = refConfiguratorMenu.current
        if (configuratorMenuElement?.contains(e.target as Node)) {
          // Dont't interfere with main menu clicks.
          return
        }
        if (isTabletOrSmaller()) {
          return
        }
        if (!visibleMenuState?.leafNodes) {
          return
        }

        // Not portrait and not small landcape mode.

        //const mainImageAreaElement = refMainImageArea.current
        //if (mainImageAreaElement?.contains(e.target as Node)) {
        if (!refDesktopCurrentSelectionContainer.current) {
          return
        }
        if (
          refDesktopCurrentSelectionContainer.current.contains(e.target as Node)
        ) {
          // Don't interfere with Current Selection clicks.
          return
        }
        if (
          refDesktopCurrentSelectionContainer.current.contains(e.target as Node)
        ) {
          // Don't interfere with Current Selection clicks.
          return
        }
        const activeMenuId = visibleMenuState.activeMenuId
        const menuIdToActivate =
          visibleMenuState.menuNodes[activeMenuId].parentId
        if (menuIdToActivate) {
          let newVisibleMenuState = buildNewVisibleMenuState(
            visibleMenuState,
            menuIdToActivate,
          )
          try {
            // Need to call the web service to set the new active menu to the
            // parent menu, otherwise the GUI menu state could get out of sync
            // with the server side session state. The menu state must be
            // controlled by the server side session state since it contains too
            // many items to get a complete update for every click, only one
            // open menu tree branch at a time is supported to limit the
            // response data size.
            const exploreMenuResult = await exploreMenu(
              menuIdToActivate,
              newVisibleMenuState,
              sessionInitData,
              client,
              verInfo,
            )
            if (exploreMenuResult === SessionError.SESSION_FAILURE) {
              handleSessionFailure()
              return
            }
            newVisibleMenuState = exploreMenuResult
          } catch (err) {
            console.error(err)
            console.error('Failed to explore menu.')
            handleFatalError()
            return
          }
          dispatch(setVisibleMenuState(newVisibleMenuState))
        }
        //}
      },
      [
        client,
        marketLanguage,
        handleFatalError,
        handleSessionFailure,
        sessionInitData,
        verInfo,
        visibleMenuState,
        dispatch,
      ],
    )

  // TODO - could probably be integrated to handleConfiguratorMenuClick
  const handleMainMenuCloseToRoot = useCallback(async () => {
    if (!visibleMenuState) {
      throw new Error('Expected visibleMenuState to be defined.')
    }
    const menuIdToActivate = '/'
    // Build a partial deep-copy for the new menu state.
    let newVisibleMenuState = buildNewVisibleMenuState(
      visibleMenuState,
      menuIdToActivate,
    )
    dispatch(setVisibleMenuState(newVisibleMenuState))
  }, [visibleMenuState, dispatch])

  const handleSaveItemClick = useCallback(() => {
    if (menuSaveMainMenuIsOpen) {
      dispatch(closeMenuSaveMainMenu())
    } else {
      dispatch(openMenuSaveMainMenu())
      dispatch(closeMenuShareMainMenu())
    }
    handleMainMenuCloseToRoot()
  }, [dispatch, menuSaveMainMenuIsOpen, handleMainMenuCloseToRoot])

  const handleShareItemClick = useCallback(() => {
    if (menuShareMainMenuIsOpen) {
      dispatch(closeMenuShareMainMenu())
    } else {
      dispatch(openMenuShareMainMenu())
      dispatch(closeMenuSaveMainMenu())
    }
    handleMainMenuCloseToRoot()
  }, [handleMainMenuCloseToRoot, dispatch, menuShareMainMenuIsOpen])

  // TODO: Separate RFQ and EYS buttons.
  useEffect(() => {
    if (!startupData) {
      return
    }
    if (startupData.exploreYourScaniaMode) {
      dispatch(
        setRfqOrEysButtonEnabled(
          eysChangesSentWithTimestamp?.getTime() !==
            userSelectionTimestamp?.getTime(),
        ),
      )
      return
    }
    if (startupData.marketSettings.rfqEnabled) {
      dispatch(
        setRfqOrEysButtonEnabled(
          rfqSentWithTimestamp?.getTime() !== userSelectionTimestamp?.getTime(),
        ),
      )
      return
    }
    dispatch(setRfqOrEysButtonEnabled(false))
  }, [
    eysChangesSentWithTimestamp,
    rfqSentWithTimestamp,
    startupData,
    userSelectionTimestamp,
    dispatch,
  ])

  const scrollToMobileActiveChanges = useCallback(() => {
    // This is a very special hack!
    // The SDDS/Tegel Accordion component has an internal expanded/collapsed
    // state that it toggles when the user clicks the component. It also has an
    // attribute called `expanded` that is intended to control the initial
    // state. This attribute can be repurposed to open the Accordion multiple
    // times if we provide a different value for the attribute every time we
    // want to open it, any number seems to be interpreted as truethy so we can
    // just keep incrementing the previous number to keep opening the Accordion!
    setActiveChoicesIsExpanded((prev) => (prev ?? 0) + 1)

    // Double requestAnimationFrame instead of a single requestAnimationFrame is
    // sometimes is needed in Firefox for various situations, let's do it here
    // just in case it's needed.
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        const elem = document.getElementById(MOBILE_ACTIVE_CHOICES_ELEMENT_ID)
        if (!elem) {
          console.error('Expected elem to be defined.')
          return
        }
        elem.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
      })
    })
  }, [])

  const handleClickOutsideMobileMainMenu: React.MouseEventHandler<HTMLDivElement> =
    useCallback(
      (ev) => {
        ev.preventDefault()
        ev.stopPropagation()
        dispatch(closeMainMenu())
      },
      [dispatch],
    )

  const disclaimer = <Disclaimer />

  if (!truckInfo) {
    return <BuildModeRoot data-name="BuildModeRoot" />
  }

  const handleSaveAsGarageClickWithPlacement = (e: React.MouseEvent) => {
    dispatch(
      setModalSaveAsGarageState({
        source: ScaniaAdobeTrackingButtonPlacement.TopRight,
      }),
    )
    handleSaveAsGarageClick(e)
  }

  // Template
  return (
    <BuildModeRoot
      data-name="BuildModeRoot"
      onClick={handleCloseDesktopLeafMenuOnClickOutside}
    >
      <MainArea data-name="MainArea" ref={refMainArea}>
        <ControlIconsWrapper data-name="ControlIconsWrapper">
          <ControlIcons
            handleSaveClick={() => {
              menuSavePopUpIsOpen
                ? dispatch(closeMenuSavePopUp())
                : dispatch(openMenuSavePopUp())
              dispatch(closeMenuSharePopUp())
            }}
            handleShareClick={() => {
              menuSharePopUpIsOpen
                ? dispatch(closeMenuSharePopUp())
                : dispatch(openMenuSharePopUp())
              dispatch(closeMenuSavePopUp())
            }}
          />
          {menuSavePopUpIsOpen && (
            <PopUpWrapper
              topPx={4}
              handleClose={() => dispatch(closeMenuSavePopUp())}
            >
              <SaveMenu
                handleSaveAsGarageClick={handleSaveAsGarageClickWithPlacement}
                source={ScaniaAdobeTrackingButtonPlacement.TopRight}
              />
            </PopUpWrapper>
          )}
          {menuSharePopUpIsOpen && (
            <PopUpWrapper
              topPx={36}
              handleClose={() => dispatch(closeMenuSharePopUp())}
            >
              <ShareMenu
                source={ScaniaAdobeTrackingButtonPlacement.TopRight}
              ></ShareMenu>
            </PopUpWrapper>
          )}
        </ControlIconsWrapper>
        <MobileMainMenuClickOutsideCatcher
          $isOpen={mainMenuIsOpen}
          onClick={handleClickOutsideMobileMainMenu}
        />
        <ConfiguratorMenuContainer
          data-name="ConfiguratorMenu"
          $isOpen={mainMenuIsOpen}
          ref={refConfiguratorMenu}
        >
          {visibleMenuState && latestConfigChangeResult && (
            <ConfiguratorMenu
              handleItemSelection={handleItemSelection}
              handleMainMenuCloseToRoot={handleMainMenuCloseToRoot}
              handleNewClick={handleNewClickWrapper}
              handleSaveAsGarageClick={handleSaveAsGarageClick}
              handleSaveItemClick={handleSaveItemClick}
              handleSearchClick={navigateToMenu}
              handleMenuSelection={handleConfiguratorMenuClick}
              handleSessionFailure={handleSessionFailure}
              handleShareItemClick={handleShareItemClick}
              highlightedId={highlightedId}
            />
          )}
        </ConfiguratorMenuContainer>
        <ConfiguratorMenuCurrentSelectionContainer
          ref={refDesktopCurrentSelectionContainer}
          $isOpen={visibleMenuState?.leafNodes !== null}
        >
          {mainMenuIsOpen &&
            visibleMenuState?.leafNodes &&
            latestConfigChangeResult && (
              <CurrentSelection
                handleItemSelection={(id: string) =>
                  handleItemSelection(
                    id,
                    ScaniaAdobeTrackingButtonPlacement.SummarySection,
                  )
                }
                highlightedId={highlightedId}
              />
            )}
        </ConfiguratorMenuCurrentSelectionContainer>

        <MainImageAreaContainer
          data-name="MainImageArea"
          $isOpen={mainMenuIsOpen}
          ref={refMainImageArea}
        >
          <MainImageArea
            handlePanoramaClick={handleOpenPanoramaViewer}
            handleViewChange={(newSeriesFrame) => {
              dispatch(setSeriesFrame(newSeriesFrame))
            }}
            latestConfigChangeResult={latestConfigChangeResult}
            navigateToMenu={navigateToMenu}
            seriesFrame={seriesFrame}
            sessionId={sessionInitData?.sessionId || null}
            smartDashCampaignMode={smartDashCampaignMode}
          />
        </MainImageAreaContainer>
        {latestConfigChangeResult && (
          <MiniSummaryContainerLandscape data-name="MiniSummaryDesktop">
            <MiniSummaryDesktop
              activeChoicesState={latestConfigChangeResult.activeChoices}
              currentlyLoadingId={currentlyLoadingId}
              navigateToMenu={navigateToMenu}
              handleItemSelection={(id: string) =>
                handleItemSelection(
                  id,
                  ScaniaAdobeTrackingButtonPlacement.SummarySection,
                )
              }
              handleRfqOrEysClick={
                rfqOrEysButtonEnabled ? handleRfqOrEysClick : null
              }
              leafMenuNodes={visibleMenuState?.leafNodes || null}
              superEngines={latestConfigChangeResult.superEngines || []}
            />
          </MiniSummaryContainerLandscape>
        )}
        {disclaimer && <DisclaimerWrapper>{disclaimer}</DisclaimerWrapper>}
        <MobileConfigureButton
          activeChoiceCount={
            latestConfigChangeResult?.activeChoices.length || 0
          }
          handleActiveChoicesClick={scrollToMobileActiveChanges}
          activeChoices={latestConfigChangeResult?.activeChoices || []}
        />
        {latestConfigChangeResult && (
          <MiniSummaryContainerMobile data-name="MiniSummaryMobile">
            <Specification
              activeChoicesElementId={MOBILE_ACTIVE_CHOICES_ELEMENT_ID}
              activeChoicesExpanded={activeChoicesIsExpanded}
              activeChoicesState={latestConfigChangeResult.activeChoices}
              navigateToMenu={navigateToMenu}
              handleEngineSelection={(id: string) =>
                handleItemSelection(
                  id,
                  ScaniaAdobeTrackingButtonPlacement.SummarySection,
                )
              }
              superEngines={latestConfigChangeResult.superEngines || []}
            />
          </MiniSummaryContainerMobile>
        )}
        {startupData && (
          <BuildModePortraitFooter
            handleFooterButtonClick={
              rfqOrEysButtonEnabled ? handleRfqOrEysClick : null
            }
          />
        )}
      </MainArea>
      {showPanoramaViewer && configDependentImageParams.buildPanoramaImage && (
        <PanoramaViewer
          buildImage={configDependentImageParams.buildPanoramaImage}
        />
      )}
      {marketSettings?.newsBannerEnabled && showNewsBanner && <NewsBanner />}
    </BuildModeRoot>
  )
}

function getUnavailableFrames(
  rebopResult: ConfigChangeResult,
): ImageSeriesFrame[] {
  return (
    rebopResult.auxCarrier.auxiliaries['FrameFilter'].ignoredFrames
      ?.map<ImageSeriesFrame>((o) => ({
        seriesId: o.series,
        frameId: o.frame,
      }))
      .sort((a, b) => {
        const cmp = a.seriesId.localeCompare(b.seriesId)
        if (cmp !== 0) {
          return cmp
        }
        return a.frameId - b.frameId
      }) || []
  )
}

function stringArrayToHashSet(
  menusWithSelectableItems: string[],
): Record<string, boolean> {
  const result: Record<string, boolean> = {}
  menusWithSelectableItems.forEach((id) => {
    result[id] = true
  })
  return result
}
