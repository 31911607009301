/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { OutResolvers } from './OutResolvers'
import {
  OutResolversFromJSON,
  OutResolversFromJSONTyped,
  OutResolversToJSON,
} from './OutResolvers'

/**
 *
 * @export
 * @interface GetInitialConsequenceOfChangeResponseWrapper
 */
export interface GetInitialConsequenceOfChangeResponseWrapper {
  /**
   *
   * @type {OutResolvers}
   * @memberof GetInitialConsequenceOfChangeResponseWrapper
   */
  getInitialConsequenceOfChange?: OutResolvers | null
  /**
   *
   * @type {string}
   * @memberof GetInitialConsequenceOfChangeResponseWrapper
   */
  factoryModelName?: string | null
  /**
   *
   * @type {boolean}
   * @memberof GetInitialConsequenceOfChangeResponseWrapper
   */
  isFactoryModel?: boolean | null
  /**
   *
   * @type {string}
   * @memberof GetInitialConsequenceOfChangeResponseWrapper
   */
  error?: string | null
}

/**
 * Check if a given object implements the GetInitialConsequenceOfChangeResponseWrapper interface.
 */
export function instanceOfGetInitialConsequenceOfChangeResponseWrapper(
  value: object,
): boolean {
  let isInstance = true

  return isInstance
}

export function GetInitialConsequenceOfChangeResponseWrapperFromJSON(
  json: any,
): GetInitialConsequenceOfChangeResponseWrapper {
  return GetInitialConsequenceOfChangeResponseWrapperFromJSONTyped(json, false)
}

export function GetInitialConsequenceOfChangeResponseWrapperFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GetInitialConsequenceOfChangeResponseWrapper {
  if (json === undefined || json === null) {
    return json
  }
  return {
    getInitialConsequenceOfChange: !exists(
      json,
      'get_initial_consequence_of_change',
    )
      ? undefined
      : OutResolversFromJSON(json['get_initial_consequence_of_change']),
    factoryModelName: !exists(json, 'factory_model_name')
      ? undefined
      : json['factory_model_name'],
    isFactoryModel: !exists(json, 'is_factory_model')
      ? undefined
      : json['is_factory_model'],
    error: !exists(json, 'error') ? undefined : json['error'],
  }
}

export function GetInitialConsequenceOfChangeResponseWrapperToJSON(
  value?: GetInitialConsequenceOfChangeResponseWrapper | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    get_initial_consequence_of_change: OutResolversToJSON(
      value.getInitialConsequenceOfChange,
    ),
    factory_model_name: value.factoryModelName,
    is_factory_model: value.isFactoryModel,
    error: value.error,
  }
}
