/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface FactoryModelOverrides
 */
export interface FactoryModelOverrides {
  /**
   *
   * @type {boolean}
   * @memberof FactoryModelOverrides
   */
  readonly visible?: boolean
  /**
   * Key:   Etel language id.
   * Value: Name override text.
   * @type {{ [key: string]: string; }}
   * @memberof FactoryModelOverrides
   */
  nameOverrides?: { [key: string]: string } | null
  /**
   *
   * @type {number}
   * @memberof FactoryModelOverrides
   */
  sortOrder?: number | null
}

/**
 * Check if a given object implements the FactoryModelOverrides interface.
 */
export function instanceOfFactoryModelOverrides(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function FactoryModelOverridesFromJSON(
  json: any,
): FactoryModelOverrides {
  return FactoryModelOverridesFromJSONTyped(json, false)
}

export function FactoryModelOverridesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): FactoryModelOverrides {
  if (json === undefined || json === null) {
    return json
  }
  return {
    visible: !exists(json, 'Visible') ? undefined : json['Visible'],
    nameOverrides: !exists(json, 'NameOverrides')
      ? undefined
      : json['NameOverrides'],
    sortOrder: !exists(json, 'SortOrder') ? undefined : json['SortOrder'],
  }
}

export function FactoryModelOverridesToJSON(
  value?: FactoryModelOverrides | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    NameOverrides: value.nameOverrides,
    SortOrder: value.sortOrder,
  }
}
