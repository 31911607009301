import styled from 'styled-components'

export const TextAndInfoWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  column-gap: 8px;

  h3 {
    font-size: 18px;
  }
`

export const IconInfoWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    /* For some reason this icon seems to need a one pixel nudge. */
    margin-top: -1px;

    height: 24px;
    color: var(--tds-blue-800);
  }
`
