// Tegel CSS as Styled Components CSS.
//
// The purpose of this is to allow easier overrides of styles inside a Styled
// Component instead of switching the `className` attribute. This can be
// convenient when we want to use media queries or Styled Component properties
// to override CSS rules from Tegel.

import { css } from 'styled-components'

/**
 * CSS rules for tds-paragraph-02.
 */
export const StyledTdsParagraph02 = css`
  font-family: 'Scania Sans', Arial, Helvetica, sans-serif;
  font-size: 1.25rem;
  line-height: 1.5rem;
  letter-spacing: -0.01em;
`

/**
 * CSS rules for tds-body-01.
 */
export const StyledTdsBody01 = css`
  font-family: 'Scania Sans', Arial, Helvetica, sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: -0.02em;
`

/**
 * CSS rules for tds-headline-01.
 */
export const StyledTdsHeadline01 = css`
  font-family: 'Scania Sans Headline', Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 2.5rem;
  line-height: 2.5rem;
  letter-spacing: 0;
`

export const StyledTdsHeadline06 = css`
  font-family: 'Scania Sans', Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
`

export const StyledTdsHeadline07 = css`
  font-family: 'Scania Sans Semi Condensed', Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.01em;
`
