/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ReadMoreResourceDefinition } from './ReadMoreResourceDefinition'
import {
  ReadMoreResourceDefinitionFromJSON,
  ReadMoreResourceDefinitionFromJSONTyped,
  ReadMoreResourceDefinitionToJSON,
} from './ReadMoreResourceDefinition'
import type { ScaniaReadMoreTextNode } from './ScaniaReadMoreTextNode'
import {
  ScaniaReadMoreTextNodeFromJSON,
  ScaniaReadMoreTextNodeFromJSONTyped,
  ScaniaReadMoreTextNodeToJSON,
} from './ScaniaReadMoreTextNode'

/**
 *
 * @export
 * @interface ReadMoreResources
 */
export interface ReadMoreResources {
  /**
   *
   * @type {Array<ReadMoreResourceDefinition>}
   * @memberof ReadMoreResources
   */
  resources: Array<ReadMoreResourceDefinition>
  /**
   *
   * @type {string}
   * @memberof ReadMoreResources
   */
  shortText?: string | null
  /**
   *
   * @type {Array<ScaniaReadMoreTextNode>}
   * @memberof ReadMoreResources
   */
  readmoreText?: Array<ScaniaReadMoreTextNode> | null
}

/**
 * Check if a given object implements the ReadMoreResources interface.
 */
export function instanceOfReadMoreResources(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'resources' in value

  return isInstance
}

export function ReadMoreResourcesFromJSON(json: any): ReadMoreResources {
  return ReadMoreResourcesFromJSONTyped(json, false)
}

export function ReadMoreResourcesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ReadMoreResources {
  if (json === undefined || json === null) {
    return json
  }
  return {
    resources: (json['resources'] as Array<any>).map(
      ReadMoreResourceDefinitionFromJSON,
    ),
    shortText: !exists(json, 'shortText') ? undefined : json['shortText'],
    readmoreText: !exists(json, 'readmoreText')
      ? undefined
      : json['readmoreText'] === null
      ? null
      : (json['readmoreText'] as Array<any>).map(
          ScaniaReadMoreTextNodeFromJSON,
        ),
  }
}

export function ReadMoreResourcesToJSON(value?: ReadMoreResources | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    resources: (value.resources as Array<any>).map(
      ReadMoreResourceDefinitionToJSON,
    ),
    shortText: value.shortText,
    readmoreText:
      value.readmoreText === undefined
        ? undefined
        : value.readmoreText === null
        ? null
        : (value.readmoreText as Array<any>).map(ScaniaReadMoreTextNodeToJSON),
  }
}
