import { useCallback, useEffect } from 'react'
import { SESSION_FAILURE } from '../../api/errors'
import { useClient } from '../../utils/useClient'
import useTexts from '../../utils/useTexts'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import {
  getSessionInitDataState,
  loggedInStatus,
} from '../../store/sessionDataSlice'
import { closeAllModals } from '../../store/modalSlice'
import { TdsButton, TdsModal } from '@scania/tegel-react'

export interface ModalUserAccountGdprConsentProps {
  handleFatalError: () => void
  handleSessionFailure: () => void
}
export function ModalUserAccountGdprConsent({
  handleFatalError,
  handleSessionFailure,
}: ModalUserAccountGdprConsentProps): JSX.Element {
  const t = useTexts()
  const client = useClient()
  const sessionInitData = useAppSelector(getSessionInitDataState)
  const dispatch = useAppDispatch()

  const handleAccept = useCallback(() => {
    const asyncWrapper = async () => {
      try {
        const sessionId = sessionInitData?.sessionId
        if (!sessionId) {
          throw new Error('Expected sessionId to be defined.')
        }
        if (!client) {
          throw new Error('Expected client to be defined.')
        }
        const response = await client.setUserGdprConsent({ sessionId })
        if (response.error === SESSION_FAILURE) {
          handleSessionFailure()
          return
        } else if (response.error) {
          throw new Error(response.error)
        }
        console.log(
          'The user has accepted the GDPR related terms for the logged in account.',
        )
        dispatch(closeAllModals())
      } catch (err) {
        console.error(err)
        handleFatalError()
        return
      }
    }
    asyncWrapper()
  }, [
    client,
    dispatch,
    handleFatalError,
    handleSessionFailure,
    sessionInitData,
  ])

  const handleLogout = useCallback(async () => {
    try {
      const sessionId = sessionInitData?.sessionId
      if (!sessionId) {
        throw new Error('Expected sessionId to be defined.')
      }
      if (!client) {
        throw new Error('Expected client to be defined.')
      }
      const response = await client.logout(sessionId)
      if (response.error === SESSION_FAILURE) {
        handleSessionFailure()
        return
      } else if (response.error || response.logout !== true) {
        handleFatalError()
        return
      }
      dispatch(loggedInStatus({ isloggedin: false, userMail: '' }))
    } catch (err) {
      console.error(err)
      handleFatalError()
      return
    }
  }, [
    dispatch,
    client,
    sessionInitData,
    handleFatalError,
    handleSessionFailure,
  ])

  const handleCancel = useCallback(() => {
    const asyncWrapper = async () => {
      await handleLogout()
      dispatch(closeAllModals())
    }
    asyncWrapper()
  }, [dispatch, handleLogout])

  // Eventlistener to close modal on tdsClose (click outside modal and click on close cross)
  useEffect(() => {
    document.addEventListener('tdsClose', handleCancel)
    return () => {
      document.removeEventListener('tdsClose', handleCancel)
    }
  }, [handleCancel])

  return (
    <TdsModal size="md" header={t('GDPR_CONFIRM_TITLE')} show>
      <span slot="body">
        <p>{t('GDPR_CONFIRM_MESSAGE')}</p>
      </span>

      <span slot="actions" className="tds-u-flex tds-u-gap2 tds-u-flex-wrap">
        <TdsButton
          variant="primary"
          size="md"
          text={t('LABEL_ACTION_OK')}
          onClick={handleAccept}
        />
        <TdsButton
          variant="secondary"
          size="md"
          text={t('LABEL_ACTION_CANCEL')}
          onClick={handleCancel}
        />
      </span>
    </TdsModal>
  )
}
