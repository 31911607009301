// TODO: Reduce this component to a minimum. All the grid related things can
// probably be removed.

import styled from 'styled-components'

export const PageRoot = styled.div`
  background-color: var(--tds-white);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  min-width: 100vw;
  //transform: scale(1); /* Z-index scope. */
  //font-size: 80%;
  //background: hsl(227, 9%, 61%);
  display: grid;
  grid-template-rows: 50px 1fr;
  grid-template-columns: 1fr;
  grid-template-rows: var(--header-height) 1fr;
  header {
    background: white;
  }

  // TODO: Replace this element selector with a class name or a styled
  // component.
  section {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;
    /* Lower truck to center it on mobile. */
    @media (min-aspect-ratio: 8/5) {
      background-position: 50% 80%;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;
    //transform: scale(1); /* Z-index scope. */

    // TODO: Try to eliminate this, seems to only be needed on iPhone??
    height: calc(var(--app-height) - var(--header-height));
  }
`
PageRoot.displayName = 'PageRoot'
