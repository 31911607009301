/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface MopinionSettings
 */
export interface MopinionSettings {
  /**
   * A two-letter ISO language code.
   * @type {string}
   * @memberof MopinionSettings
   */
  languageIso: string
  /**
   *
   * @type {boolean}
   * @memberof MopinionSettings
   */
  mopinionEnabled: boolean
}

/**
 * Check if a given object implements the MopinionSettings interface.
 */
export function instanceOfMopinionSettings(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'languageIso' in value
  isInstance = isInstance && 'mopinionEnabled' in value

  return isInstance
}

export function MopinionSettingsFromJSON(json: any): MopinionSettings {
  return MopinionSettingsFromJSONTyped(json, false)
}

export function MopinionSettingsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): MopinionSettings {
  if (json === undefined || json === null) {
    return json
  }
  return {
    languageIso: json['languageIso'],
    mopinionEnabled: json['mopinionEnabled'],
  }
}

export function MopinionSettingsToJSON(value?: MopinionSettings | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    languageIso: value.languageIso,
    mopinionEnabled: value.mopinionEnabled,
  }
}
