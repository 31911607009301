/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ScaniaFrameFilter } from './ScaniaFrameFilter'
import {
  ScaniaFrameFilterFromJSON,
  ScaniaFrameFilterFromJSONTyped,
  ScaniaFrameFilterToJSON,
} from './ScaniaFrameFilter'

/**
 *
 * @export
 * @interface ScaniaGlobalAuxiliary
 */
export interface ScaniaGlobalAuxiliary {
  /**
   *
   * @type {string}
   * @memberof ScaniaGlobalAuxiliary
   */
  name: string
  /**
   *
   * @type {Array<ScaniaFrameFilter>}
   * @memberof ScaniaGlobalAuxiliary
   */
  ignoredFrames?: Array<ScaniaFrameFilter> | null
  /**
   *
   * @type {string}
   * @memberof ScaniaGlobalAuxiliary
   */
  value?: string | null
}

/**
 * Check if a given object implements the ScaniaGlobalAuxiliary interface.
 */
export function instanceOfScaniaGlobalAuxiliary(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'name' in value

  return isInstance
}

export function ScaniaGlobalAuxiliaryFromJSON(
  json: any,
): ScaniaGlobalAuxiliary {
  return ScaniaGlobalAuxiliaryFromJSONTyped(json, false)
}

export function ScaniaGlobalAuxiliaryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ScaniaGlobalAuxiliary {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: json['Name'],
    ignoredFrames: !exists(json, 'IgnoredFrames')
      ? undefined
      : json['IgnoredFrames'] === null
      ? null
      : (json['IgnoredFrames'] as Array<any>).map(ScaniaFrameFilterFromJSON),
    value: !exists(json, 'Value') ? undefined : json['Value'],
  }
}

export function ScaniaGlobalAuxiliaryToJSON(
  value?: ScaniaGlobalAuxiliary | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    Name: value.name,
    IgnoredFrames:
      value.ignoredFrames === undefined
        ? undefined
        : value.ignoredFrames === null
        ? null
        : (value.ignoredFrames as Array<any>).map(ScaniaFrameFilterToJSON),
    Value: value.value,
  }
}
