/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ScalarConstraints
 */
export interface ScalarConstraints {
  /**
   *
   * @type {number}
   * @memberof ScalarConstraints
   */
  max: number
  /**
   *
   * @type {number}
   * @memberof ScalarConstraints
   */
  min: number
}

/**
 * Check if a given object implements the ScalarConstraints interface.
 */
export function instanceOfScalarConstraints(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'max' in value
  isInstance = isInstance && 'min' in value

  return isInstance
}

export function ScalarConstraintsFromJSON(json: any): ScalarConstraints {
  return ScalarConstraintsFromJSONTyped(json, false)
}

export function ScalarConstraintsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ScalarConstraints {
  if (json === undefined || json === null) {
    return json
  }
  return {
    max: json['max'],
    min: json['min'],
  }
}

export function ScalarConstraintsToJSON(value?: ScalarConstraints | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    max: value.max,
    min: value.min,
  }
}
