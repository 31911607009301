import { GO_CONTINUE_SENTINEL_VALUE } from '../api/constants'
import {
  GoState,
  GowizardContinueV2Request,
  GowizardUndoV2Request,
  GuidedOfferingInfo,
  ScaniaConfiguratorApi,
} from '../api/generated'
import { EtelMarketAndLanguage } from '../api/startup'
import {
  GoPageId,
  GoStateSyncSettings,
} from '../pages/GuidedOffering/syncBackendStateHook'
import { GuidedOfferingClientState } from '../types/GuidedOffering'
import { GuidedOfferingClientStateStatus } from '../types/GuidedOffering/GuidedOfferingClientState'
import { buildCardData } from './cardDataUtil'

export enum GoFilterMode {
  Normal,
  Bev,
}

export enum GoActionType {
  /** A single selection should bring the state to the desired goal. */
  SingleItemSelection = 'SingleItemSelection',

  /** A single undo should bring the state to the desired goal. */
  SingleItemUndo = 'SingleItemUndo',

  /** A full replay on top a new GO State is needed. */
  FullReplay = 'FullReplay',
}

function arrayEquals(a: string[], b: string[]) {
  return a.every((aElem, i) => {
    return aElem === b[i]
  })
}

export function resolveGoActionType(
  syncSettings: GoStateSyncSettings,
  goClientState: GuidedOfferingClientState,
  desiredSelection: string[],
  desiredMode: GoFilterMode,
): GoActionType | null {
  if (
    goClientState.lastResponse.bevFiltersEnabled === false &&
    desiredMode === GoFilterMode.Bev
  ) {
    return GoActionType.FullReplay
  }
  if (
    syncSettings.pageId !== GoPageId.Optionals &&
    goClientState.stateUpdatedByPage === GoPageId.Optionals
  ) {
    // The state has transitioned into the optionals phase and we probably need
    // to throw that away and do a full replay of the desiredSelections on top
    // of a new state.
    //
    // TODO: Refactor away the 'Continue' and 'Exit' magic strings in the web
    // API.
    return GoActionType.FullReplay
  }
  const lengthDiff =
    desiredSelection.length - goClientState.userSelections.length
  switch (lengthDiff) {
    case 0:
      return arrayEquals(desiredSelection, goClientState.userSelections)
        ? null
        : GoActionType.FullReplay
    case 1:
      const desiredMinusOne = desiredSelection.slice(
        0,
        desiredSelection.length - 1,
      )
      return arrayEquals(desiredMinusOne, goClientState.userSelections)
        ? GoActionType.SingleItemSelection
        : GoActionType.FullReplay
    case -1:
      const userSelectionMinusOne = goClientState.userSelections.slice(
        0,
        goClientState.userSelections.length - 1,
      )
      return arrayEquals(userSelectionMinusOne, desiredSelection)
        ? GoActionType.SingleItemUndo
        : GoActionType.FullReplay
    default:
      return GoActionType.FullReplay
  }
}

async function performContinueRequest(
  pageId: GoPageId,
  client: ScaniaConfiguratorApi,
  id: string,
  desiredSelection: string[],
  goState: GoState,

  // Preserve previous value since it's only provided by gowizardStart.
  // TODO: Make scds_backend report this for every GO response.
  etelLastUpdated: string,
): Promise<GuidedOfferingClientState> {
  const req: GowizardContinueV2Request = {
    selection: [id],
    state: goState,
  }
  // TODO: Use type inference here when the generated code has been
  // cleaned up from duplicate types.
  let result: GuidedOfferingInfo = (await client.gowizardContinueV2(req))
    .gowizardContinueV2
  if (
    result.question?.options?.some((o) => o.id === GO_CONTINUE_SENTINEL_VALUE)
  ) {
    if (!result.state) {
      throw new Error('Expected result.state to be defined.')
    }
    const sliderQuestions = result.sliderQuestions
    if (!sliderQuestions) {
      throw new Error('Expected sliderQuestions to be defined.')
    }
    const continueReq: GowizardContinueV2Request = {
      selection: [GO_CONTINUE_SENTINEL_VALUE],
      state: result.state,
    }
    result = (await client.gowizardContinueV2(continueReq)).gowizardContinueV2
    const newState: GuidedOfferingClientState = {
      cards: null,
      etelLastUpdated,
      lastResponse: result,
      sliderQuestions,
      stateUpdatedByPage: pageId,
      status: GuidedOfferingClientStateStatus.Active,
      userSelections: desiredSelection,
    }
    return newState
  } else {
    const cards = buildCardData(result)
    if (!cards) {
      throw new Error('Expected cards to be defined.')
    }
    const newState: GuidedOfferingClientState = {
      cards,
      etelLastUpdated,
      lastResponse: result,
      sliderQuestions: null,
      stateUpdatedByPage: pageId,
      status: GuidedOfferingClientStateStatus.Active,
      userSelections: desiredSelection,
    }
    return newState
  }
}

export async function syncClientAndBackendState(
  pageId: GoPageId,
  marketLanguage: EtelMarketAndLanguage,
  sessionTimeoutSeconds: number | undefined,
  goAction: GoAction,
  goClientState: GuidedOfferingClientState | null,
  client: ScaniaConfiguratorApi,
): Promise<GuidedOfferingClientState> {
  let newState: GuidedOfferingClientState
  switch (goAction.type) {
    case GoActionType.SingleItemSelection:
      if (!goClientState?.lastResponse.state) {
        throw new Error('Expected state to be defined.')
      }
      newState = await performContinueRequest(
        pageId,
        client,
        goAction.desiredLeaf,
        goAction.desiredSelection,
        goClientState.lastResponse.state,
        goClientState.etelLastUpdated,
      )
      break
    case GoActionType.SingleItemUndo: {
      if (!goClientState) {
        throw new Error('Expected goClientState to be defined.')
      }
      const newUserSelections = goClientState.userSelections.concat()
      const undoId = newUserSelections.pop()
      if (!undoId) {
        throw new Error('Expected undoId to be defined.')
      }
      const lastState = goClientState.lastResponse.state
      if (!lastState) {
        throw new Error('Expected lastState to be defined.')
      }
      const undoReq: GowizardUndoV2Request = lastState
      const result = await client.gowizardUndoV2(undoReq)
      const cards = buildCardData(result)
      newState = {
        cards,
        etelLastUpdated: goClientState.etelLastUpdated,
        lastResponse: result,
        sliderQuestions: null,
        stateUpdatedByPage: pageId,
        status: GuidedOfferingClientStateStatus.Active,
        userSelections: newUserSelections,
      }
      break
    }
    case GoActionType.FullReplay:
      {
        if (goClientState?.lastResponse.state) {
          try {
            const lastState: GoState = goClientState.lastResponse.state
            await client.gowizardClose(lastState)
          } catch (err) {
            // We should only log here and keep going since we are trying to start
            // a new GO session and the old one doesn't matter.
            console.error(err)
          }
        }
        const showTextPreview =
          window.tempStartupCriticalState.appData.show_preview_texts
        const startInfo: GuidedOfferingInfo = (
          await client.gowizardStart({
            language: marketLanguage.language.toString(),
            market: marketLanguage.market.toString(),
            useBevFilters: goAction.desiredMode === GoFilterMode.Bev,
            timeoutSeconds: sessionTimeoutSeconds,
            showTextPreview,
          })
        ).gowizardStart
        const cards = buildCardData(startInfo)
        if (!cards) {
          throw new Error('Expected cards to be defined.')
        }
        let tempState: GuidedOfferingClientState = {
          cards,
          etelLastUpdated: startInfo.etelLastUpdated,
          lastResponse: startInfo,
          sliderQuestions: null,
          stateUpdatedByPage: pageId,
          status: GuidedOfferingClientStateStatus.Active,
          userSelections: [],
        }
        if (!tempState.lastResponse.state) {
          throw new Error(
            'Expected tempState.lastResponse.state to be defined.',
          )
        }
        for (let i = 0; i < goAction.desiredSelection.length; i++) {
          const id = goAction.desiredSelection[i]
          const desiredSelection = goAction.desiredSelection.slice(0, i + 1)
          tempState = await performContinueRequest(
            pageId,
            client,
            id,
            desiredSelection,
            tempState.lastResponse.state,
            startInfo.etelLastUpdated,
          )
          if (!tempState.lastResponse.state) {
            throw new Error(
              'Expected tempState.lastResponse.state to be defined.',
            )
          }
        }
        newState = tempState
      }
      break
    default:
      throw new Error('Unexpected goAction.type: ' + goAction.type)
  }
  return newState
}

export interface GoAction {
  type: GoActionType
  desiredLeaf: string
  desiredSelection: string[]
  desiredMode: GoFilterMode
}

export function planNextGoAction(
  syncSettings: GoStateSyncSettings,
  desiredSelection: string[],
  goClientState: GuidedOfferingClientState | null,
  desiredMode: GoFilterMode,
): GoAction | null {
  const desiredLeaf = desiredSelection[desiredSelection.length - 1]
  const goActionType = goClientState
    ? resolveGoActionType(
        syncSettings,
        goClientState,
        desiredSelection,
        desiredMode,
      )
    : GoActionType.FullReplay
  return goActionType === null
    ? null
    : { type: goActionType, desiredLeaf, desiredSelection, desiredMode }
}
