import styled from 'styled-components'
import { Specification } from './Specification'
import { LeafMenuEntry } from '../Menu/ConfiguratorMenu'
import { MenuItem, SummaryItem } from '../../../api/generated'
import { useRfqEysButtonState } from '../RfqEysButtonState'
import { RfqEysButton } from '../RfqEysButton'

const TextAreaRoot = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const SpecificationWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  margin-right: 5px;
  max-height: calc(var(--app-height) - var(--header-height));
`

const ButtonWrapper = styled.div`
  padding-top: 10px;
  button {
    font-weight: bold !important;
  }
`

export interface MiniSummaryDesktopProps {
  activeChoicesState: SummaryItem[]
  currentlyLoadingId: string | null
  navigateToMenu: (id: string, path: string) => void
  handleItemSelection: (id: string) => void
  handleRfqOrEysClick: (() => void) | null
  leafMenuNodes: LeafMenuEntry[] | null
  superEngines: MenuItem[]
}

export function MiniSummaryDesktop({
  activeChoicesState,
  navigateToMenu,
  handleItemSelection,
  handleRfqOrEysClick,
  superEngines,
}: MiniSummaryDesktopProps): JSX.Element {
  const { hidden: buttonHidden, buttonText } = useRfqEysButtonState()

  return (
    <TextAreaRoot data-name="TextAreaDesktop">
      <SpecificationWrapper>
        <Specification
          activeChoicesElementId={'DesktopActiveChoicesElementId'}
          activeChoicesExpanded={undefined}
          activeChoicesState={activeChoicesState}
          navigateToMenu={navigateToMenu}
          handleEngineSelection={handleItemSelection}
          superEngines={superEngines}
        ></Specification>
        {!buttonHidden && (
          <ButtonWrapper>
            <RfqEysButton handleClick={handleRfqOrEysClick} text={buttonText} />
          </ButtonWrapper>
        )}
      </SpecificationWrapper>
    </TextAreaRoot>
  )
}
