/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ExpectedAnswerType } from './ExpectedAnswerType'
import {
  ExpectedAnswerTypeFromJSON,
  ExpectedAnswerTypeFromJSONTyped,
  ExpectedAnswerTypeToJSON,
} from './ExpectedAnswerType'
import type { GoOptionInternal } from './GoOptionInternal'
import {
  GoOptionInternalFromJSON,
  GoOptionInternalFromJSONTyped,
  GoOptionInternalToJSON,
} from './GoOptionInternal'
import type { GoQuestion } from './GoQuestion'
import {
  GoQuestionFromJSON,
  GoQuestionFromJSONTyped,
  GoQuestionToJSON,
} from './GoQuestion'
import type { GoStateLanguage } from './GoStateLanguage'
import {
  GoStateLanguageFromJSON,
  GoStateLanguageFromJSONTyped,
  GoStateLanguageToJSON,
} from './GoStateLanguage'
import type { GoStateMarket } from './GoStateMarket'
import {
  GoStateMarketFromJSON,
  GoStateMarketFromJSONTyped,
  GoStateMarketToJSON,
} from './GoStateMarket'

/**
 *
 * @export
 * @interface GoState
 */
export interface GoState {
  /**
   *
   * @type {boolean}
   * @memberof GoState
   */
  bevFiltersEnabled: boolean
  /**
   *
   * @type {string}
   * @memberof GoState
   */
  etelConfigId: string
  /**
   *
   * @type {Array<string>}
   * @memberof GoState
   */
  selections: Array<string>
  /**
   *
   * @type {GoStateLanguage}
   * @memberof GoState
   */
  language: GoStateLanguage
  /**
   *
   * @type {boolean}
   * @memberof GoState
   */
  textPreview: boolean
  /**
   *
   * @type {GoStateMarket}
   * @memberof GoState
   */
  market: GoStateMarket
  /**
   *
   * @type {ExpectedAnswerType}
   * @memberof GoState
   */
  answerType: ExpectedAnswerType
  /**
   *
   * @type {string}
   * @memberof GoState
   */
  desiredDealerDateOverride?: string | null
  /**
   *
   * @type {Array<GoOptionInternal>}
   * @memberof GoState
   */
  lastQuestionOptions?: Array<GoOptionInternal> | null
  /**
   *
   * @type {GoQuestion}
   * @memberof GoState
   */
  storedNextQuestion?: GoQuestion | null
  /**
   *
   * @type {Array<string>}
   * @memberof GoState
   */
  storedEtelItems?: Array<string> | null
}

/**
 * Check if a given object implements the GoState interface.
 */
export function instanceOfGoState(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'bevFiltersEnabled' in value
  isInstance = isInstance && 'etelConfigId' in value
  isInstance = isInstance && 'selections' in value
  isInstance = isInstance && 'language' in value
  isInstance = isInstance && 'textPreview' in value
  isInstance = isInstance && 'market' in value
  isInstance = isInstance && 'answerType' in value

  return isInstance
}

export function GoStateFromJSON(json: any): GoState {
  return GoStateFromJSONTyped(json, false)
}

export function GoStateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GoState {
  if (json === undefined || json === null) {
    return json
  }
  return {
    bevFiltersEnabled: json['BevFiltersEnabled'],
    etelConfigId: json['EtelConfigId'],
    selections: json['Selections'],
    language: GoStateLanguageFromJSON(json['Language']),
    textPreview: json['TextPreview'],
    market: GoStateMarketFromJSON(json['Market']),
    answerType: ExpectedAnswerTypeFromJSON(json['AnswerType']),
    desiredDealerDateOverride: !exists(json, 'DesiredDealerDateOverride')
      ? undefined
      : json['DesiredDealerDateOverride'],
    lastQuestionOptions: !exists(json, 'LastQuestionOptions')
      ? undefined
      : json['LastQuestionOptions'] === null
      ? null
      : (json['LastQuestionOptions'] as Array<any>).map(
          GoOptionInternalFromJSON,
        ),
    storedNextQuestion: !exists(json, 'StoredNextQuestion')
      ? undefined
      : GoQuestionFromJSON(json['StoredNextQuestion']),
    storedEtelItems: !exists(json, 'StoredEtelItems')
      ? undefined
      : json['StoredEtelItems'],
  }
}

export function GoStateToJSON(value?: GoState | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    BevFiltersEnabled: value.bevFiltersEnabled,
    EtelConfigId: value.etelConfigId,
    Selections: value.selections,
    Language: GoStateLanguageToJSON(value.language),
    TextPreview: value.textPreview,
    Market: GoStateMarketToJSON(value.market),
    AnswerType: ExpectedAnswerTypeToJSON(value.answerType),
    DesiredDealerDateOverride: value.desiredDealerDateOverride,
    LastQuestionOptions:
      value.lastQuestionOptions === undefined
        ? undefined
        : value.lastQuestionOptions === null
        ? null
        : (value.lastQuestionOptions as Array<any>).map(GoOptionInternalToJSON),
    StoredNextQuestion: GoQuestionToJSON(value.storedNextQuestion),
    StoredEtelItems: value.storedEtelItems,
  }
}
