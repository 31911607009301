import styled from 'styled-components'
import { SvgCross } from '../SvgImports'
import { useCallback, useEffect, useRef, useState } from 'react'
import useTexts, { TextGetter } from '../../utils/useTexts'
import convertNewlineToJSX from '../../utils/convertNewlineToJSX'
import { TestElementTypeId } from '../../types/TestAttributeId'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { BreakpointWidthPx } from '../../css/BreakpointWidthPx'
import {
  closePanelInfo,
  selectSidePanelState,
} from '../../store/sidePanelSlice'
import {
  getFactoryModelsState,
  getMarketLanguageState,
  getMarketSettingsState,
  getVersionInfoState,
} from '../../store/appSlice'
import { getGoEtelLastUpdatedState } from '../../store/sessionDataSlice'
import { openModalUploadXml } from '../../store/modalSlice'
import { TdsAccordion, TdsAccordionItem, TdsButton } from '@scania/tegel-react'

interface InfoRootProps {
  $sidePanelIsOpen: boolean
}

const InfoRoot = styled.div<InfoRootProps>`
  height: calc(100% - var(--header-height));
  width: var(--side-panel-width);
  background-color: var(--tds-white);
  position: absolute;

  // TODO: Revisit and try to eliminate.
  z-index: 10;

  top: var(--header-height);
  border: 1px solid var(--tds-grey-300);
  overflow-y: scroll;

  --sidepanel-transition-duration: 250ms;
  right: ${(props) =>
    props.$sidePanelIsOpen ? '0px' : 'calc(0px - var(--side-panel-width))'};
  visibility: ${(props) => (props.$sidePanelIsOpen ? 'visible' : 'hidden')};
  transition-duration: var(--sidepanel-transition-duration);
  transition-property: right, visibility;

  @media screen and (max-width: ${BreakpointWidthPx.Tablet}px) {
    height: 100%;
    top: 0;
    max-width: 100vw;
  }
`

const CloseIconWrapper = styled.div`
  cursor: pointer;

  svg {
    height: 32px;
    width: 32px;
    margin: 12px;
  }
`

const TermsAndConditionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  //min-width: calc(var(--side-panel-width) - 16px - 16px - 16px - 8px);
`

const AccordionContentRoot = styled.div``

const AboutWrapper = styled.div`
  display: flex;
  width: 100%;
  //min-width: calc(var(--side-panel-width) - 16px - 16px - 16px - 8px);
`

const AboutRightContainer = styled.div`
  width: 40%;
  right: 0px;
  float: right;
`

const AboutLeftContainer = styled.div`
  width: 60%;
`

const SmallHeader = styled.h5`
  font-size: 11px;
`

const Text = styled.div`
  font-size: 11px;
  margin: 0px;
  line-height: 16px;
`

const Link = styled.div`
  font-size: 11px;
  margin: 0px;
  margin-top: 6px;
  margin-left: 8px;
  line-height: 13px;
`
interface SpecialLinkEntry {
  text: string
  link: string
}

const TdsAccordionTermsAndConditions = (): JSX.Element => {
  const t = useTexts()
  const marketLanguage = useAppSelector(getMarketLanguageState)

  function getSpecialLinkEntries(
    etelMarketCode: number | null,
  ): Array<SpecialLinkEntry> {
    let specialLinks: Array<SpecialLinkEntry> = []
    switch (etelMarketCode) {
      // Germany
      case 5145:
        specialLinks = [
          {
            text: 'Impressum',
            link: 'https://www.scania.com/de/de/home/admin/misc/impressum.html',
          },
          {
            text: 'Datenschutz',
            link: 'https://www.scania.com/de/de/home/admin/misc/privacy-statement.html',
          },
        ]
        break
      // Austria
      case 5144:
        specialLinks = [
          {
            text: 'Impressum',
            link: 'https://www.scania.com/at/de/home/admin/misc/impressum.html',
          },
          {
            text: 'Datenschutz',
            link: 'https://www.scania.com/at/de/home/admin/misc/privacy-statement.html',
          },
        ]
        break
      default:
        break
    }
    return specialLinks
  }

  const buildSpecialMarketLegalLinkElements = (
    etelMarketCode: number | null,
  ) => {
    const specialLinks = getSpecialLinkEntries(etelMarketCode)
    return (
      <>
        {specialLinks.map((value) => {
          return (
            <Link>
              <a href={value.link} target="_blank" rel="noreferrer">
                {value.text}
              </a>
            </Link>
          )
        })}
      </>
    )
  }

  return (
    <TdsAccordion
      style={{ margin: '16px' }}
      data-test-element-type={TestElementTypeId.AccordionTermsAndCondition}
    >
      <TdsAccordionItem
        header={t('LABEL_TERMS_AND_CONDITIONS')}
        expand-icon-position="end"
      >
        <TermsAndConditionWrapper>
          <Text
            data-test-element-type={TestElementTypeId.TextTermsAndCondition}
          >
            {convertNewlineToJSX(t('TEXT_TERMS_AND_CONDITIONS'))}
          </Text>
          {buildSpecialMarketLegalLinkElements(marketLanguage?.market || null)}
          <Link>
            <a
              href={t('LINK_DISCLAIMER')}
              target="_blank"
              rel="noreferrer"
              data-test-element-type={TestElementTypeId.LinkDisclaimer}
            >
              {t('LINK_TEXT_DISCLAIMER')}
            </a>
          </Link>
          {TEST(t)}
        </TermsAndConditionWrapper>
      </TdsAccordionItem>
    </TdsAccordion>
  )
}

interface GuiInfo {
  version: string
  buildNumber: number
}

const TdsAccordionAbout = (): JSX.Element => {
  const t = useTexts()
  const vi = useAppSelector(getVersionInfoState)
  const ms = useAppSelector(getMarketSettingsState)
  const goEtelLastUpdated = useAppSelector(getGoEtelLastUpdatedState)
  const factoryModelsState = useAppSelector(getFactoryModelsState)
  const marketLanguage = useAppSelector(getMarketLanguageState)
  const [guiInfo, setGuiInfo] = useState<GuiInfo | null>(null)

  const lastUpdatedGo = goEtelLastUpdated?.toISOString()
  const lastUpdatedFM =
    factoryModelsState.factoryModels?.response.secmLastUpdated

  useEffect(() => {
    const asyncWrapper = async () => {
      try {
        const response = await fetch('version.json')
        const obj = await response.json()
        const branchAny = obj?.git?.branch
        const branch: string = typeof branchAny === 'string' ? branchAny : ''
        const regex = /^.*_(.*)/
        const match = regex.exec(branch)
        const version = match?.at(1) || ''
        const buildNumber = obj?.jenkins?.buildNumber || null
        const info: GuiInfo = { buildNumber, version }
        setGuiInfo(info)
      } catch {
        console.warn('Failed to get frontend version.')
        return
      }
    }
    asyncWrapper()
  }, [])

  const accordionContent = (
    <AccordionContentRoot
      data-test-element-type={TestElementTypeId.AboutContent}
    >
      <SmallHeader>{t('ABOUT_ETEL_LABEL')}</SmallHeader>
      <AboutWrapper>
        <AboutLeftContainer>
          <Text>Market name:</Text>
        </AboutLeftContainer>
        <AboutRightContainer>
          <Text>{ms?.marketFullName}</Text>
        </AboutRightContainer>
      </AboutWrapper>
      <AboutWrapper>
        <AboutLeftContainer>
          <Text>MarketId:</Text>
        </AboutLeftContainer>
        <AboutRightContainer>
          <Text>{marketLanguage?.market}</Text>
        </AboutRightContainer>
      </AboutWrapper>
      <AboutWrapper>
        <AboutLeftContainer>
          <Text>LanguageId:</Text>
        </AboutLeftContainer>
        <AboutRightContainer>
          <Text>{marketLanguage?.language}</Text>
        </AboutRightContainer>
      </AboutWrapper>
      <AboutWrapper>
        <AboutLeftContainer>
          <Text>{t('ABOUT_ETEL_GUIDED_OFFERING_VIEW_ID_LABEL')}:</Text>
        </AboutLeftContainer>
        <AboutRightContainer>
          <Text>{ms?.etelViewIdGuidedOffering}</Text>
        </AboutRightContainer>
      </AboutWrapper>
      <AboutWrapper>
        <AboutLeftContainer>
          <Text>{t('ABOUT_ETEL_BUILD_MODE_VIEW_ID_LABEL')}:</Text>
        </AboutLeftContainer>
        <AboutRightContainer>
          <Text>{ms?.etelViewIdBuildMode}</Text>
        </AboutRightContainer>
      </AboutWrapper>
      <AboutWrapper>
        <AboutLeftContainer>
          <Text>{t('ABOUT_SECM_LAST_UPDATED_LABEL') + ' (GO)'}:</Text>
        </AboutLeftContainer>
        <AboutRightContainer>
          <Text title={lastUpdatedGo}>{lastUpdatedGo?.split('T')[0]}</Text>
        </AboutRightContainer>
      </AboutWrapper>
      <AboutWrapper>
        <AboutLeftContainer>
          <Text>{t('ABOUT_SECM_LAST_UPDATED_LABEL') + ' (FM)'}:</Text>
        </AboutLeftContainer>
        <AboutRightContainer>
          <Text title={lastUpdatedFM || ''}>
            {lastUpdatedFM?.split('_')[0]}
          </Text>
        </AboutRightContainer>
      </AboutWrapper>
      <AboutWrapper>
        <AboutLeftContainer>
          <Text>{t('ABOUT_ETEL_PART_PERIOD_LABEL')}:</Text>
        </AboutLeftContainer>
        <AboutRightContainer>
          <Text>{vi?.partPeriod}</Text>
        </AboutRightContainer>
      </AboutWrapper>

      <SmallHeader>{t('ABOUT_CONTENT_VERSIONS_LABEL')}</SmallHeader>
      <AboutWrapper>
        <AboutLeftContainer>
          <Text>{t('ABOUT_CONTENT_IMAGES_LABEL')}:</Text>
        </AboutLeftContainer>
        <AboutRightContainer>
          <Text
            data-test-element-type={TestElementTypeId.AboutContentImagesVersion}
          >
            {vi?.buildInfo.contentVersions[0].version}
          </Text>
        </AboutRightContainer>
      </AboutWrapper>
      <AboutWrapper>
        <AboutLeftContainer>
          <Text>{t('ABOUT_CONTENT_RESOURCES_LABEL')}:</Text>
        </AboutLeftContainer>
        <AboutRightContainer>
          <Text
            data-test-element-type={
              TestElementTypeId.AboutContentResourcesVersion
            }
          >
            {vi?.buildInfo.contentVersions[1].version}
          </Text>
        </AboutRightContainer>
      </AboutWrapper>

      <SmallHeader>{t('ABOUT_BACKEND_LABEL')}</SmallHeader>
      <AboutWrapper>
        <AboutLeftContainer>
          <Text>{t('ABOUT_VERSION_NAME_LABEL')}:</Text>
        </AboutLeftContainer>
        <AboutRightContainer>
          <Text data-test-element-type={TestElementTypeId.AboutBackendVersion}>
            {vi?.buildInfo.applicationVersion?.version || 'dev. build'}
          </Text>
        </AboutRightContainer>
      </AboutWrapper>
      <AboutWrapper>
        <AboutLeftContainer>
          <Text>{t('ABOUT_BUILD_NUMBER_LABEL')}:</Text>
        </AboutLeftContainer>
        <AboutRightContainer>
          <Text>{vi?.buildInfo.buildNumber}</Text>
        </AboutRightContainer>
      </AboutWrapper>

      <SmallHeader>{t('ABOUT_FRONTEND_LABEL')}</SmallHeader>
      <AboutWrapper>
        <AboutLeftContainer>
          <Text>{t('ABOUT_VERSION_NAME_LABEL')}:</Text>
        </AboutLeftContainer>
        <AboutRightContainer>
          <Text>{guiInfo?.version || ''}</Text>
        </AboutRightContainer>
      </AboutWrapper>
      <AboutWrapper>
        <AboutLeftContainer>
          <Text>{t('ABOUT_BUILD_NUMBER_LABEL')}:</Text>
        </AboutLeftContainer>
        <AboutRightContainer>
          <Text>{guiInfo?.buildNumber || -1}</Text>
        </AboutRightContainer>
      </AboutWrapper>
    </AccordionContentRoot>
  )

  return (
    <TdsAccordion
      style={{ margin: '16px' }}
      data-test-element-type={TestElementTypeId.AccordionAbout}
    >
      <TdsAccordionItem header={t('LABEL_ABOUT')} expand-icon-position="end">
        {
          // An attempt at working around SDDS web components and slots not
          // working correctly with React.
          accordionContent
        }
      </TdsAccordionItem>
    </TdsAccordion>
  )
}

function TEST(t: TextGetter) {
  return (
    <Link>
      <a
        href={t('LINK_LEGAL')}
        target="_blank"
        rel="noreferrer"
        data-test-element-type={TestElementTypeId.LinkLegal}
      >
        {t('SCANIA_COPYRIGHT')}
      </a>
    </Link>
  )
}

export function SidePanelInfo(): JSX.Element {
  const dispatch = useAppDispatch()
  const refSidePanelInfo = useRef<HTMLDivElement | null>(null)
  const t = useTexts()
  const marketLanguage = useAppSelector(getMarketLanguageState)
  const sidePanelInfoIsOpen = useAppSelector(selectSidePanelState).infoOpen

  const handleCloseClick = useCallback(() => {
    dispatch(closePanelInfo())
  }, [dispatch])

  const handleUserClickOnScreen = useCallback(
    (e: MouseEvent) => {
      if (!sidePanelInfoIsOpen) {
        return
      }
      const currentElement = refSidePanelInfo.current
      const composedPath = e.composedPath()
      const clickIsOutsideSidePanel =
        -1 === composedPath.findIndex((o) => o === currentElement)
      if (clickIsOutsideSidePanel) {
        handleCloseClick()
      }
    },
    [refSidePanelInfo, handleCloseClick, sidePanelInfoIsOpen],
  )

  // Close sidePanel on click outside sidepanel
  useEffect(() => {
    document.addEventListener('click', handleUserClickOnScreen)
    return () => {
      document.removeEventListener('click', handleUserClickOnScreen)
    }
  }, [handleUserClickOnScreen])

  const handleXmlUploadClick = () => {
    if (!marketLanguage) {
      console.error('Expected marketLanguage to be defined.')
      return
    }
    dispatch(openModalUploadXml())
  }

  return (
    <InfoRoot
      className="tds-on-white-bg"
      ref={refSidePanelInfo}
      $sidePanelIsOpen={sidePanelInfoIsOpen}
    >
      <CloseIconWrapper onClick={handleCloseClick}>
        <SvgCross />
      </CloseIconWrapper>
      <TdsAccordionTermsAndConditions />
      <TdsAccordionAbout />
      <TdsAccordion
        style={{ margin: '16px' }}
        data-test-element-type={TestElementTypeId.AccordionUploadConfig}
      >
        <TdsAccordionItem
          header={t('ACTION_UPLOAD_CONFIG')}
          expand-icon-position="end"
        >
          <TermsAndConditionWrapper>
            <Text
              data-test-element-type={TestElementTypeId.DescriptionXmlUpload}
            >
              {t('DESCRIPTION_XML_UPLOAD')}
            </Text>
            <TdsButton
              type="button"
              variant="primary"
              size="md"
              text={t('ACTION_UPLOAD')}
              onClick={handleXmlUploadClick}
              data-test-element-type={TestElementTypeId.UploadConfigButton}
              style={{ marginTop: '12px' }}
              fullbleed={true}
            />
          </TermsAndConditionWrapper>
        </TdsAccordionItem>
      </TdsAccordion>
      <div></div>
    </InfoRoot>
  )
}
