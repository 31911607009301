/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PublicConfigInfo } from './PublicConfigInfo'
import {
  PublicConfigInfoFromJSON,
  PublicConfigInfoFromJSONTyped,
  PublicConfigInfoToJSON,
} from './PublicConfigInfo'

/**
 *
 * @export
 * @interface PublicConfigV2SaveResponseWrapper
 */
export interface PublicConfigV2SaveResponseWrapper {
  /**
   *
   * @type {PublicConfigInfo}
   * @memberof PublicConfigV2SaveResponseWrapper
   */
  publicConfigV2Save?: PublicConfigInfo | null
  /**
   *
   * @type {string}
   * @memberof PublicConfigV2SaveResponseWrapper
   */
  error?: string | null
}

/**
 * Check if a given object implements the PublicConfigV2SaveResponseWrapper interface.
 */
export function instanceOfPublicConfigV2SaveResponseWrapper(
  value: object,
): boolean {
  let isInstance = true

  return isInstance
}

export function PublicConfigV2SaveResponseWrapperFromJSON(
  json: any,
): PublicConfigV2SaveResponseWrapper {
  return PublicConfigV2SaveResponseWrapperFromJSONTyped(json, false)
}

export function PublicConfigV2SaveResponseWrapperFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PublicConfigV2SaveResponseWrapper {
  if (json === undefined || json === null) {
    return json
  }
  return {
    publicConfigV2Save: !exists(json, 'public_config_v2_save')
      ? undefined
      : PublicConfigInfoFromJSON(json['public_config_v2_save']),
    error: !exists(json, 'error') ? undefined : json['error'],
  }
}

export function PublicConfigV2SaveResponseWrapperToJSON(
  value?: PublicConfigV2SaveResponseWrapper | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    public_config_v2_save: PublicConfigInfoToJSON(value.publicConfigV2Save),
    error: value.error,
  }
}
