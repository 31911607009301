/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface PrepareRfqResponse
 */
export interface PrepareRfqResponse {
  /**
   *
   * @type {boolean}
   * @memberof PrepareRfqResponse
   */
  extendedCheckSuccess?: boolean
}

/**
 * Check if a given object implements the PrepareRfqResponse interface.
 */
export function instanceOfPrepareRfqResponse(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function PrepareRfqResponseFromJSON(json: any): PrepareRfqResponse {
  return PrepareRfqResponseFromJSONTyped(json, false)
}

export function PrepareRfqResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PrepareRfqResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    extendedCheckSuccess: !exists(json, 'extended_check_success')
      ? undefined
      : json['extended_check_success'],
  }
}

export function PrepareRfqResponseToJSON(
  value?: PrepareRfqResponse | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    extended_check_success: value.extendedCheckSuccess,
  }
}
