import styled from 'styled-components'
import { SummaryItem } from '../../../api/generated'
import { useActiveChoicesHighlight } from './ActiveChoicesHighlight'
import { ActiveChangesIcon } from './ActiveChoicesIcon'
import useTexts from '../../../utils/useTexts'
import { BreakpointWidthPx } from '../../../css/BreakpointWidthPx'
import { useAppDispatch } from '../../../store/hooks'
import { openMainMenu } from '../../../store/menuSlice'
import { TdsButton } from '@scania/tegel-react'

const ConfigureButtonRoot = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  background-color: var(--tds-grey-100);
  height: 56px;
  justify-content: space-between;
  align-items: center;

  button {
    // Override tds-button to match the design image.
    font-size: 12px;
    font-weight: bold;
  }

  @media screen and (min-width: ${BreakpointWidthPx.Phone}px) {
    display: none;
  }
`

const Hitbox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  height: 100%;
  cursor: pointer;
`

export interface MobileConfigureButtonProps {
  activeChoiceCount: number
  handleActiveChoicesClick: () => void
  activeChoices: SummaryItem[]
}

export const MobileConfigureButton = ({
  activeChoiceCount,
  handleActiveChoicesClick,
  activeChoices,
}: MobileConfigureButtonProps): JSX.Element => {
  const highlightActiveChoices = useActiveChoicesHighlight(activeChoices)
  const t = useTexts()
  const dispatch = useAppDispatch()

  return (
    <ConfigureButtonRoot>
      <TdsButton
        variant="secondary"
        size="sm"
        fullbleed
        text={t('OPEN_MAIN_MENU_BUTTON')}
        onClick={() => dispatch(openMainMenu())}
      ></TdsButton>
      <Hitbox onClick={handleActiveChoicesClick}>
        <ActiveChangesIcon
          choiceCount={activeChoiceCount}
          isHighlighted={highlightActiveChoices}
        />
      </Hitbox>
    </ConfigureButtonRoot>
  )
}
