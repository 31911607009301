// SC1M-2675:
//
// - Without Super & Without BEV: normal view (old back ground image) should be
//   presented. (example Turkey in prod today, they have not activated SUPER yet.)
//
// - With Super and without BEV: Use Super picture (or film) and __use the Super
//   footer__.
//
// - With BEV: use Super picture AND Bev picture (or films), __with the normal
//   footer, not the Super footer__

import styled, { css } from 'styled-components'
import { PortraitFooter } from '../../components/Footer/PortraitFooter'
import { DesktopFooter } from '../../components/Footer/DesktopFooter'
import { useCallback, useEffect, useRef, useState } from 'react'
import useTexts from '../../utils/useTexts'
import bgNoCampaignJpg from '../../assets/img/no-campaign-bg.jpg'
import { factoryModelsEnabled, guidedOfferingEnabled } from '../../api/goFmMode'
import {
  ADOBE_TRACKING_GO_FLOW_OPTION_SELECTED_START_BUTTON,
  ScaniaAdobeTrackingFlowName,
  ScaniaAdobeTrackingPageName,
} from '../../utils/adobeAnalytics'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import {
  CampaignLinkMode,
  IntroPageControlArea,
  IntroPageControlAreaParams,
} from './IntroPageControlArea'
import { BreakpointWidthPx } from '../../css/BreakpointWidthPx'
import { TestElementTypeId } from '../../types/TestAttributeId'
import { GuiMarketSettings } from '../../api/generated'
import { SuperCampaignData } from '../../components/Footer/SuperCampaignData'
import {
  UrlParamMode,
  getUrlCidBevSemiliveParameters,
} from '../../utils/getUrlCidParameter'
import { getUrlParametersToString } from '../../utils/getUrlParametersToString'
import {
  getMarketLanguageState,
  getMarketSettingsState,
} from '../../store/appSlice'
import {
  getStartupData,
  hideInitialLoadingScreen,
  pushPageViewTrackingEvent,
  setPendingUserTrackingPageNavigation,
} from '../../store/sessionDataSlice'

const bevVideoPosterUrl = `${process.env.PUBLIC_URL}/images/bev_campaign_video_poster.jpg`
const bevVideoUrl =
  'https://dreambroker.com/channel/qy35q5xo/xxrlfxin/get/hd.mp4'
const superVideoPosterUrl = `${process.env.PUBLIC_URL}/images/super_campaign_video_poster.jpg`
const superVideoUrl =
  'https://dreambroker.com/channel/qy35q5xo/q8nhl7m2/get/hd.mp4'

const LandingHomeRoot = styled.div`
  --portrait-title-height: min(128px, 20vh);
  display: flex;
  height: calc(var(--app-height) - var(--header-height));
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  width: 100%;
  background-color: var(--tds-blue-800);
`

const LandingHomeLayoutSmallScreen = css<LandingHomeLayoutProps>`
  grid-template-columns: none;
  grid-template-rows: repeat(var(--element-count), minmax(0, 1fr));
  height: unset;
  max-height: unset;
  min-height: calc(
    (
      var(--element-count) *
        (
          var(--app-height) - var(--header-height) -
            var(--portrait-title-height)
        )
    )
  );
`

interface LandingHomeLayoutProps {
  readonly $splitInTwo: boolean
  readonly $smallScreenBreakpoint: number
}

const LandingHomeLayout = styled.div<LandingHomeLayoutProps>`
  position: relative;
  --element-count: ${({ $splitInTwo }) => ($splitInTwo ? 2 : 1)};
  display: grid;
  grid-template-columns: repeat(var(--element-count), minmax(0, 1fr));
  grid-template-rows: none;
  height: 100%;
  max-height: 100%;
  width: 100%;

  @media screen and (max-width: ${(props) => props.$smallScreenBreakpoint}px) {
    ${LandingHomeLayoutSmallScreen}
  }
`

const LandingHomeScrollContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const IntropageArea = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  position: relative;
  width: 100%;
`

const MainTitleTextSmallScreen = css`
  align-items: center;
  background-color: var(--tds-white);
  color: var(--tds-grey-958);
  display: flex;
  justify-content: center;
  max-height: var(--portrait-title-height);
  min-height: var(--portrait-title-height);
  padding: unset;
  position: relative;

  & span {
    // TODO: Scale to available area?
    font-size: min(3rem, 8vw, 10vh);
  }
`

interface MainTitleTextProps {
  readonly $smallScreenBreakpoint: number
  readonly $useDropShadow: boolean
}

const MainTitleText = styled.div<MainTitleTextProps>`
  user-select: none;
  z-index: 1;
  padding-top: 32px;
  position: absolute;
  color: var(--tds-white);
  width: 100%;
  text-align: center;

  & span {
    // TODO: Revisit next weekly:
    ${(props) =>
      props.$useDropShadow &&
      css`
        filter: drop-shadow(2px 2px 32px #00001655);
      `}
  }

  @media screen and (max-width: ${(props) => props.$smallScreenBreakpoint}px) {
    ${MainTitleTextSmallScreen}
  }
`

const BackgroundImage = styled.div`
  background-size: cover;
  height: 100%;
  left: 0;
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  top: 0;
  width: 100%;
`

const BackgroundVideo = styled.div`
  height: 100%;
  left: 0;
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;

  & > video {
    object-fit: cover;
  }
`

interface BgImageInfo {
  readonly url: string
  readonly backgroundPositionX: string | number
  readonly backgroundPositionY: string | number
}

/**
 * URLs and mime type for one `<video>` element.
 */
interface VideoInfo {
  readonly poster: string
  readonly source: string
  readonly type: string
}

interface VideoInfoVariant {
  readonly kind: 'video'
  readonly videoInfo: VideoInfo
}

interface BgImageInfoVariant {
  readonly kind: 'image'
  readonly imageInfo: BgImageInfo
}

type BackgroundInfo = BgImageInfoVariant | VideoInfoVariant

interface IntroPageState {
  readonly hasTwoSections: boolean
  readonly sectionOne: BackgroundInfo
  readonly sectionTwo: BackgroundInfo | null

  /**
   * The super footer should be displayed when ONLY the Super campaign is
   * active, never when both Super and BEV campaigns are active at the same
   * time according to SC1M-2675.
   */
  readonly superCampaignData?: SuperCampaignData

  readonly smallScreenBreakpoint: number
}

function loadImage(url: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.addEventListener('load', () => resolve(img))
    img.addEventListener('error', (err) => reject(err))
    img.src = url
  })
}

function isInside(x: number, y: number, r: DOMRect): boolean {
  return x >= r.left && x <= r.right && y >= r.top && y <= r.bottom
}

// TODO: Revisit and wait for the play() promise!
// We probably need to store the play promise as a Ref...
async function playAStopBOnMouseOver(
  ev: React.MouseEvent,
  a: HTMLVideoElement | null,
  b: HTMLVideoElement | null,
): Promise<boolean> {
  const x = ev.clientX
  const y = ev.clientY
  if (a) {
    const overlaps = isInside(x, y, a.getBoundingClientRect())
    if (overlaps) {
      if (a.paused) {
        try {
          await a.play()
        } catch (err) {
          // This can happen if the user navigates away from the page before the
          // play promise has resolved which will cause problems with automated
          // E2E tests.
          console.error(err)
        }
      }
      if (b && !b.paused) {
        b.pause()
      }
      return true
    }
  }
  return false
}

interface TestModeMarketSettingsOverrides {
  bevCampaign: boolean | null
  superCampaign: boolean | null
  useCampaignVideo: boolean | null
}

interface IntroPageSectionParams {
  bgInfo: BackgroundInfo
  buttonParams: IntroPageControlAreaParams
  videoRef: React.MutableRefObject<HTMLVideoElement | null>
  videoShouldAutoPlay: boolean
}

function IntroPageSection({
  bgInfo,
  buttonParams,
  videoRef,
  videoShouldAutoPlay,
}: IntroPageSectionParams): JSX.Element {
  return (
    <IntropageArea>
      {bgInfo.kind === 'image' && (
        <BackgroundImage
          style={{
            backgroundImage: 'url(' + bgInfo.imageInfo.url + ')',
            backgroundPositionX: bgInfo.imageInfo.backgroundPositionX,
            backgroundPositionY: bgInfo.imageInfo.backgroundPositionY,
          }}
        />
      )}
      {bgInfo.kind === 'video' && (
        <BackgroundVideo>
          <video
            disableRemotePlayback
            ref={videoRef}
            width="100%"
            height="100%"
            muted={true}
            loop={true}
            autoPlay={videoShouldAutoPlay}
            preload={'auto'}
            playsInline={true}
            poster={bgInfo.videoInfo.poster}
          >
            <source
              src={bgInfo.videoInfo.source}
              type={bgInfo.videoInfo.type}
            />
            Your browser does not support HTML5 video.
          </video>
        </BackgroundVideo>
      )}
      <IntroPageControlArea {...buttonParams} />
    </IntropageArea>
  )
}

export function LandingHomeNew(): JSX.Element {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [pageState, setPageState] = useState<IntroPageState | null>(null)
  const rootRef = useRef<HTMLDivElement | null>(null)
  const video1Ref = useRef<HTMLVideoElement | null>(null)
  const video2Ref = useRef<HTMLVideoElement | null>(null)
  const t = useTexts()
  const marketLanguage = useAppSelector(getMarketLanguageState)
  const startupData = useAppSelector(getStartupData)

  // TEST MODE - BEGIN
  const upstreamMarketSettings = useAppSelector(getMarketSettingsState)
  const [marketSettings, setMarketSettings] =
    useState<GuiMarketSettings | null>(null)

  // TODO: Remove this before shipping 9.3.1.
  const [testModeOverrides, setTestModeOverrides] =
    useState<TestModeMarketSettingsOverrides>({
      bevCampaign: null,
      superCampaign: null,
      useCampaignVideo: null,
    })

  const mainTitleShadowEnabled = true

  useEffect(() => {
    if (!startupData) {
      return
    }
    if (!marketLanguage) {
      return
    }
    dispatch(
      pushPageViewTrackingEvent({
        pageName: ScaniaAdobeTrackingPageName.Home,
        marketLanguage,
      }),
    )
  }, [dispatch, marketLanguage, startupData])

  useEffect(() => {
    if (!upstreamMarketSettings) {
      return
    }
    const modified: GuiMarketSettings = { ...upstreamMarketSettings }
    if (testModeOverrides.bevCampaign !== null) {
      modified.bevCampaignEnabled = testModeOverrides.bevCampaign
    }
    if (testModeOverrides.superCampaign !== null) {
      modified.superCampaignEnabled = testModeOverrides.superCampaign
    }
    if (testModeOverrides.useCampaignVideo !== null) {
      modified.useCampaignVideo = testModeOverrides.useCampaignVideo
    }
    if (
      modified.useCampaignVideo &&
      !modified.bevCampaignEnabled &&
      !modified.superCampaignEnabled
    ) {
      console.warn(
        'Background video requires at least one campaign to be active, falling back to background images.',
      )
      modified.useCampaignVideo = false
    }
    setMarketSettings(modified)
  }, [upstreamMarketSettings, testModeOverrides])

  // TODO: Remove this before shipping 9.3.1.
  useEffect(() => {
    if (!marketSettings) {
      return
    }
    const handleKeys = (ev: KeyboardEvent) => {
      if (!ev.altKey) {
        return
      }
      switch (ev.key) {
        case '1':
          setTestModeOverrides((prev) => {
            return { ...prev, bevCampaign: !marketSettings.bevCampaignEnabled }
          })
          break
        case '2':
          setTestModeOverrides((prev) => {
            return {
              ...prev,
              superCampaign: !marketSettings.superCampaignEnabled,
            }
          })
          break
        case '3':
          setTestModeOverrides((prev) => {
            return {
              ...prev,
              useCampaignVideo: !marketSettings.useCampaignVideo,
            }
          })
          break
        default:
          break
      }
    }
    document.addEventListener('keydown', handleKeys)
    return () => {
      document.removeEventListener('keydown', handleKeys)
    }
  }, [marketSettings])
  // TEST MODE - END

  // Preload all that's needed before the page can be displayed.
  // Resolve background image URLs and preload them.
  // TODO: Update the final video URLs.
  useEffect(() => {
    if (!marketSettings) {
      return // Not initialized yet.
    }
    if (!marketLanguage) {
      return // Not initialized yet.
    }
    if (!guidedOfferingEnabled(marketSettings)) {
      if (!factoryModelsEnabled) {
        throw new Error('Expected factory models mode to be enabled.')
      }
      const urlCidParams = getUrlCidBevSemiliveParameters(
        UrlParamMode.ExcludeBev,
      )

      const isSemiliveGo =
        urlCidParams.has('semilive') && marketSettings.prepareGo
      if (!isSemiliveGo) {
        const url = `/factory-models/${marketLanguage.market}/${marketLanguage.language}/`
        const link = url + getUrlParametersToString(urlCidParams)
        navigate(link, { replace: true })
        return
      }
    }
    //const normalVideoUrls: VideoInfo = {
    //  poster: tmepVideoPosterUrl,
    //  source: tempVideoSourceUrl,
    //  type: 'video/mp4',
    //}
    const superVideoUrls: VideoInfo = {
      poster: superVideoPosterUrl,
      source: superVideoUrl,
      type: 'video/mp4',
    }
    const bevVideoUrls: VideoInfo = {
      poster: bevVideoPosterUrl,
      source: bevVideoUrl,
      type: 'video/mp4',
    }
    let defaultSectionOne: BgImageInfoVariant = {
      kind: 'image',
      imageInfo: {
        backgroundPositionX: 'center',
        backgroundPositionY: 'center',
        url: bgNoCampaignJpg,
      },
    }
    let sectionOne: BackgroundInfo = defaultSectionOne
    let sectionTwo: BackgroundInfo | null = null
    const SUPER_CAMPAIGN_LINK_TEXT = 'CAMPAIGN_LINK_TEXT'
    const SUPER_CAMPAIGN_LINK_URL = 'CAMPAIGN_LINK_URL'
    const SUPER_CAMPAIGN_SHORT_TEXT = 'CAMPAIGN_SHORT_TEXT'
    const superLinkText = t(SUPER_CAMPAIGN_LINK_TEXT)
    const superLinkUrl = t(SUPER_CAMPAIGN_LINK_URL)
    const superShortText = t(SUPER_CAMPAIGN_SHORT_TEXT)
    const isSuperCampaignProperlyEnabled = (): boolean => {
      if (!marketSettings.superCampaignEnabled) {
        return false
      }
      if (!superLinkText) {
        console.warn(
          `Super campaign mode is inactive, ${SUPER_CAMPAIGN_LINK_TEXT} missing.`,
        )
        return false
      }
      if (!superLinkUrl || superLinkUrl.length < 'http://'.length) {
        console.warn(
          `Super campaign mode is inactive, ${SUPER_CAMPAIGN_LINK_URL} is not valid: ${superLinkUrl}`,
        )
        return false
      }
      if (!superShortText) {
        console.warn(
          `Super campaign mode is inactive, ${SUPER_CAMPAIGN_SHORT_TEXT} missing.`,
        )
        return false
      }
      console.log('Super campaign mode is active.')
      return true
    }

    // If the BEV campaign is enabled (in the second section), then the Super
    // background must be used in the first section but the Super campaign is
    // still not considered active... according to feedback for SC1M-2675.
    const useSectionOneSuperBackground =
      marketSettings.superCampaignEnabled || marketSettings.bevCampaignEnabled

    // Only show the super footer if the super campaign is enabled, the super
    // background can be visible without the Super campaign being active, see
    // SC1M-2675.
    const superCampaignProperlyEnabled = isSuperCampaignProperlyEnabled()
    const superCampaignData: SuperCampaignData | undefined =
      superCampaignProperlyEnabled && !marketSettings.bevCampaignEnabled
        ? {
            linkText: superLinkText,
            linkUrl: superLinkUrl,
            shortText: superShortText,
          }
        : undefined

    // Both sections must have Super + BEV backgrounds to allow video
    // backgrounds.
    const useCampaignVideo =
      marketSettings.useCampaignVideo &&
      (marketSettings.bevCampaignEnabled || useSectionOneSuperBackground)

    if (useCampaignVideo) {
      if (marketSettings.bevCampaignEnabled) {
        const inf: VideoInfoVariant = {
          kind: 'video',
          videoInfo: bevVideoUrls,
        }
        sectionTwo = inf
      }
      if (useSectionOneSuperBackground) {
        const inf: VideoInfoVariant = {
          kind: 'video',
          videoInfo: superVideoUrls,
        }
        sectionOne = inf
      }
    } else {
      if (marketSettings.bevCampaignEnabled) {
        const inf: BgImageInfoVariant = {
          kind: 'image',
          imageInfo: {
            backgroundPositionX: 'center',
            backgroundPositionY: 'center',
            url: `${process.env.PUBLIC_URL}/images/bev_campaign_edited.jpg`,
          },
        }
        sectionTwo = inf
      }
      if (useSectionOneSuperBackground) {
        const inf: BgImageInfoVariant = {
          kind: 'image',
          imageInfo: {
            backgroundPositionX: 'center',
            backgroundPositionY: 'center',
            url: `${process.env.PUBLIC_URL}/images/super_campaign_edited.jpg`,
          },
        }
        sectionOne = inf
      }
    }
    const preloadImgUrls = [sectionOne, sectionTwo].flatMap((bgInfo) => {
      switch (bgInfo?.kind) {
        case 'image':
          return [bgInfo.imageInfo.url]
        case 'video':
          return [bgInfo.videoInfo.poster]
        case null:
        case undefined:
          return []
        default:
          throw new Error('Unhandled union tag: ' + (bgInfo as any)?.kind)
      }
    })
    const hasTwoSections: boolean = sectionOne && sectionTwo ? true : false

    // TODO: Consider LargeDesktop as the split screen breakpoint.
    const smallScreenBreakpoint = hasTwoSections
      ? BreakpointWidthPx.Desktop
      : BreakpointWidthPx.Tablet

    const preloadImgPromises = preloadImgUrls.map(loadImage)
    const asyncWrapper = async () => {
      await Promise.all(preloadImgPromises)
      setPageState({
        hasTwoSections,
        sectionOne,
        sectionTwo,
        superCampaignData,
        smallScreenBreakpoint,
      })
      dispatch(hideInitialLoadingScreen())
    }
    asyncWrapper()
  }, [dispatch, marketLanguage, marketSettings, navigate, t])

  const handleGuidedOfferingWithParamClick = useCallback(
    ({ useBevFilters }: { useBevFilters: boolean }) => {
      if (!marketSettings) {
        return
      }
      if (!marketLanguage) {
        return
      }
      if (!guidedOfferingEnabled(marketSettings)) {
        throw new Error('Expected Guided Offering to be enabled.')
      }
      dispatch(
        setPendingUserTrackingPageNavigation({
          factoryModelSelected: null,
          flowName: ScaniaAdobeTrackingFlowName.GuidedOffering,
          optionSelected: ADOBE_TRACKING_GO_FLOW_OPTION_SELECTED_START_BUTTON,
          stepName: ADOBE_TRACKING_GO_FLOW_OPTION_SELECTED_START_BUTTON,
          stepSkipped: null,
        }),
      )
      const urlCidParams = getUrlCidBevSemiliveParameters(
        useBevFilters ? UrlParamMode.IncludeBev : UrlParamMode.ExcludeBev,
      )
      const url = `/go/go-start/${marketLanguage.market}/${marketLanguage.language}/`
      const link = url + getUrlParametersToString(urlCidParams)
      try {
        navigate(link)
      } catch (e) {
        console.log('error', e)
      }
    },
    [dispatch, marketLanguage, marketSettings, navigate],
  )

  const handleGuidedOfferingClick = useCallback(() => {
    handleGuidedOfferingWithParamClick({ useBevFilters: false })
  }, [handleGuidedOfferingWithParamClick])

  const handleGuidedOfferingBevCampaignClick = useCallback(() => {
    handleGuidedOfferingWithParamClick({ useBevFilters: true })
  }, [handleGuidedOfferingWithParamClick])

  const handleFactoryModelsClick = useCallback(
    ({ bevMode }: { bevMode: boolean }) => {
      if (!marketSettings) {
        return
      }
      if (!marketLanguage) {
        return
      }
      if (!factoryModelsEnabled(marketSettings)) {
        throw new Error('Expected Factory Models to be enabled.')
      }
      dispatch(
        setPendingUserTrackingPageNavigation({
          factoryModelSelected: null,
          flowName: ScaniaAdobeTrackingFlowName.FactoryModels,
          optionSelected: ADOBE_TRACKING_GO_FLOW_OPTION_SELECTED_START_BUTTON,
          stepName: ADOBE_TRACKING_GO_FLOW_OPTION_SELECTED_START_BUTTON,
          stepSkipped: null,
        }),
      )
      const urlCidParams = getUrlCidBevSemiliveParameters(
        bevMode ? UrlParamMode.IncludeBev : UrlParamMode.ExcludeBev,
      )
      const url = `/factory-models/${marketLanguage.market}/${marketLanguage.language}/`
      const link = url + getUrlParametersToString(urlCidParams)
      navigate(link)
    },
    [dispatch, marketLanguage, marketSettings, navigate],
  )

  const handleFactoryModelsNormalModeClick = useCallback(() => {
    handleFactoryModelsClick({ bevMode: false })
  }, [handleFactoryModelsClick])

  const handleFactoryModelsBevModeClick = useCallback(() => {
    handleFactoryModelsClick({ bevMode: true })
  }, [handleFactoryModelsClick])

  const handleMouseMove = useCallback(
    async (ev: React.MouseEvent) => {
      if (!pageState) {
        return
      }
      const documentRoot = document.body.parentElement
      if (!documentRoot) {
        return
      }
      if (!rootRef.current) {
        return
      }
      const isSmallScreen =
        documentRoot.clientWidth <= pageState.smallScreenBreakpoint
      const canScroll =
        rootRef.current.scrollHeight > rootRef.current.clientHeight
      if (isSmallScreen && canScroll) {
        // Don't play and pause on mouse over for vertical layout with
        // scrollable content. The scroll handler is responsible for play and
        // pause in this case.
        return
      }
      const v1 = video1Ref.current
      const v2 = video2Ref.current
      ;(await playAStopBOnMouseOver(ev, v1, v2)) ||
        (await playAStopBOnMouseOver(ev, v2, v1))
    },
    [pageState, rootRef],
  )

  const campaignLinkEnabled =
    (startupData?.settings.bevCampaignLinkUrl ? true : false) &&
    pageState?.sectionTwo
  const campaignLinkText = t('BEV_CAMPAIGN_LINK_TEXT')
  const campaignLinkUrl =
    startupData?.settings.bevCampaignLinkUrl || 'https://www.scania.com'

  const buttonParamsOne: IntroPageControlAreaParams = {
    subHeaderText: t('HEADER_LANDPAGE_BUILD_YOUR_TRUCK'),
    paragraphText: t('LANDING_PAGE_TEXT_COMPLETE'),
    buttonOne: {
      handleClick: handleGuidedOfferingClick,
      testId: TestElementTypeId.LandingPageStartGuidedOfferingButton,
      text: t('STARTUP_GET_STARTED'),
    },
    buttonTwo:
      marketSettings && factoryModelsEnabled(marketSettings)
        ? {
            handleClick: handleFactoryModelsNormalModeClick,
            testId: TestElementTypeId.LandingPageStartFactoryModelsButton,
            text: t('FM_MARKETED_RANGE'),
          }
        : null,
    campaignLinkMode: campaignLinkEnabled
      ? CampaignLinkMode.Hidden
      : CampaignLinkMode.Disabled,
    campaignLinkText,
    campaignLinkUrl,
    smallScreenBreakpoint:
      pageState?.smallScreenBreakpoint || BreakpointWidthPx.Tablet,
  }

  const buttonParamsTwo: IntroPageControlAreaParams = {
    subHeaderText: t('LANDING_PAGE_SUBHEADER_BEV'),
    paragraphText: t('LANDING_PAGE_TEXT_BEV'),
    buttonOne: {
      handleClick: handleGuidedOfferingBevCampaignClick,
      testId: TestElementTypeId.LandingPageStartGuidedOfferingBevButton,
      text: t('STARTUP_GET_STARTED'),
    },
    buttonTwo:
      marketSettings && factoryModelsEnabled(marketSettings)
        ? {
            handleClick: handleFactoryModelsBevModeClick,
            testId: TestElementTypeId.LandingPageStartFactoryModelsButton,
            text: t('FM_MARKETED_RANGE'),
          }
        : null,
    campaignLinkMode: campaignLinkEnabled
      ? CampaignLinkMode.Enabled
      : CampaignLinkMode.Disabled,
    campaignLinkText,
    campaignLinkUrl,
    smallScreenBreakpoint:
      pageState?.smallScreenBreakpoint || BreakpointWidthPx.Tablet,
  }

  const handleScroll = useCallback(async () => {
    const root = rootRef.current
    if (!root) {
      return
    }
    if (!pageState?.hasTwoSections) {
      return
    }
    const v1 = video1Ref.current
    const v2 = video2Ref.current
    if (!v1 || !v2) {
      return
    }
    const rootElement = document.body.parentElement
    if (!rootElement) {
      return
    }
    if (rootElement.clientWidth > pageState.smallScreenBreakpoint) {
      return
    }
    const rr = root.getBoundingClientRect()
    const r1 = v1.getBoundingClientRect()
    const r2 = v2.getBoundingClientRect()
    const v1Mid = r1.top + r1.height * 0.5
    const v2Mid = r2.top + r2.height * 0.5
    const rMid = rr.top + rr.height * 0.5
    const [shouldPlay, shouldPause] =
      Math.abs(rMid - v1Mid) < Math.abs(rMid - v2Mid) ? [v1, v2] : [v2, v1]
    if (shouldPlay.paused) {
      try {
        await shouldPlay.play()
      } catch (err) {
        // This can happen if the user navigates away from the page before the
        // play promise has resolved which will cause problems with automated
        // E2E tests.
        console.error(err)
      }
    }
    if (!shouldPause.paused) {
      shouldPause.pause()
    }
  }, [rootRef, pageState, video1Ref, video2Ref])

  const smallScreenBreakpoint =
    pageState?.smallScreenBreakpoint || BreakpointWidthPx.Tablet

  return (
    <LandingHomeRoot
      ref={rootRef}
      onScroll={handleScroll}
      onMouseMove={handleMouseMove}
    >
      <LandingHomeScrollContent>
        <MainTitleText
          $smallScreenBreakpoint={smallScreenBreakpoint}
          $useDropShadow={mainTitleShadowEnabled}
        >
          <span className="tds-expressive-headline-01">
            {t('WINDOW_TITLE')}
          </span>
        </MainTitleText>
        <LandingHomeLayout
          $splitInTwo={pageState?.hasTwoSections || false}
          $smallScreenBreakpoint={smallScreenBreakpoint}
        >
          {pageState?.sectionOne && (
            <IntroPageSection
              bgInfo={pageState.sectionOne}
              buttonParams={buttonParamsOne}
              videoRef={video1Ref}
              videoShouldAutoPlay={pageState.hasTwoSections}
            />
          )}
          {pageState?.sectionTwo && (
            <IntroPageSection
              bgInfo={pageState.sectionTwo}
              buttonParams={buttonParamsTwo}
              videoRef={video2Ref}
              videoShouldAutoPlay={false}
            />
          )}
        </LandingHomeLayout>
        <PortraitFooter
          displayAtScreenMaxWidth={smallScreenBreakpoint}
          superCampaignData={pageState?.superCampaignData}
        />
        <DesktopFooter
          hideAtScreenMaxWidth={smallScreenBreakpoint}
          superCampaignData={pageState?.superCampaignData}
        />
      </LandingHomeScrollContent>
    </LandingHomeRoot>
  )
}
