/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface DealerPhoneEntry
 */
export interface DealerPhoneEntry {
  /**
   *
   * @type {string}
   * @memberof DealerPhoneEntry
   */
  type?: string | null
  /**
   *
   * @type {string}
   * @memberof DealerPhoneEntry
   */
  number?: string | null
  /**
   *
   * @type {string}
   * @memberof DealerPhoneEntry
   */
  comment?: string | null
}

/**
 * Check if a given object implements the DealerPhoneEntry interface.
 */
export function instanceOfDealerPhoneEntry(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function DealerPhoneEntryFromJSON(json: any): DealerPhoneEntry {
  return DealerPhoneEntryFromJSONTyped(json, false)
}

export function DealerPhoneEntryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): DealerPhoneEntry {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: !exists(json, 'Type') ? undefined : json['Type'],
    number: !exists(json, 'Number') ? undefined : json['Number'],
    comment: !exists(json, 'Comment') ? undefined : json['Comment'],
  }
}

export function DealerPhoneEntryToJSON(value?: DealerPhoneEntry | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    Type: value.type,
    Number: value.number,
    Comment: value.comment,
  }
}
