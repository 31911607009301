import { useCallback, useEffect, useState } from 'react'
import {
  ScaniaAdobeTrackingDownloadPdfEvent,
  pushAdobeEvent,
  ScaniaAdobeEventId,
  ScaniaAdobeDownloadType,
} from '../../utils/adobeAnalytics'
import { buildPdfUrl } from '../../utils/UrlBuilders'
import useTexts from '../../utils/useTexts'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { TestElementTypeId } from '../../types/TestAttributeId'
import {
  getSessionInitDataState,
  getStartupData,
} from '../../store/sessionDataSlice'
import { getMarketLanguageState } from '../../store/appSlice'
import { closeAllModals, getModalState } from '../../store/modalSlice'
import { TdsButton, TdsModal } from '@scania/tegel-react'

export function ModalSavePdf(): JSX.Element {
  const t = useTexts()
  const dispatch = useAppDispatch()
  const startupData = useAppSelector(getStartupData)
  const marketLanguage = useAppSelector(getMarketLanguageState)
  const sessionInitData = useAppSelector(getSessionInitDataState)
  const source = useAppSelector(getModalState).modalSavePdfState?.source
  const [pdfLink, setPdfLink] = useState<string | null>(null)

  // Build PDF-url
  useEffect(() => {
    if (!sessionInitData || !marketLanguage || !startupData) {
      return
    }
    const marketDenomination = null
    const newLink = buildPdfUrl(
      startupData.backendUrl,
      sessionInitData.sessionId,
      marketLanguage.language,
      marketDenomination,
    )
    setPdfLink(newLink)
  }, [marketLanguage, sessionInitData, startupData])

  const handlePdfDownloadButtonClick = useCallback(() => {
    if (!pdfLink) {
      return
    }
    if (!source) {
      throw new Error('Expected source.')
    }
    const trackingEvent: ScaniaAdobeTrackingDownloadPdfEvent = {
      event: ScaniaAdobeEventId.Download,
      eventInfo: {
        downloadType: ScaniaAdobeDownloadType.Pdf,
        filePath: pdfLink,
        placement: source,
      },
    }
    pushAdobeEvent(trackingEvent)
    window.open(pdfLink, '_blank', 'noreferrer')
    dispatch(closeAllModals())
  }, [pdfLink, dispatch, source])

  const handleCloseClick = useCallback(() => {
    dispatch(closeAllModals())
  }, [dispatch])

  // Eventlistener to close modal on tdsClose (click outside modal and click on close cross)
  useEffect(() => {
    document.addEventListener('tdsClose', handleCloseClick)
    return () => {
      document.removeEventListener('tdsClose', handleCloseClick)
    }
  }, [handleCloseClick])

  return (
    <TdsModal size="md" header={t('HEADER_DOWNLOAD_PDF')} show>
      <span slot="body">
        <div>{t('LABEL_DOWNLOAD_PDF')}</div>
      </span>
      <span slot="actions" className="tds-u-flex tds-u-gap2 tds-u-flex-wrap">
        <TdsButton
          variant="primary"
          size="md"
          text={t('ACTION_LABEL_DOWNLOAD')}
          onClick={handlePdfDownloadButtonClick}
          disabled={pdfLink ? false : true}
          data-test-download-url={pdfLink}
          data-test-element-type={TestElementTypeId.ModalSavePdfDownloadButton}
        />
        <TdsButton
          variant="secondary"
          size="md"
          text={t('LABEL_ACTION_CLOSE')}
          onClick={handleCloseClick}
          data-test-element-type={TestElementTypeId.ModalCloseButton}
        />
      </span>
    </TdsModal>
  )
}
