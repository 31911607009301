/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface UserConfigV2SaveAsResponseWrapper
 */
export interface UserConfigV2SaveAsResponseWrapper {
  /**
   *
   * @type {number}
   * @memberof UserConfigV2SaveAsResponseWrapper
   */
  userConfigV2SaveAs?: number | null
  /**
   *
   * @type {string}
   * @memberof UserConfigV2SaveAsResponseWrapper
   */
  error?: string | null
}

/**
 * Check if a given object implements the UserConfigV2SaveAsResponseWrapper interface.
 */
export function instanceOfUserConfigV2SaveAsResponseWrapper(
  value: object,
): boolean {
  let isInstance = true

  return isInstance
}

export function UserConfigV2SaveAsResponseWrapperFromJSON(
  json: any,
): UserConfigV2SaveAsResponseWrapper {
  return UserConfigV2SaveAsResponseWrapperFromJSONTyped(json, false)
}

export function UserConfigV2SaveAsResponseWrapperFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserConfigV2SaveAsResponseWrapper {
  if (json === undefined || json === null) {
    return json
  }
  return {
    userConfigV2SaveAs: !exists(json, 'user_config_v2_save_as')
      ? undefined
      : json['user_config_v2_save_as'],
    error: !exists(json, 'error') ? undefined : json['error'],
  }
}

export function UserConfigV2SaveAsResponseWrapperToJSON(
  value?: UserConfigV2SaveAsResponseWrapper | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    user_config_v2_save_as: value.userConfigV2SaveAs,
    error: value.error,
  }
}
