// TODO: Merge this with "bop" etc. and store as one object?

// TODO: Merge with the possibly modified Market Denomination.
export interface NewTruckLoadedInfo {
  factoryModelName: string | null
  isFactoryModel: boolean // TODO: Remove, can be replaced by `source`.
  publicConfigId: string | null
  savedAsId: number | null
  savedAsName: string | null
  source: TruckLoadedSource
  timeLoaded: Date // TODO: Rename to timeLoaded
}

export interface NewTruckLoadedInfoRedux {
  factoryModelName: string | null
  isFactoryModel: boolean // TODO: Remove, can be replaced by `source`.
  publicConfigId: string | null
  savedAsId: number | null
  savedAsName: string | null
  source: TruckLoadedSource
  timeLoaded: string
}

export enum TruckLoadedSource {
  EXPLORE_YOUR_SCANIA_START_CONFIG,
  FACTORY_MODEL,
  GUIDED_OFFERING,
  PUBLIC_CONFIG,
  USER_GARAGE,
  XML_IMPORT,
}

// A new truck has been loaded into the session, signal the need to reinitialize
// menus etc.
export type NewTruckLoadedHandler = (info: NewTruckLoadedInfo) => void
