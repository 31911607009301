// TODO: Remove this component when the Application page has been given Tegel
// styling.

import { MouseEventHandler } from 'react'
import { ChevronsRight, Info } from 'react-feather'
import { TestElementTypeId } from '../../types/TestAttributeId'
import { SimpleScaniaButton } from './SimpleScaniaButton'

export interface ButtonBuilderProps {
  buttonText: string
  defaultSelected: boolean
  hasReadmore: boolean
  id: string
  index: number // TODO: Remove this and only use id.
  onClick: MouseEventHandler<HTMLDivElement>
  onIconClick: MouseEventHandler<HTMLDivElement>
  testElementTypeId: TestElementTypeId
  testEtelVariantId: string
  testGoId: string
}

export function buildButton(data: ButtonBuilderProps): JSX.Element {
  const icon = data.hasReadmore ? <Info /> : <ChevronsRight />
  return (
    <SimpleScaniaButton
      data-defaultselected={data.defaultSelected} // Remove this and bind it to the event handler.
      data-id={data.id} // Remove this and bind it to the event handler.
      data-index={data.index} // Remove this and only use id.
      data-test-element-type={data.testElementTypeId}
      data-test-etel-variant-id={data.testEtelVariantId}
      data-test-go-group-id={data.testGoId}
      handleIconClick={data.onIconClick}
      icon={icon}
      id={data.id} // Remove this and bind it to the event handler.
      key={data.id}
      onClick={data.onClick}
    >
      {data.buttonText}
    </SimpleScaniaButton>
  )
}
