import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { SESSION_FAILURE } from '../../api/errors'
import {
  FlexImageParameters,
  FlexImageV2RequestParams,
} from '../../api/generated'
import {
  ScaniaAdobeTrackingDownloadImageEvent,
  pushAdobeEvent,
  ScaniaAdobeEventId,
  ScaniaAdobeDownloadType,
} from '../../utils/adobeAnalytics'
import { useClient } from '../../utils/useClient'
import useTexts from '../../utils/useTexts'
import { SvgLoading } from '../SvgImports'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { TestElementTypeId } from '../../types/TestAttributeId'
import {
  getMarketDenomination,
  getSessionInitDataState,
} from '../../store/sessionDataSlice'
import { closeAllModals, getModalState } from '../../store/modalSlice'
import { getTruckInfoState } from '../../store/truckInfoSlice'
import { TdsButton, TdsModal, TdsRadioButton } from '@scania/tegel-react'

interface UrlLoadingProps {
  $linksLoaded: boolean
}

const TextBox = styled.div<UrlLoadingProps>`
  display: ${(props) => (props.$linksLoaded ? '' : 'none')};
  height: 40px;
`

const RadioButtonWrapper = styled.fieldset<UrlLoadingProps>`
  display: ${(props) => (props.$linksLoaded ? '' : 'none')};
  border: 0;
  margin: 0;
  min-width: 0;
  padding: 0;
`

const SpinnerWrapper = styled.div<UrlLoadingProps>`
  display: ${(props) => (props.$linksLoaded ? 'none' : '')};
  margin-left: 32px;
  height: 40px;

  svg {
    color: var(--tds-blue-600);
  }
`

interface ModalSaveImageProps {
  handleFatalError: () => void
  handleSessionFailure: () => void
}

export function ModalSaveImage({
  handleFatalError,
  handleSessionFailure,
}: ModalSaveImageProps): JSX.Element {
  const t = useTexts()
  const client = useClient()
  const dispatch = useAppDispatch()
  const sessionInitData = useAppSelector(getSessionInitDataState)
  const sessionId = sessionInitData?.sessionId || ''
  const marketDenomination = useAppSelector(getMarketDenomination)
  const { source, imageFrame, imageSeries } =
    useAppSelector(getModalState).modalSaveImageState || {}
  const truckInfo = useAppSelector(getTruckInfoState)
  const [imagePngUrl, setImagePngUrl] = useState<string | null>(null)
  const [imageJpgUrl, setImageJpgUrl] = useState<string | null>(null)
  const [choosenFormat, setChoosenFormat] = useState<string>('png')
  const [fileDownloadName, setFileDownloadName] = useState<string | null>(null)

  useEffect(() => {
    const newName =
      (truckInfo?.savedAsName || marketDenomination) + '.' + choosenFormat
    setFileDownloadName(newName)
  }, [choosenFormat, truckInfo, marketDenomination])

  // Build url for image download
  useEffect(() => {
    async function getImageUrl(imageType: string, bgColorArgb: string) {
      if (!client || !sessionId || !imageSeries || !imageFrame) {
        return
      }
      const flexParams: FlexImageParameters = {
        series: imageSeries,
        frame: imageFrame,
        bgColor: bgColorArgb,
        autoCrop: ['top', 'bottom', 'left', 'right'],
        margin: [40, 40, 40, 40],
        imageType: imageType,
        size: [1280, 1024],
        useBoundingBoxes: false,
      }
      const requestParams: FlexImageV2RequestParams = {
        sessionId,
        flexParams,
        showBodyBuild: false,
      }
      const response = await client.flexImageV2(requestParams)
      if (response.error === SESSION_FAILURE) {
        handleSessionFailure()
        return null
      }
      if (response.error) {
        console.error(response.error)
        handleFatalError()
        return null
      }
      if (!response.success?.url) {
        console.error('Failed to find image URL in response.')
        handleFatalError()
        return null
      }
      return response.success.url
    }
    getImageUrl('png', '#00000000').then((result) => {
      setImagePngUrl(result || null)
    })
    getImageUrl('jpg', '#FFFFFFFF').then((result) => {
      setImageJpgUrl(result || null)
    })
  }, [
    client,
    handleFatalError,
    handleSessionFailure,
    sessionId,
    imageFrame,
    imageSeries,
  ])

  const handleFormatClick = useCallback(
    (format: string) => {
      setChoosenFormat(format)
    },
    [setChoosenFormat],
  )

  const handleImageTracking = useCallback(() => {
    if (!source) {
      throw new Error('Expected source.')
    }
    const trackingEvent: ScaniaAdobeTrackingDownloadImageEvent = {
      event: ScaniaAdobeEventId.Download,
      eventInfo: {
        downloadType: ScaniaAdobeDownloadType.Image,
        filePath: fileDownloadName || '',
        placement: source,
      },
    }
    pushAdobeEvent(trackingEvent)
    dispatch(closeAllModals())
  }, [fileDownloadName, source, dispatch])

  const handleCloseClick = useCallback(() => {
    dispatch(closeAllModals())
  }, [dispatch])

  // Eventlistener to close modal on tdsClose (click outside modal and click on close cross)
  useEffect(() => {
    document.addEventListener('tdsClose', handleCloseClick)
    return () => {
      document.removeEventListener('tdsClose', handleCloseClick)
    }
  }, [handleCloseClick])

  return (
    <TdsModal size="md" header={t('HEADER_DOWNLOAD_IMAGE')} show>
      <span slot="body">
        <SpinnerWrapper
          $linksLoaded={imageJpgUrl && imagePngUrl ? true : false}
        >
          <SvgLoading />
        </SpinnerWrapper>
        <TextBox $linksLoaded={imageJpgUrl && imagePngUrl ? true : false}>
          {t('LABEL_DOWNLOAD_IMAGE')}
        </TextBox>
        <RadioButtonWrapper
          $linksLoaded={imageJpgUrl && imagePngUrl ? true : false}
        >
          <TdsRadioButton
            name="image-radio-button"
            value="png"
            data-test-element-type={
              TestElementTypeId.ModalSaveImagePngRadioButton
            }
            onTdsChange={() => handleFormatClick('png')}
            checked={true}
          >
            <span slot="label">Png</span>
          </TdsRadioButton>
          <TdsRadioButton
            name="image-radio-button"
            value="jpg"
            data-test-element-type={
              TestElementTypeId.ModalSaveImageJpgRadioButton
            }
            onTdsChange={() => handleFormatClick('jpg')}
          >
            <span slot="label">Jpg</span>
          </TdsRadioButton>
        </RadioButtonWrapper>
      </span>

      <span slot="actions" className="tds-u-flex tds-u-gap2 tds-u-flex-wrap">
        <a
          href={
            imageJpgUrl && imagePngUrl && choosenFormat === 'png'
              ? imagePngUrl
              : imageJpgUrl || ''
          }
          download={fileDownloadName}
          style={{ textDecoration: 'none' }}
        >
          <TdsButton
            variant="primary"
            size="md"
            text={t('ACTION_LABEL_DOWNLOAD')}
            data-test-element-type={
              TestElementTypeId.ModalSaveImageDownloadButton
            }
            disabled={imageJpgUrl && imagePngUrl ? false : true}
            onClick={handleImageTracking}
          />
        </a>
        <TdsButton
          variant="secondary"
          size="md"
          text={t('LABEL_ACTION_CLOSE')}
          onClick={handleCloseClick}
          data-test-element-type={TestElementTypeId.ModalCloseButton}
        />
      </span>
    </TdsModal>
  )
}
