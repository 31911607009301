import { createListenerMiddleware, TypedStartListening } from '@reduxjs/toolkit'
import {
  getSessionState,
  pushPageViewTrackingEvent,
  setPendingUserTrackingPageNavigation,
} from './sessionDataSlice'
import {
  ScaniaAdobeEventId,
  ScaniaAdobeTrackingPageViewEvent,
  pushAdobeEvent,
} from '../utils/adobeAnalytics'
import { RootState, AppDispatch } from '.'

export const listenerMiddleware = createListenerMiddleware()

export type AppStartListening = TypedStartListening<RootState, AppDispatch>

// TODO: Eliminate typecast. Documentation related to typescript doesn't seem complete. https://redux-toolkit.js.org/api/createListenerMiddleware#typescript-usage
export const startAppListening =
  listenerMiddleware.startListening as AppStartListening

startAppListening({
  actionCreator: pushPageViewTrackingEvent,
  effect: (action, listenerApi) => {
    const state = listenerApi.getState()
    const flowInfo = getSessionState(state).pendingUserTrackingGoSelection
    const market = action.payload.marketLanguage.isoMarketCode
    const language = action.payload.marketLanguage.isoLanguageCode
    const pageURL = document.location.href
    const domain = document.location.hostname
    const ev: ScaniaAdobeTrackingPageViewEvent = {
      event: ScaniaAdobeEventId.PageView,
      pageInfo: {
        pageName: 'scv:configurator:' + action.payload.pageName,
        market,
        language,
        pageURL,
        domain,
      },
      flowInfo,
    }
    pushAdobeEvent(ev)
    listenerApi.dispatch(setPendingUserTrackingPageNavigation(null))
  },
})
