/**
 * This error is expected to be handled differently from most errors and can
 * occur for every web API interaction that is session bound, for now
 * it's just a different error message.
 */
export const SESSION_FAILURE = 'SESSION_FAILURE'

/**
 * A possible login error code.
 */
export const LOGIN_SUBSTATUS_NO_SUCH_USER = 'NoSuchUser'

/**
 * A possible login error code.
 */
export const LOGIN_SUBSTATUS_WRONG_PASSWORD = 'WrongPassword'

/**
 * This error is expected to be handled differently from most errors and can
 * occur for every web API interaction that is session bound, for now
 * it's just a different error message.
 */
export enum SessionError {
  SESSION_FAILURE,
}
