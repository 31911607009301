/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ConfigChangeMenuEntry } from './ConfigChangeMenuEntry'
import {
  ConfigChangeMenuEntryFromJSON,
  ConfigChangeMenuEntryFromJSONTyped,
  ConfigChangeMenuEntryToJSON,
} from './ConfigChangeMenuEntry'

/**
 *
 * @export
 * @interface OutResolvers
 */
export interface OutResolvers {
  /**
   *
   * @type {Array<ConfigChangeMenuEntry>}
   * @memberof OutResolvers
   */
  triggerItems: Array<ConfigChangeMenuEntry>
  /**
   *
   * @type {Array<ConfigChangeMenuEntry>}
   * @memberof OutResolvers
   */
  deactivatedItems: Array<ConfigChangeMenuEntry>
}

/**
 * Check if a given object implements the OutResolvers interface.
 */
export function instanceOfOutResolvers(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'triggerItems' in value
  isInstance = isInstance && 'deactivatedItems' in value

  return isInstance
}

export function OutResolversFromJSON(json: any): OutResolvers {
  return OutResolversFromJSONTyped(json, false)
}

export function OutResolversFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): OutResolvers {
  if (json === undefined || json === null) {
    return json
  }
  return {
    triggerItems: (json['trigger_items'] as Array<any>).map(
      ConfigChangeMenuEntryFromJSON,
    ),
    deactivatedItems: (json['deactivated_items'] as Array<any>).map(
      ConfigChangeMenuEntryFromJSON,
    ),
  }
}

export function OutResolversToJSON(value?: OutResolvers | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    trigger_items: (value.triggerItems as Array<any>).map(
      ConfigChangeMenuEntryToJSON,
    ),
    deactivated_items: (value.deactivatedItems as Array<any>).map(
      ConfigChangeMenuEntryToJSON,
    ),
  }
}
