import { useCallback } from 'react'
import styled from 'styled-components'
import { LocalSpecialGoQuestion } from './OptionalsPage'
import { TdsDropdown, TdsDropdownOption } from '@scania/tegel-react'

const DropdownWrapper = styled.div`
  position: relative;
  padding: 0.125rem 0.25rem;

  p.title {
    color: ${({ theme }) => theme.scDarkBlue};
    font-size: 1rem;
    margin-bottom: 28px;
  }
`

export interface DropDownComponentProps {
  question: LocalSpecialGoQuestion
  sendInputSelection: (id: string | null) => void
}

export function DropDownComponent({
  question,
  sendInputSelection,
}: DropDownComponentProps) {
  const dropdownChoices = (question.sliderOptions || []).map((opt) => {
    const choice = {
      id: opt.id,
      label: opt.label || '',
    }
    return choice
  })

  const getDropdownValue = useCallback(
    (ev: any) => {
      const dropdownValue: string = ev.detail.value
      sendInputSelection(dropdownValue)
    },
    [sendInputSelection],
  )

  return (
    <DropdownWrapper>
      <p className="title">{question.header}</p>
      <TdsDropdown size="md" onTdsChange={getDropdownValue}>
        {dropdownChoices.map((o) => (
          <TdsDropdownOption value={o.id} class="hydrated" key={o.id}>
            {o.label}
          </TdsDropdownOption>
        ))}
      </TdsDropdown>
    </DropdownWrapper>
  )
}
