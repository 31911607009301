/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { CreateUserError } from './CreateUserError'
import {
  CreateUserErrorFromJSON,
  CreateUserErrorFromJSONTyped,
  CreateUserErrorToJSON,
} from './CreateUserError'

/**
 *
 * @export
 * @interface CreateUserResponseWrapper
 */
export interface CreateUserResponseWrapper {
  /**
   *
   * @type {CreateUserError}
   * @memberof CreateUserResponseWrapper
   */
  error?: CreateUserError | null
}

/**
 * Check if a given object implements the CreateUserResponseWrapper interface.
 */
export function instanceOfCreateUserResponseWrapper(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function CreateUserResponseWrapperFromJSON(
  json: any,
): CreateUserResponseWrapper {
  return CreateUserResponseWrapperFromJSONTyped(json, false)
}

export function CreateUserResponseWrapperFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CreateUserResponseWrapper {
  if (json === undefined || json === null) {
    return json
  }
  return {
    error: !exists(json, 'error')
      ? undefined
      : CreateUserErrorFromJSON(json['error']),
  }
}

export function CreateUserResponseWrapperToJSON(
  value?: CreateUserResponseWrapper | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    error: CreateUserErrorToJSON(value.error),
  }
}
