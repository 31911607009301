// Legacy encoding, TODO: Change this to a dedicated request handler in the
// web API and replace the semicolon separated encoding.
// SCANIA_MODEL;CATEGORY#5247;SCANIA_FACTORY_MODEL#20
export function buildFactoryModelConfigString(
  etelMarket: number,
  factoryModelId: number,
  extraVariantIds: string[],
): string {
  return [
    'SCANIA_MODEL',
    `CATEGORY#${etelMarket}`,
    `SCANIA_FACTORY_MODEL#${factoryModelId}`,
  ]
    .concat(extraVariantIds)
    .join(';')
}
