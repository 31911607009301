// TODO - remove XmlUpload.tsx

import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { SESSION_FAILURE } from '../../api/errors'
import {
  NewTruckLoadedHandler,
  NewTruckLoadedInfo,
  TruckLoadedSource,
} from '../../types/TruckLoadedTypes'
import {
  ScaniaAdobeTrackingUploadXmlEvent,
  pushAdobeEvent,
  ScaniaAdobeEventId,
} from '../../utils/adobeAnalytics'
import { buildRelativeBuildPageUrl } from '../../utils/UrlBuilders'
import { useClient } from '../../utils/useClient'
import useTexts from '../../utils/useTexts'
import { TestElementTypeId } from '../../types/TestAttributeId'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { getUrlParametersToString } from '../../utils/getUrlParametersToString'
import { getSessionInitDataState } from '../../store/sessionDataSlice'
import { getMarketLanguageState } from '../../store/appSlice'
import { closeAllModals } from '../../store/modalSlice'
import { TdsButton, TdsModal } from '@scania/tegel-react'
import {
  ConsequenceOfChangeDialogData,
  ConsequenceOfChangeHandler,
} from '../../types/ConsequenceOfChangeTypes'

interface ModalUploadXmlRootProps {
  $isLoading: boolean
}

const ModalUploadXmlRoot = styled.div<ModalUploadXmlRootProps>`
  height: 100%;
  width: 100%;

  ${({ $isLoading }) =>
    $isLoading &&
    css`
      * {
        cursor: wait !important;
      }
    `}
`

const InputFileSelect = styled.input`
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  position: absolute;
`

interface FileSelectWrapperProps {
  $disabled: boolean
}

const FileSelectWrapper = styled.div<FileSelectWrapperProps>`
  display: flex;
  align-items: center;
  --border-color: var(--tds-btn-secondary-border-color);

  label {
    cursor: pointer;
  }

  ${({ $disabled }) =>
    $disabled &&
    css`
      --border-color: var(--tds-grey-300);
      color: var(--tds-grey-300);
    `}

  ${({ $disabled }) =>
    !$disabled &&
    css`
      @media (hover: hover) and (pointer: fine) {
        &:hover {
          --border-color: var(--tds-btn-secondary-border-color-hover);
        }
      }
    `}
`

const LabelFileSelect = styled.label`
  border-radius: 4px;
  border: 1px solid var(--border-color);
  height: 48px;
  display: flex;
  width: fit-content;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
`

const FileMessageWrapper = styled.div`
  margin-left: 24px;
  display: flex;
`

const ErrorMessageWrapper = styled.div`
  color: var(--tds-negative);
`

interface ModalUploadXmlProps {
  handleFatalError: () => void
  handleNewTruckIsLoaded: NewTruckLoadedHandler
  handleSessionFailure: () => void
  handleConsequenceOfChange: ConsequenceOfChangeHandler
  handleCloseModalConsequenseOfChange: () => void
}

export function ModalUploadXml({
  handleFatalError,
  handleNewTruckIsLoaded,
  handleSessionFailure,
  handleConsequenceOfChange,
  handleCloseModalConsequenseOfChange,
}: ModalUploadXmlProps): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [fileSelected, setFileSelected] = useState<File | null>(null)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const client = useClient()
  const sessionInitData = useAppSelector(getSessionInitDataState)
  const t = useTexts()
  const marketLanguage = useAppSelector(getMarketLanguageState)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const handleUploadedXmlChange = (
    event: React.FormEvent<HTMLInputElement>,
  ) => {
    const files = event.currentTarget.files
    if (!files) {
      setErrorMessage(t('ERROR_XML_NO_FILE_SELECTED'))
      return
    }
    const file = files[0]
    if (file.type !== 'text/xml' && file.type !== 'application/xml') {
      setErrorMessage(t('ERROR_XML_MANDATORY'))
      return
    }
    setErrorMessage(null)
    setFileSelected(file)
  }

  const handleXmlSubmit = async (event: SyntheticEvent) => {
    setIsLoading(true)
    event.preventDefault()
    const reader = new FileReader()

    if (!fileSelected) {
      setErrorMessage(t('ERROR_XML_NO_FILE_SELECTED'))
      setIsLoading(false)
      return
    }

    reader.readAsDataURL(fileSelected)

    reader.onload = async () => {
      try {
        const sessionId = sessionInitData?.sessionId
        if (!client) {
          throw new Error('Expected client to be defined.')
        }
        if (!marketLanguage?.isoLanguageCode) {
          // TODO: Why do we have both language and etelLanguageCode in this
          // component?
          throw new Error('Expected language to be defined.')
        }
        if (!marketLanguage) {
          throw new Error('Expected marketLanguage to be defined.')
        }
        if (!sessionId) {
          throw new Error('Expected sessionId to be defined.')
        }
        if (!reader.result) {
          setIsLoading(false)
          setErrorMessage(t('ERROR_XML_BAD_FORMAT'))
          return
        }
        if (typeof reader.result !== 'string') {
          setIsLoading(false)
          setErrorMessage(t('ERROR_XML_BAD_FORMAT'))
          return
        }
        if (
          fileSelected.type !== 'text/xml' &&
          fileSelected.type !== 'application/xml'
        ) {
          setIsLoading(false)
          setErrorMessage(t('ERROR_XML_MANDATORY'))
          return
        }
        const response = await client.scaniaImportXml({
          sessionId: sessionInitData.sessionId,
          xmlDataBase64: reader.result.split(',')[1],
        })
        const error = response.error
        if (error?.status === 'WrongImportMarket') {
          setIsLoading(false)
          setErrorMessage(error.message)
          return
        } else if (error) {
          setIsLoading(false)
          handleFatalError()
          return
        }
        const coc = await client.getInitialConsequenceOfChange({
          sessionId: sessionInitData.sessionId,
        })
        if (coc.error === SESSION_FAILURE) {
          setIsLoading(false)
          handleSessionFailure()
          return
        } else if (coc.error) {
          setIsLoading(false)
          handleFatalError()
          return
        }
        dispatch(closeAllModals())

        var deactivatedItems =
          coc.getInitialConsequenceOfChange?.deactivatedItems || []
        var triggerItems = coc.getInitialConsequenceOfChange?.triggerItems || []
        if (deactivatedItems.length > 0 || triggerItems.length > 0) {
          const data: ConsequenceOfChangeDialogData = {
            resolvers: {
              deactivatedItems,
              triggerItems,
            },

            // When loading a new configuration, we don't support rollback and
            // must display the Consequence of Change message with an OK button
            // and witout any Cancel or close buttons.
            //
            // IMPORTANT:
            // In this context, Consequence of Change is actually incorrect
            // terminology and should probably be changed to a dedicated request
            // or at least return type as a property on the userConfigV2Load
            // respone object. What this data represents when loading a new truck
            // is the difference from when it was saved, which parts of the saved
            // configuration that is no longer supported in this combination by
            // the new product rules.
            //
            // TODO: Refactor CoC for load config situations and report a
            // "LoadedConfigurationDifference" as part fo the response object or
            // something like that.
            showCancelButton: false,
            clickedId: null,
          }
          await handleConsequenceOfChange(data)
          dispatch(closeAllModals())
          handleCloseModalConsequenseOfChange()
        }

        const info: NewTruckLoadedInfo = {
          factoryModelName: coc.factoryModelName || null,
          isFactoryModel: coc.isFactoryModel || false,
          publicConfigId: null,
          savedAsId: null,
          savedAsName: null,
          source: TruckLoadedSource.XML_IMPORT,
          timeLoaded: new Date(),
        }
        setIsLoading(false)
        setFileSelected(null)
        setErrorMessage(null)
        handleNewTruckIsLoaded(info)
        handleCloseClick()

        const relativeUrl = buildRelativeBuildPageUrl(marketLanguage)
        const params = relativeUrl.params
        const newUrl = relativeUrl.path + getUrlParametersToString(params)
        navigate(newUrl, { replace: true })

        const trackingEvent: ScaniaAdobeTrackingUploadXmlEvent = {
          event: ScaniaAdobeEventId.XmlUploadClick,
        }
        pushAdobeEvent(trackingEvent)
      } catch {
        setIsLoading(false)
        handleFatalError()
      }
    }

    reader.onerror = () => {
      setIsLoading(false)
      handleFatalError()
    }
  }

  const handleCloseClick = useCallback(() => {
    dispatch(closeAllModals())
  }, [dispatch])

  // Eventlistener to close modal on tdsClose (click outside modal and click on close cross)
  useEffect(() => {
    document.addEventListener('tdsClose', handleCloseClick)
    return () => {
      document.removeEventListener('tdsClose', handleCloseClick)
    }
  }, [handleCloseClick])

  return (
    <ModalUploadXmlRoot $isLoading={isLoading}>
      <form onSubmit={handleXmlSubmit}>
        <TdsModal size="md" header={t('ACTION_UPLOAD')} show>
          <span slot="body">
            <FileSelectWrapper $disabled={isLoading}>
              <InputFileSelect
                type="file"
                id="fileSelector"
                disabled={isLoading}
                onChange={isLoading ? () => {} : handleUploadedXmlChange}
                data-test-element-type={
                  TestElementTypeId.UploadConfigFileSelector
                }
              />
              <LabelFileSelect htmlFor="fileSelector">
                {t('ACTION_BROWSE_FILES')}
              </LabelFileSelect>
              {fileSelected ? (
                <FileMessageWrapper>{fileSelected.name}</FileMessageWrapper>
              ) : (
                <FileMessageWrapper>
                  {t('DESCRIPTION_XML_UPLOAD')}
                </FileMessageWrapper>
              )}
            </FileSelectWrapper>
            {errorMessage && (
              <ErrorMessageWrapper>{errorMessage}</ErrorMessageWrapper>
            )}
          </span>

          <span
            slot="actions"
            className="tds-u-flex tds-u-gap2 tds-u-flex-wrap"
          >
            <TdsButton
              size="md"
              disabled={isLoading}
              data-test-element-type={TestElementTypeId.UploadConfigLoadButton}
              type="submit"
              text={t('ACTION_UPLOAD')}
            />
            <TdsButton
              variant="secondary"
              size="md"
              disabled={isLoading}
              onClick={handleCloseClick}
              text={t('LABEL_ACTION_CANCEL')}
            />
          </span>
        </TdsModal>
      </form>
    </ModalUploadXmlRoot>
  )
}
