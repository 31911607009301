import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import type { RootState } from './index'
import { isTabletOrSmaller } from '../utils/screenQuery'
import { VisibleMenuState } from './types'

export interface MenuState {
  mainMenuIsOpen: boolean
  visibleMenuState: VisibleMenuState | null
  currentlyLoadingId: string | null
  menuTexts: Record<string, string> | null
  menuSharePopUpIsOpen: boolean
  menuSavePopUpIsOpen: boolean
  menuShareMainMenuIsOpen: boolean
  menuSaveMainMenuIsOpen: boolean
}

const initialState: MenuState = {
  mainMenuIsOpen: isTabletOrSmaller() ? false : true,
  visibleMenuState: null,
  currentlyLoadingId: null,
  menuTexts: null,
  menuSharePopUpIsOpen: false,
  menuSavePopUpIsOpen: false,
  menuShareMainMenuIsOpen: false,
  menuSaveMainMenuIsOpen: false,
}

export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    openMainMenu: (state) => {
      state.mainMenuIsOpen = true
    },
    closeMainMenu: (state) => {
      state.mainMenuIsOpen = false
    },
    toggleMainMenu: (state) => {
      state.mainMenuIsOpen = !state.mainMenuIsOpen
    },
    setVisibleMenuState: (
      state,
      action: PayloadAction<VisibleMenuState | null>,
    ) => {
      state.visibleMenuState = action.payload
    },
    setCurrentlyLoadingId: (state, action: PayloadAction<string | null>) => {
      state.currentlyLoadingId = action.payload
    },
    setMenuTexts: (
      state,
      action: PayloadAction<Record<string, string> | null>,
    ) => {
      state.menuTexts = action.payload
    },

    openMenuSharePopUp: (state) => {
      state.menuSharePopUpIsOpen = true
    },
    closeMenuSharePopUp: (state) => {
      state.menuSharePopUpIsOpen = false
    },
    openMenuSavePopUp: (state) => {
      state.menuSavePopUpIsOpen = true
    },
    closeMenuSavePopUp: (state) => {
      state.menuSavePopUpIsOpen = false
    },
    openMenuShareMainMenu: (state) => {
      state.menuShareMainMenuIsOpen = true
    },
    closeMenuShareMainMenu: (state) => {
      state.menuShareMainMenuIsOpen = false
    },
    openMenuSaveMainMenu: (state) => {
      state.menuSaveMainMenuIsOpen = true
    },
    closeMenuSaveMainMenu: (state) => {
      state.menuSaveMainMenuIsOpen = false
    },
  },
})

export const {
  openMainMenu,
  closeMainMenu,
  toggleMainMenu,
  setVisibleMenuState,
  setCurrentlyLoadingId,
  setMenuTexts,
  openMenuSharePopUp,
  closeMenuSharePopUp,
  openMenuSavePopUp,
  closeMenuSavePopUp,
  openMenuShareMainMenu,
  closeMenuShareMainMenu,
  openMenuSaveMainMenu,
  closeMenuSaveMainMenu,
} = menuSlice.actions

export const getMenuState = (state: RootState) => state.menu

export default menuSlice.reducer
