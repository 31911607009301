/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface LoadConfigFromStringRequest
 */
export interface LoadConfigFromStringRequest {
  /**
   *
   * @type {string}
   * @memberof LoadConfigFromStringRequest
   */
  sessionId: string
  /**
   *
   * @type {string}
   * @memberof LoadConfigFromStringRequest
   */
  config: string
  /**
   *
   * @type {string}
   * @memberof LoadConfigFromStringRequest
   */
  encoding: string
}

/**
 * Check if a given object implements the LoadConfigFromStringRequest interface.
 */
export function instanceOfLoadConfigFromStringRequest(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'sessionId' in value
  isInstance = isInstance && 'config' in value
  isInstance = isInstance && 'encoding' in value

  return isInstance
}

export function LoadConfigFromStringRequestFromJSON(
  json: any,
): LoadConfigFromStringRequest {
  return LoadConfigFromStringRequestFromJSONTyped(json, false)
}

export function LoadConfigFromStringRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): LoadConfigFromStringRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    sessionId: json['session_id'],
    config: json['config'],
    encoding: json['encoding'],
  }
}

export function LoadConfigFromStringRequestToJSON(
  value?: LoadConfigFromStringRequest | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    session_id: value.sessionId,
    config: value.config,
    encoding: value.encoding,
  }
}
