/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetStatelessScaniaSystemInfoResponseWrapperGetStatelessScaniaSystemInfo } from './GetStatelessScaniaSystemInfoResponseWrapperGetStatelessScaniaSystemInfo'
import {
  GetStatelessScaniaSystemInfoResponseWrapperGetStatelessScaniaSystemInfoFromJSON,
  GetStatelessScaniaSystemInfoResponseWrapperGetStatelessScaniaSystemInfoFromJSONTyped,
  GetStatelessScaniaSystemInfoResponseWrapperGetStatelessScaniaSystemInfoToJSON,
} from './GetStatelessScaniaSystemInfoResponseWrapperGetStatelessScaniaSystemInfo'

/**
 * Legacy response wrapper structure to allow matching the original
 * configurator web API.
 *
 * TODO: This should be removed after the .Net Core and ScdsClient
 * migration has been completed.
 * @export
 * @interface GetStatelessScaniaSystemInfoResponseWrapper
 */
export interface GetStatelessScaniaSystemInfoResponseWrapper {
  /**
   *
   * @type {GetStatelessScaniaSystemInfoResponseWrapperGetStatelessScaniaSystemInfo}
   * @memberof GetStatelessScaniaSystemInfoResponseWrapper
   */
  getStatelessScaniaSystemInfo: GetStatelessScaniaSystemInfoResponseWrapperGetStatelessScaniaSystemInfo
}

/**
 * Check if a given object implements the GetStatelessScaniaSystemInfoResponseWrapper interface.
 */
export function instanceOfGetStatelessScaniaSystemInfoResponseWrapper(
  value: object,
): boolean {
  let isInstance = true
  isInstance = isInstance && 'getStatelessScaniaSystemInfo' in value

  return isInstance
}

export function GetStatelessScaniaSystemInfoResponseWrapperFromJSON(
  json: any,
): GetStatelessScaniaSystemInfoResponseWrapper {
  return GetStatelessScaniaSystemInfoResponseWrapperFromJSONTyped(json, false)
}

export function GetStatelessScaniaSystemInfoResponseWrapperFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GetStatelessScaniaSystemInfoResponseWrapper {
  if (json === undefined || json === null) {
    return json
  }
  return {
    getStatelessScaniaSystemInfo:
      GetStatelessScaniaSystemInfoResponseWrapperGetStatelessScaniaSystemInfoFromJSON(
        json['get_stateless_scania_system_info'],
      ),
  }
}

export function GetStatelessScaniaSystemInfoResponseWrapperToJSON(
  value?: GetStatelessScaniaSystemInfoResponseWrapper | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    get_stateless_scania_system_info:
      GetStatelessScaniaSystemInfoResponseWrapperGetStatelessScaniaSystemInfoToJSON(
        value.getStatelessScaniaSystemInfo,
      ),
  }
}
