import styled from 'styled-components'
import { SvgTruck } from '../../../components/SvgImports'
import { blinkKeyframes } from '../MenuBlinkAnimation'

const ActiveChangesIconRoot = styled.div`
  // TODO: Move to a wrapper in Specification.tsx
  //position: absolute;
  //z-index: 1;

  position: relative;
  margin-top: 5px;
  display: flex;
  align-items: center;
  height: 34px;
  width: 34px;
  justify-content: center;

  svg {
    height: 24px;
    width: 24px;
  }
`

const SvgTruckWrapper = styled.div`
  top: 3px;
  left: 5px;
  position: absolute;
`

interface HighlightActiveChangesProps {
  $isHighlighted: boolean
}

const GreenDot = styled.div<HighlightActiveChangesProps>`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: var(--tds-positive);
  top: 0;
  right: 0;
  color: var(--tds-white);
  text-align: center;
  font-size: 10px;
  position: absolute;
  line-height: 16px;

  animation-duration: 500ms;
  animation-iteration-count: 3;
  animation-name: ${(props) =>
    props.$isHighlighted ? blinkKeyframes : 'unset'};
`

export interface ActiveChangesIconProps {
  choiceCount: number
  isHighlighted: boolean
}

export const ActiveChangesIcon = ({
  choiceCount,
  isHighlighted,
}: ActiveChangesIconProps): JSX.Element => {
  return (
    <ActiveChangesIconRoot>
      <SvgTruckWrapper>
        <SvgTruck />
      </SvgTruckWrapper>
      <GreenDot $isHighlighted={isHighlighted && choiceCount > 0}>
        {choiceCount}
      </GreenDot>
    </ActiveChangesIconRoot>
  )
}
