import { useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { SESSION_FAILURE } from '../../api/errors'
import {
  NewTruckLoadedHandler,
  NewTruckLoadedInfo,
  TruckLoadedSource,
} from '../../types/TruckLoadedTypes'
import { setEysStartConfigId } from '../../utils/sessionStorageUtil'
import { buildRelativeBuildPageUrl } from '../../utils/UrlBuilders'
import { useClient } from '../../utils/useClient'
import { useAppSelector } from '../../store/hooks'
import { getUrlParametersToString } from '../../utils/getUrlParametersToString'
import { getMarketLanguageState } from '../../store/appSlice'
import {
  getSessionInitDataState,
  getStartupData,
} from '../../store/sessionDataSlice'

const LandingPageRoot = styled.div`
  background-color: var(--tds-blue-800);
`

export interface LinkLandingPageRouteParams {
  market: string
  language: string
  pubc: string
}

export interface LinkLandingPageProps {
  handleFatalError: () => void
  handleNewTruckIsLoaded: NewTruckLoadedHandler
  handleSessionFailure: () => void
}

export const LinkLandingPage = ({
  handleFatalError,
  handleNewTruckIsLoaded,
  handleSessionFailure,
}: LinkLandingPageProps): JSX.Element => {
  // TODO: Seems hard or impossible to bind both element properties and route
  // properties using react-router-dom 5, resulting in lack of type safety
  // here.
  // TODO: Upgrade to version 6:
  // https://reactrouter.com/en/v6.3.0/upgrading/v5#upgrade-to-react-router-v6
  const params = useParams()
  const isLoading = useRef<boolean>(false)

  const marketLanguage = useAppSelector(getMarketLanguageState)
  const startupData = useAppSelector(getStartupData)
  const sessionInitData = useAppSelector(getSessionInitDataState)
  const client = useClient()
  const navigate = useNavigate()

  // Initialize configuration and navigate to BuildModePage.
  useEffect(() => {
    if (isLoading.current === true) {
      return
    }
    // TODO: Bundle all "init data" as one object.
    if (!marketLanguage) {
      return // Not initialized yet.
    }
    if (!client) {
      return // Not initialized yet.
    }
    if (!startupData) {
      return // Not initialized yet.
    }
    if (!sessionInitData) {
      return // Not initialized yet.
    }
    const publicConfigId = params.pubc
    if (!publicConfigId) {
      return // No public config to load.
    }
    isLoading.current = true

    // TODO: Test and see if we need this to reload the landing page when
    // back-navigation loads this page.
    //
    //if (truckConfigStartTime) {
    //  return // Already initialized.
    //}

    const asyncWrapper = async () => {
      try {
        const sessionId = sessionInitData.sessionId
        await client.publicConfigV2Load(sessionId, publicConfigId)
        const coc = await client.getInitialConsequenceOfChange({
          sessionId,
        })
        if (coc.error === SESSION_FAILURE) {
          handleSessionFailure()
          return
        }
        let source: TruckLoadedSource
        if (startupData.exploreYourScaniaMode) {
          source = TruckLoadedSource.EXPLORE_YOUR_SCANIA_START_CONFIG

          // Need to store this in case the user reloads the BuildModePage which
          // should reload the same start configuration for EYS instead of going
          // tro the market landing page.
          setEysStartConfigId(publicConfigId)
        } else {
          source = TruckLoadedSource.PUBLIC_CONFIG
        }
        if (coc.getInitialConsequenceOfChange) {
          // TODO: Handle the response, display the consequence of change dialog
          // when needed, but with only the OK button, it's not possible to
          // rollback the configuration here.
          console.warn(
            'Consequence of Change for public config loading is not implemented yet.',
          )
        }
        const info: NewTruckLoadedInfo = {
          factoryModelName: coc.factoryModelName || null,
          isFactoryModel: coc.isFactoryModel || false,
          publicConfigId,
          savedAsId: null,
          savedAsName: null,
          timeLoaded: new Date(),
          source,
        }
        handleNewTruckIsLoaded(info)
        const relativeUrl = buildRelativeBuildPageUrl(marketLanguage)
        const params = relativeUrl.params
        navigate(relativeUrl.path + getUrlParametersToString(params), {
          replace: true,
        })
      } catch (err) {
        console.error(err)
        console.error('Failed to initialize public config: ' + publicConfigId)
        handleFatalError()
        return
      }
    }
    asyncWrapper()
  }, [
    client,
    handleFatalError,
    handleNewTruckIsLoaded,
    handleSessionFailure,
    marketLanguage,
    navigate,
    params,
    sessionInitData,
    startupData,
  ])

  // Should not be visible due to splash screen.
  return <LandingPageRoot></LandingPageRoot>
}
