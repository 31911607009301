import styled from 'styled-components'
import { LocalSpecialGoQuestion } from './OptionalsPage'
import { TdsSlider } from '@scania/tegel-react'
import { ScalarConstraints } from '../../../api/generated'
import { useEffect, useState } from 'react'

const SliderWrapper = styled.div`
  position: relative;
  padding: 0.125rem 1rem;

  p.title {
    color: ${({ theme }) => theme.scDarkBlue};
    font-size: 1rem;
  }

  .tds-slider__value {
    font-size: 12px;
    line-height: 16px;

    svg {
      top: 26px;
    }
  }
`
enum InputType {
  Scalar,
  Dropdown,
}

interface SliderConstraints {
  min: number
  max: number
  step: number
}

export interface SliderComponentProps {
  question: LocalSpecialGoQuestion
  sendInputSelection: (
    id: string,
    header: string | null | undefined,
    input: InputType,
  ) => void
}

export function SliderComponent({
  question,
  sendInputSelection,
}: SliderComponentProps) {
  const [selectedValue, setSelectedValue] = useState<string | undefined>(
    undefined,
  )

  const guessReasonableSliderConstraints = (
    backendConstraints?: ScalarConstraints | null,
  ): SliderConstraints => {
    if (!backendConstraints) {
      // Detect the error and throw a specific exception here instead of in the
      // template.
      throw new Error('Expected backendConstraints to be defined.')
    }
    const step = guessReasonableSliderStepSize(
      backendConstraints.max - backendConstraints.min,
    )
    const min = backendConstraints.min
    const max = roundToStep(backendConstraints.max, step)
    return { min, max, step }
  }

  /**
   * Guess a slider step size for this specific context.
   *
   * @param n {Number} The total slider range.
   */
  const guessReasonableSliderStepSize = (n: number) => {
    if (n < 10) {
      return 1
    }
    const exp = n.toExponential()
    const zeroes = Number(exp.split('+')[1])
    const flooredToClosestPowerOfTen = Math.pow(10, zeroes)
    return Math.round(flooredToClosestPowerOfTen * 0.1)
  }

  const roundToStep = (n: number, step: number): number => {
    const down = n % step
    const up = n - down + step - n
    return down < up ? n - down : n + up
  }

  // set default value if any
  useEffect(() => {
    if (selectedValue) {
      return
    }
    const defaultEtelId = question.selected
    // set to minimum value if no default value. Tegel will not show the selector otherwise.
    if (!defaultEtelId) {
      setSelectedValue(question.scalarConstraints?.min.toString())
      return
    }
    const opt = question.sliderOptions?.find((opt) => opt.id === defaultEtelId)
    const value = opt?.optionInterval.value.toString()
    setSelectedValue(value)
  }, [question, selectedValue])

  const handleInputSelection = (
    value: string,
    header: string | null | undefined,
    inputType: InputType,
  ) => {
    if (!question.sliderOptions) {
      return
    }
    var etelId = null
    const valueInt = parseInt(value)

    for (let i = 0; i < question.sliderOptions.length; i++) {
      const { id, optionInterval } = question.sliderOptions[i]
      if (valueInt >= optionInterval.min && valueInt <= optionInterval.max) {
        etelId = id
      }
    }
    if (!etelId) {
      return
    }
    setSelectedValue(value)
    sendInputSelection(etelId, question.header, InputType.Scalar)
  }

  return (
    <SliderWrapper>
      <p className="title">
        {question.header}
        {question.unit && ' (' + question.unit + ')'}
      </p>
      <TdsSlider
        key={question.etelFamily}
        onTdsChange={(ev) =>
          handleInputSelection(
            ev.detail.value,
            question.header,
            InputType.Scalar,
          )
        }
        min={guessReasonableSliderConstraints(
          question.scalarConstraints,
        ).min.toString()}
        max={guessReasonableSliderConstraints(
          question.scalarConstraints,
        ).max.toString()}
        step={guessReasonableSliderConstraints(
          question.scalarConstraints,
        ).step.toString()}
        value={selectedValue}
        ticks="1"
        showTickNumbers={true}
        tooltip={true}
        thumbSize="sm"
      />
    </SliderWrapper>
  )
}
