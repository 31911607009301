import { ConfigChangeMenuEntry } from '../../api/generated'
import { LeafMenuEntry, VisibleMenuEntry } from './Menu/ConfiguratorMenu'

export const isMenuId = (id: string): boolean => {
  return id.startsWith('MENU_') || id.startsWith('SUBMENU_')
}

// Helper for the select item API (attemptBipV2).
export interface InterpretedMenuEntries {
  leafEntries: LeafMenuEntry[]
  childSubmenuEntries: VisibleMenuEntry[]
}

export function interpretSelectionResultItems(
  updates: ConfigChangeMenuEntry[],
): InterpretedMenuEntries {
  const leafEntries: LeafMenuEntry[] = []
  const childSubmenuEntries: VisibleMenuEntry[] = []

  updates.forEach((u) => {
    if (!u.visible) {
      // TODO: Is this correct both menus and items?
      return
    }
    if (isMenuId(u.id)) {
      const submenu: VisibleMenuEntry = {
        childIds: null,
        hasReadmore: u.hasReadmore || false,
        id: u.id,
        isOpen: false,
        parentId: u.guiGroup,
        text: u.shortText || u.id,
      }
      childSubmenuEntries.push(submenu)
    } else {
      const leaf: LeafMenuEntry = {
        hasReadmore: u.hasReadmore || false,
        id: u.id,
        isSelected: u.selected,
        text: u.shortText || u.id,
      }
      leafEntries.push(leaf)
    }
  })
  return { childSubmenuEntries, leafEntries }
}
