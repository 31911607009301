/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GoOptionInternalOptionInterval } from './GoOptionInternalOptionInterval'
import {
  GoOptionInternalOptionIntervalFromJSON,
  GoOptionInternalOptionIntervalFromJSONTyped,
  GoOptionInternalOptionIntervalToJSON,
} from './GoOptionInternalOptionInterval'

/**
 *
 * @export
 * @interface GoOptionInternal
 */
export interface GoOptionInternal {
  /**
   *
   * @type {string}
   * @memberof GoOptionInternal
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof GoOptionInternal
   */
  label?: string | null
  /**
   *
   * @type {GoOptionInternalOptionInterval}
   * @memberof GoOptionInternal
   */
  optionInterval: GoOptionInternalOptionInterval
}

/**
 * Check if a given object implements the GoOptionInternal interface.
 */
export function instanceOfGoOptionInternal(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'optionInterval' in value

  return isInstance
}

export function GoOptionInternalFromJSON(json: any): GoOptionInternal {
  return GoOptionInternalFromJSONTyped(json, false)
}

export function GoOptionInternalFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GoOptionInternal {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['Id'],
    label: !exists(json, 'Label') ? undefined : json['Label'],
    optionInterval: GoOptionInternalOptionIntervalFromJSON(
      json['OptionInterval'],
    ),
  }
}

export function GoOptionInternalToJSON(value?: GoOptionInternal | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    Id: value.id,
    Label: value.label,
    OptionInterval: GoOptionInternalOptionIntervalToJSON(value.optionInterval),
  }
}
