/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { EtelBuildModeStatus } from './EtelBuildModeStatus'
import {
  EtelBuildModeStatusFromJSON,
  EtelBuildModeStatusFromJSONTyped,
  EtelBuildModeStatusToJSON,
} from './EtelBuildModeStatus'

/**
 * Information that can only be returned after the Etel "step 2"/build
 * mode session has been initialized.
 * @export
 * @interface ScaniaGetBuildModeEtelInfoResponseWrapperScaniaGetBuildModeEtelInfo
 */
export interface ScaniaGetBuildModeEtelInfoResponseWrapperScaniaGetBuildModeEtelInfo {
  /**
   *
   * @type {EtelBuildModeStatus}
   * @memberof ScaniaGetBuildModeEtelInfoResponseWrapperScaniaGetBuildModeEtelInfo
   */
  status: EtelBuildModeStatus
  /**
   *
   * @type {string}
   * @memberof ScaniaGetBuildModeEtelInfoResponseWrapperScaniaGetBuildModeEtelInfo
   */
  lastUpdated: string
}

/**
 * Check if a given object implements the ScaniaGetBuildModeEtelInfoResponseWrapperScaniaGetBuildModeEtelInfo interface.
 */
export function instanceOfScaniaGetBuildModeEtelInfoResponseWrapperScaniaGetBuildModeEtelInfo(
  value: object,
): boolean {
  let isInstance = true
  isInstance = isInstance && 'status' in value
  isInstance = isInstance && 'lastUpdated' in value

  return isInstance
}

export function ScaniaGetBuildModeEtelInfoResponseWrapperScaniaGetBuildModeEtelInfoFromJSON(
  json: any,
): ScaniaGetBuildModeEtelInfoResponseWrapperScaniaGetBuildModeEtelInfo {
  return ScaniaGetBuildModeEtelInfoResponseWrapperScaniaGetBuildModeEtelInfoFromJSONTyped(
    json,
    false,
  )
}

export function ScaniaGetBuildModeEtelInfoResponseWrapperScaniaGetBuildModeEtelInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ScaniaGetBuildModeEtelInfoResponseWrapperScaniaGetBuildModeEtelInfo {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: EtelBuildModeStatusFromJSON(json['status']),
    lastUpdated: json['last_updated'],
  }
}

export function ScaniaGetBuildModeEtelInfoResponseWrapperScaniaGetBuildModeEtelInfoToJSON(
  value?: ScaniaGetBuildModeEtelInfoResponseWrapperScaniaGetBuildModeEtelInfo | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: EtelBuildModeStatusToJSON(value.status),
    last_updated: value.lastUpdated,
  }
}
