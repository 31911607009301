// TODO: Upgrade to the latest Redux.
//
// TODO: Update Redux usage according to the latest documentation and use Redux
// Toolkit with createAction/match instead of the current boilerplate code.
// https://redux.js.org/redux-toolkit/overview
//
// TODO: Implement best practices: https://redux.js.org/style-guide/

import { LanguageInfo, ScaniaReadMoreTextNode } from '../api/generated'
import { EtelMarketAndLanguage, FactoryModelsStartupData } from '../api/startup'
import { ColorMenuInfo } from '../pages/BuildMode/BuildModePage'
import {
  LeafMenuEntry,
  VisibleMenuEntry,
} from '../pages/BuildMode/Menu/ConfiguratorMenu'
import {
  ScaniaAdobeTrackingButtonPlacement,
  ScaniaAdobeTrackingPageName,
} from '../utils/adobeAnalytics'

export interface LoginStatus {
  isloggedin: boolean
  userMail: string
}

// Fix 'any' types.
export interface MarketLanguageState extends EtelMarketAndLanguage {
  isoLanguageCode: string
  isoMarketCode: string
  isoLocalization: any
  marketFullname: string
  optionalLanguages: LanguageInfo[]
}

export interface FactoryModelsState {
  factoryModels: FactoryModelsStartupData | null
  hasBevModels: boolean
  mode: number
}

// TODO: This Typescript porting is only just started. Replace all "any" types
// with the actual types and define new types where nedded.
export interface UiState {
  layoutViewport: any
  visualViewport: any
}

export interface ReadMoreData {
  label: string
  images: string[]
  text: ScaniaReadMoreTextNode[] | undefined | null
}

export enum SmartDashCampaignMode {
  None,
  FancyAnimatedLinesAndCurve,
  OrangeBlob,
}

// Series/Frame combinations that are unavailable for the current configuration
// and should be subtracted from the complete list of series/frame combinations.
// Called Ignored Frames in the web API.
export interface ImageSeriesFrame {
  seriesId: string // eg. "EXTERIOR", "INTERIOR", "360"
  frameId: number
}

export enum ExtendedCheckStatus {
  None,
  WaitingForResult,
  Success,
  Failure,
}

export interface PageViewTrackingEventPayload {
  pageName: ScaniaAdobeTrackingPageName
  marketLanguage: MarketLanguageState
}

export interface SessionError {
  errorLevel: number
  friendlyErrorMessage: string | null
  errorMessage: string | null
}

export interface ModalSaveAsGarageState {
  source: ScaniaAdobeTrackingButtonPlacement
}

export interface ModalSaveImageState {
  source: ScaniaAdobeTrackingButtonPlacement
  imageFrame: number
  imageSeries: string
}

export interface ModalSaveQrState {
  source: ScaniaAdobeTrackingButtonPlacement
}

export interface ModalSavePdfState {
  source: ScaniaAdobeTrackingButtonPlacement
}

export interface ModalSaveXmlState {
  source: ScaniaAdobeTrackingButtonPlacement
}

export interface ModalShareEmailState {
  source: ScaniaAdobeTrackingButtonPlacement
}

export interface ModalShareFacebookState {
  source: ScaniaAdobeTrackingButtonPlacement
}

export interface ModalShareWhatsAppState {
  source: ScaniaAdobeTrackingButtonPlacement
}

// Menu data that needs to be updated in the same transaction.
export interface VisibleMenuState {
  activeMenuId: string
  colorInfo: ColorMenuInfo | null
  leafNodes: LeafMenuEntry[] | null
  menuNodes: Record<string, VisibleMenuEntry>
  menusWithSelectableItems: Record<string, boolean>
}
