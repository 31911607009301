import { useCallback } from 'react'
import {
  AdobeTrackingGoSkipToBm,
  ScaniaAdobeTrackingFlowName,
} from '../../utils/adobeAnalytics'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { getCurrentQuestionIndex } from './GoStepperEntry'
import { resolveTrackingStepName } from './goUserTracking'
import {
  getGuidedOfferingClientState,
  setPendingUserTrackingPageNavigation,
} from '../../store/sessionDataSlice'

export const useTimelineSkipWithTracking = (handleTimelineSkip: () => void) => {
  const dispatch = useAppDispatch()
  const goClientState = useAppSelector(getGuidedOfferingClientState)

  const handleTimelineSkipWithTracking = useCallback(() => {
    if (!goClientState) {
      throw new Error('Expected goClientState to be non-null.')
    }
    const familyId = goClientState.lastResponse.question?.etelFamily || null
    if (!familyId) {
      throw new Error('Expected familyId to be defined.')
    }
    const stepName = resolveTrackingStepName(familyId)
    if (!stepName) {
      throw new Error('Expected stepName to be defined.')
    }
    const i = getCurrentQuestionIndex(goClientState)
    const stepSkipped = `skip from step${i + 1} to quick start`
    dispatch(
      setPendingUserTrackingPageNavigation({
        factoryModelSelected: null,
        flowName: ScaniaAdobeTrackingFlowName.GuidedOffering,
        optionSelected: AdobeTrackingGoSkipToBm,
        stepName,
        stepSkipped,
      }),
    )
    handleTimelineSkip()
  }, [dispatch, goClientState, handleTimelineSkip])

  return handleTimelineSkipWithTracking
}
