/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { MainMenuInfo } from './MainMenuInfo'
import {
  MainMenuInfoFromJSON,
  MainMenuInfoFromJSONTyped,
  MainMenuInfoToJSON,
} from './MainMenuInfo'
import type { MenuInfo } from './MenuInfo'
import {
  MenuInfoFromJSON,
  MenuInfoFromJSONTyped,
  MenuInfoToJSON,
} from './MenuInfo'

/**
 *
 * @export
 * @interface SessionInitDataMenuInfo
 */
export interface SessionInitDataMenuInfo {
  /**
   *
   * @type {Array<MenuInfo>}
   * @memberof SessionInitDataMenuInfo
   */
  items: Array<MenuInfo>
  /**
   *
   * @type {Array<MainMenuInfo>}
   * @memberof SessionInitDataMenuInfo
   */
  menus: Array<MainMenuInfo>
}

/**
 * Check if a given object implements the SessionInitDataMenuInfo interface.
 */
export function instanceOfSessionInitDataMenuInfo(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'items' in value
  isInstance = isInstance && 'menus' in value

  return isInstance
}

export function SessionInitDataMenuInfoFromJSON(
  json: any,
): SessionInitDataMenuInfo {
  return SessionInitDataMenuInfoFromJSONTyped(json, false)
}

export function SessionInitDataMenuInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SessionInitDataMenuInfo {
  if (json === undefined || json === null) {
    return json
  }
  return {
    items: (json['items'] as Array<any>).map(MenuInfoFromJSON),
    menus: (json['menus'] as Array<any>).map(MainMenuInfoFromJSON),
  }
}

export function SessionInitDataMenuInfoToJSON(
  value?: SessionInitDataMenuInfo | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    items: (value.items as Array<any>).map(MenuInfoToJSON),
    menus: (value.menus as Array<any>).map(MainMenuInfoToJSON),
  }
}
