/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GoOption } from './GoOption'
import {
  GoOptionFromJSON,
  GoOptionFromJSONTyped,
  GoOptionToJSON,
} from './GoOption'
import type { GoOptionInternal } from './GoOptionInternal'
import {
  GoOptionInternalFromJSON,
  GoOptionInternalFromJSONTyped,
  GoOptionInternalToJSON,
} from './GoOptionInternal'
import type { GoQuestionType } from './GoQuestionType'
import {
  GoQuestionTypeFromJSON,
  GoQuestionTypeFromJSONTyped,
  GoQuestionTypeToJSON,
} from './GoQuestionType'
import type { ScalarConstraints } from './ScalarConstraints'
import {
  ScalarConstraintsFromJSON,
  ScalarConstraintsFromJSONTyped,
  ScalarConstraintsToJSON,
} from './ScalarConstraints'

/**
 *
 * @export
 * @interface GoQuestion
 */
export interface GoQuestion {
  /**
   *
   * @type {GoQuestionType}
   * @memberof GoQuestion
   */
  type: GoQuestionType
  /**
   * Formatting of information passed on to client
   * @type {Array<string>}
   * @memberof GoQuestion
   */
  tags: Array<string>
  /**
   *
   * @type {string}
   * @memberof GoQuestion
   */
  header?: string | null
  /**
   *
   * @type {string}
   * @memberof GoQuestion
   */
  etelFamily?: string | null
  /**
   *
   * @type {string}
   * @memberof GoQuestion
   */
  description?: string | null
  /**
   *
   * @type {string}
   * @memberof GoQuestion
   */
  unit?: string | null
  /**
   *
   * @type {string}
   * @memberof GoQuestion
   */
  selected?: string | null
  /**
   *
   * @type {Array<GoOption>}
   * @memberof GoQuestion
   */
  options?: Array<GoOption> | null
  /**
   * Will be non-null if this question is a slider/scalar interval based
   * question.
   * @type {Array<GoOptionInternal>}
   * @memberof GoQuestion
   */
  sliderOptions?: Array<GoOptionInternal> | null
  /**
   *
   * @type {ScalarConstraints}
   * @memberof GoQuestion
   */
  scalarConstraints?: ScalarConstraints | null
}

/**
 * Check if a given object implements the GoQuestion interface.
 */
export function instanceOfGoQuestion(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'tags' in value

  return isInstance
}

export function GoQuestionFromJSON(json: any): GoQuestion {
  return GoQuestionFromJSONTyped(json, false)
}

export function GoQuestionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GoQuestion {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: GoQuestionTypeFromJSON(json['type']),
    tags: json['tags'],
    header: !exists(json, 'header') ? undefined : json['header'],
    etelFamily: !exists(json, 'etelFamily') ? undefined : json['etelFamily'],
    description: !exists(json, 'description') ? undefined : json['description'],
    unit: !exists(json, 'unit') ? undefined : json['unit'],
    selected: !exists(json, 'selected') ? undefined : json['selected'],
    options: !exists(json, 'options')
      ? undefined
      : json['options'] === null
      ? null
      : (json['options'] as Array<any>).map(GoOptionFromJSON),
    sliderOptions: !exists(json, 'sliderOptions')
      ? undefined
      : json['sliderOptions'] === null
      ? null
      : (json['sliderOptions'] as Array<any>).map(GoOptionInternalFromJSON),
    scalarConstraints: !exists(json, 'scalarConstraints')
      ? undefined
      : ScalarConstraintsFromJSON(json['scalarConstraints']),
  }
}

export function GoQuestionToJSON(value?: GoQuestion | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: GoQuestionTypeToJSON(value.type),
    tags: value.tags,
    header: value.header,
    etelFamily: value.etelFamily,
    description: value.description,
    unit: value.unit,
    selected: value.selected,
    options:
      value.options === undefined
        ? undefined
        : value.options === null
        ? null
        : (value.options as Array<any>).map(GoOptionToJSON),
    sliderOptions:
      value.sliderOptions === undefined
        ? undefined
        : value.sliderOptions === null
        ? null
        : (value.sliderOptions as Array<any>).map(GoOptionInternalToJSON),
    scalarConstraints: ScalarConstraintsToJSON(value.scalarConstraints),
  }
}
