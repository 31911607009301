/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ResetPasswordRequest
 */
export interface ResetPasswordRequest {
  /**
   *
   * @type {string}
   * @memberof ResetPasswordRequest
   */
  token: string
  /**
   *
   * @type {string}
   * @memberof ResetPasswordRequest
   */
  newPassword: string
}

/**
 * Check if a given object implements the ResetPasswordRequest interface.
 */
export function instanceOfResetPasswordRequest(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'token' in value
  isInstance = isInstance && 'newPassword' in value

  return isInstance
}

export function ResetPasswordRequestFromJSON(json: any): ResetPasswordRequest {
  return ResetPasswordRequestFromJSONTyped(json, false)
}

export function ResetPasswordRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ResetPasswordRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    token: json['token'],
    newPassword: json['new_password'],
  }
}

export function ResetPasswordRequestToJSON(
  value?: ResetPasswordRequest | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    token: value.token,
    new_password: value.newPassword,
  }
}
