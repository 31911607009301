import { ChevronRight } from 'react-feather'
import styled from 'styled-components'
import { TestElementTypeId } from '../../types/TestAttributeId'

const ViewAllTrucksButtonRoot = styled.div<{ $size: number }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  cursor: pointer;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      //filter: drop-shadow(8px 8px 5px #041e4222);
      filter: brightness(1.15);
    }
  }

  &:active {
    filter: unset;
  }
  height: 200px;
  margin-bottom: 45px;
  max-width: 100%;
  @media only screen and (max-width: 1300px) {
    width: ${(props) => props.$size}px;
  }
  @media only screen and (min-width: 780px) and (max-width: 1300px) {
    margin-right: 28px; // should get shadowmargin here aswell.
  }
  @media only screen and (min-width: 1300px) {
    max-width: 160px;
  }
`

const NumberText = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  font-size: 3rem;
  font-family: 'Scania Sans', sans-serif;
  width: 100%;
  color: #99999b;
`

const ViewAllButton = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: 'Scania Sans Bold', sans-serif;
  font-size: 12px;
  font-weight: 700;
  width: 100%;
  background-color: #3d71ce;
  color: white;
  text-transform: uppercase;
  padding: 12px;
  align-items: center;
`

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
`

export interface ViewAllTrucksForApplicationButtonProps {
  onClick: () => void
  number: number
  text: string
  imageWidth: number
  style?: React.CSSProperties
}

export const ViewAllTrucksForApplicationButton = (
  props: ViewAllTrucksForApplicationButtonProps,
): JSX.Element => {
  return (
    <ViewAllTrucksButtonRoot
      $size={props.imageWidth}
      style={props.style}
      onClick={props.onClick}
    >
      <NumberText
        data-test-element-type={TestElementTypeId.FactoryModelViewAllNumberText}
      >
        +{props.number}
      </NumberText>
      <ViewAllButton
        data-test-element-type={TestElementTypeId.FactoryModelViewAllButton}
      >
        {props.text}
        <IconWrapper>
          <ChevronRight />
        </IconWrapper>
      </ViewAllButton>
    </ViewAllTrucksButtonRoot>
  )
}
