/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { DealerOpenTimesEntry } from './DealerOpenTimesEntry'
import {
  DealerOpenTimesEntryFromJSON,
  DealerOpenTimesEntryFromJSONTyped,
  DealerOpenTimesEntryToJSON,
} from './DealerOpenTimesEntry'

/**
 *
 * @export
 * @interface DealerOpeningHoursEntry
 */
export interface DealerOpeningHoursEntry {
  /**
   *
   * @type {Array<string>}
   * @memberof DealerOpeningHoursEntry
   */
  days?: Array<string> | null
  /**
   *
   * @type {Array<DealerOpenTimesEntry>}
   * @memberof DealerOpeningHoursEntry
   */
  openTimes?: Array<DealerOpenTimesEntry> | null
}

/**
 * Check if a given object implements the DealerOpeningHoursEntry interface.
 */
export function instanceOfDealerOpeningHoursEntry(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function DealerOpeningHoursEntryFromJSON(
  json: any,
): DealerOpeningHoursEntry {
  return DealerOpeningHoursEntryFromJSONTyped(json, false)
}

export function DealerOpeningHoursEntryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): DealerOpeningHoursEntry {
  if (json === undefined || json === null) {
    return json
  }
  return {
    days: !exists(json, 'Days') ? undefined : json['Days'],
    openTimes: !exists(json, 'OpenTimes')
      ? undefined
      : json['OpenTimes'] === null
      ? null
      : (json['OpenTimes'] as Array<any>).map(DealerOpenTimesEntryFromJSON),
  }
}

export function DealerOpeningHoursEntryToJSON(
  value?: DealerOpeningHoursEntry | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    Days: value.days,
    OpenTimes:
      value.openTimes === undefined
        ? undefined
        : value.openTimes === null
        ? null
        : (value.openTimes as Array<any>).map(DealerOpenTimesEntryToJSON),
  }
}
