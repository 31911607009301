import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { SESSION_FAILURE } from '../../api/errors'
import {
  ScaniaAdobeTrackingShareFacebookEvent,
  pushAdobeEvent,
  ScaniaAdobeEventId,
  ScaniaAdobeShareOption,
} from '../../utils/adobeAnalytics'
import { buildAbsoluteFacebookShareLink } from '../../utils/UrlBuilders'
import { useClient } from '../../utils/useClient'
import useTexts from '../../utils/useTexts'
import { SvgLoading } from '../SvgImports'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import {
  getMarketDenomination,
  getSessionInitDataState,
} from '../../store/sessionDataSlice'
import { getMarketLanguageState } from '../../store/appSlice'
import { closeAllModals, getModalState } from '../../store/modalSlice'
import { TdsButton, TdsModal } from '@scania/tegel-react'

const LinkWrapper = styled.div`
  height: 40px;
`

const SpinnerWrapper = styled.div`
  margin-left: 32px;
  height: 40px;

  svg {
    color: var(--tds-blue-600);
  }
`

interface ModalShareFacebookProps {
  handleFatalError: () => void
  handleSessionFailure: () => void
}

export function ModalShareFacebook({
  handleFatalError,
  handleSessionFailure,
}: ModalShareFacebookProps): JSX.Element {
  const t = useTexts()
  const client = useClient()
  const dispatch = useAppDispatch()
  const sessionInitData = useAppSelector(getSessionInitDataState)
  const marketLanguage = useAppSelector(getMarketLanguageState)
  const marketDenomination = useAppSelector(getMarketDenomination)
  const source = useAppSelector(getModalState).modalShareFacebookState?.source
  const [url, setUrl] = useState<string | null>(null)

  useEffect(() => {
    const sessionId = sessionInitData?.sessionId
    if (!client) {
      return
    }
    if (!sessionId) {
      return
    }
    if (!marketLanguage) {
      return
    }
    ;(async () => {
      const response = await client.publicConfigV2Save(
        sessionId,
        marketDenomination || '-',
      )
      if (response.error) {
        console.error(
          'Failed to save public config for facebook share link, status: ' +
            response.error,
        )
        if (response.error === SESSION_FAILURE) {
          handleSessionFailure()
        } else {
          handleFatalError()
        }
        return
      }
      if (!response.publicConfigV2Save) {
        console.error('Expected response.publicConfigV2Save to be defined.')
        handleFatalError()
        return
      }
      const guid = response.publicConfigV2Save.guid
      const link = buildAbsoluteFacebookShareLink(marketLanguage, guid)
      setUrl(link)
    })()
  }, [
    client,
    handleFatalError,
    handleSessionFailure,
    marketDenomination,
    marketLanguage,
    sessionInitData,
  ])

  const handleShareToFacebookClick = useCallback(() => {
    if (!url) {
      return
    }
    if (!source) {
      return
    }
    const trackingEvent: ScaniaAdobeTrackingShareFacebookEvent = {
      event: ScaniaAdobeEventId.Share,
      eventInfo: {
        shareOption: ScaniaAdobeShareOption.Facebook,
        placement: source,
      },
    }
    pushAdobeEvent(trackingEvent)
    FB.ui(
      {
        method: 'share',
        href: url,
      },
      function (response) {},
    )
  }, [source, url])

  const handleCloseClick = useCallback(() => {
    dispatch(closeAllModals())
  }, [dispatch])

  // Eventlistener to close modal on tdsClose (click outside modal and click on close cross)
  useEffect(() => {
    document.addEventListener('tdsClose', handleCloseClick)
    return () => {
      document.removeEventListener('tdsClose', handleCloseClick)
    }
  }, [handleCloseClick])

  return (
    <TdsModal size="md" header={t('LABEL_ACTION_SHARE_FACEBOOK')} show>
      <span slot="body">
        {url ? (
          <LinkWrapper className="tds-detail-05">{url}</LinkWrapper>
        ) : (
          <SpinnerWrapper>
            <SvgLoading />
          </SpinnerWrapper>
        )}
      </span>

      <span slot="actions" className="tds-u-flex tds-u-gap2 tds-u-flex-wrap">
        <TdsButton
          variant="primary"
          size="md"
          text={t('LABEL_ACTION_SHARE_FACEBOOK')}
          onClick={handleShareToFacebookClick}
          disabled={!url}
        />
        <TdsButton
          variant="secondary"
          size="md"
          text={t('LABEL_ACTION_CLOSE')}
          onClick={handleCloseClick}
        />
      </span>
    </TdsModal>
  )
}
