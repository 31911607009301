import { useState, useEffect, createContext, useContext } from 'react'

export interface BreakpointQueries {
  [index: string]: string
  phone: string
  tablet: string
  desktop: string
  desktop_large: string
  or: string
}

const defaultValue: Record<string, boolean> = {}

const BreakpointContext = createContext(defaultValue)

export interface BreakpointProviderProps {
  children: any
  queries: BreakpointQueries
}

const BreakpointProvider = ({ children, queries }: BreakpointProviderProps) => {
  const [queryMatch, setQueryMatch] = useState({})

  useEffect(() => {
    const mediaQueryLists: Record<string, MediaQueryList> = {}
    const keys = Object.keys(queries)
    let isAttached = false

    const handleQueryListener = () => {
      const updatedMatches = keys.reduce<Record<string, boolean>>(
        (acc, media) => {
          acc[media] = !!(
            mediaQueryLists[media] && mediaQueryLists[media].matches
          )
          return acc
        },
        {},
      )
      setQueryMatch(updatedMatches)
    }

    if (window && window.matchMedia) {
      const matches: Record<string, boolean> = {}
      keys.forEach((media) => {
        if (typeof queries[media] === 'string') {
          mediaQueryLists[media] = window.matchMedia(queries[media])
          matches[media] = mediaQueryLists[media].matches
        } else {
          matches[media] = false
        }
      })
      setQueryMatch(matches)
      isAttached = true
      keys.forEach((media) => {
        if (typeof queries[media] === 'string') {
          mediaQueryLists[media].addListener(handleQueryListener)
        }
      })
    }

    return () => {
      if (isAttached) {
        keys.forEach((media) => {
          if (typeof queries[media] === 'string') {
            mediaQueryLists[media].removeListener(handleQueryListener)
          }
        })
      }
    }
  }, [queries])

  return (
    <BreakpointContext.Provider value={queryMatch}>
      {children}
    </BreakpointContext.Provider>
  )
}

function useBreakpoint() {
  const context = useContext(BreakpointContext)
  if (context === defaultValue) {
    throw new Error('useBreakpoint must be used within BreakpointProvider')
  }
  return context
}
export { useBreakpoint, BreakpointProvider }
