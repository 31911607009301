import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { SESSION_FAILURE } from '../../api/errors'
import {
  ScaniaAdobeTrackingShareWhatsAppEvent,
  pushAdobeEvent,
  ScaniaAdobeEventId,
  ScaniaAdobeShareOption,
} from '../../utils/adobeAnalytics'
import { buildAbsolutePublicConfigLink } from '../../utils/UrlBuilders'
import { useClient } from '../../utils/useClient'
import useTexts from '../../utils/useTexts'
import { SvgLoading } from '../SvgImports'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import {
  getMarketDenomination,
  getSessionInitDataState,
} from '../../store/sessionDataSlice'
import { getMarketLanguageState } from '../../store/appSlice'
import { closeAllModals, getModalState } from '../../store/modalSlice'
import { TdsButton, TdsModal } from '@scania/tegel-react'

const TextBox = styled.div`
  height: 40px;
`

const SpinnerWrapper = styled.div`
  margin-left: 32px;
  height: 40px;

  svg {
    color: var(--tds-blue-600);
  }
`

interface ModalShareWhatsAppProps {
  handleSessionFailure: () => void
}

export function ModalShareWhatsApp(
  props: ModalShareWhatsAppProps,
): JSX.Element {
  const t = useTexts()
  const client = useClient()
  const dispatch = useAppDispatch()
  const sessionInitData = useAppSelector(getSessionInitDataState)
  const marketLanguage = useAppSelector(getMarketLanguageState)
  const marketDenomination = useAppSelector(getMarketDenomination)
  const source = useAppSelector(getModalState).modalShareWhatsAppState?.source
  const [shareLinkWhatsApp, setShareLinkWhatsApp] = useState<string | null>(
    null,
  )

  // Create link to share on Whats App
  useEffect(() => {
    async function getPublicConfUrl() {
      if (!sessionInitData || !client || !marketDenomination) {
        return
      }
      if (!marketLanguage) {
        return
      }
      const configAux = {}

      const response = await client.publicConfigV2Save(
        sessionInitData.sessionId,
        marketDenomination,
        configAux,
      )
      if (response.error === SESSION_FAILURE) {
        props.handleSessionFailure()
        return
      }
      if (!response.publicConfigV2Save) {
        throw new Error('Expected response.publicConfigV2Save to be defined.')
      }
      const config = response.publicConfigV2Save
      const linkToPublicConfig = buildAbsolutePublicConfigLink(
        marketLanguage,
        config.guid,
      )
      const linkWhatsApp =
        'https://wa.me/?text=' + encodeURIComponent(linkToPublicConfig)
      return { linkWhatsApp }
    }

    getPublicConfUrl().then((result) => {
      if (result) {
        setShareLinkWhatsApp(result.linkWhatsApp)
      }
    })
  }, [client, marketLanguage, props, sessionInitData, marketDenomination])

  const handleShareTracking = () => {
    if (!source) {
      throw new Error('Expected source.')
    }
    const trackingEvent: ScaniaAdobeTrackingShareWhatsAppEvent = {
      event: ScaniaAdobeEventId.Share,
      eventInfo: {
        shareOption: ScaniaAdobeShareOption.WhatsApp,
        placement: source,
      },
    }
    pushAdobeEvent(trackingEvent)
  }

  const handleCloseClick = useCallback(() => {
    dispatch(closeAllModals())
  }, [dispatch])

  // Eventlistener to close modal on tdsClose (click outside modal and click on close cross)
  useEffect(() => {
    document.addEventListener('tdsClose', handleCloseClick)
    return () => {
      document.removeEventListener('tdsClose', handleCloseClick)
    }
  }, [handleCloseClick])

  return (
    <TdsModal size="md" header={t('HEADER_SHARE_WHATSAPP')} show>
      <span slot="body">
        {shareLinkWhatsApp ? (
          <TextBox>{t('LABEL_SHARE_WHATSAPP')}</TextBox>
        ) : (
          <SpinnerWrapper>
            <SvgLoading />
          </SpinnerWrapper>
        )}
      </span>
      <span slot="actions" className="tds-u-flex tds-u-gap2 tds-u-flex-wrap">
        <a
          href={shareLinkWhatsApp || ''}
          target={'_blank'}
          rel="noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <TdsButton
            size="md"
            onClick={handleShareTracking}
            text={t('LABEL_ACTION_SHARE')}
            disabled={shareLinkWhatsApp ? false : true}
          />
        </a>
        <TdsButton
          size="md"
          variant="secondary"
          text={t('LABEL_ACTION_CLOSE')}
          onClick={handleCloseClick}
        />
      </span>
    </TdsModal>
  )
}
