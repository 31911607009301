/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { SendResetPasswordMailError } from './SendResetPasswordMailError'
import {
  SendResetPasswordMailErrorFromJSON,
  SendResetPasswordMailErrorFromJSONTyped,
  SendResetPasswordMailErrorToJSON,
} from './SendResetPasswordMailError'

/**
 * This wrapper is needed to match the old API exactly diring the
 * migration, consider using different models and response codes for
 * success and error cases later.
 * @export
 * @interface SendResetPasswordMailResponseWrapper
 */
export interface SendResetPasswordMailResponseWrapper {
  /**
   *
   * @type {string}
   * @memberof SendResetPasswordMailResponseWrapper
   */
  sendResetPasswordMail?: string | null
  /**
   *
   * @type {SendResetPasswordMailError}
   * @memberof SendResetPasswordMailResponseWrapper
   */
  error?: SendResetPasswordMailError | null
}

/**
 * Check if a given object implements the SendResetPasswordMailResponseWrapper interface.
 */
export function instanceOfSendResetPasswordMailResponseWrapper(
  value: object,
): boolean {
  let isInstance = true

  return isInstance
}

export function SendResetPasswordMailResponseWrapperFromJSON(
  json: any,
): SendResetPasswordMailResponseWrapper {
  return SendResetPasswordMailResponseWrapperFromJSONTyped(json, false)
}

export function SendResetPasswordMailResponseWrapperFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SendResetPasswordMailResponseWrapper {
  if (json === undefined || json === null) {
    return json
  }
  return {
    sendResetPasswordMail: !exists(json, 'send_reset_password_mail')
      ? undefined
      : json['send_reset_password_mail'],
    error: !exists(json, 'error')
      ? undefined
      : SendResetPasswordMailErrorFromJSON(json['error']),
  }
}

export function SendResetPasswordMailResponseWrapperToJSON(
  value?: SendResetPasswordMailResponseWrapper | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    send_reset_password_mail: value.sendResetPasswordMail,
    error: SendResetPasswordMailErrorToJSON(value.error),
  }
}
