/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface GowizardStartRequest
 */
export interface GowizardStartRequest {
  /**
   *
   * @type {string}
   * @memberof GowizardStartRequest
   */
  language: string
  /**
   *
   * @type {string}
   * @memberof GowizardStartRequest
   */
  market: string
  /**
   *
   * @type {boolean}
   * @memberof GowizardStartRequest
   */
  useBevFilters: boolean
  /**
   *
   * @type {boolean}
   * @memberof GowizardStartRequest
   */
  showTextPreview: boolean
  /**
   * Intended only for automated tests.
   * @type {number}
   * @memberof GowizardStartRequest
   */
  timeoutSeconds?: number | null
}

/**
 * Check if a given object implements the GowizardStartRequest interface.
 */
export function instanceOfGowizardStartRequest(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'language' in value
  isInstance = isInstance && 'market' in value
  isInstance = isInstance && 'useBevFilters' in value
  isInstance = isInstance && 'showTextPreview' in value

  return isInstance
}

export function GowizardStartRequestFromJSON(json: any): GowizardStartRequest {
  return GowizardStartRequestFromJSONTyped(json, false)
}

export function GowizardStartRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GowizardStartRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    language: json['language'],
    market: json['market'],
    useBevFilters: json['useBevFilters'],
    showTextPreview: json['showTextPreview'],
    timeoutSeconds: !exists(json, 'timeoutSeconds')
      ? undefined
      : json['timeoutSeconds'],
  }
}

export function GowizardStartRequestToJSON(
  value?: GowizardStartRequest | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    language: value.language,
    market: value.market,
    useBevFilters: value.useBevFilters,
    showTextPreview: value.showTextPreview,
    timeoutSeconds: value.timeoutSeconds,
  }
}
