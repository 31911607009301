/* tslint:disable */
/* eslint-disable */
/**
 * Scania Configurator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ScaniaReadMoreTextNode
 */
export interface ScaniaReadMoreTextNode {
  /**
   *
   * @type {string}
   * @memberof ScaniaReadMoreTextNode
   */
  textParagraph?: string | null
  /**
   *
   * @type {string}
   * @memberof ScaniaReadMoreTextNode
   */
  textList?: string | null
}

/**
 * Check if a given object implements the ScaniaReadMoreTextNode interface.
 */
export function instanceOfScaniaReadMoreTextNode(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function ScaniaReadMoreTextNodeFromJSON(
  json: any,
): ScaniaReadMoreTextNode {
  return ScaniaReadMoreTextNodeFromJSONTyped(json, false)
}

export function ScaniaReadMoreTextNodeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ScaniaReadMoreTextNode {
  if (json === undefined || json === null) {
    return json
  }
  return {
    textParagraph: !exists(json, 'textParagraph')
      ? undefined
      : json['textParagraph'],
    textList: !exists(json, 'textList') ? undefined : json['textList'],
  }
}

export function ScaniaReadMoreTextNodeToJSON(
  value?: ScaniaReadMoreTextNode | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    textParagraph: value.textParagraph,
    textList: value.textList,
  }
}
